import {convertorFromSwagger, convertorToSwagger} from '../convertors/audit-log';
import {extractJSONLD} from '../../helpers/request-helper';
import {getApiJSONLD} from '../swagger/api-factory';
import {AuditLogFilterParamsType} from "../../types";


export const getItems = (params:AuditLogFilterParamsType ) => {
  return getApiJSONLD()
    .then(api => api.getCompanyLogCollection(convertorToSwagger(params)))
    .then(extractJSONLD(convertorFromSwagger));
};
