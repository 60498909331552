import * as React from 'react';
import {connect} from 'react-redux';
import {withStyles, Modal, Typography, Grid, Button, WithStyles} from '@material-ui/core';
import {testerAccountSubmit, testerAccountModalClose} from '../../actions';
import {getIsOpenModalTesterAccount} from "../../selectors/modal";
import {defaultStyleWidth} from '../../styles/material/popup-style';
import ReduxTextField from "../../components/forms/ReduxTextField";
import {FieldGeneric} from "../../components/forms/FieldGeneric";
import {InjectedFormProps, reduxForm} from "redux-form";
import {composeValidators, fieldIsEmail, fieldsNotEmpty} from "../../helpers/validations/validation";
import {getUserEmail, getUserFirstName, getUserLastName, getUserPhone} from "../../selectors/auth";
import {handleSpaceKeyPress} from "../../helpers/validations/emptyTestFieldValidation";
import {handleKeyPressOnPhoneField} from "../../helpers/validations/numberFieldValidator";

const FORM_NAME = 'TesterAccountModal';

interface IStateToProps {
  isOpenModal: boolean;
  initialValues: { email: string; phone: string; firstName: string; lastName: string };
}

interface IDispatchToProps {
  testerAccountModalClose: () => void;
  testerAccountSubmit: (email: string, phone: string, firstName: string, lastName: string) => void;
}

type IProps = InjectedFormProps<{}, {}> & IStateToProps & IDispatchToProps & WithStyles<any>;

class TesterAccountModal extends React.Component<IProps> {
  componentWillReceiveProps(nextProps: Readonly<IProps>): void {
    const {isOpenModal, change, initialValues} = this.props
    if (nextProps.isOpenModal && !isOpenModal) {
      change('email', initialValues.email);
      change('phone', initialValues.phone);
      change('firstName', initialValues.firstName);
      change('lastName', initialValues.lastName);
    }
  }

  render() {
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.props.isOpenModal}
        onClose={this.handleClose}
      >
        <form
          noValidate
          autoComplete="off"
          onSubmit={this.props.handleSubmit((values: any) => {
            const {email, phone, firstName, lastName} = values

            this.props.testerAccountSubmit(email, phone, firstName, lastName);
          })}
        >
          <div className={this.props.classes.paper}>
            <Typography variant="title" component="h3" id="modal-title">
              My Account
            </Typography>
            <hr/>
            <div>
              <FieldGeneric
                name="firstName"
                label="First name *"
                component={ReduxTextField}
              />
              <FieldGeneric
                name="lastName"
                label="Last name *"
                component={ReduxTextField}
              />
              <FieldGeneric
                name="email"
                label="Email *"
                component={ReduxTextField}
                onKeyPress={handleSpaceKeyPress}
              />
              <FieldGeneric
                name="phone"
                label="Telephone *"
                component={ReduxTextField}
                onKeyPress={handleKeyPressOnPhoneField}
              />
            </div>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <div className="d-flex justify-content-between mt-4">
                  <Button
                    variant="contained"
                    color="default"
                    className="mr-5"
                    onClick={this.handleClose}>
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit">
                    Save
                  </Button>
                </div>
              </Grid>
            </Grid>
          </div>
        </form>
      </Modal>
    );
  }

  private readonly handleClose = () => {
    this.props.testerAccountModalClose();
  };
}

function mapStateToProps(state) {
  return {
    isOpenModal: getIsOpenModalTesterAccount(state),
    initialValues: {
      firstName: getUserFirstName(state),
      lastName: getUserLastName(state),
      email: getUserEmail(state),
      phone: getUserPhone(state),
    },
  };
}

const fetched = reduxForm({
  form: FORM_NAME,
  validate: composeValidators(
    fieldsNotEmpty(["email", "phone", "firstName", "lastName"]),
    fieldIsEmail(["email"])
  ),
})(TesterAccountModal);

const connectedComponent = connect<IStateToProps, IDispatchToProps>(mapStateToProps, {
  testerAccountModalClose,
  testerAccountSubmit,
})(fetched);

export default withStyles(defaultStyleWidth(320))(connectedComponent as any);
