import React, { useEffect, useState } from "react";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { connect } from "react-redux";
import routerModal from "../../../../components/routerModal";
import { bindActionCreators, Dispatch } from "redux";
import ReduxTextField from "../../../forms/ReduxTextField";
import {
  composeValidators,
  fieldsNotEmpty
} from "../../../../helpers/validations/validation";
import { RouteComponentProps } from "react-router-dom";
import {
  TimesheetPoNumberType,
  TimesheetPoNumberUpdateParams,
  TimesheetPoNumberFormData
} from "../../../../types/timesheets-and-expenses";
import { updateTimesheetPoNumberAction } from "../../../../actions/time-expenses";
import { getTimesheetWeekByIdRequest } from "../../../../api/requests/time-and-expenses";
import { TableNoItems } from "../../../table/TableNoItems";

export const TE_PO_NUMBER_FORM_NAME = "TimesheetPoNumberForm";
interface TimesheetPoNumberFormProps
  extends RouteComponentProps<{ id: string }> {
  handleSubmit: () => void;
  goBackHandler: () => void;
  poNumberType: TimesheetPoNumberType;
  updateTimesheetPoNumber: (params: TimesheetPoNumberUpdateParams) => void;
}

const TimesheetPoNumberForm: React.FC<TimesheetPoNumberFormProps &
  InjectedFormProps<TimesheetPoNumberFormData>> = ({
  handleSubmit,
  poNumberType,
  change,
  goBackHandler,
  updateTimesheetPoNumber,
  match: {
    params: { id }
  }
}) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const label =
    poNumberType === TimesheetPoNumberType.PO_NUMBER
      ? "PO Number"
      : "Expenses PO Number";

  useEffect(() => {
    getTimesheetWeekByIdRequest(id).then(data => {
      const poNumber =
        poNumberType === TimesheetPoNumberType.PO_NUMBER
          ? data.purchaseOrderNumber
          : data.expensesPurchaseOrderNumber;
      if (poNumber) {
        change("value", poNumber);
      }
      setIsLoaded(true);
    });
  }, [id]);

  if (!isLoaded) {
    return <TableNoItems asLoading className="mt-0" />;
  }

  return (
    <div>
      <form
        className="timesheet-po-number-form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(({ value }) =>
          updateTimesheetPoNumber({ value, id, poNumberType, goBackHandler })
        )}
      >
        <div className="row">
          <div className="col-12">
            <h4>Edit Week`s {label}</h4>
          </div>
        </div>
        <div className="timesheet-form-section">
          <div className="row">
            <div className="col-12">
              <Field
                name="value"
                label={label}
                component={ReduxTextField}
                margin="none"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="d-flex justify-content-end mt-4">
              <button
                type="button"
                className="btn-cancel mr-4"
                onClick={goBackHandler}
              >
                Cancel
              </button>
              <button className="btn-accept" type="submit">
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

const ConnectedForm = reduxForm<
  TimesheetPoNumberFormData,
  TimesheetPoNumberFormProps
>({
  form: TE_PO_NUMBER_FORM_NAME,
  validate: composeValidators(fieldsNotEmpty(["value"]))
})(TimesheetPoNumberForm);

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      updateTimesheetPoNumber: updateTimesheetPoNumberAction
    },
    dispatch
  );
};
const connected = connect(null, mapDispatchToProps)(ConnectedForm);

export default routerModal(connected);
