import * as React from "react";
import {Route, Switch, withRouter, RouteComponentProps} from "react-router-dom";
import TestBooking from "./test/TestBooking";
import Calendar from "./calendar/Calendar";
import TestersBooking from "./testers/TestersBooking";
import TestersReviews from "./testers/TestersReviews";
import TestersBookingConfirmation from "./testers/TestersBookingConfirmation";
import TestDetails from "../../client-tester/test-details/TestDetails";
import TestUpdate from "../../client-tester/test-details/TestUpdate";

class Schedule extends React.Component<RouteComponentProps<any>, {}> {
  render() {
    return (
      <Switch>
        <Route path={`/client/schedule`}
               exact
               component={Calendar}/>
        <Route path={`/client/schedule/booking/:project/:test/testers`}
               component={TestersBooking}/>
        <Route path={`/client/schedule/booking/:project/:test/:tester/reviews`}
               component={TestersReviews}/>
        <Route path={`/client/schedule/booking/:project/:test/:tester/confirmation`}
               component={TestersBookingConfirmation}/>
        <Route path={`/client/schedule/booking/:project?/:test?`}
               component={TestBooking}/>
        <Route path={`/client/schedule/test/:test/edit`}
               component={TestUpdate}/>
        <Route path={`/client/schedule/test/pre-fill/:preFillTesterId`}
               component={TestUpdate}/>
        <Route path={`/client/schedule/test/:test`}
               component={TestDetails}/>
        <Route path={`/client/schedule/test`}
               component={TestUpdate}/>
        <Route path={`/client/schedule/:test/copy`}
               component={TestUpdate}/>
      </Switch>
    );
  }
}

export default withRouter(Schedule);
