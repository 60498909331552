import {getApi} from "../swagger/api-factory";
import {fetchRequestBody} from "../../helpers/request-helper";
import {convertorFromSwagger} from "../convertors/default-convertor";

export const postImage = (file: File) => {
  return getApi()
    .then(api => {
      return api.postApiPublicImage({file});
    })
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};

export const postSecuredImage = (file: File) => {
    return getApi()
        .then(api => {
            return api.postApiSecuredImagesUpload({file});
        })
        .then(fetchRequestBody)
        .then(convertorFromSwagger);
};