import React from "react";
import { NavLink, Redirect, Route, Switch } from "react-router-dom";
import Users from "./users/Users";
import Locations from "./locations/Locations";
import CompanyDetails from "./company-details/CompanyDetails";
import { Permission } from "../../../constants/permission";
import { Group } from "../../../constants/group";
import {
  getIsLoadedPermissions,
  getRoles,
  getUserGroup,
  getUserPermissions,
} from "../../../selectors/auth";
import { connect } from "react-redux";
import {AuditLog} from "./audit-log/AuditLog";
import AdminSettings from "./settings/Settings";

class ClientAdmin extends React.Component {
  render() {
    const { userGroup, userPermissions } = this.props;
    return (
      <div>
        <div className="styled-tabs flex-column-in-mobile">
          {(userGroup === Group.OWNER ||
            userPermissions.includes(Permission.CAN_MANAGE_VIEWERS) ||
            userPermissions.includes(Permission.CAN_MANAGE_PLANNERS)) && (
            <NavLink
              to="/client/admin/users"
              className="tab"
              activeClassName="active"
            >
              Users
            </NavLink>
          )}
          <NavLink
            to="/client/admin/locations"
            className="tab"
            activeClassName="active"
          >
            Locations
          </NavLink>
          {userGroup === Group.OWNER && (
            <NavLink
              to="/client/admin/company-details"
              className="tab"
              activeClassName="active"
            >
              Company Details
            </NavLink>
          )}
          {userGroup === Group.OWNER && (
            <NavLink
              to="/client/admin/settings"
              className="tab"
              activeClassName="active"
            >
              Settings
            </NavLink>
          )}
          {userGroup === Group.OWNER && (
            <NavLink
              to="/client/admin/audit-log"
              className="tab"
              activeClassName="active"
            >
              Audit Log
            </NavLink>
          )}
        </div>
        <Switch>
          <Route path="/client/admin/users" component={Users} />
          <Route path="/client/admin/locations" component={Locations} />
          <Route
            path="/client/admin/company-details"
            component={CompanyDetails}
          />
          <Route
            path="/client/admin/settings"
            component={AdminSettings}
          />
          <Route
            path="/client/admin/audit-log"
            component={AuditLog}
          />
          {userGroup === Group.OWNER ||
          userPermissions.includes(Permission.CAN_MANAGE_VIEWERS) ||
          userPermissions.includes(Permission.CAN_MANAGE_PLANNERS) ? (
            <Redirect from="/client/admin" to="/client/admin/users" />
          ) : (
            <Redirect from="/client/admin" to="/client/admin/locations" />
          )}
        </Switch>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentRoles: getRoles(state),
    isLoadedPermissions: getIsLoadedPermissions(state),
    userPermissions: getUserPermissions(state),
    userGroup: getUserGroup(state),
  };
}

export default connect(mapStateToProps, {})(ClientAdmin);
