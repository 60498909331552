import * as React from "react";
import { connect } from "react-redux";
import selector from "selectors/project";
import { projectsFilter } from "actions/company-projects";
import { formValueSelector, InjectedFormProps, reduxForm } from "redux-form";
import { FieldGeneric } from "components/forms/FieldGeneric";
import { bindActionCreators, Dispatch } from "redux";
import { isChangesExist } from "helpers/props-checker";
import ReduxTextField from "components/forms/ReduxTextField";
import { ACTIVE } from "constants/filter";
import { RootState } from "reducers";

const FORM_NAME = "ProjectSearchForm";
const selectorForm = formValueSelector(FORM_NAME);

class ProjectSearchForm extends React.Component<
  InjectedFormProps<{}, {}> & {
    submitFilterChanges: (...any) => void;
    valuesData: { active: string; text?: string };
    dispatch: (...any) => void;
  },
  any
> {
  componentWillReceiveProps(nextProps) {
    if (isChangesExist(["valuesData"], nextProps, this.props)) {
      this.props.submitFilterChanges(
        ACTIVE,
        nextProps.valuesData.findProject || ""
      );
    }
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <form
        onSubmit={handleSubmit((values: { active: string }) => {
          this.props.submitFilterChanges(values.active);
        })}
      >
        <div className="row">
          <div className="col-md-6 col-lg-6 col-xl-4">
            <div className="styled-block pt-2">
              <FieldGeneric
                className="p-0"
                name="findProject"
                label="Find"
                fullWidth
                component={ReduxTextField}
              />
            </div>
          </div>
        </div>
      </form>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    valuesData: { findProject: selectorForm(state, "findProject") },
    initialValues: {
      find: selector.getFilterTextFields(state),
      active: selector.getFilterActivityStatus(state)
    }
  };
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      submitFilterChanges: projectsFilter.submitFilterChanges
    },
    dispatch
  );
};

const connectForm = reduxForm({
  form: FORM_NAME
})(ProjectSearchForm);

export default connect(mapStateToProps, mapDispatchToProps)(connectForm);
