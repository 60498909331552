import * as React from "react";
import Paper from "@material-ui/core/Paper/Paper";
import Table from "@material-ui/core/Table/Table";
import TableBody from "@material-ui/core/TableBody/TableBody";
import {connect} from "react-redux";
import {getBookingRequestsAction, testerBookingRequestsLoader, testerBookingRequestsRequest} from "../../../actions/tester-booking-requests";
import selector from "../../../selectors/tester-booking-requests";
import {Button, TablePagination} from "@material-ui/core";
import {isChangesExist} from "../../../helpers/props-checker";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import debounce from "debounce";
import {ITest} from "../../../models/test.interface";
import {TestStatus} from "../../../constants/test-status";
import {formValueSelector} from "redux-form";
import {
  ALL_STATUSES_BOOKING_REQUESTS,
  BOOKING_REQUESTS_FORM_NAME
} from "../../common/BookingRequestsSearchForm";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {Link} from "react-router-dom";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Icon from "@material-ui/core/Icon/Icon";
import * as _ from "lodash";
import {extractL1SpecialismNames} from "../../../helpers/specialims-extractor";
import {formatDateWithTime} from "../../../helpers/date-formatter";
import Tooltip from "@material-ui/core/Tooltip";
import TableHead from "@material-ui/core/TableHead";
import {HashLink} from "react-router-hash-link";
import StatusBlock from "../../common/StatusBlock";
import {Group} from "../../../constants/group";
import {IState} from "../../../interfaces/state.interface";

const searchFormSelector = formValueSelector(BOOKING_REQUESTS_FORM_NAME);

interface IOwnProps {
  baseUrl: string;
  data: ITest[];

  [key: string]: any;

  searchValues: {
    project: number[],
    status: string[],
    dateFrom: string,
    dateTo: string,
    questionAsked: boolean,
  };
}

type IProps = IOwnProps & RouteComponentProps<any>;

class BookingRequestsList extends React.Component<IProps, {}> {
  debouncedLoad = debounce((nextProps) => {
    this.loadItems(nextProps);
  }, 500);

  loadItems = (props) => {
    props.loadItems(
      props.pageNumber,
      props.itemsPerPage,
      props.searchValues.project,
      !!props.searchValues.status && props.searchValues.status.length ?
        props.searchValues.status :
        ALL_STATUSES_BOOKING_REQUESTS,
      props.searchValues.dateFrom,
      props.searchValues.dateTo,
      props.searchValues.questionAsked
    );
  };

  onArchive = (item) => {
    this.props.archiveItem(
      item.id,
      this.props.pageNumber,
      this.props.itemsPerPage,
      this.props.showStatus,
      this.props.filterText
    );
  };

  componentDidMount() {
    this.loadItems(this.props);
  }

  componentWillReceiveProps(nextProps: IProps) {
    if (_.isEmpty(this.props.searchValues)) {
      return;
    }

    if (
      isChangesExist(["pageNumber", "itemsPerPage", "showStatus", "filterText"], nextProps, this.props) ||
      isChangesExist(["project", "status", "dateFrom", "dateTo", "questionAsked"], nextProps.searchValues, this.props.searchValues)
    ) {
      this.loadItems(nextProps);
    }

    if (!nextProps.data.length && nextProps.pageNumber > 1) {
      this.props.setPageNumber(1);
    }
  }

  isConsultantInputManager = !!this.props.currentUser?.isConsultancyCompanyManager


  handleChangePage = (event, page) => {
    this.props.setPageNumber(page + 1);
  };

  handleChangeRowsPerPage = event => {
    this.props.setItemsPerPage(event.target.value);
  };

  getStatusAction = (item: ITest) => {
    if (this.isConsultantInputManager) {
      return (
          <Link to={`/tester/booking-requests/${item.id}/details`}>
            <button className="btn-view" type="submit">
              Details
            </button>
          </Link>
      );
    }

    if (item.status === TestStatus.STATUS_BOOKED) {
      return (
        <Link to={`/tester/bookings/${item.id}/details`}>
          <Button type="submit" variant="contained" color="primary">
            View&nbsp;Details
          </Button>
        </Link>
      );
    }

    return (
      <Link to={`/tester/booking-requests/${item.id}`}>
        <Button type="submit" variant="contained" color="primary">
          View&nbsp;Details
        </Button>
      </Link>
    );
  };

  render() {
    const {data, isLoaded, totalItems, pageNumber, itemsPerPage} = this.props
    if (!isLoaded) {
      return (
        <div>
          Loading...
        </div>
      );
    }

    if (!data.length) {
      return (
        <div>
          There are no items!
        </div>
      );
    }

    return (
      <Paper style={{overflowX: "auto", width: "100%"}}>
        <Table padding="dense">
          <TableHead>
            <TableRow>
              <TableCell>Requested</TableCell>
              {this.props.authGroup === Group.MANAGER &&
                <TableCell>Tester</TableCell>
              }
              {this.isConsultantInputManager && (
                  <TableCell>Consultant <br/> Input</TableCell>
              )}
              <TableCell>Company</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Initial service date</TableCell>
              <TableCell style={{whiteSpace: "nowrap"}}>
                Questions
                <Tooltip
                  disableFocusListener
                  disableTouchListener
                  title="To reduce all counters to zero you must reply to all questions">
                  <Icon style={{marginBottom: "-6px", marginLeft: "6px"}}>info</Icon>
                </Tooltip>
              </TableCell>
              <TableCell>Status</TableCell>
              <TableCell/>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              data.map(item => {
                return (
                  <TableRow key={item.id}>
                    <TableCell>
                      <div>
                        {item.dateFrom && formatDateWithTime(item.dateFrom)}
                      </div>
                      <div>
                        {item.hash + "-" + item.name}
                      </div>
                    </TableCell>
                    {this.props.authGroup===Group.MANAGER && item.tester && (
                      <TableCell>
                        {item.tester.user ? (
                          <Link to={"/tester/admin/" + (item as any).tester.user.testerProfileId}>
                            {item.tester.testerFirstName + " " + item.tester.testerLastName}
                          </Link>
                        ):(
                          <div>{item.tester.testerFirstName + " " + item.tester.testerLastName}</div>
                        )}
                      </TableCell>
                    )}
                    {this.isConsultantInputManager && (
                        <TableCell>
                          <div>
                            {item?.consultantInput &&
                                `${item?.consultantInput?.user.firstName} ${item?.consultantInput?.user.lastName}`}
                          </div>
                          <div>
                            {item?.consultantInput &&
                                item?.consultantInput?.user.email}
                          </div>
                        </TableCell>
                    )}
                    <TableCell>
                      <div>
                        {item?.plannerCompanyName}
                      </div>
                    </TableCell>
                    <TableCell>
                      {
                        !!item.specialisms && extractL1SpecialismNames(item.specialisms).join(", ") + ";"
                      }
                    </TableCell>
                    <TableCell>
                      <div>
                        {!!item.dateStart && [TestStatus.STATUS_BOOKING_REQUESTED, TestStatus.STATUS_BOOKING_REQUESTED_EDITED].indexOf(item.status as number) === -1 ? (
                          <b>{formatDateWithTime(item.dateStart)}</b>
                        ) : <span>No&nbsp;proposal</span>}
                      </div>
                    </TableCell>
                    <TableCell>
                      {item.testStatisticData.unAnsweredQuestionsCountForTester === 0 && (
                        <React.Fragment>
                          <IconButton disabled={true}>
                            <Icon>textsms</Icon>
                            0
                          </IconButton>
                        </React.Fragment>
                      )}
                      {item.testStatisticData.unAnsweredQuestionsCountForTester > 0 &&
                      <HashLink to={`/tester/booking-requests/${item.id}#questions`}>
                        <IconButton disabled={false}>
                          <Icon>textsms</Icon>
                          {item.testStatisticData.unAnsweredQuestionsCountForTester}
                        </IconButton>
                      </HashLink>
                      }
                    </TableCell>
                    <TableCell>
                      <div>
                        {item.status && <StatusBlock status={item.status}/>}
                      </div>
                    </TableCell>
                    <TableCell>
                      {this.getStatusAction(item)}
                    </TableCell>
                  </TableRow>
                );
              })
            }
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={totalItems}
          rowsPerPage={itemsPerPage}
          page={pageNumber - 1}
          backIconButtonProps={{
            "aria-label": "Previous Page"
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page"
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Paper>
    );
  }
}

const routered = withRouter(BookingRequestsList);

export default connect((state: IState) => {
  return {
    currentUser: state.auth,
    data: selector.getItems(state),
    isLoaded: selector.getIsLoaded(state),
    itemsPerPage: selector.getItemsPerPage(state),
    pageNumber: selector.getPageNumber(state),
    showStatus: selector.getFilterActivityStatus(state),
    totalItems: selector.getTotalItems(state),
    filterText: selector.getFilterTextFields(state),
    searchValues: searchFormSelector(state, "project", "status", "dateFrom", "dateTo", "questionAsked"),
    authGroup: state.auth.group
  };
}, {
  loadItems: getBookingRequestsAction,
  setItemsPerPage: testerBookingRequestsLoader.setItemsPerPage,
  setPageNumber: testerBookingRequestsLoader.setPageNumber,
  archiveItem: testerBookingRequestsRequest.archiveItem
})(routered);
