import * as React from 'react';
import Select from '@material-ui/core/Select/Select';
import Chip from '@material-ui/core/Chip/Chip';
import {InputLabel, FormControl, FormHelperText, WithStyles} from "@material-ui/core";
import {withStyles} from '@material-ui/core/styles';
import {WrappedFieldProps} from "redux-form/lib/Field";
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import TextField from '@material-ui/core/TextField/TextField';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import Icon from '@material-ui/core/Icon/Icon';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';
import {connect} from "react-redux";
import {FORM_SEARCH_FIELD_SET} from "../../constants/action-type";
import {createAction} from "redux-actions";
import {IAnyProps} from "../../interfaces/any-props.interface";
import {getFormSearchField} from "../../selectors/form-search-fields";
import {ISpecialism} from "../../models/specialism.interface";
import {find, difference, uniq, findIndex} from 'lodash';
import {ILocation} from "../../models/location.interface";

class ReduxSpecialismsField extends React.Component<WrappedFieldProps & {
  [key: string]: any,
  searchText: string,
  items: ISpecialism[],
} & WithStyles<any>> {
  handleChangeParent = (event): any => {
    this.props.input.onChange(event.target.value);
  };

  getSpecialismByKey: (id: number) => ISpecialism = (id) => find(this.props.items, {id}) as ISpecialism
    || find<any>(this.props.items, {subTypes: [{id}]} as any) as ISpecialism;

  getSubtypeNameByKey = (key: number) => {
    const specialism = this.getSpecialismByKey(key);
    if(specialism) {
      return (specialism.id !== 1) ? specialism.subTypes!.find(item => item.id === key).name 
                                   : specialism.name; 
    }
  }

  renderValue = (key: number) => {
    const initialValue = this.props.input.value[0];
    const initialSpecialism = this.getSubtypeNameByKey(initialValue);
    const keySpecialism = this.getSubtypeNameByKey(key);

    return (
      <div>
        {
          keySpecialism || (initialValue && initialSpecialism)
        }
      </div>)
  };
  
  render() {
    const {input, label, meta: {touched, error}, classes, renderItem, items, ...custom} = this.props;

    const close = (event) => {
      event.stopPropagation();
    };

    const handleSearchChange = (event) => {
      this.props.setLocationSearchText(event.target.value);
    };

    const filter = (item: any) => {
      return (item.name || '').toLocaleLowerCase().indexOf(this.props.searchText.toLocaleLowerCase()) !== -1;
    };

    return (
      <FormControl
        className={classes.fullWidth}
        error={touched && error}>
        <InputLabel htmlFor="select-multiple" error={touched && error}>{label}</InputLabel>
        <Select
          className={classes.fullWidth}
          fullWidth={true}
          {...input}
          renderValue={this.renderValue}
          autoWidth
          onChange={this.handleChangeParent}
          {...custom}
        >
          <MenuItem
            key={'Search'}
            onClick={close}
          >
            <TextField
              value={this.props.searchText}
              onChange={handleSearchChange}
              onClick={close}
              fullWidth={true}/>
          </MenuItem>
          {
            items.filter(filter).map((option: ISpecialism) => {
              const items: IAnyProps[] = [];

              if (option.id === 1) {
                items.push(
                <MenuItem
                  key={option.id}
                  value={option.id}
                  onClick={close}
                >
                  <ListItemText primary={option.name}/>
                  {
                    option.description &&
                    <Tooltip disableFocusListener disableTouchListener title={option.description}>
                      <Icon>info</Icon>
                    </Tooltip>
                  }
                </MenuItem>)
              } else if (option.subTypes && option.subTypes.length !== 0) {
                items.push(
                  <MenuItem
                    key={option.id}
                    className="d-flex align-items-center justify-content-between no-opacity"
                    disabled
                  >
                    <h6 className="m-0">{option.name}</h6>
                    {
                      option.description &&
                      <Tooltip disableFocusListener disableTouchListener title={option.description}>
                        <Icon>info</Icon>
                      </Tooltip>
                    }
                  </MenuItem>)
              }

              if (option.subTypes && option.subTypes.length) {
                return [...items, option.subTypes.filter(filter).map(subType => (
                  <MenuItem
                    onClick={close}
                    key={subType.id}
                    value={subType.id}
                  >
                    <ListItemText className="ml-3" primary={subType.name}/>
                    {
                      subType.description &&
                      <Tooltip disableFocusListener disableTouchListener title={subType.description}>
                        <Icon>info</Icon>
                      </Tooltip>
                    }
                  </MenuItem>
                ))]
              }

              return items;
            })
          }
        </Select>
        {
          touched && error ?
            <FormHelperText>{error}</FormHelperText> :
            null
        }
      </FormControl>
    );
  }
}

const getFieldName = (ownProps: IAnyProps) => `${ownProps.meta.form}_${ownProps.input.name}`

const styled = withStyles({
  fullWidth: {
    width: '100%',
  },
})(ReduxSpecialismsField as any);

const mapStateToProps = (state, ownProps: IAnyProps) => {
  return {
    searchText: getFormSearchField(getFieldName(ownProps))(state),
  };
};

export default connect(mapStateToProps, (dispatch, ownProps: IAnyProps) => {
  return {
    setLocationSearchText: (text) => dispatch(createAction(FORM_SEARCH_FIELD_SET, () => {
      return {
        value: text,
        key: getFieldName(ownProps),
      };
    })())
  };
})(styled);