import React from "react";
import { DayCellProps } from "types/timesheets-and-expenses";
import { DayTimeExpensesLog } from "./DayTimeExpensesLog";
import {
  getConsultantViewTimeLogLink,
  getCreateTimeLogLink,
  getEditTimeLogLink
} from "helpers/router-helper";
import {
  CellCreateButton,
  CellEditButton,
  CellViewButton
} from "./CellActionButton";
import {
  consultantCanCreateDay,
  consultantCanEditDay,
  consultantCanViewDay
} from "helpers/timesheets-expenses";

const ConsultantDayCell = (props: DayCellProps) => {
  const { days, expensesAmount, id, day, status, vat } = props;
  const dayTimeExpensesLog = !!days || !!expensesAmount;
  if (dayTimeExpensesLog && consultantCanEditDay(status)) {
    return (
      <div className="timesheet-day-cell-wrap">
        <DayTimeExpensesLog days={days} expenses={expensesAmount} vat={vat} />
        <CellEditButton to={`${getEditTimeLogLink()}/${id}`} />
      </div>
    );
  }
  if (!dayTimeExpensesLog && consultantCanCreateDay(status)) {
    return (
      <div className="timesheet-day-cell-wrap">
        <CellCreateButton to={`${getCreateTimeLogLink()}/${day}`} />
      </div>
    );
  }
  if (dayTimeExpensesLog && (consultantCanViewDay(status))) {
    return (
      <div className="timesheet-day-cell-wrap">
        <DayTimeExpensesLog days={days} expenses={expensesAmount} vat={vat} />
        <CellViewButton to={`${getConsultantViewTimeLogLink()}/${id}`} />
      </div>
    );
  }
  return null;
};

export default ConsultantDayCell;
