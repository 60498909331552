import * as React from "react";
import { AdditionalServicesUpdateListElement } from "./AdditionalServicesUpdateListElement";
import { WrappedFieldArrayProps } from "redux-form/lib/FieldArray";
import { IManagedServices } from "../../../models/managed-services.interface";

interface IAdditionalServicesProps {
  level: number;
}

export const AdditionalServicesUpdateList: React.SFC<
  WrappedFieldArrayProps<IManagedServices> & IAdditionalServicesProps
> = ({ fields, level = 0 }) => {
  return (
    <ul className="specialisms-list">
      {fields.map((member, index) => {
        return (
          <AdditionalServicesUpdateListElement
            key={index}
            fields={fields}
            index={index}
            level={level}
            member={member}
          />
        );
      })}
    </ul>
  );
};
