import * as React from "react";
import {connect} from "react-redux";
import {Modal} from "@material-ui/core";
import {questionNewModalClose} from "../../../actions/modals";
import {getIsOpenModalQuestionNew} from "../../../selectors/modal";
import {FieldGeneric} from "../../../components/forms/FieldGeneric";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {reduxForm, InjectedFormProps} from "redux-form";
import ReduxTextField from "../../../components/forms/ReduxTextField";
import {questionNewModalSubmit} from "../../../actions/question";
import {QUESTION_NEW_FORM_NAME} from "../../../constants/forms";
import {composeValidators, fieldMaxLength, fieldsNotEmpty} from "../../../helpers/validations/validation";

interface IStateToProps {
  isOpenModal: any;
}

interface IDispatchToProps {
  modalClose: () => any;
  modalSubmit: (idTest, question) => any;
}

class QuestionNewModal extends React.Component<IStateToProps
  & IDispatchToProps
  & RouteComponentProps<any>
  & InjectedFormProps<any>> {
  render() {
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.props.isOpenModal}
        onClose={this.handleClose}
        className="d-flex overflow-auto"
      >
        <div className="styled-modal sm">
          <h4>New Question</h4>
          <hr/>
          <form noValidate autoComplete="off" onSubmit={this.props.handleSubmit((values) => {
            this.props.modalSubmit(this.props.match.params.test, values.question.trim());
          })}>
            <FieldGeneric
              name="question"
              rows={4}
              multiline={true}
              label="Question *"
              margin="normal"
              component={ReduxTextField}
            />
            <div className="text-right">
              <button onClick={this.handleClose} className="btn-cancel">Cancel</button>
              <button type="submit" className="btn-save">Save</button>
            </div>
          </form>
        </div>
      </Modal>
    );
  }

  private readonly handleClose = () => {
    this.props.modalClose();
    this.props.untouch("question");
    this.props.change("question", "");
  };
}

function mapStateToProps(state) {
  return {
    isOpenModal: getIsOpenModalQuestionNew(state),
  };
}

const connectedComponent = connect<IStateToProps, IDispatchToProps>(mapStateToProps, {
  modalClose: questionNewModalClose,
  modalSubmit: questionNewModalSubmit,
})(QuestionNewModal);

const formed = reduxForm({
  form: QUESTION_NEW_FORM_NAME,
  destroyOnUnmount: false,
  validate: composeValidators(
    fieldsNotEmpty(["question"]),
    fieldMaxLength(["question"], 2000),
  ),
})(connectedComponent);

const routered = withRouter(formed as any);

export default withRouter(routered as any) as any;
