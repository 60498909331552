import {convertorFromSwagger, convertorToSwagger} from '../convertors/test';
import * as FilterActions from '../../constants/filter';
import {fetchRequestBody, extractJSONLD} from '../../helpers/request-helper';
import {getApi, getApiJSONLD} from '../swagger/api-factory';
import {IAnyProps} from "../../interfaces/any-props.interface";
import {ITest} from "../../models/test.interface";
import {TestStatus} from "../../constants/test-status";
import test from "../../reducers/test";

export const getItems = (page: number, itemsPerPage: number, show: string, text: string = '') => {
  const isShow = show || FilterActions.DEFAULT_FILTER;
  const parameters: IAnyProps = {page};

  parameters.items = itemsPerPage;
  parameters.show = isShow;

  if (text) {
    parameters.question = text;
  }

  return getApi()
    .then(api => api.getTesterProfileCollection(parameters))
    .then(extractJSONLD(convertorFromSwagger));
};

export const getTestersFilteredRequest = (page: number,
                                          itemsPerPage: number,
                                          show: string,
                                          testId = 0) => {
  const isShow = show || FilterActions.DEFAULT_FILTER;
  const parameters: IAnyProps = {$queryParameters: {page}};

  parameters.items = itemsPerPage;
  parameters.show = isShow;

  if (testId) {
    parameters.testId = testId;
  }

  return getApi()
    .then(api => api.getTesterProfileCollection(parameters))
    .then(extractJSONLD(convertorFromSwagger));
};

export const getItem = (id: string | number) => {
  return getApi()
    .then(api => api.getTesterProfileItem({id}))
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};

export const postItem = (model) => {
  return getApiJSONLD()
  .then(api => api.postTesterProfileCollection({
    testerProfile: model,
  }))
  .then(fetchRequestBody)
  .then(convertorFromSwagger);
};

export const putItem = (model) => {
  model.externalCompany = model.externalCompany || null;

  return getApiJSONLD()
  .then(api => api.putTesterProfileItem({
    id: model.id,
    testerProfile: model,
  }))
  .then(fetchRequestBody)
  .then(convertorFromSwagger);
};

export const bookTesterRequest = (testId, testerId) => {
  const test: Partial<ITest> = {
    tester: testerId,
    status: TestStatus.STATUS_BOOKING_REQUESTED,
  };

  return getApi()
    .then(api => api.putTestItem({
      id: testId,
      test: convertorToSwagger(test),
    }))
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};
