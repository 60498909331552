import { useEffect, useState } from "react";
import moment from "moment";
import { ReportedWeek } from "models/report-day.interface";

export type Day = {
  date: Date;
  report?: ReportedWeek;
};

export type Week = {
  key: number;
  yearWeek: string;
  days: Date[];
};

export function useWeeks(selectedDate: Date) {
  const [weeks, setWeeks] = useState<Week[]>([]);

  useEffect(() => {
    const currentYear = selectedDate.getFullYear();
    const currentMonth = selectedDate.getMonth();

    const firstDayOfMonth = new Date(currentYear, currentMonth, 1);
    const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0);

    const startDate = new Date(firstDayOfMonth);
    if (startDate.getDay() !== 1) {
      // 1 - monday
      startDate.setDate(startDate.getDate() - ((startDate.getDay() + 6) % 7));
    }

    const endDate = new Date(lastDayOfMonth);
    if (endDate.getDay() !== 0) {
      // 0 - sunday
      endDate.setDate(endDate.getDate() + (7 - endDate.getDay()));
    }

    const currentDate = new Date();
    const currentDayOfWeek = currentDate.getDay();
    const startOfCurrentWeek = new Date(currentDate);
    startOfCurrentWeek.setDate(currentDate.getDate() - currentDayOfWeek);
    const endOfCurrentWeek = new Date(currentDate);
    endOfCurrentWeek.setDate(currentDate.getDate() + (7 - currentDayOfWeek));
    if (endDate > currentDate) {
      endDate.setFullYear(endOfCurrentWeek.getFullYear());
      endDate.setMonth(endOfCurrentWeek.getMonth());
      endDate.setDate(endOfCurrentWeek.getDate());
    }

    const weeksArray: Week[] = [];
    let currentWeek: Date[] = [];

    for (
      const day = startDate;
      day <= endDate;
      day.setDate(day.getDate() + 1)
    ) {
      currentWeek.push(new Date(day));

      if (day.getDay() === 0) {
        // 0 - sunday
        const momentDay = moment(day);
        weeksArray.push({
          key: momentDay.isoWeek(),
          days: currentWeek,
          yearWeek: `${momentDay.year()}-${momentDay
            .isoWeek()
            .toString()
            .padStart(2, "0")}`
        });
        currentWeek = [];
      }
    }

    if (currentWeek.length > 0) {
      const date = moment();
      weeksArray.push({
        key: date.isoWeek(),
        yearWeek: `${date.year()}-${date
          .isoWeek()
          .toString()
          .padStart(2, "0")}`,
        days: currentWeek
      });
    }

    setWeeks(weeksArray.sort((a, b) => (a.key < b.key ? b.key : a.key)).reverse());
  }, [selectedDate]);

  return weeks;
}
