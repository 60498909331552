import * as React from "react";
import {Paper, WithStyles, withStyles} from "@material-ui/core";
import {withRouter} from "react-router-dom";
import {styles} from "../../../styles/material/tesrer-card";
import {ITesterIncoming, ITesterProfile, ITesterProfileGet} from "../../../models/tester-profile.interface";
import {getProfile} from "../../../selectors/tester-profile";
import {getProfileFromJWT} from "../../../helpers/jwt-checker";
import {logOut} from "../../../actions/common";
import {createLoaderActionItem} from "../../../interfaces/reducer-item.interface";
import * as actionType from "../../../constants/action-type";
import {dataFetcher} from "../../../components/dataFetcher";
import {ICountry} from "../../../models/dialing-code.interface";
import {replaceNToBr} from "../../../helpers/converter-helper";
import {RawHTML} from "../../../components/RawHTML";
import {IState} from "../../../interfaces/state.interface";
import {isInputConsultant} from "../../client-tester/test-details/BookingProposal";

interface IProps {
  countries: ICountry[];
  profile: ITesterProfile & ITesterIncoming & ITesterProfileGet;

  [key: string]: any;
}

class ExternalProfileCard extends React.Component<IProps & IState & WithStyles> {
  baseUrl = this.props.match.path;

  render() {
    const {
      profile,
      auth: { roles },
    } = this.props;

    if (!profile) {
      return (
        <div>
          Loading...
        </div>
      );
    }

    return (
      <div>
        <Paper className={"p-4"}>
          <div className="d-flex justify-content-between mb-4">
            <div>
              <h4>My Profile</h4>
            </div>
          </div>
          <hr/>

          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <table className="table table-borderless">
                  <tbody>
                    <tr>
                      <th>Name</th>
                      <td>{`${profile.testerFirstName} ${profile.testerLastName}`}</td>
                    </tr>

                    {profile.user && (
                      <>
                        <tr>
                          <th>Email</th>
                          <td>{profile.user.email}</td>
                        </tr>

                        {!isInputConsultant(roles) && (
                            <tr>
                              <th>Type</th>
                              <td>{profile.user.groupName}</td>
                            </tr>
                        )}
                      </>
                    )}

                    {!isInputConsultant(roles) && profile.companyName && (
                      <tr>
                        <th scope="row" className={this.props.classes.th}>
                          {"Company"}
                        </th>
                        <td>{profile.companyName}</td>
                      </tr>
                    )}

                    {!isInputConsultant(roles) && (
                      <tr>
                        <th scope="row" className={this.props.classes.th}>
                          Comment
                        </th>
                        <td>
                          <div>
                            <RawHTML>
                              {replaceNToBr(profile.bio ? profile.bio : "")}
                            </RawHTML>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Paper>
      </div>
    );
  }
}

const styled = withStyles(styles as any)(ExternalProfileCard) as any;
const componentWithRouter = withRouter(styled);

export default dataFetcher<IProps>(componentWithRouter, [
  {
    key: "profile",
    action: () => {
      const profile = getProfileFromJWT();

      if (!profile) {
        return logOut();
      } else {
        return createLoaderActionItem(actionType.TESTER_PROFILE).get();
      }
    },
    selector: (state) => getProfile(state),
    alwaysReceiveFreshData: true,
  },
]) as unknown as React.ComponentClass<{}>;
