import * as React from "react";
import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent/CardContent";
import { withStyles } from "@material-ui/core";
import { WithStyles } from "@material-ui/core/styles/withStyles";
import { noticeStyles } from "../../styles/material/notice";
import { ISpecialism } from "../../models/specialism.interface";
import { IBankDatails } from "../../models/bank-datails.interface";
import { getSpecialismsFlat } from "../../helpers/specialisms";
import certificateSelector from "../../selectors/certificate";
import { ICertificate } from "../../models/certificate.interface";
import insuranceSelector from "../../selectors/insurance";
import { IInsurance } from "../../models/insurance.interface";
import DBScertificateSelector from "../../selectors/dbs-certificate";
import { IDBSCertificate } from "../../models/dbs-certificate.interface";
import { STATUS_VALIDATION_PASSED } from "../../constants/dbs-certificate-statuses";
import { connect } from "react-redux";
import { IAnyProps } from "../../interfaces/any-props.interface";
import {
  getBankDetails,
  getTesterSpecialisms
} from "../../selectors/tester-profile";

type IProps = WithStyles<"card" | "cardContent"> & {
  specialisms: ISpecialism[];
  bankDetails: IBankDatails;
  certificates: ICertificate[];
  insurances: IInsurance[];
  dbsCertificates: IDBSCertificate[];
  isUk: boolean;
  bankDetailsIsLoaded: boolean;
  specialismsIsLoaded: boolean;
  certificatesIsLoaded: boolean;
  insurancesIsLoaded: boolean;
  dbsCertificatesIsLoaded: boolean;
};

class Notice extends React.Component<IProps & WithStyles & any, any> {
  render() {
    const {
      bankDetails,
      specialisms,
      certificates,
      insurances,
      dbsCertificates,
      isUk,
      bankDetailsIsLoaded,
      specialismsIsLoaded,
      certificatesIsLoaded,
      insurancesIsLoaded,
      dbsCertificatesIsLoaded
    } = this.props;

    const isLoading = [
      bankDetailsIsLoaded,
      specialismsIsLoaded,
      certificatesIsLoaded,
      insurancesIsLoaded,
      dbsCertificatesIsLoaded
    ].some((loader: boolean) => !loader);

    if (isLoading) {
      return null;
    }

    const dbsValid = dbsCertificates.find(
      (check: IDBSCertificate) => check.status === STATUS_VALIDATION_PASSED
    );

    if (
      bankDetails &&
      bankDetails.account_no &&
      bankDetails.sort_code &&
      getSpecialismsFlat(specialisms).length &&
      certificates.length &&
      (insurances.length >= 2 || !isUk) &&
      ((dbsCertificates.length && dbsValid) || !isUk)
    ) {
      return null;
    }

    return (
      <Card className={this.props.classes.card}>
        <CardContent className={this.props.classes.cardContent}>
          <span>
            To be listed as a tester on AVORD you must complete your profile!
          </span>
          {(!bankDetails.account_no || !bankDetails.sort_code) && (
            <span>
              <br />
              To be listed as a tester on AVORD you must complete your bank
              details!
            </span>
          )}
          {certificates.length < 1 && (
            <span>
              <br />
              To be listed as a tester on AVORD you must have at least 1 actual
              certification!
            </span>
          )}
          {insurances.length < 2 && isUk && (
            <span>
              <br />
              To be listed as a tester on AVORD you must have Public Liability
              and Indemnity insurances!
            </span>
          )}
          {(!dbsCertificates.length || !dbsValid) && isUk && (
            <span>
              <br />
              To be listed as a tester on AVORD you must have DBS check with
              status "Validation passed"!
            </span>
          )}
          {insurances.length < 1 && isUk && (
            <span>
              <br />
              To be listed as a tester on AVORD you must have valid insurances!
            </span>
          )}
        </CardContent>
      </Card>
    );
  }
}

const componentWithStyle = withStyles<"card" | "cardContent">(noticeStyles)(
  Notice
);

export default connect<IAnyProps, IAnyProps, IAnyProps>(state => {
  const bankDetailsState = getBankDetails(state);
  const specialismsState = getTesterSpecialisms(state);
  return {
    bankDetails: bankDetailsState.item,
    bankDetailsIsLoaded: bankDetailsState.loaded,
    specialisms: specialismsState.item,
    specialismsIsLoaded: specialismsState.loaded,
    certificates: certificateSelector.getItems(state),
    certificatesIsLoaded: certificateSelector.getIsLoaded(state),
    insurances: insuranceSelector.getItems(state),
    insurancesIsLoaded: insuranceSelector.getIsLoaded(state),
    dbsCertificates: DBScertificateSelector.getItems(state),
    dbsCertificatesIsLoaded: DBScertificateSelector.getIsLoaded(state)
  };
})(componentWithStyle);
