import * as React from 'react';
import ReduxTextField from "../../../../components/forms/ReduxTextField";
import {FieldGeneric} from "../../../../components/forms/FieldGeneric";
import Grid from "@material-ui/core/Grid/Grid";
import Button, {ButtonProps} from "@material-ui/core/Button/Button";
import {Link} from "react-router-dom";
import {IBackRoute} from "../../../../components/routerModal";

const ApplicationSettingForm: React.SFC<IBackRoute> = ({backRoute}) => {
  return (
    <Grid container spacing={40}>
      <Grid item xs={12}>
        <FieldGeneric
          name="message"
          label="Message *"
          rows={6}
          multiline={true}
          component={ReduxTextField}
        />
        <br/>
        <div className="d-flex justify-content-between mt-4">
          <Button variant="contained"
                  color="default"
                  component={Link as React.ReactType<ButtonProps>}
                  {...{to: backRoute}}>
            Cancel
          </Button>
          <Button type="submit"
                  variant="contained"
                  color="primary">
            Save
          </Button>
        </div>
      </Grid>
    </Grid>
  )
};


export default ApplicationSettingForm;
