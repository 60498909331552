import * as Types from '../constants/action-type';
import {
  createCrudAction,
  createRequestAction
} from '../helpers/action-helper';
import {openSnackbar} from "./snackbar";
import {setRequestInProcess} from "./request";
import {reset} from "redux-form";
import {handleHttpErrors} from "../helpers/errors/http-error-handler";
import {putProposalRequest, postProposalRequest} from "../api/requests/proposal";
import {changeTestStatus} from "../api/requests/test";
import {TestStatus} from "../constants/test-status";
import {go} from "react-router-redux";

export const createProposal = (testId, model, formName, callback?: (dispatch) => void) => async dispatch => {
  dispatch(setRequestInProcess(true, 'updateProposal'));

  let data;

  try {
    data = await postProposalRequest(model);

    dispatch(openSnackbar('Created', 4000));
    dispatch(createCrudAction(Types.PROPOSAL).addItem(data));
    dispatch(reset(formName));

    await changeTestStatus(testId, TestStatus.STATUS_PROPOSAL_SUBMITTED);

    dispatch(createRequestAction(Types.TEST).getItem(testId));

    if (callback) {
      callback(data);
    }
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(setRequestInProcess(false, 'createProposal'));
};

export const updateProposal = (id, model, formName, backRoute, callback?: (dispatch) => void) => async dispatch => {
  dispatch(setRequestInProcess(true, 'updateProposal'));

  let data;

  try {
    data = await putProposalRequest(id, model);

    dispatch(openSnackbar('Updated', 4000));
    dispatch(createCrudAction(Types.PROPOSAL).addItem(data));
    dispatch(reset(formName));
    dispatch(go(backRoute));

    if (callback) {
      callback(data);
    }
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(setRequestInProcess(false, 'updateProposal'));
};