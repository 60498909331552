import * as React from 'react';
import Paper from "@material-ui/core/Paper";
import {connect} from 'react-redux';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import selector from '../../../../selectors/faq';
import {Field, formValueSelector, InjectedFormProps, reduxForm, reset} from 'redux-form';
import {faqFilter} from "../../../../actions/faq";
import ReduxRadioButton from '../../../../components/forms/ReduxRadioButton';
import * as FilterTypes from '../../../../constants/filter';
import {bindActionCreators} from 'redux';
import ReduxTextField from "../../../../components/forms/ReduxTextField";
import {FieldGeneric} from '../../../../components/forms/FieldGeneric';
import {isChangesExist} from "../../../../helpers/props-checker";
import {Button} from "@material-ui/core";


const FORM_NAME = 'FaqSearchForm';
const selectorForm = formValueSelector(FORM_NAME);

class FaqSearchForm extends React.Component<InjectedFormProps<{}, {} > & {
  submitFilterChanges: (...any) => void,
  valuesData: {active: string, text: string},
}, any> {
  componentWillReceiveProps(nextProps) {
    if (isChangesExist(['valuesData'], nextProps, this.props)) {
      this.props.submitFilterChanges(nextProps.valuesData.active, nextProps.valuesData.text);
    }
  }

  render() {
    const {handleSubmit, reset} = this.props;

    return (
      <form
        className="mb-3"
        onSubmit={handleSubmit((values: {active: string, text: string}) => {
          this.props.submitFilterChanges(values.active, values.text);
        })}>
        <Paper className="pl-4 pr-4 pb-4">
          <div className="row">
            <div className="col-md-12">
              <Field
                name="text"
                label="Find"
                margin="normal"
                fullWidth
                component={ReduxTextField}
              />
            </div>
            <div className="col-md-12">
              <Button className="float-right mt-2" variant="contained" color="primary" onClick={reset}>Reset Filter</Button>
              <FieldGeneric
                name="active"
                margin="normal"
                fullWidth
                inline={true}
                component={ReduxRadioButton}
              >
                <FormControlLabel
                  value={FilterTypes.ACTIVE}
                  control={<Radio/>}
                  label="Active"/>
                <FormControlLabel
                  value={FilterTypes.ARCHIVED}
                  control={<Radio/>}
                  label="Archived"/>
                <FormControlLabel
                  value={FilterTypes.ALL}
                  control={<Radio/>}
                  label="All"/>
              </FieldGeneric>
            </div>
          </div>
        </Paper>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    valuesData: { ...selectorForm(state, 'text', 'active') },
    initialValues: {
      text: selector.getFilterTextFields(state),
      active: selector.getFilterActivityStatus(state),
    },
  };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    submitFilterChanges: faqFilter.submitFilterChanges,
  }, dispatch);
};

const connectForm = reduxForm({
  form: FORM_NAME,
})(FaqSearchForm);

export default connect(mapStateToProps, mapDispatchToProps)(connectForm);
