import {getApi, getApiJSON, getApiJSONLD} from '../swagger/api-factory';
import {extractJSONLD} from "../../helpers/request-helper";
import {convertorFromSwagger, convertorToSwagger} from "../convertors/default-convertor";
import {IAnyProps} from "../../interfaces/any-props.interface";

export const getItems = (userFilter) => {
  const parameters: IAnyProps = {};

  if(userFilter) {
    parameters.userFilter = userFilter;
  }

  return getApiJSONLD()
    .then(api => api.getUserCollection(parameters))
    .then(extractJSONLD(convertorFromSwagger));
};