import * as React from 'react';
import TextField from "@material-ui/core/TextField/TextField";
import {WrappedFieldProps} from "redux-form/lib/Field";
import {ComponentType} from "react";

const ReduxTextPlaceholder: ComponentType<WrappedFieldProps & {
  [key: string]: any
}> = ({input, label, converter, meta: {touched, error}, id, ...custom},) => {
  return (
    <TextField
      fullWidth={true}
      helperText={touched && error ? error : ''}
      disabled={true}
      {...input}
      {...custom}
      value={converter ? converter((input.value || [])) : input.value}
      label={label}
    />
  )
};

export default ReduxTextPlaceholder;
