import * as React from "react";
import Select from "@material-ui/core/Select/Select";
import {InputLabel, FormControl, FormHelperText} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import {WrappedFieldProps} from "redux-form/lib/Field";
import {ComponentType} from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 9 + ITEM_PADDING_TOP
    }
  }
};

const ReduxSelectField: ComponentType<WrappedFieldProps & {
  [key: string]: any
}> = ({input, label, meta: {touched, error}, children, classes, ...custom}) => {
  const onChange = (event) => {
    input.onChange(event.target.value);
  };

  return (
    <FormControl
      className={classes.fullWidth}
      margin="normal"
      error={touched && error}
    >
      <InputLabel htmlFor="select-multiple" error={touched && error}>{label}</InputLabel>
      <Select
        MenuProps={MenuProps}
        className={classes.fullWidth}
        fullWidth={true}
        {...input}
        onChange={onChange}
        {...custom}
      >
        {children}
      </Select>
      {
        touched && error ?
          <FormHelperText>{error}</FormHelperText> :
          null
      }
    </FormControl>
  );
};

export default withStyles({
  fullWidth: {
    width: "100%",
    color: "#3a3a3a"
  }
})(ReduxSelectField);
