import * as React from "react";
import QuestionSearch from "./QuestionSearch";
import QuestionTable from "./QuestionTable";
import {Grid} from "@material-ui/core";
import BasePageTitle from "../../common/BasePageTitle";

function QuestionsBlock() {
  return (
    <div className="styled-block" id={"questions"}>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <BasePageTitle title="Questions" text="New Question" />
        </Grid>

        <Grid item xs={12} className="mt-2">
          <QuestionSearch/>
        </Grid>

        <Grid item xs={12} className="mt-2">
          <QuestionTable/>
        </Grid>
      </Grid>
    </div>
  );
}

export default QuestionsBlock;