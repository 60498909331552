import * as React from 'react';
import { TestStatus } from "constants/test-status";

interface IOwnProps {
  currentStatus: TestStatus;
  requiredStatuses: TestStatus[];
}

class TestStatusBlock extends React.Component<IOwnProps, {}> {
  render() {
    const { requiredStatuses, currentStatus } = this.props;

    if (!requiredStatuses.includes(currentStatus)) {
      return null;
    }

    return this.props.children;
  };
}

export default TestStatusBlock;
