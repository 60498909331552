import {Observable} from 'rxjs';
import {catchError, flatMap, switchMap} from 'rxjs/operators';
import {ActionsObservable} from 'redux-observable';
import * as actionType from '../constants/action-type';
import {setRequestInProcess} from '../actions/request';
import {of} from "rxjs/internal/observable/of";
import {fromPromise} from "rxjs/internal-compatibility";
import {openSnackbar} from "../actions/snackbar";
import {IActionPayload} from "./index";
import {startEndCatch} from "./pipes/errors";
import {clientAccountModalClose} from "../actions/modals";
import {requestClientAccountUpdate} from "../api/requests/clients";
import {fetchMyData} from "../api/requests/auth";
import {setPermissionsAction} from "../actions/common";

export const clientAccountSubmitEpic = (action$: ActionsObservable<IActionPayload<{
  firstName: string,
  lastName: string,
  email: string,
  phone: string
}>>): Observable<any> => {
  return action$
    .ofType(actionType.CLIENT_ACCOUNT_MODAL_SUBMIT)
    .pipe(
      switchMap(action => {
        return fromPromise(requestClientAccountUpdate(
          action.payload.firstName,
          action.payload.lastName,
          action.payload.email,
          action.payload.phone
        ))
          .pipe(
            switchMap(data => fromPromise(fetchMyData())),
            flatMap((permissions) => {
              return [
                setPermissionsAction(permissions),
                openSnackbar('Your profile has been updated', 4000),
                clientAccountModalClose(),
              ];
            }),
            ...startEndCatch('clientAccountSubmitEpic'),
          );
      }),
      catchError((error) => {
        return of(setRequestInProcess(false, 'clientAccountSubmitEpic'));
      })
    );
};
