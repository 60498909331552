import * as React from 'react';
import PrivateRoute from './PrivateRoute';
import { ROLE_GUEST } from 'constants/roles';

const GuestRoute: React.FC<{ path: string, component: React.ReactNode }> = (props) => {
  return (
    <PrivateRoute
      role={ROLE_GUEST}
      redirectPath="/"
      {...props} />
  );
};

export default GuestRoute;
