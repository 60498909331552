import React, { useContext } from "react";
import {
  TimeSheetExpensesContextValueType,
  TimesheetPoNumberRoute
} from "types/timesheets-and-expenses";
import { CellCreateButton, CellEditButton } from "./CellActionButton";
import {
  getAdminTimesheetPoNumberEdit,
  getClientTimesheetPoNumberEdit
} from "helpers/router-helper";
import { TE_STATUS } from "constants/timesheet-expenses";
import { TimeSheetExpensesContext } from "../../context/timeSheetsContext";

type PoNumberCellProps = {
  statusId: TE_STATUS;
  weekId: number;
  type: TimesheetPoNumberRoute;
  poNumberValue: string | null;
  hasTotalDays: boolean;
  hasTotalExpenses: boolean;
};

const PoNumberCell = ({
  statusId,
  weekId,
  type,
  poNumberValue,
  hasTotalDays,
  hasTotalExpenses
}: PoNumberCellProps) => {
  const { isAdmin, isConsultant, canAdminManageTimeExpenses = false } = useContext<
    TimeSheetExpensesContextValueType
  >(TimeSheetExpensesContext);
  const shouldHidePoNumber =
    type === TimesheetPoNumberRoute.PO_NUMBER && !hasTotalDays;
  const shouldHideExpensesPoNumber =
    type === TimesheetPoNumberRoute.EXPENSES_PO_NUMBER && !hasTotalExpenses;

  if (isConsultant && (shouldHidePoNumber || shouldHideExpensesPoNumber)) {
    return null;
  }

  if (isConsultant) {
    return poNumberValue ? (
      <span className="timesheet-po-number-cell-value">{poNumberValue}</span>
    ) : null;
  }
  if (shouldHidePoNumber || shouldHideExpensesPoNumber) {
    return <span>-</span>;
  }

  const ActionButton = poNumberValue ? CellEditButton : CellCreateButton;
  const redirectUrlFn = isAdmin
    ? getAdminTimesheetPoNumberEdit
    : getClientTimesheetPoNumberEdit;
  const redirectUrl = `${redirectUrlFn(type)}/${weekId}`;

  if (isAdmin && !canAdminManageTimeExpenses) {
    return <div className="timesheet-po-number-cell-wrap">
      <span className="timesheet-po-number-cell-value">{poNumberValue}</span>
    </div>
  }

  return (
    <div className="timesheet-po-number-cell-wrap">
      <span className="timesheet-po-number-cell-value">{poNumberValue}</span>
      {statusId !== TE_STATUS.STATUS_INVOICED && (
        <ActionButton to={redirectUrl} />
      )}
    </div>
  );
};

export default PoNumberCell;
