import React, {PropsWithChildren, useCallback} from "react";
import {downloadFile} from "./helper";
import {Converter} from "./config";

type Props<T> = PropsWithChildren<{
    disabled?: boolean,
    converter: Converter,
    fileName: string;
}>

export function ExportRiskChartButton<T>({disabled, children, fileName, converter}: Props<T>) {
    const onClickHandler = useCallback(() => {
        downloadFile(converter.convert(), fileName, 'application/json')
    }, [converter]);

    return <button
        disabled={disabled}
        className="btn-export ml-4"
        onClick={onClickHandler}
    >
        {children}
    </button>
}
