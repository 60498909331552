import {setRequestInProcess} from "./request";
import {openSnackbar} from "./snackbar";
import {getInvoiceURL} from "../api/requests/test";

export const goToXeroInvoiceDetails = (testId: number) => async dispatch => {
  dispatch(setRequestInProcess(true, 'goToXeroInvoiceDetails'));

  let data;

  try {
    data = await getInvoiceURL(testId);
    window.location.href = data.xeroInvoiceUrl;
  } catch (e) {
    dispatch(openSnackbar('Something went wrong while getting invoice URL', 4000));
  }

  dispatch(setRequestInProcess(false, 'goToXeroInvoiceDetails'));

};