import Tooltip from '@material-ui/core/Tooltip';
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { formValueSelector, reduxForm } from "redux-form";
import { Checkbox, ListItemText, MenuItem } from "@material-ui/core";
import { FieldGeneric } from "components/forms/FieldGeneric";
import {
  isOptionSelected,
  joinSelectedOptionsLabels
} from "helpers/timesheets-expenses";
import { ConsultantDataType, ConsultantProjects } from "types/consultant";
import { ProjectSelectOptionType, SelectOptionType } from "types";
import consultantSelector from "selectors/consultants";
import {
  TIME_SHEETS_STATUSES,
  TIME_SHEETS_SEARCH_FORM
} from "constants/timesheet-expenses";
import ReduxYearMonthPicker from "components/forms/YearMonthPicker/ReduxYearMonthPicker";
import ReduxSelectSearchField from "components/forms/ReduxSelectSearchField";
import { RootState } from "reducers";

const searchFormSelector = formValueSelector(TIME_SHEETS_SEARCH_FORM);

type SearchFormProps = {
  projectsSelected?: number[];
  companiesSelected?: number[];
  consultantsSelected?: number[];
  statusesSelected?: number[];
  change: (field: string, value: unknown) => void;
  reset: () => void;
  allProjects?: ProjectSelectOptionType[];
  allConsultants?: SelectOptionType[];
  allCompanies?: SelectOptionType[];
};

const SearchForm = (props: SearchFormProps) => {
  const {
    reset,
    projectsSelected = [],
    companiesSelected = [],
    statusesSelected = [],
    consultantsSelected = [],
    change,
    allProjects = [],
    allConsultants = [],
    allCompanies = []
  } = props;
  const [projectsOfSelectedClients, setProjectsOfSelectedClients] = useState<
    ProjectSelectOptionType[]
  >([]);
  const isProjectsHidden = !companiesSelected.length;

  useEffect(() => {
    const projectsList = allProjects.filter(project =>
      companiesSelected.includes(project.companyId)
    );

    const uniqueProjectsList = Array.from(
      new Set(projectsList.map(project => project.id))
    ).map(id => projectsList.find(project => project.id === id));

    setProjectsOfSelectedClients(uniqueProjectsList.filter((project): project is ProjectSelectOptionType => project !== undefined));
  }, [companiesSelected, allProjects]);

  useEffect(() => {
    const updatedSelectedProjects = projectsSelected.filter(projectId =>
      projectsOfSelectedClients.some(project => project.id === projectId)
    );
    change("projects", updatedSelectedProjects);
  }, [projectsOfSelectedClients, projectsSelected]);

  return (
    <form>
      <div className="table-responsive styled-table styled-block">
        <div className="timesheet-search-form__wrap">
          <div
            className="timesheet-search-form__item"
            style={{ marginTop: "auto" }}
          >
            <FieldGeneric
              name="yearMonth"
              label="Search by MM/YYYY"
              component={ReduxYearMonthPicker}
              margin="dense"
            />
          </div>
          <div className="timesheet-search-form__item">
            <FieldGeneric
              name="companies"
              label="Client"
              fullWidth
              multiple
              component={ReduxSelectSearchField}
              items={allCompanies}
              renderItem={(option: SelectOptionType) => (
                <MenuItem key={option.id} value={option.id}>
                  <Checkbox
                    checked={isOptionSelected(companiesSelected, option.id)}
                  />
                  <ListItemText primary={option.name} />
                </MenuItem>
              )}
              renderValue={() =>
                joinSelectedOptionsLabels({
                  selectedOptionsIds: companiesSelected,
                  selectOptions: allCompanies
                })
              }
            />
          </div>
          <div className="timesheet-search-form__item">
            {isProjectsHidden ? (
              <Tooltip title="Select Client">
                <div>
                  <FieldGeneric
                    name="projects"
                    label="Projects"
                    fullWidth
                    multiple
                    disabled={!projectsOfSelectedClients.length}
                    component={ReduxSelectSearchField}
                    items={projectsOfSelectedClients}
                    renderItem={(option: SelectOptionType) => (
                      <MenuItem key={option.id} value={option.id}>
                        <Checkbox
                          checked={isOptionSelected(projectsSelected, option.id)}
                        />
                        <ListItemText primary={option.name} />
                      </MenuItem>
                    )}
                    renderValue={() =>
                      joinSelectedOptionsLabels({
                        selectedOptionsIds: projectsSelected,
                        selectOptions: projectsOfSelectedClients
                      })
                    }
                  />
                </div>
              </Tooltip>
            ) : (
              <FieldGeneric
                name="projects"
                label="Projects"
                fullWidth
                multiple
                component={ReduxSelectSearchField}
                items={projectsOfSelectedClients}
                renderItem={(option: SelectOptionType) => (
                  <MenuItem key={option.id} value={option.id}>
                    <Checkbox
                      checked={isOptionSelected(projectsSelected, option.id)}
                    />
                    <ListItemText primary={option.name} />
                  </MenuItem>
                )}
                renderValue={() =>
                  joinSelectedOptionsLabels({
                    selectedOptionsIds: projectsSelected,
                    selectOptions: projectsOfSelectedClients
                  })
                }
              />
            )}
          </div>
          <div className="timesheet-search-form__item">
            <FieldGeneric
              name="consultants"
              label="Consultant"
              fullWidth
              multiple
              component={ReduxSelectSearchField}
              items={allConsultants}
              renderItem={(option: SelectOptionType) => (
                <MenuItem key={option.id} value={option.id}>
                  <Checkbox
                    checked={isOptionSelected(consultantsSelected, option.id)}
                  />
                  <ListItemText primary={option.name} />
                </MenuItem>
              )}
              renderValue={() =>
                joinSelectedOptionsLabels({
                  selectedOptionsIds: consultantsSelected,
                  selectOptions: allConsultants
                })
              }
            />
          </div>
          <div className="timesheet-search-form__item">
            <FieldGeneric
              name="statuses"
              label="Status"
              fullWidth
              multiple
              component={ReduxSelectSearchField}
              items={TIME_SHEETS_STATUSES}
              renderItem={(option: SelectOptionType) => (
                <MenuItem key={option.id} value={option.id}>
                  <Checkbox
                    checked={isOptionSelected(statusesSelected, option.id)}
                  />
                  <ListItemText primary={option.name} />
                </MenuItem>
              )}
              renderValue={() =>
                joinSelectedOptionsLabels({
                  selectedOptionsIds: statusesSelected,
                  selectOptions: TIME_SHEETS_STATUSES
                })
              }
            />
          </div>
          <div className="timesheet-search-form__refresh">
            <button className="btn-refresh" type="button" onClick={reset}>
              Reset Filters
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

function mapStateToProps(state: RootState) {
  const allCompanies: SelectOptionType[] = [];
  const allProjects: ProjectSelectOptionType[] = [];
  const consultantsArray: ConsultantDataType[] =
    consultantSelector.getItems(state) || [];

  const consultantProjects: ConsultantProjects[] = [];

  const allConsultants: SelectOptionType[] = consultantsArray.map(
    (item: ConsultantDataType) => {
      consultantProjects.push(...(item.consultantProjects || []));
      return {
        id: +item.consultantProfile,
        name: `${item.firstName} ${item.lastName}`
      };
    }
  );

  if (consultantProjects.length > 0) {
    consultantProjects.map((item: ConsultantProjects): void => {
      if (!allProjects.find(project => project.id === item.id)) {
        allProjects.push({
          id: Number(item.project?.id),
          name: `${item?.company?.name} - ${item?.project?.name}`,
          companyId: Number(item?.company?.id)
        });
      }

      if (
        !allCompanies.find(company => company.id === Number(item?.company?.id))
      ) {
        allCompanies.push({
          id: Number(item?.company?.id),
          name: item?.company?.name || ""
        });
      }
    });
  }

  return {
    data: consultantSelector.getItems(state),
    projectsSelected: searchFormSelector(state, "projects"),
    companiesSelected: searchFormSelector(state, "companies"),
    consultantsSelected: searchFormSelector(state, "consultants"),
    statusesSelected: searchFormSelector(state, "statuses"),
    allProjects,
    allConsultants,
    allCompanies
  };
}

const connectForm = reduxForm({
  form: TIME_SHEETS_SEARCH_FORM,
  initialValues: {
    projects: [],
    companies: [],
    consultants: [],
    statuses: []
  }
})(SearchForm);

export default connect(mapStateToProps)(connectForm);
