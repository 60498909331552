import React from 'react';
import {Field} from "redux-form";
import ReduxTextField from "../../../../components/forms/ReduxTextField";
import Grid from "@material-ui/core/Grid/Grid";
import {Button} from "@material-ui/core";
import {Link} from "react-router-dom";
import { FieldGeneric } from "../../../../components/forms/FieldGeneric";
import InputAdornment from "../../../../../node_modules/@material-ui/core/InputAdornment/InputAdornment";
import ReduxImageFileField from "../../../../components/forms/ReduxImageFileField";
import {handleKeyPressOnNumberField} from "../../../../helpers/validations/numberFieldValidator";


const CountryForm = ({backRoute}) => {
  return (
    <Grid container spacing={40}>
      <Grid item xs={12}>
        <FieldGeneric
          name="image"
          label="Flag"
          component={ReduxImageFileField}
        />
        <Field
          inputProps={{ maxLength: 50 }}
          name="name"
          label="Name *"
          component={ReduxTextField}
        />
        <Field
          name="dialingCode"
          label="Dialing code *"
          type="tel"
          onKeyPress={handleKeyPressOnNumberField}
          component={ReduxTextField}
          inputProps={{ maxLength: 6, startAdornment: <InputAdornment position="start">+</InputAdornment> }}
        />
        <br/>
        <br/>
        <div className="d-flex justify-content-between mt-4">
          <Button variant="contained" color="default" component={Link} to={backRoute}>
            Cancel
          </Button>
          <Button type="submit" variant="contained" color="primary">
            Save
          </Button>
        </div>
      </Grid>
    </Grid>
  )
};

export {CountryForm};
