import * as ActionTypes from '../constants/action-type';


const specialismsWithoutReportTemplates = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.GET_SPECIALISMS_WITHOUT_REPORT_TEMPLATES:
      return state = [...action.payload];
    default:
      return state;
  }
};

export default specialismsWithoutReportTemplates;