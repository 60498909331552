import { ACTIVE, ARCHIVED, ALL } from '../constants/filter';

export const getHeaderColumns = (defaultColumns: string[], status: string): string[] => {
  switch (status) {
    case ACTIVE:
      return [
        ...defaultColumns,
        'Edit',
        'Archive',
      ];

    case ARCHIVED:
      return [
        ...defaultColumns,
        'Unarchive',
      ];

    case ALL:
      return [
        ...defaultColumns,
        'Edit',
        'Archive/Unarchive',
      ];

    default:
      return [];
  }
};
