import * as React from "react";
import ContactsModal from "../guest/ContactsModal";
import LoginModal from "../guest/LoginModal";
import ForgotPasswordModal from "../guest/ForgotPasswordModal";
import ChangePasswordModal from "../guest/ChangePasswordModal";
import ApproachesModal from "../client/ApproachesModal";
import RequestDeletionModal from "../tester/RequestDeletionModal";
import TesterAccountModal from "../tester/TesterAccountModal";
import ClientAccountModal from "../client/ClientAccountModal";
import ThankYouForRegistrationModal from "../guest/ThankYouForRegistrationModal";
import {connect} from "react-redux";
import TwoFactorInitModal from "../guest/2fa/TwoFactorInitModal";
import TwoFactorCheckModal from "../guest/2fa/TwoFactorCheckModal";

class Modals extends React.Component<{}, {}> {
  render() {
    return ([
      <ContactsModal key={1}/>,
      <LoginModal key={2}/>,
      <ForgotPasswordModal key={3}/>,
      <ChangePasswordModal key={4}/>,
      <RequestDeletionModal key={5}/>,
      <TesterAccountModal key={6}/>,
      <ApproachesModal key={7}/>,
      <ClientAccountModal key={8}/>,
      <ThankYouForRegistrationModal key={9}/>,
      <TwoFactorInitModal key={10}/>,
      <TwoFactorCheckModal key={11}/>,
    ]);
  }
}

export default connect()(Modals);
