import * as React from 'react';
import {RULES_ENGAGEMENT} from "../../../constants/content-management";
import {dataFetcher} from "../../../components/dataFetcher";
import {getItemByKey} from "../../../actions/content-management";
import {getByKey} from "../../../selectors/content-management";
import {IContentManagement} from "../../../models/content-management.interface";
import {RawHTML} from "../../../components/RawHTML";

interface IProps {
  contentManagement: IContentManagement;
}

class Guidance extends React.Component<IProps,{}> {
  render() {
    return (
      <div>
        <h1>{this.props.contentManagement.name}</h1>
        <hr/>
        <RawHTML>{this.props.contentManagement.content}</RawHTML>
      </div>
    );
  }
}

const fetched = dataFetcher(
  Guidance,
  [{
    key: 'contentManagement',
    selector: (state, props) => getByKey(RULES_ENGAGEMENT)(state),
    action: () => getItemByKey(RULES_ENGAGEMENT),
  }]
);

export default fetched as any;