import * as React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import ReduxTextField from '../../components/forms/ReduxTextField';
import {composeValidators, fieldIsEmail, fieldsNotEmpty} from '../../helpers/validations/validation';
import {withStyles, Modal, Typography, Grid, Button} from '@material-ui/core';
import {loginModalOpen, forgotPasswordModalClose, postEmail} from '../../actions';
import {getIsOpenModalForgotPassword} from "../../selectors/modal";
import {defaultStyleWidth} from '../../styles/material/popup-style';
import {handleSpaceKeyPress} from "../../helpers/validations/emptyTestFieldValidation";

const FORM_NAME = "ForgotPasswordModal";

class ForgotPasswordModal extends React.Component<any> {
  handleCancel = () => {
    this.props.forgotPasswordModalClose();
    this.props.loginModalOpen();
    this.props.reset();
  };

  render() {
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.props.isOpenModal}
        onClose={this.handleCancel}
      >
        <div className={this.props.classes.paper}>
          <Typography variant="title" component="h3" id="modal-title">
            Forgot password?
          </Typography>
          <form noValidate
                autoComplete="off"
                onSubmit={this.props.handleSubmit((values) => {
                  this.props.postEmail(values.email, FORM_NAME);
                })}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Typography paragraph={true}>
                  Please enter your email address and press the send button.
                  We will send you an email with a new password:
                </Typography>
                <Field
                  name="email"
                  label="Email"
                  type="email"
                  margin="normal"
                  component={ReduxTextField}
                  onKeyPress={handleSpaceKeyPress}
                  autoFocus
                />
                <br/><br/>
                <Button type="submit" variant="contained" color="primary" fullWidth>Send</Button>
                <Button fullWidth variant="flat" onClick={this.handleCancel}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    isOpenModal: getIsOpenModalForgotPassword(state),
  };
}

const connectedComponent = connect(mapStateToProps, {
  forgotPasswordModalClose,
  loginModalOpen,
  postEmail,
})(reduxForm({
  form: FORM_NAME,
  validate: composeValidators(
    fieldsNotEmpty(['email']),
    fieldIsEmail(['email']),
  )
})(ForgotPasswordModal));

export default withStyles(defaultStyleWidth(320))(connectedComponent);
