import * as React from "react";
import {
  composeValidators,
  fieldMaxLength,
  fieldsNotEmpty,
} from "../../../helpers/validations/validation";
import { formValueSelector, InjectedFormProps, reduxForm } from "redux-form";
import { connect } from "react-redux";
import parameterFetcher from "../../../components/parametersFetcher";
import selector from "../../../selectors/risks";
import { putItemAction, risksRequest } from "../../../actions/risks";
import routerModal from "../../../components/routerModal";
import { WithStyles } from "@material-ui/core/styles/withStyles";
import RiskForm from "./RiskForm";
import { FIELD_TITLE_MAX_LENGTH, FIELD_DESCRIPTION_MAX_LENGTH } from "./const";
import {IRiskUpdateData} from "./RiskCreate";

const FORM_NAME = "RiskUpdate";
const selectorForm = formValueSelector(FORM_NAME);

class RiskUpdate extends React.Component<
  { match: { params: { id: number } } } & InjectedFormProps<{}, {}> & {
      putData: (
        id: number,
        values: IRiskUpdateData,
        formName: string,
        backRoute: string
      ) => void;
      backRoute: string;
      vulnerabilities: number;
    }
> {
  render() {
    const { handleSubmit, putData, backRoute, vulnerabilities } = this.props;

    return (
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit((values: IRiskUpdateData) => {
          putData(
            this.props.match.params.id,
            (values = {
              title: values.title,
              description: values.description,
              impact: Number(values.impact),
              likelihood: Number(values.likelihood),
              vulnerabilityScore: Number(values.vulnerabilityScore),
            }),
            FORM_NAME,
            backRoute
          );
        })}
      >
        <RiskForm backRoute={backRoute} isVulnerabilities={vulnerabilities} />
      </form>
    );
  }
}

const formConnected = reduxForm({
  form: FORM_NAME,
  validate: composeValidators(
    fieldsNotEmpty(["title", "description", "impact", "likelihood"]),
    fieldMaxLength(["title"], FIELD_TITLE_MAX_LENGTH),
    fieldMaxLength(["description"], FIELD_DESCRIPTION_MAX_LENGTH)
  ),
})(RiskUpdate);

const connected = parameterFetcher(
  connect(
    (state) => ({
      valuesData: {
        ...selectorForm(
          state,
          "title",
          "description",
          "impact",
          "likelihood",
          "vulnerabilityScore"
        ),
      },
    }),
    {
      putData: putItemAction,
    }
  )(formConnected),
  FORM_NAME,
    risksRequest.getItem,
  selector.getItemById
);

export default routerModal(connected);