import * as React from 'react';

class TestEditHelpBlock extends React.Component<{
  testStatus: any;
  showHelpBlock: any;
  onToggleHelpBlock: (...any) => void;
}, {}> {
  render() {
    return (
      <div className="styled-block">
        <div className="block-header-wrapper">
          <h4 className="block-header">Help</h4>
        </div>
        <p>
          If you save this form, the test will change it's status to "booking requested".<br/>
          A new booking request will be sent to the same tester.<br/>
        </p>
        <button
          className="btn-accept ml-0"
          onClick={this.props.onToggleHelpBlock}
        >Got it. Don't show me this again.</button>
      </div>
    )
  }
}

export default TestEditHelpBlock as any;