import React, { MouseEvent } from "react";
import { downloadFile } from "helpers/file";
import { downloadDocumentFile } from "api/requests/document-download";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { ConnectedProps } from "types";
import { IDocument } from "models/document.interface";

type DownloadDocumentProps = {
  document?: IDocument | File;
} & PropsFromRedux;

export function DownloadDocument({
  document,
  downloadDocumentFile
}: DownloadDocumentProps) {
  const onClickHandler = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    if (!document) {
      return;
    }

    if (document instanceof File) {
      downloadFile(document);
      return;
    }

    if (document.id) {
      downloadDocumentFile(document.id);
    }
  };

  return (
    <a className="text-overflow-ellipsis" title={document?.name} href="#" onClick={onClickHandler}>
      {document?.name}
    </a>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      downloadDocumentFile
    },
    dispatch
  );
};
const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(DownloadDocument);
