import * as React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import userSelectors from "selectors/users";
import { getUser } from "actions/users";
import { RouteComponentProps } from "react-router";
import {bindActionCreators, Dispatch} from "redux";
import CompanyDetailsUpdate from "containers/client/admin/company-details/CompanyDetailsUpdate";
import { IUsers } from "models/users.interface";

type IProps = {
  getUser: (id: number) => (dispatch: Dispatch) => Promise<void>
  user: IUsers;
  currentUser: {
    companyDetails: {
      companyId: number,
      owner: {
        phone: string;
        email: string;
      };
    };
  };
} & RouteComponentProps<{id: string}>;

interface IHeaderProps {
  backRoute: string;
  owner: {
    phone: string;
    email: string;
  };
}

const ClientDetailsUpdateHeader = ({ backRoute, owner }: IHeaderProps) => {
  return (
    <div className="mt-4">
      <div
        className="crud-sections-header justify-content-between"
      >
        <h3>Company Details</h3>
        <div className="text-right">
          <Link to={backRoute} className="btn-cancel">
            Cancel
          </Link>
          <button type="submit" className="btn-save">
            Save
          </button>
        </div>
      </div>
      <hr />
      <div className="styled-block mt-3">
        <h5 className="mt-4">Owner's Account</h5>
        <hr />
        <div className="row">
          <div className="col-3">Email</div>
          <div className="col-3">{owner.email}</div>
        </div>
        <div className="row">
          <div className="col-3">Telephone</div>
          <div className="col-3">{owner.phone}</div>
        </div>
      </div>
    </div>
  );
};

class ClientDetailsUpdate extends React.Component<IProps & IHeaderProps> {
  componentDidMount() {
    this.props.getUser(+this.props.match.params.id);
  }

  render() {
    const {
      backRoute,
      currentUser: { companyDetails },
    } = this.props;

    if (!companyDetails) {
      return (
        <div className="mt-4">
          <div className="crud-sections-header">
            <h4>Company details</h4>
            <p>Loading...</p>
          </div>
        </div>
      );
    }

    return (
      <div className="mt-4">
        <CompanyDetailsUpdate
          backRoute={backRoute}
          showHeader
          companyId={companyDetails.companyId}
        >
          <ClientDetailsUpdateHeader
            owner={companyDetails.owner}
            backRoute={backRoute}
          />
        </CompanyDetailsUpdate>
      </div>
    );
  }
}

function mapStateToProps(state: IProps, ownProps: IProps) {
  return {
    currentUser: state.currentUser,
    user: userSelectors.getItemById(ownProps.match.params.id)(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    getUser: bindActionCreators(getUser, dispatch),
  };
}

const connected = connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientDetailsUpdate);

const routered = withRouter(connected) as React.ComponentClass<{
  backRoute: string;
}>;

export default routered;
