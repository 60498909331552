import React from "react";
import {connect} from "react-redux";
import {Field, reduxForm} from "redux-form";
import ReduxTextField from "../../components/forms/ReduxTextField";
import {composeValidators, fieldsNotEmpty, fieldIsEmail} from "../../helpers/validations/validation";
import {Modal, Grid, Button} from "@material-ui/core";
import {forgotPasswordModalClose, forgotPasswordModalOpen, loginModalClose, postLogin} from "../../actions";
import {getIsOpenModalLogin} from "../../selectors/modal";
import {handleSpaceKeyPress} from "../../helpers/validations/emptyTestFieldValidation";

const FORM_NAME = "LoginModal";

class LoginModal extends React.Component {
  handleClose = () => {
    this.props.loginModalClose();
  };

  render() {
    const {handleSubmit} = this.props;

    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.props.isOpenModal}
        onClose={this.handleClose}
        className="d-flex overflow-auto"
      >
        <div className="styled-modal xs">
          <h4>Login</h4>
          <form noValidate autoComplete="off" onSubmit={handleSubmit((values) => {
            // Action to ajax
            this.props.postLogin(values.email, values.password, FORM_NAME);
          })}>
            <Grid container spacing={40}>
              <Grid item xs={12}>
                <Field
                  name="email"
                  label="Email"
                  type="email"
                  margin="normal"
                  component={ReduxTextField}
                  onKeyPress={handleSpaceKeyPress}
                  autoFocus
                />
                <Field
                  name="password"
                  label="Password"
                  type="password"
                  margin="normal"
                  component={ReduxTextField}
                />
                <br/><br/>
                <Button type="submit" variant="contained" color="primary" fullWidth>
                  Login
                </Button>
                <Button
                  fullWidth
                  onClick={() => {
                    this.props.forgotPasswordModalOpen();
                    this.props.loginModalClose();
                  }}>
                  Forgot password?
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    isOpenModal: getIsOpenModalLogin(state),
  };
}

const connectedComponent = connect(mapStateToProps, {
  forgotPasswordModalClose,
  forgotPasswordModalOpen,
  loginModalClose,
  postLogin,
})(reduxForm({
  form: FORM_NAME,
  validate: composeValidators(
    fieldsNotEmpty(["email", "password"]),
    fieldIsEmail(["email"]),
  )
})(LoginModal));

export default connectedComponent;
