import * as React from 'react';
import {withStyles, Modal, Typography, Grid, Button, WithStyles} from '@material-ui/core';
import {defaultStyleWidth} from '../../../../styles/material/popup-style';


interface IProps {
  open?: boolean;
  onClose: (...any) => void;
  onSave: (...any) => void;
}

class TestSaveModal extends React.Component<WithStyles<any> & IProps, {}> {
  render() {
    if (!this.props.open) {
      return null;
    }

    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.props.open}
        onClose={this.props.onClose}
      >
        <div className={this.props.classes.paper}>
          <Typography variant="title" component="h3" id="modal-title">
            Save changes?
          </Typography>
          <hr/>
          <Typography component="p" id="modal-title">
            The Test changes it's status to "Booking requested".
            <br/>
            New Booking Request will be sent to the same Tester.
          </Typography>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <div className="d-flex justify-content-between mt-4">
                <Button
                  variant="contained"
                  color="default"
                  onClick={this.props.onClose}
                >
                  Close
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={this.props.onSave}
                >
                  Save
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </Modal>
    );
  }
}

export default withStyles(defaultStyleWidth(620))(TestSaveModal as any) as any;