import * as React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import {withStyles, Modal, Typography, Grid, Button} from '@material-ui/core';
import {
  forgotPasswordModalClose,
  twoFactorCheckClose,
  twoFactorCheckOpen,
  twoFactorInitClose
} from '../../../actions';
import {getIsOpenTwoFactorCheck} from "../../../selectors/modal";
import {defaultStyleWidth} from '../../../styles/material/popup-style';
import ReduxTextField from "../../../components/forms/ReduxTextField";
import {handleSpaceKeyPress} from "../../../helpers/validations/emptyTestFieldValidation";
import {composeValidators, fieldsNotEmpty} from "../../../helpers/validations/validation";
import {twoFactorAuthCheck} from "../../../actions/two-factor-auth";

const FORM_NAME = 'TwoFactorCheckModal';

class TwoFactorCheckModal extends React.Component<any> {
  handleClose = () => {
    this.props.twoFactorCheckClose();
    this.props.reset();
  };

  render() {
    const {handleSubmit} = this.props;

    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.props.twoFactorInit}
        onClose={this.handleClose}
      >
        <div className={this.props.classes.paper}>
          <Grid item={true} xs={12}>
            <form
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit((values) => {
                this.props.twoFactorAuthCheck(values.code, FORM_NAME);
                })
              }
            >
              <Grid container spacing={40}>
                <Grid item xs={12}>
                  <Typography variant="title" component="h3" id="two-factor-check-modal-title">
                    Two-factor authentication
                  </Typography>
                  <br/>
                  <small>
                    Please enter the code generated by your Mobile Authenticator application
                  </small>
                  <Field
                    name="code"
                    label="Authentication code *"
                    type="text"
                    margin="normal"
                    component={ReduxTextField}
                    onKeyPress={handleSpaceKeyPress}
                    autoFocus
                  />
                  <br/><br/>
                  <Button type="submit" variant="contained" color="primary" fullWidth>
                    Login
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </div>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    twoFactorInit: getIsOpenTwoFactorCheck(state),
  };
}

const connectedComponent = connect(mapStateToProps, {
  forgotPasswordModalClose,
  twoFactorInitClose,
  twoFactorCheckOpen,
  twoFactorCheckClose,
  twoFactorAuthCheck,
})(reduxForm({
  form: FORM_NAME,
  validate: composeValidators(
    fieldsNotEmpty(['code']),
  )
})(TwoFactorCheckModal));

export default withStyles(defaultStyleWidth(320))(connectedComponent);
