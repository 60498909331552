import * as Types from '../constants/action-type';

export interface IStateLinkedin {
  linkedinCompanies: string[];
}

export default function (state: IStateLinkedin = {
  linkedinCompanies: [],
}, action): IStateLinkedin {
  switch (action.type) {
    case Types.LINKEDIN_COMPANIES_RECIEVED:
      return {...state, linkedinCompanies: action.payload};
    default:
      return state;
  }
}