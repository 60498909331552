export const ACTIONS = [
  {'id': 1, 'label': 'Avoid'},
  {'id': 2, 'label': 'Reduce'},
  {'id': 3, 'label': 'Transfer'},
  {'id': 4, 'label': 'Accept'},
  {'id': 5, 'label': 'Share'},
];

export const STATUSES = [
  {'id': 1, 'label': 'Open'},
  {'id': 2, 'label': 'Closed'},
];

export const MAX_RESIDUAL_RISK_RATING = 25;