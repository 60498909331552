import * as React from "react";
import {connect} from "react-redux";
import {InjectedFormProps, reduxForm, formValueSelector} from "redux-form";
import {withRouter} from "react-router-dom";
import {WithStyles} from "@material-ui/core/styles/withStyles";
import {
  composeValidators,
  fieldIsEmail,
  fieldMaxLength,
  fieldsNotEmpty,
  fieldsNotEmptyDependsFromAdditionalField
} from "../../../helpers/validations/validation";
import {postItemAction} from "../../../actions/testers";
import {groupsRequest} from "../../../actions/groups";
import {testerExternalCompaniesRequest} from "../../../actions/tester-external-companies";
import routerModal, {IBackRoute} from "../../../components/routerModal";
import {dataFetcher} from "../../../components/dataFetcher";
import {ACTIVE} from "../../../constants/filter";
import groupsSelector from "../../../selectors/groups";
import externalCompaniesSelector from "../../../selectors/tester-external-companies";
import TesterForm from "./TesterForm";
import { IAnyProps } from "../../../interfaces/any-props.interface";

interface IFormData {
  firstName: string;
  lastName: string;
  email: string;
  group: string;
  externalCompany: string;
  companyName?: string;
  bio: string;
}

const mappingErrors: Array<[string, string]> = [
  ["user.email", "email"],
  ["user.firstName", "firstName"],
  ["user.lastName", "lastName"],
];

const handleServerError = (error: IAnyProps) => {
  return mappingErrors.reduce((acc, data) => {
    const errorFiltered = error.filter((err)=> err.propertyPath === data[0]);

    return Object.assign(
      acc,
      errorFiltered[0] ? { [data[1]]: errorFiltered[0].message } : {}
    );
  }, {});
};

const FORM_NAME = "TesterCreate";

class TesterCreate extends React.Component<{ match: any } & InjectedFormProps<{}, {}> & {
  postData: (...any) => void,
  backRoute: string,
  groups: any,
  group: string,
  externalCompanies: any,
  externalCompany: string,
} & WithStyles<any>> {
  onFormSubmit = (values) => {
    let requestCompany;
    const { postData, backRoute } = this.props;
    const { firstName, lastName, email, group, companyName } = values;

    const requestUserData = {
      ...values,
      user: {
        firstName,
        lastName,
        email,
        group
      }
    };

    if (companyName) {
      requestCompany = {
        company: {
          name: companyName
        },
      }
    }

    postData({...requestUserData, ...requestCompany}, FORM_NAME, backRoute, handleServerError);
  };

  render() {
    const {
      handleSubmit,
      backRoute,
      initialValues,
      groups,
      group,
      externalCompanies,
      externalCompany,
      change
    } = this.props;

    return (
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit((values: IFormData) => this.onFormSubmit(values))}>
        <TesterForm
          title={"Create Tester"}
          backRoute={backRoute}
          initialValues={initialValues}
          groups={groups}
          group={group}
          externalCompanies={externalCompanies}
          externalCompany={externalCompany}
          updateField={change}
        />
      </form>
    );
  }
}

const formConnected = reduxForm({
  form: FORM_NAME,
  validate: composeValidators(
    fieldsNotEmpty(["firstName", "lastName", "email", "group", 'companyName']),
    fieldMaxLength(["email"], 50),
    fieldMaxLength(["firstName", "lastName"], 60),
    fieldMaxLength(['bio'], 2000),
    fieldMaxLength(["companyName"], 50),
    fieldIsEmail(["email"]),
    fieldsNotEmptyDependsFromAdditionalField(
      ["externalCompany"],
      ["companyName"],
      {},
      'Company or Other company name is required'
    )
  )
})(TesterCreate);

const connected = connect(
  (state: any) => {
    const formSelector = formValueSelector(FORM_NAME);
    const group = formSelector(state, 'user.group');
    const externalCompany = formSelector(state, 'externalCompany');

    return { initialValues: {}, group, externalCompany };
  },
  {
    postData: postItemAction
  }
)(formConnected);

const componentWithData = dataFetcher(
  connected,
  [
    {
      key: 'groups',
      selector: (state) => groupsSelector.getItems(state),
      action: (props) => groupsRequest.getItems(1, 1000, ACTIVE, '', {parentUserGroups: props.auth.groupId}),
      alwaysReceiveFreshData: false,
    },
    {
      key: 'externalCompanies',
      selector: (state) => externalCompaniesSelector.getItems(state),
      action: () => testerExternalCompaniesRequest.getItems(1, 1000, ACTIVE),
      alwaysReceiveFreshData: false,
    },
  ]
);


export default routerModal(withRouter(componentWithData as any)) as React.ComponentClass<IBackRoute>;
