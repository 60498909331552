import * as React from "react";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import ReduxTextField from "../../components/forms/ReduxTextField";
import ReduxDialingCodeSelectSearchField from "../../components/forms/ReduxDialingCodeSelectSearchField";
import {InjectedFormProps, reduxForm} from "redux-form";
import {composeValidators, fieldIsEmail, fieldIsNumber, fieldsNotEmpty} from "../../helpers/validations/validation";
import ReduxCheckbox from "../../components/forms/ReduxCheckbox";
import {FieldGeneric} from "../../components/forms/FieldGeneric";
import {IRegistrationTester} from "../../models/registration-tester.interface";
import {connect} from "react-redux";
import {registrationTester} from "../../actions/common";
import {first, result} from "lodash";
import {IAnyProps} from "../../interfaces/any-props.interface";
import dialingCodeSelector from "../../selectors/countries";
import * as dialingCodeRequest from "../../actions/countries";
import {ACTIVE} from "../../constants/filter";
import {dataFetcher} from "../../components/dataFetcher";
import {ICountry} from "../../models/dialing-code.interface";
import {BASE_URL} from "../../constants/urls";
import {thankYouForRegistrationModalOpen} from "../../actions/modals";
import {handleKeyPressOnNumberField} from "../../helpers/validations/numberFieldValidator";
import {handleSpaceKeyPress} from "../../helpers/validations/emptyTestFieldValidation";
import Header from "./Header";
import {sortDialingCodes} from "../../helpers/dialing-codes-sorting";
import {getDialingCodeFromCountry} from "../../helpers/converter-helper";
import applicationSettingsSelector from "../../selectors/application-settings";
import {getApplicationSettingByKey} from "../../actions/application-settings";
import {RawHTML} from "../../components/RawHTML";
import Footer from "./Footer";
import TooltipIcon from "@material-ui/core/Tooltip/Tooltip";
import {Zoom} from "@material-ui/core";
import Icon from "@material-ui/core/Icon/Icon";
import {LINKS} from "../../constants/links";

const FORM_NAME = "TesterRegistartion";

interface IPhoneProps {
    dialingCodes: ICountry[]
    renderCodes: (option: ICountry) => JSX.Element
}

export const PhoneFields = ({dialingCodes, renderCodes }: IPhoneProps): JSX.Element =>{
    const sortedDialingCodes = sortDialingCodes(dialingCodes);

    return (
      <div className="row">
        <div className="col-md-5">
          <FieldGeneric
            name="dialingCode"
            label="Code *"
            component={ReduxDialingCodeSelectSearchField}
            items={sortedDialingCodes}
            renderItem={renderCodes}
          />
        </div>
        <div className="col-md-7 d-flex align-items-center">
          <FieldGeneric
            inputProps={{ minLength: 1, maxLength: 20 }}
            name="phone"
            label="Telephone *"
            type={`tel`}
            component={ReduxTextField}
            onKeyPress={handleKeyPressOnNumberField}
            fullWidth={true}
          />
          <TooltipIcon
            placement={"top"}
            TransitionComponent={Zoom}
            disableFocusListener
            disableTouchListener
            title='Please note, "0" is not required for UK international numbers'
          >
            <Icon className="mt-4">info</Icon>
          </TooltipIcon>
        </div>
      </div>
    );
}

interface IDispatchProps {
  registerTester: (
    model: IRegistrationTester,
    formName: string,
    validationHandler: (error: any) => IAnyProps
  ) => void;
  fetchDialingCode: (...any) => any;
  thankYouForRegistrationModalOpen: (...any) => any;
  isTesterRegistrationOpenedSetting: any;
}

interface IDataFetcher {
  dialingCodes: ICountry[];
}

const mappingErrors: Array<[string, string]> = [
  ["testerProfile.currency", "currencyCode"],
  ["testerProfile.rate", "rate"],
  ["testerProfile.user.email", "email"],
  ["testerProfile.user.phone", "phone"],
  ["testerProfile.user.firstName", "firstName"],
  ["testerProfile.user.lastName", "lastName"],
];

const handleServerError = (error: IAnyProps) => {
  return mappingErrors.reduce((acc, data) => {
    return Object.assign(acc, result(error, data[0], false) ? {[data[1]]: result(error, data[0])} : {});
  }, {});
};

class TesterRegistartion extends React.Component<InjectedFormProps<{}> & IDispatchProps & IDataFetcher, {}> {
  handleSubmit = this.props.handleSubmit((values: IRegistrationTester & {
    dialingCode: string;
  }) => {
    this.props.registerTester({
        dialingCode: undefined,
        ...values,
        phone: `+${getDialingCodeFromCountry(values.dialingCode, this.props.dialingCodes)}${values.phone}`
      } as IRegistrationTester,
      FORM_NAME,
      handleServerError
    );
  });

  checkboxLabel = (
    <div>
      {`I agree to the AVORD `}
      <a href={LINKS.TERMS_AND_CONDITIONS} target="_blank">
          Terms &amp; Conditions
      </a>
      {` and `}
      <a href={LINKS.PRIVACY_POLICY} target="_blank">
          Privacy Policy
      </a>
    </div>
  );

  renderCodes = (option: ICountry) => (
    <MenuItem
      key={option.id}
      value={option.name}
    >
      {option.flagImagePath && <img src={BASE_URL + option.flagImagePath} height="16px" alt={option.name}/>}
      &nbsp;<span style={{width: "55px"}}>{`+${option.dialingCode} `}</span> {option.name}
    </MenuItem>
  );

  renderForm = () => {
    const firstSetting = first(this.props.isTesterRegistrationOpenedSetting);

    if (!firstSetting || !result(firstSetting, "state", false)) {
      const message = result(firstSetting, "message", "");

      return (
        <div className="row text-center mt-5">
          <div className="col-md-12">
            <h4>
              <RawHTML>
                {message}
              </RawHTML>
            </h4>
            <hr/>
            <img src="/images/logo-icon-big.svg" alt="" width="320"/>
          </div>
        </div>
      );
    }

    return (
      <form onSubmit={this.handleSubmit} noValidate autoComplete="off">
        <h1>Register to receive contract offers!</h1>
        <hr/>
        <div className="row">
          <div className="col-md-4">
            <FieldGeneric
              inputProps={{minLength: 1, maxLength: 50}}
              name="firstName"
              label="First name *"
              margin="dense"
              component={ReduxTextField}
            />
            <FieldGeneric
              inputProps={{minLength: 1, maxLength: 50}}
              name="lastName"
              label="Last name *"
              margin="dense"
              component={ReduxTextField}
            />
            <FieldGeneric
              inputProps={{maxLength: 60}}
              name="email"
              label="Email *"
              component={ReduxTextField}
              fullWidth={true}
              onKeyPress={handleSpaceKeyPress}
            />
            <PhoneFields
              dialingCodes={this.props.dialingCodes}
              renderCodes={this.renderCodes}
            />
          </div>
          <div className="col-md-4">
            <FieldGeneric
              inputProps={{minLength: 1, maxLength: 255}}
              name="addressLine1"
              label="Address line 1 *"
              margin="dense"
              component={ReduxTextField}
            />
            <FieldGeneric
              inputProps={{minLength: 1, maxLength: 255}}
              name="addressLine2"
              label="Address line 2"
              margin="dense"
              component={ReduxTextField}
            />
            <FieldGeneric
              inputProps={{minLength: 1, maxLength: 255}}
              name="addressLine3"
              label="Address line 3"
              margin="dense"
              component={ReduxTextField}
            />
            <FieldGeneric
              inputProps={{minLength: 1, maxLength: 50}}
              name="town"
              label="Town/City *"
              margin="dense"
              component={ReduxTextField}
            />
            <FieldGeneric
              inputProps={{minLength: 1, maxLength: 50}}
              name="state"
              label="State/Province"
              margin="dense"
              component={ReduxTextField}
            />
            <FieldGeneric
              inputProps={{minLength: 1, maxLength: 10}}
              name="postCode"
              label="Post/zip code *"
              margin="dense"
              component={ReduxTextField}
            />
          </div>
          <div className="col-md-4">
            <br/><br/>
            <img src="/images/logo-icon-big.svg" alt="" width="100%"/>
          </div>
        </div>
        <hr/>
        <FieldGeneric
          name="is_agree"
          label={this.checkboxLabel as any}
          component={ReduxCheckbox}
        />
        <br/>
        <Button type="submit" variant="contained" color="primary" className="mb-5">
          Register and start making money
        </Button>
      </form>
    );
  };

  render() {
    return (
      <div>
        <Header/>
        <div className="page-content">
          <div className="container">
            {this.renderForm()}
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}

const validators = composeValidators(
  fieldsNotEmpty([
    "firstName",
    "lastName",
    "email",
    "prefix",
    "phone",
    "addressLine1",
    "town",
    "dialingCode",
    "postCode",
    "is_agree",
  ]),
  fieldIsEmail(["email"]),
  fieldIsNumber(["phone"]),
);

function mapStateToProps(state) {
  return {
    prefixes: dialingCodeSelector.getItems(state),
  };
}

const connected = connect(mapStateToProps, {
  registerTester: registrationTester,
  fetchDialingCode: dialingCodeRequest.countriesRequest.getItems,
  thankYouForRegistrationModalOpen,
})(reduxForm({
  form: FORM_NAME,
  validate: validators
})(TesterRegistartion));

export default dataFetcher<IDataFetcher>(connected, [
  {
    key: "dialingCodes",
    selector: (state, props) => dialingCodeSelector.getItems(state),
    action: () => dialingCodeRequest.countriesRequest.getItems(1, 1000, ACTIVE),
  },
  {
    key: "isTesterRegistrationOpenedSetting",
    selector: (state, props) => applicationSettingsSelector.getItemsObject(state),
    action: () => getApplicationSettingByKey("isTesterRegistrationOpened"),
    alwaysReceiveFreshData: true
  }
]);
