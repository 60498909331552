import * as React from 'react';
import {connect} from 'react-redux';
import {withStyles, Modal, Typography, Button, WithStyles, MenuItem, Select} from '@material-ui/core';
import {defaultStyleWidth} from '../../../styles/material/popup-style';
import {getModalReasignId, getModalReassignGroup, getModalReassignTitle} from "../../../selectors/modal";
import {getIsOpenModalReasign} from "../../../selectors/modal";
import {reassignModalClose} from "../../../actions/modals";

import {IUsers} from "../../../models/users.interface";
import {reassignCompanyTesterAction} from "../../../actions/users";
import {getUsersFilteredForReassign} from "../../../actions/reassign-users";
import reassignUsersSelector from "../../../selectors/reassign-users";
import {RouteComponentProps, withRouter} from "react-router-dom";

interface IStateToProps {
  isOpenModal: any;
  modalTitle: string;
  modalGroup: string;
  data: IUsers[];
  id: number;
  filterText?: string;
  page?: number;
  itemsPerPage?: number;
  showStatus?: string;
}

interface IDispatchToProps {
  modalClose: () => any;
  getUsersFilteredForReassign: (...any) => any;
  reassignCompanyTesterAction: (...any) => any;
}

class UsersReassignModal extends React.Component<
  IStateToProps
  & IDispatchToProps
  & WithStyles<any>
  & RouteComponentProps<any>
> {
  state = {
    value: 0,
  };

  handleChange = (event) => {
    this.setState({value: event.target.value});
  };

  componentWillMount(): void {
    if (!this.props.data.length) {
      this.props.getUsersFilteredForReassign(this.props.modalGroup);
    }
  }

  handleSubmit = () => {
    const tester = this.props.data.filter((item) => item.id === this.props.id);
    this.props.reassignCompanyTesterAction(
      this.props.id,
      this.state.value,
      this.props.modalGroup,
      tester[0].testerProfileId,
      this.props.page,
      this.props.itemsPerPage,
      this.props.showStatus,
      this.props.filterText
    );
    this.props.modalClose();
  };

  render() {
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.props.isOpenModal}
        onClose={this.handleClose}
        className="d-flex overflow-auto"
      >
        <div className="styled-modal sm">
          <h4>Reassign</h4>
          <p>
            Tester has linked tests, please reassign them to another member
          </p>
          <hr/>
          <Select
            fullWidth
            className="mb-4"
            onChange={this.handleChange}
            value={this.state.value}
          >
            {this.props.data.map((item: IUsers)=> {
              return (
                item.id !== this.props.id &&
                  <MenuItem
                    key={item.id}
                    value={item.id}>
                    {item.email}
                  </MenuItem>
              )
            })}
          </Select>
            <Button variant="contained" onClick={this.handleClose}>
              CANCEL
            </Button>
            <Button className="btn-save" onClick={this.handleSubmit} style={{float: 'right', color: "white"}}>
              REASSIGN AND UNLINK
            </Button>
        </div>
      </Modal>
    );
  }

  private readonly handleClose = () => {
    this.props.modalClose();
  };
}

function mapStateToProps(state, props: any) {
  return {
    isOpenModal: getIsOpenModalReasign(state),
    modalTitle: getModalReassignTitle(state),
    id: getModalReasignId(state),
    modalGroup: getModalReassignGroup(state),
    data: reassignUsersSelector.getItems(state),
  };
}

const connectedComponent = connect<IStateToProps, IDispatchToProps>(mapStateToProps, {
  modalClose: reassignModalClose,
  getUsersFilteredForReassign,
  reassignCompanyTesterAction,
})(UsersReassignModal);

export default withRouter(withStyles(defaultStyleWidth(320))(connectedComponent as any) as any) as any;
