import * as React from "react";
import Header from "./Header";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import confirmInviteToCompany from "../../actions/confirm-invite-to-company";

class InviteToCompanyConfirmationPage extends React.Component<any> {

  componentDidMount() {
    this.props.confirmInviteToCompany({token: this.props.match.params.inviteToCompanyToken});
  }

  renderMessage = () => {
    if (this.props.acceptInvite.status) {
      return this.props.acceptInvite.message || "You have accepted the invitation and now linked to a testing company.";
    }

    if (!this.props.acceptInvite.status && this.props.acceptInvite.message) {
      return this.props.acceptInvite.message
    }

    return 'Error: the link has expired.';
  };

  render() {
    if (!this.props.acceptInvite) {
      return (
        <div>
          Loading...
        </div>
      );
    }
    return (
      <div>
        <Header/>
        <div className="page-content">
          <div className="container">
            <div className="content">
              <h4>Invite to Testing Company confirmation</h4>
              <h5>{this.renderMessage()}</h5>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const routered = withRouter(InviteToCompanyConfirmationPage);

export default connect ((state: any) => {
  return {
    acceptInvite: state.inviteToCompanyConfirmation
  };
}, {
  confirmInviteToCompany
})(routered as any);
