import * as _ from "lodash";
const convertModelToSwagger = model => {
  return _.pick(model, [
    "id",
    "image",
    "name",
    "dialingCode",
    "flagImagePath",
    "flagImage"
  ]);
};

export const convertorFromSwagger = model => {
  if (Array.isArray(model)) {
    return [...model];
  }

  return { ...model };
};

export const convertorToSwagger = model => {
  if (Array.isArray(model)) {
    return [...model];
  }

  return convertModelToSwagger(model);
};
