import * as React from 'react';
import {ComponentType} from 'react';
import {Checkbox, FormControlLabel, InputLabel} from "@material-ui/core";
import {WrappedFieldProps} from "redux-form/lib/Field";

const ReduxCheckbox: ComponentType<WrappedFieldProps & {
  [key: string]: any
}> = ({input, label, meta: {touched, error}, classes, ...custom}) => {
  const onChange = event => {
    input.onChange(event.target.checked);
  };

  return (
    <div>
      <FormControlLabel
        style={{margin: '0 0 0 -12px'}}
        label={label}
        control={
          <Checkbox
            checked={input.value}
            onChange={onChange}
            {...custom}
            value={input.name}
          />
        }
      />
      {
        touched && error &&
        <div>
          <InputLabel style={{fontSize: '13px'}} htmlFor="select-multiple" error={touched && error}>{error}</InputLabel>
        </div>
      }
    </div>
  );
};

export default ReduxCheckbox;
