import { result } from "lodash";
import { openSnackbar } from "../../actions/snackbar";
import { logOut } from "../../actions/common";
import UnauthorizedError from "./UnauthorizedError";

export const handleHttpErrors = (error, dispatch) => {
  /** Block with responses handling */
  const errorCode: number = result(error, "response.status");

  if ([400, 401, 403, 404].indexOf(errorCode) !== -1) {
    let message = result(error, "response.body.detail");
    if (!message) {
      message = result(error, "response.body.hydra:description");
    }

    dispatch(openSnackbar(`Error: ${message}`, 30000));
    return;
  }

  /** Handle custom errors */
  if (error instanceof UnauthorizedError) {
    dispatch(logOut());
    dispatch(openSnackbar(result(error, "message", ""), 30000));

    return;
  }

  dispatch(openSnackbar(`Error: ${error.message}`, 30000));
};
