import React, { MouseEvent } from "react";
import classNames from "classnames";

type AddReceiptButtonProps = {
  onClick: () => void;
  disabled?: boolean;
};

export function AddReceiptButton({ onClick, disabled }: AddReceiptButtonProps) {
  const onClickHandler = (event: MouseEvent) => {
    event.preventDefault();

    onClick();
  };

  return (
    <button
      className={classNames({ "btn btn-new": true, disabled: disabled })}
      type="button"
      onClick={onClickHandler}
      disabled={disabled}
    >
      Add Receipt
    </button>
  );
}
