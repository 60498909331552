import FormControl from "@material-ui/core/es/FormControl/FormControl";
import Input from "@material-ui/core/es/Input/Input";
import InputAdornment from "@material-ui/core/es/InputAdornment/InputAdornment";
import IconButton from "@material-ui/core/es/IconButton/IconButton";
import {VisibilityOff, Visibility} from "@material-ui/icons";
import * as React from "react";

interface IProps {
  hiddenData: string;
}

interface IState {
  isVisible?: boolean;
}

class HiddenBankDetailsField extends React.Component<IProps, IState> {
  state = {
    isVisible: false,
  };

  toggleVisible = () => {
    this.setState({isVisible: !this.state.isVisible})
  };

  render() {
    return (
      <FormControl>
        <Input
          type={this.state.isVisible ? 'text' : 'password'}
          value={this.props.hiddenData}
          disabled={true}
          onChange={this.toggleVisible}
          disableUnderline={true}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="Toggle hidden data visibility"
                onClick={this.toggleVisible}
              >
                {this.state.isVisible ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
    );
  }
}

export default HiddenBankDetailsField;