import React, { useState } from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { archiveTimesheetExpensesDocumentAction } from "actions/time-expenses";
import { ConfirmModal } from "components/ConfirmModal";
import { ConnectedProps } from "types";

type RemoveRepeaterButtonProps = {
  id?: number;
  onRemove: () => void;
} & PropsFromRedux;

function RemoveRepeaterButton(
  {
    id,
    archiveTimesheetExpensesDocument,
    onRemove,
  }: RemoveRepeaterButtonProps
) {
  const [isOpened, setIsOpened] = useState(false);

  const archiveDocument = (id: number) => {
    archiveTimesheetExpensesDocument(id);

    setIsOpened(false);
  };

  const clickHandler = () => {
    if (id) {
      setIsOpened(true);
      return;
    }

    onRemove();
  };

  return (
    <>
      <button className="btn-remove" type="button" onClick={clickHandler} />
      <ConfirmModal
        isOpened={isOpened}
        confirmDescription="The document will be archived and completely deleted after saving the changes as a draft."
        onConfirm={() => archiveDocument(Number(id))}
        onClose={() => setIsOpened(false)}
        okLabel="Archive"
        cancelLabel="Cancel"
      />
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      archiveTimesheetExpensesDocument: archiveTimesheetExpensesDocumentAction
    },
    dispatch
  );
};

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(RemoveRepeaterButton);
