import * as React from "react";
import {
  Button,
  Checkbox,
  ListItemText,
  MenuItem,
  Paper,
} from "@material-ui/core";
import { connect } from "react-redux";
import { testerBookingRequestsFilter } from "../../actions/tester-booking-requests";
import { formValueSelector, InjectedFormProps, reduxForm } from "redux-form";
import { FieldGeneric } from "../../components/forms/FieldGeneric";
import {bindActionCreators, Dispatch} from "redux";
import ReduxSelectField from "../../components/forms/ReduxSelectField";
import {
  getTestStatusNameForTesterBookingRequestsTab,
  TestStatus,
} from "../../constants/test-status";
import selector from "../../selectors/users";
import ReduxCheckbox from "../../components/forms/ReduxCheckbox";
import ReduxDatePicker from "../../components/forms/ReduxDatePicker";

export const BOOKING_REQUESTS_FORM_NAME = "BookingRequestsSearchForm";
const selectorForm = formValueSelector(BOOKING_REQUESTS_FORM_NAME);
export const ALL_STATUSES_BOOKING_REQUESTS = [
  TestStatus.STATUS_BOOKING_REQUESTED,
  TestStatus.STATUS_BOOKING_REQUESTED_EDITED,
  TestStatus.STATUS_PROPOSAL_SUBMITTED,
  TestStatus.STATUS_BOOKED,
  TestStatus.STATUS_BOOKING_REJECTED_BY_TESTER,
  TestStatus.STATUS_BOOKING_REJECTED_BY_CUSTOMER,
  TestStatus.STATUS_PROPOSAL_SUBMITTED_CANCELLED_BY_CUSTOMER,
  TestStatus.STATUS_PROPOSAL_SUBMITTED_CANCELLED_BY_TESTER,
  TestStatus.STATUS_AVORD_VALIDATION,
];

interface IValuesData {
  status: number[];
  dateFrom: string;
  dateTo: string;
  questionAsked: boolean;
}

type IProps = InjectedFormProps<{}, {}> & {
  submitFilterChanges: (value: string) => void;
  valuesData: {
    active: string;
    text?: string;
    questionAsked: boolean;
    status: number[];
  };
};

const BookingRequestsSearchForm = (props: IProps) => {
  const { handleSubmit, submitFilterChanges, valuesData, change } = props;

  const joinStatuses = (selected) =>
    selected.map(getTestStatusNameForTesterBookingRequestsTab).join(", ");

  const checkStatuses = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    change("status", ALL_STATUSES_BOOKING_REQUESTS);
  };

  const uncheckStatuses = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    event.stopPropagation();
    change("status", []);
  };

  return (
    <form
      onSubmit={handleSubmit((values: { active: string }) => {
        submitFilterChanges(values.active);
      })}
    >
      <Paper className="p-4">
        <div className="row">
          <div className="col-md-6">
            <FieldGeneric
              name="dateFrom"
              margin="normal"
              label="From"
              InputLabelProps={{
                shrink: true,
              }}
              component={ReduxDatePicker}
            />
          </div>
          <div className="col-md-6">
            <FieldGeneric
              name="dateTo"
              margin="normal"
              label="To"
              InputLabelProps={{
                shrink: true,
              }}
              addEndOfDay={true}
              component={ReduxDatePicker}
            />
          </div>
          <div className="col-md-12">
            <FieldGeneric
              name="status"
              label="Status"
              margin="normal"
              fullWidth
              multiple
              component={ReduxSelectField}
              renderValue={joinStatuses}
            >
              <MenuItem key={0}>
                <Button color="primary" onClick={checkStatuses}>
                  Check all
                </Button>
                <Button color="primary" onClick={uncheckStatuses}>
                  Uncheck all
                </Button>
              </MenuItem>
              {ALL_STATUSES_BOOKING_REQUESTS.map((status) => (
                <MenuItem key={status} value={status}>
                  <Checkbox
                    checked={(valuesData.status || []).indexOf(status) > -1}
                  />
                  <ListItemText
                    primary={getTestStatusNameForTesterBookingRequestsTab(
                      status
                    )}
                  />
                </MenuItem>
              ))}
            </FieldGeneric>
          </div>
          <div className="col-md-12">
            <FieldGeneric
              name="questionAsked"
              label="Question asked"
              margin="normal"
              checked={valuesData.questionAsked}
              component={ReduxCheckbox}
            />
          </div>
        </div>
      </Paper>
      <br />
    </form>
  );
};

function mapStateToProps(state: IValuesData) {
  return {
    valuesData: selectorForm(
      state,
      "status",
      "dateFrom",
      "dateTo",
      "questionAsked"
    ),
    initialValues: {
      status: ALL_STATUSES_BOOKING_REQUESTS,
      dateFrom: selector.getFilterTextFields(state),
      dateTo: selector.getFilterTextFields(state),
      questionAsked: false,
    },
  };
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      submitFilterChanges: testerBookingRequestsFilter.submitFilterChanges,
    },
    dispatch
  );
};

const connectForm = reduxForm({
  form: BOOKING_REQUESTS_FORM_NAME,
})(BookingRequestsSearchForm);

const connected = connect(mapStateToProps, mapDispatchToProps)(connectForm);

export default connected;
