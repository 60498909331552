import * as Types from "../constants/action-type";

export const bookingPageTestsListIsEmpty = () => {
  return {type: Types.BOOKING_PAGE_TESTS_LIST_IS_EMPTY};
};

export const bookingPageTestItemIsEmpty = () => {
  return {type: Types.BOOKING_PAGE_TEST_ITEM_IS_EMPTY};
};


