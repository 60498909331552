import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  contactsModalOpen,
  loginModalOpen,
  showDrawer,
  resetPassword,
  logOut,
} from "../../actions/index";
import { getIsDrawerOpen } from "../../selectors/drawer";
import { getAuth, getRoles } from "../../selectors/auth";
import AccountButtons from "../common/AccountButtons";
import { ROLE, ROLE_GUEST } from "../../constants/roles";
import { AppBar } from "@material-ui/core";
import PermissionBlock from "../../components/auth/PermissionBlock";

interface IStateToProps {
  isDrawerOpen: boolean;
  auth: boolean;
  roles: ROLE[];
}

interface IDispatchToProps {
  contactsModalOpen: () => void;
  loginModalOpen: () => void;
  showDrawer: () => void;
  resetPassword: () => void;
  logOut: () => void;
}

const Header = (props: IStateToProps & IDispatchToProps) => {
  const { auth, roles } = props;

  return (
    <AppBar position="fixed" className="header">
      <div className="container-fluid">
        <Link to="/" className="logo">
          <img src="/images/logo.png" alt="AVORD" />
        </Link>
        {auth ? (
          <div className="float-right">
            <AccountButtons roles={roles} />
          </div>
        ) : (
          <PermissionBlock roles={[ROLE_GUEST]}>
            <div className="register-button-wrapper">
              <span>Consultant/Tester</span>
              <Link to="/tester-registration" className="register-button">
                Register
              </Link>
            </div>
          </PermissionBlock>
        )}
      </div>
    </AppBar>
  );
};

function mapStateToProps(state) {
  return {
    isDrawerOpen: getIsDrawerOpen(state),
    auth: getAuth(state),
    roles: getRoles(state),
  };
}

export default connect<IStateToProps, IDispatchToProps>(mapStateToProps, {
  contactsModalOpen,
  loginModalOpen,
  showDrawer,
  resetPassword,
  logOut,
})(Header);
