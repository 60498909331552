import {
  ADMIT_CRUD_TYPE,
  APP_ACTION_PREFIX,
  TIME_AND_EXPENSES
} from "../constants/action-type";
import * as FilterTypes from "../constants/filter";
import * as LoaderTypes from "../constants/loader";
import * as ActionTypes from "../constants/crud-action-type";
import {
  postItem,
  putItem,
  getItems,
  getItem,
  archiveItem,
  unarchiveItem
} from "./action-request-helper";

export function getActionTypeName(app_prefix, name: ADMIT_CRUD_TYPE, action) {
  return `${APP_ACTION_PREFIX} ${name}_${action}`;
}

export const createAction = (name: ADMIT_CRUD_TYPE) => {
  return (action, payload: any = undefined) => ({
    type: getActionTypeName(APP_ACTION_PREFIX, name, action),
    payload
  });
};

export const createAppActionName = name => {
  return (action, payload: any = undefined) => ({
    type: getActionTypeName(APP_ACTION_PREFIX, name, action),
    payload
  });
};

export const createFilterAction = (name: ADMIT_CRUD_TYPE) => {
  const setActiveFilter = type => createAction(name)(type);
  const setTextFilter = text => createAction(name)(FilterTypes.TEXT, text);

  return {
    submitFilterChanges: (type, text: string | boolean = false) => dispatch => {
      if (!text || text === undefined) {
        text = "";
        dispatch(setTextFilter(text));
      }

      if (!!text) {
        dispatch(setTextFilter(text));
      }

      dispatch(setActiveFilter(type));
    },
    submitFilter: (type, text: string | boolean = false) => dispatch => {
      if (text !== false) {
        dispatch(setTextFilter(text));
      }

      dispatch(setActiveFilter(type));

      dispatch(
        getItems(name)(1, 10, type, (text === false ? "" : text) as string)
      );
    },
    setArchivedFilter: () => createAction(name)(FilterTypes.ARCHIVED),
    setAllFilter: () => createAction(name)(FilterTypes.ACTIVE),
    setActiveFilter,
    setTextFilter
  };
};

export const createLoaderAction = (name: ADMIT_CRUD_TYPE) => {
  return {
    setPageNumber: number =>
      createAction(name)(LoaderTypes.SET_PAGE_NUMBER, number),
    setIsNotLoaded: () => createAction(name)(LoaderTypes.SET_IS_NOT_LOADED),
    setIsLoaded: () => createAction(name)(LoaderTypes.SET_IS_LOADED),
    setItemsPerPage: count =>
      createAction(name)(LoaderTypes.SET_ITEMS_PER_PAGE, count),
    setTotalItems: totalItems =>
      createAction(name)(LoaderTypes.SET_TOTAL_ITEMS, totalItems),
    setPaginatioData: (data, itemsPerPage, totalItems, pageNumber) =>
      createAction(name)(LoaderTypes.SET_PAGINATION_DATA, {
        data,
        itemsPerPage,
        totalItems,
        pageNumber: totalItems <= itemsPerPage ? 1 : pageNumber
      })
  };
};

export const createCrudAction = (name: ADMIT_CRUD_TYPE) => {
  return {
    addItems: number => createAction(name)(ActionTypes.ADD_ITEMS, number),
    addItem: item => createAction(name)(ActionTypes.ADD_ITEM, item),
    removeItem: item => createAction(name)(ActionTypes.REMOVE_ITEM, item),
    replaceItem: item => createAction(name)(ActionTypes.REPLACE_ITEM, item),
    removeItems: items => createAction(name)(ActionTypes.REMOVE_ITEMS, items)
  };
};

export const createRequestAction = (name: ADMIT_CRUD_TYPE) => {
  return {
    postItem: postItem(name),
    putItem: putItem(name),
    getItems: getItems(name),
    getItem: getItem(name),
    archiveItem: archiveItem(name),
    unarchiveItem: unarchiveItem(name)
  };
};
