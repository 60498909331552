import {getApi, getPublicApi} from '../swagger/api-factory';
import {fetchRequestBody} from "../../helpers/request-helper";

export const login = (username: string, password: string) => {
  return getPublicApi()
    .then(api => api.postApiToken({username, password}))
    .then(data => JSON.parse(data.text));
};

export const fetchMyData = () => {
  return getApi()
    .then(api => api.getByUrl('/api/users/me'))
    .then(fetchRequestBody);
};

export const invalidateJWTRequest = (refreshToken: string) => {
  return getApi()
    .then(api => api.postApiTokenInvalidate({
      refreshToken
    }))
    .then(fetchRequestBody);
};
