import * as React from "react";
import { TableHead, WithStyles, Tooltip } from "@material-ui/core";
import DBScertificateSelector from "../../../selectors/dbs-certificate";
import {
  getTesterDBSCertifications,
  testerDBSCertificateArchive
} from "../../../actions/dbs-certificate";
import { dataFetcher } from "../../../components/dataFetcher";
import { IDBSCertificate } from "../../../models/dbs-certificate.interface";
import Table from "@material-ui/core/Table/Table";
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableBody from "@material-ui/core/TableBody/TableBody";
import TableCell from "@material-ui/core/TableCell/TableCell";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Icon from "@material-ui/core/Icon/Icon";
import { connect } from "react-redux";
import { downloadSecureDocument } from "../../../api/requests/document-download";
import Button from "@material-ui/core/Button";
import { API_SECURE_DOCUMENTS_ENDPOINT_URL } from "../../../constants/urls";
import { formatDateWithSlashesDdMmYyyy } from "../../../helpers/date-formatter";
import { getStatusDescription } from "../../../constants/dbs-certificate-statuses";
import { TableNoItems } from "../../../components/table/TableNoItems";

interface IOwnProps {
  certificates: IDBSCertificate[];
  DBScertificateArchive: (id: any) => void;
  downloadDocumentFile: (...any) => void;
  getProps: (props: IDBSCertificate[]) => void;
}

interface IProps {
  getProps: (props: IDBSCertificate[]) => void;

  [key: string]: any;
}

class DBSCertificatesTable extends React.Component<
  IProps & IOwnProps & WithStyles & any,
  {}
> {
  downloadFile = event => {
    const evidenceDocumentId = event.currentTarget.dataset.evidenceid;
    if (evidenceDocumentId) {
      this.props.downloadSecureDocument(evidenceDocumentId);
    }
  };

  render() {
    if (!this.props.dbsCertificates) {
      return <TableNoItems asLoading />;
    }

    this.props.getProps(this.props.dbsCertificates);

    return (
      <div className="table-responsive">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Certificate</TableCell>
              <TableCell>Comment</TableCell>
              <TableCell>Reference No</TableCell>
              <TableCell>
                <span className="d-flex align-baseline">
                  <span className="mr-1 mt-1">Validated</span>
                  <Tooltip
                    disableTouchListener
                    disableFocusListener
                    leaveDelay={1000}
                    placement={"top-start"}
                    title="Next validation to be completed 24 months from the Validation Passed date"
                  >
                    <Icon>info</Icon>
                  </Tooltip>
                </span>
              </TableCell>
              <TableCell>AVORD admin comments</TableCell>
              <TableCell>Archive</TableCell>
              <TableCell>Check status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.dbsCertificates.map((item: IDBSCertificate) => {
              const dbsCertificate = getStatusDescription(item.status);
              return (
                <TableRow key={item.id}>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      data-evidenceid={item.evidence.replace(
                        API_SECURE_DOCUMENTS_ENDPOINT_URL,
                        ""
                      )}
                      onClick={this.downloadFile}
                    >
                      Download
                    </Button>
                  </TableCell>

                  <TableCell>{item.comment}</TableCell>
                  <TableCell>{item.referenceNo}</TableCell>
                  <TableCell>{this.getValidity(item)}</TableCell>
                  <TableCell>{item.adminComment}</TableCell>

                  <TableCell onClick={this.handleArchive(item)}>
                    <IconButton>
                      <Icon>archive</Icon>
                    </IconButton>
                  </TableCell>

                  <TableCell>{dbsCertificate.name}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    );
  }

  private readonly getValidity = (item: IDBSCertificate) => {
    return item.validFrom && formatDateWithSlashesDdMmYyyy(item.validFrom);
  };

  private readonly getValidTo = (item: IDBSCertificate) => {
    if (item.reValidTo) {
      return formatDateWithSlashesDdMmYyyy(item.reValidTo);
    }

    return "";
  };

  private readonly handleArchive = (item: IDBSCertificate) => () => {
    this.props.DBScertificateArchive(item.id);
  };
}

const connected = connect(undefined, {
  DBScertificateArchive: testerDBSCertificateArchive,
  downloadSecureDocument,
  getTesterDBSCertifications
})(DBSCertificatesTable as any);

export default dataFetcher<IProps>(connected, [
  {
    key: "dbsCertificates",
    action: () => getTesterDBSCertifications(),
    selector: (state, props) => DBScertificateSelector.getItemsObject(state),
    alwaysReceiveFreshData: true
  }
]) as any;
