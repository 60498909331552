import { ReactNode, useMemo } from "react";
import EnhancedTableHead from "components/table/EnhancedTableHead";
import TableBody from "@material-ui/core/TableBody/TableBody";
import { Table as MaterialTable, TableCell, TableRow } from "@material-ui/core";
import * as React from "react";
import { connect } from "react-redux";
import { getUserPermissions } from "selectors/auth";
import { ConnectedProps } from "types";

export type ColumnType<T> = {
  key: string;
  name: string;
  render?: (item: T) => ReactNode;
  permissions?: string[];
};

type TableProps<T> = {
  columns: Array<ColumnType<T>>;
  data: T[];
  index: string;
  permissions?: string[];
};

function Table<T>({
  columns,
  data,
  index,
  permissions = []
}: TableProps<T> & PropsFromRedux) {
  const cols = useMemo(() => {
    return columns.filter(({ permissions: needs }) => {
      if (!needs) {
        return true;
      }

      return permissions.some(permission => needs.includes(permission));
    });
  }, [columns]);

  return (
    <MaterialTable padding="dense">
      <EnhancedTableHead columns={cols.map(({ name }) => name)} />
      <TableBody>
        {data.map((item: T) => {
          const rowKey = item[index];

          return (
            <TableRow key={rowKey}>
              {cols.map(({ render, key }) => (
                <TableCell key={`${rowKey}-${key}`}>
                  {render ? render(item) : item[key]}
                </TableCell>
              ))}
            </TableRow>
          );
        })}
      </TableBody>
    </MaterialTable>
  );
}

const connector = connect(state => {
  return {
    permissions: getUserPermissions(state)
  };
}, {});

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Table);
