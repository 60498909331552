export default function () {
  return [
    {
      title: 'Client / Autonomous Vehicle',
      url: '/client-registration',
      description: 'Register for our management tool!',
      image: '/images/slides/Artificial Intelligence.jpg',
      button: 'Register'
    },
    {
      title: 'Tester / Autonomous Vehicle',
      url: '/tester-registration',
      description: 'Register to receive contract offers!',
      image: '/images/slides/Autonomous Car.jpg',
      button: 'Register'
    },
    {
      title: 'Client / Autonomous Vehicle',
      url: '/client-registration',
      description: 'Register for our management tool!',
      image: '/images/slides/Banking.jpg',
      button: 'Register'
    },
    {
      title: 'Client / Autonomous Vehicle',
      url: '/client-registration',
      description: 'Register for our management tool!',
      image: '/images/slides/Big Data 3.jpg',
      button: 'Register'
    },
    {
      title: 'Client / Autonomous Vehicle',
      url: '/client-registration',
      description: 'Register for our management tool!',
      image: '/images/slides/Energy.jpg',
      button: 'Register'
    },
    {
      title: 'Client / Autonomous Vehicle',
      url: '/client-registration',
      description: 'Register for our management tool!',
      image: '/images/slides/Logistics.jpg',
      button: 'Register'
    },
    {
      title: 'Client / Autonomous Vehicle',
      url: '/client-registration',
      description: 'Register for our management tool!',
      image: '/images/slides/Medical.jpg',
      button: 'Register'
    },
    {
      title: 'Client / Autonomous Vehicle',
      url: '/client-registration',
      description: 'Register for our management tool!',
      image: '/images/slides/Mobile.jpg',
      button: 'Register'
    },
    {
      title: 'Client / Autonomous Vehicle',
      url: '/client-registration',
      description: 'Register for our management tool!',
      image: '/images/slides/Mobile Data.jpg',
      button: 'Register'
    },
    {
      title: 'Client / Autonomous Vehicle',
      url: '/client-registration',
      description: 'Register for our management tool!',
      image: '/images/slides/Payments.jpg',
      button: 'Register'
    },
    {
      title: 'Client / Autonomous Vehicle',
      url: '/client-registration',
      description: 'Register for our management tool!',
      image: '/images/slides/Pen Testing.jpg',
      button: 'Register'
    },
    {
      title: 'Client / Autonomous Vehicle',
      url: '/client-registration',
      description: 'Register for our management tool!',
      image: '/images/slides/Shipping.jpg',
      button: 'Register'
    },
    {
      title: 'Client / Autonomous Vehicle',
      url: '/client-registration',
      description: 'Register for our management tool!',
      image: '/images/slides/Software Design.jpg',
      button: 'Register'
    },
    {
      title: 'Client / Autonomous Vehicle',
      url: '/client-registration',
      description: 'Register for our management tool!',
      image: '/images/slides/Software Testing.jpg',
      button: 'Register'
    },
  ]
}
