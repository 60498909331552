import * as React from "react";
import { WrappedFieldProps } from "redux-form/lib/Field";
import { ComponentType } from "react";
import { FormControlLabel, Switch } from "@material-ui/core";

const ReduxSwitch: ComponentType<
  WrappedFieldProps & {
    [key: string]: any;
  }
> = ({ input, label, checked, ...custom }) => {
  return (
    <FormControlLabel
      control={
        <Switch value="true" checked={checked ? checked : input.value} />
      }
      label={label}
      {...input}
      {...custom}
      checked={checked}
    />
  );
};

export default ReduxSwitch;
