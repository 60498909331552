import React, {useEffect} from "react";
import {Icon, Grid, Zoom} from "@material-ui/core";
import Paper from "@material-ui/core/Paper/Paper";
import TooltipIcon from "@material-ui/core/Tooltip/Tooltip";
import Table from "@material-ui/core/Table/Table";
import EnhancedTableHead from "../../../../components/table/EnhancedTableHead";
import TableBody from "@material-ui/core/TableBody/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import ipRangesSelector from "../../../../selectors/ip-ranges";
import {bindActionCreators} from "redux";
import {getIpRangesRequestsAction} from "../../../../actions/ip-ranges";
import {connect} from "react-redux";
import {TableNoItems} from "../../../../components/table/TableNoItems";

export const WhiteListedRangeTooltipText = () => (
  <div>
    <span>This section allows you to restrict user access by specifying and managing whitelisted IP ranges</span><br/>
    <span><b>Examples:</b></span><br/>
    <ul className="pl-4">
      <li>102.129.134.222</li>
      <li>101.36.96.0 - 101.36.97.255</li>
    </ul>
  </div>
)

const WhiteListedRangeList = (props) => {
  const {isLoaded, loadIpRanges, data} = props;
  useEffect(() => {
    loadIpRanges();
  }, []);


  return (
    <div className="pt-4">
      <div className="block-header-wrapper flex-column-in-mobile">
        <h4>
          Whitelisted IP Range
          <TooltipIcon
            placement={"top-start"}
            TransitionComponent={Zoom}
            disableFocusListener
            disableTouchListener
            title={<WhiteListedRangeTooltipText />}
          >
            <Icon style={{marginLeft: "5px", marginBottom: "2px", verticalAlign: "middle"}}>info</Icon>
          </TooltipIcon>
        </h4>
      </div>
      {!isLoaded && <TableNoItems asLoading hasWrapper />}
      {isLoaded && data.length === 0 && <TableNoItems hasWrapper />}
      {isLoaded && data.length > 0 && (
        <Paper className="p-4 mb-4">
          <div className="table-responsive styled-table">
            <Grid container>
              <Grid item xs={12}>
                <Table padding="dense">
                  <EnhancedTableHead
                    columns={["IP address", "Note"]}
                  />
                  <TableBody>
                    {
                      data.map(({address, note}, index:number) => (
                        <TableRow key={index}>
                          <TableCell>
                            {address}
                          </TableCell>
                          <TableCell>
                            {note}
                          </TableCell>
                        </TableRow>
                      ))
                    }
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </div>
        </Paper>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    data: ipRangesSelector.getItems(state),
    isLoaded: ipRangesSelector.getIsLoaded(state),

  };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    loadIpRanges: getIpRangesRequestsAction,
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(WhiteListedRangeList);