import {setRequestInProcess} from "./request";
import {getRequestName} from "../helpers/action-request-helper";
import * as Types from "../constants/action-type";
import {createLoaderAction} from "../helpers/action-helper";
import {handleHttpErrors} from "../helpers/errors/http-error-handler";
import {getIpRanges} from "../api/requests/ip-ranges";

export const ipRangesLoader = createLoaderAction(Types.IP_RANGES);

export const getIpRangesRequestsAction = () => async dispatch => {
  dispatch(setRequestInProcess(true, getRequestName(Types.IP_RANGES, 'getIpRangesRequestsAction')));

  dispatch(ipRangesLoader.setIsNotLoaded());

  let data = [], totalItems;

  try {
    ({items: data, totalItems} = await getIpRanges());
    dispatch(ipRangesLoader.setPaginatioData(data, totalItems, totalItems, 1));
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }
  finally {
    dispatch(setRequestInProcess(false, getRequestName(Types.IP_RANGES, 'getIpRangesRequestsAction')));
  }
};