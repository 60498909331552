import { IOptionItem } from "../interfaces/option-item.interface";

export enum Approach {
  APPROACH_WHITE_HAT = 1,
  APPROACH_BLACK_HAT = 2,
  APPROACH_GREY_HAT = 3,
  APPROACH_NOT_APPLICABLE = 4,
}

export const APPROACHES_LIST: IOptionItem[] = [
  {
    key: Approach.APPROACH_NOT_APPLICABLE,
    value: "Not applicable",
  },
  {
    key: Approach.APPROACH_WHITE_HAT,
    value: "White Box",
  },
  {
    key: Approach.APPROACH_BLACK_HAT,
    value: "Black Box",
  },
  {
    key: Approach.APPROACH_GREY_HAT,
    value: "Grey Box",
  },
];
