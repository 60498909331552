import * as React from "react";
import ReduxTextField from "../../../components/forms/ReduxTextField";
import {FieldGeneric} from "../../../components/forms/FieldGeneric";
import Button from "@material-ui/core/Button/Button";
import {Link} from "react-router-dom";
import {IBackRoute} from "../../../components/routerModal";
import ReduxDatePicker from "../../../components/forms/ReduxDatePicker";

const WorkHistoryForm: React.SFC<IBackRoute> = ({backRoute}) => {
  return (
    <div>
      <h4>Work History Details</h4>
      <hr/>
      <div className="styled-modal-body">
        <FieldGeneric
          inputProps={{minLength: 2, maxLength: 60}}
          name="employer"
          label="Employer *"
          component={ReduxTextField}
        />
        <FieldGeneric
          inputProps={{minLength: 3, maxLength: 60}}
          name="jobTitle"
          label="Job title *"
          component={ReduxTextField}
        />
        <div className="row">
          <div className="col-md-6">
            <FieldGeneric
              name="dateFrom"
              label="From *"
              InputLabelProps={{
                shrink: true
              }}
              disableFuture={true}
              component={ReduxDatePicker}
            />
          </div>
          <div className="col-md-6">
            <FieldGeneric
              name="dateTo"
              label="To *"
              InputLabelProps={{
                shrink: true
              }}
              disableFuture={true}
              component={ReduxDatePicker}
            />
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between mt-4">
        <Link to={backRoute}>
          <Button variant="contained" color="default">
            Cancel
          </Button>
        </Link>
        <Button type="submit" variant="contained" color="primary">
          Save
        </Button>
      </div>
    </div>
  );
};

export default WorkHistoryForm;
