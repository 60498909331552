import React, { Fragment, useState } from "react";
import { NotesThreadType } from "../../../../types/timesheets-and-expenses";
import { reformatTimesheetNoteDate } from "../../../../helpers/date-formatter";

type NotesCellProps = {
  notes: NotesThreadType[];
};

type SeeMoreToggleProps = {
  isOpened: boolean;
  clickHandler: () => void;
};

type convertNotesDataType = {
  messageDate: string;
  messageStatus: string;
  messageText: string;
  messageTextLength: number;
};

const LIMIT_CHARACTERS = 50;

const SeeMoreToggle = ({
  isOpened = false,
  clickHandler
}: SeeMoreToggleProps) => (
  <button type="button" onClick={clickHandler}>
    <span>&nbsp;{isOpened ? "See less" : "See more"}</span>
  </button>
);
const getNotesWithLimitedMessageText = (notes: convertNotesDataType[]) => {
  let currentLength = 0;
  const newArray: convertNotesDataType[] = [];
  for (const note of notes) {
    if (
      currentLength <= LIMIT_CHARACTERS &&
      currentLength + note.messageTextLength <= LIMIT_CHARACTERS
    ) {
      newArray.push(note);
    }
    if (
      currentLength < LIMIT_CHARACTERS &&
      currentLength + note.messageTextLength > LIMIT_CHARACTERS
    ) {
      const textLength = LIMIT_CHARACTERS - currentLength;
      const text = `${note.messageText.slice(0, textLength)}...`;
      newArray.push({ ...note, messageText: text });
    }
    currentLength = currentLength + note.messageTextLength;
  }
  return newArray;
};
const convertNotesData = (notes: NotesThreadType[], isOpened) => {
  let messageTextLength = 0;

  const convertedNotesArray = notes.map(({ date, message }) => {
    const [messageStatus, ...rest] = message.split(" ");
    const messageDate = reformatTimesheetNoteDate(date);
    const messageText = rest.join(" ");
    messageTextLength = messageTextLength + messageText.length;

    return {
      messageDate,
      messageStatus,
      messageText,
      messageTextLength
    };
  });
  const shouldRenderSeeMoreToggle = messageTextLength > LIMIT_CHARACTERS;
  return {
    convertedNotes:
      shouldRenderSeeMoreToggle && !isOpened
        ? getNotesWithLimitedMessageText(convertedNotesArray)
        : convertedNotesArray,
    shouldRenderSeeMoreToggle
  };
};

export const NotesCell = ({ notes }: NotesCellProps): JSX.Element | null => {
  const [isOpened, setIsOpened] = useState<boolean>(false);

  if (Array.isArray(notes) && notes.length) {
    const { convertedNotes, shouldRenderSeeMoreToggle } = convertNotesData(
      notes,
      isOpened
    );

    const notesList = convertedNotes.map((item, index, array) => {
      const isLast = index === array.length - 1;
      return (
        <Fragment key={index}>
          <span key={index}>
            {item.messageDate}{" "}
            <span
              className={`timesheet-notes-status timesheet-status--${item.messageStatus.toLowerCase()}`}
            >
              {item.messageStatus}
            </span>{" "}
            {item.messageText}
          </span>
          {!isLast && <br />}
        </Fragment>
      );
    });

    return (
      <>
        {notesList}
        {shouldRenderSeeMoreToggle && (
          <SeeMoreToggle
            isOpened={isOpened}
            clickHandler={() => setIsOpened(!isOpened)}
          />
        )}
      </>
    );
  }
  return null;
};
