import React from "react";
import {connect} from "react-redux";
import getLastReports from "../../../actions/lastReports";
import {Link} from "react-router-dom";
import {formatDateWithTime} from "../../../helpers/date-formatter";
import StatusBlock from "../../common/StatusBlock";
import {BASE_URL} from "../../../constants/urls";
import {getCurrency} from "../../../helpers/currency";

class DashboardBookingReports extends React.Component {
  componentDidMount() {
    this.props.getLastReports();
  }

  render() {
    const {lastReports} = this.props;

    if (!lastReports) {
      return (
        <div className="styled-block">
          <h4 className="block-header mb-4">Last Booking Reports</h4>
          <div className="table-no-items">Loading...</div>
        </div>
      );
    }

    if (!lastReports.length) {
      return (
        <div className="styled-block">
          <h4 className="block-header mb-4">Last Booking Reports</h4>
          <div className="table-no-items">There are no items...</div>
        </div>
      );
    }

    return (
      <div className="styled-block">
        <div className="block-header-wrapper">
          <h4 className="block-header mb-4">Last Booking Reports</h4>
        </div>
        <div className="table-responsive styled-table">
          <table>
            <tbody>
            <tr>
              <th>Test</th>
              <th>Project</th>
              <th>
                <span>Virtual<br/>Team</span>
              </th>
              <th>Tester</th>
              <th>Date Start</th>
              <th>Date End</th>
              <th>Status</th>
              <th className="total">Total Cost</th>
            </tr>
            {lastReports.map((report) => (
              <tr key={report.id}>
                <td><Link to={`/client/reports/${report.id}/details`}>{report.name}</Link></td>
                <td>{report.project.name}</td>
                <td>{(report.tester.isFavorite)
                  ? (
                    <span className="btn-manage pt-2"/>
                  )
                  : (
                    <span className="btn-manage-inactive pt-2"/>
                  )}
                </td>
                <td>
                  {report.tester.profileImagePath ?
                    <div className="tester-image-name">
                      <img src={`${BASE_URL}${report.tester.profileImagePath}`} alt={report.testerName}/>
                      <span>{report.testerName}</span>
                    </div>
                    :
                    <div className="tester-image-name">
                      <img src={`/images/user.svg`} alt="No images"/>
                      <span>{report.testerName}</span>
                    </div>
                  }
                </td>
                <td>{formatDateWithTime(String(report.proposal.dateStart))}</td>
                <td>{formatDateWithTime(String(report.proposal.dateEnd))}</td>
                <td>
                  {report.status && <StatusBlock status={report.status}/>}
                </td>
                <td className="total">
                  {report.tester.currencyInfo.symbol}{getCurrency(report.proposal.rate * report.proposal.countDays)}
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    lastReports: state.dashboard.lastReports
  }),

  {
    getLastReports
  }
)(DashboardBookingReports);
