import * as React from "react";
import {ITesterProfileGet} from "../../../../models/tester-profile.interface";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {dataFetcher} from "../../../../components/dataFetcher";
import {IReview} from "../../../../models/review.interface";
import {getTesterReviews} from "../../../../selectors/tester-reviews";
import {getTestersReviewsAction} from "../../../../actions/tester-reviews";
import TestersView from "./TestersView";
import testersSelector from "../../../../selectors/testers";
import {testersRequest} from "../../../../actions/testers";
import TestersReview from "./TestersReview";
import countriesSelector from "../../../../selectors/countries";
import {countriesRequest} from "../../../../actions/countries";
import {ICountry} from "src/models/dialing-code.interface";
import {ACTIVE} from "../../../../constants/filter";

type IProps = {
  reviews: IReview[];
  tester: ITesterProfileGet;
  countries: ICountry[];
} & RouteComponentProps<{ tester: string; project: string; test: string }>;

class TestersReviews extends React.Component<IProps, {}> {

  render() {
    const canRenderButton: boolean =
      this.props.match.params.project !== "undefined"
      && this.props.match.params.test !== "undefined";

    return (
      <div>
        <div className="mb-4">
          <span
            onClick={this.props.history.goBack}
            className="btn-back"
          >
            {canRenderButton ? 'Back to search results' : 'Back'}
          </span>
        </div>

        <TestersView item={this.props.tester} showSeeReviewsLink={false} canRenderButton={canRenderButton}/>

        {this.props.reviews && this.props.reviews.length > 0 ? (
          this.props.reviews.map(review => (
            <TestersReview key={review.id} item={review}/>
          ))
        ) : (
          <div className="styled-block table-no-items">
            No reviews
          </div>
        )}
      </div>
    );
  }
}

const fetched: any = dataFetcher(TestersReviews, [
  {
    key: "reviews",
    selector: (state, props: IProps) =>
      getTesterReviews(props.match.params.tester)(state),
    action: (props: IProps) =>
      getTestersReviewsAction(props.match.params.tester),
    alwaysReceiveFreshData: true
  },
  {
    key: "tester",
    selector: (state, props: IProps) =>
      testersSelector.getItemById(props.match.params.tester)(state),
    action: (props: IProps) => testersRequest.getItem(props.match.params.tester)
  },
  {
    key: "countries",
    action: () => countriesRequest.getItems(1, 1000, ACTIVE),
    selector: (state, props) => countriesSelector.getItemsObject(state),
    alwaysReceiveFreshData: true
  }
]);

export default withRouter(fetched);
