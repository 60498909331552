import {invert} from 'lodash';

export enum ReportDocumentStatus {
  STATUS_UPLOADED = 10, // #99CC66 circle
  STATUS_REUPLOADED = 4, // #99CC66 circle
  STATUS_ACCEPTED = 1, // #99CC66
  STATUS_SUBMITTED = 2, // #99CC66 circle
  STATUS_AMENDMENTS_REQUESTED = 3, // #FFCC00
}

export enum ReportDocumentStatusNames {
  STATUS_UPLOADED = 'Uploaded',
  STATUS_REUPLOADED = 'Reuploaded',
  STATUS_ACCEPTED = 'Report Document Accepted',
  STATUS_SUBMITTED = 'Report Document Submitted',
  STATUS_AMENDMENTS_REQUESTED = 'Clarifications requested',
}

export const getReportDocumentStatusName = (data: ReportDocumentStatus): string => {
  return ReportDocumentStatusNames[invert(ReportDocumentStatus)[data]];
};

export const reportDocumentsSubmitted = (data: ReportDocumentStatus): boolean => {
  return data === ReportDocumentStatus.STATUS_ACCEPTED || data === ReportDocumentStatus.STATUS_SUBMITTED
};

export const reportDocumentCanBeDeleted = (data: ReportDocumentStatus): boolean => {
  return data === ReportDocumentStatus.STATUS_UPLOADED ||
    data === ReportDocumentStatus.STATUS_AMENDMENTS_REQUESTED ||
    data === ReportDocumentStatus.STATUS_REUPLOADED
};

export const reportDocumentIsUploadedOrNeedClarifications = (data: ReportDocumentStatus): boolean => {
  return data === ReportDocumentStatus.STATUS_UPLOADED || data === ReportDocumentStatus.STATUS_AMENDMENTS_REQUESTED
};

export const reportDocumentShouldBeReUploaded = (data: ReportDocumentStatus): boolean => {
  return data === ReportDocumentStatus.STATUS_AMENDMENTS_REQUESTED || data === ReportDocumentStatus.STATUS_REUPLOADED
};

export const reportDocumentCanBeSubmitted = (data: ReportDocumentStatus): boolean => {
  //return data === ReportDocumentStatus.STATUS_UPLOADED || data === ReportDocumentStatus.STATUS_AMENDMENTS_REQUESTED
  return [
    ReportDocumentStatus.STATUS_UPLOADED,
    ReportDocumentStatus.STATUS_REUPLOADED,
    ReportDocumentStatus.STATUS_SUBMITTED].some(status => status === data);
};
