export const APP_ACTION_PREFIX = "[App]";

export type ADMIT_CRUD_TYPE =
  | "FAQ"
  | "EMAIL_TEMPLATES"
  | "GET_SPECIALISMS_WITHOUT_REPORT_TEMPLATES"
  | "SPECIALISMS_WITHOUT_REPORT_TEMPLATES"
  | "COUNTRY"
  | "CAROUSEL"
  | "SPECIALISM"
  | "FINANCE"
  | "SECTOR"
  | "CONTENT_MANAGEMENT"
  | "TESTERS"
  | "WORK_HISTORY"
  | "LOCATIONS"
  | "USERS"
  | "CERTIFICATE_TYPE"
  | "CLIENTS"
  | "DBS_CERTIFICATES"
  | "CERTIFICATES"
  | "EXPERIENCE"
  | "INSURANCES"
  | "PROJECT"
  | "LAST_RISKS"
  | "LAST_QUESTION"
  | "LAST_MESSAGE"
  | "LAST_REPORTS"
  | "TEST"
  | "QUESTION"
  | "MESSAGE"
  | "PROPOSAL"
  | "INVOICE"
  | "REVIEW"
  | "REPORTS"
  | "REPORT_DOCUMENTS"
  | "RISKS"
  | "DOCUMENT"
  | "TEST_DOCUMENT"
  | "TESTER_COMPANY"
  | "TESTER_EXTERNAL_COMPANY"
  | "COMPANY_DETAILS"
  | "COMPANY_PROJECTS"
  | "REASSIGN_USERS"
  | "APPLICATION_SETTINGS"
  | "TESTS_CHART"
  | "PROJECTS_COST_CHART"
  | "FAVORITE_TESTERS"
  | "GROUP"
  | "GROUP_SEARCH"
  | "GET_AUDIT_LOG"
  | "EMAIL_DOMAINS"
  | "IP_RANGES"
  | "CLIENT_ADMIN_SETTINGS"
  | "CONSULTANTS"
  | "TIME_AND_EXPENSES"
  | "TIMESHEET_TIME_CATEGORIES"
  | "TIMESHEET_EXPENSES_TYPES";

export const FAQ = `FAQ` as ADMIT_CRUD_TYPE;
export const RISKS = `RISKS` as ADMIT_CRUD_TYPE;
export const RISK_TREATMENTS = `RISK_TREATMENTS` as ADMIT_CRUD_TYPE;
export const EMAIL_TEMPLATES = `EMAIL_TEMPLATES` as ADMIT_CRUD_TYPE;
export const COUNTRY = `COUNTRY` as ADMIT_CRUD_TYPE;
export const CAROUSEL = `CAROUSEL` as ADMIT_CRUD_TYPE;
export const SPECIALISM = `SPECIALISM` as ADMIT_CRUD_TYPE;
export const PROPOSAL = `PROPOSAL` as ADMIT_CRUD_TYPE;

export const GET_USER = "GET_USER";

export const SPECIALISMS_WITHOUT_REPORT_TEMPLATES = `SPECIALISMS_WITHOUT_REPORT_TEMPLATES` as ADMIT_CRUD_TYPE;
export const GET_SPECIALISMS_WITHOUT_REPORT_TEMPLATES = `GET_SPECIALISMS_WITHOUT_REPORT_TEMPLATES` as ADMIT_CRUD_TYPE;
export const CREATE_SPECIALISMS_WITHOUT_REPORT_TEMPLATES = `CREATE_SPECIALISMS_WITHOUT_REPORT_TEMPLATES` as ADMIT_CRUD_TYPE;
export const UPDATE_SPECIALISMS_WITHOUT_REPORT_TEMPLATES = `UPDATE_SPECIALISMS_WITHOUT_REPORT_TEMPLATES` as ADMIT_CRUD_TYPE;

export const DOWNLOAD_SPECIALISM_REPOERT_TEMPLATE = `DOWNLOAD_SPECIALISM_REPOERT_TEMPLATE` as ADMIT_CRUD_TYPE;
export const FINANCE = `FINANCE` as ADMIT_CRUD_TYPE;
export const SECTOR = `SECTOR` as ADMIT_CRUD_TYPE;
export const CERTIFICATE_TYPE = `CERTIFICATE_TYPE` as ADMIT_CRUD_TYPE;
export const CONTENT_MANAGEMENT = `CONTENT_MANAGEMENT` as ADMIT_CRUD_TYPE;
export const TESTERS = `TESTERS` as ADMIT_CRUD_TYPE;
export const CERTIFICATES = `CERTIFICATES` as ADMIT_CRUD_TYPE;
export const INSURANCES = `INSURANCES` as ADMIT_CRUD_TYPE;
export const DBS_CERTIFICATES = `DBS_CERTIFICATES` as ADMIT_CRUD_TYPE;
export const EXPERIENCE = `EXPERIENCE` as ADMIT_CRUD_TYPE;
export const WORK_HISTORY = `WORK_HISTORY` as ADMIT_CRUD_TYPE;
export const LOCATIONS = `LOCATIONS` as ADMIT_CRUD_TYPE;
export const USERS = `USERS` as ADMIT_CRUD_TYPE;
export const CLIENTS = `CLIENTS` as ADMIT_CRUD_TYPE;
export const PROJECT = `PROJECT` as ADMIT_CRUD_TYPE;
export const TEST = `TEST` as ADMIT_CRUD_TYPE;
export const PROJECTS_FOR_SCHEDULING = `PROJECTS_FOR_SCHEDULING` as ADMIT_CRUD_TYPE;
export const LAST_QUESTION = `LAST_QUESTION` as ADMIT_CRUD_TYPE;
export const LAST_RISKS = `LAST_RISKS` as ADMIT_CRUD_TYPE;
export const LAST_MESSAGE = `LAST_MESSAGE` as ADMIT_CRUD_TYPE;
export const LAST_REPORTS = `LAST_REPORTS` as ADMIT_CRUD_TYPE;
export const QUESTION = `QUESTION` as ADMIT_CRUD_TYPE;
export const MESSAGE = `MESSAGE` as ADMIT_CRUD_TYPE;
export const INVOICE = `INVOICE` as ADMIT_CRUD_TYPE;
export const REPORTS = `REPORTS` as ADMIT_CRUD_TYPE;
export const REPORT_DOCUMENTS = `REPORT_DOCUMENTS` as ADMIT_CRUD_TYPE;
export const DOCUMENT = `DOCUMENT` as ADMIT_CRUD_TYPE;
export const REVIEW = `REVIEW` as ADMIT_CRUD_TYPE;
export const TEST_DOCUMENT = `TEST_DOCUMENT` as ADMIT_CRUD_TYPE;
export const TEST_ADDITIONAL_DOCUMENT = `TEST_ADDITIONAL_DOCUMENT` as ADMIT_CRUD_TYPE;
export const COMPANY_PROJECTS = `COMPANY_PROJECTS` as ADMIT_CRUD_TYPE;
export const COMPANY_DETAILS = `COMPANY_DETAILS` as ADMIT_CRUD_TYPE;
export const REASSIGN_USERS = `REASSIGN_USERS` as ADMIT_CRUD_TYPE;

export const CONSULTANT_DAYS = `CONSULTANT_DAYS` as ADMIT_CRUD_TYPE;

export const TESTER_RIGHTS_TO_WORK_COUNTRIES = `${APP_ACTION_PREFIX} TESTER_RIGHTS_TO_WORK_COUNTRIES` as ADMIT_CRUD_TYPE;

export const APPLICATION_SETTINGS = `APPLICATION_SETTINGS` as ADMIT_CRUD_TYPE;

export const TESTER_BOOKING_REQUESTS = `TESTER_BOOKING_REQUESTS` as ADMIT_CRUD_TYPE;
export const TESTER_BOOKINGS = `TESTER_BOOKINGS` as ADMIT_CRUD_TYPE;

export const CLIENT_BOOKING_REQUESTS = `CLIENT_BOOKING_REQUESTS` as ADMIT_CRUD_TYPE;
export const TEST_REPORTS = `TEST_REPORTS` as ADMIT_CRUD_TYPE;
export const TEST_AND_PROJECTS = `TEST_AND_PROJECTS` as ADMIT_CRUD_TYPE;

export const RESET_PASSWORD = `${APP_ACTION_PREFIX} RESET_PASSWORD`;
export const CHANGE_PASSWORD = `${APP_ACTION_PREFIX} CHANGE_PASSWORD`;

export const MODAL_CONTACT_OPEN = `${APP_ACTION_PREFIX} MODAL_CONTACT_OPEN`;
export const MODAL_CONTACT_CLOSE = `${APP_ACTION_PREFIX} MODAL_CONTACT_CLOSE`;
export const MODAL_AFTER_REGISTRATION_OPEN = `${APP_ACTION_PREFIX} MODAL_AFTER_REGISTRATION_OPEN`;
export const MODAL_AFTER_REGISTRATION_CLOSE = `${APP_ACTION_PREFIX} MODAL_AFTER_REGISTRATION_CLOSE`;
export const CONTACT_US_FORM_SENDED = `${APP_ACTION_PREFIX} CONTACT_US_FORM_SENDED`;
export const LOGIN_COMPLETED = `${APP_ACTION_PREFIX} LOGIN_COMPLETED`;
export const REQUEST_IN_PROCESS = `${APP_ACTION_PREFIX} REQUEST_IN_PROCESS`;
export const OPEN_SNACKBAR = `${APP_ACTION_PREFIX} OPEN_SNACKBAR`;

export const MODAL_LOGIN_OPEN = `${APP_ACTION_PREFIX} MODAL_LOGIN_OPEN`;
export const MODAL_LOGIN_CLOSE = `${APP_ACTION_PREFIX} MODAL_LOGIN_CLOSE`;
export const MODAL_FORGOT_PASSWORD_OPEN = `${APP_ACTION_PREFIX} MODAL_FORGOT_PASSWORD_OPEN`;
export const MODAL_FORGOT_PASSWORD_CLOSE = `${APP_ACTION_PREFIX} MODAL_FORGOT_PASSWORD_CLOSE`;

export const MODAL_TWO_FACTOR_INIT_OPEN = `${APP_ACTION_PREFIX} MODAL_TWO_FACTOR_INIT_OPEN`;
export const MODAL_TWO_FACTOR_INIT_CLOSE = `${APP_ACTION_PREFIX} MODAL_TWO_FACTOR_INIT_CLOSE`;
export const MODAL_TWO_FACTOR_CHECK_OPEN = `${APP_ACTION_PREFIX} MODAL_TWO_FACTOR_CHECK_OPEN`;
export const MODAL_TWO_FACTOR_CHECK_CLOSE = `${APP_ACTION_PREFIX} MODAL_TWO_FACTOR_CHECK_CLOSE`;

export const CHANGE_PASSWORD_MODAL_OPEN = `${APP_ACTION_PREFIX} MODAL_CHANGE_PASSWORD_OPEN`;
export const CHANGE_PASSWORD_MODAL_CLOSE = `${APP_ACTION_PREFIX} CHANGE_PASSWORD_MODAL_CLOSE`;

export const DELETE_TEST_MODAL_OPEN = `${APP_ACTION_PREFIX} DELETE_TEST_MODAL_OPEN`;
export const DELETE_TEST_MODAL_CLOSE = `${APP_ACTION_PREFIX} DELETE_TEST_MODAL_CLOSE`;
export const DELETE_TEST_MODAL_SUBMIT = `${APP_ACTION_PREFIX} DELETE_TEST_MODAL_SUBMIT`;

export const DELETE_TEST_MODAL_SUBMIT_WITHOUT_REDIRECT = `${APP_ACTION_PREFIX} DELETE_TEST_MODAL_SUBMIT_WITHOUT_REDIRECT`;

export const CALENDAR_MODAL_OPEN = `${APP_ACTION_PREFIX} CALENDAR_MODAL_OPEN`;
export const CALENDAR_MODAL_CLOSE = `${APP_ACTION_PREFIX} CALENDAR_MODAL_CLOSE`;

export const REQUEST_ACCOUNT_DELETION_MODAL_OPEN = `${APP_ACTION_PREFIX} REQUEST_ACCOUNT_DELETION_MODAL_OPEN`;
export const REQUEST_ACCOUNT_DELETION_MODAL_CLOSE = `${APP_ACTION_PREFIX} REQUEST_ACCOUNT_DELETION_MODAL_CLOSE`;
export const REQUEST_ACCOUNT_DELETION_MODAL_SUBMIT = `${APP_ACTION_PREFIX} REQUEST_ACCOUNT_DELETION_MODAL_SUBMIT`;

export const TESTER_ACCOUNT_MODAL_OPEN = `${APP_ACTION_PREFIX} TESTER_ACCOUNT_MODAL_OPEN`;
export const TESTER_ACCOUNT_MODAL_CLOSE = `${APP_ACTION_PREFIX} TESTER_ACCOUNT_MODAL_CLOSE`;
export const TESTER_ACCOUNT_MODAL_SUBMIT = `${APP_ACTION_PREFIX} TESTER_ACCOUNT_MODAL_SUBMIT`;

export const CLIENT_ACCOUNT_MODAL_OPEN = `${APP_ACTION_PREFIX} CLIENT_ACCOUNT_MODAL_OPEN`;
export const CLIENT_ACCOUNT_MODAL_CLOSE = `${APP_ACTION_PREFIX} CLIENT_ACCOUNT_MODAL_CLOSE`;
export const CLIENT_ACCOUNT_MODAL_SUBMIT = `${APP_ACTION_PREFIX} CLIENT_ACCOUNT_MODAL_SUBMIT`;

export const APPROACH_MODAL_OPEN = `${APP_ACTION_PREFIX} APPROACH_MODAL_OPEN`;
export const APPROACH_MODAL_CLOSE = `${APP_ACTION_PREFIX} APPROACH_MODAL_CLOSE`;

export const REASSIGN_MODAL_OPEN = `${APP_ACTION_PREFIX} REASSIGN_MODAL_OPEN`;
export const REASSIGN_MODAL_GROUP = `${APP_ACTION_PREFIX} REASSIGN_MODAL_GROUP`;
export const REASSIGN_MODAL_CLOSE = `${APP_ACTION_PREFIX} REASSIGN_MODAL_CLOSE`;
export const REASSIGN_MODAL_TITLE = `${APP_ACTION_PREFIX} REASSIGN_MODAL_TITLE`;

export const QUESTION_MODAL_OPEN = `${APP_ACTION_PREFIX} QUESTION_MODAL_OPEN`;
export const QUESTION_MODAL_CLOSE = `${APP_ACTION_PREFIX} QUESTION_MODAL_CLOSE`;

export const QUESTION_NEW_MODAL_OPEN = `${APP_ACTION_PREFIX} QUESTION_NEW_MODAL_OPEN`;
export const QUESTION_NEW_MODAL_CLOSE = `${APP_ACTION_PREFIX} QUESTION_NEW_MODAL_CLOSE`;

export const MESSAGE_MODAL_OPEN = `${APP_ACTION_PREFIX} MESSAGE_MODAL_OPEN`;
export const MESSAGE_MODAL_CLOSE = `${APP_ACTION_PREFIX} MESSAGE_MODAL_CLOSE`;

export const MESSAGE_NEW_MODAL_OPEN = `${APP_ACTION_PREFIX} MESSAGE_NEW_MODAL_OPEN`;
export const MESSAGE_NEW_MODAL_CLOSE = `${APP_ACTION_PREFIX} MESSAGE_NEW_MODAL_CLOSE`;

export const BOOKING_REJECT_MODAL_OPEN = `${APP_ACTION_PREFIX} BOOKING_REJECT_MODAL_OPEN`;
export const BOOKING_REJECT_MODAL_CLOSE = `${APP_ACTION_PREFIX} BOOKING_REJECT_MODAL_CLOSE`;
export const BOOKING_REJECT_MODAL_SUBMIT = `${APP_ACTION_PREFIX} BOOKING_REJECT_MODAL_SUBMIT`;

export const BOOKING_CANCEL_MODAL_OPEN = `${APP_ACTION_PREFIX} BOOKING_CANCEL_MODAL_OPEN`;
export const BOOKING_CANCEL_MODAL_CLOSE = `${APP_ACTION_PREFIX} BOOKING_CANCEL_MODAL_CLOSE`;
export const BOOKING_CANCEL_MODAL_SUBMIT = `${APP_ACTION_PREFIX} BOOKING_CANCEL_MODAL_SUBMIT`;

export const AVORD_VALIDATION_MODAL_OPEN = `${APP_ACTION_PREFIX} AVORD_VALIDATION_MODAL_OPEN`;
export const AVORD_VALIDATION_MODAL_CLOSE = `${APP_ACTION_PREFIX} AVORD_VALIDATION_MODAL_CLOSE`;

export const EDIT_PROPOSAL_BY_TESTER_MODAL_OPEN = `${APP_ACTION_PREFIX} EDIT_PROPOSAL_BY_TESTER_MODAL_OPEN`;
export const EDIT_PROPOSAL_BY_TESTER_MODAL_CLOSE = `${APP_ACTION_PREFIX} EDIT_PROPOSAL_BY_TESTER_MODAL_CLOSE`;

export const ACCEPT_EDIT_PROPOSAL_BY_TESTER_MODAL_OPEN = `${APP_ACTION_PREFIX} ACCEPT_EDIT_PROPOSAL_BY_TESTER_MODAL_OPEN`;
export const ACCEPT_EDIT_PROPOSAL_BY_TESTER_MODAL_CLOSE = `${APP_ACTION_PREFIX} ACCEPT_EDIT_PROPOSAL_BY_TESTER_MODAL_CLOSE`;

export const AMENDMENTS_MODAL_OPEN = `${APP_ACTION_PREFIX} AMENDMENTS_MODAL_OPEN`;
export const AMENDMENTS_MODAL_CLOSE = `${APP_ACTION_PREFIX} AMENDMENTS_MODAL_CLOSE`;

export const AMENDMENTS_ANSWER_MODAL_OPEN = `${APP_ACTION_PREFIX} AMENDMENTS_ANSWER_MODAL_OPEN`;
export const AMENDMENTS_ANSWER_MODAL_CLOSE = `${APP_ACTION_PREFIX} AMENDMENTS_ANSWER_MODAL_CLOSE`;

export const REPORT_ACCEPTED_MODAL_OPEN = `${APP_ACTION_PREFIX} REPORT_ACCEPTED_MODAL_OPEN`;
export const REPORT_ACCEPTED_MODAL_CLOSE = `${APP_ACTION_PREFIX} REPORT_ACCEPTED_MODAL_CLOSE`;

export const REPORT_DOCUMENT_MODAL_OPEN = `${APP_ACTION_PREFIX} REPORT_DOCUMENT_MODAL_OPEN`;
export const REPORT_DOCUMENT_MODAL_CLOSE = `${APP_ACTION_PREFIX} REPORT_DOCUMENT_MODAL_CLOSE`;

export const SET_EXTRA_PERMISSIONS = `${APP_ACTION_PREFIX} SET_EXTRA_PERMISSIONS`;
export const SET_PERMISSIONS = `${APP_ACTION_PREFIX} SET_PERMISSIONS`;
export const CLEAR_STORE = `${APP_ACTION_PREFIX} CLEAR_STORE`;

export const CERTIFICATES_GET = `${APP_ACTION_PREFIX} CERTIFICATES_GET`;
export const CERTIFICATE_POST = `${APP_ACTION_PREFIX} CERTIFICATE_POST`;
export const CERTIFICATE_ARCHIVE = `${APP_ACTION_PREFIX} CERTIFICATE_ARCHIVE`;

export const DBS_CERTIFICATES_GET = `${APP_ACTION_PREFIX} DBS_CERTIFICATES_GET`;
export const DBS_CERTIFICATE_POST = `${APP_ACTION_PREFIX} DBS_CERTIFICATE_POST`;
export const DBS_CERTIFICATE_ARCHIVE = `${APP_ACTION_PREFIX} DBS_CERTIFICATE_ARCHIVE`;

export const INSURANCES_GET = `${APP_ACTION_PREFIX} INSURANCES_GET`;

export const DOCUMENT_POST = `${APP_ACTION_PREFIX} DOCUMENT_POST`;
export const DOCUMENT_POST_COMPLETED = `${APP_ACTION_PREFIX} DOCUMENT_POST_COMPLETED`;
export const DOCUMENT_ARCHIVE = `${APP_ACTION_PREFIX} DOCUMENT_ARCHIVE`;
export const ADDITIONAL_DOCUMENT_ARCHIVE = `${APP_ACTION_PREFIX} ADDITIONAL_DOCUMENT_ARCHIVE`;
export const DOCUMENT_SEARCH_APPLY = `${APP_ACTION_PREFIX} DOCUMENT_SEARCH_APPLY`;
export const ADDITIONAL_DOCUMENT_SEARCH_APPLY = `${APP_ACTION_PREFIX} ADDITIONAL_DOCUMENT_SEARCH_APPLY`;

export const SECURED_DOCUMENT_POST = `${APP_ACTION_PREFIX} SECURED_DOCUMENT_POST`;
export const SECURED_DOCUMENT_POST_COMPLETED = `${APP_ACTION_PREFIX} SECURED_DOCUMENT_POST_COMPLETED`;

export const PROFILE_IMAGE_POST = `${APP_ACTION_PREFIX} IMAGE_POST`;
export const PROFILE_IMAGE_POST_COMPLETED = `${APP_ACTION_PREFIX} IMAGE_POST_COMPLETED`;

export const SHOW_DRAWER = `${APP_ACTION_PREFIX} SHOW_DRAWER`;
export const HIDE_DRAWER = `${APP_ACTION_PREFIX} HIDE_DRAWER`;

export const SHOW_ACCOUNT_MENU = `${APP_ACTION_PREFIX} SHOW_ACCOUNT_MENU`;
export const HIDE_ACCOUNT_MENU = `${APP_ACTION_PREFIX} HIDE_ACCOUNT_MENU`;

export const CONTENT_MANAGEMENT_GET_BY_KEY = `${APP_ACTION_PREFIX} CONTENT_MANAGEMENT_GET_BY_KEY`;

export const SPECIALISM_ADD_ITEMS_NESTED = `${APP_ACTION_PREFIX} SPECIALISM_ADD_ITEMS_NESTED`;

export const FINANCE_ADD_ITEMS = `${APP_ACTION_PREFIX} FINANCE_ADD_ITEMS`;
export const FINANCE_PUT_ITEMS = `${APP_ACTION_PREFIX} FINANCE_PUT_ITEMS`;
export const FINANCE_GET_ITEM = `${APP_ACTION_PREFIX} FINANCE_GET_ITEM`;

export const ADDITIONAL_SERVICE_GET_ITEM = `${APP_ACTION_PREFIX} ADDITIONAL_SERVICE_GET_ITEM`;

export const SPECIALISM_SAVE_BATCH = `${APP_ACTION_PREFIX} SPECIALISM_SAVE_BATCH`;

export type LOADER_ITEM_TYPE =
  | "TESTER_PROFILE"
  | "TESTER_BANK_DETAILS"
  | "TESTER_CERTIFICATES"
  | "TESTER_SPECIALISMS";

export const TESTER_PROFILE = "TESTER_PROFILE" as LOADER_ITEM_TYPE;
export const TESTER_BANK_DETAILS = "TESTER_BANK_DETAILS" as LOADER_ITEM_TYPE;
export const TESTER_CERTIFICATES = "TESTER_CERTIFICATES" as LOADER_ITEM_TYPE;
export const TESTER_SPECIALISMS = "TESTER_SPECIALISMS" as LOADER_ITEM_TYPE;

export const TESTER_SPECIALISMS_EDIT_OPEN = `${APP_ACTION_PREFIX} TESTER_SPECIALISMS_EDIT_OPEN`;
export const TESTER_SPECIALISMS_EDIT_CLOSE = `${APP_ACTION_PREFIX} TESTER_SPECIALISMS_EDIT_CLOSE`;
export const TESTER_SPECIALISMS_PUT = `${APP_ACTION_PREFIX} TESTER_SPECIALISMS_PUT`;

export const LOCATIONS_SET_SEARCH_TEXT = `${APP_ACTION_PREFIX} LOCATIONS_SET_SEARCH_TEXT`;
export const FORM_SEARCH_FIELD_SET = `${APP_ACTION_PREFIX} FORM_SEARCH_FIELD_SET`;

export const TESTER_REVIEWS_ADD = `${APP_ACTION_PREFIX} TESTER_REVIEWS_ADD`;
export const SET_IS_TESTER_NAME_BLOCKED = `${APP_ACTION_PREFIX} SET_IS_TESTER_NAME_BLOCKED`;

export const CALENDAR_SET_VIEW = `${APP_ACTION_PREFIX} CALENDAR_SET_VIEW`;
export const CALENDAR_SET_DATE_RANGE = `${APP_ACTION_PREFIX} CALENDAR_SET_DATE_RANGE`;
export const CALENDAR_SET_DATE = `${APP_ACTION_PREFIX} CALENDAR_SET_DATE`;
export const CALENDAR_SET_EVENTS = `${APP_ACTION_PREFIX} CALENDAR_SET_EVENTS`;

export const TESTER_PROFILE_SET_WORK_HISTORIES = `${APP_ACTION_PREFIX} TESTER_PROFILE_SET_WORK_HISTORIES`;
export const TESTER_PROFILE_SET_REVIEWS = `${APP_ACTION_PREFIX} TESTER_PROFILE_SET_REVIEWS`;
export const TESTER_PROFILE_SET_CERTIFICATES = `${APP_ACTION_PREFIX} TESTER_PROFILE_SET_CERTIFICATES`;
export const TESTER_PROFILE_SET_DETAILS = `${APP_ACTION_PREFIX} TESTER_PROFILE_SET_DETAILS`;

export const QUESTION_SEARCH_APPLY = `${APP_ACTION_PREFIX} QUESTION_SEARCH_APPLY`;
export const AMENDMENTS_SEARCH_APPLY = `${APP_ACTION_PREFIX} AMENDMENTS_SEARCH_APPLY`;
export const MESSAGE_SEARCH_APPLY = `${APP_ACTION_PREFIX} MESSAGE_SEARCH_APPLY`;

export const BOOKING_PAGE_TESTS_LIST_IS_EMPTY = `${APP_ACTION_PREFIX} BOOKING_PAGE_TESTS_LIST_IS_EMPTY`;
export const BOOKING_PAGE_TEST_ITEM_IS_EMPTY = `${APP_ACTION_PREFIX} BOOKING_PAGE_TEST_ITEM_IS_EMPTY`;

export const LINKEDIN_COMPANIES_RECIEVED = `${APP_ACTION_PREFIX} LINKEDIN_COMPANIES_RECIEVED`;

export const RESET_PASSWORD_CONFIRMATION = `${APP_ACTION_PREFIX} RESET_PASSWORD_CONFIRMATION`;

export const TESTS_CHART = `TESTS_CHART` as ADMIT_CRUD_TYPE;
export const PROJECTS_COST_CHART = `PROJECTS_COST_CHART` as ADMIT_CRUD_TYPE;

export const FAVORITE_TESTERS = `FAVORITE_TESTERS` as ADMIT_CRUD_TYPE;

export const GROUP = `GROUP` as ADMIT_CRUD_TYPE;
export const GROUP_SEARCH = `GROUP_SEARCH` as ADMIT_CRUD_TYPE;

export const TESTER_EXTERNAL_COMPANY = `TESTER_EXTERNAL_COMPANY` as ADMIT_CRUD_TYPE;

export const CHANGE_RECEIVE_BOOKING_REQUESTS = `${APP_ACTION_PREFIX} CHANGE_RECEIVE_BOOKING_REQUESTS`;

export const INVITE_TO_COMPANY_CONFIRMATION = `${APP_ACTION_PREFIX} INVITE_TO_COMPANY_CONFIRMATION`;

export const GET_TESTING_COMPANIES = `GET_TESTING_COMPANIES` as ADMIT_CRUD_TYPE;
export const GET_AUDIT_LOG = `GET_AUDIT_LOG` as ADMIT_CRUD_TYPE;
export const CLIENT_ADMIN_SETTINGS = `CLIENT_ADMIN_SETTINGS` as ADMIT_CRUD_TYPE;
export const EMAIL_DOMAINS = `EMAIL_DOMAINS` as ADMIT_CRUD_TYPE;
export const IP_RANGES = `IP_RANGES` as ADMIT_CRUD_TYPE;
export const CONSULTANTS = `CONSULTANTS` as ADMIT_CRUD_TYPE;
export const TIME_AND_EXPENSES = `TIME_AND_EXPENSES` as const;
export const TIMESHEET_TIME_CATEGORIES = `TIMESHEET_TIME_CATEGORIES` as ADMIT_CRUD_TYPE;
export const TIMESHEET_EXPENSES_TYPES = `TIMESHEET_EXPENSES_TYPES` as ADMIT_CRUD_TYPE;
export const TM_ACTION_TYPES = {
  SET_IS_LOADED: `${TIME_AND_EXPENSES}_SET_IS_LOADED`,
  HANDLE_MODAL: `${TIME_AND_EXPENSES}_HANDLE_MODAL`,
  SET_TIME_EXPENSES_WEEKS: `${TIME_AND_EXPENSES}_SET_TIME_EXPENSES_WEEKS`,
  UPDATE_DAY_DATA: `${TIME_AND_EXPENSES}_UPDATE_DAY_DATA`,
  SET_SELECTED_DAY_DATA: `${TIME_AND_EXPENSES}_SET_SELECTED_DAY_DATA`,
  DELETE_SELECTED_DAY_DATA: `${TIME_AND_EXPENSES}_DELETE_SELECTED_DAY_DATA`,
  ADD_SELECTED_PROJECT_WEEKS: `${TIME_AND_EXPENSES}_ADD_SELECTED_PROJECT_WEEKS`,
  REMOVE_SELECTED_PROJECT_WEEKS: `${TIME_AND_EXPENSES}_REMOVE_SELECTED_PROJECT_WEEKS`,
  CLEAR_SELECTED_PROJECT_WEEKS: `${TIME_AND_EXPENSES}_CLEAR_SELECTED_PROJECT_WEEKS`,
  SET_TIME_CATEGORIES_LIST: `${TIME_AND_EXPENSES}_SET_TIME_CATEGORIES_LIST`,
  SET_EXPENSES_TYPES_LIST: `${TIME_AND_EXPENSES}_SET_EXPENSES_TYPES_LIST`,
  ADD_ARCHIVED_DOCUMENT: `${TIME_AND_EXPENSES}_ADD_ARCHIVED_DOCUMENT`,
  CLEAR_ARCHIVED_DOCUMENTS_LIST: `${TIME_AND_EXPENSES}_CLEAR_ARCHIVED_DOCUMENTS_LIST`,
  SET_TIMESHEET_MANAGERS_LIST: `${TIME_AND_EXPENSES}_SET_TIMESHEET_MANAGERS_LIST`,
  SET_TIMESHEET_PO_NUMBER: `${TIME_AND_EXPENSES}_SET_TIMESHEET_PO_NUMBER`
} as const;
