import {convertorFromSwagger, convertorToSwagger} from '../convertors/carousel';
import * as FilterActions from '../../constants/filter';
import {extractJSONLD, fetchRequestBody} from "../../helpers/request-helper";
import { getApiJSONLD, getApiJSON, getApi } from '../swagger/api-factory';
import {IAnyProps} from "../../interfaces/any-props.interface";

export const getItems = (page, itemsPerPage, show) => {
  const isShow = show || FilterActions.DEFAULT_FILTER;
  const parameters: IAnyProps = { page };
 
  parameters.items = itemsPerPage;
  parameters.show = isShow;

  return getApiJSONLD()
    .then(api => api.getCarouselCollection(parameters))
    .then(extractJSONLD(convertorFromSwagger));
};

export const getItem = (id) => {
  return getApi()
    .then(api => api.getCarouselItem({id}))
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};

export const postItem = (model) => {
  return getApi()
    .then(api => api.postCarouselCollection({
      carousel: convertorToSwagger(model),
    }))
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};

export const putItem = (model) => {
  return getApi()
    .then(api => api.putCarouselItem({
      id: model.id,
      carousel: convertorToSwagger(model),
    }))
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};

export const deleteItem = (id) => {
  return getApi()
    .then(api => api.deleteCarouselItem({
      id,
    }))
    .then(fetchRequestBody);
};

export const unarchiveItem = (id) => {
  return getApiJSON()
    .then(api => api.unarchiveCarouselItem({
      id,
      carousel: {},
    }))
    .then(fetchRequestBody);
};
