import * as React from "react";
import {
  Grid,
  Icon,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Zoom,
} from "@material-ui/core";
import {FieldArray, InjectedFormProps, reduxForm, reset} from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import Paper from "@material-ui/core/Paper/Paper";
import { composeValidators } from "../../../helpers/validations/validation";
import { formatDateWithTime } from "../../../helpers/date-formatter";
import ManagedServicesAddButton from "../../admin/settings/finance/ManagedServicesAddButton";
import { AdditionalServicesUpdateList } from "./AdditionalServicesUpdateList";
import { ITest } from "../../../models/test.interface";
import { Group } from "../../../constants/group";
import { Permission } from "constants/permission";
import { getUserPermissions } from "selectors/auth";
import { getAdditionalServicesList } from "../../../actions";
import {
  MAX_LENGTH_DAYS_FIELD,
  MAX_LENGTH_DESCRIPTION_FIELD,
  MAX_LENGTH_NAME_FIELD,
  MAX_LENGTH_PRICE_FIELD,
  MIN_LENGTH_NUMBER_FIELD,
  MIN_LENGTH_STRING_FIELD,
} from "./const";
import { ROLE_ACCOUNT_MANAGER } from "constants/roles";

export const FORM_NAME = "AdditionalServiceEdit";

interface IAvordValidationResult {
  name: string;
  description: string;
  daysPercent: string;
  price: string;
}

interface IState {
  additionalServices: IAvordValidationResult[];
  initialValues: IAvordValidationResult[];
}

const avordValidation = (
  field: string,
  dependencyField: string,
  from: number,
  to: number
): string => {
  if (!field || !dependencyField) {
    return "Required";
  }
  if (!from) {
    if (dependencyField.length > to) {
      return `Should be less then ${to} characters`;
    }
  }
  if (dependencyField.length < from || dependencyField.length > to) {
    return `Should be between ${from} and ${to} characters`;
  }
  return "";
};

class AdditionalServiceEdit extends React.Component<
  InjectedFormProps<ITest, {}> & any
> {
  componentDidMount() {
    const { getAdditionalServices, test } = this.props;

    getAdditionalServices(test.id);
  }

  isOwnerOrPlanner = () => {
    const { userGroup } = this.props;
    return userGroup === Group.OWNER || userGroup === Group.PLANNER;
  };

  isAccountManagerWithEditPermissions = () => {
    const { auth } = this.props;
    if (auth.roles.includes(ROLE_ACCOUNT_MANAGER) && !auth.permissions.includes(Permission.CAN_MANAGE_TESTS)) {
      return false;
    }

    return true;
  };

  isAccountManagerWithViewCostsPermissions = () => {
    const { auth } = this.props;
    if (
      auth.roles.includes(ROLE_ACCOUNT_MANAGER)
      && !(
        auth.permissions.includes(Permission.CAN_MANAGE_TESTS)
        || auth.permissions.includes(Permission.CAN_VIEW_PLATFORM_INVOICES_AND_COSTS)
      )
    ) {
      return false;
    }

    return true;
  };

  isOwnerOrPlannerOrAdmin = () => {
    const { userGroup } = this.props;

    return (
      this.isOwnerOrPlanner() ||
      userGroup === Group.ADMIN ||
      userGroup === Group.SUPER_ADMIN
    );
  };

  haveAdditionalServices = () => {
    const { test } = this.props;
    return test.proposal && !!test.proposal.additionalServiceDescription;
  };

  haveServiceCharge = () => {
    const { test } = this.props;
    return (
      test.proposal &&
      (!!test.proposal.testerMargin || !!test.proposal.clientMargin)
    );
  };

  handleDownloadPDF = () => {
    const { test, downloadSoWDocument } = this.props;
    downloadSoWDocument(test.id);
  };

  render() {
    const { test, downloadSoWDocument } = this.props;

    if (!test) {
      return <div className="styled-block">Loading...</div>;
    }

    if (test.proposal) {
      return (
        <form>
          <div className="styled-block">
            <div className="styled-table table-responsive">
              <div className="block-header col-4">
                {downloadSoWDocument &&
                  this.isOwnerOrPlannerOrAdmin() &&
                  test.proposal.clientMargin && (
                    <Grid item className="pt-4 d-flex align-items-center">
                      <button
                        className="btn-download"
                        onClick={this.handleDownloadPDF}
                      >
                        {"Please download Statement of Work"}
                      </button>
                      <Tooltip
                        placement={"bottom-end"}
                        TransitionComponent={Zoom}
                        disableFocusListener
                        disableTouchListener
                        title="Once you have downloaded the SOW/Quotation, please add the relevant Purchase Order Number together with the signature of an authorised person and then email to support@avord.com"
                      >
                        <Icon>info</Icon>
                      </Tooltip>
                    </Grid>
                  )}
              </div>
              <div className="booking-proposal-view">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <h4 className="block-header mb-4">
                          Proposed work overview
                        </h4>
                        {test.proposal &&
                        !test.proposal.info.includes("<script>") ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: test.proposal.info,
                            }}
                          />
                        ) : (
                          <div>{test.proposal.info}</div>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <h4 className="block-header mb-4">Booking proposal</h4>
                        <div className="d-flex justify-content-between flex-wrap">
                          <div className="booking-proposal-item">
                            <b>Dates: </b>
                            {formatDateWithTime(
                              test.proposal.dateStart
                            )} to {formatDateWithTime(test.proposal.dateEnd)}
                          </div>
                          {!test.isOwnedByCompany && (
                            <div className="booking-proposal-item">
                              <b>Days: </b>
                              {test.proposal.countDays}
                            </div>
                          )}
                          {this.isAccountManagerWithViewCostsPermissions() && !test.isOwnedByCompany && test.proposal.currency && (
                            <div className="booking-proposal-item">
                              <b>Day Rate: </b>
                              {test.proposal.currency.symbol}
                              {test.proposal.rate}
                            </div>
                          )}
                          {this.isAccountManagerWithViewCostsPermissions() && !test.isOwnedByCompany && test.proposal.currency && (
                            <div className="booking-proposal-item">
                              <b>Daily Expenses: </b>
                              {test.proposal.currency.symbol}
                              {test.proposal.dailyExpenses}
                            </div>
                          )}
                        </div>
                        {this.isAccountManagerWithViewCostsPermissions() && this.haveServiceCharge() && (
                          <div className="booking-proposal-item">
                            <b>
                              AVORD service charge (
                              {this.isOwnerOrPlanner()
                                ? test.proposal.clientMarginPercent
                                : test.proposal.testerMarginPercent}
                              %):{" "}
                            </b>
                            {test.proposal.currency.symbol}
                            {this.isOwnerOrPlanner()
                              ? test.proposal.clientMargin
                              : test.proposal.testerMargin}
                          </div>
                        )}
                        {this.isOwnerOrPlannerOrAdmin() &&
                          test.proposal.currency &&
                          this.haveAdditionalServices() && (
                            <div className="booking-proposal-item">
                              <b>AVORD managed services: </b>
                              {
                                test.proposal.additionalServiceDescription
                              } - {test.proposal.currency.symbol}
                              {test.proposal.costAdditionalServices}
                              <Tooltip
                                placement={"top-end"}
                                disableFocusListener
                                disableTouchListener
                                title={
                                  <span>
                                    Service days: {test.proposal.serviceDays}{" "}
                                    days
                                    <div>
                                      AVORD management service day rate:{" "}
                                      {test.proposal.currency.symbol}
                                      {test.proposal.additionalServicePrice}
                                    </div>
                                  </span>
                                }
                              >
                                <Icon className="ml-1">info</Icon>
                              </Tooltip>
                            </div>
                          )}
                        <>
                          <b>Additional Services:</b>
                          <div className="pt-4">
                            <Paper className="p-4 mb-4">
                              <FieldArray
                                name="additionalServices"
                                component={AdditionalServicesUpdateList}
                                rerenderOnEveryChange
                              />
                              {this.isAccountManagerWithEditPermissions() && (
                                <FieldArray
                                  name="additionalServices"
                                  component={ManagedServicesAddButton}
                                />
                              )}
                            </Paper>
                          </div>
                        </>
                        {this.isAccountManagerWithViewCostsPermissions() && !test.isOwnedByCompany &&
                          test.proposal.currency &&
                          (!!test.proposal.fullTotalCostForTester ||
                            !!test.proposal.fullTotalCostForClient) && (
                            <div className="booking-proposal-item">
                              <b>Total (excl VAT): </b>
                              {test.proposal.currency.symbol}
                              {this.isOwnerOrPlanner()
                                ? test.proposal.fullTotalCostForClient
                                : test.proposal.fullTotalCostForTester}
                            </div>
                          )}
                      </TableCell>
                    </TableRow>
                    {test.proposal.firstPhase &&
                      test.proposal.firstPhaseInformation && (
                        <TableRow>
                          <TableCell>
                            <h4 className="block-header mb-4">
                              Phase 1. {test.proposal.firstPhase}
                            </h4>
                            {!test.proposal.firstPhaseInformation.includes(
                              "<script>"
                            ) ? (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: test.proposal.firstPhaseInformation,
                                }}
                              />
                            ) : (
                              <div>{test.proposal.firstPhaseInformation}</div>
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                    {test.proposal.secondPhase &&
                      test.proposal.secondPhaseInformation && (
                        <TableRow>
                          <TableCell>
                            <h4 className="block-header mb-4">
                              Phase 2. {test.proposal.secondPhase}
                            </h4>
                            {!test.proposal.secondPhaseInformation.includes(
                              "<script>"
                            ) ? (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: test.proposal.secondPhaseInformation,
                                }}
                              />
                            ) : (
                              <div>{test.proposal.secondPhaseInformation}</div>
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                    {test.proposal.otherPhases &&
                      test.proposal.otherPhasesInformation && (
                        <TableRow>
                          <TableCell>
                            <h4 className="block-header mb-4">
                              Other phases. {test.proposal.otherPhases}
                            </h4>
                            {test.proposal.otherPhasesInformation &&
                            !test.proposal.otherPhasesInformation.includes(
                              "<script>"
                            ) ? (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: test.proposal.otherPhasesInformation,
                                }}
                              />
                            ) : (
                              <div>{test.proposal.otherPhases}</div>
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                    {test.proposal.preRequisites && (
                      <TableRow>
                        <TableCell>
                          <h4 className="block-header mb-4">Pre-requisites</h4>
                          {!test.proposal.preRequisites.includes("<script>") ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: test.proposal.preRequisites,
                              }}
                            />
                          ) : (
                            <div>{test.proposal.preRequisites}</div>
                          )}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableHead>
                </Table>
              </div>
            </div>
          </div>
        </form>
      );
    }

    return null;
  }
}

function mapStateToProps(state: IState) {
  if (JSON.stringify(state.additionalServices) !== "{}") {
    const avordValidationFields = {};
    Object.keys(state.additionalServices).forEach((key) => {
      avordValidationFields[key.replace(".", "____")] =
        state.additionalServices[key];
    });

    return {
      additionalServices: avordValidationFields,
      initialValues: avordValidationFields,
      permissions: getUserPermissions(state),
    };
  }

  return {
    additionalServices: state.additionalServices,
  };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAdditionalServices: getAdditionalServicesList,
    },
    dispatch
  );
};

const connectForm = reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
  validate: composeValidators((values) => {
    const { additionalServices } = values;
    const errors: { additionalServices?: IAvordValidationResult[] } = {};

    if (additionalServices) {
      const additionalServicesErrorsArray: IAvordValidationResult[] = [];

      additionalServices.forEach((field, index) => {
        additionalServicesErrorsArray[index] = {
          name: avordValidation(
            field,
            field.name,
            MIN_LENGTH_STRING_FIELD,
            MAX_LENGTH_NAME_FIELD
          ),
          description: avordValidation(
            field,
            field.description,
            MIN_LENGTH_STRING_FIELD,
            MAX_LENGTH_DESCRIPTION_FIELD
          ),
          daysPercent: avordValidation(
            field,
            field.daysPercent,
            MIN_LENGTH_NUMBER_FIELD,
            MAX_LENGTH_DAYS_FIELD
          ),
          price: avordValidation(
            field,
            field.price,
            MIN_LENGTH_NUMBER_FIELD,
            MAX_LENGTH_PRICE_FIELD
          ),
        };
      });
      if (additionalServicesErrorsArray.length) {
        errors.additionalServices = additionalServicesErrorsArray;
      }
    }
    return errors;
  }),
})(AdditionalServiceEdit);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(connectForm));
