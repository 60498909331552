import * as React from 'react';
import Grid from "@material-ui/core/Grid/Grid";
import {Button, FormControlLabel, Radio,} from "@material-ui/core";
import {Link} from "react-router-dom";
import {ButtonProps} from "@material-ui/core/Button/Button";
import {IBackRoute} from "../../../../components/routerModal";
import ReduxDocumentFileField from "../../../../components/forms/ReduxDocumentFileField";
import {FieldGeneric} from "../../../../components/forms/FieldGeneric";
import ReduxRadioButton from "../../../../components/forms/ReduxRadioButton";
import {dataFetcher} from "../../../../components/dataFetcher";
import {specialismsRequest} from "../../../../actions/specialisms";
import selector from "../../../../selectors/specialisms";
import {MAX_PAGINATION_RESULTS} from "../../../../constants/loader";
import {ACTIVE} from "../../../../constants/filter";
import {ISpecialism} from "../../../../models/specialism.interface";
import {IAnyProps} from "../../../../interfaces/any-props.interface";

interface IProps {
  specialisms: ISpecialism[];
}

class ReportTemplatesForm extends React.Component<IBackRoute & IProps & IAnyProps> {
  render() {
    const {backRoute} = this.props;

    return (
      <Grid container spacing={40}>
        <Grid item xs={12}>
          <FieldGeneric
            name="reportTemplate"
            label="Choose template *"
            component={ReduxDocumentFileField}
          />
          <br/>
          {(window.location.pathname === '/admin/settings/report-templates/create') &&
          <FieldGeneric
            className="p-0 d-flex flex-column"
            name="specialism"
            component={ReduxRadioButton}
          >
            {this.props.specialisms.map(item => (
              <FormControlLabel
                key={item.id}
                value={`${item.id}`}
                control={<Radio/>}
                label={item.name}
                disabled={!!item.reportTemplateDocument}
              />
            ))}
          </FieldGeneric>}
          <br/>
          <br/>
          <div className="d-flex justify-content-between mt-4">
            <Button variant="contained"
                    color="default"
                    component={Link as React.ReactType<ButtonProps>}
                    {...{
                      to:
                      backRoute
                    }}>
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary" className="ml-4">
              Save
            </Button>
          </div>
        </Grid>
      </Grid>
    )
  }
}

export default dataFetcher(ReportTemplatesForm, [
  {
    key: 'specialisms',
    action: () => {
      return specialismsRequest.getItems(1, MAX_PAGINATION_RESULTS, ACTIVE);
    },
    selector: (state, props) => selector.getItems(state),
  }
]) as React.ComponentClass<IBackRoute>;