import * as React from "react";
import { connect } from "react-redux";
import moment from "moment";
import CanvasJSReact from "../../../lib/canvasjs/canvasjs.react";
import getTestsChartData from "../../../actions/testsChart";
import { downloadTestsByDate } from "../../../api/requests/document-download";
import { setFilteredCostChartData } from "../../../helpers/set-filtered-dashboard-data";
import { DashboardChartHeader } from "./DashboardChartHeader";
import { TestStatus } from "../../../constants/test-status";

interface IChartTest {
  id: number;
  dateFrom: {
    date: string;
    timezone_type: number,
    timezone: string;
  },
  status: number;
}

interface IDataPoint {
  label: string;
  month: string;
  year: string;
  y: number;
}

interface IChartData {
  type: string;
  name: string;
  legendText: string;
  showInLegend: boolean;
  color: string;
  dataPoints: IDataPoint[];
  legendMarkerType: string;
  visible: boolean;
}

interface IRangeOfDates {
  dateFrom?: string;
  dateTo?: string;
}

interface IRangeOfMonths {
  month?: number;
  year?: number;
  key?: string;
}

class DashboardColumnChart extends React.Component<{
  testsChartData: IChartTest[],
  getTestsChartData: (params?: {}) => void,
  downloadTestsByDate: (params?: {}) => void,
  rangeOfDates: IRangeOfDates,
}, {}> {
  months: string[] = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];

  data: IChartData[] = [];

  componentDidMount() {
    this.props.getTestsChartData();
  }

  componentWillReceiveProps(nextProps){
    const { rangeOfDates: nextRangeOfDate = {}, getTestsChartData } = nextProps;
    const { rangeOfDates = {} } = this.props;

    if(nextRangeOfDate.dateFrom !== rangeOfDates.dateFrom || nextRangeOfDate.dateTo !== rangeOfDates.dateTo) {
      setFilteredCostChartData(nextProps, getTestsChartData);
    }
  }

  toggleDataSeries(event: any) {
    event.dataSeries.visible = !(event.dataSeries.visible);
    event.chart.render();
  }

  createDataPoints(rangeOfMonths: IRangeOfMonths[] = []) {
    const dataPoints: IDataPoint[] = rangeOfMonths
      .map((point) => ({
        label: `${this.months[point.month ? point.month : 0]} '${point.year}`,
        month: this.months[point.month ? point.month : 0],
        year: point.year ? point.year.toString() : '',
        y: 0
      })
    );

    return dataPoints;
  }

  fillChartData(rangeOfMonths) {
    this.data = [
      {
        type: "column",
        name: "Scheduled",
        legendText: "Scheduled",
        legendMarkerType: "square",
        showInLegend: true,
        color: "#bbe64f",
        dataPoints: this.createDataPoints(rangeOfMonths),
        visible: true,
      },
      {
        type: "column",
        name: "In progress",
        legendText: "In progress",
        legendMarkerType: "square",
        showInLegend: true,
        color: "#5bbff9",
        dataPoints: this.createDataPoints(rangeOfMonths),
        visible: true,
      },
      {
        type: "column",
        name: "Completed",
        legendText: "Completed",
        legendMarkerType: "square",
        showInLegend: true,
        color: "#0f5392",
        dataPoints: this.createDataPoints(rangeOfMonths),
        visible: true,
      },
    ];
  }

  getRangeOfMonths() {
    const { testsChartData } = this.props;
    const rangeOfMonths = testsChartData
      .map(item => {
        const month = moment(item.dateFrom.date).month();
        const year = moment(item.dateFrom.date).format('YY');

        return {
          month,
          year,
          key: `${year}${month}`
        }
      });

    const rangeOfDistinctMonths = {};

    return rangeOfMonths.filter((item) => {
      if (!rangeOfDistinctMonths[item.key]) {
        rangeOfDistinctMonths[item.key] = item;

        return true;
      }

      return false;
    });
  }

  fillDataPoint() {
    const { testsChartData } = this.props;

    testsChartData.map((item: IChartTest) => {
      const itemMonth = this.months[(new Date(Date.parse(item.dateFrom.date))).getMonth()];
      const itemYear = moment(item.dateFrom.date).format('YY');
      switch (item.status) {
        case TestStatus.STATUS_SCHEDULED:
          this.data.map((item) => {
            if (item.name === "Scheduled") {
              item.dataPoints.map((dataPoint: IDataPoint) => {
                if (dataPoint.month === itemMonth && dataPoint.year === itemYear) {
                  dataPoint.y++;
                }
              });
            }
          });
          break;
        case TestStatus.STATUS_BOOKING_REQUESTED:
          this.data.map((item) => {
            if (item.name === "Scheduled") {
              item.dataPoints.map((dataPoint: IDataPoint) => {
                if (dataPoint.month === itemMonth && dataPoint.year === itemYear) {
                  dataPoint.y++;
                }
              });
            }
          });
          break;
        case TestStatus.STATUS_BOOKING_REQUESTED_EDITED:
          this.data.map((item) => {
            if (item.name === "Scheduled") {
              item.dataPoints.map((dataPoint: IDataPoint) => {
                if (dataPoint.month === itemMonth && dataPoint.year === itemYear) {
                  dataPoint.y++;
                }
              });
            }
          });
          break;
        case TestStatus.STATUS_COMPLETED:
          this.data.map((item) => {
            if (item.name === "Completed") {
              item.dataPoints.map((dataPoint: IDataPoint) => {
                if (dataPoint.month === itemMonth && dataPoint.year === itemYear) {
                  dataPoint.y++;
                }
              });
            }
          });
          break;
        case TestStatus.STATUS_REPORT_ACCEPTED:
          this.data.map((item) => {
            if (item.name === "Completed") {
              item.dataPoints.map((dataPoint: IDataPoint) => {
                if (dataPoint.month === itemMonth && dataPoint.year === itemYear) {
                  dataPoint.y++;
                }
              });
            }
          });
          break;
        case TestStatus.STATUS_BOOKING_REJECTED_BY_TESTER:
          break;
        case TestStatus.STATUS_BOOKING_REJECTED_BY_CUSTOMER:
          break;
        default:
          this.data.map((item) => {
            if (item.name === "In progress") {
              item.dataPoints.map((dataPoint: IDataPoint) => {
                if (dataPoint.month === itemMonth && dataPoint.year === itemYear) {
                  dataPoint.y++;
                }
              });
            }
          });
      }
    });
  }

  getOptions() {
    return {
      axisY: {
        lineColor: "transparent",
        labelFontColor: "#778596",
        gridColor: "#EAECF0",
        tickLength: 13,
        tickColor: "transparent",
        labelFontSize: 11
      },
      axisX: {
        lineColor: "#EAECF0",
        labelFontColor: "#778596",
        gridColor: "#EAECF0",
        tickLength: 13,
        tickColor: "transparent",
        labelFontSize: 11,
        interval: 1,
        intervalType: "month",
      },
      animationEnabled: true,
      title: {
        text: ""
      },
      toolTip: {
        shared: true,
      },
      legend: {
        cursor: "pointer",
        itemclick: this.toggleDataSeries
      },
      data: this.data,
    };
  }

  render() {
    const { testsChartData = [], downloadTestsByDate, rangeOfDates } = this.props;

    if (!testsChartData) {
      return (
        <div className="styled-block">
          <DashboardChartHeader
            downloadPDF={downloadTestsByDate}
            rangeOfDates={rangeOfDates}
            caption="Tests"
            formName="ColumnChartSearchForm"
          />
          <div className="table-no-items">Loading...</div>
        </div>
      );
    }

    this.fillChartData(this.getRangeOfMonths());
    this.fillDataPoint();

    const allTestsAmount = testsChartData.length;

    const getScheduledTests = testsChartData.filter(test => test.status === 2).length;
    const getInProgressTests = testsChartData.filter(test => test.status !== 2 && test.status !== 10).length;
    const getCompletedTestsAmount = testsChartData.filter(test => test.status === 10).length;

    const scheduledTestsPercentage = (100 * getScheduledTests / allTestsAmount).toFixed(2) + "%";
    const inProgressTestsPercentage = (100 * getInProgressTests / allTestsAmount).toFixed(2) + "%";
    const completedTestsPercentage = (100 * getCompletedTestsAmount / allTestsAmount).toFixed(2) + "%";

    return (
      <div className="styled-block chart">
        <DashboardChartHeader
          downloadPDF={allTestsAmount ? downloadTestsByDate : null}
          rangeOfDates={rangeOfDates}
          caption="Activities"
          formName="ColumnChartSearchForm"
        />

        {
          allTestsAmount
          ? (
            <div>
              <div className="total-data container text-center mb-4 chart-top">
                <div className="row">
                  <div className="col-3">
                    <h2>{allTestsAmount}</h2>
                    <span>Total Tests</span>
                  </div>
                  <div className="col-3">
                    <h2>{scheduledTestsPercentage}</h2>
                    <span>Scheduled</span>
                  </div>
                  <div className="col-3">
                    <h2>{inProgressTestsPercentage}</h2>
                    <span>In progress</span>
                  </div>
                  <div className="col-3">
                    <h2>{completedTestsPercentage}</h2>
                    <span>Completed</span>
                  </div>
                </div>
              </div>
              <CanvasJSReact.CanvasJSChart options={this.getOptions()}/>
            </div>
          ) : (
            <div className="table-no-items">There are no items...</div>
          )
        }
      </div>
    );
  }
}

export default connect(
  (state: any) => ({
    testsChartData: state.dashboard.testsChartData,
    rangeOfDates: state.form.ColumnChartSearchForm ? state.form.ColumnChartSearchForm.values : {}
  }),
  {
    getTestsChartData,
    downloadTestsByDate
  }
)(DashboardColumnChart as any);
