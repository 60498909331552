import {
  StyleRulesCallback
} from "@material-ui/core/styles/withStyles";
import * as React from "react";
import { InjectedFormProps, reduxForm } from "redux-form";
import { defaultStyleWidth } from "styles/material/popup-style";
import {
  composeValidators,
  fieldsNotEmpty
} from "helpers/validations/validation";
import { withRouter } from "react-router-dom";
import routerModal from "components/routerModal";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import { postTestDocumentAction } from "actions/test";
import DocumentForm from "./DocumentForm";
import { ITest } from "models/test.interface";
import { ConnectedProps } from "types";

const FORM_NAME = 'DocumentCreate';

type FormData = {testDocuments: File};

// @ts-ignore
type Props = ConnectedProps<typeof connector> & {
  test: ITest;
  backRoute: string;
};

class DocumentCreate extends React.Component<Props, InjectedFormProps<FormData, Props>> {

  render() {
    const {handleSubmit, postData, backRoute, test} = this.props;

    return (
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit((values) => {
          postData(values, FORM_NAME, backRoute, test);
        })}
      >
        <DocumentForm backRoute={backRoute} />
      </form>
    );
  }
}

const formConnected = reduxForm<FormData, Props>({
  form: FORM_NAME,
  validate: composeValidators(
    fieldsNotEmpty(['testDocuments']),
  )
})(DocumentCreate);

const connector = connect(undefined, {
  postData: postTestDocumentAction,
})(formConnected);

const withStyle = withStyles(defaultStyleWidth(400) as StyleRulesCallback)(connector);

export default routerModal(withRouter(withStyle));
