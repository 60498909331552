import React, { ReactNode, useState } from "react";
import TableCell, {
  TableCellProps
} from "@material-ui/core/TableCell/TableCell";
import { Link } from "@material-ui/core";
import { ConfirmModal } from "../ConfirmModal";

interface TableCellResetPasswordProps extends TableCellProps {
  onReset: () => void;
  children?: ReactNode;
  modalMessage?: string;
}

export const TableCellResetPassword = (props: TableCellResetPasswordProps) => {
  const [isOpened, setIsOpened] = useState(false);
  const { children = "Reset password", onReset, modalMessage, ...rest } = props;

  return (
    <>
      <TableCell {...rest}>
        <Link variant="body2" onClick={() => setIsOpened(true)}>
          {children}
        </Link>
      </TableCell>

      {isOpened && (
        <ConfirmModal
          isOpened={isOpened}
          confirmDescription={modalMessage}
          onConfirm={() => {
            onReset();
            setIsOpened(false);
          }}
        />
      )}
    </>
  );
};
