import * as React from "react";
import {reduxForm} from "redux-form";
import {connect} from "react-redux";
import moment from "moment";
import {FieldGeneric} from "../../../../components/forms/FieldGeneric";
import ReduxDatePicker from "../../../../components/forms/ReduxDatePicker";

export const SEARCH_FORM_NAME = "ColumnChartSearchForm";

class DashboardColumnChartSearch extends React.Component<{}, {}> {
  render() {
    return (
      <>
        <div className="mb-4 col-md-3">
          <FieldGeneric
            name="dateFrom"
            label="From"
            InputLabelProps={{shrink: true}}
            component={ReduxDatePicker}
            maxDate={moment().endOf('month').format('YYYY-MM-DD')}
          />
        </div>
        <div className="mb-4 col-md-3">
          <FieldGeneric
            name="dateTo"
            label="To"
            InputLabelProps={{shrink: true}}
            addEndOfDay={true}
            component={ReduxDatePicker}
            maxDate={moment().endOf('month').format('YYYY-MM-DD')}
          />
        </div>
      </>
    );
  }
}

const connectForm = reduxForm({
  form: SEARCH_FORM_NAME,
})(DashboardColumnChartSearch as any);

const connected = connect()(connectForm);

export default connected;
