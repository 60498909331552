import * as React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Zoom
} from "@material-ui/core";
import { connect } from "react-redux";
import { deleteRisksAction } from "actions";
import {
  postItemAction as postRiskTreatmentAction
} from "actions/risk-treatments";
import risksSelector from "selectors/risks";
import { Link, Route, Switch, withRouter } from "react-router-dom";
import { TestStatus } from "constants/test-status";
import * as Roles from "constants/roles";
import { ROLE } from "constants/roles";
import PermissionBlock from "components/auth/PermissionBlock";
import TestStatusBlock from "components/auth/TestStatusBlock";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";
import RiskTreatmentCreate from "./RiskTreatmentCreate";
import RiskTreatmentUpdate from "./RiskTreatmentUpdate";
import {
  formatDate,
  getColorByRiskRating,
  renderAction,
  renderStatus
} from "helpers/risk-treatment";
import { ITest } from "models/test.interface";
import { ConnectedProps } from "types";
import { RouteComponentProps } from "react-router";
import { getRoles } from "selectors/auth";
import ExportWrapper
  from "containers/client-tester/risk-chart/export/ExportWrapper";
import { RISK_TYPE_ORIGINAL } from "constants/risk";
import classNames from "classnames";
import AreYouSureModal from "components/table/AreYouSureModal";
import { IRisk } from "containers/client/dashboard/DashboardCriticalRisks";
import { Permission } from "constants/permission";

interface IState {
  fullViewedRiskTreatment: number;
  auth?: boolean;
}

class RiskChartTable extends React.Component<RiskChartTableProps & PropsFromRedux & RouteComponentProps<{ test: string }>, IState> {
  testId = this.props.match.params.test;

  state = {
    fullViewedRiskTreatment: 0,
    confirmId: null,
    isOpen: false,
  };

  closeConfirmation = () => {
    this.setState((prev) => ({
      ...prev,
      isOpen: false,
      confirmId: null,
    }));
  }

  onShowConfirmHandler = ({id}: IRisk) => {
    this.setState((prev) => ({
      ...prev,
      confirmId: id,
      isOpen: true,
    }));
  }

  onDeleteHandler = () => {
    const {confirmId} = this.state;

    if (confirmId === null) {
      return;
    }

    this.props.deleteRisksAction(confirmId)();
    this.closeConfirmation();
  }

  viewFullRiskTreatmentDescription = id => () => {
    id = this.state.fullViewedRiskTreatment === id ? 0 : id;
    this.setState({fullViewedRiskTreatment: id});
  };

  createTreatment = () => (
    <RiskTreatmentCreate backRoute={this.props.match.url} />
  );
  updateTreatment = () => (
    <RiskTreatmentUpdate backRoute={this.props.match.url} />
  );

  getCvssScoreColor = (risk: number = 0): string => {
    switch (true) {
      case risk >= 0.1 && risk < 4:
        return "#9c6";
      case risk >= 4 && risk < 7:
        return "#EEE94C";
      case risk >= 7 && risk < 9:
        return "#FFA73F";
      case risk >= 9 && risk <= 10:
        return "#FF4567";
      default:
        return "#63C5FF";
    }
  };

  getRatingScoreColor = (risk: number): string => {
    switch (true) {
      case risk <= 5:
        return "#9c6";
      case risk < 12:
        return "#fc0";
      case risk >= 12:
        return "#f00";
      default:
        return "#9c6";
    }
  };

  canModifyEntry = (roles: ROLE[], permissions: Permission[]) => {
    if (roles.includes(Roles.ROLE_TESTER)) {
      return true;
    }

    return permissions.includes(Permission.CAN_MANAGE_AVORD_CONSULTANCY_COMPANY_MANAGER);
  }

  render() {
    const {
      isLoaded,
      canExportTable,
      data,
      test,
      match
    } = this.props;

    if (!isLoaded) {
      return <div className="table-no-items">Loading...</div>;
    }

    if (!data.length) {
      return <div className="table-no-items">There are no items!</div>;
    }

    const showCvssScore = !!test.vulnerabilities;

    return (
      <div className="table-responsive styled-table">
        {canExportTable && <div className="text-right mb-4">
          <ExportWrapper
            data={data}
            test={test}
            userRoles={this.props.userRoles}
          />
        </div>}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Risk</TableCell>
              <TableCell>Impact</TableCell>
              <TableCell>Likelihood</TableCell>
              {showCvssScore && (
                <TableCell>
                  CVSS
                  <br />
                  Score
                </TableCell>
              )}
              <TableCell>Rating</TableCell>

              <PermissionBlock roles={[Roles.ROLE_PLANNER, Roles.ROLE_VIEWER]}>
                <TestStatusBlock
                  currentStatus={test.status}
                  requiredStatuses={[
                    TestStatus.STATUS_REPORT_ACCEPTED,
                    TestStatus.STATUS_COMPLETED
                  ]}
                >
                  <TableCell>Treatment</TableCell>
                  <TableCell>
                    Residual
                    <br />
                    Risk Rating
                  </TableCell>
                  <TableCell className="text-nowrap">
                    Comment
                    <Tooltip
                      placement="top"
                      TransitionComponent={Zoom}
                      disableFocusListener
                      disableTouchListener
                      title="Any change of the Risk rating must include justification."
                    >
                      <Icon className="ml-2 align-middle">info</Icon>
                    </Tooltip>
                  </TableCell>
                  <TableCell className="text-nowrap">
                    Status
                    <Tooltip
                      placement="top"
                      TransitionComponent={Zoom}
                      disableFocusListener
                      disableTouchListener
                      title="Open or Closed"
                    >
                      <Icon className="ml-2 align-middle">info</Icon>
                    </Tooltip>
                  </TableCell>
                </TestStatusBlock>
              </PermissionBlock>

              <PermissionBlock has={this.canModifyEntry}>
                <TestStatusBlock
                  currentStatus={test.status}
                  requiredStatuses={[
                    TestStatus.STATUS_BOOKED,
                    TestStatus.STATUS_REPORT_SUBMITTED,
                    TestStatus.STATUS_AMENDMENT_SENT,
                    TestStatus.STATUS_AMENDMENT_RESPONDED
                  ]}
                >
                  <TableCell className="text-center">Edit</TableCell>
                  <TableCell className="text-center">Delete</TableCell>
                </TestStatusBlock>
              </PermissionBlock>

              <PermissionBlock roles={[Roles.ROLE_PLANNER]}>
                <TestStatusBlock
                  currentStatus={test.status}
                  requiredStatuses={[
                    TestStatus.STATUS_DRAFT,
                    TestStatus.STATUS_SCHEDULED,
                    TestStatus.STATUS_BOOKING_REQUESTED,
                    TestStatus.STATUS_BOOKING_REQUESTED_EDITED
                  ]}
                >
                  <TableCell className="text-center">Edit</TableCell>
                  <TableCell className="text-center">Delete</TableCell>
                </TestStatusBlock>
              </PermissionBlock>

              <PermissionBlock roles={[Roles.ROLE_PLANNER]}>
                <TestStatusBlock
                  currentStatus={test.status}
                  requiredStatuses={[
                    TestStatus.STATUS_REPORT_ACCEPTED,
                    TestStatus.STATUS_COMPLETED
                  ]}
                >
                  <TableCell className="text-center">Edit</TableCell>
                </TestStatusBlock>
                <TestStatusBlock
                  currentStatus={test.status}
                  requiredStatuses={[
                    TestStatus.STATUS_COMPLETED,
                  ]}
                >
                  <TableCell className="text-center">Delete</TableCell>
                </TestStatusBlock>
              </PermissionBlock>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map(risk => (
                <TableRow key={risk.id}>
                  <TableCell>
                    <b>{risk.title}</b>
                    <br /> {risk.description}
                  </TableCell>
                  <TableCell>{risk.impact}</TableCell>
                  <TableCell>{risk.likelihood}</TableCell>
                  {showCvssScore && (
                    <TableCell
                      className="rating-text"
                      style={{
                        color: this.getCvssScoreColor(risk.vulnerabilityScore)
                      }}
                    >
                      {risk.vulnerabilityScore}
                    </TableCell>
                  )}

                  <TableCell
                    className="rating-text"
                    style={{
                      color: this.getRatingScoreColor(
                        risk.impact * risk.likelihood
                      )
                    }}
                  >
                    {risk.impact * risk.likelihood}
                  </TableCell>

                  <PermissionBlock
                    roles={[Roles.ROLE_PLANNER, Roles.ROLE_VIEWER]}
                  >
                    <TestStatusBlock
                      currentStatus={test.status}
                      requiredStatuses={[
                        TestStatus.STATUS_REPORT_ACCEPTED,
                        TestStatus.STATUS_COMPLETED
                      ]}
                    >
                      <TableCell>
                        {risk.treatment && renderAction(risk.treatment.action)}
                      </TableCell>
                      <TableCell>
                        {risk.treatment && (
                          <span
                            style={{
                              color: getColorByRiskRating(
                                risk.treatment.residualRiskRating
                              )
                            }}
                          >
                            {risk.treatment.residualRiskRating}
                          </span>
                        )}
                      </TableCell>
                      <TableCell>
                        {risk.treatment && (
                          <div
                            onClick={this.viewFullRiskTreatmentDescription(
                              risk.treatment.id
                            )}
                            className={
                              this.state.fullViewedRiskTreatment ===
                              risk.treatment.id
                                ? "word-break-all risk-treatment-comment full"
                                : "text-truncate risk-treatment-comment"
                            }
                          >
                            {`Last update: ${formatDate(
                              risk.treatment.updatedAt
                            )}`}
                            <br />
                            {risk.treatment.comment}
                          </div>
                        )}
                      </TableCell>
                      <TableCell>
                        {risk.treatment && renderStatus(risk.treatment.status)}
                      </TableCell>
                    </TestStatusBlock>
                  </PermissionBlock>

                  <PermissionBlock has={this.canModifyEntry}>
                    <TestStatusBlock
                      currentStatus={test.status}
                      requiredStatuses={[
                        TestStatus.STATUS_BOOKED,
                        TestStatus.STATUS_REPORT_SUBMITTED,
                        TestStatus.STATUS_AMENDMENT_SENT,
                        TestStatus.STATUS_AMENDMENT_RESPONDED
                      ]}
                    >
                      <TableCell className="text-center">
                        <Link
                          to={`${match.url}/edit-risk/${risk.id}`}
                          className="btn-edit m-0"
                        />
                      </TableCell>
                      <TableCell className="text-center">
                        <button
                          onClick={() => this.onShowConfirmHandler(risk)}
                          className="btn-delete m-0"
                        />
                      </TableCell>
                    </TestStatusBlock>
                  </PermissionBlock>

                  <PermissionBlock roles={[Roles.ROLE_PLANNER]}>
                    <TestStatusBlock
                      currentStatus={test.status}
                      requiredStatuses={[
                        TestStatus.STATUS_DRAFT,
                        TestStatus.STATUS_SCHEDULED,
                        TestStatus.STATUS_BOOKING_REQUESTED,
                        TestStatus.STATUS_BOOKING_REQUESTED_EDITED
                      ]}
                    >
                      <TableCell className="text-center">
                        <Link
                          to={`${match.url}/edit-risk/${risk.id}`}
                          className="btn-edit m-0"
                        />
                      </TableCell>
                      <TableCell className="text-center">
                        <button
                          onClick={() => this.onShowConfirmHandler(risk)}
                          className="btn-delete m-0"
                        />
                      </TableCell>
                    </TestStatusBlock>
                  </PermissionBlock>

                  <PermissionBlock roles={[Roles.ROLE_PLANNER]}>
                    <TestStatusBlock
                      currentStatus={test.status}
                      requiredStatuses={[
                        TestStatus.STATUS_REPORT_ACCEPTED,
                        TestStatus.STATUS_COMPLETED
                      ]}
                    >
                      <TableCell className="text-center">
                        <Link
                          to={`${this.props.match.url}/${risk.id}/${
                            risk.treatment ? "edit" : "create"
                          }-risk-treatment/`}
                          className="btn-edit m-0"
                        />
                      </TableCell>
                    </TestStatusBlock>
                    <TestStatusBlock
                      currentStatus={test.status}
                      requiredStatuses={[
                        TestStatus.STATUS_COMPLETED,
                      ]}
                    >
                      <TableCell className="text-center">
                        <button
                          onClick={() => this.onShowConfirmHandler(risk)}
                          className={classNames({
                            'btn-delete m-0': true,
                            'btn-disabled': risk.type === RISK_TYPE_ORIGINAL
                          })}
                          disabled={risk.type === RISK_TYPE_ORIGINAL}
                        />
                      </TableCell>
                    </TestStatusBlock>
                  </PermissionBlock>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
        <AreYouSureModal
          open={this.state.isOpen}
          onClose={this.closeConfirmation}
          onConfirm={this.onDeleteHandler}
        />
        <Switch>
          <Route
            path={match.url + "/:id/create-risk-treatment"}
            render={this.createTreatment}
          />
          <Route
            path={match.url + "/:id/edit-risk-treatment"}
            render={this.updateTreatment}
          />
        </Switch>
      </div>
    );
  }
}

type RiskChartTableProps = {
  test: ITest;
  canExportTable?: boolean;
}

const mapStateToProps = (state) => ({
  data: risksSelector.getItems(state),
  isLoaded: risksSelector.getIsLoaded(state),
  userRoles: getRoles(state),
});

const mapDispatchToProps = {
  deleteRisksAction,
  postRiskTreatmentAction
};

const connector = connect(
  mapStateToProps,
  mapDispatchToProps
);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default withRouter(connector(RiskChartTable));
