import * as React from 'react';
import TextField from "@material-ui/core/TextField";
import {WrappedFieldProps} from "redux-form/lib/Field";
import {WithStyles, withStyles} from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import {IAnyProps} from "../../interfaces/any-props.interface";
import {find} from "lodash";
import FormHelperText from "@material-ui/core/FormHelperText";
import {ICountry} from "../../models/dialing-code.interface";

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    width: '100%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing.unit / 4,
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
  },
  chipDisabled: {
    margin: theme.spacing.unit / 4,
    color: '#8e8d92'
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

interface IProps {
  items: ICountry[];
  setLocationSearchText: (name: string) => void;
  searchText: string;
}

class ReduxMultipleCountryField extends React.Component<WrappedFieldProps & IAnyProps & WithStyles<'chips' | 'formControl' | 'chip' | 'chipDisabled'> & IProps,
  {
    searchText: string;
  }
  > {
  state = {
    searchText: ''
  };

  handleChange = (event) => {
    return this.props.input.onChange(event.target.value.filter(a => !!a));
  };

  getCountryByKey: (id: string | number) => ICountry = (id) => find(this.props.items, {id}) as ICountry;

  renderValue = (selected: Array<string | number>) => {
    return (
      <div className={this.props.classes.chips}>
        {selected
          .map((value => this.getCountryByKey(value)))
          .map((value: ICountry) => (
            <Chip key={value.id}
                label={value.name}
                className={this.props.disabled ? this.props.classes.chipDisabled : this.props.classes.chip}/>
            )
          )
        }
      </div>
    );
  };

  renderName = (country: ICountry) => {
    return (
      <MenuItem
        key={country.id}
        value={country.id}
      >
        {/*<Checkbox checked={this.props.input.value.indexOf() > -1}/>*/}
        <ListItemText
          disableTypography
          primary={country.name}
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}/>
      </MenuItem>
    );
  };

  close = (event) => {
    event.stopPropagation();
  };

  handleSearchChange = (event) => {
    this.setState({searchText: event.target.value});
  };

  render() {
    if (this.props.items.length === 0) {
      return (
        <div>
          Loading...
        </div>
      );
    }

    const {label, classes, meta: {touched, error}} = this.props;

    return (
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="select-multiple-checkbox" error={!!touched && !!error}>{label}</InputLabel>
        <Select
          {...this.props.input}
          value={this.props.input.value ? this.props.input.value : []}
          disabled={this.props.disabled}
          fullWidth={true}
          input={<Input id="select-multiple-chip"/>}
          onChange={this.handleChange}
          renderValue={this.renderValue}
          error={!!touched && !!error}
          multiple={true}
        >
          <MenuItem
            key={'Search'}
            onClick={this.close}
          >
            <TextField
              value={this.state.searchText}
              onChange={this.handleSearchChange}
              fullWidth={true}
              onClick={this.close}
            />
          </MenuItem>
          {this.props.items.filter((location: ICountry) => {
            return (location.name || '').toLocaleLowerCase().indexOf(this.state.searchText.toLocaleLowerCase()) !== -1;
          }).map(this.renderName)}
        </Select>
        {
          !!touched && error ?
            <FormHelperText style={{color: '#f44336'}}>{error}</FormHelperText> :
            null
        }
      </FormControl>
    )
  }
}


const connected = ((withStyles(styles as any, {withTheme: true})(ReduxMultipleCountryField)) as any);

export default connected;