import * as React from 'react';
import { composeValidators, fieldsNotEmpty } from "../../../../helpers/validations/validation";
import {InjectedFormProps, reduxForm} from "redux-form";
import { connect } from "react-redux";
import { postItemCarousel } from "../../../../actions/carousel";
import CarouselForm from "./CarouselForm";
import { withStyles } from "@material-ui/core/styles/index";
import { withRouter } from 'react-router-dom'
import { defaultStyleWidth } from '../../../../styles/material/popup-style';
import routerModal from '../../../../components/routerModal';
import {WithStyles} from "@material-ui/core/styles/withStyles";

const FORM_NAME = 'CarouselCreate';

class CarouselCreate extends React.Component<InjectedFormProps<{}, {}> & {
  postData: (...any) => void,
  backRoute: string
} & WithStyles<any>> {
  render() {
    const { handleSubmit, postData, backRoute } = this.props;

    return (
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit((formData: any) => {
          postData(formData, FORM_NAME, backRoute);
        })}
      >
        <CarouselForm backRoute={backRoute}/>
      </form>
    );
  }
}

const formConnected = reduxForm({
  form: FORM_NAME,
  validate: composeValidators(
    fieldsNotEmpty(['image', 'position',]),
  )
})(CarouselCreate);

const connected = connect(undefined, {
  postData: postItemCarousel,
})(formConnected);

/** Todo: cut */
const withStyle = withStyles(defaultStyleWidth(400))(connected as any);

export default routerModal(withRouter(withStyle as any));