import * as React from "react";
import {TableBody, WithStyles, withStyles} from "@material-ui/core";
import {withRouter} from "react-router-dom";
import {RouteComponentProps} from "react-router-dom";
import {TableRow, TableCell, Table, Typography, Card, CardContent} from "@material-ui/core";
import EnhancedTableHead from "../../../../components/table/EnhancedTableHead";
import {IWorkHistory} from "../../../../models/work-history.interface";
import {getTesterProfileWorkHistoryRequest} from "../../../../api/requests/admin-tester-profile";
import {styles} from "../../../../styles/material/tesrer-card";
import {formatDateWithSlashesDdMmYyyy} from "../../../../helpers/date-formatter";

type IProps = RouteComponentProps<any> & WithStyles;

interface IState {
  isLoaded: boolean;
  workHistories: IWorkHistory[];
}

class WorkHistory extends React.Component<IProps, IState> {
  state = {
    isLoaded: false,
    workHistories: []
  };

  componentWillMount() {
    this.fetchData();
  }

  componentWillReceiveProps(nextProps: Readonly<IProps>, nextContext: any): void {
    this.setState({isLoaded: false, workHistories: []});
    this.fetchData();
  }

  fetchData() {
    getTesterProfileWorkHistoryRequest(this.props.match.params.id)
      .then(data => {
        this.setState({workHistories: data, isLoaded: true});
      }).catch(error => {
        this.setState({isLoaded: true});
      });
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div>
          Loading...
        </div>
      );
    }

    return (
      <Card className={this.props.classes.card}>
        <div className={this.props.classes.details}>
          <CardContent className={this.props.classes.content}>
            <Typography variant="headline" className={this.props.classes.header}>
              <span>
                Work History
              </span>
            </Typography>
            <hr/>
            <Table>
              <EnhancedTableHead columns={["Employer", "Job title", "From", "To"]}/>
              <TableBody>
                {
                  !this.state.workHistories.length ?
                    <TableRow key={0}>
                      <TableCell colSpan={3}>There are no work histories.</TableCell>
                    </TableRow> :
                    this.state.workHistories.map((item: IWorkHistory) => {
                      return (
                        <TableRow key={item.id}>
                          <TableCell>
                            {item.employer}
                          </TableCell>
                          <TableCell>
                            {item.jobTitle}
                          </TableCell>
                          <TableCell>
                            {item.dateFrom && formatDateWithSlashesDdMmYyyy(item.dateFrom)}
                          </TableCell>
                          <TableCell>
                            {item.dateTo && formatDateWithSlashesDdMmYyyy(item.dateTo)}
                          </TableCell>
                        </TableRow>
                      );
                    })
                }
              </TableBody>
            </Table>
          </CardContent>
        </div>
      </Card>
    );
  }
}

const routered = withRouter(withStyles(styles as any)(WorkHistory) as any);

export default routered as React.ComponentClass<{}>;
