import React, { useState, useEffect } from "react";
import Table from "@material-ui/core/Table/Table";
import TableBody from "@material-ui/core/TableBody/TableBody";
import EnhancedTableHead from "components/table/EnhancedTableHead";
import AdditionalDocumentsRow from "./AdditionalDocumentsRow";
import { Zoom } from "@material-ui/core";
import CreateAdditionalDocumentForm from "./AdditionalDocumentCreateModal";
import Icon from "@material-ui/core/Icon/Icon";
import TooltipIcon from "@material-ui/core/Tooltip/Tooltip";
import { AdditionalDocumentType } from "types";
import { useAdditionalDocumentsList } from "hooks/use-additional-documents-list";
import { TableNoItems } from "components/table/TableNoItems";
import { ITest } from "models/test.interface";
import { ADDITIONAL_DOCUMENTS_LIST_LIMIT } from "../constants/additional-document";

interface IAdditionalDocumentsProps {
  test?: ITest;
}

const AdditionalDocuments = ({ test }: IAdditionalDocumentsProps) => {
  const [isOpenedModal, setIsOpenedModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const {
    additionalDocumentsList,
    getAdditionalDocumentsItems
  } = useAdditionalDocumentsList();
  const loadAdditionalDocuments = () =>
    getAdditionalDocumentsItems(test?.id as number);
  const closeModal = () => setIsOpenedModal(false);

  useEffect(() => {
    if (!test) {
      return;
    }

    getAdditionalDocumentsItems(test.id as number).finally(() =>
      setIsLoading(false)
    );
  }, [test?.id]);

  if (isLoading) {
    return <TableNoItems asLoading hasWrapper />;
  }

  return (
    <div>
      <div className="block-header-wrapper flex-column-in-mobile">
        <h4 className="mt-4">
          Additional Documents
          <TooltipIcon
            placement={"top"}
            TransitionComponent={Zoom}
            disableFocusListener
            disableTouchListener
            title="Additional documents that can be created in addition to the final report"
          >
            <Icon
              style={{
                marginLeft: "5px",
                marginBottom: "2px",
                verticalAlign: "middle"
              }}
            >
              info
            </Icon>
          </TooltipIcon>
        </h4>
        {additionalDocumentsList.length < ADDITIONAL_DOCUMENTS_LIST_LIMIT && (
          <button
            className="btn-new mb=2"
            onClick={() => setIsOpenedModal(true)}
          >
            Add Additional Document
          </button>
        )}
      </div>

      {!additionalDocumentsList.length && <TableNoItems hasWrapper />}
      {!!additionalDocumentsList.length && (
        <div className="styled-block">
          <div className="table-responsive styled-table">
            <Table padding="dense">
              <EnhancedTableHead
                columns={["Document Name", "Uploaded Date", "", ""]}
              />
              <TableBody>
                {additionalDocumentsList.map(
                  (document: AdditionalDocumentType) => (
                    <AdditionalDocumentsRow
                      key={document.id}
                      test={test}
                      additionalDocument={document}
                      reloadItems={loadAdditionalDocuments}
                    />
                  )
                )}
              </TableBody>
            </Table>
          </div>
        </div>
      )}

      {isOpenedModal && (
        <CreateAdditionalDocumentForm
          testId={test?.id as number}
          closeModal={closeModal}
          reloadItems={loadAdditionalDocuments}
        />
      )}
    </div>
  );
};

export default AdditionalDocuments;
