import { FormControl } from '@material-ui/core';
import { FormLabel } from '@material-ui/core';
import { RadioGroup } from '@material-ui/core';
import * as React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {WrappedFieldProps} from "redux-form/lib/Field";
import {ComponentType} from "react";

const ReduxRadioButton: ComponentType<WrappedFieldProps & {
  [key: string]: any
}> = ({ input, label, children, inline, classes, ...custom}) => {
  const wrapperClass = inline ? classes.inline : {};
  const onChange = (event: React.ChangeEvent<HTMLInputElement>)=> {
    input.onChange(event.target.value);
  };

  return (
    <FormControl 
      component="fieldset" 
      required>
      {label && <FormLabel component="legend">{label}</FormLabel>}
      <RadioGroup
        {...custom}
        value={input.value}
        className={wrapperClass}
        onChange={onChange}
      >
        {children}
      </RadioGroup>
    </FormControl>
  );
};

export default withStyles({
  inline: {
    display: 'flex',
    flexDirection: 'row',
  },
})(ReduxRadioButton);
