import * as React from "react";
import Grid from "@material-ui/core/Grid/Grid";
import {IReview} from "../../../../models/review.interface";
import StarRatingComponent from "react-star-rating-component";

interface IProps {
  item: IReview;
}

class TestersReview extends React.Component<IProps, {}> {
  render() {
    const {item} = this.props;

    return (
      <div className="tester-review styled-block">
        <Grid container spacing={16}>
          <Grid item xs={3}>
            <h5>{item.authorName}</h5>
            <StarRatingComponent
              className="rating-stars"
              name="rang"
              starCount={5}
              editing={false}
              value={!!item.rang ? item.rang : 0}
            />
          </Grid>
          <Grid item xs={9}>
            <p>
              {item.comment}
            </p>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default TestersReview;
