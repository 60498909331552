import { TE_STATUS } from "constants/timesheet-expenses";
import { ConsultantProfileStatistic } from "types/consultant";

export function getStatistic(
  searchProject: number,
  searchStatus: TE_STATUS,
  items: ConsultantProfileStatistic[]
) {
  let total = 0,
    amount = 0,
    vat = 0;

  const item = items.find(
    ({ project_id, status }) =>
      Number(project_id) === Number(searchProject) && status === searchStatus
  );

  if (item) {
    total = item.total;
    amount = item.expenses_amount;
    vat = item.vat;
  }

  return {
    total,
    amount,
    vat
  };
}
