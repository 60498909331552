import * as React from "react";
import TextField from "@material-ui/core/TextField";
import { WrappedFieldProps } from "redux-form/lib/Field";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import { IAnyProps } from "../../interfaces/any-props.interface";
import { ILocation } from "../../models/location.interface";
import { find } from "lodash";
import { connect } from "react-redux";
import { getLocationSearchText } from "../../selectors/locations";
import { createAction } from "redux-actions";
import { LOCATIONS_SET_SEARCH_TEXT } from "../../constants/action-type";
import FormHelperText from "@material-ui/core/FormHelperText";

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  formControl: {
    width: "100%"
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: theme.spacing.unit / 4
  }
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    },
    span: {
      overflow: "hidden",
      textOverflow: "ellipsis !important"
    }
  }
};

interface IProps {
  items: ILocation[];
  setLocationSearchText: (name: string) => void;
  searchText: string;
}

class ReduxLocationsField extends React.Component<
  WrappedFieldProps &
    IAnyProps &
    WithStyles<"chips" | "formControl" | "chip"> &
    IProps
> {
  handleChange = event => {
    return this.props.input.onChange(event.target.value.filter(a => !!a));
  };

  getLocationByKey: (id: string | number) => ILocation = id =>
    find(this.props.items, { id }) as ILocation;

  renderValue = (selected: Array<string | number>) => {
    return (
      <div className={this.props.classes.chips}>
        {selected.map(value => (
          <Chip
            key={value}
            label={this.getLocationByKey(value)?.name}
            className={this.props.classes.chip}
          />
        ))}
      </div>
    );
  };

  renderName = (location: ILocation) => {
    return (
      <MenuItem
        key={location.id}
        value={location.id}
        style={{
          fontWeight:
            this.props.input.value.indexOf(location.id) === -1
              ? (this.props.theme as any).typography.fontWeightRegular
              : (this.props.theme as any).typography.fontWeightMedium
        }}
      >
        <Checkbox checked={this.props.input.value.indexOf(location.id) > -1} />
        <ListItemText
          disableTypography
          primary={location.name}
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis"
          }}
        />
      </MenuItem>
    );
  };

  close = event => {
    event.stopPropagation();
  };

  handleSearchChange = event => {
    this.props.setLocationSearchText(event.target.value);
  };

  render() {
    const {
      label,
      classes,
      meta: { touched, error }
    } = this.props;

    return (
      <FormControl className={classes.formControl}>
        <InputLabel
          htmlFor="select-multiple-checkbox"
          error={!!touched && !!error}
        >
          {label}
        </InputLabel>
        <Select
          multiple
          {...this.props.input}
          value={this.props.input.value ? this.props.input.value : []}
          fullWidth={true}
          onChange={this.handleChange}
          input={<Input id="select-multiple-chip" />}
          renderValue={this.renderValue}
          MenuProps={MenuProps}
          error={!!touched && !!error}
        >
          <MenuItem key={"Search"} onClick={this.close}>
            <TextField
              value={this.props.searchText}
              onChange={this.handleSearchChange}
              fullWidth={true}
            />
          </MenuItem>
          {this.props.items
            .filter((location: ILocation) => {
              return (
                (location.name || "")
                  .toLocaleLowerCase()
                  .indexOf(this.props.searchText.toLocaleLowerCase()) !== -1
              );
            })
            .map(this.renderName)}
        </Select>
        {touched && error ? (
          <FormHelperText style={{ color: "#f44336" }}>{error}</FormHelperText>
        ) : null}
      </FormControl>
    );
  }
}

const mapStateToProps = state => {
  return {
    searchText: getLocationSearchText(state)
  };
};

export default connect(mapStateToProps, dispatch => {
  return {
    setLocationSearchText: text =>
      dispatch(createAction(LOCATIONS_SET_SEARCH_TEXT, () => text)())
  };
})(withStyles(styles as any, { withTheme: true })(ReduxLocationsField));
