import * as Types from '../constants/action-type';
import {
  createFilterAction,
  createLoaderAction,
  createRequestAction
} from '../helpers/action-helper';
import {setRequestInProcess} from "./request";
import {handleHttpErrors} from "../helpers/errors/http-error-handler";
import {getRequestName} from "../helpers/action-request-helper";
import {getBookingRequestsRequest, getTestsRequest} from "../api/requests/test";

export const testerBookingsFilter = createFilterAction(Types.TESTER_BOOKINGS);
export const testerBookingsLoader = createLoaderAction(Types.TESTER_BOOKINGS);
export const testerBookingsRequest = createRequestAction(Types.TESTER_BOOKINGS);

const getBookingRequests = getRequestName(Types.TESTER_BOOKINGS, 'getBookingRequests');

export const getTestsRequestsAction = (
  page: number,
  itemsPerPage: number,
  project: number[],
  status: string[],
  dateFrom: string,
  dateTo: string,
  questionAsked: boolean,
  statuses: string,
  name: string,
  plannerName: string,
  clientCompany: number,
  testerName: string,
  testingCompany: number,
) => async dispatch => {
  dispatch(setRequestInProcess(true, getBookingRequests));
  /** part responsible for a loader state */
  dispatch(createLoaderAction(Types.TESTER_BOOKINGS).setIsNotLoaded());

  let data = [], totalItems;

  try {
    ({items: data, totalItems} = await getTestsRequest(
      page,
      itemsPerPage,
      project,
      status,
      dateFrom,
      dateTo,
      questionAsked,
      statuses,
      name,
      plannerName,
      clientCompany,
      testerName,
      testingCompany
    ));
    dispatch(createLoaderAction(Types.TESTER_BOOKINGS).setPaginatioData(data, itemsPerPage, totalItems, page));
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(setRequestInProcess(false, getBookingRequests));
};

export const getBookingRequestsAction = (
    page: number,
    itemsPerPage: number,
    project: number[],
    status: string[],
    dateFrom: string,
    dateTo: string,
    questionAsked: boolean,
) => async dispatch => {
  dispatch(setRequestInProcess(true, getBookingRequests));
  /** part responsible for a loader state */
  dispatch(createLoaderAction(Types.TESTER_BOOKINGS).setIsNotLoaded());

  let data = [], totalItems;

  try {
    ({items: data, totalItems} = await getBookingRequestsRequest(
        page,
        itemsPerPage,
        project,
        status,
        dateFrom,
        dateTo,
        questionAsked,
    ));
    dispatch(createLoaderAction(Types.TESTER_BOOKINGS).setPaginatioData(data, itemsPerPage, totalItems, page));
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(setRequestInProcess(false, getBookingRequests));
};
