import * as Types from "../constants/action-type";
import {
  createFilterAction,
  createLoaderAction,
  createCrudAction,
  createRequestAction
} from "../helpers/action-helper";
import { createAction, Action } from "redux-actions";
import { ICertificateLocal } from "../models/certificate.interface";

export interface IPostCertificateAction {
  certificate: ICertificateLocal;
  backRoute: string;
  formName: string;
}

export const certificateLoader = createLoaderAction(Types.CERTIFICATES);
export const certificateRequest = createRequestAction(Types.CERTIFICATES);

export const getTesterCertifications = () => {
  return {
    type: Types.CERTIFICATES_GET,
    payload: {},
  };
};

export const testerCertificateArchive = (id: string) => {
  return {
    type: Types.CERTIFICATE_ARCHIVE,
    payload: id,
  };
};

export const postItem = (certificate: ICertificateLocal, backRoute: string, formName: string): Action<IPostCertificateAction> => {
  return createAction(Types.CERTIFICATE_POST, () => ({
    certificate,
    backRoute,
    formName,
  }))();
};
