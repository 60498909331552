/**
 *
 * @export
 * @interface RegistrationTester
 */
export interface IRegistrationTester {
  /**
   *
   * @type {string}
   * @memberof RegistrationTester
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof RegistrationTester
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof RegistrationTester
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof RegistrationTester
   */
  phone: string;
  /**
   *
   * @type {string}
   * @memberof RegistrationTester
   */
  addressLine1: string;
  /**
   *
   * @type {string}
   * @memberof RegistrationTester
   */
  addressLine2?: string;
  /**
   *
   * @type {string}
   * @memberof RegistrationTester
   */
  addressLine3?: string;
  /**
   *
   * @type {string}
   * @memberof RegistrationTester
   */
  town: string;
  /**
   *
   * @type {string}
   * @memberof RegistrationTester
   */
  state?: string;
  /**
   *
   * @type {string}
   * @memberof RegistrationTester
   */
  postCode: string;
  /**
   *
   * @type {string}
   * @memberof RegistrationTester
   */
  currencyCode: CurrencyCodeEnum;
  /**
   *
   * @type {number}
   * @memberof RegistrationTester
   */
  rate: number;
  /**
   *
   * @type {boolean}
   * @memberof RegistrationTester
   */
  is_agree: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum CurrencyCodeEnum {
  USD = 'USD' as any,
  GBP = 'GBP' as any,
  EUR = 'EUR' as any
}
