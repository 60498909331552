import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import getLastRisks from "actions/lastRisks";
import { downloadCriticalRiskByDate } from "api/requests/document-download";
import { setFilteredCostChartData } from "helpers/set-filtered-dashboard-data";
import { DashboardChartHeader } from "./DashboardChartHeader";
import {
  getColorByRiskRating,
  formatDate,
  renderAction,
  renderStatus
} from "helpers/risk-treatment";
import { RISK_TYPES } from "constants/risk";

interface IRiskTreatment {
  id: number;
  action: number;
  residualRiskRating: number;
  comment: string;
  status: number;
  updatedAt: string;
}

export interface IRisk {
  id: number;
  title: string;
  type: RISK_TYPES;
  description: string;
  impact: number;
  likelihood: number;
  treatment?: IRiskTreatment;
  vulnerabilityScore?: number;
}

export interface ICriticalRisk extends IRisk {
  test: {
    id: number;
    project: {
      id: number;
      name: string;
    };
    name: string;
    hash: string;
  };
}

interface IRangeOfDates {
  dateFrom?: string;
  dateTo?: string;
}

class DashboardCriticalRisks extends React.Component<
  {
    lastRisks: ICriticalRisk[];
    getLastRisks: (params?: {}) => void;
    rangeOfDates: IRangeOfDates;
    downloadCriticalRiskByDate: (params?: {}) => void;
  },
  {}
> {
  state = {
    fullViewedRiskTreatment: 0
  };

  componentDidMount() {
    this.props.getLastRisks();
  }

  viewFullRiskTreatmentDescription = id => () => {
    id = this.state.fullViewedRiskTreatment === id ? 0 : id;
    this.setState({ fullViewedRiskTreatment: id });
  };

  componentWillReceiveProps(nextProps) {
    const { rangeOfDates: nextRangeOfDate = {}, getLastRisks } = nextProps;
    const { rangeOfDates = {} } = this.props;

    if (
      nextRangeOfDate.dateFrom !== rangeOfDates.dateFrom ||
      nextRangeOfDate.dateTo !== rangeOfDates.dateTo
    ) {
      setFilteredCostChartData(nextProps, getLastRisks);
    }
  }

  render() {
    const { downloadCriticalRiskByDate, lastRisks, rangeOfDates } = this.props;

    if (!lastRisks) {
      return (
        <div className="styled-block">
          <DashboardChartHeader
            downloadPDF={downloadCriticalRiskByDate}
            rangeOfDates={rangeOfDates}
            caption="Critical Risks"
            formName="CriticalRiskSearchForm"
          />
          <div className="table-no-items">Loading...</div>
        </div>
      );
    }

    return (
      <div className="styled-block">
        <DashboardChartHeader
          downloadPDF={lastRisks.length ? downloadCriticalRiskByDate : null}
          rangeOfDates={rangeOfDates}
          caption="Critical Risks"
          formName="CriticalRiskSearchForm"
        />

        {lastRisks.length ? (
          <div className="table-responsive styled-table">
            <table>
              <tbody>
                <tr>
                  <th>Title</th>
                  <th>Activity</th>
                  <th>Project</th>
                  <th className="text-center">Rating</th>
                  <th>Description</th>
                  <th>Treatment</th>
                  <th>
                    Residual
                    <br />
                    Risk Rating
                  </th>
                  <th>Comment</th>
                  <th>Status</th>
                </tr>
                {lastRisks.map(risk => (
                  <tr key={risk.id}>
                    <td>{risk.title}</td>
                    <td>
                      <Link to={`/client/reports/${risk.test.id}/details`}>
                        {risk.test.name}
                      </Link>
                    </td>
                    <td>{risk.test.project.name}</td>
                    <td className="rating">{risk.impact * risk.likelihood}</td>
                    <td>{risk.description}</td>
                    <td>
                      {risk.treatment && renderAction(risk.treatment.action)}
                    </td>
                    <td>
                      {risk.treatment && (
                        <span
                          style={{
                            color: getColorByRiskRating(
                              risk.treatment.residualRiskRating
                            )
                          }}
                        >
                          {risk.treatment.residualRiskRating}
                        </span>
                      )}
                    </td>
                    <td>
                      {risk.treatment && (
                        <div
                          onClick={this.viewFullRiskTreatmentDescription(
                            risk.treatment.id
                          )}
                          className={
                            this.state.fullViewedRiskTreatment ===
                            risk.treatment.id
                              ? "word-break-all risk-treatment-comment full"
                              : "text-truncate risk-treatment-comment"
                          }
                        >
                          {`Last update: ${formatDate(
                            risk.treatment.updatedAt
                          )}`}
                          <br />
                          {risk.treatment.comment}
                        </div>
                      )}
                    </td>
                    <td>
                      {risk.treatment && renderStatus(risk.treatment.status)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="table-no-items">There are no items...</div>
        )}
      </div>
    );
  }
}

export default connect(
  (state: any) => ({
    lastRisks: state.dashboard.lastRisks,
    rangeOfDates: state.form.CriticalRiskSearchForm
      ? state.form.CriticalRiskSearchForm.values
      : {}
  }),
  {
    getLastRisks,
    downloadCriticalRiskByDate
  }
)(DashboardCriticalRisks as any);
