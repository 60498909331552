import {
  mergeReducers,
  paginationDefaultState,
  reducerCrudItems,
  reducerLoaderItems
} from "helpers/reducer-helper";
import { combineReducers } from "redux";
import * as ActionTypes from "constants/action-type";

const mainReducer = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const loaderReducer = (
  state = { ...paginationDefaultState, itemsPerPage: 10 },
  action
) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default combineReducers({
  items: combineReducers({
    items: mergeReducers(
      reducerCrudItems(ActionTypes.CONSULTANT_DAYS),
      mainReducer
    ),
    loader: mergeReducers(
      reducerLoaderItems(ActionTypes.CONSULTANT_DAYS),
      loaderReducer
    )
  })
});
