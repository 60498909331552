import * as React from "react";
import {Button, TableBody, WithStyles, withStyles} from "@material-ui/core";
import {Link, withRouter} from "react-router-dom";
import {RouteComponentProps} from "react-router";
import {TableRow, TableCell, Table, IconButton, Icon, Typography, Card, CardContent} from "@material-ui/core";
import EnhancedTableHead from "../../../../components/table/EnhancedTableHead";
import {
  getTesterProfileDBSCertificatesRequest
} from "../../../../api/requests/admin-tester-profile";

import {styles} from "../../../../styles/material/tesrer-card";
import {downloadSecureDocument} from "../../../../api/requests/document-download";
import {API_SECURE_DOCUMENTS_ENDPOINT_URL} from "../../../../constants/urls";
import {connect} from "react-redux";
import {formatDateWithSlashesDdMmYyyy} from "../../../../helpers/date-formatter";
import {IDBSCertificate} from "../../../../models/dbs-certificate.interface";
import {getStatusDescription} from "../../../../constants/dbs-certificate-statuses";
import { ROLE } from "constants/roles";
import { Permission } from "constants/permission";
import { getRoles, getUserPermissions } from "selectors/auth";
import { isUserAccountManager } from "helpers/timesheets-expenses";

interface IState {
  isLoaded: boolean;
  dbsCertificates: IDBSCertificate[];
}

type IProps = RouteComponentProps<any> & WithStyles & {
  downloadSecureDocument: (...any) => void,
  baseUrl: string,
  roles: ROLE[],
  permissions: Permission[]
};

class DBSCertificates extends React.Component<IProps, IState> {
  state = {
    isLoaded: false,
    dbsCertificates: []
  };

  baseUrl = this.props.match.url;

  componentWillMount() {
    this.fetchData();
  }

  downloadFile = (event) => {
    const evidenceDocumentId = event.currentTarget.dataset.evidenceid;
    if (evidenceDocumentId) {
      this.props.downloadSecureDocument(evidenceDocumentId);
    }
  };

  componentWillReceiveProps(nextProps: Readonly<IProps>, nextContext: any): void {
    this.setState({isLoaded: false, dbsCertificates: []});
    this.fetchData();
  }

  fetchData() {
    getTesterProfileDBSCertificatesRequest(this.props.match.params.id)
      .then(data => {
        this.setState({dbsCertificates: data, isLoaded: true});
      });
  }

  getValidity = (item: IDBSCertificate) => {
    return item.validFrom && formatDateWithSlashesDdMmYyyy(item.validFrom);
  };

  getValidTo = (item: IDBSCertificate) => {
    return item.reValidTo && formatDateWithSlashesDdMmYyyy(item.reValidTo);
  };

  render() {
    const hasEditPermission = !isUserAccountManager(this.props.roles) || (isUserAccountManager(this.props.roles) && this.props.permissions.includes(Permission.CAN_MANAGE_TESTERS));

    if (!this.state.isLoaded) {
      return (
        <div>
          Loading...
        </div>
      );
    }

    const columns = [
      "Certificate",
      "Comment",
      "Reference No",
      "Validated",
      "AVORD re-validate",
      "AVORD admin comments"
    ];

    if (hasEditPermission) {
      columns.push("Edit");
    }
    columns.push("Check status");

    return (
      <Card className={this.props.classes.card}>
        <div className={this.props.classes.details}>
          <CardContent className={this.props.classes.content}>
            <Typography variant="headline" className={this.props.classes.header}>
              <span>
                DBS Basic Financial Check
              </span>
            </Typography>
            <hr/>
            <Table>
              <EnhancedTableHead columns={columns}/>
              <TableBody>
                {
                  !this.state.dbsCertificates.length ?
                    <TableRow key={0}>
                      <TableCell colSpan={8}>There are no DBS certificates.</TableCell>
                    </TableRow>
                    : this.state.dbsCertificates.map((item: IDBSCertificate) => {
                      return (
                        <TableRow key={item.id}>
                          <TableCell>
                            <Button
                              variant="contained"
                              color="primary"
                              data-evidenceid={item.evidence.replace(API_SECURE_DOCUMENTS_ENDPOINT_URL, "")}
                              onClick={this.downloadFile}>
                              Download
                            </Button>
                          </TableCell>

                          <TableCell>{item.comment}</TableCell>
                          <TableCell>{item.referenceNo}</TableCell>
                          <TableCell>{this.getValidity(item)}</TableCell>
                          <TableCell>{this.getValidTo(item)}</TableCell>
                          <TableCell>{item.adminComment}</TableCell>

                          {hasEditPermission && (
                            <TableCell>
                              <Link to={this.baseUrl + "/update-dbs-certificate/" + item.id}>
                                <IconButton>
                                  <Icon>edit</Icon>
                                </IconButton>
                              </Link>
                            </TableCell>
                          )}

                          <TableCell>
                            <Link to={this.baseUrl + "/update-dbs-certificate/" + item.id}>
                              {getStatusDescription(item.status).name}
                            </Link>
                          </TableCell>
                        </TableRow>
                      );
                    })
                }
              </TableBody>
            </Table>
          </CardContent>
        </div>
      </Card>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    roles: getRoles(state),
    permissions: getUserPermissions(state)
  };
}

const connected = connect(mapStateToProps, {
  downloadSecureDocument
})(DBSCertificates as any);

const routered = withRouter(withStyles(styles as any)(connected) as any);

export default routered as React.ComponentClass<{}>;
