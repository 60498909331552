import * as React from "react";
import Paper from "@material-ui/core/Paper/Paper";
import Table from "@material-ui/core/Table/Table";
import { TableRow, TableCell, Button } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody/TableBody";
import { connect } from "react-redux";
import { invoiceLoader } from "../../../actions/invoice";
import selector from "../../../selectors/invoice";
import { TablePagination } from "@material-ui/core";
import EnhancedTableHead from "../../../components/table/EnhancedTableHead";
import { isChangesExist } from "../../../helpers/props-checker";
import { IAnyProps } from "../../../interfaces/any-props.interface";
import debounce from "debounce";
import { getInvoicesAction } from "../../../actions/invoice";
import { IInvoice } from "../../../models/invoice.interface";
import { formValueSelector } from "redux-form";
import { INVOICES_SEARCH_FORM_NAME } from "./InvoicesSearchForm";
import { format } from "date-fns";
import { downloadInvoiceFile } from "../../../api/requests/document-download";
import { formatDateWithTime } from "../../../helpers/date-formatter";
import { Link } from "react-router-dom";
import { extractId } from "../../../helpers/converter-helper";
import {
  InvoiceStatus,
  SENT_STATUSES_FOR_SEARCH_FORM
} from "../../../constants/invoice";
import { TableNoItems } from "../../../components/table/TableNoItems";

const formSelector = formValueSelector(INVOICES_SEARCH_FORM_NAME);

class InvoicesList extends React.Component<
  {
    downloadInvoiceFile: (invoiceId) => void;
    baseUrl: string;
    formValues: {
      no: string;
      dateFrom: Date;
      dateTo: Date;
      client: string;
      po: string;
      status: string;
    };
    [key: string]: any;
  },
  {}
> {
  loadItems = props => {
    let status;

    if (
      props.formValues.status &&
      props.formValues.status.toLocaleUpperCase() ===
        InvoiceStatus.INVOICE_STATUS_PAID
    ) {
      status = props.formValues.status;
    } else if (props.formValues.status) {
      status = SENT_STATUSES_FOR_SEARCH_FORM;
    }

    props.loadItems(
      props.pageNumber,
      props.itemsPerPage,
      props.showStatus,
      props.formValues.no,
      props.formValues.dateFrom,
      props.formValues.dateTo,
      props.formValues.client,
      props.formValues.po,
      status
    );
  };

  componentDidMount() {
    this.loadItems(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (
      isChangesExist(
        ["pageNumber", "itemsPerPage", "showStatus", "filterText"],
        nextProps,
        this.props
      ) ||
      JSON.stringify(nextProps.formValues) !==
        JSON.stringify(this.props.formValues)
    ) {
      this.loadItems(nextProps);
    }
  }

  handleChangePage = (event, page) => {
    this.props.setPageNumber(page + 1);
  };

  handleChangeRowsPerPage = event => {
    this.props.setItemsPerPage(event.target.value);
  };

  downloadFile = event => {
    const invoiceId = event.currentTarget.dataset.invoiceid;
    if (invoiceId) {
      this.props.downloadInvoiceFile(invoiceId);
    }
  };

  render() {
    if (!this.props.isLoaded) {
      return <TableNoItems asLoading hasWrapper />;
    }

    if (!this.props.data.length) {
      return <TableNoItems hasWrapper />;
    }

    return (
      <Paper style={{ overflowX: "auto", width: "100%" }}>
        <Table>
          <EnhancedTableHead
            columns={[
              "No.",
              "Date",
              "Client",
              "Invoice Contact",
              "Invoice address",
              "Amount",
              "Consultant/Tester",
              "Status",
              "Download"
            ]}
            status={this.props.showStatus}
          />
          <TableBody>
            {this.props.data.map((item: IInvoice) => {
              return (
                <TableRow key={item.id}>
                  <TableCell>{item.xeroInvoiceNumber}</TableCell>
                  <TableCell>{formatDateWithTime(item.date)}</TableCell>
                  <TableCell>{item.clientName}</TableCell>
                  <TableCell>{item.invoiceContact}</TableCell>
                  <TableCell>{item.invoiceAddress}</TableCell>
                  <TableCell>
                    {item.amount}&nbsp;{item.currencyCode}
                  </TableCell>
                  <TableCell>
                    <Link
                      to={"/admin/testers/" + extractId((item as any).tester)}
                    >
                      {item.testerName}
                    </Link>
                  </TableCell>
                  <TableCell>
                    {item.status === InvoiceStatus.INVOICE_STATUS_PAID
                      ? item.status
                      : "SENT"}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth={true}
                      data-invoiceid={item.id}
                      onClick={this.downloadFile}
                    >
                      Download
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={this.props.totalItems}
          rowsPerPage={this.props.itemsPerPage}
          page={this.props.pageNumber - 1}
          backIconButtonProps={{
            "aria-label": "Previous Page"
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page"
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Paper>
    );
  }
}

export default connect<IAnyProps, IAnyProps, IAnyProps & { baseUrl: string }>(
  state => {
    return {
      data: selector.getItems(state),
      isLoaded: selector.getIsLoaded(state),
      itemsPerPage: selector.getItemsPerPage(state),
      pageNumber: selector.getPageNumber(state),
      totalItems: selector.getTotalItems(state),
      formValues: formSelector(
        state,
        "no",
        "dateFrom",
        "dateTo",
        "client",
        "status"
      )
    };
  },
  {
    loadItems: getInvoicesAction,
    setItemsPerPage: invoiceLoader.setItemsPerPage,
    setPageNumber: invoiceLoader.setPageNumber,
    downloadInvoiceFile
  }
)(InvoicesList);
