import * as React from "react";
import { IBackRoute } from "../../../components/routerModal";
import Grid from "@material-ui/core/Grid";
import { FieldGeneric } from "../../../components/forms/FieldGeneric";
import ReduxTextField from "../../../components/forms/ReduxTextField";
import ReduxSelectField from "../../../components/forms/ReduxSelectField";
import {
  INSURANCE_VALUES_COUNT,
  InsuranceTypes
} from "../../../constants/insurance";
import MenuItem from "@material-ui/core/MenuItem";
import ReduxDocumentFileField from "../../../components/forms/ReduxDocumentFileField";
import ReduxDatePicker from "../../../components/forms/ReduxDatePicker";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { valuesIn, difference } from "lodash";

const InsurancesForm: React.SFC<IBackRoute & { usedTypes?: string[] }> = ({
  backRoute,
  usedTypes
}) => {
  const needDisableSelect =
    difference(valuesIn(InsuranceTypes), usedTypes ? usedTypes : []).length <=
    1;

  return (
    <React.Fragment>
      <h4>Insurance Details</h4>
      <hr />
      <Grid container spacing={40}>
        <Grid item xs={12}>
          <FieldGeneric
            name="insuranceCompany"
            label="Name of insurance company *"
            component={ReduxTextField}
          />
          <FieldGeneric
            name="type"
            label="Type *"
            component={ReduxSelectField}
            disabled={needDisableSelect}
          >
            {valuesIn(InsuranceTypes).map((option: string) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </FieldGeneric>
          <FieldGeneric
            name="value"
            label="Value *"
            component={ReduxSelectField}
          >
            {Array.apply(null, Array(INSURANCE_VALUES_COUNT - 1))
              .map((a, i) => i + 1)
              .map(value => (
                <MenuItem key={value} value={value}>
                  &pound;{value}m
                </MenuItem>
              ))}
            <MenuItem key={0} value={INSURANCE_VALUES_COUNT}>
              more than &pound;{INSURANCE_VALUES_COUNT - 1}m
            </MenuItem>
          </FieldGeneric>
          <FieldGeneric
            name="evidence"
            label="File path *"
            component={ReduxDocumentFileField}
          />
          <FieldGeneric
            name="comments"
            label="Comments"
            component={ReduxTextField}
          />
          <Grid container>
            <Grid item xs={6}>
              <FieldGeneric
                name="validFrom"
                label="From *"
                InputLabelProps={{
                  shrink: true
                }}
                component={ReduxDatePicker}
              />
            </Grid>
            <Grid item xs={6}>
              <FieldGeneric
                name="validTo"
                label="To *"
                InputLabelProps={{
                  shrink: true
                }}
                component={ReduxDatePicker}
              />
            </Grid>
          </Grid>
          <hr />
          <div className="d-flex justify-content-between mt-4">
            <Link to={backRoute}>
              <Button variant="contained" color="default">
                Cancel
              </Button>
            </Link>
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default InsurancesForm;
