import * as React from "react";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router-dom";
import CompletedReportsTable from "./CompletedReportsTable";
import { IAnyProps } from "../../../../interfaces/any-props.interface";
import AmendmentsTable from "../../../client-tester/amendments/AmendmentsTable";
import AdminBackToTest from "../../../client-tester/test-details/button/AdminBackToTest";
import AdditionalDocuments from "./AdditionalDocuments/AdditionalDocuments";
import { Zoom } from "@material-ui/core";
import Icon from "@material-ui/core/Icon/Icon";
import TooltipIcon from "@material-ui/core/Tooltip/Tooltip";

type IProps = RouteComponentProps<any> & IAnyProps & {};

class CompletedReports extends React.Component<IProps, {}> {
  baseUrl = this.props.match.url;

  render() {
    return (
      <>
        <AdminBackToTest />
        <div className="styled-block">
          <div className="block-header-wrapper mb-4">
            <h4 className="block-header">Completed Report(s)</h4>
          </div>
          <CompletedReportsTable testId={this.props.match.params.test} />
        </div>

        <div className="styled-block">
          <div className="block-header-wrapper mb-4">
            <h4 className="block-header">Clarifications</h4>
          </div>
          <AmendmentsTable />
        </div>
        <div className="styled-block">
          <div className="block-header-wrapper mb-4">
            <h4 className="block-header">
              Additional documents
              <TooltipIcon
                placement={"top"}
                TransitionComponent={Zoom}
                disableFocusListener
                disableTouchListener
                title="Additional documents that can be created in addition to the final report"
              >
                <Icon
                  style={{
                    marginLeft: "5px",
                    marginBottom: "2px",
                    verticalAlign: "middle"
                  }}
                >
                  info
                </Icon>
              </TooltipIcon>
            </h4>
          </div>
          <AdditionalDocuments testId={this.props.match.params.test} />
        </div>
      </>
    );
  }
}

export default withRouter(CompletedReports);
