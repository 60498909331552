import * as React from "react";
import ReportsList from "./ReportsList";
import {withRouter, Switch, Route, RouteComponentProps} from "react-router-dom";
import ReportsSearchForm from "./ReportsSearchForm";

class Reports extends React.Component<RouteComponentProps<any>, {}> {
  baseUrl = this.props.match.path;

  reportsList = () => (
    <div>
      <div className="crud-sections-header">
        <h4 className="main-content-header">Reports</h4>
      </div>
      <div>
        <ReportsSearchForm/>
        <ReportsList baseUrl={this.baseUrl}/>
      </div>
    </div>
  );

  render() {
    return (
      <Switch>
        <Route exact path={this.baseUrl} render={this.reportsList}/>
      </Switch>
    );
  }
}

export default withRouter(Reports);
