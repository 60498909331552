import React from "react";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Icon from "@material-ui/core/Icon/Icon";
import { withRouter, RouteComponentProps } from "react-router-dom";

interface CustomIconButton extends RouteComponentProps {
  iconName: string;
  onClick?: () => void;
  to?: string;
  disabled?: boolean;
}

const CustomIconButton = (props: CustomIconButton) => {
  const { history, onClick, to, disabled, iconName } = props;
  const buttonHandler = () => {
    if (typeof onClick === "function") {
      return onClick();
    }
    if (to) {
      return history.push(to);
    }
    return;
  };
  return (
    <IconButton onClick={buttonHandler} disabled={!!disabled}>
      <Icon style={{ color: "#778596" }}>{iconName}</Icon>
    </IconButton>
  );
};

export default withRouter(CustomIconButton);
