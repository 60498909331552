import * as React from "react";
import Paper from "@material-ui/core/Paper/Paper";
import { connect } from "react-redux";
import {
  usersRequest,
  usersLoader,
  getUsersFiltered,
  archiveItem,
  unarchiveItem,
  deleteItem,
  changeTesterRoleAction,
  resetAction2FA,
  postEmail,
} from "actions";
import selector from "selectors/users";
import { TablePagination } from "@material-ui/core";
import { isChangesExist } from "helpers/props-checker";
import { IAnyProps } from "interfaces/any-props.interface";
import { IUsers } from "models/users.interface";
import debounce from "debounce";
import { formValueSelector } from "redux-form";
import { FORM_NAME } from "./TestersSearchForm";
import AssignCompanyManagerModal from "./AssignCompanyManagerModal";
import { ACTIVE } from "constants/filter";
import { testingCompaniesRequest } from "actions/testing-companies";
import { TableNoItems } from "components/table/TableNoItems";
import { getTesterListColumns, OnReset } from "./TesterList";
import Table, { ColumnType } from "components/table/Table";
import { RootState } from "reducers";
import { Group } from "constants/group";

const selectorSearchForm = formValueSelector(FORM_NAME);

interface IFilterValues {
  name: string;
  email: string;
  phone: string;
  date: string;
  createdAt: string;
  deletedAt: string;
  userTestingCompanyFilter: string;
  role: string;
}

type IProps = {
  baseUrl: string;
  [key: string]: any;
} & IFilterValues;

class TestersList extends React.Component<IProps, {}> {
  state = {
    isArchive: false,
    assignCompanyManagerModalVisible: false,
    testerProfileId: "",
    userGroup: "",
    isConfirmModalOpen: false,
    userId: "",
    is2FAModalOpen: false
  };

  private columns: ColumnType<IUsers>[] = [];

  debouncedLoad = debounce(nextProps => {
    this.loadItems(nextProps);
  }, 500);

  handleSendLink: OnReset = (user) => {
    this.props.postEmail(user.email);
  };

  loadItems = (props: IProps) => {
    props.loadTestingCompanies(1, 100, ACTIVE);
    props.loadItems(
      props.pageNumber,
      props.itemsPerPage,
      props.showStatus,
      props.name,
      props.email,
      props.phone,
      props.role,
      props.createdAt,
      props.deletedAt,
      props.userDbsFilter,
      props.userTestingCompanyFilter
    );
  };

  onUnarchive = (item) => {
    this.props.unarchiveItem(
      item.id,
      this.props.pageNumber,
      this.props.itemsPerPage,
      this.props.showStatus,
      this.props.name,
      this.props.email,
      this.props.phone,
      this.props.role,
      this.props.createdAt,
      this.props.deletedAt,
      this.props.userDbsFilter,
      this.props.userTestingCompanyFilter
    );
  };

  onArchive = (item) => {
    this.props.archiveItem(
      item.id,
      this.props.pageNumber,
      this.props.itemsPerPage,
      this.props.showStatus,
      this.props.name,
      this.props.email,
      this.props.phone,
      this.props.role,
      this.props.createdAt,
      this.props.deletedAt,
      this.props.userDbsFilter,
      this.props.userTestingCompanyFilter
    );
    this.setOnArchive(true);
  };

  onDelete: OnReset = (item) => {
    this.props.deleteItem(
      item.id,
      this.props.pageNumber,
      this.props.itemsPerPage,
      this.props.showStatus,
      this.props.name,
      this.props.email,
      this.props.phone,
      this.props.role,
      this.props.createdAt,
      this.props.deletedAt,
      this.props.userDbsFilter,
      this.props.userTestingCompanyFilter
    );
  };

  setOnArchive = value => {
    this.setState({ isArchive: value });
  };

  componentDidMount() {
    const props = this.props;
    this.loadItems(props);

    this.columns = getTesterListColumns({
        onResetPassword: this.handleSendLink,
        onReset2FA({id}) {
          if (id) {
            props.onReset2FA(id)
          }
        },
        onArchive: this.onArchive,
        onUnArchive: this.onUnarchive,
        onDelete: this.onDelete,
        onSwitchCompanyManager: (user) => {
          if (user.groupName === Group.MANAGER) {
            this.props.changeRole(
              user.testerProfileId,
              user.groupName,
              this.props.pageNumber,
              this.props.itemsPerPage,
              this.props.showStatus,
              this.props.name,
              this.props.email,
              this.props.phone,
              this.props.role,
              this.props.createdAt,
              this.props.deletedAt,
              this.props.userDbsFilter,
              this.props.userTestingCompanyFilter
            );
            return;
          }

          this.setState({
            assignCompanyManagerModalVisible: true,
            testerProfileId: user.testerProfileId,
            userGroup: user.groupName,
          });
        }
      }
    );
  }

  componentWillReceiveProps(nextProps) {
    if (
      isChangesExist(
        [
          "pageNumber",
          "itemsPerPage",
          "showStatus",
          "name",
          "email",
          "phone",
          "createdAt",
          "deletedAt",
          "userDbsFilter",
          "userTestingCompanyFilter",
          "role"
        ],
        nextProps,
        this.props
      )
    ) {
      if (
        isChangesExist(
          [
            "name",
            "email",
            "phone",
            "createdAt",
            "deletedAt",
            "userDbsFilter",
            "userTestingCompanyFilter",
            "role"
          ],
          nextProps,
          this.props
        )
      ) {
        this.debouncedLoad.clear();
        this.debouncedLoad(nextProps);
      } else {
        this.loadItems(nextProps);
      }
    }

    if (!nextProps.data.length && nextProps.pageNumber > 1) {
      this.props.setPageNumber(1);
    }
  }

  handleChangePage = (event, page) => {
    this.props.setPageNumber(page + 1);
  };

  handleChangeRowsPerPage = event => {
    this.props.setItemsPerPage(event.target.value);
  };

  loadItem = props => {
    props.loadItem(
      props.name,
      props.email,
      props.phone,
      props.createdAt,
      props.deletedAt,
      props.userDbsFilter
    );
  };

  showModal = (testerProfileId, userGroup, isCompanyManager) => () => {
    if (isCompanyManager) {
      this.setState({isConfirmModalOpen: true, testerProfileId, userGroup});
    } else {
      this.setState({
        assignCompanyManagerModalVisible: true,
        testerProfileId,
        userGroup
      });
    }
  };

  hideAssignCompanyManagerModal = () => {
    this.setState({
      assignCompanyManagerModalVisible: false,
      testerProfileId: "",
      userGroup: ""
    });
  };

  hideConfirmModal = () => {
    this.setState({
      isConfirmModalOpen: false,
      testerProfileId: "",
      userGroup: ""
    });
  };

  showReset2FAModal: OnReset = (user) => {
    this.setState({is2FAModalOpen: true, userId: user.id});
  };

  hideReset2FAModal = () => {
    this.setState({is2FAModalOpen: false});
  };

  onConfirm = () => {
    this.props.changeRole(
      this.state.testerProfileId,
      this.state.userGroup,
      this.props.pageNumber,
      this.props.itemsPerPage,
      this.props.showStatus,
      this.props.name,
      this.props.email,
      this.props.phone,
      this.props.role,
      this.props.createdAt,
      this.props.deletedAt,
      this.props.userDbsFilter,
      this.props.userTestingCompanyFilter
    );
  };

  render() {
    if (!this.props.isLoaded) {
      return <TableNoItems asLoading hasWrapper />;
    }

    if (!this.props.data.length) {
      return <TableNoItems hasWrapper />;
    }

    return (
      <div>
        {/* <UsersReassignModal isArchive={this.state.isArchive} setOnArchive={this.setOnArchive} onArchive={this.onArchive}/> */}
        <AssignCompanyManagerModal
          open={this.state.assignCompanyManagerModalVisible}
          onConfirm={this.props.changeRole}
          onClose={this.hideAssignCompanyManagerModal}
          testerProfileId={this.state.testerProfileId}
          userGroup={this.state.userGroup}
          pageNumber={this.props.pageNumber}
          itemsPerPage={this.props.itemsPerPage}
          showStatus={this.props.showStatus}
        />
        <Paper style={{overflowX: "auto", width: "100%"}}>
          <Table columns={this.columns} data={this.props.data} index="id" />
          <TablePagination
            component="div"
            count={this.props.totalItems}
            rowsPerPage={this.props.itemsPerPage}
            page={this.props.pageNumber - 1}
            backIconButtonProps={{"aria-label": "Previous Page"}}
            nextIconButtonProps={{"aria-label": "Next Page"}}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>);
  }
}

export default connect<IAnyProps, IAnyProps, IAnyProps & { baseUrl: string }, RootState>(state => ({
  data: selector.getItems(state),
  isLoaded: selector.getIsLoaded(state),
  itemsPerPage: selector.getItemsPerPage(state),
  pageNumber: selector.getPageNumber(state),
  showStatus: selector.getFilterActivityStatus(state),
  totalItems: selector.getTotalItems(state),
  name: selectorSearchForm(state, "name") || "",
  email: selectorSearchForm(state, "email") || "",
  phone: selectorSearchForm(state, "phone") || "",
  createdAt: selectorSearchForm(state, "createdAt") || "",
  deletedAt: selectorSearchForm(state, "deletedAt") || "",
  userDbsFilter: selectorSearchForm(state, "userDbsFilter") || "",
  userTestingCompanyFilter: selectorSearchForm(state, "userTestingCompanyFilter") || "",
  role: selectorSearchForm(state, "role") || "TesterAndManager",
}), {
  postEmail,
  loadItem: usersRequest.getItem,
  loadItems: getUsersFiltered,
  setItemsPerPage: usersLoader.setItemsPerPage,
  setPageNumber: usersLoader.setPageNumber,
  archiveItem: archiveItem("TesterAndManager"),
  unarchiveItem: unarchiveItem("TesterAndManager"),
  deleteItem: deleteItem("TesterAndManager"),
  changeRole: changeTesterRoleAction,
  loadTestingCompanies: testingCompaniesRequest.getItems,
  onReset2FA: resetAction2FA
})(TestersList);
