import {Modal} from "@material-ui/core";
import * as React from "react";
import {
  getBookingRejectBackRoute, getBookingRejectStatus, getBookingRejectTestId,
  getIsOpenBookingReject
} from "../../../../selectors/modal";
import {connect} from "react-redux";
import {bookingRejectModalClose} from "../../../../actions/modals";
import {
  changeTestStatusAction, IChangeTestStatusAction
} from "../../../../actions/test";
import {TestStatus} from "../../../../constants/test-status";

interface IStateToProps {
  isOpenModal: any;
  testId: number;
  status: TestStatus;
  backRoute: string;
}

interface IDispatchToProps {
  changeTestStatus: IChangeTestStatusAction;
  bookingRejectModalClose: () => any;
}

class BookingRejectModal extends React.Component<IStateToProps & IDispatchToProps> {
  render() {
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.props.isOpenModal}
        onClose={this.handleClose}
        className="d-flex overflow-auto"
      >
        <div className="styled-modal xs">
          <h4>Are you sure?</h4>
          <hr/>
          <div className="text-right">
            <button
              className="btn-cancel"
              onClick={this.handleClose}>
              No
            </button>
            <button
              className="btn-accept"
              onClick={this.handleSubmit}>
              Reject
            </button>
          </div>
        </div>
      </Modal>
    );
  }

  handleSubmit = () => this.props.changeTestStatus(
    this.props.testId,
    this.props.status,
    (data) => {
      this.props.bookingRejectModalClose();
      location.reload()
    }
  );

  private readonly handleClose = () => {
    this.props.bookingRejectModalClose();
  };
}

function mapStateToProps(state) {
  return {
    isOpenModal: getIsOpenBookingReject(state),
    testId: getBookingRejectTestId(state),
    status: getBookingRejectStatus(state),
    backRoute: getBookingRejectBackRoute(state),
  };
}

const connectedComponent = connect<IStateToProps, IDispatchToProps>(mapStateToProps, {
  changeTestStatus: changeTestStatusAction,
  bookingRejectModalClose,
})(BookingRejectModal);

export default connectedComponent;