import React, { PropsWithChildren, useCallback, useState } from "react";
import AreYouSureModal from "components/table/AreYouSureModal";

type ArchiveProps = PropsWithChildren<{
  onConfirm: () => void,
  message?: string;
  buttonText?: string;
}>;

export function ConfirmWrapper({children, onConfirm, message, buttonText}: ArchiveProps) {
  const [open, setOpen] = useState(false);

  const hideConfirmModal = useCallback(() => {
    setOpen(false);
  }, []);

  const onConfirmHandler = useCallback(() => {
    onConfirm();
  }, []);

  const showConfirmModal = useCallback(e => {
    e.preventDefault();

    setOpen(true);
  }, []);

  return <>
    <AreYouSureModal
      open={open}
      onClose={hideConfirmModal}
      onConfirm={onConfirmHandler}
      message={message}
      buttonText={buttonText}
    />
    <span onClick={showConfirmModal} style={{cursor: 'pointer'}}>
      {children}
    </span>
  </>
}
