import {Observable} from 'rxjs';
import {catchError, flatMap, switchMap} from 'rxjs/operators';
import {ActionsObservable} from 'redux-observable';
import * as actionType from '../constants/action-type';
import {setRequestInProcess} from '../actions/request';
import {of} from "rxjs/internal/observable/of";
import {fromPromise} from "rxjs/internal-compatibility";
import {openSnackbar} from "../actions/snackbar";
import {IActionPayload} from "./index";
import {startEndCatch} from "./pipes/errors";
import {getTestsForCalendar} from "../api/requests/test";
import { setCalendarEventsAction } from "../actions/calendar";
import moment from "moment";
import {getCalendarMonth, getCalendarViewType, getCalendarYear} from "../selectors/calendar";

type IActions = IActionPayload<{ year: number, month: number }>;
type IActionType = IActionPayload<'month' | 'year'>;


const getDatesByType = (year, month, type) => {
  let start;
  let end;

  if (type === 'month') {
    end = moment()
      .year(year)
      .month(month)
      .startOf('month')
      .startOf('isoWeek')
      .toDate();
    start = moment()
      .year(year)
      .month(month)
      .endOf('month')
      .endOf('isoWeek')
      .add('1d')
      .toDate();
  } else {
    start = moment()
      .year(year)
      .month(11)
      .endOf('year')
      .endOf('isoWeek')
      .add('1d')
      .toDate();
    end = moment()
      .year(year)
      .month(1)
      .startOf('year')
      .toDate();
  }

  return {start, end};
};

export const calendarDateChangeEpic = (action$: ActionsObservable<IActions>, state$): Observable<any> => {
  return action$
    .ofType(actionType.CALENDAR_SET_DATE)
    .pipe(
      switchMap((action: IActions) => {
        const type = getCalendarViewType(state$.value);

        const {start, end} = getDatesByType(action.payload.year, action.payload.month, type);

        return fromPromise(getTestsForCalendar(start, end))
          .pipe(
            flatMap((tests) => {
              return [
                setCalendarEventsAction(tests),
              ];
            }),
            ...startEndCatch('calendarDateChangeEpic'),
          );
      }),
      catchError((error) => {
        return of(setRequestInProcess(false, 'calendarDateChangeEpic'));
      })
    );
};

export const calendarDateTypeChangeEpic = (action$: ActionsObservable<IActionType>, state$): Observable<any> => {
  return action$
    .ofType(actionType.CALENDAR_SET_VIEW)
    .pipe(
      switchMap((action: IActionType) => {
        const year = getCalendarYear(state$.value);
        const month = getCalendarMonth(state$.value);
        const {start, end} = getDatesByType(year, month, action.payload);

        return fromPromise(getTestsForCalendar(start, end))
          .pipe(
            flatMap((tests) => {
              return [
                setCalendarEventsAction(tests),
              ];
            }),
            ...startEndCatch('calendarDateChangeEpic'),
          );
      }),
      catchError((error) => {
        return of(setRequestInProcess(false, 'calendarDateChangeEpic'));
      })
    );
};
