import React, { useRef, useEffect } from "react";
import Chart from "chart.js";
import { usePrevValue } from "../../../hooks/use-prev-value";
import { hasDataChanged } from "../../../helpers/hasDataChanged";

const options = {
  title: {
    display: true
  },
  layout: {
    padding: {
      left: 0,
      right: 30,
      top: 0,
      bottom: 0
    }
  },
  legend: {
    position: "bottom"
  },
  scales: {
    yAxes: [
      {
        scaleLabel: {
          display: true,
          labelString: "Probability"
        },
        ticks: {
          stepSize: 1,
          autoSkip: false
        }
      }
    ],
    xAxes: [
      {
        scaleLabel: {
          display: true,
          labelString: "Impact"
        },
        ticks: {
          stepSize: 1,
          autoSkip: false
        }
      }
    ]
  }
};

const BubbleChart = ({ risks = [] }) => {
  const canvasRef = useRef(null);
  const prevRiskValues = usePrevValue(risks, []);
  const shouldUpdateChart = hasDataChanged(prevRiskValues, risks);
  const colorize = risk => {
    const riskType = risk.impact * risk.likelihood;
    let color;

    if (riskType <= 4) {
      color = "153, 204, 102,";
    } else if (riskType <= 12) {
      color = "255, 204, 0,";
    } else if (riskType <= 25) {
      color = "255, 0, 0,";
    }

    return {
      backgroundColor: `rgba(${color} .5)`,
      borderColor: `rgba(${color} 1)`
    };
  };
  const dataItemCreator = risks => {
    return risks.map(risk => ({
      label: [risk.title],
      backgroundColor: colorize(risk).backgroundColor,
      borderColor: colorize(risk).borderColor,
      data: [
        {
          x: risk.impact,
          y: risk.likelihood,
          r: risk.impact * risk.likelihood
        }
      ]
    }));
  };

  const renderChart = risks => {
    const node = canvasRef.current;

    if (risks.length) {
      new Chart(node, {
        type: "bubble",
        data: {
          datasets: dataItemCreator(risks),
          data: [1, 2, 3, 4, 5]
        },
        options: options
      });
    }
  };

  useEffect(() => {
    if (shouldUpdateChart) {
      renderChart(risks);
    }
  }, [risks, shouldUpdateChart]);

  if (!risks.length) {
    return null;
  }

  return <canvas style={{ width: 800, minHeight: 320 }} ref={canvasRef} />;
};

export default BubbleChart;
