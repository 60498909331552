import * as React from "react";
import ReduxTextField from "../../../../components/forms/ReduxTextField";
import { FieldGeneric } from "../../../../components/forms/FieldGeneric";
import Grid from "@material-ui/core/Grid/Grid";
import { Link } from "react-router-dom";
import { IBackRoute } from "../../../../components/routerModal";
import ReduxLocationsField from "../../../../components/forms/ReduxLocationsField";
import { ILocation } from "../../../../models/location.interface";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";
import { Checkbox, ListItemText, MenuItem } from "@material-ui/core";
import { customSelectHandler } from "../../../../helpers/timesheets-expenses";
import { ReduxSelectSearchFieldCustom } from "../../../../components/timesheets-and-expenses/components/forms/ReduxSelectSearchFieldCustom";
import { SelectOptionType } from "../../../../types";
import { connect } from "react-redux";
import { projectRequest } from "../../../../actions/project";
import { formValueSelector } from "redux-form";
import { FORM_NAME } from "./ProjectCreate";

const ProjectForm: React.SFC<IBackRoute & {
  locations: ILocation[];
  managers: SelectOptionType[];
  managerSelected?: number;
  change: (field: string, value: any) => void;
  formName: string;
}> = ({
  backRoute,
  locations = [],
  managers = [],
  managerSelected,
  change
}) => {
  const managersSelectHandler = customSelectHandler("manager", change);

  return (
    <Grid container spacing={40}>
      <Grid item xs={12}>
        <FieldGeneric
          inputProps={{ maxLength: 100 }}
          name="name"
          label="Project name*"
          component={ReduxTextField}
        />
        <FieldGeneric
          inputProps={{ maxLength: 100 }}
          name="purchaseOrderNumber"
          label="Purchase Order Number*"
          component={ReduxTextField}
        />
        <FieldGeneric
          inputProps={{ maxLength: 100 }}
          name="expensesPurchaseOrderNumber"
          label="Expenses Purchase Order Number*"
          component={ReduxTextField}
        />
        <FieldGeneric
          name="manager"
          label="Manager"
          fullWidth
          component={ReduxSelectSearchFieldCustom}
          onChange={managersSelectHandler}
          items={managers}
          renderItem={(option: SelectOptionType) => (
            <MenuItem key={option.id} value={option.id}>
              <Checkbox checked={managerSelected === option.id} />
              <ListItemText primary={option.name} />
            </MenuItem>
          )}
          renderValue={() => {
            return managers.find(
              (item: SelectOptionType) => item.id === managerSelected
            )?.name;
          }}
        />

        <div className="d-flex justify-content-between">
          <FieldGeneric
            name="locations"
            label="Locations"
            type="number"
            items={locations}
            component={ReduxLocationsField}
          />
          {
            <span className="locations-tool-tip">
              <Tooltip
                disableTouchListener
                disableFocusListener
                interactive
                placement={"bottom-end"}
                title={
                  <span>
                    To add new locations you can follow by the
                    <Link to={`/client/admin/locations/create`}> link </Link>
                    (if permitted)
                  </span>
                }
              >
                <Icon>info</Icon>
              </Tooltip>
            </span>
          }
        </div>
        <div className="text-right mt-4">
          <Link className="btn-cancel" to={backRoute}>
            Cancel
          </Link>
          <button type="submit" className="btn-accept">
            Save
          </button>
        </div>
      </Grid>
    </Grid>
  );
};

function mapStateToProps(state, props) {
  const searchFormSelector = formValueSelector(props.formName);
  return {
    managerSelected: searchFormSelector(state, "manager")
  };
}

export default connect(mapStateToProps)(ProjectForm);
