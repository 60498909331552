import * as React from "react";
import {RouteComponentProps, withRouter} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import {ICompanyDetails} from "../../../../models/company-details.interface";
import {dataFetcher} from "../../../../components/dataFetcher";
import {ISector} from "../../../../models/sector.interface";
import {sectorRequest} from "../../../../actions/sector";
import selector from "../../../../selectors/sector";
import {result} from "lodash";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {ACTIVE} from "../../../../constants/filter";
import {MAX_PAGINATION_RESULTS} from "../../../../constants/loader";

interface IProps {
  companyDetails: ICompanyDetails;
  sector: ISector;
}

class CompanyDetailsPage extends React.Component<RouteComponentProps<any> & IProps, {}> {

  render() {
    const {companyDetails, sector} = this.props;

    return (
      <div className="table-responsive styled-table styled-block">
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Company name</TableCell>
                  <TableCell>{companyDetails.companyName}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Sector</TableCell>
                  <TableCell>{sector.name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2}>
                    <h4 className="test-details-header mt-4">Invoice Contact</h4>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>
                    {companyDetails.contactFirstName && companyDetails.contactLastName &&
                    `${companyDetails.contactFirstName} ${companyDetails.contactLastName}`}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell>{companyDetails.contactEmail}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Telephone</TableCell>
                  <TableCell>{companyDetails.contactPhone}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2}>
                    <h4 className="test-details-header mt-4">Invoice Address</h4>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Address line 1</TableCell>
                  <TableCell>{companyDetails.addressLine1}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Address line 2</TableCell>
                  <TableCell>{companyDetails.addressLine2}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Address line 3</TableCell>
                  <TableCell>{companyDetails.addressLine3}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Town/City</TableCell>
                  <TableCell>{companyDetails.town}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>State/Province</TableCell>
                  <TableCell>{companyDetails.state}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Post/Zip code</TableCell>
                  <TableCell>{companyDetails.postCode}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const fetched = dataFetcher(CompanyDetailsPage, [{
  key: "sector",
  selector: (state, props) => selector.getItemById(result(props, "companyDetails.sector", false))(state),
  action: (props) => sectorRequest.getItems(1, MAX_PAGINATION_RESULTS, ACTIVE),
}]);

export default withRouter(fetched as any);
