import * as ActionTypes from '../constants/action-type';
import {IReview} from "../models/review.interface";

export interface ITesterReviewState {
  [key: string]: [IReview];
}

const reducer = (state: ITesterReviewState = {}, action) => {
  switch (action.type) {
    case ActionTypes.TESTER_REVIEWS_ADD:
      return {
        [action.payload.id]: action.payload.data,
        ...state,
      };
    default:
      return state;
  }
};

export default reducer;
