import * as React from "react";
import {Link, RouteComponentProps, withRouter} from "react-router-dom";
import BookingRequestsList from "./BookingRequestsList";
import BookingRequestsSearchForm from "../../common/BookingRequestsSearchForm";

class BookingRequests extends React.Component<RouteComponentProps<any>, {}> {
  baseUrl = this.props.match.path;

  render() {
    return (
      <div>
        <div className="crud-sections-header">
          <h4 className="main-content-header">Booking Requests</h4>
          <Link to={`/client/schedule/booking`}>
            <button className="mb-2 btn-book">Book</button>
          </Link>
        </div>
        <BookingRequestsSearchForm />
        <BookingRequestsList baseUrl={this.baseUrl} />
      </div>
    );
  }
}

export default withRouter(BookingRequests);
