import * as Types from '../constants/action-type';
import {
    createFilterAction,
    createLoaderAction,
    createCrudAction,
    createRequestAction
} from '../helpers/action-helper';
import {Action, createAction} from "redux-actions";
import {IAnyProps} from "../interfaces/any-props.interface";
import {createLoaderActionItem} from "../interfaces/reducer-item.interface";
import {ITesterIncoming} from "../models/tester-profile.interface";
import {setRequestInProcess} from "./request";
import {handleHttpErrors} from "../helpers/errors/http-error-handler";
import {bookTesterRequest, getTestersFilteredRequest} from "../api/requests/testers";
import {push} from "react-router-redux";
import {openSnackbar} from "./snackbar";
import {getRequestName} from "../helpers/action-request-helper";
import {postItem, putItem} from "../api/requests/testers";
import {reset, stopSubmit} from "redux-form";
import { putTesterProfile as putTesterProfileRequest} from "../api/requests/tester-account";
import { result } from "lodash";

export const testersRequest = createRequestAction(Types.TESTERS);

const crudType = Types.TESTERS;

export interface IPutProfileAction {
  item: IAnyProps & ITesterIncoming;
  backRoute: string;
  formName: string;
}

export const putTesterProfile = (item: IAnyProps & ITesterIncoming, backRoute: string, formName: string): Action<IPutProfileAction> => {
  return createAction(createLoaderActionItem(Types.TESTER_PROFILE).put().type, () => ({
    item,
    backRoute,
    formName,
  }))();
};

export const updateTesterProfile = (model, backRoute, formName) => async dispatch => {
  dispatch(setRequestInProcess(true, getRequestName(crudType, 'putItem')));

  let data;

  try {
    data = await putTesterProfileRequest(model.id, model);

    dispatch(openSnackbar('Updated', 4000));
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(reset(formName));
  dispatch(push(backRoute));
  dispatch(setRequestInProcess(false, getRequestName(crudType, 'putItem')));
}

export const putTesterSpecialisms = (specialisms: string[]): Action<string[]> => {
  return createAction(Types.TESTER_SPECIALISMS_PUT, () => specialisms)();
};

export const specialismsEditOpen = createAction(Types.TESTER_SPECIALISMS_EDIT_OPEN);
export const specialismsEditClose = createAction(Types.TESTER_SPECIALISMS_EDIT_CLOSE);

export const getTestersFiltered = (page: number,
                                   itemsPerPage: number,
                                   show: string,
                                   testId = 0) => async dispatch => {
  dispatch(setRequestInProcess(true, 'getTestersFiltered'));
  /** part responsible for a loader state */
  dispatch(createLoaderAction(Types.TESTERS).setIsNotLoaded());

  let data = [], totalItems;

  try {
    ({ items: data, totalItems } = await getTestersFilteredRequest(
      page,
      itemsPerPage,
      show,
      testId
    ));
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(createLoaderAction(Types.TESTERS).setPaginatioData(data, itemsPerPage, totalItems, page));
  dispatch(setRequestInProcess(false, 'getTestersFiltered'));
};

export const bookTesterAction = (testId, testerId) => async dispatch => {
  dispatch(setRequestInProcess(true, 'bookTesterAction'));

  try {
    await bookTesterRequest(testId, testerId);

    dispatch(openSnackbar('You just have assigned tester to test', 4000));
    dispatch(push('/client/booking-requests'))
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(setRequestInProcess(false, 'bookTesterAction'));
};

export const postItemAction = (model, formName, backRoute, validationHandler) => async dispatch => {
  dispatch(setRequestInProcess(true, getRequestName(crudType, 'postItem')));

  try {
    const data = await postItem(model);

    dispatch(openSnackbar('Created', 4000));
    dispatch(createCrudAction(Types.FAVORITE_TESTERS).addItem(data));
    dispatch(reset(formName));
    dispatch(push(backRoute));
  } catch (error) {
    if (error.response.status === 400) {
      handleHttpErrors(error, dispatch);
      dispatch(
        stopSubmit(
          formName,
          validationHandler(result(error, "response.body.violations", {}))
        )
      );
    }
    return;
  } finally {
    dispatch(setRequestInProcess(false, getRequestName(crudType, 'postItem')));
  }
};

export const putItemAction = (model, formName, backRoute) => async dispatch => {
  dispatch(setRequestInProcess(true, getRequestName(crudType, 'putItem')));

  try {
   const data = await putItem(model);

    dispatch(openSnackbar('Updated', 4000));
    dispatch(createCrudAction(Types.FAVORITE_TESTERS).replaceItem(data));
    dispatch(reset(formName));
    dispatch(push(backRoute));

  } catch (error) {
    handleHttpErrors(error, dispatch);
  } finally {
    dispatch(setRequestInProcess(false, getRequestName(crudType, 'putItem')));
  }
};
