import {IOptionItem} from "../interfaces/option-item.interface";

export enum Recurring {
  RECURRING_NO = 1,
  RECURRING_QUARTERLY = 2,
  RECURRING_ANNUALLY = 3,
  RECURRING_BI_ANNUALLY = 4,
}

export const RECURRING_LIST: IOptionItem[] = [
  {
    key: Recurring.RECURRING_NO,
    value: 'No',
  },
  {
    key: Recurring.RECURRING_QUARTERLY,
    value: 'Quarterly',
  },
  {
    key: Recurring.RECURRING_ANNUALLY,
    value: 'Annually',
  },
  {
    key: Recurring.RECURRING_BI_ANNUALLY,
    value: 'Bi-Annually',
  },
];
