import * as React from "react";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {TableCell, TableRow, Zoom} from "@material-ui/core";
import {BASE_URL} from "../../../constants/urls";
import TesterProfileModal from "./TesterProfileModal";
import {ITesterProfileGet} from "../../../models/tester-profile.interface";
import Tooltip from "@material-ui/core/Tooltip";
import {connect} from "react-redux";
import {IAnyProps} from "../../../interfaces/any-props.interface";
import {getFavoriteTestersFiltered} from "../../../actions/favorite-testers";
import {Link} from "react-router-dom";
import {Group} from "../../../constants/group";

interface IOwnProps {
  tester: ITesterProfileGet;
  deleteFavorite: any;
  resetPassword: any;
}

type IProps = IOwnProps & RouteComponentProps<any>;

class TesterRow extends React.Component<IProps, {}> {
  state = {
    isModalOpen: false,
  };

  toggleModal = () => {
    this.setState({isModalOpen: !this.state.isModalOpen});
  };

  render() {
    const {tester} = this.props;

    return (
      <TableRow>
        <TesterProfileModal
          open={this.state.isModalOpen}
          onClose={this.toggleModal}
          tester={tester}
        />
        <TableCell>
          {
            tester.isOwnedByCompany
              ? (
                <Link to={`${this.props.match.url}/${tester.id}`}>
                  <button className="btn-book">Edit Profile</button>
                </Link>
              ) : (
                <button className="btn-book" onClick={this.toggleModal}>View Profile</button>
              )
          }
        </TableCell>
        <TableCell onClick={this.toggleModal}>
          <div className="tester-image-name btn-tester">
            {
              !!tester
              && (
                <>
                  <img
                    src={
                      (tester.profileImagePath)
                      ? (BASE_URL + tester.profileImagePath)
                      : "/images/user.svg"
                    }
                    alt={`${tester.testerFirstName} ${tester.testerLastName}`}
                  />
                  <span className="tester-cell">{`${tester.testerFirstName} ${tester.testerLastName}`}</span>
                </>
              )
            }
          </div>
        </TableCell>
        <TableCell>
          {tester.user && tester.user.groupName}
        </TableCell>
        <TableCell>
          {
            (tester.isOwnedByCompany && tester.user)
              ? (
                <a
                  className="text-nowrap"
                  href="javascript:void(0);"
                  onClick={this.props.resetPassword(tester.user.email)}
                >
                  Reset Password
                </a>
            ) : 'N/A'

          }
        </TableCell>
        <TableCell>
          <Tooltip
            placement="bottom-end"
            TransitionComponent={Zoom}
            disableFocusListener
            disableTouchListener
            title="Remove from favorites"
          >
            <button className="btn-manage" onClick={this.props.deleteFavorite(tester.id)}/>
          </Tooltip>
        </TableCell>
      </TableRow>
    );
  }
}

const connected = connect<IAnyProps, IAnyProps, IAnyProps & { baseUrl: string }>(
  state => ({}),
  {
    loadItems: getFavoriteTestersFiltered
  })(TesterRow);

export default withRouter(connected);