import {Observable} from 'rxjs';
import {flatMap, switchMap} from 'rxjs/operators';
import {ActionsObservable} from 'redux-observable';
import * as actionType from '../constants/action-type';
import {fromPromise} from "rxjs/internal-compatibility";
import {IActionPayload} from "./index";
import {startEndCatch} from "./pipes/errors";
import {formValueSelector} from "redux-form";
import {MESSAGE_SEARCH_FORM_NAME} from "../containers/client-tester/messages/MessageSearch";
import {createLoaderAction} from "../helpers/action-helper";
import selector from "../selectors/message";
import {messageSearchRequest} from "../api/requests/message";

export const messagesSearchApplyEpic = (action$: ActionsObservable<IActionPayload<any>>, state$): Observable<any> => {
  return action$
    .ofType(actionType.MESSAGE_SEARCH_APPLY)
    .pipe(
      switchMap((action: IActionPayload<any>) => {
        const selectorForm = formValueSelector(MESSAGE_SEARCH_FORM_NAME);

        const pageNumber = selector.getPageNumber(state$.value);
        const itemsPerPage = selector.getItemsPerPage(state$.value);

        return fromPromise(messageSearchRequest(
          action.payload,
          pageNumber,
          itemsPerPage,
          selectorForm(state$.value, 'dateFrom'),
          selectorForm(state$.value, 'dateTo')
        ))
          .pipe(
            flatMap(({items, totalItems}) => {
              return [
                createLoaderAction(actionType.MESSAGE).setPaginatioData(items, itemsPerPage, totalItems, pageNumber),
              ];
            }),
            ...startEndCatch('messagesSearchApplyEpic'),
          );
      }),
    );
};
