import React from "react";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import classnames from "classnames";
import {
  ReducedWeekType,
  TimeExpensesWeekType
} from "../../../types/timesheets-and-expenses";
import selector from "../../../selectors/time-expenses";
import { bindActionCreators, Dispatch } from "redux";
import {
  addSelectedProjectWeeksAction,
  clearSelectedProjectWeeksAction
} from "../../../actions/time-expenses";
import { connect } from "react-redux";
import { TE_STATUS } from "../../../constants/timesheet-expenses";
import { getHasApprovedWeeks } from "../../../helpers/timesheets-expenses";
type SelectAllApproved = {
  weeksItems: ReducedWeekType[];
  selectedProjectWeeks: number[];
  addItems: (ids: number[]) => void;
  clearItems: () => void;
};

const SelectAllApproved = ({
  weeksItems,
  selectedProjectWeeks,
  addItems,
  clearItems
}: SelectAllApproved) => {
  const disabled = false;
  const cn = classnames("timesheet-checkbox", {
    disabled: disabled
  });

  const hasApprovedWeeks = getHasApprovedWeeks(weeksItems);
  if (!hasApprovedWeeks) {
    return null;
  }

  let allProjectsWeeks: TimeExpensesWeekType[] = [];
  weeksItems.map(({ projectsWeeks }) => {
    allProjectsWeeks = [...allProjectsWeeks, ...projectsWeeks];
  });
  const allApprovedWeeksIds: number[] = allProjectsWeeks
    .filter(week => week.status === TE_STATUS.STATUS_APPROVED)
    .map(({ id }) => id as number);

  const isChecked =
    !!allApprovedWeeksIds.length &&
    allApprovedWeeksIds.every(id => selectedProjectWeeks.includes(id));

  const toggleCheckbox = () => {
    if (isChecked) {
      clearItems();
    } else {
      const unselectedIds: number[] = allApprovedWeeksIds.filter(
        id => !selectedProjectWeeks.includes(id)
      );
      addItems(unselectedIds);
    }
  };
  const checkboxLabel = isChecked
    ? "Unselect All Approved"
    : "Select All Approved";

  return (
    <div>
      <FormControlLabel
        className={cn}
        control={
          <Checkbox
            defaultChecked={false}
            onChange={toggleCheckbox}
            disabled={!allApprovedWeeksIds.length}
            checked={isChecked}
          />
        }
        label={
          <span className="timesheet-select-all-approved-label">
            {checkboxLabel}
          </span>
        }
      />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    selectedProjectWeeks: selector.getSelectedProjectWeeks(state),
    weeksItems: selector.getTimeExpensesWeeks(state)
  };
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      addItems: addSelectedProjectWeeksAction,
      clearItems: clearSelectedProjectWeeksAction
    },
    dispatch
  );
};

const connected = connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectAllApproved);

export default connected;
