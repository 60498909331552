export const userRegistrationStyle = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  demo: {
    [theme.breakpoints.up("lg")]: {
      width: 1170
    }
  },
});

export const defaultStyleWidth = width => theme => ({
  paper: {
    maxHeight: '80%',
    minWidth: '320px',
    overflow: 'auto',
    position: 'absolute',
    maxWidth: width || theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    top: `100px`,
    left: `50%`,
    transform: `translate(-50%, 0)`,
  },
});

export const certificateModalStyle = width => theme => ({
  paper: {
    maxHeight: '80%',
    minWidth: '320px',
    overflow: 'auto',
    position: 'absolute',
    maxWidth: width || theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    top: `100px`,
    left: `50%`,
    transform: `translate(-50%, 0)`,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  }
});

export const defaultStyle = defaultStyleWidth();
