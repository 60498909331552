import React from 'react';
import {connect} from 'react-redux';
import Paper from "@material-ui/core/es/Paper/Paper";
import {faqRequest} from "../../../actions/faq";
import selector from "../../../selectors/faq";
import debounce from 'debounce';
import {MAX_PAGINATION_RESULTS} from "../../../constants/loader";
import {ACTIVE} from "../../../constants/filter";

class ClientFaq extends React.Component {
  state = {
    activeItemId: null
  };

  toggleCollapse = (id) => {
    this.setState({activeItemId: id});
  };

  handleCollapse = (e) => {
    const id = Number(e.target.id);

    if (this.state.activeItemId === id) {
      return this.toggleCollapse(null);
    }

    this.toggleCollapse(id);
  };

  debouncedLoad = debounce((nextProps) => {
    this.loadItems(nextProps)
  }, 500);

  loadItems = (props) => {
    props.loadItems(
      1,
      MAX_PAGINATION_RESULTS,
      ACTIVE
    );
  };

  componentDidMount() {
    this.loadItems(this.props);
  }

  render() {
    if (!this.props.isLoaded) {
      return (
        <div>
          <div className="crud-sections-header">
            <h4 className="main-content-header">Frequently Asked Questions</h4>
          </div>
          <Paper className="p-4 mt-4">
            Loading...
          </Paper>
        </div>
      );
    }

    if (!this.props.data.length) {
      return (
        <div>
          <div className="crud-sections-header">
            <h4 className="main-content-header">Frequently Asked Questions</h4>
          </div>
          <Paper className="p-4 mt-4">
            There are no items!
          </Paper>
        </div>
      );
    }

    return (
      <div className="client-faq-block">
        <div className="crud-sections-header">
          <h4 className="main-content-header">Frequently Asked Questions</h4>
        </div>
        <div className="styled-block">
        {this.props.data.map(item => (
          <div key={item.id}>
            <img
              src={this.state.activeItemId === item.id ? '/images/faq_minus.svg' : '/images/faq_plus.svg'}
              onClick={this.handleCollapse}
              className="float-right btn p-0"
              id={item.id}
            />
            <h5 className="question-header">{item.question}</h5>
            {this.state.activeItemId === item.id &&
              <div className="answer">
                {item.answer}
              </div>
            }
            <hr/>
          </div>
        ))}
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    data: selector.getItems(state),
    isLoaded: selector.getIsLoaded(state),
  }),

  {
    loadItems: faqRequest.getItems,
  },
)(ClientFaq);
