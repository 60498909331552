import getCrudSelectors from '../helpers/selector-helper';
import {createSelector} from "reselect";

const getLocations = state => state.locations;

export default getCrudSelectors(getLocations);

export const getLocationSearchText = createSelector(
  getLocations,
  state => state.searchText
);
