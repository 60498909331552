import React, { useContext } from "react";
import { TE_STATUS } from "constants/timesheet-expenses";
import {
  getTEStatusNameById,
  getTimesheetBillFileName
} from "helpers/timesheets-expenses";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { getTimeExpensesWeekInvoiceAction } from "actions/time-expenses";
import {
  TimeSheetExpensesContextValueType,
  TimeSheetInvoiceLinkType,
  TimeSheetWeeksInvoiceAction
} from "types/timesheets-and-expenses";
import { TimeSheetExpensesContext } from "../../context/timeSheetsContext";
import PermissionBlock from "components/auth/PermissionBlock";
import { Permission } from "constants/permission";

interface StatusCellProps {
  weekId?: number;
  weekNumber: string;
  statusId: TE_STATUS;
  consultantName: string;
  isEmptyTimeAndExpenses: boolean;
  invoiceNumber: string | null;
  expensesInvoiceNumber: string | null;
  getTimeExpensesWeekInvoice: (params: TimeSheetWeeksInvoiceAction) => void;
}

const StatusCell = ({
  statusId,
  weekId,
  weekNumber,
  isEmptyTimeAndExpenses,
  consultantName,
  invoiceNumber,
  expensesInvoiceNumber,
  getTimeExpensesWeekInvoice
}: StatusCellProps): JSX.Element | null => {
  const { isAdmin, isOwner } = useContext<TimeSheetExpensesContextValueType>(
    TimeSheetExpensesContext
  );
  const statusLabel = isEmptyTimeAndExpenses ? null : getTEStatusNameById(statusId);

  if (!statusLabel) {
    return null;
  }

  const labelClassName = `timesheet-status timesheet-status--${statusLabel.toLowerCase()}`;

  function getDownloadButton(params: { weekId: number, invoiceLinkType: TimeSheetInvoiceLinkType, fileName: string }, name, className = 'timesheet-status-link--invoice') {
    if (isAdmin) {
      return <PermissionBlock permissions={[Permission.CAN_VIEW_PLATFORM_INVOICES_AND_COSTS]}>
        <button
          className={`btn-cancel-default timesheet-status-link ${className}`}
          onClick={() =>
            getTimeExpensesWeekInvoice(params)
          }
        >
          {name}
        </button>
      </PermissionBlock>
    }

    return <button
      className={`btn-cancel-default timesheet-status-link ${className}`}
      onClick={() =>
        getTimeExpensesWeekInvoice(params)
      }
    >
      {name}
    </button>;
  }

  if (statusId === TE_STATUS.STATUS_INVOICED && (isAdmin || isOwner)) {
    const billFileName = getTimesheetBillFileName(consultantName, weekNumber);

    return (
      <>
        <span className={labelClassName}>{statusLabel}</span>

        {invoiceNumber && getDownloadButton({
          weekId: weekId as number,
          invoiceLinkType: TimeSheetInvoiceLinkType.INVOICE,
          fileName: invoiceNumber
        }, invoiceNumber)}

        {expensesInvoiceNumber && getDownloadButton({
          weekId: weekId as number,
          invoiceLinkType: TimeSheetInvoiceLinkType.EXPENSES_INVOICE,
          fileName: expensesInvoiceNumber
        }, expensesInvoiceNumber)}

        {isAdmin && (invoiceNumber || expensesInvoiceNumber) && getDownloadButton({
          weekId: weekId as number,
          invoiceLinkType: TimeSheetInvoiceLinkType.BILL,
          fileName: billFileName
        }, '& Billed', 'timesheet-status--invoiced')}
      </>
    );
  }
  return <span className={labelClassName}>{statusLabel}</span>;
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getTimeExpensesWeekInvoice: getTimeExpensesWeekInvoiceAction
    },
    dispatch
  );
};

const connected = connect(null, mapDispatchToProps)(StatusCell);

export default connected;
