import * as React from 'react';
import { composeValidators, fieldsNotEmpty } from "../../../../helpers/validations/validation";
import {InjectedFormProps, reduxForm} from "redux-form";
import { connect } from "react-redux";
import {applicationSettingsRequest, putApplicationSetting} from "../../../../actions/application-settings";
import parameterFetcher from '../../../../components/parametersFetcher';
import selector from '../../../../selectors/application-settings';
import ApplicationSettingForm from "./ApplicationSettingForm";
import routerModal from '../../../../components/routerModal';
import {WithStyles} from "@material-ui/core/styles/withStyles";

const FORM_NAME = 'CarouselUpdate';

class ApplicationSettingUpdate extends React.Component<InjectedFormProps<{}, {}> & {
  putData: (...any) => void,
  backRoute: string
} & WithStyles<any>, any> {
  render() {
    const { handleSubmit, putData, backRoute } = this.props;

    return (
      <form noValidate autoComplete="off"
            onSubmit={handleSubmit((values) => {
              putData(values, FORM_NAME, backRoute);
            })}>
        <ApplicationSettingForm backRoute={backRoute}/>
      </form>
    )
  }
}

const formConnected = reduxForm({
  form: FORM_NAME,
  validate: composeValidators(
    fieldsNotEmpty(['message', 'state',]),
  )
})(ApplicationSettingUpdate);

const connected = parameterFetcher(
  connect(undefined, {
    putData: putApplicationSetting,
  })(formConnected),
  FORM_NAME,
  applicationSettingsRequest.getItem,
  selector.getItemById
);

export default routerModal(connected);