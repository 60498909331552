export const styles = {
  card: {
    display: 'flex',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  content: {
    flex: '1 0 auto',
  },
  image: {
    width: '30%',
    height: 'auth',
    marginLeft: 'auto',
  },
  table: {
    width: '100%',
  },
  th: {
    padding: '10px 0px',
  },
  rightAlign: {
    marginLeft: 'auto',
  },
  header: {
    display: 'flex',
  },
};
