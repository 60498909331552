import { getItem as getUserById } from "./users";
import {
  convertorAssignedProjectsFromSwagger,
  convertorCreateConsultantToSwagger,
  convertorFromSwagger,
  convertorReDeActivateConsultantToSwagger,
  convertorToSwagger
} from "api/convertors/consultants";
import { extractJSONLD, fetchRequestBody } from "helpers/request-helper";
import { getApiJSONLD } from "api/swagger/api-factory";
import {
  consultantProfileType,
  ConsultantProjects,
  FilterParamsType,
  FormValuesType,
  ResponseDataType
} from "types/consultant";
import { extractId } from "helpers/converter-helper";
import { getStatistic } from "helpers/projects/getStatistic";
import { TE_STATUS } from "constants/timesheet-expenses";

export const getItems = async (params: FilterParamsType) => {
  return getApiJSONLD()
    .then(api => api.getUserCollection(convertorToSwagger(params)))
    .then(extractJSONLD(convertorFromSwagger));
};

export const getAllAssignedProjects = async () => {
  return getApiJSONLD()
    .then(api => api.getProjectCollection({ consultantsProjects: 1 }))
    .then(extractJSONLD(convertorAssignedProjectsFromSwagger));
};

export const createItem = async (params: FormValuesType) => {
  const convertedParams = convertorCreateConsultantToSwagger(params);
  return getApiJSONLD().then(api =>
    api.createProjectConsultant({ consultant: convertedParams })
  );
};
export const updateItem = async ({ id, consultant }) => {
  const convertedParams = convertorCreateConsultantToSwagger(consultant);
  return getApiJSONLD().then(api =>
    api.updateProjectConsultant({ id, consultant: convertedParams })
  );
};
export const deleteItem = async (params: { id: number }) => {
  return getApiJSONLD().then(api => api.deleteUserItem(params));
};
export const archiveItem = async (params: {
  id: number;
  user: ResponseDataType;
}) => {
  return getApiJSONLD()
    .then(api =>
      api.archiveUserItem(convertorReDeActivateConsultantToSwagger(params))
    )
    .then(fetchRequestBody);
};
export const unarchiveItem = async (params: {
  id: number;
  user: ResponseDataType;
}) => {
  return getApiJSONLD()
    .then(api =>
      api.unarchiveUserItem(convertorReDeActivateConsultantToSwagger(params))
    )
    .then(fetchRequestBody);
};
export const getConsultantProfile = async (id: number) => {
  return getApiJSONLD()
    .then(api => api.getConsultantProfileById({ id }))
    .then(fetchRequestBody);
};

export const getConsultantData = async (consultantId: number | string) => {
  try {
    const consultant = await getUserById(consultantId);
    const { firstName, lastName, email, phone, consultantProfile } = consultant;
    const consultantProfileId = extractId(consultantProfile);
    const consultantProfileData: consultantProfileType = await getConsultantProfile(
      consultantProfileId
    );

    const {
      rate,
      consultantProjects,
      companyName,
      statistic = []
    } = consultantProfileData;

    const projects = consultantProjects.map(
      ({ project_rate, sow_days, project = {} }: ConsultantProjects) => ({
        project: Number(project?.id),
        projectRate: project_rate,
        daysInSow: sow_days,
        submitted: getStatistic(
          Number(project?.id),
          TE_STATUS.STATUS_SUBMITTED,
          statistic
        ),
        approved: getStatistic(
          Number(project?.id),
          TE_STATUS.STATUS_APPROVED,
          statistic
        ),
        invoiced: getStatistic(
          Number(project?.id),
          TE_STATUS.STATUS_INVOICED,
          statistic
        )
      })
    );

    return {
      id: consultantProfileId,
      firstName,
      lastName,
      email,
      phone,
      companyName,
      consultantRate: +rate,
      projects
    };
  } catch (error) {
    throw new Error(error?.message || "Can`t get consultant`s data");
  }
};
