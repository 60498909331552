import React, { useEffect } from "react";
import {
  ConsultantProfileType,
  TimeExpensesWeekType
} from "types/timesheets-and-expenses";
import { useWeeks } from "components/timesheets-and-expenses/hooks/useWeeks";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { TIME_SHEETS_SEARCH_FORM } from "constants/timesheet-expenses";
import { ConnectedProps } from "types";
import { RootState } from "reducers";
import moment from "moment";
import { WeekRow } from "components/timesheets-and-expenses/components/Consultant/WeekRow";
import selector from "selectors/time-expenses";
import { getTimeExpensesWeeksAction } from "actions/time-expenses";

type ProjectTimesheetProps = {
  consultant: ConsultantProfileType;
} & PropsFromRedux;

const searchFormSelector = formValueSelector(TIME_SHEETS_SEARCH_FORM);

function ProjectTimesheet({
  consultant,
  yearMonth,
  loadItems,
  reportedDays: reportedWeeks
}: ProjectTimesheetProps) {
  const weeks = useWeeks(yearMonth);

  useEffect(() => {
    const date = moment(yearMonth);

    loadItems({
      yearMonth: date.format("MMMM YYYY")
    });
  }, [yearMonth.toString()]);

  return (
    <>
      {weeks.map(({ days, key, yearWeek }) => (
        <WeekRow
          key={key}
          days={days}
          consultant={consultant}
          yearWeekNumber={yearWeek}
          reportedWeeks={reportedWeeks
            .filter(item =>
              item.yearWeek.includes(yearWeek.replaceAll("-", ""))
            )
            .reduce<TimeExpensesWeekType[]>(
              (acc, curr) => [...acc, ...curr.projectsWeeks],
              []
            )}
        />
      ))}
    </>
  );
}

function mapStateToProps(state: RootState) {
  const yearMonth = searchFormSelector(state, "yearMonth") || new Date();

  return {
    yearMonth:
      yearMonth instanceof Date
        ? yearMonth
        : moment(yearMonth, "MMMM YYYY").toDate(),
    reportedDays: selector.getTimeExpensesWeeks(state)
  };
}

const connector = connect(mapStateToProps, {
  loadItems: getTimeExpensesWeeksAction
});

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ProjectTimesheet);
