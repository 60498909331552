import * as React from "react";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {History} from "history";
import {Modal} from "@material-ui/core";
import {getAcceptEditProposalModeOpenModal} from "../../../../selectors/modal";
import {acceptEditProposalByTesterModalClose} from "../../../../actions/modals";

interface IAcceptEditProposalByTesterProps {
  history: History;
  testId: number;
  isOpenModal: boolean;
  closeModal: () => void;
  match: {
    url: string;
    params: {
      test: number;
    };
  };
}

const AcceptEditProposalModal: React.FunctionComponent<IAcceptEditProposalByTesterProps> = (
    {closeModal, isOpenModal, history, match}
) => {
    const goToAccept = () => {
        history.push(`/client/booking-requests/${match.params.test}/booking-confirmation`)
        closeModal()
    }

  return (
      <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={isOpenModal}
          onClose={closeModal}
          className="d-flex overflow-auto"
      >
        <div className="styled-modal sm">
            <h4>Please note</h4>
            <p>
                The original proposal has been edited, please review before the approval
            </p>
            <hr/>
          <div className="text-right">
              <button className="btn-cancel" onClick={closeModal}>
                Back to test details
              </button>
              <button className="btn-accept" type="submit" onClick={goToAccept}>
                ACKNOWLEDGE
              </button>
          </div>
        </div>
      </Modal>
  );
}

function mapStateToProps(state) {
  return {
    isOpenModal: getAcceptEditProposalModeOpenModal(state),
  };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
      {
        closeModal: acceptEditProposalByTesterModalClose,
      },
      dispatch
  );
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(AcceptEditProposalModal));
