import * as React from "react";
import Paper from "@material-ui/core/Paper/Paper";
import Table from "@material-ui/core/Table/Table";
import TableBody from "@material-ui/core/TableBody/TableBody";
import {connect} from "react-redux";
import selector from "../../../selectors/tester-bookings";
import {TablePagination, Button} from "@material-ui/core";
import {isChangesExist} from "../../../helpers/props-checker";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import debounce from "debounce";
import {ITest} from "../../../models/test.interface";
import {reportCanBeViewedByTester} from "../../../constants/test-status";
import {formValueSelector} from "redux-form";
import {ALL_STATUSES_BOOKINGS, BOOKINGS_FORM_NAME} from "./BookingsSearchForm";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {Link} from "react-router-dom";
import * as _ from "lodash";
import {extractL1SpecialismNames} from "../../../helpers/specialims-extractor";
import EnhancedTableHead from "../../../components/table/EnhancedTableHead";
import {formatDateWithTime} from "../../../helpers/date-formatter";
import StatusBlock from "../../common/StatusBlock";
import {Group} from "../../../constants/group";
import {testerBookingsRequest, testerBookingsLoader, getBookingRequestsAction} from "../../../actions/tester-bookings";
import {IState} from "../../../interfaces/state.interface";

const searchFormSelector = formValueSelector(BOOKINGS_FORM_NAME);

interface IOwnProps {
  baseUrl: string;
  data: ITest[];

  [key: string]: any;

  searchValues: {
    status: string[],
    dateFrom: string,
    dateTo: string,
  };
}

type IProps = IOwnProps & RouteComponentProps<any>;

class BookingsList extends React.Component<IProps, {}> {
  debouncedLoad = debounce((nextProps) => {
    this.loadItems(nextProps);
  }, 500);

  loadItems = (props) => {
    props.loadItems(
      props.pageNumber,
      props.itemsPerPage,
      props.searchValues.project,
      !!props.searchValues.status && props.searchValues.status.length ?
        props.searchValues.status :
        ALL_STATUSES_BOOKINGS,
      props.searchValues.dateFrom,
      props.searchValues.dateTo
    );
  };

  onArchive = (item) => {
    this.props.archiveItem(
      item.id,
      this.props.pageNumber,
      this.props.itemsPerPage,
      this.props.showStatus,
      this.props.filterText
    );
  };

  isConsultantInputManager = !!this.props.currentUser?.isConsultancyCompanyManager

  componentDidMount() {
    this.loadItems(this.props);
  }

  componentWillReceiveProps(nextProps: IProps) {
    if (_.isEmpty(this.props.searchValues)) {
      return;
    }

    if (
      isChangesExist(["pageNumber", "itemsPerPage", "showStatus", "filterText"], nextProps, this.props) ||
      isChangesExist(["status", "dateFrom", "dateTo"], nextProps.searchValues, this.props.searchValues)
    ) {
      this.loadItems(nextProps);
    }

    if (!nextProps.data.length && nextProps.pageNumber > 1) {
      this.props.setPageNumber(1);
    }
  }

  handleChangePage = (event, page) => {
    this.props.setPageNumber(page + 1);
  };

  handleChangeRowsPerPage = event => {
    this.props.setItemsPerPage(event.target.value);
  };

  getBookingAction = (item: ITest) => {
    if (this.isConsultantInputManager) {
      return (
          <Link to={`/tester/bookings/${item.id}/details`}>
            <button className="btn-view" type="submit">
              Details
            </button>
          </Link>
      );
    }

    if (reportCanBeViewedByTester(Number(item.status))) {
      return (
        <Link to={`/tester/bookings/${item.id}/details`}>
          <Button type="submit" variant="contained" color="primary">
            View&nbsp;Details
          </Button>
        </Link>
      );
    }

    return (
      <Link to={`/tester/booking-requests/${item.id}`}>
        <Button type="submit" variant="contained" color="primary">
          View&nbsp;Details
        </Button>
      </Link>
    );
  };

  columnsNames = () => {
    if(this.isConsultantInputManager) {
      return ["Testing start date", "Tester", "Consultant\nInput", "Company", "Type", "Number of testing days", "Status", ""];
    }

    if(this.props.authGroup === Group.MANAGER) {
      return ["Testing start date", "Tester", "Company", "Type", "Number of testing days", "Status", ""];
    }

    return ["Testing start date", "Company", "Type", "Number of testing days", "Status", ""];
  }

  render() {

    if (!this.props.isLoaded) {
      return (
        <div>
          Loading...
        </div>
      );
    }

    if (!this.props.data.length) {
      return (
        <div>
          There are no items!
        </div>
      );
    }

    return (
      <Paper style={{overflowX: "auto", width: "100%"}}>
        <Table padding="dense">
          <EnhancedTableHead
            columns={this.columnsNames()}/>
          <TableBody>
            {
              this.props.data.map(item => {
                return (
                  <TableRow key={item.id}>
                    <TableCell>
                      <div>
                        {item.dateFrom && formatDateWithTime(item.dateFrom)}
                      </div>
                      <div>
                        {item.hash + " - " + item.name}
                      </div>
                    </TableCell>
                    {this.props.authGroup===Group.MANAGER && item.tester && (
                      <TableCell>
                        {item.tester.user ? (
                          <Link to={"/tester/admin/" + (item as any).tester.user.testerProfileId}>
                            {item.tester.testerFirstName + " " + item.tester.testerLastName}
                          </Link>
                        ):(
                          <div>{item.tester.testerFirstName + " " + item.tester.testerLastName}</div>
                        )}
                      </TableCell>
                    )}
                    {this.isConsultantInputManager && (
                      <TableCell>
                        <div>{item?.consultantInput && item?.consultantInput?.user.firstName + " " + item?.consultantInput?.user.lastName}</div>
                        <div>{item?.consultantInput && item?.consultantInput?.user.email}</div>
                      </TableCell>
                    )}
                    <TableCell>
                      <div>
                        {item?.plannerCompanyName}
                      </div>
                    </TableCell>
                    <TableCell>
                      {
                        !!item.specialisms && extractL1SpecialismNames(item.specialisms).join(", ") + ";"
                      }
                    </TableCell>
                    <TableCell>
                      <div>
                        {!!item.proposal ? `${item.proposal.countDays}` : ""}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div>
                        {item.status && <StatusBlock status={item.status}/>}
                      </div>
                    </TableCell>
                    <TableCell>
                      {this.getBookingAction(item)}
                    </TableCell>
                  </TableRow>
                );
              })
            }
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={this.props.totalItems}
          rowsPerPage={this.props.itemsPerPage}
          page={this.props.pageNumber - 1}
          backIconButtonProps={{
            "aria-label": "Previous Page"
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page"
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Paper>
    );
  }
}

const routered = withRouter(BookingsList);

export default connect((state: IState) => {
  return {
    currentUser: state.auth,
    data: selector.getItems(state),
    isLoaded: selector.getIsLoaded(state),
    itemsPerPage: selector.getItemsPerPage(state),
    pageNumber: selector.getPageNumber(state),
    showStatus: selector.getFilterActivityStatus(state),
    totalItems: selector.getTotalItems(state),
    filterText: selector.getFilterTextFields(state),
    searchValues: searchFormSelector(state, "status", "dateFrom", "dateTo"),
    authGroup: state.auth.group
  };
}, {
  loadItems: getBookingRequestsAction,
  setItemsPerPage: testerBookingsLoader.setItemsPerPage,
  setPageNumber: testerBookingsLoader.setPageNumber,
  archiveItem: testerBookingsRequest.archiveItem
})(routered);
