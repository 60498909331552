import * as React from "react";
import Paper from "@material-ui/core/Paper/Paper";
import Table from "@material-ui/core/Table/Table";
import TableBody from "@material-ui/core/TableBody/TableBody";
import { connect } from "react-redux";
import { carouselRequest, carouselLoader } from "../../../../actions/carousel";
import selector from "../../../../selectors/carousel";
import { TablePagination } from "@material-ui/core";
import EnhancedTableHead from "../../../../components/table/EnhancedTableHead";
import EnhancedTableRow from "../../../../components/table/EnhancedTableRow";
import { isChangesExist } from "../../../../helpers/props-checker";
import { IAnyProps } from "../../../../interfaces/any-props.interface";
import { BASE_URL } from "../../../../constants/urls";

class CarouselList extends React.Component<
  {
    baseUrl: string;
    [key: string]: any;
  },
  {}
> {
  loadItems = props => {
    props.loadItems(props.pageNumber, props.itemsPerPage, props.showStatus);
  };

  onUnarchive = item => {
    this.props.unarchiveItem(
      item.id,
      this.props.pageNumber,
      this.props.itemsPerPage,
      this.props.showStatus
    );
  };

  onArchive = item => {
    this.props.archiveItem(
      item.id,
      this.props.pageNumber,
      this.props.itemsPerPage,
      this.props.showStatus
    );
  };

  componentDidMount() {
    this.loadItems(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (
      isChangesExist(
        ["pageNumber", "itemsPerPage", "showStatus", "filterText"],
        nextProps,
        this.props
      )
    ) {
      this.loadItems(nextProps);
    }

    if (!nextProps.data.length && nextProps.pageNumber > 1) {
      this.props.setPageNumber(1);
    }
  }

  handleChangePage = (event, page) => {
    this.props.setPageNumber(page + 1);
  };

  handleChangeRowsPerPage = event => {
    this.props.setItemsPerPage(event.target.value);
  };

  render() {
    if (!this.props.isLoaded) {
      return <div>Loading</div>;
    }

    if (!this.props.data.length) {
      return <div>There are no items!</div>;
    }

    this.props.data.sort((a, b) => {
      return a.position - b.position || a.position === b.position;
    });

    return (
      <Paper style={{ overflowX: "auto", width: "100%" }} className="mb-4">
        <Table>
          <EnhancedTableHead
            columns={["Image", "Text", "Position"]}
            status={this.props.showStatus}
          />
          <TableBody>
            {this.props.data.map(item => {
              return (
                <EnhancedTableRow
                  key={item.id}
                  item={item}
                  baseUrl={this.props.baseUrl}
                  showStatus={this.props.showStatus}
                  onArchive={this.onArchive}
                  onUnarchive={this.onUnarchive}
                  needActions
                >
                  <img
                    className="table-image"
                    src={BASE_URL + item.imagePath}
                    alt={item.text}
                  />
                  {item.text}
                  {item.position}
                </EnhancedTableRow>
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={this.props.totalItems}
          rowsPerPage={this.props.itemsPerPage}
          page={this.props.pageNumber - 1}
          backIconButtonProps={{
            "aria-label": "Previous Page"
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page"
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Paper>
    );
  }
}

export default connect<IAnyProps, IAnyProps, IAnyProps>(
  state => {
    return {
      data: selector.getItems(state),
      isLoaded: selector.getIsLoaded(state),
      itemsPerPage: selector.getItemsPerPage(state),
      pageNumber: selector.getPageNumber(state),
      showStatus: selector.getFilterActivityStatus(state),
      totalItems: selector.getTotalItems(state)
    };
  },
  {
    loadItems: carouselRequest.getItems,
    setItemsPerPage: carouselLoader.setItemsPerPage,
    setPageNumber: carouselLoader.setPageNumber,
    archiveItem: carouselRequest.archiveItem,
    unarchiveItem: carouselRequest.unarchiveItem
  }
)(CarouselList);
