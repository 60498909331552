import * as React from "react";
import Paper from "@material-ui/core/Paper/Paper";
import Table from "@material-ui/core/Table/Table";
import TableBody from "@material-ui/core/TableBody/TableBody";
import { connect } from "react-redux";
import { IconButton, TableCell, TableRow } from "@material-ui/core";
import EnhancedTableHead from "../../../../components/table/EnhancedTableHead";
import { dataFetcher } from "../../../../components/dataFetcher";
import applicationSettingsSelector from "../../../../selectors/application-settings";
import {
  getApplicationSettings,
  updateApplicationSetting
} from "../../../../actions/application-settings";
import { ACTIVE } from "../../../../constants/filter";
import Switch from "../../../../../node_modules/@material-ui/core/Switch/Switch";
import { Link } from "react-router-dom";
import Icon from "../../../../../node_modules/@material-ui/core/Icon/Icon";
import { IAnyProps } from "../../../../interfaces/any-props.interface";

interface IApplicationSetting {
  id: number;
  settingKey: string;
  state: boolean;
  message?: string;
}

interface IProps {
  baseUrl: string;
  [key: string]: any;
  updateApplicationSetting: (settingId: number, data: any) => void;
}

class ApplicationSettingsList extends React.Component<IProps, {}> {
  handleChange = (settingId: number, data: any) => event => {
    this.props.updateApplicationSetting(settingId, data);
  };

  render() {
    // if (!this.props.isLoaded) {
    //   return (
    //     <div>
    //       Loading
    //     </div>
    //   );
    // }

    return (
      <Paper style={{ overflowX: "auto", width: "100%" }} className="mb-4">
        <Table>
          <EnhancedTableHead
            columns={["Key/Name", "Message", "Edit", "State"]}
          />
          <TableBody>
            {this.props.applicationSettings.map((item: IApplicationSetting) => {
              return (
                <TableRow key={item.id}>
                  <TableCell>{item.settingKey}</TableCell>
                  <TableCell>{item.message}</TableCell>
                  <TableCell>
                    <Link to={this.props.baseUrl + "/" + item.id + "/edit"}>
                      <IconButton>
                        <Icon>edit</Icon>
                      </IconButton>
                    </Link>
                  </TableCell>
                  <TableCell>
                    {item.state}
                    <Switch
                      checked={item.state}
                      color="primary"
                      onChange={this.handleChange(item.id, {
                        state: !item.state
                      })}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

const fetched = dataFetcher(ApplicationSettingsList, [
  {
    key: "applicationSettings",
    selector: (state, props) =>
      applicationSettingsSelector.getItemsObject(state),
    action: () => getApplicationSettings(),
    alwaysReceiveFreshData: true
  }
]);

const connected = connect<IAnyProps, IAnyProps, IAnyProps>(null, {
  updateApplicationSetting
})(fetched);

export default connected;
