import * as React from "react";
import Paper from "@material-ui/core/Paper/Paper";
import { connect } from "react-redux";
import {
  usersRequest,
  usersLoader,
  getUsersFiltered,
  archiveItem,
  unarchiveItem
} from "actions";
import selector from "selectors/users";
import { TablePagination } from "@material-ui/core";
import { isChangesExist } from "helpers/props-checker";
import debounce from "debounce";
import { postEmail } from "actions/common";
import { FORM_NAME } from "./AdminUsersSearchForm";
import { formValueSelector } from "redux-form";
import { RootState } from "reducers";
import { ConnectedProps } from "types";
import { IUsers } from "models/users.interface";
import { ChangeEvent } from "react";
import Table from "components/table/Table";
import { getConfig } from "./table/config";

const selectorSearchForm = formValueSelector(FORM_NAME);

type Props = {
  baseUrl: string;
} & PropsFromRedux;

class AdminUsersList extends React.Component<Props> {
  debouncedLoad = debounce((nextProps: Props) => {
    this.loadItems(nextProps);
  }, 500);

  handleSendLink = (user: IUsers) => {
    // @ts-ignore
    this.props.postEmail(user.email);
  };

  loadItems = (props: Props) => {
    // @ts-ignore
    props.loadItems(
      props.pageNumber,
      props.itemsPerPage,
      props.showStatus,
      props.text,
      "",
      "",
      "Admin",
      "",
      "",
      undefined,
      "",
      "",
      props.groupName
    );
  };

  onUnArchive = (user: IUsers) => {
    // @ts-ignore
    this.props.unarchiveItem(
      user.id,
      this.props.pageNumber,
      this.props.itemsPerPage,
      this.props.showStatus
    );
  };

  onArchive = (user: IUsers) => {
    // @ts-ignore
    this.props.archiveItem(
      user.id,
      this.props.pageNumber,
      this.props.itemsPerPage,
      this.props.showStatus
    );
  };

  componentDidMount() {
    this.loadItems(this.props);
  }

  componentWillReceiveProps(nextProps: Props) {
    if (
      isChangesExist(
        ["pageNumber", "itemsPerPage", "showStatus", "text", "groupName"],
        nextProps,
        this.props
      )
    ) {
      if (isChangesExist(["text"], nextProps, this.props)) {
        this.debouncedLoad.clear();
        this.debouncedLoad(nextProps);
      } else {
        this.loadItems(nextProps);
      }
    }

    if (!nextProps.data.length && nextProps.pageNumber > 1) {
      this.props.setPageNumber(1);
    }
  }

  handleChangePage = (_, page: number) => {
    this.props.setPageNumber(page + 1);
  };

  handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    this.props.setItemsPerPage(event.target.value);
  };

  getColumns() {
    return getConfig({
      status: this.props.showStatus,
      baseUrl: this.props.baseUrl,
      onReset: this.handleSendLink,
      onArchive: this.onArchive,
      onUnArchive: this.onUnArchive
    });
  }

  render() {
    const { isLoaded, data = [] } = this.props;

    if (!isLoaded) {
      return <div>Loading...</div>;
    }

    if (!data.length) {
      return <div>There are no items!</div>;
    }

    return (
      <Paper style={{ overflowX: "auto", width: "100%" }} className="mb-4">
        <Table<IUsers> columns={this.getColumns()} data={data} />
        <TablePagination
          component="div"
          count={this.props.totalItems}
          rowsPerPage={this.props.itemsPerPage || 0}
          page={this.props.pageNumber - 1}
          backIconButtonProps={{ "aria-label": "Previous Page" }}
          nextIconButtonProps={{ "aria-label": "Next Page" }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Paper>
    );
  }
}

const connector = connect(
  (state: RootState) => ({
    data: selector.getItems(state),
    isLoaded: selector.getIsLoaded(state),
    itemsPerPage: selector.getItemsPerPage(state),
    pageNumber: selector.getPageNumber(state),
    showStatus: selector.getFilterActivityStatus(state),
    totalItems: selector.getTotalItems(state),
    text: selectorSearchForm(state, "text") || "",
    groupName: selectorSearchForm(state, "groupName") || []
  }),
  {
    postEmail,
    loadItem: usersRequest.getItem,
    loadItems: getUsersFiltered,
    setItemsPerPage: usersLoader.setItemsPerPage,
    setPageNumber: usersLoader.setPageNumber,
    archiveItem: archiveItem("Admin"),
    unarchiveItem: unarchiveItem("Admin")
  }
);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AdminUsersList);
