import * as FilterActions from "../../constants/filter";
import {extractJSONLD, fetchRequestBody} from "../../helpers/request-helper";
import {getApiJSONLD} from "../swagger/api-factory";
import {IAnyProps} from "../../interfaces/any-props.interface";
import {convertorFromSwagger} from '../convertors/default-convertor';

export const getItems = (page, itemsPerPage, show, name, groups = []) => {
  const isShow = show || FilterActions.DEFAULT_FILTER;
  const parameters: IAnyProps = {page};

  parameters.items = itemsPerPage;
  parameters.show = isShow;
  parameters.$queryParameters = {};
  if (groups) {
    parameters.$queryParameters['groups[]'] = groups;
  }

  if (name) {
    parameters.searchName = name;
  }

  return getApiJSONLD()
    .then(api => api.get_favoritesTesterProfileCollection(parameters))
    .then(extractJSONLD(convertorFromSwagger));
};

export const putItem = (testerId) => {
  return getApiJSONLD()
  .then(api => api.put_favoriteTesterProfileItem({id: testerId}))
  .then(fetchRequestBody)
  .then(convertorFromSwagger);
};

export const deleteFavorite = (testerId) => {
  return getApiJSONLD()
  .then(api => api.delete_favoriteTesterProfileItem({ id: testerId }));
};