import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { result } from "lodash";
import Table from "@material-ui/core/Table/Table";
import TableBody from "@material-ui/core/TableBody/TableBody";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import EnhancedTableHead from "../../../../components/table/EnhancedTableHead";
import { IAnyProps } from "../../../../interfaces/any-props.interface";
import { IReportDocument } from "../../../../models/report-documents.interface";
import { dataFetcher } from "../../../../components/dataFetcher";
import selector from "../../../../selectors/report-documents";
import { reportDocumentsRequest } from "../../../../actions/report-documents";
import {
  changeTestStatusAction,
  IChangeTestStatusAction,
  testRequest
} from "../../../../actions/test";
import selectorTest from "../../../../selectors/test";
import { MAX_PAGINATION_RESULTS } from "../../../../constants/loader";
import { ITest } from "../../../../models/test.interface";
import { ISpecialism } from "../../../../models/specialism.interface";
import { extractId } from "../../../../helpers/converter-helper";
import { ReportDocumentStatus } from "../../../../constants/report-document-status";
import {
  testDetailsCanBeViewed,
  TestStatus
} from "../../../../constants/test-status";
import { reportAcceptedModalOpen } from "../../../../actions/modals";
import { getInvoiceUrl, IGetInvoiceUrl } from "../../../../actions/invoice";
import CompletedReportTableRow from "./CompletedReportTableRow";
import ReportAcceptedModal from "./ReportAcceptedModal";
import AmendmentsRequestModal from "./AmendmentsRequestModal";
import AcceptedInvoiceModal from "./AcceptedInvoiceModal";
import PermissionBlock from "../../../../components/auth/PermissionBlock";
import {
  ROLE_TESTER,
  ROLE_VIEWER,
  ROLE_PLANNER
} from "../../../../constants/roles";

interface IState {
  openInvoiceModal: boolean;
}

type IProps = RouteComponentProps<any> &
  IAnyProps & {
    reportDocuments: IReportDocument[];
    test: ITest;
    setTestStatus: IChangeTestStatusAction;
    reportAcceptedModal: (...any) => any;
    getInvoiceUrl: IGetInvoiceUrl;
    testId: number;
  };

class CompletedReportsTable extends React.Component<IProps, IState> {
  static defaultProps = {
    test: {}
  };

  baseUrl = this.props.match.path;
  state = {
    openInvoiceModal: false
  };

  isCanReportBeAccepted = () => {
    const { test } = this.props;

    if (test.status === TestStatus.STATUS_COMPLETED) {
      return true;
    }

    let allReportsAccepted = true;

    this.props.reportDocuments.map(item => {
      if (item.status !== ReportDocumentStatus.STATUS_ACCEPTED) {
        allReportsAccepted = false;
      }
    });

    return allReportsAccepted;
  };

  getButtonAction = () => {
    const { test } = this.props;

    if (test.status === TestStatus.STATUS_REPORT_SUBMITTED) {
      return (
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="small"
          onClick={this.handleOpenAcceptedInvoiceModal}
        >
          Accept Report
        </Button>
      );
    }

    if (
      !test.feedbackSend &&
      (test.status === TestStatus.STATUS_COMPLETED ||
        test.status === TestStatus.STATUS_REPORT_ACCEPTED)
    ) {
      return (
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="small"
          onClick={this.submitFeedback}
        >
          Submit&nbsp;Feedback
        </Button>
      );
    }

    return null;
  };

  handleOpenAcceptedInvoiceModal = () => {
    this.setState({ openInvoiceModal: true });
  };

  handleCloseAcceptedInvoiceModal = () => {
    this.setState({ openInvoiceModal: false });
  };

  handleAcceptReportAction = async () => {
    this.handleCloseAcceptedInvoiceModal();
    const data = await this.props.setTestStatus(
      this.props.match.params.test,
      TestStatus.STATUS_REPORT_ACCEPTED,
      this.props.reportAcceptedModal
    );
    if (
      data &&
      this.props.test.tester &&
      !this.props.test.tester.isOwnedByCompany
    ) {
      await this.props.getInvoiceUrl(this.props.match.params.test);
    }
  };

  submitFeedback = () => {
    this.props.reportAcceptedModal();
  };

  render() {
    const { test } = this.props;

    if (testDetailsCanBeViewed(Number(test.status))) {
      return (
        <Grid item md={12} className="mt-2">
          <div className="table-no-items">Report not submitted yet!</div>
        </Grid>
      );
    }

    if (!this.props.reportDocuments.length) {
      return <div className="table-no-items">There are no items!</div>;
    }

    if (!this.props.reportDocuments) {
      return <div className="table-no-items">Loading...</div>;
    }

    return (
      <React.Fragment>
        <div className="table-responsive styled-table">
          <AcceptedInvoiceModal
            open={this.state.openInvoiceModal}
            onClose={this.handleCloseAcceptedInvoiceModal}
            onAccept={this.handleAcceptReportAction}
            isTesterOwnedByCompany={
              test.tester ? test.tester.isOwnedByCompany : false
            }
          />
          <ReportAcceptedModal reportId={test.report} testId={test.id} />
          <AmendmentsRequestModal />
          <Table padding="dense">
            <EnhancedTableHead
              columns={["Report Document Name", "Status", "Date", "", "", ""]}
            />
            <TableBody>
              {this.props.reportDocuments.length &&
                this.props.reportDocuments
                  .filter(
                    report =>
                      report.status !== ReportDocumentStatus.STATUS_UPLOADED
                  )
                  .map((report, index) => {
                    return (
                      <CompletedReportTableRow
                        {...{
                          reportDocument: report,
                          specialism: 1,
                          lastIndex:
                            index === this.props.reportDocuments.length - 1,
                          test
                        }}
                        key={index}
                      />
                    );
                  })}
            </TableBody>
          </Table>
        </div>
        <PermissionBlock roles={[ROLE_TESTER, ROLE_VIEWER, ROLE_PLANNER]}>
          <Grid className="float-right mt-3">
            {this.isCanReportBeAccepted() && this.getButtonAction()}
          </Grid>
        </PermissionBlock>
      </React.Fragment>
    );
  }
}

const fetched: any = dataFetcher(
  CompletedReportsTable,
  [
    {
      key: "test",
      selector: (state, props) =>
        selectorTest.getItemById(result(props, "match.params.test", false))(
          state
        ),
      action: props =>
        testRequest.getItem(result(props, "match.params.test", false)),
      alwaysReceiveFreshData: true
    }
  ],
  [
    {
      key: "reportDocuments",
      action: ({ testId }) => {
        return reportDocumentsRequest.getItems(
          1,
          MAX_PAGINATION_RESULTS,
          testId
        );
      },
      selector: state => {
        return selector.getItems(state);
      },
      alwaysReceiveFreshData: true
    }
  ]
);

const routered = withRouter(fetched);

const connected: any = connect<IAnyProps, IAnyProps, IAnyProps>(
  (state: any) => ({}),
  {
    setTestStatus: changeTestStatusAction,
    reportAcceptedModal: reportAcceptedModalOpen,
    getInvoiceUrl
  }
)(routered);

export default connected;
