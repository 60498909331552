import * as Types from "../constants/action-type";

export const openSnackbar = (message, time = 3000) => {
  return {
    type: Types.OPEN_SNACKBAR,
    payload: {
      message,
      time
    }
  };
};

export const closeSnackbar = () => {
  return {
    type: Types.OPEN_SNACKBAR,
    payload: {}
  };
};
