import {setRequestInProcess} from "./request";
import {Dispatch} from "redux";
import {
  confirmResetPasswordRequest,
  IResetPasswordConfirmationRequest
} from "../api/requests/reset-password-confirmation";
import {result} from "lodash";
import {createAction} from "redux-actions";
import * as Types from "../constants/action-type";

export const confirmResetPassword = (parameters: IResetPasswordConfirmationRequest) => async  (dispatch: Dispatch) => {
  dispatch(setRequestInProcess(true, 'resetPasswordConfirmation'));

  let response: any = {
    success: false
  };

  try {
    response = await confirmResetPasswordRequest(parameters);
  } catch (error) {
    if (error.status === 400) {
      response = result(error, 'response.body');
    }

    dispatch(setRequestInProcess(false, 'resetPasswordConfirmation'));
  }

  dispatch(setRequestInProcess(false, 'resetPasswordConfirmation'));

  const payload = {
    passwordResetPayload: {
      status: response.success,
      message: response.error
    }
  };

  dispatch(createAction(Types.RESET_PASSWORD_CONFIRMATION, () => payload)());
};

export default confirmResetPassword