import * as Types from '../constants/action-type';
import {createCrudAction, createFilterAction, createLoaderAction, createRequestAction} from '../helpers/action-helper';
import {setRequestInProcess} from "./request";
import {getRequestName} from "../helpers/action-request-helper";
import {postImage} from "../api/requests/image";
import {getRequestFromType} from "../api/requests/request-factory";
import {openSnackbar} from "./snackbar";
import {reset} from "redux-form";
import {push} from "react-router-redux";
import {handleHttpErrors} from "../helpers/errors/http-error-handler";
import { API_IMAGES_ENDPOINT_URL } from "../constants/urls";

const crudType = Types.COUNTRY;

export const countriesFilter = createFilterAction(crudType);
export const countriesLoader = createLoaderAction(crudType);
export const countriesRequest = createRequestAction(crudType);

export const postCountryItem = (
    model,
    formName,
    backRoute
) => async dispatch => {
  dispatch(setRequestInProcess(true, getRequestName(crudType, "postItem")));

  let data, createdFile;

  try {
    if (model.image instanceof File) {
      createdFile = await postImage(model.image);

      if (createdFile) {
        model.image = API_IMAGES_ENDPOINT_URL + createdFile.id;
        model.flagImage = API_IMAGES_ENDPOINT_URL + createdFile.id;
        model.flagImagePath = createdFile.imagePath;
      }
    }

    model.name = model.name.trim();

    data = await getRequestFromType(crudType).postItem(model);

    dispatch(openSnackbar("Created", 4000));
    dispatch(createCrudAction(crudType).addItem(data));
    dispatch(reset(formName));
    dispatch(push(backRoute));
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(setRequestInProcess(false, getRequestName(crudType, "postItem")));
};


export const putCountryItem = (
    model,
    formName,
    backRoute
) => async dispatch => {
  dispatch(setRequestInProcess(true, getRequestName(crudType, "putItem")));

  let data, createdFile;

  try {
    if (model.image instanceof File) {
      createdFile = await postImage(model.image);

      if (createdFile) {
        model.image = API_IMAGES_ENDPOINT_URL + createdFile.id;
        model.flagImage = API_IMAGES_ENDPOINT_URL + createdFile.id;
        model.flagImagePath = createdFile.imagePath;
      }
    }

    model.name = model.name.trim();

    data = await getRequestFromType(crudType).putItem(model);

    dispatch(openSnackbar("Updated", 4000));
    dispatch(createCrudAction(crudType).replaceItem(data));
    dispatch(reset(formName));
    dispatch(push(backRoute));
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(setRequestInProcess(false, getRequestName(crudType, "putItem")));
};
