import * as ActionTypes from '../constants/action-type';
import * as FilterTypes from '../constants/filter';
import {
    reducerCrud,
    reducerCrudItems,
    mergeReducers,
    filterItems,
    reducerLoaderItems,
    paginationDefaultState
} from '../helpers/reducer-helper';

import { actionTypes } from 'redux-form';
import { combineReducers } from 'redux';

const mainReducer = function (state = [], action) {
  switch (action.type) {

    default:
      return state;
  }
};

const loaderReducer = function (state = paginationDefaultState, action) {
  switch (action.type) {
    default:
      return state;
  }
};

const filterReducer = function (state = {
  field: '',
  conditions: {
    active: FilterTypes.ACTIVE,
  }
}, action) {
  switch (action.type) {
    default:
      return state;
  }
};

export default combineReducers({
  items: combineReducers({
    items: mergeReducers(reducerCrudItems(ActionTypes.TESTERS), mainReducer),
    loader: mergeReducers(reducerLoaderItems(ActionTypes.TESTERS), loaderReducer),
  }),
  filter: mergeReducers(filterItems(ActionTypes.TESTERS), filterReducer),
});
