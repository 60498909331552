import * as React from "react";
import { connect } from "react-redux";
import { ROLE_PLANNER, ROLE_TESTER, ROLE_VIEWER } from "../../constants/roles";
import { IAnyProps } from "../../interfaces/any-props.interface";
import MessageNewModal from "../client-tester/messages/MessageNewModal";
import { messageNewModalOpen, questionNewModalOpen } from "../../actions";
import PermissionBlock from "../../components/auth/PermissionBlock";
import QuestionNewModal from "../client-tester/questions/QuestionNewModal";

interface ITitleProps {
  messageNewModal: () => void;
  questionNewModal: () => void;
  title: string;
  text: string;
}

const BasePageTitle = (props: ITitleProps): JSX.Element => {
  const isMessage = props.title === "Messages";

  const renderNewMessageModal = (): void => {
    props.messageNewModal();
  };

  const renderNewQuestionModal = () => {
    props.questionNewModal();
  };

  const renderModal = isMessage ? renderNewMessageModal : renderNewQuestionModal


  return (
    <>
      <div className="block-header-wrapper">
        <h4 className="block-header">{props.title}</h4>
        <PermissionBlock roles={[ROLE_PLANNER, ROLE_TESTER, ROLE_VIEWER]}>
          <button className="btn-new" onClick={renderModal}>
            {props.text}
          </button>
        </PermissionBlock>
      </div>
      {isMessage ? <MessageNewModal /> : <QuestionNewModal />}
    </>
  );
};

export default connect<IAnyProps, IAnyProps, IAnyProps>(null, {
  messageNewModal: messageNewModalOpen,
  questionNewModal: questionNewModalOpen,
})(BasePageTitle);
