import * as React from 'react';
import {WrappedFieldProps} from "redux-form/lib/Field";
import {ComponentType} from "react";
import Button from "@material-ui/core/Button/Button";
import { InputLabel } from '@material-ui/core';
import {withStyles} from "@material-ui/core/styles";
import {result} from 'lodash';

const ReduxImageFileField: ComponentType<WrappedFieldProps & {
  [key: string]: any
}> = ({input, label, meta: {touched, error}, id, classes, needPreview, ...custom},) => {
  let file;

  const onChange = (event: React.ChangeEvent<any>)=> {
    const image = event.target.files[0];

    input.onChange(image);

    // preview image
    const reader  = new FileReader();
    reader.onload= () => {
      const imageElement = document.getElementById('uploadImagePreview') as HTMLImageElement;
      if(imageElement && needPreview) {
        imageElement.src = reader.result;
      }
    }
    reader.readAsDataURL(image);

  };
  const handleClick = (event: React.ChangeEvent<any>)=> {
    file.click();
  };
  return (
    <div className={classes.fullWidth}>
      <InputLabel htmlFor="select-multiple" error={touched && error}>{label}</InputLabel>
      <div>
        <Button variant="contained"
          color="default"
          onClick={handleClick}
        >
          Choose File
          <input type="file"
            accept="image/*"
            ref={(ref) => file = ref}
            onChange={onChange}
            hidden
          />
        </Button>
        <div>{result(input, 'value.name', '')}</div>
      </div>
    </div>
  )
};

export default withStyles({
  fullWidth: {
    width: '100%',
    height: 90,
    marginTop: 20,
  },
})(ReduxImageFileField);
