import {
  convertorFromSwagger,
  convertorReportDocumentToSwagger,
  convertorToSwagger
} from '../convertors/report-document';
import {fetchRequestBody, extractJSONLD} from '../../helpers/request-helper';
import {getApi, getApiJSONLD} from '../swagger/api-factory';
import {format} from "date-fns";
import {IReportDocument} from "../../models/report-documents.interface";
import {IAnyProps} from "../../interfaces/any-props.interface";

export const getItems = (page, itemsPerPage, text = '') => {
  const parameters: IAnyProps = {};

  parameters.items = itemsPerPage;
  parameters.page = page;

  if (text) {
    parameters.report = text;
  }

  return getApiJSONLD()
    .then(api => api.getReportDocumentCollection(parameters))
    .then(extractJSONLD(convertorFromSwagger));
};

export const getItem = (id: string | number) => {
  return getApi()
    .then(api => api.getReportDocumentItem({id}))
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};

export const postItem = (model) => {
  return getApi()
    .then(api => api.postReportDocumentCollection({
      reportDocument: convertorReportDocumentToSwagger(model),
    }))
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};

export const putItem = (id: string, model: IReportDocument) => {
  return getApi()
    .then(api => api.putReportDocumentItem({
      id,
      reportDocument: convertorReportDocumentToSwagger(model),
    }))
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};

export const updateReportDocumentsStatusesToSubmitted = async (reportDocumentIds: Array<number>) => {
  return getApi()
    .then(api => api.updateStatusesReportDocumentCollection({
      reportDocumentIds: {reportDocumentIds}
    }))
    .then(fetchRequestBody);
};

export const addAdditionalDocumentRequest = async ({name, document, report}) => {
  return getApi()
    .then(api => {
      return api.postAdditionalReportDocumentCollection({additionalReportDocument: {name, document, report}});
    })
    .then(fetchRequestBody)
    .then(convertorFromSwagger)
}

export const deleteAdditionalDocumentRequest = async ({id}) => {
  return getApi()
    .then(api => {
      return api.deleteAdditionalReportDocumentItem({id});
    })
    .then(fetchRequestBody)
    .then(convertorFromSwagger)
}