import * as React from "react";
import { LINKS } from "../../constants/links";

const footerLinks = [
  { id: "home", link: LINKS.HOME, label: "Home" },
  { id: "privacy-policy", link: LINKS.PRIVACY_POLICY, label: "Privacy Policy" },
  {
    id: "terms_and_conditions",
    link: LINKS.TERMS_AND_CONDITIONS,
    label: "Terms & Conditions"
  },
  { id: "contact", link: LINKS.CONTACT, label: "Contact" },
  { id: "news", link: LINKS.NEWS, label: "News" },
  { id: "modern_slavery", link: LINKS.MODERN_SLAVERY, label: "Modern Slavery" }
];

type FooterProps = {
  hasDigiCert?: boolean;
};

const Footer = ({ hasDigiCert = false }: FooterProps) => {
  return (
    <footer>
      <div className="container-fluid">
        {hasDigiCert && (
          <div className="DigiCert_Wrap">
            <div id="DigiCertClickID_yayD5Bru"></div>
          </div>
        )}
        <div className="wrapper">
          <div className="copyright">
            &copy; {new Date().getFullYear()} AVORD LTD
          </div>
          <div className="navigation">
            {footerLinks.map(({ id, link, label }) => (
              <a href={link} target="_blank" key={id}>
                {label}
              </a>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
