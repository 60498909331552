import * as React from 'react';
import htmlToText from "html-to-text";

const HtmlToText: React.SFC<{}> = ({children}) => {
  return (
    <div>
      {htmlToText.fromString(children)}
    </div>
  );
};

export default HtmlToText;
