import * as React from "react";
import Table from "@material-ui/core/Table/Table";
import TableBody from "@material-ui/core/TableBody/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import { ARCHIVED } from "../../../../constants/filter";
import { connect } from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";
import { withRouter } from "react-router-dom";
import {
  usersLoader,
  unarchiveItem,
  archiveItem,
  getUsersFiltered,
  deleteItem
} from "../../../../actions/users";
import selector, { getExtraPermissions } from "../../../../selectors/users";
import { TablePagination } from "@material-ui/core";
import EnhancedTableHead from "../../../../components/table/EnhancedTableHead";
import TableCellArchive from "../../../../components/table/TableCellArchive";
import TableCellEdit from "../../../../components/table/TableCellEdit";
import TableCellDelete from "../../../../components/table/TableCellDelete";
import { isChangesExist } from "../../../../helpers/props-checker";
import { IAnyProps } from "../../../../interfaces/any-props.interface";
import { IUsers } from "../../../../models/users.interface";
import debounce from "debounce";
import { postEmail } from "../../../../actions";
import {
  getUserGroup,
  getUserPermissions,
  getUserEmail
} from "../../../../selectors/auth";
import { Group } from "../../../../constants/group";
import UsersReassignModal from "./UsersReassignModal";
import { Permission } from "constants/permission";
import { Link } from "react-router-dom";

import { loadDynamicPermissions } from "../../../../actions/users";

class UsersList extends React.Component<any, {}> {
  state = {
    isArchive: false,
    actionName: ""
  };

  debouncedLoad = debounce(nextProps => {
    this.loadItems(nextProps);
  }, 500);

  handleSendLink = e => {
    e.preventDefault();
    this.props.postEmail(e.target.dataset.email);
  };

  loadItems = props => {
    if (
      this.props.currentUserPermissions.includes(
        Permission.CAN_MANAGE_PLANNER_EXTRA_PERMISSIONS
      )
    ) {
      props.loadPermissions();
    }
    props.loadItems(
      props.pageNumber,
      props.itemsPerPage,
      props.showStatus,
      props.filterText
    );
  };

  setActionName = name => {
    this.setState({ actionName: name });
  };

  onUnarchive = item => () => {
    this.props.unarchiveItem(
      item.id,
      this.props.pageNumber,
      this.props.itemsPerPage,
      this.props.showStatus,
      this.props.filterText
    );
  };

  onArchive = item => () => {
    this.props.archiveItem(
      item.id,
      this.props.pageNumber,
      this.props.itemsPerPage,
      this.props.showStatus,
      this.props.filterText
    );
    this.setOnArchive(true);
    this.setActionName("archive");
  };

  setOnArchive = value => {
    this.setState({ isArchive: value });
  };

  componentDidMount() {
    const { group, currentUserPermissions, history } = this.props;
    if (
      group !== Group.OWNER &&
      !(
        currentUserPermissions.includes(Permission.CAN_MANAGE_VIEWERS) ||
        currentUserPermissions.includes(Permission.CAN_MANAGE_PLANNERS)
      )
    ) {
      history.push("/");
    }
    this.loadItems(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (
      isChangesExist(
        ["pageNumber", "itemsPerPage", "showStatus", "filterText"],
        nextProps,
        this.props
      )
    ) {
      if (isChangesExist(["filterText"], nextProps, this.props)) {
        this.debouncedLoad.clear();
        this.debouncedLoad(nextProps);
      } else {
        this.loadItems(nextProps);
      }
    }
  }

  handleChangePage = (event, page) => {
    this.props.setPageNumber(page + 1);
  };

  handleChangeRowsPerPage = event => {
    this.props.setItemsPerPage(event.target.value);
  };

  roleRender = (groupName, extraPermissions) => {
    return (
      <div style={{ display: "inline-flex" }}>
        <span>{groupName}</span>

        {this.props.group === Group.OWNER &&
          !!Object.keys(extraPermissions).length && (
            <span style={{ marginLeft: "2px" }}>
              <Tooltip
                disableTouchListener
                disableFocusListener
                placement={"top-start"}
                title={
                  <span style={{ fontSize: "14px" }}>
                    Extra Permissions:
                    {Object.keys(extraPermissions).map((permission, index) => (
                      <li key={index} style={{ marginLeft: "10px" }}>
                        {this.props.extraPermissions[permission]}
                      </li>
                    ))}
                  </span>
                }
              >
                <Icon style={{ fontSize: "20px" }}>info</Icon>
              </Tooltip>
            </span>
          )}
      </div>
    );
  };

  onDelete = item => () => {
    this.props.deleteItem(
      item.id,
      this.props.pageNumber,
      this.props.itemsPerPage,
      this.props.showStatus,
      this.props.filterText
    );
    this.setActionName("delete");
  };

  getTableColumns = () => {
    if (this.props.showStatus === ARCHIVED) {
      return [
        "Name",
        "Email",
        "Role",
        "Reset password",
        "De/Re Activate",
        "Delete"
      ];
    }
    return [
      "Name",
      "Email",
      "Role",
      "Reset password",
      "Edit",
      "De/Re Activate",
      "Delete"
    ];
  };

  chooseAction = () => {
    if (this.state.actionName === "delete") {
      return this.onDelete;
    }
    if (this.state.actionName === "archive") {
      return this.onArchive;
    }
    return "";
  };

  chooseEditUrl = (item, baseUrl) => {
    if (
      item.groupName === Group.EMPLOYEE ||
      item.groupName === Group.EXTERNAL_THIRD_PARTY
    ) {
      return "/client/virtual-team";
    }
    return baseUrl;
  };

  render() {
    const baseUrl = this.props.match.path;
    if (!this.props.isLoaded) {
      return <div className="styled-block table-no-items">Loading...</div>;
    }

    if (!this.props.data.length) {
      return (
        <div className="styled-block table-no-items">There are no items!</div>
      );
    }

    return (
      <div className="styled-block">
        <div className="table-responsive styled-table">
          <UsersReassignModal
            needAction={!!this.state.actionName}
            action={this.chooseAction()}
            resetAction={this.setActionName}
          />
          <Table padding="dense">
            <EnhancedTableHead columns={this.getTableColumns()} />
            <TableBody>
              {this.props.data.map((item: IUsers) => {
                return (
                  <TableRow key={item.id}>
                    <TableCell>
                      {item.firstName + " " + item.lastName}
                    </TableCell>
                    <TableCell>{item.email}</TableCell>
                    <TableCell className="text-nowrap">
                      {this.roleRender(item.groupName, item.dynamicPermissions)}
                    </TableCell>
                    <TableCell>
                      {!item.deletedAt &&
                      this.props.currentUserEmail !== item.email ? (
                        <a
                          href={item.email}
                          data-email={item.email}
                          onClick={this.handleSendLink}
                        >
                          Reset&nbsp;password
                        </a>
                      ) : (
                        <div>Reset&nbsp;password</div>
                      )}
                    </TableCell>
                    {this.props.currentUserEmail === item.email ? (
                      <TableCell>
                        <div style={{ height: "48px" }} />
                      </TableCell>
                    ) : (item.groupName === Group.EMPLOYEE ||
                        item.groupName === Group.EXTERNAL_THIRD_PARTY) &&
                      !item.deletedAt ? (
                      <TableCell>
                        <Link
                          to={`/client/virtual-team/${item.testerProfileId}`}
                          className="btn-edit"
                        />
                      </TableCell>
                    ) : (
                      <TableCellEdit
                        item={item}
                        baseUrl={baseUrl}
                        status={this.props.showStatus}
                      />
                    )}
                    {this.props.currentUserEmail === item.email ||
                    ((item.groupName === Group.EMPLOYEE ||
                      item.groupName === Group.EXTERNAL_THIRD_PARTY) &&
                      !item.deletedAt) ? (
                      <TableCell>
                        <div style={{ height: "48px" }} />
                      </TableCell>
                    ) : (
                      <TableCellArchive
                        onArchive={this.onArchive(item)}
                        onUnarchive={this.onUnarchive(item)}
                        item={item}
                      />
                    )}
                    {item.deletedAt ||
                    this.props.currentUserEmail === item.email ||
                    item.groupName === Group.EMPLOYEE ||
                    item.groupName === Group.EXTERNAL_THIRD_PARTY ? (
                      <TableCell>
                        <div style={{ height: "48px" }} />
                      </TableCell>
                    ) : (
                      <TableCellDelete
                        onDelete={this.onDelete(item)}
                        item={item}
                      />
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={this.props.totalItems}
            rowsPerPage={this.props.itemsPerPage}
            page={this.props.pageNumber - 1}
            backIconButtonProps={{ "aria-label": "Previous Page" }}
            nextIconButtonProps={{ "aria-label": "Next Page" }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </div>
      </div>
    );
  }
}

export default connect<IAnyProps, IAnyProps, IAnyProps & { baseUrl: string }>(
  state => ({
    data: selector.getItems(state),
    isLoaded: selector.getIsLoaded(state),
    itemsPerPage: selector.getItemsPerPage(state),
    pageNumber: selector.getPageNumber(state),
    showStatus: selector.getFilterActivityStatus(state),
    totalItems: selector.getTotalItems(state),
    filterText: selector.getFilterTextFields(state),
    group: getUserGroup(state),
    extraPermissions: getExtraPermissions(state),
    currentUserPermissions: getUserPermissions(state),
    currentUserEmail: getUserEmail(state)
  }),
  {
    postEmail,
    loadItems: getUsersFiltered,
    setItemsPerPage: usersLoader.setItemsPerPage,
    setPageNumber: usersLoader.setPageNumber,
    archiveItem: archiveItem("Planner"),
    unarchiveItem: unarchiveItem("Planner"),
    loadPermissions: loadDynamicPermissions,
    deleteItem: deleteItem("Planner")
  }
)(withRouter(UsersList));
