import * as Types from '../constants/action-type';


export default ((state = {}, action) => {
  switch (action.type) {
    case Types.FINANCE_ADD_ITEMS:
      return action.payload;
    case Types.FINANCE_PUT_ITEMS:
      return action.payload;
    case Types.FINANCE_GET_ITEM:
      return action.payload;
    default:
      return state;
  }
});