import * as ActionTypes from '../constants/action-type';
import moment from "moment";
import {ITest} from "../models/test.interface";
import {Moment} from "moment";

export interface ICalendarEvent extends ITest {
  start: Moment;
  end: Moment;
}

export interface ICalendarReducer {
  view: 'month' | 'year',
  events: ICalendarEvent[],
  year: number,
  month: number,
}

const reducer = (state: ICalendarReducer = {
  view: 'month',
  events: [],
  year: moment().year(),
  month: moment().month(),
}, action) => {
  switch (action.type) {
    case ActionTypes.CALENDAR_SET_EVENTS:
      return {...state, events: action.payload};
    case ActionTypes.CALENDAR_SET_VIEW:
      return {...state, view: action.payload};
    case ActionTypes.CALENDAR_SET_DATE:
      const newState = {
        ...state,
      };

      if (typeof action.payload.year !== 'undefined') {
        newState.year = action.payload.year;
      }

      if (typeof action.payload.month !== 'undefined') {
        newState.month = action.payload.month;
      }

      return newState;
    default:
      return state;
  }
};

export default reducer;
