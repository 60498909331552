import React from "react";
import {connect} from "react-redux";
import Modal from "@material-ui/core/Modal";
import {contactsModalClose} from "../../actions";
import Grid from "@material-ui/core/Grid/Grid";
import {Field, reduxForm} from "redux-form";
import ReduxTextField from "../../components/forms/ReduxTextField";
import {fieldsNotEmpty, composeValidators, fieldIsEmail} from "../../helpers/validations/validation";
import {postContactUs} from "../../actions";
import {getIsOpenModalContactUs} from "../../selectors/modal";
import {handleKeyPressOnPhoneField} from "../../helpers/validations/numberFieldValidator";
import {handleSpaceKeyPress} from "../../helpers/validations/emptyTestFieldValidation";

const FORM_NAME = "ContactsModal";

class ContactsModal extends React.Component {
  handleClose = () => {
    this.props.contactsModalClose();
  };

  render() {
    const {handleSubmit} = this.props;
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.props.isOpenModal}
        onClose={this.handleClose}
        className="d-flex"
      >
        <div className="styled-modal md">
          <h4>Contact Us</h4>
          <form noValidate autoComplete="off" onSubmit={handleSubmit((values) => {
            this.props.postContactUs(values, FORM_NAME);
          })}>
            <Grid container spacing={24}>
              <Grid item xs={12} sm={4}>
                <Field
                  inputProps={{minLength: 1, maxLength: 50}}
                  name="name"
                  label="Name *"
                  margin="normal"
                  component={ReduxTextField}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Field
                  inputProps={{maxLength: 35}}
                  name={`phone`}
                  label="Tel *"
                  margin="normal"
                  component={ReduxTextField}
                  onKeyPress={handleKeyPressOnPhoneField}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Field
                  inputProps={{maxLength: 60}}
                  name="email"
                  label="Email *"
                  margin="normal"
                  component={ReduxTextField}
                  onKeyPress={handleSpaceKeyPress}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Field
                  rows={8}
                  inputProps={{maxLength: 2000}}
                  multiline={true}
                  name="message"
                  label="Message *"
                  margin="normal"
                  component={ReduxTextField}
                />
              </Grid>
              <Grid item xs={12} className="text-right">
                <button type="reset" className="btn-cancel" onClick={this.handleClose}>
                  Cancel
                </button>
                <button type="submit" className="btn-accept">
                  Send
                </button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    isOpenModal: getIsOpenModalContactUs(state)
  };
}

const connectedComponent = connect(mapStateToProps, {contactsModalClose, postContactUs})(reduxForm({
  form: FORM_NAME,
  validate: composeValidators(
    fieldsNotEmpty([
      "name", "phone", "email", "message"
    ]),
    fieldIsEmail([
      "email",
    ])
  )
})(ContactsModal));

export default connectedComponent;
