import * as Types from '../constants/action-type';
import {
  createFilterAction,
  createLoaderAction,
  createRequestAction
} from '../helpers/action-helper';

export const sectorFilter = createFilterAction(Types.SECTOR);
export const sectorLoader = createLoaderAction(Types.SECTOR);
export const sectorRequest = createRequestAction(Types.SECTOR);
