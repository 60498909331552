import * as React from 'react';
import Table from "@material-ui/core/Table/Table";
import TableBody from "@material-ui/core/TableBody/TableBody";
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Icon from "@material-ui/core/Icon/Icon";
import EnhancedTableHead from '../../../components/table/EnhancedTableHead';
import {IAnyProps} from "../../../interfaces/any-props.interface";
import {IWorkHistory} from "../../../models/work-history.interface";
import {ACTIVE} from "../../../constants/filter";
import {Link} from "react-router-dom";
import TableCellArchive from "../../../components/table/TableCellArchive";
import { connect } from "react-redux";
import {workHistoryRequest, workHistoryLoader} from "../../../actions/work-history";
import selector from "../../../selectors/work-history";
import {formatDateWithSlashesDdMmYyyy} from "../../../helpers/date-formatter";

interface IProps {
  baseUrl: string;
  [key: string]: any;
}

class WorkHistoryList extends React.Component<IProps> {
  loadItems = (props) => {
    props.loadItems(
      props.pageNumber,
      props.itemsPerPage,
      props.showStatus
    );
  };

  onUnarchive = () => {
    alert('Will be soon!');
  };

  onArchive = (item) => () => {
    this.props.archiveItem(
      item.id,
      this.props.pageNumber,
      this.props.itemsPerPage,
      this.props.showStatus
    );
  };

  componentDidMount() {
    this.loadItems(this.props);
  }

  render() {
    if (!this.props.isLoaded) {
      return (
        <div>
          Loading...
        </div>
      );
    }

    if (!this.props.data.length) {
      return (
        <div>
          There are no items!
        </div>
      );
    }

    this.props.getProps(this.props.data);

    return (
      <Table>
        <EnhancedTableHead
          columns={['Employer', 'Job title', 'From', 'To',]}
          status={ACTIVE}
        />
        <TableBody>
          {
            this.props.data.map((item: IWorkHistory) => {
              return (
                <TableRow key={item.id}>
                  <TableCell>
                    {item.employer}
                  </TableCell>
                  <TableCell>
                    {item.jobTitle}
                  </TableCell>
                  <TableCell>
                    {item.dateFrom && formatDateWithSlashesDdMmYyyy(item.dateFrom)}
                  </TableCell>
                  <TableCell>
                    {item.dateTo && formatDateWithSlashesDdMmYyyy(item.dateTo)}
                  </TableCell>
                  <TableCell>
                    <Link to={`${this.props.baseUrl}/work-history/${item.id}`}>
                      <IconButton>
                        <Icon>edit</Icon>
                      </IconButton>
                    </Link>
                  </TableCell>
                  <TableCellArchive
                    onArchive={this.onArchive(item)}
                    onUnarchive={this.onUnarchive}
                    item={item} />
                </TableRow>
              );
            })
          }
        </TableBody>
      </Table>
    );
  }
}

export default connect<IAnyProps, IAnyProps, IAnyProps>((state) => {
  return {
    data: selector.getItems(state),
    isLoaded: selector.getIsLoaded(state),
    itemsPerPage: selector.getItemsPerPage(state),
    pageNumber: selector.getPageNumber(state),
    showStatus: selector.getFilterActivityStatus(state),
    totalItems: selector.getTotalItems(state),
  };
}, {
    loadItems: workHistoryRequest.getItems,
    setItemsPerPage: workHistoryLoader.setItemsPerPage,
    setPageNumber: workHistoryLoader.setPageNumber,
    archiveItem: workHistoryRequest.archiveItem,
  })(WorkHistoryList);
