import moment from "moment";

export const setFilteredCostChartData = (nextProps, getfilteredData) => {
  const { rangeOfDates: nextRangeOfDate = {} } = nextProps;
  const { dateFrom: nextDateFrom = '', dateTo: nextDateTo = '' } = nextRangeOfDate;

  if (nextDateFrom || nextDateTo) {
    const lastDateInRange = moment().endOf('month').toISOString();
    const dateFrom = nextDateFrom ? moment(nextDateFrom).toISOString() : undefined;
    const dateTo = nextDateTo ? moment(nextDateTo).toISOString() : lastDateInRange;

    getfilteredData(
      { $queryParameters: {
          dateFrom,
          dateTo,
        }
      }
    );
  } else {
    getfilteredData();
  }
};