import { Permission } from "../constants/permission";
import { TE_STATUS } from "../constants/timesheet-expenses";
import { FILTER_TYPE } from "../constants/filter";

export type NotesThreadType = {
  date: string;
  message: string;
};

export interface TimesheetUploadedDocument {
  id: number;
  name: string;
  size?: number;
}

export type TimesheetDocumentType = {
  id?: number;
  document: TimesheetUploadedDocument | File;
  type?: number;
};

export type TimesheetDocumentConvertedType = {
  id?: number;
  document: string;
  type?: number;
};

export type TimesheetDocumentErrorType = {
  document: {
    name: string;
  };
};

export interface SelectedDayDocument {
  id: number;
  name: string;
}

export type DayDocumentsResponse = {
  id: number;
  document_id: number;
  name: string;
  type_expenses_id: number;
};

export interface ExpensesDocumentType {
  document: File | string;
}

export enum TIME_SHEETS_USER_TYPE {
  ADMIN = "ADMIN",
  CLIENT = "CLIENT",
  CONSULTANT = "CONSULTANT"
}

export type DayCellProps = {
  consultant: number;
  project: number;
  date: string;
  day: string;
  timesheetDocuments: string[];
  id?: number;
  comment?: string;
  days?: number;
  expensesAmount?: number;
  timeCategory?: number;
  expensesType?: number;
  vat?: number;
  isTotal?: boolean;
  status: TE_STATUS;
  canViewPlatformPrices?: boolean;
};

export type WeekDayType = {
  day: string;
  consultant: number;
  project: number;
  timesheetDocuments: string[];
  date: string;
  comment?: string;
  timeCategory?: number;
  days?: number;
  expensesType?: number;
  expensesAmount?: number;
  vat?: number;
  id?: number;
};

export type DayByIdResponseType = {
  day: string;
  consultant: string;
  project: string;
  documents: DayDocumentsResponse[];
  comment: string | null;
  timeCategory: string | null;
  days: number[] | null;
  expensesType: string | null;
  expensesAmount: number | null;
  vat: number | null;
};

type DayDocument = {
  id?: number;
  document: TimesheetUploadedDocument | File;
  type: number;
};

export type DayByIdActionType = {
  day: string;
  documents: DayDocument[];
  comment: string | null;
  timeCategory: number | null;
  days: string | null;
  expensesType: number | null;
  expensesAmount: number | null;
  vat: number | null;
  consultant: number;
};

export type TimeExpensesWeekType = {
  consultant: number;
  project: number;
  status: number;
  weekNumber: number;
  yearWeek: string;
  days: WeekDayType[];
  id?: number;
  manager?: number | null;
  managerInfo: { user_id: number; name: string } | null;
  notesThread: NotesThreadType[];
  projectName: string;
  consultantName: string;
  consultantUserId: number;
  companyName: string;
  companyId: number;
  companyOwnerId: number;
  isActiveWeek: boolean;
  hasLoggedDays: boolean;
  purchaseOrderNumber: string | null;
  expensesPurchaseOrderNumber: string | null;
  invoiceNumber: string | null;
  expensesInvoiceNumber: string | null;
};

export type ReducedWeekType = {
  yearWeek: string;
  projectsWeeks: Array<TimeExpensesWeekType>;
};

export type AssignedProjectsType = {
  id: number;
  name: string;
  consultantId: number;
  consultantName: string;
};

export type TimeSheetWeekProps = {
  week: ReducedWeekType;
  selectedProjectWeeks: number[];
  canManageConsultants?: boolean;
  canManageClients?: boolean;
  canManageTimeExpenses?: boolean;
  canViewPlatformPrices?: boolean;
  canMangeTimeExpenses?: boolean;
  canManageConsultants?: boolean;
  canManageClients?: boolean;
  canManageTimeExpenses?: boolean;
  canViewPlatformPrices?: boolean;
};

export type TimeSheetExpensesContextValueType = {
  isAdmin: boolean;
  canAdminManageTimeExpenses?: boolean;
  isOwner: boolean;
  isPlanner: boolean;
  isConsultant: boolean;
  goBackHandler: () => void;
  userType: TIME_SHEETS_USER_TYPE;
};

export interface ConsultantProject {
  id: number;
  consultant: string;
  project: {
    id: number;
    company: string;
    name: string;
    manager: string | null;
  };
}

export interface AuthConsultantProject {
  project: {
    id: number;
    name: string;
    manager_info: {
      user_id: number;
      name: string;
    };
  };
  company: {
    id: number;
    name: string;
  };
  sow_days: number;
  project_rate: number;
}

export type ConsultantProfileType = {
  name: string;
  id?: number;
  permissions: Permission[];
  projects?: AuthConsultantProject[];
};

export interface DailyTimeLogFormData {
  days?: string;
  timeCategory?: number;
  expensesAmount?: string;
  vat?: string;
  comment?: string;
  documents?: Array<{
    document: TimesheetUploadedDocument | File;
    type: number;
  }>;
  goBackHandler: () => void;
}

export interface CreateDailyTimeLogFormData extends DailyTimeLogFormData {
  project: string;
  date: string;
  queryParams?: any;
}

export interface WeeklyTimeLogFormData {
  days: string;
  timeCategory: number;
  comment?: string;
}

export interface CreateWeeklyTimeLogFormData extends WeeklyTimeLogFormData {
  project: string;
  date: string;
  goBackHandler: () => void;
  queryParams?: any;
}

export interface EditDailyTimeLogFormData extends CreateDailyTimeLogFormData {
  id: string;
  archivedDocuments?: number[];
}

export type DailyTimeExpensesLogRequestType2 = Omit<
  CreateDailyTimeLogFormData,
  "goBackHandler" | "documents"
>;

export interface DailyTimeExpensesLogRequest
  extends DailyTimeExpensesLogRequestType2 {
  documents: TimesheetDocumentConvertedType[];
}

export type WeeklyTimeExpensesLogRequestType = Omit<
  CreateWeeklyTimeLogFormData,
  "goBackHandler"
>;

export type EditDailyTimeExpensesLogRequestType2 = Omit<
  EditDailyTimeLogFormData,
  "documents" | "goBackHandler"
>;

export interface EditDailyTimeExpensesLogRequestType
  extends EditDailyTimeExpensesLogRequestType2 {
  documents: TimesheetDocumentConvertedType[];
}

export type WeekDayDataHelperType = {
  year: string;
  month: string;
  day: string;
  date: string;
  weekDay: string;
  projectId: number;
  consultantId: number;
  projectRoute: string;
  consultantRoute: string;
  yearWeek: string;
};

export type DeleteDailyTimeExpensesLogActionType = {
  id: string;
  weekDay: string;
  projectId: number;
  yearWeek: string;
  cb: () => void;
  queryParams?: {};
};

export interface UpdateSelectedWeeksStatusFormData {
  selectedIds: number[];
  status: TE_STATUS;
  comment?: string;
}

export interface UpdateSelectedWeeksStatusAction
  extends UpdateSelectedWeeksStatusFormData {
  cb: () => void;
  queryParams?: TimeSheetsFilterParamsType;
}

export interface UpdateSelectedWeeksStatusFormProps {
  handleSubmit: () => void;
  closeModal: () => void;
  updateSelectedWeeksStatusAction: (
    props: UpdateSelectedWeeksStatusAction
  ) => void;
  status: TE_STATUS;
  confirmLabel?: string;
  description?: string;
  filterParams: TimeSheetsFilterParamsType;
}

export type convertUpdateSelectedWeeksStatusToSwaggerParams = {
  selectedIds: number[];
  status: TE_STATUS;
  comment?: string;
};

export type convertUpdateSelectedWeeksStatusToSwaggerResponse = {
  timeExpensesWeeks: {
    timeExpensesWeeks: string[];
    status: TE_STATUS;
    comment: string;
  };
};
export type TimeExpensesCategory = {
  id: number;
  name: string;
};

export type TimeSheetsFilterParamsType = {
  yearMonth?: string;
  companies?: number[];
  projects?: number[];
  consultants?: number[];
  statuses?: number[];
};

export type TimeSheetWeeksListProps = {
  weeksItems: ReducedWeekType[];
  loadItems: (params: TimeSheetsFilterParamsType) => void;
  isLoaded: boolean;
  searchFormFilters: TimeSheetsFilterParamsType;
  selectedProjectWeeks: number[];
  asAdmin?: boolean;
  asConsultant?: boolean;
  projectsList?: AssignedProjectsType[];
  permissions?: (keyof typeof Permission)[];
};

export enum TimeSheetInvoiceLinkType {
  INVOICE = "invoice",
  EXPENSES_INVOICE = "expenses_invoice",
  BILL = "bill"
}

export interface TimeSheetWeeksInvoiceAction {
  weekId: number;
  invoiceLinkType: TimeSheetInvoiceLinkType;
  fileName: string;
}

export type TimeLogCategoryType = {
  id: number | string;
  name: string;
  deletedAt: string | null;
};
export type GetTimeLogCategoriesModel = {
  pageNumber?: number;
  itemsPerPage?: number;
  name?: string;
  show?: FILTER_TYPE;
};

export type TimeCategoriesSearchFormType = {
  name?: string;
  show?: FILTER_TYPE;
};

export type TimeCategoriesFilterParamsType = TimeCategoriesSearchFormType & {
  pageNumber?: number;
  itemsPerPage?: number;
};

export type CreateTimeCategoryFormValue = {
  id?: number | string;
  name: string;
};

export type TimesheetManagerType = {
  id: number;
  firstName: string;
  lastName: string;
};

export enum TimesheetPoNumberType {
  PO_NUMBER = 1,
  EXPENSES_PO_NUMBER = 2
}

export enum TimesheetPoNumberRoute {
  PO_NUMBER = "po-number",
  EXPENSES_PO_NUMBER = "expenses-po-number"
}

export interface TimesheetPoNumberFormData {
  value: string;
}

export interface TimesheetPoNumberUpdateParams
  extends TimesheetPoNumberFormData {
  poNumberType: TimesheetPoNumberType;
  id: string;
  goBackHandler?: () => void;
}

export interface SelectedApprovedProjectWeek {
  id: string;
  weekNumber: number;
  name: string;
  consultant: string;
  poNumbers: string[];
}

export interface UpdatePoNumberPayload {
  id: string;
  data: {
    notesThread: NotesThreadType[];
    purchaseOrderNumber: string | null;
    expensesPurchaseOrderNumber: string | null;
  };
}
