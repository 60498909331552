import * as Types from '../constants/action-type';
import {push} from "react-router-redux";
import {
  getFinancesRequest,
  putFinancesRequest,
  getFinanceItemRequest,
  getCompaniesListForManagedServicesRequest,
  putServicesManagedCompaniesRequest,
  putAdditionalServicesCompaniesRequest,
  getCompaniesListForAdditionalServicesRequest,
  loadAdditionalServicesRequest,
} from "../api/requests/finance";
import {openSnackbar} from "./snackbar";
import {setRequestInProcess} from "./request";
import {MILLISECONDS_FOR_SHOWING_MESSAGE} from "./const";
import {AdditionalCompaniesType, ServiceManagedType} from "../types";


export const addFinances = (data) => ({type: Types.FINANCE_ADD_ITEMS, payload: data});

export const getFinances = () => async dispatch => {
  dispatch(setRequestInProcess(true, 'getFinances'));
  const data = await getFinancesRequest();

  // managed services additional Services
  const loadedServices = await loadAdditionalServicesRequest();
  const additionalCompanies = await getCompaniesListForAdditionalServicesRequest()
  const services = loadedServices.body["hydra:member"];
  const additionalServices = additionalCompanies.body;

  const mappedServices = services.map(service => {
    return {
      company: service.company.id,
      daysPercent: service.daysPercent,
      price: service.price,
      description: service.description
    }
  })

  const mappedAdditionalServices = additionalServices.map(({id, name}) => {
    return {
      company: id,
      name
    }
  })

  // companies for client dropdown
  const companies = await getCompaniesListForManagedServicesRequest();

  const payload = {
    ...data.body,
    servicesManaged: mappedServices,
    managedServices: services,
    additionalServices: mappedAdditionalServices,
    companiesForManagedServices: companies.body["hydra:member"],
  }
  dispatch(addFinances(payload));
  dispatch(setRequestInProcess(false, 'getFinances'));
};

export const getCompanies = () => async dispatch => {
  dispatch(setRequestInProcess(true, 'getCompanies'));

  const companies = await getCompaniesListForManagedServicesRequest();

  const payload = {
    companiesForManagedServices: companies.body["hydra:member"]
  }
  dispatch(addFinances(payload));
  dispatch(setRequestInProcess(false, 'getCompanies'));
};

export const putFinances = (finances) => async dispatch => {
  dispatch(setRequestInProcess(true, 'putFinances'));

  let data;

  try {
    data = await putFinancesRequest(finances);
  } catch (e) {
    dispatch(setRequestInProcess(false, 'putFinances'));
    dispatch(openSnackbar(e.message, MILLISECONDS_FOR_SHOWING_MESSAGE));
    return;
  }

  dispatch({type: Types.FINANCE_PUT_ITEMS, payload: data.body});
  dispatch(openSnackbar('Updated', MILLISECONDS_FOR_SHOWING_MESSAGE));
  dispatch(setRequestInProcess(false, 'putFinances'));
  dispatch(push('/admin/settings/finance'));
};

export const getFinanceItem = (key) => async dispatch => {
  const data = await getFinanceItemRequest(key);
  dispatch({type: Types.FINANCE_GET_ITEM, payload: data.body});
};

export const putManagedServicesCompaniesAction = (companies: ServiceManagedType[]) => async dispatch => {
  dispatch(setRequestInProcess(true, 'putCompanies'));
  try {
    await putServicesManagedCompaniesRequest(companies);
  } catch (e) {
    dispatch(setRequestInProcess(false, 'putCompanies'));
    dispatch(openSnackbar(e.message, MILLISECONDS_FOR_SHOWING_MESSAGE));
    return;
  }
  dispatch(setRequestInProcess(false, 'putCompanies'));
}

export const putAdditionalServicesCompaniesAction = (params:AdditionalCompaniesType) => async dispatch => {
  dispatch(setRequestInProcess(true, 'putAdditionalCompanies'));
  try {
    await putAdditionalServicesCompaniesRequest(params);
  } catch (e) {
    dispatch(setRequestInProcess(false, 'putAdditionalCompanies'));
    dispatch(openSnackbar(e.message, MILLISECONDS_FOR_SHOWING_MESSAGE));
    return;
  }
  dispatch(setRequestInProcess(false, 'putAdditionalCompanies'));
}