import * as Types from '../constants/action-type';

export default function (state = {
  isDrawerOpen: false,
}, action) {
  switch (action.type) {
    case Types.SHOW_DRAWER:
      return {...state, isDrawerOpen: true};
    case Types.HIDE_DRAWER:
      return {...state, isDrawerOpen: false};
    default:
      return state;
  }
}
