import Api from "../swagger/api";
import {BASE_URL} from "../../constants/urls";
import * as Storage from "../../helpers/storage-helper";
import {TOKEN_KEY} from "../../constants/storage";
import {fetchRequestBody} from "../../helpers/request-helper";

export const getTwoFactorInit = () => {
  const api = Promise.resolve(new Api(BASE_URL, Storage.getValue(TOKEN_KEY)));

  return api
    .then(api => api.getApiTwoFactorAuthInit())
    .then(fetchRequestBody);
};

export const postTwoFactorCheck = (code: number) => {
  const api = Promise.resolve(new Api(BASE_URL, Storage.getValue(TOKEN_KEY)));

  return api
    .then(api => api.postApiTwoFactorAuthCheck({
      twoFaDigitCode: code
    }))
    .then(fetchRequestBody);
};