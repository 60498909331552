import * as Types from '../constants/action-type';
import {openSnackbar} from "./snackbar";
import {setRequestInProcess} from "./request";
import {getTestsChartDataRequest} from "../api/requests/dashboard";

export const getTestsChartData = (parameters) => async dispatch => {
  dispatch(setRequestInProcess(true, 'testChartData'));

  let data;

  try {
    data = await getTestsChartDataRequest(parameters);
    dispatch({type: Types.TESTS_CHART, payload: data});
  } catch (e) {
    dispatch(setRequestInProcess(false, 'testChartData'));
    dispatch(openSnackbar(e.message, 4000));
    data = e.message;

    return;
  }

  dispatch(setRequestInProcess(false, 'testChartData'));

  return data;
};

export default getTestsChartData;