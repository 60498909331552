import React from "react";
import {
  Field,
  formValueSelector,
  InjectedFormProps,
  reduxForm
} from "redux-form";
import { connect } from "react-redux";
import routerModal from "../../../../components/routerModal";
import { bindActionCreators, Dispatch } from "redux";
import ReduxTextField from "../../../forms/ReduxTextField";
import ApprovedListForInvoice from "../ApprovedListForInvoice";
import selector from "../../../../selectors/time-expenses";
import { updateSelectedWeeksStatusAction } from "../../../../actions/time-expenses";
import {
  UpdateSelectedWeeksStatusFormData,
  UpdateSelectedWeeksStatusFormProps
} from "../../../../types/timesheets-and-expenses";
import {
  TE_STATUS,
  TIME_SHEETS_SEARCH_FORM
} from "../../../../constants/timesheet-expenses";
import { getTimesheetQueryParams } from "../../../../helpers/timesheets-expenses";

const UPDATE_SELECTED_WEEKS_STATUS_FORM = "UPDATE_SELECTED_WEEKS_STATUS_FORM";
const searchFormSelector = formValueSelector(TIME_SHEETS_SEARCH_FORM);

const UpdateSelectedWeeksStatusForm: React.FC<UpdateSelectedWeeksStatusFormProps &
  InjectedFormProps<UpdateSelectedWeeksStatusFormData>> = ({
  handleSubmit,
  closeModal,
  confirmLabel = "Submit",
  description,
  updateSelectedWeeksStatusAction,
  status,
  filterParams
}) => {
  const queryParams = getTimesheetQueryParams(filterParams);

  return (
    <div>
      <form
        className="timesheet-form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(values => {
          updateSelectedWeeksStatusAction({
            ...values,
            cb: closeModal,
            queryParams
          });
        })}
      >
        <div className="row">
          <div className="col-12">
            <h4>Are you sure?</h4>
            <hr />
            {description && <p>{description}</p>}
            {status === TE_STATUS.STATUS_INVOICED && <ApprovedListForInvoice />}
          </div>
        </div>
        <div className="timesheet-form-section">
          <div className="row">
            <div className="col-12">
              <Field
                name="comment"
                label="Comment"
                component={ReduxTextField}
                margin="normal"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="d-flex justify-content-end mt-4">
              <button
                type="button"
                className="btn-cancel mr-4"
                onClick={closeModal}
              >
                Cancel
              </button>
              <button type="submit" className="btn-accept">
                {confirmLabel}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

const ConnectedForm = reduxForm<
  UpdateSelectedWeeksStatusFormData,
  UpdateSelectedWeeksStatusFormProps
>({
  form: UPDATE_SELECTED_WEEKS_STATUS_FORM
})(UpdateSelectedWeeksStatusForm);

function mapStateToProps(state, props) {
  const selectedProjectWeeks = selector.getSelectedProjectWeeks(state);
  return {
    initialValues: {
      selectedIds: selectedProjectWeeks,
      status: props.status
    },
    filterParams: {
      yearMonth: searchFormSelector(state, "yearMonth"),
      projects: searchFormSelector(state, "projects"),
      companies: searchFormSelector(state, "companies"),
      consultants: searchFormSelector(state, "consultants"),
      statuses: searchFormSelector(state, "statuses")
    }
  };
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      updateSelectedWeeksStatusAction: updateSelectedWeeksStatusAction
    },
    dispatch
  );
};
const connected = connect(mapStateToProps, mapDispatchToProps)(ConnectedForm);

export default routerModal(connected);
