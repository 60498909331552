import * as Types from '../constants/action-type';
import {openSnackbar} from "./snackbar";
import {setRequestInProcess} from "./request";
import {getLastMessageRequest} from "../api/requests/dashboard";


const getLastMessage = (parameters) => async dispatch => {
  dispatch(setRequestInProcess(true, 'lastMessage'));

  let data;

  try {
    data = await getLastMessageRequest(parameters)
      .then(res => {
        dispatch({type: Types.LAST_MESSAGE, payload: res});
      });
  } catch (e) {
    dispatch(setRequestInProcess(false, 'lastMessage'));
    dispatch(openSnackbar(e.message, 4000));
  }

  dispatch(setRequestInProcess(false, 'lastMessage'));
};

export default getLastMessage;
