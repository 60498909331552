import {getApi} from '../swagger/api-factory';
import {convertorFromSwagger} from "../convertors/default-convertor";
import {fetchRequestBody} from '../../helpers/request-helper';

export const postDocument = (file: File) => {
  return getApi()
    .then(api => {
      return api.postApiSecuredImagesUpload({file});
    })
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};