import {convertorFromSwagger, convertorToSwagger} from '../convertors/report-templates';
import * as FilterActions from '../../constants/filter';
import {fetchRequestBody} from '../../helpers/request-helper';
import {getApi} from '../swagger/api-factory';
import {IAnyProps} from "../../interfaces/any-props.interface";

export const getItems = () => {
  return getApi()
    .then(api => api.getApiReportTemplates())
    .then(fetchRequestBody);
};

export const getItem = (specialismId) => {
  return getApi()
    .then(api => api.getApiReportTemplatesBySpecialismId({specialismId}))
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};

export const postItem = (model) => {
  return getApi()
    .then(api => api.postApiReportTemplates({
      reportTemplate: convertorToSwagger(model),
    }))
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};

export const putItem = (model) => {
  return getApi()
    .then(api => api.putApiReportTemplatesBySpecialismId({
      specialismId: model.specialismId,
      reportTemplate: convertorToSwagger(model.reportTemplate),
    }))
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};
