import { setRequestInProcess } from "./request";
import { getRequestName } from "../helpers/action-request-helper";
import * as Types from "../constants/action-type";
import { createLoaderAction, createCrudAction } from "../helpers/action-helper";
import { handleHttpErrors } from "../helpers/errors/http-error-handler";
import {
  getItems,
  createItem,
  updateItem,
  archiveItem,
  unArchiveItem
} from "../api/requests/timesheets-time-categories";

import { openSnackbar } from "./snackbar";
import { MILLISECONDS_FOR_SHOWING_MESSAGE } from "./const";
import { Dispatch } from "redux";
import { ALL, FILTER_TYPE } from "../constants/filter";
import {
  GetTimeLogCategoriesModel,
  TimeLogCategoryType
} from "../types/timesheets-and-expenses";
import { getDeletedAtDate } from "../helpers/date-formatter";

export const TimesheetTimeCategoriesLoader = createLoaderAction(
  Types.TIMESHEET_TIME_CATEGORIES
);
export const TimesheetTimeCategoriesRequest = createCrudAction(
  Types.TIMESHEET_TIME_CATEGORIES
);

export const getTimesheetTimeCategoriesAction = (
  params: GetTimeLogCategoriesModel
) => async dispatch => {
  dispatch(
    setRequestInProcess(
      true,
      getRequestName(
        Types.TIMESHEET_TIME_CATEGORIES,
        "getTimesheetTimeCategoriesAction"
      )
    )
  );
  const itemsPerPage = params?.itemsPerPage || 25;
  const pageNumber = params?.pageNumber || 1;

  dispatch(TimesheetTimeCategoriesLoader.setIsNotLoaded());

  try {
    const { items, totalItems } = await getItems(params);
    dispatch(
      TimesheetTimeCategoriesLoader.setPaginatioData(
        items,
        itemsPerPage,
        totalItems,
        pageNumber
      )
    );
  } catch (error) {
    handleHttpErrors(error, dispatch);
  } finally {
    dispatch(
      setRequestInProcess(
        false,
        getRequestName(
          Types.TIMESHEET_TIME_CATEGORIES,
          "getTimesheetTimeCategoriesAction"
        )
      )
    );
  }
};

export const createTimesheetTimeCategoryAction = (
  values: Pick<TimeLogCategoryType, "name">,
  callback: () => void
) => async dispatch => {
  dispatch(
    setRequestInProcess(
      true,
      getRequestName(
        Types.TIMESHEET_TIME_CATEGORIES,
        "createTimesheetTimeCategoryAction"
      )
    )
  );

  try {
    await createItem(values);
    callback();
    dispatch(
      openSnackbar(
        "New category has been created",
        MILLISECONDS_FOR_SHOWING_MESSAGE
      )
    );
  } catch (error) {
    handleHttpErrors(error, dispatch);
  } finally {
    dispatch(
      setRequestInProcess(
        false,
        getRequestName(
          Types.TIMESHEET_TIME_CATEGORIES,
          "createTimesheetTimeCategoryAction"
        )
      )
    );
  }
};

export const updateTimesheetTimeCategoryAction = (
  values: Omit<TimeLogCategoryType, "deletedAt">,
  callback: () => void
) => async (dispatch: Dispatch) => {
  dispatch(
    setRequestInProcess(
      true,
      getRequestName(
        Types.TIMESHEET_TIME_CATEGORIES,
        "updateTimesheetTimeCategoryAction"
      )
    )
  );

  try {
    await updateItem(values);
    dispatch(
      openSnackbar(
        "Category has been updated",
        MILLISECONDS_FOR_SHOWING_MESSAGE
      )
    );
    callback();
  } catch (error) {
    handleHttpErrors(error, dispatch);
  } finally {
    dispatch(
      setRequestInProcess(
        false,
        getRequestName(
          Types.TIMESHEET_TIME_CATEGORIES,
          "updateTimesheetTimeCategoryAction"
        )
      )
    );
  }
};

export const archiveTimesheetTimeCategoryAction = (
  item: TimeLogCategoryType,
  filter?: FILTER_TYPE
) => async (dispatch: Dispatch) => {
  dispatch(
    setRequestInProcess(
      true,
      getRequestName(
        Types.TIMESHEET_TIME_CATEGORIES,
        "deleteTimesheetTimeCategoryAction"
      )
    )
  );
  try {
    await archiveItem(+item.id);
    if (filter === ALL) {
      dispatch(
        TimesheetTimeCategoriesRequest.replaceItem({
          ...item,
          deletedAt: getDeletedAtDate()
        })
      );
    } else {
      dispatch(TimesheetTimeCategoriesRequest.removeItem({ id: item.id }));
    }
    dispatch(
      openSnackbar(
        `Category has been archived`,
        MILLISECONDS_FOR_SHOWING_MESSAGE
      )
    );
  } catch (error) {
    handleHttpErrors(error, dispatch);
  } finally {
    dispatch(
      setRequestInProcess(
        false,
        getRequestName(
          Types.TIMESHEET_TIME_CATEGORIES,
          "deleteTimesheetTimeCategoryAction"
        )
      )
    );
  }
};

export const unArchiveTimesheetTimeCategoryAction = (
  item: TimeLogCategoryType,
  filter?: FILTER_TYPE
) => async (dispatch: Dispatch) => {
  dispatch(
    setRequestInProcess(
      true,
      getRequestName(
        Types.TIMESHEET_TIME_CATEGORIES,
        "unArchiveTimesheetTimeCategoryAction"
      )
    )
  );
  try {
    await unArchiveItem(+item.id);
    if (filter === ALL) {
      dispatch(
        TimesheetTimeCategoriesRequest.replaceItem({ ...item, deletedAt: null })
      );
    } else {
      dispatch(TimesheetTimeCategoriesRequest.removeItem({ id: item.id }));
    }
    dispatch(
      openSnackbar(
        `Category has been unarchived`,
        MILLISECONDS_FOR_SHOWING_MESSAGE
      )
    );
  } catch (error) {
    handleHttpErrors(error, dispatch);
  } finally {
    dispatch(
      setRequestInProcess(
        false,
        getRequestName(
          Types.TIMESHEET_TIME_CATEGORIES,
          "unArchiveTimesheetTimeCategoryAction"
        )
      )
    );
  }
};
