import {createMuiTheme} from "@material-ui/core/index";

export const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: ["Open Sans", "sans-serif"],
  },
  palette: {
    primary: {
      main: "#2b895c",
    },
    secondary: {
      main: "#2b895c"
    },
  },
});