import React from "react";
import Paper from "@material-ui/core/Paper/Paper";
import { Field, reduxForm, InjectedFormProps } from "redux-form";
import ReduxTextField from "../../../../components/forms/ReduxTextField";
import Button from "@material-ui/core/es/Button/Button";
import ReduxRadioButton from "../../../../components/forms/ReduxRadioButton";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import * as FilterTypes from "../../../../constants/filter";
import Radio from "@material-ui/core/Radio/Radio";
import { connect } from "react-redux";
import { ACTIVE } from "../../../../constants/filter";
import { TIMESHEET_EXPENSES_TYPES_FORM_NAME } from "../../../../constants/timesheet-expenses";

const SearchForm = ({ reset }: InjectedFormProps) => {
  return (
    <form className="mb-3">
      <Paper className="pl-4 pr-4 pb-4">
        <div className="row">
          <div className="col-md-12">
            <Field
              name="name"
              label="Find"
              margin="normal"
              fullWidth
              component={ReduxTextField}
            />
          </div>
          <div className="col-md-12">
            <Button
              className="float-right mt-2"
              variant="contained"
              color="primary"
              onClick={reset}
            >
              Reset Filter
            </Button>
            <Field
              name="show"
              margin="normal"
              fullWidth
              inline={true}
              component={ReduxRadioButton}
            >
              <FormControlLabel
                value={FilterTypes.ACTIVE}
                control={<Radio />}
                label={FilterTypes.ACTIVE}
              />
              <FormControlLabel
                value={FilterTypes.ARCHIVED}
                control={<Radio />}
                label="Archived"
              />
              <FormControlLabel
                value={FilterTypes.ALL}
                control={<Radio />}
                label="All"
              />
            </Field>
          </div>
        </div>
      </Paper>
    </form>
  );
};

function mapStateToProps() {
  return {
    initialValues: {
      name: "",
      show: ACTIVE
    }
  };
}

const connectForm = reduxForm({
  form: TIMESHEET_EXPENSES_TYPES_FORM_NAME
})(SearchForm);

export default connect(mapStateToProps)(connectForm);
