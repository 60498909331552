import * as React from "react";
import {IAnyProps} from "../../../../interfaces/any-props.interface";
import {connect} from "react-redux";
import {getCalendar} from "../../../../selectors/calendar";
import {ICalendarEvent, ICalendarReducer} from "../../../../reducers/calendar";
import {
  setCalendarDateAction,
  setCalendarEventsAction,
  setCalendarRangeAction,
  setCalendarViewTypeAction
} from "../../../../actions/calendar";
import {Moment} from "moment";
import moment from "moment";
import {Link} from "react-router-dom";
import StatusBlock from "../../../common/StatusBlock";
import {Icon, Tooltip} from "@material-ui/core";
import {IRenderTitle, renderTitle} from "./CalendarMonthView";

type IMapStateToProps = ICalendarReducer;

interface IDispatchProps {
  setCalendarDate: (year?: number | undefined, month?: number | undefined) => void;
  setCalendarViewType: (type: "month" | "year") => void;
}

type IProps = IAnyProps & IMapStateToProps & IDispatchProps;

const isIntersect = (d1: [Moment, Moment], d2: [Moment, Moment]) => {
  if (d1[0].isBetween(d2[0], d2[1], "day", "(]")) {
    return true;
  }

  if (d1[1].isBetween(d2[0], d2[1], "day", "[)")) {
    return true;
  }

  if (d2[0].isBetween(d1[0], d1[1], "day", "[)")) {
    return true;
  }

  if (d2[1].isBetween(d1[0], d1[1], "day", "(]")) {
    return true;
  }

  return false;
};

class CalendarYearView extends React.Component<IProps> {
  handleClickThisYear = () => {
    this.props.setCalendarDate(new Date().getFullYear(), this.props.month);
  };

  handleClickPrev = () => {
    this.props.setCalendarDate(this.props.year - 1, this.props.month);
  };

  handleClickNext = () => {
    this.props.setCalendarDate(this.props.year + 1, this.props.month);
  };

  renderMonth = (name, index) => {
    return (
      <td className="calendar-names" key={index}>
        {name}
      </td>
    );
  };

  getEventsMonth = (events: ICalendarEvent[], start: Moment, end: Moment) => {
    return events.filter(event => {
      return isIntersect([start, end], [event.start, event.end]);
    });
  };

  renderEvents = () => {
    const events = this.props.events;
    const date = moment([this.props.year, 0, 1]);

    return Array.apply(null, new Array(12)).map((a, index) => {
      const start = date.clone()
        .month(index)
        .startOf("month");

      const end = date.clone()
        .month(index)
        .endOf("month");

      const eventsToday = this.getEventsMonth(events, start, end);

      return (
        <td key={`${index}`} className={"calendar-cell"}>
          {eventsToday.map(event => {
            return (
              <div key={event.id} className={"year-event"}>
                <Link to={`/client/schedule/test/${event.id}`}>
                  {event.status && <StatusBlock status={event.status} noText={true}/>}
                  <Tooltip
                      className="ml-1"
                      placement={"bottom-end"}
                      disableFocusListener
                      disableTouchListener
                      title={renderTitle(event)}>
                    <span>{event.name}</span>
                  </Tooltip>
                </Link>
              </div>
            );
          })}
        </td>
      );
    });
  };

  render() {
    return (
      <div>

        <div className="calendar-head">

          <div className="month-year-buttons">
            <button
              onClick={this.props.setCalendarViewType.bind(null, "month")}
            >month
            </button>
            <button
              onClick={this.props.setCalendarViewType.bind(null, "year")}
              disabled
            >year
            </button>
          </div>

          <div className="calendar-prev-next-buttons">

            <button onClick={this.handleClickPrev} className="prev">
              <img src="/images/calendar-arrow-prev.svg" alt="Previous month"/>
            </button>

            <span>
              {this.props.year}
            </span>

            <button onClick={this.handleClickNext} className="next">
              <img src="/images/calendar-arrow-next.svg" alt="Next month"/>
            </button>

          </div>

          <div className="text-right">
            <button onClick={this.handleClickThisYear} className="btn-calendar-this-year">
              this year
            </button>
          </div>

        </div>

        <table className="calendar year">
          <tbody>
          <tr>
            {
              ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
                .map(this.renderMonth)
            }
          </tr>
          <tr>
            {this.renderEvents()}
          </tr>
          </tbody>
        </table>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return getCalendar(state);
};

export default connect(mapStateToProps, {
  setCalendarViewType: setCalendarViewTypeAction,
  setCalendarRange: setCalendarRangeAction,
  setCalendarEvents: setCalendarEventsAction,
  setCalendarDate: setCalendarDateAction
})(CalendarYearView);
