import * as React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Modal} from "@material-ui/core";
import {getEditProposalByTesterOpenModal} from "../../../../selectors/modal";
import {editProposalByTesterModalClose} from "../../../../actions/modals";

interface IEditProposalByTesterProps {
    isOpenModal: boolean;
    closeModal: () => void;
    openEditMode: () => void;
}

const EditProposalModal: React.FunctionComponent<IEditProposalByTesterProps> = (
    {closeModal, isOpenModal, openEditMode}
) => {
    const goToAccept = () => {
        openEditMode()
        closeModal()
    }

    return (
        <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={isOpenModal}
            onClose={closeModal}
            className="d-flex overflow-auto"
        >
            <div className="styled-modal sm">
                <h4>Are you sure?</h4>
                <hr/>
                <div className="text-right">
                    <button className="btn-cancel" onClick={closeModal}>
                        No
                    </button>
                    <button className="btn-accept" type="submit" onClick={goToAccept}>
                        Yes
                    </button>
                </div>
            </div>
        </Modal>
    );
}

function mapStateToProps(state) {
    return {
        isOpenModal: getEditProposalByTesterOpenModal(state),
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            closeModal: editProposalByTesterModalClose,
        },
        dispatch
    );
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditProposalModal);
