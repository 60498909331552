import {getApi, getApiJSON, getApiJSONLD} from '../swagger/api-factory';
import {extractJSONLD, fetchRequestBody} from "../../helpers/request-helper";
import {convertorFromSwagger} from "../convertors/default-convertor";
import {convertorToSwagger} from "../convertors/locations";
import * as FilterActions from "../../constants/filter";
import {IAnyProps} from "../../interfaces/any-props.interface";
import {MAX_PAGINATION_RESULTS} from "../../constants/loader";

export const getItems = (page = 1, itemsPerPage = MAX_PAGINATION_RESULTS, show = FilterActions.DEFAULT_FILTER, text = '') => {
  const parameters: IAnyProps = { page };

  parameters.items = itemsPerPage;
  parameters.show = show;

  if (text) {
    parameters.search = text;
  }

  return getApiJSONLD()
    .then(api => api.getLocationCollection(parameters))
    .then(extractJSONLD(convertorFromSwagger));
};

export const postItem = (model) =>{
  return getApiJSON()
    .then(api => api.postLocationCollection({
      location: convertorToSwagger(model),
    }))
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};

export const getItem = (id: string | number) =>{
  return getApi()
    .then(api => api.getLocationItem({id}))
    .then(fetchRequestBody);
};

export const putItem = (model) => {
  return getApi()
    .then(api => api.putLocationItem({
      id: model.id,
      location: model,
    }))
    .then(fetchRequestBody)
};

export const deleteItem = (id: string | number) => {
  return getApi()
    .then(api => api.deleteLocationItem({id}));
};

export const unarchiveItem = (id: string | number) => {
  return getApi()
    .then(api => api.unarchiveLocationItem({
      id,
      location: {},
    }));
};
