import * as React from "react";
import {Link, RouteComponentProps, withRouter} from "react-router-dom";
import {ITest} from "../../../models/test.interface";
import {connect} from "react-redux";
import {
  acceptEditProposalByTesterModalOpen,
  bookingCancelModalOpen,
  bookingRejectModalOpen,
} from "../../../actions/modals";
import BookingRejectModal from "./modal/BookingRejectModal";
import BookingCancelModal from "./modal/BookingCancelModal";
import AcceptEditProposalModal from "./modal/AcceptEditProposalModal";
import PermissionBlock from "../../../components/auth/PermissionBlock";
import {ROLE_PLANNER, ROLE_TESTER} from "../../../constants/roles";
import {AfterBookedStatuses, ClientInvoicedStatuses, TesterInvoicedStatuses, TestStatus} from "../../../constants/test-status";
import InvoiceDetailsButton from "./button/InvoiceDetailsButton";

interface IProps {
  test: ITest;
}

const testerBookingPage = "/tester/booking-requests";
const clientBookingPage = "/client/booking-requests";

class TestDetailsButtons extends React.Component<RouteComponentProps<any> & IProps & {
  actionBookTester: (idTest, idProject) => void;
  bookingRejectModalOpen: (id: number, status: TestStatus, backRoute: string) => void,
  bookingCancelModalOpen: (id: number, status: TestStatus, backRoute: string) => void,
  acceptEditProposalByTesterModalOpen: () => void
}, {}> {
  baseUrl = this.props.match.path;

  rejectBooking = () => {
    this.props.bookingRejectModalOpen(
      this.props.match.params.test,
      TestStatus.STATUS_BOOKING_REJECTED_BY_CUSTOMER,
      clientBookingPage
    );
  };

  rejectBookingByTester = () => {
    this.props.bookingRejectModalOpen(
      this.props.match.params.test,
      TestStatus.STATUS_BOOKING_REJECTED_BY_TESTER,
      testerBookingPage
    );
  };

  cancelProposalByPlanner = () => {
    this.props.bookingCancelModalOpen(
      this.props.match.params.test,
      TestStatus.STATUS_PROPOSAL_SUBMITTED_CANCELLED_BY_CUSTOMER,
      clientBookingPage
    );
  };

  cancelProposalByTester = () => {
    this.props.bookingCancelModalOpen(
      this.props.match.params.test,
      TestStatus.STATUS_PROPOSAL_SUBMITTED_CANCELLED_BY_TESTER,
      testerBookingPage
    );
  };

  cancelBookingByPlanner = () => {
    this.props.bookingCancelModalOpen(
      this.props.match.params.test,
      TestStatus.STATUS_BOOKING_CANCELLED_BY_CLIENT,
      clientBookingPage
    );
  };

  cancelBookingByTester = () => {
    this.props.bookingCancelModalOpen(
      this.props.match.params.test,
      TestStatus.STATUS_BOOKING_CANCELLED_BY_TESTER,
      testerBookingPage
    );
  };

  render() {
    return (
      <div className="text-right">
        <PermissionBlock roles={[ROLE_PLANNER]}>
          {this.props.test.status === TestStatus.STATUS_PROPOSAL_SUBMITTED && (
            <>
              <button
                className="ml-2 btn-reject"
                onClick={this.rejectBooking}>
                Reject
              </button>
              <button
                className="ml-2 btn-cancel"
                onClick={this.cancelProposalByPlanner}>
                Cancel
              </button>
              <Link to={`/client/booking-requests/${this.props.match.params.test}/edit`}>
                <button className="ml-2 btn-edit">Edit</button>
              </Link>
              {this.props.test?.proposal?.isEditedByTester ? (
                  <button
                      className="ml-2 btn-accept"
                      onClick={()=>this.props.acceptEditProposalByTesterModalOpen()}
                  >
                    Accept
                  </button>
              ) : (
                  <Link
                      to={`/client/booking-requests/${this.props.match.params.test}/booking-confirmation`}
                  >
                    <button className="ml-2 btn-accept">Accept</button>
                  </Link>
              )}
            </>
          )}

          {this.props.test.status === TestStatus.STATUS_BOOKING_REQUESTED &&
          <>
            <button
              className="ml-2 btn-reject"
              onClick={this.rejectBooking}>
              Reject
            </button>
            <Link to={`/client/booking-requests/${this.props.match.params.test}/edit`}>
              <button className="ml-2 btn-edit">Edit</button>
            </Link>
          </>
          }

          {(this.props.test.status === TestStatus.STATUS_BOOKING_REQUESTED_EDITED) && (
            <>
              <button
                className="ml-2 btn-reject"
                onClick={this.rejectBooking}>
                Reject
              </button>
              <Link to={`/client/booking-requests/${this.props.match.params.test}/edit`}>
                <button className="ml-2 btn-edit">Edit</button>
              </Link>
            </>
          )}

          {(this.props.test.status === TestStatus.STATUS_SCHEDULED) && (
            <>
              <Link to={`/client/booking-requests/${this.props.match.params.test}/edit`}>
                <button
                  className="ml-2 btn-edit"
                >
                  Edit
                </button>
              </Link>
              <Link to={`/client/schedule/booking/${this.props.test.project}/${this.props.test.id}`}>
                <button
                  className="ml-2 btn-book-a-tester"
                >
                  Book
                </button>
              </Link>
            </>
          )}


          {(this.props.test.status === TestStatus.STATUS_DRAFT) && (
            <>
              <Link to={`/client/booking-requests/${this.props.match.params.test}/edit`}>
                <button
                  className="ml-2 btn-edit"
                >
                  Edit
                </button>
              </Link>
            </>
          )}

          {AfterBookedStatuses.indexOf(this.props.test.status as TestStatus) !== -1 && (
            <>
              {this.props.test.status === TestStatus.STATUS_BOOKED &&
              <button
                className="ml-2 btn-cancel"
                onClick={this.cancelBookingByPlanner}>
                Cancel&nbsp;Booking
              </button>
              }
            </>
          )}
          {this.props.test.hasInvoices && ClientInvoicedStatuses.indexOf(this.props.test.status as TestStatus) !== -1 && (
            <>
              <InvoiceDetailsButton
                testId={this.props.test.id}
              />
            </>
          )}
        </PermissionBlock>

        <PermissionBlock roles={[ROLE_TESTER]}>
          {this.props.test.status === TestStatus.STATUS_BOOKED && (
            <button
              className="ml-2 btn-cancel"
              onClick={this.cancelBookingByTester}>
              Cancel&nbsp;Booking
            </button>
          )}

          {(this.props.test.status === TestStatus.STATUS_BOOKING_REQUESTED ||
            this.props.test.status === TestStatus.STATUS_BOOKING_REQUESTED_EDITED) && (
            <button
              className="ml-2 btn-reject"
              onClick={this.rejectBookingByTester}>
              Reject
            </button>
          )}

          {this.props.test.status === TestStatus.STATUS_PROPOSAL_SUBMITTED && (
            <button
              className="ml-2 btn-cancel"
              onClick={this.cancelProposalByTester}>
              Cancel&nbsp;Request
            </button>
          )}

          {this.props.test.hasInvoices && TesterInvoicedStatuses.indexOf(this.props.test.status as TestStatus) !== -1 && (
              <>
                <InvoiceDetailsButton
                    testId={this.props.test.id}
                />
              </>
          )}
        </PermissionBlock>

        <AcceptEditProposalModal/>
        <BookingRejectModal/>
        <BookingCancelModal/>
      </div>
    );
  }
}

// TODO: Change push URL to correct.
const connected = connect(undefined, {
  bookingRejectModalOpen,
  bookingCancelModalOpen,
  acceptEditProposalByTesterModalOpen
})(TestDetailsButtons as any);

export default withRouter(connected as any) as any;
