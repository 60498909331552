import * as React from "react";
import {composeValidators, fieldsNotEmpty} from "../../../../helpers/validations/validation";
import {InjectedFormProps, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {
  specialismsWithoutReportTemplatesRequest,
  putSpecialismWithoutReportTemplatesAction
} from "../../../../actions/specialisms-without-report-templates";
import parameterFetcher from "../../../../components/parametersFetcher";
import selector from "../../../../selectors/specialisms";
import ReportTemplatesForm from "./ReportTemplatesForm";
import routerModal from "../../../../components/routerModal";
import {WithStyles} from "@material-ui/core/styles/withStyles";
import {IAnyProps} from "../../../../interfaces/any-props.interface";

const FORM_NAME = "ReportTemplatesUpdate";

class ReportTemplatesUpdate extends React.Component<IAnyProps & InjectedFormProps<{}, {}> & {
  putData: (...any) => void,
  backRoute: string,
} & WithStyles<any>, any> {
  render() {
    const {handleSubmit, putData, backRoute} = this.props;

    return (
      <div className="styled-modal md">
        <form noValidate autoComplete="off"
              onSubmit={handleSubmit((values) => {
                putData(values, FORM_NAME, backRoute);
              })}>
          <ReportTemplatesForm backRoute={backRoute}/>
        </form>
      </div>
    );
  }
}

const formConnected = reduxForm({
  form: FORM_NAME,
  validate: composeValidators(
    fieldsNotEmpty(["reportTemplate", "specialism",]),
  )
})(ReportTemplatesUpdate);

const connected = parameterFetcher(
  connect(undefined, {
    putData: putSpecialismWithoutReportTemplatesAction,
  })(formConnected),
  FORM_NAME,
  specialismsWithoutReportTemplatesRequest.getItem,
  selector.getItemById
);

export default routerModal(connected);
