import { Observable } from 'rxjs';
import { ActionsObservable } from 'redux-observable';
import * as actionType from 'constants/action-type';
import { flatMap, map, switchMap } from 'rxjs/operators';
import {
  additionalDocumentSearchRequest,
  deleteTestDocument,
  deleteAdditionalTestDocument,
  postDocument
} from 'api/requests/document';
import { fromPromise } from "rxjs/internal-compatibility";
import { IActionPayload } from "./index";
import { startEndCatch } from "./pipes/errors";
import { createAction } from "redux-actions";
import { createLoaderAction } from "helpers/action-helper";
import { documentSearchRequest } from "api/requests/document";

export const postDocumentEpic = (action$: ActionsObservable<IActionPayload<File>>): Observable<any> => {
  return action$
    .ofType(actionType.DOCUMENT_POST)
    .pipe(
      switchMap((action: IActionPayload<File>) => {
        return fromPromise(postDocument(action.payload))
          .pipe(
            map((data) => {
              return createAction(actionType.DOCUMENT_POST_COMPLETED, () => data)();
            }),
            ...startEndCatch('postDocumentEpic'),
          );
      }),
    );
};

export const documentsSearchApplyEpic = (action$: ActionsObservable<IActionPayload<any>>): Observable<any> => {
  return action$
    .ofType(actionType.DOCUMENT_SEARCH_APPLY)
    .pipe(
      switchMap((action: IActionPayload<any>) => {
        return fromPromise(documentSearchRequest(
          action.payload,
        ))
          .pipe(
            flatMap(({items, totalItems}) => {
              return [
                createLoaderAction(actionType.TEST_DOCUMENT).setPaginatioData(items, totalItems),
              ];
            }),
            ...startEndCatch('documentsSearchApplyEpic'),
          );
      }),
    );
};

export const additionalDocumentsSearchApplyEpic = (action$: ActionsObservable<IActionPayload<any>>): Observable<any> => {
  return action$
    .ofType(actionType.ADDITIONAL_DOCUMENT_SEARCH_APPLY)
    .pipe(
      switchMap((action: IActionPayload<any>) => {
        return fromPromise(additionalDocumentSearchRequest(
          action.payload,
        ))
          .pipe(
            flatMap(({items, totalItems}) => {
              return [
                createLoaderAction(actionType.TEST_ADDITIONAL_DOCUMENT).setPaginatioData(items, totalItems),
              ];
            }),
            ...startEndCatch('additionalDocumentsSearchApplyEpic'),
          );
      }),
    );
};

export const archiveTestDocumentEpic = (action$: ActionsObservable<IActionPayload<{ testId: number, documentId: any }>>): Observable<any> => {
  return action$
    .ofType(actionType.DOCUMENT_ARCHIVE)
    .pipe(
      switchMap((action) => {
        return fromPromise(deleteTestDocument(action.payload.testId, action.payload.documentId))
          .pipe(
            flatMap((data) => {
              return [
                createAction(actionType.DOCUMENT_SEARCH_APPLY, () => action.payload.testId)(),
              ];
            }),
            ...startEndCatch('archiveDocumentEpic'),
          );
      }),
    );
};

export const archiveAdditionalTestDocumentEpic = (action$:  ActionsObservable<IActionPayload<{ testId: number, documentId: number }>>): Observable<any> => {
  return action$
    .ofType(actionType.ADDITIONAL_DOCUMENT_ARCHIVE)
    .pipe(
      switchMap((action) => {
        return fromPromise(deleteAdditionalTestDocument(action.payload.testId, action.payload.documentId))
          .pipe(
            flatMap((data) => {
              return [
                createAction(actionType.ADDITIONAL_DOCUMENT_SEARCH_APPLY, () => action.payload.testId)(),
              ];
            }),
            ...startEndCatch('archiveDocumentEpic'),
          );
      }),
    );
};
