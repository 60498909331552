import {isActiveJWT} from "./jwt-checker";
import {setGuestPermissionsAction, setPermissionsAction} from "../actions/common";
import {fetchMyData} from "../api/requests/auth";
import {checkToken} from "../api/swagger/api-factory";
import * as Storage from "./storage-helper";
import {REFRESH_TOKEN_KEY, TOKEN_KEY} from "../constants/storage";

async function checkingToken() {
  if (!isActiveJWT() && Storage.getValue(REFRESH_TOKEN_KEY)) {
    await checkToken();
  }

  return isActiveJWT();
}

export const asyncPermissionsAuthState = (store) => {
  checkingToken()
    .then(value => {
      if (value) {
        fetchMyData()
          .then(data => {
            store.dispatch(setPermissionsAction(data));

            return data;
          });
      } else {
        /**
         * Some kind of work around
         * For initializing middleware before dispatching actions
         */
        setTimeout(() => {
          store.dispatch(setGuestPermissionsAction(true));
        }, 0);
      }
    })
    .catch(error => {
      Storage.removeValue(TOKEN_KEY);
      Storage.removeValue(REFRESH_TOKEN_KEY);

      setTimeout(() => {
        store.dispatch(setGuestPermissionsAction(true));
      }, 0);
    });

  return next => action => {
    next(action);
  };
};
