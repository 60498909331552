import * as React from "react";
import {connect} from "react-redux";
import {formValueSelector, InjectedFormProps, reduxForm} from "redux-form";
import {FieldGeneric} from "../../../components/forms/FieldGeneric";
import ReduxTextField from "../../../components/forms/ReduxTextField";
import selector from "../../../selectors/users";
import ReduxSelectField from "../../../components/forms/ReduxSelectField";
import {Button, Checkbox, ListItemText, MenuItem} from "@material-ui/core";
import {dataFetcher} from "../../../components/dataFetcher";
import groupsSelector from "../../../selectors/groups-search";
import {groupsSearchRequest} from "../../../actions/groupsSearch";
import {ACTIVE} from "../../../constants/filter";
import {GroupTypes} from "../../../constants/group";
import {find} from "lodash";

export const FORM_NAME = "VirtualTeamSearchForm";
const selectorForm = formValueSelector(FORM_NAME);

interface IGroup {
  id: number;
  name: string;
}

class VirtualTeamSearchForm extends React.Component<InjectedFormProps<{}, {}> & {
  valuesData: { text: string, [key: string]: any },
  groups: IGroup[],
}, any> {
  renderGroups = selected => selected
    .map(data => find(this.props.groups, {id: data}))
    .filter(a => !!a)
    .map((data: IGroup) => data.name)
    .join(", ");

  checkGroups = e => {
    e.stopPropagation();
    this.props.change("groups", this.props.groups.map(a => a.id));
  };

  uncheckGroups = e => {
    e.stopPropagation();
    this.props.change("groups", []);
  };

  render() {
    return (
      <div className="styled-block">
        <form className="mb-4">
          <div className="row">
            <div className="col-md-6">
              <FieldGeneric
                name="name"
                label="Name"
                component={ReduxTextField}
                margin="normal"
              />
            </div>
            <div className="col-md-6">
              <FieldGeneric
                name="groups"
                label="Type"
                fullWidth
                multiple
                component={ReduxSelectField}
                renderValue={this.renderGroups}
              >
                <MenuItem key={0}>
                  <Button color="primary" onClick={this.checkGroups}>Check all</Button>
                  <Button color="primary" onClick={this.uncheckGroups}>Uncheck all</Button>
                </MenuItem>
                {this.props.groups.map((group) => (
                  <MenuItem key={group.id} value={group.id}>
                    <Checkbox checked={(this.props.valuesData.groups || []).indexOf(group.id) > -1}/>
                    <ListItemText primary={group.name}/>
                  </MenuItem>
                ))}
              </FieldGeneric>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    valuesData: {
      ...selectorForm(
        state,
        "name",
        "groups",
      )
    },
    initialValues: {
      text: selector.getFilterTextFields(state),
      groups: [],
    }
  };
}

const connectForm = reduxForm({
  form: FORM_NAME
})(VirtualTeamSearchForm);

const connected = connect(mapStateToProps, {})(connectForm);

const fetched = dataFetcher(connected, [{
  key: "groups",
  selector: (state, props) => groupsSelector.getItemsObject(state),
  action: () => groupsSearchRequest.getItems(1, 1000, ACTIVE, '', {type: GroupTypes.TESTER})
}]);

export default fetched as React.ComponentClass<{}>;