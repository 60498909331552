import * as React from "react";
import {
  composeValidators,
  fieldsNotEmpty,
} from "../../../helpers/validations/validation";
import {
  InjectedFormProps,
  reduxForm,
} from "redux-form";
import { connect } from "react-redux";
import { postItemInsurance } from "../../../actions/insurance";
import routerModal, { IBackRoute } from "../../../components/routerModal";
import { WithStyles } from "@material-ui/core/styles/withStyles";
import {IInsurance, IInsuranceLocal} from "../../../models/insurance.interface";
import "react-datepicker/dist/react-datepicker.css";
import InsurancesForm from "./InsurancesForm";
import {InsuranceTypes} from "../../../constants/insurance";
import { result, valuesIn, difference, head } from "lodash";


const FORM_NAME = 'InsurancesCreate';

class InsurancesCreate extends React.Component<InjectedFormProps<{}, {}> & {
  postItem: (insurance: IInsuranceLocal, backRoute: string, formName: string) => void,
  usedTypes: string[];
} & WithStyles<any> & IBackRoute> {

  render() {
    const {handleSubmit, postItem, backRoute} = this.props;

    return (
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit((values: IInsuranceLocal) => {
          postItem(values, backRoute, FORM_NAME);
        })}
      >
        <InsurancesForm usedTypes={this.props.usedTypes} backRoute={backRoute}/>
      </form>
    );
  }
}

const formConnected = reduxForm({
  form: FORM_NAME,
  validate: composeValidators(
    fieldsNotEmpty([
      'insuranceCompany',
      'type',
      'value',
      'evidence',
      'validFrom',
      'validTo',
    ]),
    (values) => {
      const error = {validFrom: 'From should not be after To'};

      return (new Date(values.validFrom) >= new Date(values.validTo)) ? error : {};
    },
  )
})(InsurancesCreate);

function mapStateToProps(state) {
  const items = result(state, 'insurance.items.items', []);

  if (items) {
    const usedTypes = items.map((value: IInsurance) => value.type);
    const types = difference(valuesIn(InsuranceTypes), usedTypes ? usedTypes : []);
    const initialValues: any = {};

    if (types.length===1) {
      initialValues.type = head(types);
    }

    return {
      usedTypes,
      ...(initialValues.type && {initialValues})
    };
  }

  return {};
}

const connected = connect(mapStateToProps, {
  postItem: postItemInsurance,
})(formConnected);

export default routerModal(connected);