import { convertorFromSwagger, convertorToSwagger } from '../convertors/email-templates';
import * as FilterActions from '../../constants/filter';
import { extractJSONLD, fetchRequestBody } from '../../helpers/request-helper';
import { getApi, getApiJSONLD } from '../swagger/api-factory';
import {IAnyProps} from "../../interfaces/any-props.interface";

export const getItems = (page, itemsPerPage, show, text = '') => {
  const isShow = show || FilterActions.DEFAULT_FILTER;
  const parameters: IAnyProps = { page };

  parameters.items = itemsPerPage;
  parameters.show = isShow;

  if (text) {
    parameters.question = text;
  }

  return getApiJSONLD()
    .then(api => api.getEmailTemplateCollection(parameters))
    .then(extractJSONLD(convertorFromSwagger));
};

export const getItem = (id) => {
  return getApi()
    .then(api => api.getEmailTemplateItem({ id }))
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};

export const putItem = (model) => {
  return getApi()
    .then(api => api.putEmailTemplateItem({
      id: model.id,
      emailTemplate: convertorToSwagger(model),
    }))
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};
