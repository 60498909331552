import * as React from "react";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import { WithStyles, withStyles } from "@material-ui/core";
import { styles } from "../../../../styles/material/tesrer-card";
import { FieldGeneric } from "../../../../components/forms/FieldGeneric";
import { formValueSelector, InjectedFormProps, reduxForm } from "redux-form";
import Grid from "@material-ui/core/Grid/Grid";
import {
  composeValidators,
  fieldsNotEmpty
} from "../../../../helpers/validations/validation";
import ReduxSelectField from "../../../../components/forms/ReduxSelectField";
import { connect } from "react-redux";
import { ACTIVE } from "../../../../constants/filter";
import { projectsForSchedulingRequest } from "../../../../actions/projects-for-scheduling";
import { testRequest } from "../../../../actions/test";
import projectsSelector from "../../../../selectors/projects-for-scheduling";
import testsSelector from "../../../../selectors/test";
import { dataFetcher } from "../../../../components/dataFetcher";
import { IProject } from "../../../../models/project.interface";
import { ITest } from "../../../../models/test.interface";
import { isChangesExist } from "../../../../helpers/props-checker";
import * as _ from "lodash";
import { IAnyProps } from "../../../../interfaces/any-props.interface";
import { push } from "react-router-redux";
import { TestStatus } from "../../../../constants/test-status";
import TestInfo from "../../../client-tester/test-details/TestInfo";
import { getTestForBookingAction } from "../../../../actions/test";
import { result } from "lodash";
import Tooltip from "@material-ui/core/Tooltip";
import { bookTesterAction } from "../../../../actions/testers";
import TestersBookingConfirmationModal from "../testers/TestersBookingConfirmationModal";
import moment from "moment";

const FORM_NAME = "TestBooking";
const selector = formValueSelector(FORM_NAME);

interface IDispatch {
  loadTest: (id: any) => void;
  push: (url: string) => void;
}

const TestStatusesForBook = [TestStatus.STATUS_SCHEDULED];

interface IProps
  extends WithStyles,
    RouteComponentProps<any>,
    InjectedFormProps,
    IDispatch {
  projects: IProject[];
  tests: ITest[];
  test: ITest | null;
  testItem: ITest | null;
  formValues: {
    project: string;
    test: string;
  };
  getTestForBookingAction: (
    page: number,
    itemsPerPage: number,
    project: number[],
    status: string[]
  ) => void;
  bookTesterAction: (testId: number, testerId: number) => void;
}

interface IState {
  isModalOpen: boolean;
}

class TestBooking extends React.Component<IProps, IState> {
  state = {
    isModalOpen: false
  };

  componentDidMount(): void {
    if (!this.props.testItem && this.props.match.params.test) {
      this.props.loadTest(this.props.match.params.test);
    }
  }

  componentWillReceiveProps(
    nextProps: Readonly<IProps>,
    nextContext: any
  ): void {
    if (
      isChangesExist(
        ["test", "project"],
        nextProps.match.params,
        this.props.match.params
      )
    ) {
      const projectId = _.result(nextProps.match.params, "project", false);
      const testId = _.result(nextProps.match.params, "test", false);

      if (projectId) {
        this.props.change("project", +projectId);
      } else {
        this.props.change("project", "");
      }

      if (testId) {
        this.props.change("test", +testId);
      } else {
        this.props.change("test", "");
      }
    }
  }

  projectHandleChange = (event, value) => {
    const project = _.find(this.props.projects, { id: value }) as IProject;

    this.props.history.push(`/client/schedule/booking/${project.id}`);
    this.props.getTestForBookingAction(
      1,
      1000,
      project && project.id ? [project.id] : [],
      TestStatusesForBook as any
    );
  };

  testHandleChange = (event, value) => {
    const test = _.find(this.props.tests, { id: value }) as ITest;
    const project = this.props.projects.find(project => {
      return !!project.id && +project.id === +test.project;
    }) as IProject;

    this.props.history.push(
      `/client/schedule/booking/${project.id}/${test.id}`
    );

    this.props.loadTest(test.id);
  };

  redirectToTesterBooking = e => {
    e.preventDefault();

    if (
      !this.props.match.params.project ||
      !this.props.match.params.test ||
      !this.validateDates()
    ) {
      return;
    }

    this.props.push(
      `/client/schedule/booking/${this.props.match.params.project}/${this.props.match.params.test}/testers`
    );
  };

  validateDates = () => {
    if (this.props.testItem?.dateTo) {
      const dateTo = moment(this.props.testItem.dateTo).startOf("day");
      const today = moment().startOf("day");

      return dateTo.isSameOrAfter(today);
    }

    return false;
  };

  checkResponseRequiredBy = () => {
    if (this.props.testItem?.dateFrom && this.props.testItem?.dateTo) {
      const dateFrom = moment(this.props.testItem.dateFrom).startOf("day");
      const dateTo = moment(this.props.testItem.dateTo).startOf("day");

      const diffInDays = dateTo.diff(dateFrom, "days") + 1;

      if (this.props.testItem.daysForResponse <= diffInDays) {
        return false;
      }
    }
    return true;
  };

  handleEdit = event => {
    if (!this.props.match.params.test) {
      event.preventDefault();
    }
  };

  toggleModal = () => {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  };

  tooltipText = () => {
    if (!this.validateDates()) {
      return "Service date is less than current date";
    }
    if (this.checkResponseRequiredBy()) {
      return "Days For Response should be more than actual test time";
    }
    return "";
  };

  render() {
    if (!this.props.tests) {
      return <div>Loading...</div>;
    }

    return (
      <Grid container spacing={16}>
        <Grid item xs={12} md={6}>
          <h4 className="main-content-header">Book</h4>
        </Grid>

        <Grid item xs={12} md={6} className="text-right">
          <Link to={"/client/schedule"} className="btn-cancel">
            Cancel
          </Link>

          {this.props.match.params.test && (
            <Link
              to={`/client/schedule/test/${this.props.match.params.test}/edit`}
              onClick={this.handleEdit}
            >
              <button
                className="btn-edit"
                disabled={!this.props.match.params.test}
              >
                Edit
              </button>
            </Link>
          )}
          {!!this.props.testItem && this.props.testItem.preFillTester ? (
            <>
              {this.props.testItem.preFillTester.user &&
              this.props.testItem.preFillTester.user.email ? (
                <button className="btn-continue" onClick={this.toggleModal}>
                  Book
                </button>
              ) : (
                <Tooltip
                  disableFocusListener
                  disableTouchListener
                  title={
                    "Selected Consultant/Tester has been removed from AVORD"
                  }
                >
                  <button className="btn-accept" disabled={true}>
                    Book
                  </button>
                </Tooltip>
              )}
            </>
          ) : (
            <Tooltip
              disableFocusListener
              disableTouchListener
              title={this.tooltipText()}
            >
              <Link to={"#"} onClick={this.redirectToTesterBooking}>
                <button
                  className="btn-accept"
                  disabled={
                    !this.props.match.params.project ||
                    !this.props.match.params.test ||
                    !this.validateDates() ||
                    this.checkResponseRequiredBy()
                  }
                >
                  Search
                </button>
              </Link>
            </Tooltip>
          )}
        </Grid>

        <Grid item xs={12} md={4}>
          <div className="styled-block pb-5">
            <form noValidate autoComplete="off">
              <FieldGeneric
                name="project"
                label="Project name *"
                component={ReduxSelectField}
                margin="normal"
                onChange={this.projectHandleChange}
              >
                {this.props.projects.map(option => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </FieldGeneric>
              <FieldGeneric
                name="test"
                label="Test *"
                component={ReduxSelectField}
                margin="normal"
                onChange={this.testHandleChange}
              >
                {this.props.tests.map(option => (
                  <MenuItem key={option.id} value={option.id}>
                    {`${option.hash}-${option.name}`}
                  </MenuItem>
                ))}
              </FieldGeneric>
            </form>
          </div>
        </Grid>

        <Grid item xs={12} md={8}>
          {!!this.props.testItem && this.props.testItem.id && (
            <TestInfo test={this.props.testItem} />
          )}
        </Grid>
        {this.props.testItem && this.props.testItem.preFillTester && (
          <TestersBookingConfirmationModal
            open={this.state.isModalOpen}
            onClose={this.toggleModal}
            tester={this.props.testItem.preFillTester}
          />
        )}
      </Grid>
    );
  }
}

const mapStateToProps = (state, props: Readonly<IProps>) => {
  const initialValues: IAnyProps = {};

  const projectId = _.result(props.match.params, "project", false);
  const testId = _.result(props.match.params, "test", false);

  if (projectId) {
    initialValues.project = +projectId;
  }

  if (testId) {
    initialValues.test = +testId;
  }

  const formValues: IAnyProps = selector(state, "project", "test");
  const projectObj = projectsSelector.getItemsObject(state);
  const testObj = testsSelector.getItemsObject(state);

  if (!projectObj.loader.isLoaded || !testObj.loader.isLoaded) {
    return { formValues, test: null };
  }

  const test = _.find(testObj.items, { id: formValues.test }) as ITest;
  const testItem = testsSelector.getItemById(
    result(props, "match.params.test", false)
  )(state);

  return {
    formValues,
    test,
    testItem,
    initialValues
  };
};

const mapDispatchToProps = {
  loadTest: testRequest.getItem,
  getTestForBookingAction,
  push,
  bookTesterAction
};

const formConnected = reduxForm({
  form: FORM_NAME,
  validate: composeValidators(fieldsNotEmpty(["project", "test"]))
})(TestBooking);

const routed = withRouter(formConnected);

const fetchedComponent = dataFetcher(routed, [
  {
    key: "projects",
    selector: state => projectsSelector.getItemsObject(state),
    action: () => projectsForSchedulingRequest.getItems(1, 1000, ACTIVE),
    alwaysReceiveFreshData: true
  },
  {
    key: "tests",
    selector: state => testsSelector.getItemsObject(state),
    action: props => {
      const projectId = result(props, "match.params.project", false);
      if (projectId) {
        return getTestForBookingAction(
          1,
          1000,
          [projectId],
          TestStatusesForBook as any
        );
      } else {
        return getTestForBookingAction(1, 1000, [], TestStatusesForBook as any);
      }
    },
    alwaysReceiveFreshData: true
  }
]);

const connectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(fetchedComponent);

export default withStyles(styles)(connectedComponent as any);
