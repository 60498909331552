/** Headers for a pagination */
export const jsonldHeaders = {
  Accept: "application/ld+json"
};
export const jsonHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json"
};

const membersKey = "hydra:member";
const totalItemsKey = "hydra:totalItems";

export const extractJSONLD = converter => response => {
  const data = fetchRequestBody(response);
  const membersRaw = data[membersKey];
  const items = membersRaw.map(converter);
  const totalItems = data[totalItemsKey];

  return {
    items,
    totalItems
  };
};

export const fetchRequestBody = data => {
  if (data === undefined || data === null) {
    return;
  }

  return data.body;
};

export const dummyItemsPagination = data => {
  return { items: data, totalItems: 0 };
};
