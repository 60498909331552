import React from "react";
import selector from "../../../selectors/time-expenses";
import { connect } from "react-redux";
import { getYearAndWeekNumber } from "../../../helpers/timesheets-expenses";
import { SelectedApprovedProjectWeek } from "../../../types/timesheets-and-expenses";

type AdminTimeSheetsComponentProps = {
  selectedApprovedProjectWeeks: SelectedApprovedProjectWeek[];
};

const getPoNumbersText = (poNumbers: string[]): string => {
  const prefix = poNumbers.length === 1 ? "PO Number: " : "PO Numbers: ";
  return poNumbers.length > 0 ? `${prefix}${poNumbers.join(", ")}` : "";
};

const ApprovedListForInvoice = (props: AdminTimeSheetsComponentProps) => {
  const { selectedApprovedProjectWeeks = [] } = props;
  if (!selectedApprovedProjectWeeks.length) {
    return null;
  }
  const selectedList = selectedApprovedProjectWeeks.map(
    (item: SelectedApprovedProjectWeek) => {
      const { weekNumber } = getYearAndWeekNumber(item.weekNumber.toString());
      const poNumbersText = getPoNumbersText(item.poNumbers);
      const projectName = `#${weekNumber} ${item.name} - ${item.consultant}. ${poNumbersText}`;
      return <li key={item.id}>{projectName}</li>;
    }
  );

  return <ul style={{ color: "#3a3a3a" }}>{selectedList}</ul>;
};

function mapStateToProps(state) {
  return {
    selectedApprovedProjectWeeks: selector.getSelectedApprovedProjectWeeks(
      state
    )
  };
}

const connected = connect(mapStateToProps)(ApprovedListForInvoice);

export default connected;
