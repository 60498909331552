import {createSelector} from "reselect";
import {ICalendarReducer} from "../reducers/calendar";

export const getCalendar: (any) => ICalendarReducer = state => state.calendar;

export const getCalendarYear = createSelector(
  getCalendar,
  state => state.year
);

export const getCalendarMonth = createSelector(
  getCalendar,
  state => state.month
);

export const getCalendarViewType = createSelector(
  getCalendar,
  state => state.view
);

export const getCalendarEvents = createSelector(
  getCalendar,
  state => state.events
);
