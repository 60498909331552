import * as React from "react";
import {connect} from "react-redux";
import {Radio, FormControlLabel} from "@material-ui/core";
import selector from "../../../../selectors/users";
import {Field, formValueSelector, InjectedFormProps, reduxForm} from "redux-form";
import ReduxRadioButton from "../../../../components/forms/ReduxRadioButton";
import * as FilterTypes from "../../../../constants/filter";
import {bindActionCreators} from "redux";
import ReduxTextField from "../../../../components/forms/ReduxTextField";
import {FieldGeneric} from "../../../../components/forms/FieldGeneric";
import {usersFilter} from "../../../../actions/users";
import {isChangesExist} from "../../../../helpers/props-checker";

const FORM_NAME = "UsersSearchForm";
const selectorForm = formValueSelector(FORM_NAME);

class UsersSearchForm extends React.Component<InjectedFormProps<{}, {}> & {
  submitFilterChanges: (...any) => void,
  valuesData: { active: string, text: string },
}, any> {
  componentWillReceiveProps(nextProps) {
    if (isChangesExist(["valuesData"], nextProps, this.props)) {
      this.props.submitFilterChanges(nextProps.valuesData.active, nextProps.valuesData.text);
    }
  }

  render() {
    const {handleSubmit} = this.props;

    return (
      <form onSubmit={handleSubmit((values: { active: string, text: string }) => {
        this.props.submitFilterChanges(values.active, values.text);
      })}>
        <div className="table-responsive styled-table styled-block">
          <div className="row">
            <div className="col-md-12">
              <Field
                name="text"
                label="Find"
                margin="normal"
                fullWidth
                component={ReduxTextField}
              />
            </div>
            <div className="col-md-8">
              <FieldGeneric
                name="active"
                margin="normal"
                fullWidth
                inline={true}
                component={ReduxRadioButton}
              >
                <FormControlLabel
                  value={FilterTypes.ACTIVE}
                  control={<Radio/>}
                  label="Active"/>
                <FormControlLabel
                  value={FilterTypes.ARCHIVED}
                  control={<Radio/>}
                  label="Archived"/>
                <FormControlLabel
                  value={FilterTypes.ALL}
                  control={<Radio/>}
                  label="All"/>
              </FieldGeneric>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    valuesData: {...selectorForm(state, "text", "active")},
    initialValues: {
      text: selector.getFilterTextFields(state),
      active: selector.getFilterActivityStatus(state)
    }
  };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    submitFilterChanges: usersFilter.submitFilterChanges
  }, dispatch);
};

const connectForm = reduxForm({
  form: FORM_NAME
})(UsersSearchForm);

export default connect(mapStateToProps, mapDispatchToProps)(connectForm);
