import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Header from "../guest/Header";
import Footer from "../guest/Footer";
import Dashboard from "./dashboard/Dashboard";
import { CONSULTANT_DASHBOARD_URL, CONSULTANT_URL } from "constants/pages";

const Consultant = () => (
  <div>
    <Header />
    <div className="page-content">
      <div className="container-fluid">
        <div className="row">
          <div className="content">
            <Switch>
              <Redirect
                exact
                from={CONSULTANT_URL}
                to={CONSULTANT_DASHBOARD_URL}
              />
              <Route
                path={CONSULTANT_DASHBOARD_URL}
                component={Dashboard}
              />
            </Switch>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Consultant;
