import * as Types from '../constants/action-type';
import {
    createFilterAction,
    createLoaderAction,
    createRequestAction
} from '../helpers/action-helper';
import {setRequestInProcess} from "./request";
import {getItems} from "../api/requests/locations";
import * as FilterActions from "../constants/filter";
import {handleHttpErrors} from "../helpers/errors/http-error-handler";

export const locationsFilter = createFilterAction(Types.LOCATIONS);
export const locationsLoader = createLoaderAction(Types.LOCATIONS);
export const locationsRequest = createRequestAction(Types.LOCATIONS);

export const getAllLocations = () => async dispatch => {
    dispatch(setRequestInProcess(true, 'getAllLocations'));

    dispatch(locationsLoader.setIsNotLoaded());

    try {
        const {items, totalItems} = await getItems(1, 9999, FilterActions.DEFAULT_FILTER);
        dispatch(locationsLoader.setPaginatioData(items, totalItems, totalItems, 1));
    } catch (error) {
        handleHttpErrors(error, dispatch);
    }

    dispatch(setRequestInProcess(false, 'getAllLocations'));
};
