import {
  //ConsultantCreateFormValues,
  FilterParamsType,
  FormValuesType,
  ProjectRepeater,
  RequestDataType,
  ResponseDataType,
  SelectProjectType
} from "../../types/consultant";
import { extractId } from "../../helpers/converter-helper";
import { SelectOptionType } from "../../types";

export const convertorFromSwagger = (
  model: ResponseDataType
): ResponseDataType => {
  const { consultantProfile } = model;
  return {
    ...model,
    consultantProfile: extractId(consultantProfile as string)
  };
};

export const convertorToSwagger = (model: FilterParamsType) => {
  let params: RequestDataType = {
    page: model.pageNumber as number,
    items: model.itemsPerPage as number,
    userFilter: "Consultant"
  };

  if (!!model?.nameEmailPhone) {
    params = { ...params, nameEmailPhone: model.nameEmailPhone };
  }
  if (model?.consultantProject && !!model.consultantProject.length) {
    params = { ...params, consultantProject: model.consultantProject.join() };
  }
  if (model?.dateFrom) {
    params = { ...params, createdAtAfter: model.dateFrom };
  }
  if (model?.dateTo) {
    params = { ...params, createdAtBefore: model.dateTo };
  }

  if (model?.show) {
    params = { ...params, show: model.show };
  }

  return params;
};
export const convertorAssignedProjectsFromSwagger = (
  model: SelectProjectType
): SelectOptionType => {
  const { id, name, companyName } = model;
  return {
    id,
    name: `${companyName} - ${name}`
  };
};
export const convertorCreateConsultantToSwagger = (model: FormValuesType) => {
  const { projects } = model;
  if (projects && !!projects.length) {
    const updatedProjects = projects.map(item => ({
      project: `/api/projects/${item.project}`,
      projectRate: item.projectRate,
      daysInSow: typeof item.daysInSow === "number" ? item.daysInSow : null
    }));
    return { ...model, projects: updatedProjects };
  }
  return model;
};

export const convertorReDeActivateConsultantToSwagger = model => {
  const { id, user } = model;
  return {
    id,
    user: {
      ...user,
      consultantProfile: `/api/consultant_profiles/${user.consultantProfile}`
    }
  };
};
