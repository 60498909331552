import {ITesterProfileGet} from "../models/tester-profile.interface";

export const EMPTY_TESTER_ID: number = -1;

export const EMPTY_TESTER: ITesterProfileGet = {
  id: EMPTY_TESTER_ID,
  currency: "none",
  rate: 0,
  testerFirstName: "",
  testerLastName: "",
  insurancesData: {},
  rightsToWorkCountries: [""],
  isFavorite: true,
  isOwnedByCompany: false,
  isXeroConnected: false
};