import React from 'react';
import {connect} from 'react-redux';
import Modal from "@material-ui/core/Modal";
import Typography from "@material-ui/core/Typography";
import {withStyles} from '@material-ui/core/styles';
import {thankYouForRegistrationModalClose} from '../../actions';
import Grid from "@material-ui/core/Grid/Grid";
import {reduxForm} from 'redux-form';
import Button from "@material-ui/core/Button";
import {getIsOpenThankYouForRegistrationModal} from "../../selectors/modal";
import {defaultStyleWidth} from '../../styles/material/popup-style';

const FORM_NAME = 'ThankYouForRegistrationModal';

class ThankYouForRegistrationModal extends React.Component {
  handleClose = () => {
    this.props.thankYouForRegistrationModalClose();
  };

  render() {
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.props.isOpenModal}
        onClose={this.handleClose}
      >
        <div className={this.props.classes.paper}>
          <form noValidate autoComplete="off" >
          <Grid container spacing={24}>
            <Grid item md={12} sm={6}>
              <Typography variant="headline">
                <span>
                Thank you for registering with AVORD
                </span>
              </Typography>
              <hr/>
              <Typography variant="subheading">
                We have sent you an email containing your login details.
              </Typography>
            </Grid>
            <Grid item md={12} className="text-center">
              <Button
                variant="contained"
                color="default"
                onClick={this.props.thankYouForRegistrationModalClose}>
                OK
              </Button>
            </Grid>
          </Grid>
          </form>
        </div>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    isOpenModal: getIsOpenThankYouForRegistrationModal(state)
  };
}

const connectedComponent = connect(mapStateToProps, {thankYouForRegistrationModalClose})(reduxForm({
  form: FORM_NAME,
})(ThankYouForRegistrationModal));

export default withStyles(defaultStyleWidth(600))(connectedComponent);
