import * as React from 'react';
import { WithStyles, withStyles } from "@material-ui/core";
import Card from '@material-ui/core/Card/Card';
import CardContent from '@material-ui/core/CardContent/CardContent';
import Button from '@material-ui/core/Button/Button';
import Typography from '@material-ui/core/Typography/Typography';
import { styles } from '../../../styles/material/tesrer-card';
import { IDBSCertificate } from "../../../models/dbs-certificate.interface";
import { Link } from "react-router-dom";
import {STATUS_VALIDATION_PASSED} from "../../../constants/dbs-certificate-statuses"
import DBSCertificatesTable from "./DBSCertificatesTable";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface IOwnProps {
  dbsCertificates: IDBSCertificate[];
}

interface IProps {
  getProps: (props: IDBSCertificate[]) => void;
  [key: string]: any;
}

interface IState {
  certificateItems: IDBSCertificate[];
}

class DBSCertificatesCard extends React.Component<IProps & WithStyles & IOwnProps & RouteComponentProps<any>, IState> {
  state = {
    certificateItems: [],
  }

  baseUrl = this.props.match.path;

  getProps = (props: IDBSCertificate[]) => {
    if (props) {
      this.setState({ certificateItems: [...props] });
    }

    this.setState({ certificateItems: [...props] });
  }

  highlight = () => {
    const validCertificates = this.state.certificateItems.filter(
        (item: IDBSCertificate) =>item.status === STATUS_VALIDATION_PASSED
    );

    if (!validCertificates.length) {
      return " box-shadow-danger";
    }

    return "";
  };

  render() {
    return (
      <div className={this.highlight()}>
        <Card className={this.props.classes.card}>
          <div className={this.props.classes.details}>
            <CardContent className={this.props.classes.content}>
              <Typography variant="headline" className={this.props.classes.header}>
                <span>
                  DBS Basic Financial Check
                </span>
                <span className={this.props.classes.rightAlign}>
                  <a href="https://www.personnelchecks.co.uk/avord/" target="_blank">
                    <Button size="small" color="primary">
                      I want to initiate new check
                    </Button>
                  </a>
                  <Link to={`${this.baseUrl}/create-dbs-certificate`}>
                    <Button size="small" color="primary">
                      I have a certificate
                    </Button>
                  </Link>
                </span>
              </Typography>
              <hr />
              <DBSCertificatesTable getProps={this.getProps} />
            </CardContent>
          </div>
        </Card>
      </div>
    );
  }
}

const styled = withStyles(styles as any)(DBSCertificatesCard);

export default withRouter(styled as any);
