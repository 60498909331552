import React from "react";
import Button from "@material-ui/core/Button/Button";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import TimeLogCategoriesList from "./TimeLogCategoriesList";
import SearchForm from "./SearchForm";
import TimeLogCategoriesCreate from "./TimeLogCategoriesCreate";
import { withRouter } from "react-router-dom";
import { getCreateLink } from "../../../../helpers/router-helper";

interface TimeLogCategoriesProps extends RouteComponentProps {}

const TimeLogCategories = (props: TimeLogCategoriesProps) => {
  const baseUrl = props.match.path;
  const history = props.history;

  const openCreateModal = () => {
    history.push(getCreateLink(baseUrl));
  };

  return (
    <div>
      <div className="crud-sections-header">
        <h4>Timesheet Categories</h4>
        <Button
          variant="contained"
          color="default"
          type="button"
          onClick={openCreateModal}
        >
          New Category
        </Button>
      </div>
      <hr />
      <Switch>
        <Route
          path={getCreateLink(baseUrl)}
          render={() => <TimeLogCategoriesCreate />}
        />
        <Route
          path={baseUrl + "/:id"}
          render={() => <TimeLogCategoriesCreate />}
        />
      </Switch>
      <SearchForm />
      <TimeLogCategoriesList baseUrl={baseUrl} />
    </div>
  );
};

export default withRouter(TimeLogCategories);
