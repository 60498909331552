import React from 'react';
import { composeValidators, fieldsNotEmpty, fieldMaxLength } from "../../../../helpers/validations/validation";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import { sectorRequest } from "../../../../actions/sector";
import { SectorForm } from "./SectorForm";
import { withStyles } from "@material-ui/core/styles/index";
import { withRouter } from 'react-router-dom'
import { defaultStyleWidth } from '../../../../styles/material/popup-style';
import routerModal from '../../../../components/routerModal';

const FORM_NAME = 'SectorCreate';

class SectorCreate extends React.Component {
  render() {
    const { handleSubmit, postData, backRoute } = this.props;

    return (
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit((values) => {
          postData(values, FORM_NAME, backRoute);
        })}
      >
        <SectorForm backRoute={backRoute}/>
      </form>
    );
  }
}

const formConnected = reduxForm({
  form: FORM_NAME,
  validate: composeValidators(
    fieldsNotEmpty(['name',]),
    fieldMaxLength(['name'], 100),
  )
})(SectorCreate);

const connected = connect(undefined, {
  postData: sectorRequest.postItem,
})(formConnected);

export default routerModal(withRouter(withStyles(defaultStyleWidth(400))(connected)));
