import * as React from "react";
import { Modal } from "@material-ui/core";

type Props = {
  onDelete: () => void;
  open: boolean;
  onClose: () => void;
};

class DocumentDeleteModal extends React.Component<Props> {
  onDelete = () => {
    this.props.onDelete();
  }

  render() {
    const {onClose, open} = this.props;

    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={onClose}
        className="d-flex overflow-auto"
      >
        <div className="styled-modal sm">
          <p>Delete document confirmation</p>
          <h4>Are you sure?</h4>
          <hr />
          <p>Are you sure you want to delete this document?</p>
          <div className="text-right">
            <button className="btn-cancel" onClick={onClose}>No</button>
            <button type="button" className="btn-save" onClick={this.onDelete}>
              Yes
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

export default DocumentDeleteModal;
