import * as Types from "../constants/action-type";
import {
  createCrudAction,
  createRequestAction
} from "../helpers/action-helper";
import { setRequestInProcess } from "./request";
import { handleHttpErrors } from "../helpers/errors/http-error-handler";
import { putItem, postItem } from "../api/requests/risk-treatments";
import { getRequestName } from "../helpers/action-request-helper";
import { openSnackbar } from "./snackbar";
import { push } from "react-router-redux";
import { reset } from "redux-form";

export const riskTreatmentsRequest = createRequestAction(Types.RISK_TREATMENTS);

const crudType = Types.RISK_TREATMENTS;

export const putItemAction = (model, formName, backRoute) => async dispatch => {
  dispatch(setRequestInProcess(true, getRequestName(crudType, "putItem")));
  const { action, status, comment, residualRiskRating, id } = model;

  let data;

  try {
    data = await putItem(id, { action, status, comment, residualRiskRating });

    const { risk, ...treatment } = data;

    dispatch(openSnackbar("Updated", 4000));
    dispatch(createCrudAction(Types.RISKS).replaceItem({ ...risk, treatment }));
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(reset(formName));
  dispatch(setRequestInProcess(false, getRequestName(crudType, "putItem")));
  dispatch(push(backRoute));
};

export const postItemAction = (
  model,
  formName,
  backRoute
) => async dispatch => {
  dispatch(setRequestInProcess(true, getRequestName(crudType, "postItem")));

  let data;

  try {
    data = await postItem(model);

    const { risk, ...treatment } = data;

    dispatch(openSnackbar("Created", 4000));
    dispatch(createCrudAction(Types.RISKS).replaceItem({ ...risk, treatment }));
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(reset(formName));
  dispatch(setRequestInProcess(false, getRequestName(crudType, "postItem")));
  dispatch(push(backRoute));
};
