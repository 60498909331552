export type ROLE =
  | "ROLE_SUPER_ADMIN"
  | "ROLE_ADMIN"
  | "ROLE_OWNER"
  | "ROLE_PLANNER"
  | "ROLE_VIEWER"
  | "ROLE_GUEST"
  | "ROLE_TESTER"
  | "ROLE_INTERNAL"
  | "ROLE_EXTERNAL"
  | "ROLE_EMPLOYEE"
  | "ROLE_MANAGER"
  | "ROLE_CONSULTANT"
  | "ROLE_PROJECT_CONSULTANT"
  | "ROLE_ACCOUNT_MANAGER";

// ADMINS
export const ROLE_SUPER_ADMIN = "ROLE_SUPER_ADMIN" as ROLE;
export const ROLE_ADMIN = "ROLE_ADMIN" as ROLE;
export const ROLE_ACCOUNT_MANAGER = "ROLE_ACCOUNT_MANAGER";

// CLIENTS
export const ROLE_OWNER = "ROLE_OWNER" as ROLE;
export const ROLE_PLANNER = "ROLE_PLANNER" as ROLE;
export const ROLE_VIEWER = "ROLE_VIEWER" as ROLE;

// TESTERS
export const ROLE_TESTER = "ROLE_TESTER" as ROLE;
export const ROLE_INTERNAL = "ROLE_INTERNAL" as ROLE;
export const ROLE_EXTERNAL = "ROLE_EXTERNAL" as ROLE;
export const ROLE_EMPLOYEE = "ROLE_EMPLOYEE" as ROLE;
export const ROLE_MANAGER = "ROLE_MANAGER" as ROLE;

// GUESTS
export const ROLE_GUEST = "ROLE_GUEST" as ROLE;

// CONSULTANT
export const ROLE_CONSULTANT = "ROLE_CONSULTANT";
export const ROLE_PROJECT_CONSULTANT = "ROLE_PROJECT_CONSULTANT";
