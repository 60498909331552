import {IConverterFromSwagger, IConverterToSwagger} from "./converter.interface";

export const converterFromSwagger: IConverterFromSwagger<any, any> = modelIn => {
  const model = {
    ...modelIn,
  };

  if (!!modelIn.createdAt) {
    model.createdAt = modelIn.createdAt;
  }

  if (!!modelIn.dateAnswered) {
    model.dateAnswered = modelIn.dateAnswered;
  }

  return model;
};

export const converterToSwagger: IConverterToSwagger<any, any> = model => {
  return {
    ...model,
  };
};
