import { extractJSONLD, fetchRequestBody } from "../../helpers/request-helper";
import { getApiJSONLD } from "../swagger/api-factory";
import {
  GetTimeLogCategoriesModel,
  TimeLogCategoryType
} from "../../types/timesheets-and-expenses";
import {
  convertGetTimeLogCategoriesToSwagger,
  convertGetTimeLogCategoriesFromSwagger
} from "../convertors/time-and-expenses";

export const getItems = async (
  params: GetTimeLogCategoriesModel
): Promise<{ items: TimeLogCategoryType[]; totalItems: number }> => {
  return getApiJSONLD()
    .then(api =>
      api.getTimesheetTimeCategories(
        convertGetTimeLogCategoriesToSwagger(params)
      )
    )
    .then(extractJSONLD(convertGetTimeLogCategoriesFromSwagger));
};

export const createItem = async (params: Pick<TimeLogCategoryType, "name">) => {
  return getApiJSONLD()
    .then(api => api.addTimesheetTimeCategoriesCollection({ name: params }))
    .then(fetchRequestBody);
};
export const updateItem = async ({
  id,
  name
}: {
  id: number | string;
  name: string;
}) => {
  return getApiJSONLD()
    .then(api => api.updateTimesheetTimeCategoryById({ id, name: { name } }))
    .then(fetchRequestBody);
};

export const archiveItem = async (id: number) => {
  return getApiJSONLD()
    .then(api => api.deleteTimesheetTimeCategoryById({ id }))
    .then(fetchRequestBody);
};
export const unArchiveItem = async (id: number) => {
  return getApiJSONLD()
    .then(api => api.unarchiveTimesheetTimeCategoryById({ id, category: {} }))
    .then(fetchRequestBody);
};

export const getCategoryById = async (id: number) => {
  return getApiJSONLD()
    .then(api => api.getTimeCategoryById({ id }))
    .then(fetchRequestBody);
};

export const getCategoryNameById = async (
  id: number
): Promise<{ name: string }> => {
  try {
    const category = await getCategoryById(id);
    return { name: category?.name || "" };
  } catch (error) {
    throw new Error(error?.message || "Can`t get category data");
  }
};
