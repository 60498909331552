import {getApiJSONLD} from "../swagger/api-factory";
import {fetchRequestBody} from "../../helpers/request-helper";


export const getLastRisksRequest = async (parameters) => {
  return await getApiJSONLD()
    .then(api => api.lastRisksRiskCollection(parameters))
    .then(fetchRequestBody)
};

export const getLastReportsRequest = (parameters) => {
  return getApiJSONLD()
    .then(api => api.lastReportsTestCollection(parameters))
    .then(fetchRequestBody)
};

export const getLastMessageRequest = (parameters) => {
  return getApiJSONLD()
    .then(api => api.lastMessageMessageCollection(parameters))
    .then(fetchRequestBody)
};

export const getLastQuestionRequest = (parameters = {}) => {
  return getApiJSONLD()
    .then(api => api.lastQuestionQuestionCollection(parameters))
    .then(fetchRequestBody)
};

export const getTestsChartDataRequest = (parameters) => {
  return getApiJSONLD()
    .then(api => api.getApiChartsTests(parameters))
    .then(fetchRequestBody)
};

export const getProjectCostChartDataRequest = (parameters) => {
  return getApiJSONLD()
    .then(api => api.getApiChartsProjectsCost(parameters))
    .then(fetchRequestBody)
};
