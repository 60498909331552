import { setRequestInProcess } from "./request";
import { getRequestName } from "helpers/action-request-helper";
import * as Types from "constants/action-type";
import { createLoaderAction, createCrudAction } from "helpers/action-helper";
import { handleHttpErrors } from "helpers/errors/http-error-handler";
import {
  getItems,
  createItem,
  updateItem,
  deleteItem,
  archiveItem,
  unarchiveItem
} from "api/requests/consultants";
import {
  FormValuesType,
  FilterParamsType,
  ResponseDataType
} from "types/consultant";
import { openSnackbar } from "./snackbar";
import { MILLISECONDS_FOR_SHOWING_MESSAGE } from "./const";
import { Dispatch } from "redux";
import { ALL, FILTER_TYPE } from "constants/filter";

export const consultantsLoader = createLoaderAction(Types.CONSULTANTS);
export const consultantsRequest = createCrudAction(Types.CONSULTANTS);

export const getConsultantsRequestsAction = (
  params: FilterParamsType
) => async (dispatch: Dispatch) => {
  const itemsPerPage = params?.itemsPerPage || 25;
  const pageNumber = params?.pageNumber || 1;

  dispatch(
    setRequestInProcess(
      true,
      getRequestName(Types.CONSULTANTS, "getConsultantsRequestsAction")
    )
  );

  dispatch(consultantsLoader.setIsNotLoaded());

  try {
    const { items: data, totalItems } = await getItems(params);

    dispatch(
      consultantsLoader.setPaginatioData(
        data,
        itemsPerPage,
        totalItems,
        pageNumber
      )
    );
  } catch (error) {
    handleHttpErrors(error, dispatch);
  } finally {
    dispatch(
      setRequestInProcess(
        false,
        getRequestName(Types.CONSULTANTS, "getConsultantsRequestsAction")
      )
    );
  }
};

export const createNewConsultantAction = (
  values: FormValuesType,
  handleGoBack: () => void
) => async (dispatch: Dispatch) => {
  dispatch(
    setRequestInProcess(
      true,
      getRequestName(Types.CONSULTANTS, "createNewConsultantAction")
    )
  );

  try {
    await createItem(values);
    handleGoBack();
    dispatch(
      openSnackbar(
        "New consultant has been created",
        MILLISECONDS_FOR_SHOWING_MESSAGE
      )
    );
    handleGoBack();
  } catch (error) {
    handleHttpErrors(error, dispatch);
  } finally {
    dispatch(
      setRequestInProcess(
        false,
        getRequestName(Types.CONSULTANTS, "createNewConsultantAction")
      )
    );
  }
};

export const updateConsultantAction = (
  values: FormValuesType,
  handleGoBack: () => void
) => async (dispatch: Dispatch) => {
  dispatch(
    setRequestInProcess(
      true,
      getRequestName(Types.CONSULTANTS, "updateConsultantAction")
    )
  );

  try {
    const { id, ...rest } = values;
    await updateItem({ id: id as string, consultant: rest });
    dispatch(
      openSnackbar(
        "Consultant has been updated",
        MILLISECONDS_FOR_SHOWING_MESSAGE
      )
    );
    handleGoBack();
  } catch (error) {
    handleHttpErrors(error, dispatch);
  } finally {
    dispatch(
      setRequestInProcess(
        false,
        getRequestName(Types.CONSULTANTS, "updateConsultantAction")
      )
    );
  }
};

export const deleteConsultantAction = (id: number, name: string) => async (
  dispatch: Dispatch
) => {
  dispatch(
    setRequestInProcess(
      true,
      getRequestName(Types.CONSULTANTS, "deleteConsultantAction")
    )
  );
  try {
    await deleteItem({ id });
    dispatch(consultantsRequest.removeItem({ id }));
    dispatch(
      openSnackbar(
        `Consultant ${name} has been deleted`,
        MILLISECONDS_FOR_SHOWING_MESSAGE
      )
    );
  } catch (error) {
    handleHttpErrors(error, dispatch);
  } finally {
    dispatch(
      setRequestInProcess(
        false,
        getRequestName(Types.CONSULTANTS, "deleteConsultantAction")
      )
    );
  }
};
export const archiveConsultantAction = (
  item: ResponseDataType,
  filter?: FILTER_TYPE
) => async (dispatch: Dispatch) => {
  dispatch(
    setRequestInProcess(
      true,
      getRequestName(Types.CONSULTANTS, "archiveConsultantAction")
    )
  );
  try {
    const user = await archiveItem({ id: item.id, user: item });
    if (filter === ALL) {
      dispatch(consultantsRequest.replaceItem(user));
    } else {
      dispatch(consultantsRequest.removeItem({ id: item.id }));
    }
    dispatch(
      openSnackbar(
        `Consultant ${item.firstName} ${item.lastName} has been deactivated`,
        MILLISECONDS_FOR_SHOWING_MESSAGE
      )
    );
  } catch (error) {
    handleHttpErrors(error, dispatch);
  } finally {
    dispatch(
      setRequestInProcess(
        false,
        getRequestName(Types.CONSULTANTS, "archiveConsultantAction")
      )
    );
  }
};

export const unArchiveConsultantAction = (
  item: ResponseDataType,
  filter?: FILTER_TYPE
) => async (dispatch: Dispatch) => {
  dispatch(
    setRequestInProcess(
      true,
      getRequestName(Types.CONSULTANTS, "unArchiveConsultantAction")
    )
  );
  try {
    const user = await unarchiveItem({ id: item.id, user: item });
    if (filter === ALL) {
      dispatch(consultantsRequest.replaceItem(user));
    } else {
      dispatch(consultantsRequest.removeItem({ id: item.id }));
    }
    dispatch(
      openSnackbar(
        `Consultant ${item.firstName} ${item.lastName} has been activated`,
        MILLISECONDS_FOR_SHOWING_MESSAGE
      )
    );
  } catch (error) {
    handleHttpErrors(error, dispatch);
  } finally {
    dispatch(
      setRequestInProcess(
        false,
        getRequestName(Types.CONSULTANTS, "unArchiveConsultantAction")
      )
    );
  }
};
