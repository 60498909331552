import React from 'react';
import {Link, Route, Switch, withRouter} from "react-router-dom";
import FinanceList from "./FinanceList";
import FinanceUpdate from "./FinanceUpdate";
import Button from "@material-ui/core/Button/Button";


class Finance extends React.Component {
  render() {
    const baseUrl = this.props.match.path;

    return (
      <div>
        <div className="crud-sections-header">
          <h4>Finance</h4>
          {this.props.location.pathname !== `${baseUrl}/edit` && <Link to={`${baseUrl}/edit`} color="default" className="text-center">
            <Button variant="contained">
              Edit
            </Button>
          </Link>}
        </div>
        <hr/>
        <Switch>
          <Route exact path={baseUrl} render={() => <FinanceList baseUrl={baseUrl}/>}/>
          <Route path={baseUrl + '/edit'} render={() => <FinanceUpdate backRoute={baseUrl}/>}/>
        </Switch>
      </div>
    );

  }
}

export default withRouter(Finance);