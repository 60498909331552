const convertModel = (model) => {
  return {
    ...model,
    position: +model.position,
  };
};

export const convertorFromSwagger = model => {
  if (Array.isArray(model)) {
    return [...model];
  }

  return convertModel(model);
};

export const convertorToSwagger = model => {
  if (Array.isArray(model)) {
    return [...model];
  }

  return convertModel(model);
};
