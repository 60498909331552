import React from "react";
import Paper from "@material-ui/core/Paper/Paper";
import Table from "@material-ui/core/Table/Table";
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableBody from "@material-ui/core/TableBody/TableBody";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Icon from "@material-ui/core/Icon/Icon";
import { connect } from "react-redux";
import {
  contentManagementRequest,
  contentManagementLoader
} from "../../../../actions/content-management";
import selector from "../../../../selectors/content-management";
import { Link } from "react-router-dom";
import { getEditLink } from "../../../../helpers/router-helper";
import PropTypes from "prop-types";
import EnhancedTableHead from "../../../../components/table/EnhancedTableHead";

class ContentManagementList extends React.Component {
  loadItems = props => {
    props.loadItems(
      props.pageNumber,
      props.itemsPerPage,
      props.showStatus,
      props.filterText
    );
  };

  componentDidMount() {
    this.loadItems(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.pageNumber !== this.props.pageNumber ||
      nextProps.itemsPerPage !== this.props.itemsPerPage ||
      nextProps.showStatus !== this.props.showStatus ||
      nextProps.filterText !== this.props.filterText
    ) {
      this.loadItems(nextProps);
    }
  }

  render() {
    if (!this.props.isLoaded) {
      return <div>Loading</div>;
    }

    if (!this.props.data.length) {
      return <div>There are no items!</div>;
    }

    return (
      <Paper style={{ overflowX: "auto", width: "100%" }} className="mb-4">
        <Table>
          <EnhancedTableHead columns={["Key", "Name", "Edit"]} />
          <TableBody>
            {this.props.data.map(item => {
              return (
                <TableRow key={item.id}>
                  <TableCell>{item.contentKey}</TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>
                    <IconButton
                      variant="fab"
                      mini="true"
                      component={Link}
                      to={getEditLink(this.props.baseUrl, item.id)}
                    >
                      <Icon>edit</Icon>
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

ContentManagementList.propTypes = {
  baseUrl: PropTypes.string.isRequired
};

export default connect(
  state => {
    return {
      data: selector.getItems(state),
      isLoaded: selector.getIsLoaded(state),
      itemsPerPage: selector.getItemsPerPage(state),
      pageNumber: selector.getPageNumber(state),
      showStatus: selector.getFilterActivityStatus(state),
      totalItems: selector.getTotalItems(state),
      filterText: selector.getFilterTextFields(state)
    };
  },
  {
    loadItems: contentManagementRequest.getItems,
    setItemsPerPage: contentManagementLoader.setItemsPerPage,
    setPageNumber: contentManagementLoader.setPageNumber,
    archiveItem: contentManagementRequest.archiveItem
  }
)(ContentManagementList);
