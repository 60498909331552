import { setRequestInProcess } from "./request";
import { getRequestName } from "../helpers/action-request-helper";
import * as Types from "../constants/action-type";
import { createLoaderAction, createCrudAction } from "../helpers/action-helper";
import { handleHttpErrors } from "../helpers/errors/http-error-handler";
import {
  getItems,
  createItem,
  updateItem,
  archiveItem,
  unArchiveItem
} from "../api/requests/timesheets-expenses-types";

import { openSnackbar } from "./snackbar";
import { MILLISECONDS_FOR_SHOWING_MESSAGE } from "./const";
import { Dispatch } from "redux";
import { ALL, FILTER_TYPE } from "../constants/filter";
import {
  GetTimeLogCategoriesModel,
  TimeLogCategoryType
} from "../types/timesheets-and-expenses";
import { getDeletedAtDate } from "../helpers/date-formatter";

export const TimesheetExpensesTypesLoader = createLoaderAction(
  Types.TIMESHEET_EXPENSES_TYPES
);
export const TimesheetExpensesTypesRequest = createCrudAction(
  Types.TIMESHEET_EXPENSES_TYPES
);

export const getTimesheetExpensesTypesAction = (
  params: GetTimeLogCategoriesModel
) => async dispatch => {
  dispatch(
    setRequestInProcess(
      true,
      getRequestName(
        Types.TIMESHEET_EXPENSES_TYPES,
        "getTimesheetExpensesTypesAction"
      )
    )
  );
  const itemsPerPage = params?.itemsPerPage || 25;
  const pageNumber = params?.pageNumber || 1;

  dispatch(TimesheetExpensesTypesLoader.setIsNotLoaded());

  try {
    const { items, totalItems } = await getItems(params);
    dispatch(
      TimesheetExpensesTypesLoader.setPaginatioData(
        items,
        itemsPerPage,
        totalItems,
        pageNumber
      )
    );
  } catch (error) {
    handleHttpErrors(error, dispatch);
  } finally {
    dispatch(
      setRequestInProcess(
        false,
        getRequestName(
          Types.TIMESHEET_EXPENSES_TYPES,
          "getTimesheetExpensesTypesAction"
        )
      )
    );
  }
};

export const createTimesheetExpensesTypeAction = (
  values: Pick<TimeLogCategoryType, "name">,
  callback: () => void
) => async dispatch => {
  dispatch(
    setRequestInProcess(
      true,
      getRequestName(
        Types.TIMESHEET_EXPENSES_TYPES,
        "createTimesheetExpensesTypeAction"
      )
    )
  );

  try {
    await createItem(values);
    callback();
    dispatch(
      openSnackbar(
        "New expenses type has been created",
        MILLISECONDS_FOR_SHOWING_MESSAGE
      )
    );
  } catch (error) {
    handleHttpErrors(error, dispatch);
  } finally {
    dispatch(
      setRequestInProcess(
        false,
        getRequestName(
          Types.TIMESHEET_EXPENSES_TYPES,
          "createTimesheetExpensesTypeAction"
        )
      )
    );
  }
};

export const updateTimesheetExpensesTypeAction = (
  values: Omit<TimeLogCategoryType, "deletedAt">,
  callback: () => void
) => async (dispatch: Dispatch) => {
  dispatch(
    setRequestInProcess(
      true,
      getRequestName(
        Types.TIMESHEET_EXPENSES_TYPES,
        "updateTimesheetExpensesTypeAction"
      )
    )
  );

  try {
    await updateItem(values);
    dispatch(
      openSnackbar(
        "Expenses type has been updated",
        MILLISECONDS_FOR_SHOWING_MESSAGE
      )
    );
    callback();
  } catch (error) {
    handleHttpErrors(error, dispatch);
  } finally {
    dispatch(
      setRequestInProcess(
        false,
        getRequestName(
          Types.TIMESHEET_EXPENSES_TYPES,
          "updateTimesheetExpensesTypeAction"
        )
      )
    );
  }
};

export const archiveTimesheetExpensesTypeAction = (
  item: TimeLogCategoryType,
  filter?: FILTER_TYPE
) => async (dispatch: Dispatch) => {
  dispatch(
    setRequestInProcess(
      true,
      getRequestName(
        Types.TIMESHEET_EXPENSES_TYPES,
        "archiveTimesheetExpensesTypeAction"
      )
    )
  );
  try {
    await archiveItem(+item.id);
    if (filter === ALL) {
      dispatch(
        TimesheetExpensesTypesRequest.replaceItem({
          ...item,
          deletedAt: getDeletedAtDate()
        })
      );
    } else {
      dispatch(TimesheetExpensesTypesRequest.removeItem({ id: item.id }));
    }
    dispatch(
      openSnackbar(
        `Expenses type has been archived`,
        MILLISECONDS_FOR_SHOWING_MESSAGE
      )
    );
  } catch (error) {
    handleHttpErrors(error, dispatch);
  } finally {
    dispatch(
      setRequestInProcess(
        false,
        getRequestName(
          Types.TIMESHEET_EXPENSES_TYPES,
          "archiveTimesheetExpensesTypeAction"
        )
      )
    );
  }
};

export const unArchiveTimesheetExpensesTypeAction = (
  item: TimeLogCategoryType,
  filter?: FILTER_TYPE
) => async (dispatch: Dispatch) => {
  dispatch(
    setRequestInProcess(
      true,
      getRequestName(
        Types.TIMESHEET_EXPENSES_TYPES,
        "unArchiveTimesheetExpensesTypeAction"
      )
    )
  );
  try {
    await unArchiveItem(+item.id);
    if (filter === ALL) {
      dispatch(
        TimesheetExpensesTypesRequest.replaceItem({ ...item, deletedAt: null })
      );
    } else {
      dispatch(TimesheetExpensesTypesRequest.removeItem({ id: item.id }));
    }
    dispatch(
      openSnackbar(
        `Expenses type has been unarchived`,
        MILLISECONDS_FOR_SHOWING_MESSAGE
      )
    );
  } catch (error) {
    handleHttpErrors(error, dispatch);
  } finally {
    dispatch(
      setRequestInProcess(
        false,
        getRequestName(
          Types.TIMESHEET_EXPENSES_TYPES,
          "unArchiveTimesheetExpensesTypeAction"
        )
      )
    );
  }
};
