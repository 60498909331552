import { IUsers } from "models/users.interface";
import * as React from "react";
import { useCallback } from "react";
import { OnReset } from "../constants";
import {
  ConfirmWrapper
} from "containers/admin/testers/TesterList/columns/ConfirmWrapper";

type ResetPasswordProps = {
  user: IUsers,
  onReset: OnReset;
};

export function ResetPassword({ user, onReset }: ResetPasswordProps) {
  const onResetPasswordHandler = useCallback(() => {
    onReset(user);
  }, [user]);

  if (user.deletedAt) {
    return <></>;
  }

  return <ConfirmWrapper
    onConfirm={onResetPasswordHandler}
    message='You want to reset password?'
  >
    <a
      href={user.email}
      style={{fontSize: '0.8125rem'}}
    >
      Reset&nbsp;password
    </a>
  </ConfirmWrapper>
}
