import {IOptionItem} from "../interfaces/option-item.interface";

export enum RadiusNames {
  W20M = 'Within 20 miles',
  W50M = 'Within 50 miles',
  W200M = 'Within 200 miles',
  W500M = 'Within 500 miles',
  ANYWHERE = 'Anywhere',
}

export const RADIUS_LIST: IOptionItem[] = [
  {
    key: '20',
    value: RadiusNames.W20M,
  },
  {
    key: '50',
    value: RadiusNames.W50M,
  },
  {
    key: '200',
    value: RadiusNames.W200M,
  },
  {
    key: '500',
    value: RadiusNames.W500M,
  },
  {
    key: ' ',
    value: RadiusNames.ANYWHERE,
  },
];
