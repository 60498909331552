import * as Types from '../constants/action-type';
import {
  createLoaderAction,
  createRequestAction
} from '../helpers/action-helper';
import {setRequestInProcess} from "./request";
import {getRequestName} from "../helpers/action-request-helper";
import {getBookingRequestsRequest} from "../api/requests/test";
import {handleHttpErrors} from "../helpers/errors/http-error-handler";

export const testReportLoader = createLoaderAction(Types.TEST_REPORTS);
export const testReportRequest = createRequestAction(Types.TEST_REPORTS);

export const getBookingRequestsAction = (
  page: number,
  itemsPerPage: number,
  project: number[],
  status: string[],
  dateFrom: string,
  dateTo: string,
  questionAsked: boolean,
) => async dispatch => {
  dispatch(setRequestInProcess(true, getRequestName(Types.TEST_REPORTS, 'getBookingRequests')));
  /** part responsible for a loader state */
  dispatch(createLoaderAction(Types.TEST_REPORTS).setIsNotLoaded());

  let data = [], totalItems;

  try {
    ({items: data, totalItems} = await getBookingRequestsRequest(
      page,
      itemsPerPage,
      project,
      status,
      dateFrom,
      dateTo,
      questionAsked,
    ));
    dispatch(createLoaderAction(Types.TEST_REPORTS).setPaginatioData(data, itemsPerPage, totalItems, page));
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(setRequestInProcess(false, getRequestName(Types.TEST_REPORTS, 'getBookingRequests')));
};