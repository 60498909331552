import {setRequestInProcess} from "./request";
import {fetchMyData, login} from "../api/requests/auth";
import {openSnackbar} from "./snackbar";
import * as Storage from "../helpers/storage-helper";
import {postTwoFactorCheck} from "../api/requests/two-factor-auth";
import {twoFactorCheckClose} from "./modals";
import {LOGIN_COMPLETED} from "../constants/action-type";
import {redirectToControlPanel, setPermissionsAction} from "./common";
import {reset, stopSubmit} from "redux-form";
import * as Types from "../constants/action-type";
import {REFRESH_TOKEN_KEY, TOKEN_KEY} from "../constants/storage";
import {result} from "lodash";

export const twoFactorAuthCheck = (code: number, formName: string = "") => async dispatch => {
  dispatch(setRequestInProcess(true, "twoFactorAuthCheck"));

  let data;
  try {
    data = await postTwoFactorCheck(code);
  } catch (e) {
    dispatch(setRequestInProcess(false, "twoFactorAuthCheck"));
    if (result(e, "response.body.message")) {
      dispatch(stopSubmit(formName, {
        code: result(e, "response.body.message")
      }));
    }
    return;
  }

  Storage.setValue(TOKEN_KEY, data.token);

  Storage.setValue(REFRESH_TOKEN_KEY, data.refresh_token);

  const permissions = await fetchMyData();

  dispatch(setPermissionsAction(permissions));
  dispatch(twoFactorCheckClose());
  dispatch(setRequestInProcess(false, "twoFactorAuthCheck"));
  dispatch({type: LOGIN_COMPLETED, payload: data});
  dispatch({type: Types.MODAL_LOGIN_CLOSE});
  dispatch(openSnackbar("Logged in", 4000));
  dispatch(setRequestInProcess(false, "twoFactorAuthCheck"));
  dispatch(reset(formName));
  dispatch(redirectToControlPanel(permissions.roles, permissions.isXeroContactExists));
};