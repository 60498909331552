import * as React from "react";
import {Modal, Typography, Grid, WithStyles} from "@material-ui/core";

interface IProps {
  onClose: () => void;
  open?: boolean;
}

class TestInfoModal extends React.Component<WithStyles<any> & IProps, {}> {
  static defaultProps: Partial<IProps> = {
    open: false,
  };

  render() {
    if (!this.props.open) {
      return null;
    }

    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={true}
        onClose={this.handleClose}
        className="d-flex overflow-auto"
      >
        <div className="styled-modal xs">
          <h4>Information</h4>
          <hr/>
          <p>Please, ask your manager to perform this operation.</p>
          <hr/>
          <div className="text-right">
            <button className="btn-cancel" onClick={this.handleClose}>
              Close
            </button>
          </div>
        </div>
      </Modal>
    );
  }

  private readonly handleClose = () => {
    this.props.onClose();
  };
}

export default TestInfoModal as React.ComponentClass<IProps>;
