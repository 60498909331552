import {Observable} from 'rxjs';
import {catchError, flatMap, switchMap} from 'rxjs/operators';
import {ActionsObservable} from 'redux-observable';
import * as actionType from '../constants/action-type';
import {setRequestInProcess} from '../actions/request';
import {of} from "rxjs/internal/observable/of";
import {fromPromise} from "rxjs/internal-compatibility";
import {openSnackbar} from "../actions/snackbar";
import {IActionPayload} from "./index";
import {startEndCatch} from "./pipes/errors";
import {testerAccountModalClose} from "../actions/modals";
import {requestAccountUpdate} from "../api/requests/tester-account";
import {fetchMyData} from "../api/requests/auth";
import {setPermissionsAction} from "../actions/common";

export const testerAccountSubmitEpic = (action$: ActionsObservable<IActionPayload<{email: string, phone: string, firstName: string, lastName: string}>>): Observable<any> => {
  return action$
    .ofType(actionType.TESTER_ACCOUNT_MODAL_SUBMIT)
    .pipe(
      switchMap(action => {
        const {firstName, lastName, phone, email} = action.payload
        return fromPromise(requestAccountUpdate(email, phone, firstName, lastName))
          .pipe(
            switchMap(data => fromPromise(fetchMyData())),
            flatMap((permissions) => {
              return [
                setPermissionsAction(permissions),
                openSnackbar('Your profile has been updated', 4000),
                testerAccountModalClose(),
                location.reload()
              ];
            }),
            ...startEndCatch('testerAccountSubmitEpic'),
          );
      }),
      catchError((error) => {
        return of(setRequestInProcess(false, 'testerAccountSubmitEpic'));
      })
    );
};
