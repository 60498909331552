import { extractJSONLD, fetchRequestBody } from "../../helpers/request-helper";
import { getApiJSONLD } from "../swagger/api-factory";
import {
  GetTimeLogCategoriesModel,
  TimeLogCategoryType
} from "../../types/timesheets-and-expenses";
import {
  convertGetTimeLogCategoriesToSwagger,
  convertGetTimeLogCategoriesFromSwagger
} from "../convertors/time-and-expenses";

export const getItems = async (
  params: GetTimeLogCategoriesModel
): Promise<{ items: TimeLogCategoryType[]; totalItems: number }> => {
  return getApiJSONLD()
    .then(api =>
      api.getTimesheetExpensesTypes(
        convertGetTimeLogCategoriesToSwagger(params)
      )
    )
    .then(extractJSONLD(convertGetTimeLogCategoriesFromSwagger));
};

export const createItem = async (params: Pick<TimeLogCategoryType, "name">) => {
  return getApiJSONLD()
    .then(api => api.addTimesheetExpensesTypesCollection({ name: params }))
    .then(fetchRequestBody);
};
export const updateItem = async ({
  id,
  name
}: {
  id: number | string;
  name: string;
}) => {
  return getApiJSONLD()
    .then(api => api.updateTimesheetExpensesTypeById({ id, name: { name } }))
    .then(fetchRequestBody);
};

export const archiveItem = async (id: number) => {
  return getApiJSONLD()
    .then(api => api.deleteTimesheetExpensesTypeById({ id }))
    .then(fetchRequestBody);
};
export const unArchiveItem = async (id: number) => {
  return getApiJSONLD()
    .then(api => api.unarchiveTimesheetExpensesTypeById({ id, type: {} }))
    .then(fetchRequestBody);
};

export const getExpensesTypeById = async (id: number) => {
  return getApiJSONLD()
    .then(api => api.getTimesheetExpensesTypeById({ id }))
    .then(fetchRequestBody);
};

export const getExpensesTypeNameById = async (
  id: number
): Promise<{ name: string }> => {
  try {
    const type = await getExpensesTypeById(id);
    return { name: type?.name || "" };
  } catch (error) {
    throw new Error(error?.message || "Can`t get expenses type data");
  }
};
