import * as Types from '../constants/action-type';
import {createLoaderActionItem} from "../interfaces/reducer-item.interface";
import {Action, createAction} from "redux-actions";
import {IBankDatails} from "../models/bank-datails.interface";

export interface IPutBankDetailsAction {
  item: IBankDatails;
  backRoute: string;
  formName: string;
}

export const putBankDetailsAction = (
  bankDatails: IBankDatails,
  backRoute: string,
  formName: string
): Action<IPutBankDetailsAction> => {
  return createAction(createLoaderActionItem(Types.TESTER_BANK_DETAILS).put().type, () => ({
    item: bankDatails,
    backRoute,
    formName,
  }))();
};
