export const handleSpaceKeyPress = (event) => {
  if (event.ctrlKey || event.metaKey || event.altKey) {
    return;
  }

  const char = event.key;

  if (!char) { return; }

  if (char === ' ') {
    event.preventDefault();
  }
};