import React from "react";
import {reduxForm} from "redux-form";
import {connect} from "react-redux";
import {putContentManagementItem, contentManagementRequest} from "../../../../actions/content-management";
import {composeValidators, fieldMaxLength, fieldsNotEmpty} from "../../../../helpers/validations/validation";
import parameterFetcher from "../../../../components/parametersFetcher";
import selector from "../../../../selectors/content-management";
import routerModal from "../../../../components/routerModal";
import {Field} from "redux-form";
import Button from "@material-ui/core/Button/Button";
import ReduxWYSIWYGField from "../../../../components/forms/ReduxWYSIWYGField";
import ReduxTextField from "../../../../components/forms/ReduxTextField";
import {Link} from "react-router-dom";

const FORM_NAME = "ContentManagementUpdate";

class ContentManagementUpdate extends React.Component {

  render() {
    const {handleSubmit, putData, backRoute} = this.props;

    return (
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit((values) => {
          putData(values, FORM_NAME, backRoute);
        })}>
        <div>
          <h4>Content Management</h4>
          <div className="styled-modal-body">
            <Field
              inputProps={{readOnly: true, disabled: true}}
              name="contentKey"
              label="Key"
              type="text"
              margin="normal"
              component={ReduxTextField}
            />
            <Field
              name="name"
              label="Name"
              type="text"
              margin="normal"
              component={ReduxTextField}
            />
            <Field
              name="content"
              label="Content"
              type="text"
              margin="normal"
              component={ReduxWYSIWYGField}
            />
          </div>
          <div className="d-flex justify-content-between mt-4">
            <Button variant="contained" color="default" component={Link} to={backRoute}>
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
          </div>
        </div>
      </form>
    );
  }
}

const formConnected = reduxForm({
  form: FORM_NAME,
  validate: composeValidators(
    fieldsNotEmpty(["name"]),
    fieldMaxLength(["name"], 50)
  )
})(ContentManagementUpdate);

const connected = parameterFetcher(connect(undefined, {
  putData: putContentManagementItem
})(formConnected), FORM_NAME, contentManagementRequest.getItem, selector.getItemById);

export default routerModal(connected);
