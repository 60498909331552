import React from "react";
import { AppBar, Tab, Tabs } from "@material-ui/core";
import { NavLink, Redirect, Switch, withRouter } from "react-router-dom";
import Testers from "./testers/Testers";
import Settings from "./settings/Settings";
import Payment from "./Payment";
import Clients from "./clients/Clients";
import Tests from "./tests/Tests";
import ReportTemplatesAlert from "./ReportTemplatesAlert";
import Invoices from "./invoces/Invoices";
import Header from "../guest/Header";
import Footer from "containers/guest/Footer";
import { AdminUrls } from "constants/admin-urls";
import { Consultants } from "./consultants/Consultants";
import TimeAndExpenses from "./time-and-expenses/TimeAndExpenses";
import PermissionBlock from "components/auth/PermissionBlock";
import {
  ROLE_ACCOUNT_MANAGER,
  ROLE_ADMIN,
  ROLE_SUPER_ADMIN
} from "constants/roles";
import { Permission } from "constants/permission";
import { getRoles } from "selectors/auth";
import { connect } from "react-redux";
import PrivateRoute from "components/auth/PrivateRoute";

class Admin extends React.Component {
  constructor(props) {
    super(props);

    this.tabsWrapperRef = React.createRef();
  }

  getTabsCurrentValue = () => {
    if (!this.tabsWrapperRef.current) {
      return 0;
    }

    const tabs = Array.from([
      ...this.tabsWrapperRef.current.tabsRef.children[0].children
    ]);

    const {
      location: { pathname: activeTabRoute }
    } = this.props;

    const index = tabs.findIndex(element =>
      activeTabRoute.includes(new URL(element.href).pathname)
    );

    return index <= 0 ? 0 : index;
  };

  render() {
    return (
      <div>
        <Header />
        <div className="page-content">
          <ReportTemplatesAlert />
          <AppBar position="static" color="default">
            <Tabs
              indicatorColor="primary"
              variant="scrollable"
              value={this.getTabsCurrentValue()}
              innerRef={this.tabsWrapperRef}
            >
              <PermissionBlock roles={[ROLE_ADMIN, ROLE_SUPER_ADMIN]}>
                <Tab
                  component={NavLink}
                  activeStyle={{ backgroundColor: "rgba(0, 0, 0, 0.08)" }}
                  label="Settings"
                  to={AdminUrls.SETTINGS}
                />
              </PermissionBlock>
              <PermissionBlock
                roles={[ROLE_ADMIN, ROLE_SUPER_ADMIN, ROLE_ACCOUNT_MANAGER]}
                permissions={[Permission.CAN_VIEW_PLATFORM_INVOICES_AND_COSTS]}
              >
                <Tab
                  component={NavLink}
                  activeStyle={{ backgroundColor: "rgba(0, 0, 0, 0.08)" }}
                  label="Invoices"
                  to={AdminUrls.INVOICES}
                />
              </PermissionBlock>
              <Tab
                component={NavLink}
                activeStyle={{ backgroundColor: "rgba(0, 0, 0, 0.08)" }}
                label="Testers"
                to={AdminUrls.TESTERS}
              />
              <Tab
                component={NavLink}
                activeStyle={{ backgroundColor: "rgba(0, 0, 0, 0.08)" }}
                label="Clients"
                to={AdminUrls.CLIENTS}
              />
              <Tab
                component={NavLink}
                activeStyle={{ backgroundColor: "rgba(0, 0, 0, 0.08)" }}
                label="Tests"
                to={AdminUrls.TESTS}
              />
              <PermissionBlock
                roles={[ROLE_ADMIN, ROLE_SUPER_ADMIN, ROLE_ACCOUNT_MANAGER]}
                permissions={[Permission.CAN_VIEW_CONSULTANTS]}
              >
                <Tab
                  component={NavLink}
                  activeStyle={{ backgroundColor: "rgba(0, 0, 0, 0.08)" }}
                  label="Consultants"
                  to={AdminUrls.CONSULTANTS}
                />
              </PermissionBlock>
              <PermissionBlock
                roles={[ROLE_ADMIN, ROLE_SUPER_ADMIN, ROLE_ACCOUNT_MANAGER]}
                permissions={[Permission.CAN_VIEW_TIME_EXPENSES]}
              >
                <Tab
                  component={NavLink}
                  activeStyle={{ backgroundColor: "rgba(0, 0, 0, 0.08)" }}
                  label="Time & expenses"
                  to={AdminUrls.TIME_AND_EXPENSES}
                />
              </PermissionBlock>
            </Tabs>
          </AppBar>
          <div className="content">
            <Switch>
              <Redirect
                exact
                from="/admin"
                to={this.getRedirectUrlByUserRole()}
              />
              <PrivateRoute
                roles={[ROLE_ADMIN, ROLE_SUPER_ADMIN, ROLE_ACCOUNT_MANAGER]}
                path="/admin/tests"
                component={Tests}
              />
              <PrivateRoute
                roles={[ROLE_ADMIN, ROLE_SUPER_ADMIN, ROLE_ACCOUNT_MANAGER]}
                path="/admin/clients"
                component={Clients}
              />
              <PrivateRoute
                roles={[ROLE_ADMIN, ROLE_SUPER_ADMIN, ROLE_ACCOUNT_MANAGER]}
                path="/admin/testers"
                component={Testers}
              />
              <PrivateRoute
                roles={[ROLE_ADMIN, ROLE_SUPER_ADMIN, ROLE_ACCOUNT_MANAGER]}
                path="/admin/invoices"
                permissions={[Permission.CAN_VIEW_PLATFORM_INVOICES_AND_COSTS]}
                component={Invoices}
              />
              <PrivateRoute
                roles={[ROLE_ADMIN, ROLE_SUPER_ADMIN]}
                path="/admin/payment-batches"
                component={Payment}
              />
              <PrivateRoute
                roles={[ROLE_ADMIN, ROLE_SUPER_ADMIN]}
                path="/admin/settings"
                component={Settings}
              />
              <PrivateRoute
                roles={[ROLE_ADMIN, ROLE_SUPER_ADMIN, ROLE_ACCOUNT_MANAGER]}
                permissions={[Permission.CAN_VIEW_CONSULTANTS]}
                path={AdminUrls.CONSULTANTS}
                component={Consultants}
              />
              <PrivateRoute
                roles={[ROLE_ADMIN, ROLE_SUPER_ADMIN, ROLE_ACCOUNT_MANAGER]}
                permissions={[Permission.CAN_VIEW_TIME_EXPENSES]}
                path={AdminUrls.TIME_AND_EXPENSES}
                component={TimeAndExpenses}
              />
            </Switch>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  getRedirectUrlByUserRole() {
    const { currentRoles = [] } = this.props;

    if (
      currentRoles.includes(ROLE_SUPER_ADMIN) ||
      currentRoles.includes(ROLE_ADMIN)
    ) {
      return AdminUrls.SETTINGS;
    }

    if (currentRoles.includes(ROLE_ACCOUNT_MANAGER)) {
      return AdminUrls.TESTERS;
    }

    return "/";
  }
}

function mapStateToProps(state) {
  return {
    currentRoles: getRoles(state)
  };
}

export default withRouter(connect(mapStateToProps, {})(Admin));
