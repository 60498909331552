import * as React from "react";
import { ACTIVE, ARCHIVED, ALL } from "constants/filter";
import { getEditLink } from "helpers/router-helper";
import TableCell from "@material-ui/core/TableCell";
import { TableCellProps } from "@material-ui/core/TableCell/TableCell";
import CustomIconButton from "../CustomIconButton";
import { ComponentType } from "react";

type TableCellEditProps = {
  status: string;
  Wrapper?: ComponentType;
  item: { [key: string]: any };
  baseUrl: string;
} & TableCellProps;

class TableCellEdit extends React.Component<TableCellEditProps, {}> {
  render() {
    const { status, item, baseUrl, Wrapper = TableCell, ...rest } = this.props;
    if (status === ACTIVE) {
      return (
        <Wrapper {...rest}>
          <CustomIconButton
            to={getEditLink(baseUrl, item.id)}
            iconName="edit"
          />
        </Wrapper>
      );
    } else if (status === ARCHIVED) {
      return null;
    } else if (status === ALL) {
      if (item.deletedAt) {
        return (
          <Wrapper {...rest}>
            <div style={{ height: "48px" }} />
          </Wrapper>
        );
      }

      return (
        <Wrapper {...rest}>
          <CustomIconButton
            to={getEditLink(baseUrl, item.id)}
            iconName="edit"
          />
        </Wrapper>
      );
    }

    return null;
  }
}

export default TableCellEdit;
