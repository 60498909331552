import * as React from "react";
import debounce from "debounce";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {getBookingRequestsAction, testReportLoader, testReportRequest} from "../../../actions/test-reports";
import selector from "../../../selectors/test-reports";
import {connect} from "react-redux";
import {IAnyProps} from "../../../interfaces/any-props.interface";
import {ITest} from "../../../models/test.interface";
import {isChangesExist} from "../../../helpers/props-checker";
import {ALL_STATUSES_REPORTS, REPORTS_FORM_NAME} from "./ReportsSearchForm";
import {
  completedReportsTabMustBeViewed,
  reportCanBeViewed,
  testDetailsCanBeViewed,
} from "../../../constants/test-status";
import {TablePagination, TableHead, TableBody, TableRow, TableCell, Table, Zoom} from "@material-ui/core";
import {formValueSelector} from "redux-form";
import {Link} from "react-router-dom";
import {IGlobalState} from "../../../reducers";
import * as _ from "lodash";
import {formatDateWithTime} from "../../../helpers/date-formatter";
import {HashLink} from "react-router-hash-link";
import StatusBlock from "../../common/StatusBlock";
import {getCurrency} from "../../../helpers/currency";
import TesterTableCell from "../../common/TesterTableCell";
import {putItem, deleteFavorite} from "../../../api/requests/favorite-testers";
import Tooltip from "@material-ui/core/Tooltip";
import { openSnackbar } from "../../../actions/snackbar";
import {bindActionCreators} from "redux";
import Icon from "@material-ui/core/Icon/Icon";
import TableAction from "./TableAction";

const searchFormSelector = formValueSelector(REPORTS_FORM_NAME);

interface IOwnProps {
  baseUrl: string;
  data: ITest[];

  [key: string]: any;

  searchValues: {
    project: number[],
    status: string[],
    dateFrom: string,
    dateTo: string,
  };
}

type IProps = IOwnProps & RouteComponentProps<any>;

class ReportsList extends React.Component<IProps, {}> {
  debouncedLoad = debounce((nextProps) => {
    this.loadItems(nextProps);
  }, 500);

  loadItems = (props) => {
    props.loadItems(
      props.pageNumber,
      props.itemsPerPage,
      props.searchValues.project,
      !!props.searchValues.status && props.searchValues.status.length ?
        props.searchValues.status :
        ALL_STATUSES_REPORTS,
      props.searchValues.dateFrom,
      props.searchValues.dateTo
    );
  };

  onArchive = (item) => {
    this.props.archiveItem(
      item.id,
      this.props.pageNumber,
      this.props.itemsPerPage,
      this.props.showStatus,
      this.props.filterText
    );
  };

  componentDidMount() {
    this.loadItems(this.props);
  }

  componentWillReceiveProps(nextProps: IProps) {
    if (_.isEmpty(this.props.searchValues)) {
      return;
    }

    if (
      isChangesExist(["pageNumber", "itemsPerPage", "showStatus", "filterText"], nextProps, this.props) ||
      isChangesExist(["project", "status", "dateFrom", "dateTo"], nextProps.searchValues, this.props.searchValues)
    ) {
      this.loadItems(nextProps);
    }
  }

  handleChangePage = (event, page) => {
    this.props.setPageNumber(page + 1);
  };

  handleChangeRowsPerPage = event => {
    this.props.setItemsPerPage(event.target.value);
  };

  refreshItems = () => {
    this.loadItems(this.props);
  }

  handleOnClick = (tester) => (event) => {
    if (tester && !!tester.id) {
      if (tester.isFavorite) {
        deleteFavorite(tester.id)
        .then(()=>{
          this.debouncedLoad(this.props);
          this.props.showSuccessMessage("Tester successfully removed from favorites");
        });
      } else {
        putItem(tester.id)
        .then(()=>{
          this.debouncedLoad(this.props);
          this.props.showSuccessMessage("Tester successfully added to favorites");
        });
      }
    }
  };

  getReportAction = (item: ITest) => {
    if (item.report && reportCanBeViewed(Number(item.status))) {
      if (completedReportsTabMustBeViewed(Number(item.status))) {
        return (
          <Link to={`/client/reports/${item.id}/report`}>
            <button type="submit" className="btn-view">
              View&nbsp;Report
            </button>
          </Link>
        );
      } else {
        return (
          <Link to={`/client/reports/${item.id}/details`}>
            <button type="submit" className="btn-view">
              View&nbsp;Report
            </button>
          </Link>
        );
      }
    }

    if (testDetailsCanBeViewed(Number(item.status))) {
      return (
        <Link to={`/client/reports/${item.id}/details`}>
          <button type="submit" className="btn-view">
            View&nbsp;Details
          </button>
        </Link>
      );
    }

    return null;
  };

  getStatusActionForComponent = (item: ITest, component) => {
    if (item.report && reportCanBeViewed(Number(item.status))) {
      return (
        <HashLink to={`/client/reports/${item.id}/details#messages`}>
          {component}
        </HashLink>
      );
    }

    if (testDetailsCanBeViewed(Number(item.status))) {
      return (
        <HashLink to={`/client/booking-requests/${item.id}#messages`}>
          {component}
        </HashLink>
      );
    }

    return null;
  };

  getWrapperStyle = (item: ITest) => this.getReportAction(item) === null ? 'justify-content-end' : 'justify-content-between'

  render() {
    if (!this.props.isLoaded) {
      return (
        <div className="styled-block">
          <h4 className="block-header mb-4">Last Booking Reports</h4>
          Loading
        </div>
      );
    }

    if (!this.props.data.length) {
      return (
        <div className="styled-block">
          <h4 className="block-header mb-4">Last Booking Reports</h4>
          There are no items!
        </div>
      );
    }

    return (
      <div className="styled-block">
        <h4 className="block-header mb-4">Last Booking Reports</h4>
        <div className="table-responsive styled-table">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Start</TableCell>
                <TableCell>Project</TableCell>
                <TableCell>Virtual<br/>Team</TableCell>
                <TableCell>Consultant/Tester</TableCell>
                <TableCell>PO number</TableCell>
                <TableCell className="text-right">Cost</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>New Messages</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                this.props.data.map(item => {
                  return (
                    <TableRow key={item.id}>
                      <TableCell>
                        <div>
                          <Link to={`/client/reports/${item.id}/details`}>
                            {item.hash + " - " + item.name}
                          </Link>
                        </div>
                        {item.dateFrom && formatDateWithTime(item.dateFrom)}
                      </TableCell>
                      <TableCell>{item.projectName}</TableCell>
                      <TableCell onClick={this.handleOnClick(item.tester)}>
                        {!!item.tester && item.tester.user && !item.tester.user.deletedAt && (
                          <div className="tester-image-name">
                            {(item.tester.isFavorite)
                              ? (
                                <Tooltip
                                  placement="bottom-end"
                                  TransitionComponent={Zoom}
                                  disableFocusListener
                                  disableTouchListener
                                  title="Remove from favorites"
                                >
                                  <span className="btn-manage"/>
                                </Tooltip>
                              )
                              : (
                                <Tooltip
                                  placement="bottom-end"
                                  TransitionComponent={Zoom}
                                  disableFocusListener
                                  disableTouchListener
                                  title="Add to favorites"
                                >
                                  <span className="btn-manage-inactive"/>
                                </Tooltip>
                              )}
                          </div>
                        )}
                      </TableCell>
                      <TesterTableCell tester={item.tester}/>
                      <TableCell>
                        {!item.purchaseOrderNumber ? (
                            <div style={{marginLeft: "25px"}}>
                              <Tooltip
                                  placement="bottom-end"
                                  TransitionComponent={Zoom}
                                  disableFocusListener
                                  disableTouchListener
                                  title="Purchase Order number missing"
                              >
                                <Icon style={{color:"#FF0000"}}>games_icon</Icon>
                              </Tooltip>
                            </div>
                        ) : (
                            <div style={{maxWidth:"100px", wordBreak: "break-all"}}>
                              {item.purchaseOrderNumber}
                            </div>
                        )}
                      </TableCell>
                      <TableCell className="text-right total">
                        {item.proposal && item.proposal.currency && `${item.proposal.currency.symbol}${getCurrency(item.cost)}`}
                      </TableCell>
                      <TableCell>
                        <div>
                          {item.status && <StatusBlock status={item.status}/>}
                        </div>
                      </TableCell>
                      <TableCell className="text-center">
                        {item.testStatisticData.newMessagesCountForPlanner > 0 &&
                        this.getStatusActionForComponent(item,
                          <span className="notification">
                          {item.testStatisticData.newMessagesCountForPlanner}
                        </span>
                        )
                        }
                      </TableCell>
                      <TableCell>
                        <div className={`d-flex align-items-center ${this.getWrapperStyle(item)}`}>
                          {this.getReportAction(item)}
                          <TableAction
                              item={item}
                              refreshItems={this.refreshItems}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })
              }
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={this.props.totalItems}
            rowsPerPage={this.props.itemsPerPage}
            page={this.props.pageNumber - 1}
            backIconButtonProps={{"aria-label": "Previous Page"}}
            nextIconButtonProps={{"aria-label": "Next Page"}}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </div>
      </div>
    );
  }
}

const routered = withRouter(ReportsList);

export default connect<IAnyProps, IAnyProps, IAnyProps>((state: IGlobalState) => {
  return {
    data: selector.getItems(state),
    isLoaded: selector.getIsLoaded(state),
    itemsPerPage: selector.getItemsPerPage(state),
    pageNumber: selector.getPageNumber(state),
    showStatus: selector.getFilterActivityStatus(state),
    totalItems: selector.getTotalItems(state),
    filterText: selector.getFilterTextFields(state),
    searchValues: searchFormSelector(state, "project", "status", "dateFrom", "dateTo"),
    authGroup: state.auth.group
  };
},
(dispatch) => ({
  loadItems: bindActionCreators(getBookingRequestsAction, dispatch),
  setItemsPerPage: bindActionCreators(testReportLoader.setItemsPerPage, dispatch),
  setPageNumber: bindActionCreators(testReportLoader.setPageNumber, dispatch),
  archiveItem: bindActionCreators(testReportRequest.archiveItem, dispatch),
  showSuccessMessage: (message)=>dispatch(openSnackbar(message, 4000)),
}))(routered);
