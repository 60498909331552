import * as React from "react";
import Paper from "@material-ui/core/Paper";
import { connect } from "react-redux";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import selector from "../../../selectors/users";
import {
  Field,
  formValueSelector,
  InjectedFormProps,
  reduxForm
} from "redux-form";
import ReduxRadioButton from "../../../components/forms/ReduxRadioButton";
import * as FilterTypes from "../../../constants/filter";
import { bindActionCreators } from "redux";
import ReduxTextField from "../../../components/forms/ReduxTextField";
import { FieldGeneric } from "../../../components/forms/FieldGeneric";
import { usersFilter } from "../../../actions/users";
import { isChangesExist } from "../../../helpers/props-checker";
import { Button, MenuItem } from "@material-ui/core";
import ReduxDatePicker from "../../../components/forms/ReduxDatePicker";
import ReduxSelectField from "../../../components/forms/ReduxSelectField";
import ReduxDialingCodeSelectSearchField from "../../../components/forms/ReduxDialingCodeSelectSearchField";
import { getCompanies } from "../../../actions/finances";

export const CLIENT_SEARCH_FORM_NAME = "ClientsSearchForm";
const selectorForm = formValueSelector(CLIENT_SEARCH_FORM_NAME);

const roles = [
  {
    id: "Client",
    name: "Common Company Owner"
  },
  {
    id: "Planner",
    name: "Planner"
  },
  {
    id: "Viewer",
    name: "Viewer"
  },
  {
    id: "Employee",
    name: "Employee"
  },
  {
    id: "ExternalTester",
    name: "External Third Party"
  }
];

class ClientsSearchForm extends React.Component<
  InjectedFormProps<{}, {}> & {
    submitFilterChanges: (...any) => void;
    valuesData: { active: string; text: string };
    loadCompanies: any;
    companies: { companiesForManagedServices: string[] };
  },
  any
> {
  componentWillReceiveProps(nextProps) {
    if (isChangesExist(["valuesData"], nextProps, this.props)) {
      this.props.submitFilterChanges(
        nextProps.valuesData.active,
        nextProps.valuesData.text
      );
    }
  }

  componentWillMount() {
    this.props.loadCompanies();
  }

  renderOption = (item: any) => (
    <MenuItem key={item.id} value={item.id}>
      {item.name}
    </MenuItem>
  );

  render() {
    const {
      handleSubmit,
      reset,
      companies: { companiesForManagedServices }
    } = this.props;

    return (
      <form
        className="mb-4"
        onSubmit={handleSubmit((values: { active: string; text: string }) => {
          this.props.submitFilterChanges(values.active, values.text);
        })}
      >
        <Paper className="p-4">
          <div className="row">
            <div className="col-md-4">
              <Field
                name="name"
                label="Name"
                component={ReduxTextField}
                margin="normal"
              />
            </div>
            <div className="col-md-4">
              <Field
                name="email"
                label="Email"
                component={ReduxTextField}
                margin="normal"
              />
            </div>
            <div className="col-md-4">
              <FieldGeneric
                name="userClientCompanyFilter"
                label="Company"
                component={ReduxDialingCodeSelectSearchField}
                items={companiesForManagedServices || []}
                renderItem={this.renderOption}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <FieldGeneric
                name="createdAt"
                label="From"
                InputLabelProps={{
                  shrink: true
                }}
                component={ReduxDatePicker}
                margin="normal"
              />
            </div>
            <div className="col-md-4">
              <FieldGeneric
                name="deletedAt"
                label="To"
                InputLabelProps={{
                  shrink: true
                }}
                addEndOfDay={true}
                component={ReduxDatePicker}
                margin="normal"
              />
            </div>
            <div className="col-md-4">
              <FieldGeneric
                name="role"
                label="Role"
                component={ReduxSelectField}
                margin="normal"
              >
                <MenuItem value="">None</MenuItem>
                {roles.map(this.renderOption)}
              </FieldGeneric>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <Field
                name="phone"
                label="Telephone"
                component={ReduxTextField}
                margin="normal"
              />
            </div>
            <div className="col-md-8 d-flex align-items-end justify-content-end flex-wrap">
              <div>
                <FieldGeneric
                  name="active"
                  inline={true}
                  component={ReduxRadioButton}
                  margin="normal"
                >
                  <FormControlLabel
                    value={FilterTypes.ACTIVE}
                    control={<Radio />}
                    label="Active"
                  />
                  <FormControlLabel
                    value={FilterTypes.ARCHIVED}
                    control={<Radio />}
                    label="Inactive"
                  />
                  <FormControlLabel
                    value={FilterTypes.ALL}
                    control={<Radio />}
                    label="Both"
                  />
                </FieldGeneric>
              </div>
              <button
                className="btn-refresh"
                type="button"
                onClick={reset}
                style={{ marginBottom: 15 }}
              >
                Reset Filters
              </button>
            </div>
          </div>
        </Paper>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    valuesData: {
      ...selectorForm(
        state,
        "name",
        "email",
        "phone",
        "createdAt",
        "deletedAt",
        "active",
        "role",
        "userClientCompanyFilter"
      )
    },
    initialValues: {
      text: selector.getFilterTextFields(state),
      active: selector.getFilterActivityStatus(state)
    },
    companies: state.finances
  };
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      submitFilterChanges: usersFilter.submitFilterChanges,
      loadCompanies: getCompanies || {}
    },
    dispatch
  );
};

const connectForm = reduxForm({
  form: CLIENT_SEARCH_FORM_NAME
})(ClientsSearchForm);

export default connect(mapStateToProps, mapDispatchToProps)(connectForm);
