import React from "react";
import TooltipIcon from "@material-ui/core/Tooltip/Tooltip";
import { Icon, Zoom } from "@material-ui/core";
import Paper from "@material-ui/core/Paper/Paper";
import {
  FieldArray,
  reduxForm,
  InjectedFormProps,
  InjectedArrayProps,
  Field
} from "redux-form";
import { connect } from "react-redux";
import { AddRepeaterFieldButton } from "./AddRepeaterFieldButton";
import ReduxTextField from "../../../../components/forms/ReduxTextField";
import Button from "@material-ui/core/Button/Button";
import {
  composeValidators,
  emailDomainsValidation,
  ipRangeValidation
} from "../../../../helpers/validations/validation";
import { ButtonWithTooltip } from "../../../../components/ButtonWithTooltip";
import {
  EmailDomainsType,
  IPRangesType,
  SettingsFormValuesType,
  EmailDomainsValidationErrorsType,
  IPRangeValidationErrorsType,
  ValidationErrorsType
} from "../../../../types/client-admin-settings";
import emailDomainsSelector from "../../../../selectors/email-domains";
import ipRangesSelector from "../../../../selectors/ip-ranges";
import { bindActionCreators } from "redux";
import { putEmailDomainsAndIpRangesRequestsAction } from "../../../../actions/client-admin-settings";
import { EmailDomainRestrictionTooltipText } from "./EmailDomainRestrictionList";
import { WhiteListedRangeTooltipText } from "./WhiteListedIPRangeList";

export const ADMIN_SETTINGS_FORM_NAME = "AdminSettingsForm";

interface AdminSettingsFormProps extends InjectedFormProps, InjectedArrayProps {
  closeEditMode: () => void;
  updateSettings: (values: SettingsFormValuesType, cb: () => void) => void;
}

const EmailDomainsRepeater = props => {
  const { fields } = props;
  const rows = fields.map((name, index) => {
    return (
      <div className="row align-items-center mb-2" key={name}>
        <div className="col-sm-12 col-md-1 pt-4 repeater-remove-button-wrap">
          <Button
            className="action-button array-button"
            variant="fab"
            type="button"
            color="secondary"
            onClick={() => fields.remove(index)}
          >
            <Icon>delete</Icon>
          </Button>
        </div>
        <div className="col-sm-12 col-md-5 col-lg-4">
          <Field
            name={`${name}.domain`}
            component={ReduxTextField}
            margin="normal"
            type="string"
            label="Domain"
          />
        </div>
      </div>
    );
  });

  return (
    <>
      {rows}
      {!!fields.length && <div className="mb-4"></div>}
    </>
  );
};

const IpRangesRepeater = props => {
  const { fields } = props;

  const rows = fields.map((name, index) => {
    return (
      <div className="row align-items-center mb-2" key={index}>
        <div className="col-sm-12 col-md-1 pt-4 repeater-remove-button-wrap">
          <Button
            className="action-button array-button"
            variant="fab"
            type="button"
            color="secondary"
            onClick={() => fields.remove(index)}
          >
            <Icon>delete</Icon>
          </Button>
        </div>
        <div className="col-sm-12 col-md-5 col-lg-4">
          <Field
            name={`${name}.address`}
            component={ReduxTextField}
            margin="normal"
            type="string"
            label="IP Address"
          />
        </div>
        <div className="col-sm-12 col-md-6 col-lg-7">
          <Field
            name={`${name}.note`}
            component={ReduxTextField}
            margin="normal"
            type="string"
            label="Note"
          />
        </div>
      </div>
    );
  });

  return (
    <>
      {rows}
      {!!fields.length && <div className="mb-4"></div>}
    </>
  );
};

const AdminSettingsForm = (props: AdminSettingsFormProps) => {
  const { closeEditMode, handleSubmit, dirty, invalid, updateSettings } = props;
  let saveButtonTooltip = "";
  if (!dirty) {
    saveButtonTooltip = "The form has not yet been edited";
  }
  if (invalid) {
    saveButtonTooltip =
      "The form contains empty values for required fields or some fields contain an invalid value.";
  }

  return (
    <form
      onSubmit={handleSubmit(values =>
        updateSettings(values as SettingsFormValuesType, closeEditMode)
      )}
    >
      <div>
        <div className="block-header-wrapper flex-column-in-mobile">
          <h4>
            Email Domain Restriction
            <TooltipIcon
              placement={"top-start"}
              TransitionComponent={Zoom}
              disableFocusListener
              disableTouchListener
              title={<EmailDomainRestrictionTooltipText />}
            >
              <Icon
                style={{
                  marginLeft: "5px",
                  marginBottom: "2px",
                  verticalAlign: "middle"
                }}
              >
                info
              </Icon>
            </TooltipIcon>
          </h4>
        </div>
        <Paper className="p-4 mb-4">
          <FieldArray
            name="emailDomains"
            component={EmailDomainsRepeater}
            rerenderOnEveryChange
          />
          <FieldArray name="emailDomains" component={AddRepeaterFieldButton} />
        </Paper>
      </div>
      <div className="pt-4">
        <div className="block-header-wrapper flex-column-in-mobile">
          <h4>
            Whitelisted IP Range
            <TooltipIcon
              placement={"top-start"}
              TransitionComponent={Zoom}
              disableFocusListener
              disableTouchListener
              title={<WhiteListedRangeTooltipText />}
            >
              <Icon
                style={{
                  marginLeft: "5px",
                  marginBottom: "2px",
                  verticalAlign: "middle"
                }}
              >
                info
              </Icon>
            </TooltipIcon>
          </h4>
        </div>
        <Paper className="p-4 mb-4">
          <FieldArray
            name="ipRanges"
            component={IpRangesRepeater}
            rerenderOnEveryChange
          />
          <FieldArray name="ipRanges" component={AddRepeaterFieldButton} />
        </Paper>
      </div>
      <div className="row">
        <div className="col-sm-6 mb-2">
          <Button
            variant="contained"
            onClick={closeEditMode}
            className="full-width-in-mobile"
          >
            Cancel
          </Button>
        </div>
        <div className="col-sm-6 text-right">
          <ButtonWithTooltip
            color="primary"
            variant="contained"
            className="full-width-in-mobile"
            type="submit"
            disabled={!dirty || invalid}
            tooltipText={saveButtonTooltip}
            onClick={() => null}
          >
            Save
          </ButtonWithTooltip>
        </div>
      </div>
    </form>
  );
};

const formComponent = reduxForm({
  form: ADMIN_SETTINGS_FORM_NAME,
  validate: composeValidators((values: SettingsFormValuesType) => {
    const { emailDomains, ipRanges = [] } = values;
    const errors: ValidationErrorsType = {};
    const emailDomainsArrayErrors: EmailDomainsValidationErrorsType[] = [];
    const ipRangeArrayErrors: IPRangeValidationErrorsType[] = [];

    emailDomains.forEach((item: EmailDomainsType, index: number) => {
      emailDomainsArrayErrors[index] = {
        domain: emailDomainsValidation(item.domain, values)
      };
    });

    ipRanges.forEach((item: IPRangesType, index: number) => {
      ipRangeArrayErrors[index] = {
        address: ipRangeValidation(item.address, values)
      };
    });

    errors.ipRanges = ipRangeArrayErrors;
    return {
      emailDomains: emailDomainsArrayErrors,
      ipRanges: ipRangeArrayErrors
    };
  })
})(AdminSettingsForm);

const mapStateToProps = state => {
  const emailDomains = emailDomainsSelector.getItems(state);
  const ipRanges = ipRangesSelector.getItems(state);
  return {
    initialValues: {
      emailDomains: emailDomains,
      ipRanges: ipRanges
    }
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      updateSettings: putEmailDomainsAndIpRangesRequestsAction
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
