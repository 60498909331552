import * as React from "react";
import {connect} from "react-redux";
import {withStyles, Modal, WithStyles, Tooltip, Icon} from "@material-ui/core";
import {defaultStyleWidth} from "../../../../styles/material/popup-style";
import {
  getIsOpenModalCalendar,
  getOpenModalCalendarEvents,
  getOpenModalCalendarDate
} from "../../../../selectors/modal";
import {ICalendarEvent} from "../../../../reducers/calendar";
import {calendarModalClose} from "../../../../actions/modals";
import {Link} from "react-router-dom";
import {Moment} from "moment";
import {push} from "react-router-redux";
import StatusBlock from "../../../common/StatusBlock";
import {renderTitle} from "./CalendarMonthView";
import {formatDateWithSlashesDdMmYyyy} from "../../../../helpers/date-formatter";

interface IStateToProps {
  isOpenModal: any;
  date: Moment;
  events: ICalendarEvent[];
}

interface IDispatchToProps {
  modalClose: () => any;
  push: (url: string) => void;
}

class CalendarModal extends React.Component<IStateToProps & IDispatchToProps & WithStyles<any>> {
  handleEventClick = (event) => () => {
    this.props.push(`/client/schedule/test/${event.id}`);
    this.props.modalClose();
  };

  render() {
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.props.isOpenModal}
        onClose={this.handleClose}
        className="d-flex overflow-auto"
      >
        <div className="styled-modal lg">
          <h4 className="block-header mb-4" id="modal-title">
            {this.props.date.format("DD/MM/YYYY") + ", " + this.props.date.format("dddd")}
          </h4>
          <div className="d-flex justify-content-between flex-row pl-3 pr-4 pb-3">
            <span className="font-weight-bold styled-modal-text">Project</span>
            <span className="font-weight-bold styled-modal-text fb30 pl-2">Test Title</span>
            <span className="font-weight-bold styled-modal-text">Proposed date from/to</span>
            <span className="font-weight-bold styled-modal-text fb10 text-right">Total</span>
          </div>
          <div className="styled-modal-body">
            {this.props.events.map(event => {
              return (
                <div className="modal-event d-flex justify-content-between" key={event.id}>
                  <span className="styled-modal-text pl-2" >{event.projectName}</span>
                  <Link
                    to={"#"}
                    onClick={this.handleEventClick(event)}
                    key={event.id}
                   className="styled-modal-text-link"
                  >
                    {event.status && (
                      <StatusBlock status={event.status} noText={true} />
                    )}
                    {event.hash}-{event.name}
                  </Link>
                  {event.proposal ? (
                      <div className="styled-modal-text">
                        {formatDateWithSlashesDdMmYyyy(
                          event.proposal?.dateStart
                        )}
                        {" "}to{" "}
                        {formatDateWithSlashesDdMmYyyy(event.proposal?.dateEnd)}
                      </div>
                  ) : (
                      <div className="styled-modal-text"/>
                  )}
                  {event.proposal ? (
                    <div className="styled-modal-text fb10 text-right pr-3">
                      {event.proposal?.currency?.symbol}
                      {event.proposal?.fullTotalCostForClient}
                    </div>
                  ) : (
                      <div className="styled-modal-text fb10"/>
                  )}
                </div>
              );
            })
            }
          </div>
        </div>
      </Modal>
    );
  }

  private readonly handleClose = () => {
    this.props.modalClose();
  };
}

function mapStateToProps(state) {
  return {
    isOpenModal: getIsOpenModalCalendar(state),
    date: getOpenModalCalendarDate(state),
    events: getOpenModalCalendarEvents(state)
  };
}

const connectedComponent = connect<IStateToProps, IDispatchToProps>(mapStateToProps, {
  modalClose: calendarModalClose,
  push
})(CalendarModal);

export default withStyles(defaultStyleWidth(620))(connectedComponent as any);
