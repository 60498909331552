import React from 'react';
import {
  composeValidators,
  fieldsNotEmpty,
  fieldMaxLength
} from "../../../../helpers/validations/validation";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import {putEmailTemplateItem, emailTemplatesRequest} from "../../../../actions/email-templates";
import parameterFetcher from '../../../../components/parametersFetcher';
import selector from '../../../../selectors/email-templates';
import { EmailTemplatesForm } from "./EmailTemplatesForm";
import routerModal from '../../../../components/routerModal';

const FORM_NAME = 'EmailTemplatesUpdate';

class EmailTemplatesUpdate extends React.Component {
  render() {
    const { handleSubmit, putData, backRoute } = this.props;

    return (
      <form noValidate autoComplete="off"
        onSubmit={handleSubmit((values) => {
          putData(values, FORM_NAME, backRoute);
        })}>
        <EmailTemplatesForm {...this.props} backRoute={backRoute}/>
      </form>
    )
  }
}

const formConnected = reduxForm({
  form: FORM_NAME,
  validate: composeValidators(
    fieldsNotEmpty(['subject', 'body',]),
    fieldMaxLength(['subject',], 150),
  )
})(EmailTemplatesUpdate);

const connected = parameterFetcher(connect(undefined, {
  putData: putEmailTemplateItem,
})(formConnected), FORM_NAME, emailTemplatesRequest.getItem, selector.getItemById);

export default routerModal(connected);
