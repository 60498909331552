import React from 'react'
import {connect} from "react-redux";
import {getFinances, putFinances, getFinanceItem} from "../../../../actions/finances";
import Paper from "@material-ui/core/Paper/Paper";
import {Tooltip, Icon} from "@material-ui/core";


class FinanceList extends React.Component {
  componentDidMount() {
    this.props.loadItems();
  }

  render() {
    const {finances} = this.props;

    return (
      <div>
        <h6>Admin Fee</h6>
        <Paper className="p-4 mb-4">
          <div className="row">
            <div className="col-md-4 font-weight-bold">
              Client Margin:
            </div>
            <div className="col-md-8">
              {finances.client_margin}%
            </div>
          </div>

          <div className="row mb-2 mt-2 d-flex align-items-center">
            <div className="col-md-4">
              <b>Tester Threshold: </b>

            </div>
            <div className="col-md-8 finances-page-tooltip">
              &pound;{finances.threshold_GBP} or ${finances.threshold_USD} or &euro;{finances.threshold_EUR}
              <Tooltip
                placement={"bottom-end"}
                disableFocusListener
                disableTouchListener
                title="Tester's Day Rate.">
                <Icon>info</Icon>
              </Tooltip>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <b>Tester Margin: </b>
            </div>
            <div className="col-md-8 finances-page-tooltip">
              {finances.tester_margin}%
                <Tooltip
                  placement={"bottom-end"}
                  disableFocusListener
                  disableTouchListener
                  title="Above the threshold Tester will pay the Margin.">
                  <Icon>info</Icon>
                </Tooltip>
            </div>
          </div>

        </Paper>

        <h6>Admin Fee Cancelled (%of the day rate per day from Booking canceled)</h6>
        <Paper className="p-4 mb-4">

          <div className="row">
            <div className="col-md-4">
              <b>Client: </b>
            </div>
            <div className="col-md-8">
              {finances.admin_fee_client}%
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <b>Tester: </b>
            </div>
            <div className="col-md-8">
              {finances.admin_fee_tester}%
            </div>
          </div>

        </Paper>
        {finances.managedServices && !!finances.managedServices.length && (
          <div>
            <h6 className="finances-page-tooltip">
              AVORD managed services
              <Tooltip
                  placement={"bottom-end"}
                  disableFocusListener
                  disableTouchListener
                  title="Clients with additional AVORD managed services in SoW document and invoice">
                <Icon>info</Icon>
              </Tooltip>
            </h6>
            <Paper className="p-4 mb-4">
              {finances.managedServices.map(service=>{
                return(
                    <div className="row mb-2">
                      <div className="col-sm-3" >
                        <b>
                          {service.company ? service.company.name : "no name"}:
                        </b>
                      </div>
                      <div className="col-sm-9">
                        Service name: <b>{service.description}</b>,
                        Price: <b>£{service.price}</b> per day,
                        days from proposal <b>{service.daysPercent}%</b>
                      </div>
                    </div>
                )
              })}
            </Paper>
          </div>
        )}
        {finances.additionalServices && !!finances.additionalServices.length && (
            <div>
              <h6 className="finances-page-tooltip">
                Additional Services
                <Tooltip
                    placement={"bottom-end"}
                    disableFocusListener
                    disableTouchListener
                    title="By adding a customer to this section, the AVORD validation required for all tester proposals">
                  <Icon>info</Icon>
                </Tooltip>
              </h6>
              <Paper className="p-4 mb-4">
                {finances.additionalServices.map(service=>{
                  return(
                      <div className="row mb-2" key={service.name}>
                        <li className="col-sm-3" >
                          <b>
                            {service.name || "no name"}
                          </b>
                        </li>
                      </div>
                  )
                })}
              </Paper>
            </div>
        )}
      </div>
    )
  }
}

export default connect(
  state => ({
    finances: state.finances,
  }),

  {
    loadItems: getFinances,
    puItems: putFinances,
    loadItem: getFinanceItem,
  },
)(FinanceList);