import {IConverterFromSwagger, IConverterToSwagger} from "./converter.interface";

export const converterFromSwagger: IConverterFromSwagger<any, any> = modelIn => {
  const model = {
    ...modelIn,
  };

  if (!!modelIn.dateFrom) {
    model.dateFrom = modelIn.dateFrom;
  }

  if (!!modelIn.dateTo) {
    model.dateTo = modelIn.dateTo;
  }

  return model;
};

export const converterToSwagger: IConverterToSwagger<any, any> = model => {
  return {
    ...model,
    profile_picture: `/api/documents/${model.profile_picture}`,
  };
};
