import { invert } from "lodash";

export enum TestStatus {
  STATUS_DRAFT = 1,
  STATUS_SCHEDULED = 2,
  STATUS_BOOKING_REQUESTED = 3,
  STATUS_PROPOSAL_SUBMITTED = 4,
  STATUS_PROPOSAL_SUBMITTED_CANCELLED_BY_CUSTOMER = 5,
  STATUS_PROPOSAL_SUBMITTED_CANCELLED_BY_TESTER = 6,
  STATUS_BOOKED = 7,
  STATUS_REPORT_SUBMITTED = 8,
  STATUS_REPORT_ACCEPTED = 9,
  STATUS_COMPLETED = 10,
  STATUS_BOOKING_CANCELLED_BY_CLIENT = 11,
  STATUS_BOOKING_CANCELLED_BY_TESTER = 12,
  STATUS_REPORT_REJECTED = 13,
  STATUS_AMENDMENT_SENT = 14,
  STATUS_AMENDMENT_RESPONDED = 15,
  STATUS_BOOKING_REJECTED_BY_CUSTOMER = 16,
  STATUS_BOOKING_REJECTED_BY_TESTER = 17,
  STATUS_BOOKING_REQUESTED_EDITED = 18,
  STATUS_AVORD_VALIDATION = 19
}

export const ALL_TEST_STATUSES_ARRAY = [
  TestStatus.STATUS_DRAFT,
  TestStatus.STATUS_SCHEDULED,
  TestStatus.STATUS_BOOKING_REQUESTED,
  TestStatus.STATUS_PROPOSAL_SUBMITTED,
  TestStatus.STATUS_PROPOSAL_SUBMITTED_CANCELLED_BY_CUSTOMER,
  TestStatus.STATUS_PROPOSAL_SUBMITTED_CANCELLED_BY_TESTER,
  TestStatus.STATUS_BOOKED,
  TestStatus.STATUS_REPORT_SUBMITTED,
  TestStatus.STATUS_REPORT_ACCEPTED,
  TestStatus.STATUS_COMPLETED,
  TestStatus.STATUS_BOOKING_CANCELLED_BY_CLIENT,
  TestStatus.STATUS_BOOKING_CANCELLED_BY_TESTER,
  TestStatus.STATUS_REPORT_REJECTED,
  TestStatus.STATUS_AMENDMENT_SENT,
  TestStatus.STATUS_AMENDMENT_RESPONDED,
  TestStatus.STATUS_BOOKING_REJECTED_BY_CUSTOMER,
  TestStatus.STATUS_BOOKING_REJECTED_BY_TESTER,
  TestStatus.STATUS_BOOKING_REQUESTED_EDITED,
  TestStatus.STATUS_AVORD_VALIDATION
];

export enum TestStatusNames {
  STATUS_DRAFT = "Draft",
  STATUS_SCHEDULED = "Scheduled",
  STATUS_BOOKING_REQUESTED = "Booking requested",
  STATUS_PROPOSAL_SUBMITTED = "Proposal submitted",
  STATUS_PROPOSAL_SUBMITTED_CANCELLED_BY_CUSTOMER = "Proposal submitted cancelled by client",
  STATUS_PROPOSAL_SUBMITTED_CANCELLED_BY_TESTER = "Proposal submitted cancelled by tester",
  STATUS_REPORT_REJECTED = "Rejected",
  STATUS_BOOKED = "Booked",
  STATUS_REPORT_SUBMITTED = "Report submitted",
  STATUS_REPORT_ACCEPTED = "Report accepted",
  STATUS_COMPLETED = "Completed",
  STATUS_BOOKING_CANCELLED_BY_CLIENT = "Booking cancelled by client",
  STATUS_BOOKING_CANCELLED_BY_TESTER = "Booking cancelled by tester",
  STATUS_AMENDMENT_SENT = "Clarifications requested",
  STATUS_AMENDMENT_RESPONDED = "Clarifications responded",
  STATUS_BOOKING_REJECTED_BY_CUSTOMER = "Booking rejected by client",
  STATUS_BOOKING_REJECTED_BY_TESTER = "Booking rejected by tester",
  STATUS_BOOKING_REQUESTED_EDITED = "Booking requested edited",
  STATUS_AVORD_VALIDATION = "AVORD validation"
}

export enum TestStatusNamesForClientReportTab {
  STATUS_BOOKED = "In progress",
  STATUS_REPORT_SUBMITTED = "Report submitted",
  STATUS_REPORT_ACCEPTED = "Report accepted",
  STATUS_COMPLETED = "Completed",
  STATUS_BOOKING_CANCELLED_BY_CLIENT = "Cancelled by client",
  STATUS_BOOKING_CANCELLED_BY_TESTER = "Cancelled by tester",
  STATUS_AMENDMENT_SENT = "Clarifications requested",
  STATUS_AMENDMENT_RESPONDED = "Clarifications responded",
  STATUS_AVORD_VALIDATION = "AVORD validation"
}

export enum TestStatusNamesForClientBookingsTab {
  STATUS_BOOKING_REQUESTED = "Booking requested",
  STATUS_PROPOSAL_SUBMITTED = "Proposal submitted",
  STATUS_PROPOSAL_SUBMITTED_CANCELLED_BY_CUSTOMER = "Cancelled by client",
  STATUS_PROPOSAL_SUBMITTED_CANCELLED_BY_TESTER = "Cancelled by tester",
  STATUS_BOOKED = "Booked",
  STATUS_BOOKING_REJECTED_BY_CUSTOMER = "Rejected by client",
  STATUS_BOOKING_REJECTED_BY_TESTER = "Rejected by tester",
  STATUS_BOOKING_REQUESTED_EDITED = "Request edited",
  STATUS_AVORD_VALIDATION = "AVORD validation"
}

export enum TestStatusNamesForTesterBookingRequestsTab {
  STATUS_BOOKING_REQUESTED = "New",
  STATUS_PROPOSAL_SUBMITTED = "Proposal submitted",
  STATUS_PROPOSAL_SUBMITTED_CANCELLED_BY_CUSTOMER = "Cancelled by client",
  STATUS_PROPOSAL_SUBMITTED_CANCELLED_BY_TESTER = "Cancelled by tester",
  STATUS_BOOKED = "Booked",
  STATUS_BOOKING_REJECTED_BY_CUSTOMER = "Rejected by client",
  STATUS_BOOKING_REJECTED_BY_TESTER = "Rejected by tester",
  STATUS_BOOKING_REQUESTED_EDITED = "Request edited",
  STATUS_AVORD_VALIDATION = "AVORD validation"
}

export enum TestStatusNamesForTesterBookingsTab {
  STATUS_BOOKED = "In progress",
  STATUS_REPORT_SUBMITTED = "Report submitted",
  STATUS_REPORT_ACCEPTED = "Report accepted",
  STATUS_COMPLETED = "Completed",
  STATUS_BOOKING_CANCELLED_BY_CLIENT = "Cancelled by client",
  STATUS_BOOKING_CANCELLED_BY_TESTER = "Cancelled by tester",
  STATUS_AMENDMENT_SENT = "Clarifications requested",
  STATUS_AMENDMENT_RESPONDED = "Clarifications responded",
  STATUS_AVORD_VALIDATION = "AVORD validation"
}

export const getTestStatusName = (data: TestStatus): string => {
  return TestStatusNames[invert(TestStatus)[data]];
};

export const getTestStatusNameForClientReportTab = (
  data: TestStatus
): string => {
  return TestStatusNamesForClientReportTab[invert(TestStatus)[data]];
};

export const getTestStatusNameForClientBookingsTab = (
  data: TestStatus
): string => {
  return TestStatusNamesForClientBookingsTab[invert(TestStatus)[data]];
};

export const getTestStatusNameForTesterBookingRequestsTab = (
  data: TestStatus
): string => {
  return TestStatusNamesForTesterBookingRequestsTab[invert(TestStatus)[data]];
};

export const getTestStatusNameForTesterBookingsTab = (
  data: TestStatus
): string => {
  return TestStatusNamesForTesterBookingsTab[invert(TestStatus)[data]];
};

export const TestStatusesForQuestionsBlock = [
  TestStatus.STATUS_BOOKING_REQUESTED,
  TestStatus.STATUS_BOOKING_REQUESTED_EDITED,
  TestStatus.STATUS_BOOKING_REJECTED_BY_TESTER,
  TestStatus.STATUS_BOOKING_REJECTED_BY_CUSTOMER,
  TestStatus.STATUS_PROPOSAL_SUBMITTED,
  TestStatus.STATUS_PROPOSAL_SUBMITTED_CANCELLED_BY_CUSTOMER,
  TestStatus.STATUS_PROPOSAL_SUBMITTED_CANCELLED_BY_TESTER
];

const TestStatusesReportCanBeViewed = [
  TestStatus.STATUS_REPORT_SUBMITTED,
  TestStatus.STATUS_REPORT_ACCEPTED,
  TestStatus.STATUS_COMPLETED,
  TestStatus.STATUS_REPORT_REJECTED,
  TestStatus.STATUS_AMENDMENT_SENT,
  TestStatus.STATUS_AMENDMENT_RESPONDED,
  TestStatus.STATUS_AVORD_VALIDATION
];

export const testStatusShouldChangeToBookingRequested = (data: number) => {
  return (
    data === TestStatus.STATUS_BOOKING_REQUESTED ||
    data === TestStatus.STATUS_BOOKING_REQUESTED_EDITED ||
    data === TestStatus.STATUS_PROPOSAL_SUBMITTED
  );
};

export const reportCanBeViewed = (data: number): boolean => {
  return TestStatusesReportCanBeViewed.includes(data);
};

export const completedReportsTabMustBeViewed = (data: number): boolean => {
  return (
    data === TestStatus.STATUS_REPORT_SUBMITTED ||
    (data >= TestStatus.STATUS_REPORT_REJECTED &&
      data <= TestStatus.STATUS_AMENDMENT_RESPONDED)
  );
};

export const reportCanBeViewedByViewer = (data: number): boolean => {
  return (
    data === TestStatus.STATUS_REPORT_ACCEPTED ||
    data === TestStatus.STATUS_COMPLETED
  );
};

export const reportCanBeViewedByTester = (data: number): boolean => {
  return (
    (data >= TestStatus.STATUS_BOOKED && data <= TestStatus.STATUS_COMPLETED) ||
    (data >= TestStatus.STATUS_REPORT_REJECTED &&
      data <= TestStatus.STATUS_AMENDMENT_RESPONDED)
  );
};

export const testDetailsCanBeViewed = (data: number): boolean => {
  return (
    [
      TestStatus.STATUS_BOOKED,
      TestStatus.STATUS_BOOKING_CANCELLED_BY_CLIENT,
      TestStatus.STATUS_BOOKING_CANCELLED_BY_TESTER
    ].indexOf(data) !== -1
  );
};

export const testCanBeDeleted = (data: number): boolean => {
  return (
    [TestStatus.STATUS_DRAFT, TestStatus.STATUS_SCHEDULED].indexOf(data) !== -1
  );
};

export const reportCanBeSubmitted = (data: any): boolean => {
  return (
    data &&
    [TestStatus.STATUS_BOOKED, TestStatus.STATUS_AMENDMENT_RESPONDED].indexOf(
      data
    ) !== -1
  );
};

export const testerCanUploadDocuments = (data: any): boolean => {
  return (
    data &&
    data !== TestStatus.STATUS_REPORT_ACCEPTED &&
    data !== TestStatus.STATUS_REPORT_SUBMITTED
  );
};

export const checkTestIsNotDraftStatus = (status: number) =>
  status !== TestStatus.STATUS_DRAFT;

export const AfterBookedStatuses = [
  TestStatus.STATUS_BOOKED,
  TestStatus.STATUS_BOOKING_CANCELLED_BY_CLIENT,
  TestStatus.STATUS_BOOKING_CANCELLED_BY_TESTER,
  TestStatus.STATUS_REPORT_SUBMITTED,
  TestStatus.STATUS_AMENDMENT_SENT,
  TestStatus.STATUS_AMENDMENT_RESPONDED,
  TestStatus.STATUS_REPORT_ACCEPTED,
  TestStatus.STATUS_COMPLETED
];

export const TestIsInvoicedStatuses = [
  TestStatus.STATUS_BOOKING_CANCELLED_BY_CLIENT,
  TestStatus.STATUS_BOOKING_CANCELLED_BY_TESTER,
  TestStatus.STATUS_REPORT_ACCEPTED,
  TestStatus.STATUS_COMPLETED
];

export const ClientInvoicedStatuses = [
  TestStatus.STATUS_BOOKING_CANCELLED_BY_CLIENT,
  TestStatus.STATUS_REPORT_ACCEPTED,
  TestStatus.STATUS_COMPLETED
];

export const TesterInvoicedStatuses = [
  TestStatus.STATUS_BOOKING_CANCELLED_BY_TESTER
];

export const DisplayBookingProposalStatuses = [
  TestStatus.STATUS_PROPOSAL_SUBMITTED,
  TestStatus.STATUS_PROPOSAL_SUBMITTED_CANCELLED_BY_CUSTOMER,
  TestStatus.STATUS_PROPOSAL_SUBMITTED_CANCELLED_BY_TESTER,
  ...AfterBookedStatuses
];

export const EditServiceDatesStatuses = [
  // TestStatus.STATUS_BOOKED,
  // TestStatus.STATUS_PROPOSAL_SUBMITTED,
  // TestStatus.STATUS_PROPOSAL_SUBMITTED_CANCELLED_BY_CUSTOMER,
  // TestStatus.STATUS_PROPOSAL_SUBMITTED_CANCELLED_BY_TESTER,
  TestStatus.STATUS_BOOKING_CANCELLED_BY_CLIENT,
  TestStatus.STATUS_BOOKING_CANCELLED_BY_TESTER,
  TestStatus.STATUS_REPORT_SUBMITTED,
  TestStatus.STATUS_AMENDMENT_SENT,
  TestStatus.STATUS_AMENDMENT_RESPONDED,
  TestStatus.STATUS_REPORT_ACCEPTED
];

export const ProposalEditAccessStatusesForTester = [
  TestStatus.STATUS_PROPOSAL_SUBMITTED,
  TestStatus.STATUS_BOOKED
];
