import * as Types from '../constants/action-type';
import {createLoaderAction} from '../helpers/action-helper';
import {createAction} from "redux-actions";
import {setRequestInProcess} from "./request";
import {handleHttpErrors} from "../helpers/errors/http-error-handler";
import {getTestersReviewsRequest} from "../api/requests/tester-reviews";

export const getTestersReviewsAction = (id: string) => async dispatch => {
  dispatch(setRequestInProcess(true, 'getTestersReviewsAction'));
  /** part responsible for a loader state */
  dispatch(createLoaderAction(Types.TESTERS).setIsNotLoaded());

  let data = [];

  try {
    data = await getTestersReviewsRequest(id);

    dispatch(createAction(Types.TESTER_REVIEWS_ADD, () => ({id, data}))());
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(setRequestInProcess(false, 'getTestersReviewsAction'));
};
