import React from 'react';
import {composeValidators, fieldIsNumber, fieldsNotEmpty} from "../../../../helpers/validations/validation";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import { CountryForm } from "./CountryForm";
import { withStyles } from "@material-ui/core/styles/index";
import { withRouter } from 'react-router-dom'
import { defaultStyleWidth } from '../../../../styles/material/popup-style';
import routerModal from '../../../../components/routerModal';
import { postCountryItem } from "../../../../actions";

const FORM_NAME = 'CountryCreate';

class CountryCreate extends React.Component {
  render() {
    const { handleSubmit, postData, backRoute } = this.props;

    return (
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit((values) => {
          postData(values, FORM_NAME, backRoute);
        })}
      >
        <CountryForm backRoute={backRoute}/>
      </form>
    );
  }
}

const formConnected = reduxForm({
  form: FORM_NAME,
  validate: composeValidators(
    fieldsNotEmpty([
      'name',
      'dialingCode',
    ]),
    fieldIsNumber([
      'dialingCode',
    ])
  )
})(CountryCreate);

const connected = connect(undefined, {
  postData: postCountryItem,
})(formConnected);

export default routerModal(withRouter(withStyles(defaultStyleWidth(400))(connected)));