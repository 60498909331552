import 'rxjs';
import {combineEpics} from 'redux-observable';
import {Action} from 'redux';
import {specialismSaveBatch} from "./specialism";
import {requestAccountDeletionEpic} from "./request-account-deletion";
import {testerAccountSubmitEpic} from "./tester-account-submit";
import {clientAccountSubmitEpic} from "./client-account-submit";
import {getContentManagementByKeyEpic} from "./content-management";
import {
  putTesterProfileEpic,
  testerBankDetailsGetEpic,
  testerCertificatesEpic,
  testerDBSCertificatesEpic,
  testerInsurancesEpic,
  testerProfileGetEpic,
  testerSpecialismsGetEpic
} from "./tester-profile";
import {archiveCertificateEpic, postCertificateEpic} from "./certificate";
import {archiveDBSCertificateEpic, postDBSCertificateEpic} from "./dbs-certificate";
import {
  additionalDocumentsSearchApplyEpic,
  archiveAdditionalTestDocumentEpic,
  archiveTestDocumentEpic,
  documentsSearchApplyEpic,
  postDocumentEpic
} from "./documents";
import {putBankDetailsEpic} from "./bank-details";
import {testDeleteSubmitEpic, testDeleteWithoutRedirectSubmitEpic} from "./test-delete-submit";
import {calendarDateChangeEpic, calendarDateTypeChangeEpic} from "./calendar";
import {questionsSearchApplyEpic} from "./questions";
import {amendmentsSearchApplyEpic} from "./amendments";
import {postImageEpic} from "./images";
import {messagesSearchApplyEpic} from "./messages";
import {postSecuredDocumentEpic} from "./secured-documents";

export interface IAction extends Action {
  payload?: any;
}

export interface IActionParams<T> extends Action {
  payload: T[];
}

export interface IActionPayload<T> extends Action {
  payload: T
}

export const rootEpic = combineEpics(
  specialismSaveBatch,
  requestAccountDeletionEpic,
  testerAccountSubmitEpic,
  clientAccountSubmitEpic,
  getContentManagementByKeyEpic,
  testerProfileGetEpic,
  testerBankDetailsGetEpic,
  testerSpecialismsGetEpic,
  testerCertificatesEpic,
  testerInsurancesEpic,
  postCertificateEpic,
  postDocumentEpic,
  postSecuredDocumentEpic,
  postImageEpic,
  archiveCertificateEpic,
  testerDBSCertificatesEpic,
  putBankDetailsEpic,
  putTesterProfileEpic,
  testDeleteSubmitEpic,
  testDeleteWithoutRedirectSubmitEpic,
  calendarDateChangeEpic,
  calendarDateTypeChangeEpic,
  questionsSearchApplyEpic,
  messagesSearchApplyEpic,
  amendmentsSearchApplyEpic,
  documentsSearchApplyEpic,
  additionalDocumentsSearchApplyEpic,
  archiveTestDocumentEpic,
  archiveAdditionalTestDocumentEpic,
  archiveDBSCertificateEpic,
  postDBSCertificateEpic
);




