import getCrudSelectors from '../helpers/selector-helper';
import {createSelector} from "reselect";
import {CONTENT_MANAGEMENT_KEY} from "../constants/content-management";
import {IContentManagement} from "../models/content-management.interface";

const getContentManagement = state => state.contentManagement;
const selectors = getCrudSelectors(getContentManagement);

export const findByKey = (key: CONTENT_MANAGEMENT_KEY) => (state: IContentManagement[]) => {
  return state.find(element => element.contentKey === key);
};

const getByKey = (key: CONTENT_MANAGEMENT_KEY) => createSelector(
  selectors.getItems,
  findByKey(key)
);

export {getByKey};

export default selectors;
