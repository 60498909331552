import * as React from "react";
import {connect} from "react-redux";
import {Modal} from "@material-ui/core";
import {questionModalClose} from "../../../actions/modals";
import {getIsOpenModalQuestion, getModalQuesionItem} from "../../../selectors/modal";
import {FieldGeneric} from "../../../components/forms/FieldGeneric";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {reduxForm, InjectedFormProps} from "redux-form";
import ReduxTextField from "../../../components/forms/ReduxTextField";
import {questionModalSubmit} from "../../../actions/question";
import {IQuestion} from "../../../models/question.interface";
import {QUESTION_FORM_NAME} from "../../../constants/forms";
import {composeValidators, fieldMaxLength, fieldsNotEmpty} from "../../../helpers/validations/validation";

interface IStateToProps {
  isOpenModal: any;
  item: IQuestion | false;
}

interface IDispatchToProps {
  modalClose: () => any;
  modalSubmit: (question, idQuestion) => any;
}

class QuestionModal extends React.Component<IStateToProps
  & IDispatchToProps
  & RouteComponentProps<any>
  & InjectedFormProps<any>> {
  render() {
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.props.isOpenModal}
        onClose={this.handleClose}
        className="d-flex overflow-auto"
      >
        <div className="styled-modal sm">
          <h4>Question</h4>
          <form noValidate autoComplete="off" onSubmit={this.props.handleSubmit((values) => {
            if (this.props.item) {
              this.props.modalSubmit(values.question.trim(), this.props.item.id);
            }
          })}>
            <div className="styled-modal-body">
              {this.props.item && this.props.item.content}
            </div>
            <hr/>
            <FieldGeneric
              name="question"
              rows={4}
              multiline={true}
              label="Answer *"
              margin="normal"
              component={ReduxTextField}
              inputProps={{maxLength: 2000}}
            />
            <div className="text-right">
              <button className="btn-cancel" onClick={this.handleClose}>Cancel</button>
              <button type="submit" className="btn-save">Save</button>
            </div>
          </form>
        </div>
      </Modal>
    );
  }

  private readonly handleClose = () => {
    this.props.modalClose();
    this.props.untouch("question");
    this.props.change("question", "");
  };
}

function mapStateToProps(state) {
  return {
    isOpenModal: getIsOpenModalQuestion(state),
    item: getModalQuesionItem(state),
  };
}

const connectedComponent = connect<IStateToProps, IDispatchToProps>(mapStateToProps as any, {
  modalClose: questionModalClose,
  modalSubmit: questionModalSubmit,
})(QuestionModal);

const formed = reduxForm({
  form: QUESTION_FORM_NAME,
  destroyOnUnmount: false,
  validate: composeValidators(
    fieldsNotEmpty(["question"]),
    fieldMaxLength(["question"], 2000),
  ),
})(connectedComponent);

const routered = withRouter(formed as any);

export default withRouter(routered as any) as any;
