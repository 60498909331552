import {STORAGE_TYPE} from "constants/storage";
import storage from "local-storage-fallback";


/**
 * In order to separate implementation
 * add wrapper to the functions currently from local storage
 */

export const setValue = (key: STORAGE_TYPE, value: any) => {
  storage.setItem(key, JSON.stringify(value));
};

export const getValue = (key: STORAGE_TYPE) => {
  try {
    return JSON.parse(storage.getItem(key) || "");
  } catch (e) {
    return ;
  }
};

export const removeValue = (key: STORAGE_TYPE) => {
  return storage.removeItem(key);
};
