import { Observable } from "rxjs";
import {
  flatMap,
  switchMap,
  take,
} from "rxjs/operators";
import { ActionsObservable } from "redux-observable";
import * as actionType from "../constants/action-type";
import * as Request from "../api/requests/certificates";
import { fromPromise } from "rxjs/internal-compatibility";
import { IActionPayload } from "./index";
import { startEndCatch } from "./pipes/errors";
import { IPostCertificateAction } from "../actions/certificate";
import { createAction } from "redux-actions";
import { merge } from "rxjs/internal/observable/merge";
import { reset } from "redux-form";
import { push } from "react-router-redux";
import { createCrudAction } from "../helpers/action-helper";
import { of } from "rxjs/internal/observable/of";
import { IDocument } from "../models/document.interface";

export const postCertificateEpic = (action$: ActionsObservable<IActionPayload<IPostCertificateAction>>): Observable<any> => {
  return action$
    .ofType(actionType.CERTIFICATE_POST)
    .pipe(
      switchMap((action: IActionPayload<IPostCertificateAction>)=> {
        return merge(
          of(createAction(actionType.SECURED_DOCUMENT_POST, () => action.payload.certificate.evidence)()),
          action$
            .ofType(actionType.SECURED_DOCUMENT_POST_COMPLETED)
            .pipe(
              take(1),
              switchMap((fileIdentifier: IActionPayload<IDocument>) => {
                return fromPromise(Request.postItem({
                  ...action.payload.certificate,
                  evidence: fileIdentifier.payload.id ? '' + fileIdentifier.payload.id: '',
                }))
                .pipe(
                  flatMap(data => {
                    return [
                      reset(action.payload.formName),
                      push(action.payload.backRoute),
                      createCrudAction(actionType.CERTIFICATES).addItem(data),
                    ];
                  }),
                  ...startEndCatch('postCertificateEpic')
                );
              })
            )
        );
      }),
    );
};

export const archiveCertificateEpic = (action$: ActionsObservable<IActionPayload<string>>): Observable<any> => {
  return action$
    .ofType(actionType.CERTIFICATE_ARCHIVE)
    .pipe(
      switchMap((action: IActionPayload<string>) => {
        return fromPromise(Request.deleteItem(action.payload))
          .pipe(
            flatMap((data) => {
              return [
                createAction(actionType.CERTIFICATES_GET)(),
              ];
            }),
            ...startEndCatch('archiveCertificateEpic'),
          );
      }),
    );
};
