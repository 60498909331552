import { reset } from "redux-form";
import { goBack, push } from "react-router-redux";
import * as Types from "../constants/action-type";
import { openSnackbar } from "./snackbar";
import { setRequestInProcess } from "./request";
import { handleHttpErrors } from "helpers/errors/http-error-handler";
import {
  getDetails,
  getDetailsById,
  updateCompanyDetails,
} from "api/requests/company-details";
import { createCrudAction } from "helpers/action-helper";
import { MILLISECONDS_FOR_SHOWING_MESSAGE } from "./const";

interface ICompanyDetailsProps {
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    companyId: number;
    companyName: string;
    contactEmail: string;
    contactFirstName: string;
    contactLastName: string;
    contactPhone: string;
    postCode: string;
    sector: number;
    state: string;
    town: string;
}

export const getCompanyDetails =
  (companyId: null | string = null) =>
  async (dispatch) => {
    dispatch(setRequestInProcess(true, "getCompanyDetails"));

    let data;
    try {
      if (companyId) {
        data = await getDetailsById(companyId);
      } else {
        data = await getDetails();
      }
      dispatch(createCrudAction(Types.COMPANY_DETAILS).addItem(data));
    } catch (error) {
      handleHttpErrors(error, dispatch);
    }

    dispatch(setRequestInProcess(false, "getCompanyDetails"));
  };

export const putCompanyDetails =
  (model: ICompanyDetailsProps, backRoute: string, formName: string) => async (dispatch) => {
    dispatch(setRequestInProcess(true, "putCompanyDetails"));

  let data;

  try {
    await updateCompanyDetails(model);

      if (model.hasOwnProperty("companyId")) {
        history.back()
      } else {
        try {
          data = await getDetails();
          dispatch(createCrudAction(Types.COMPANY_DETAILS).removeItems([]));
          dispatch(createCrudAction(Types.COMPANY_DETAILS).addItem(data));
          dispatch(push(backRoute));
        } catch (error) {
          handleHttpErrors(error, dispatch);
        }
      }

      dispatch(reset(formName));

      dispatch(openSnackbar("Company details has just been updated", MILLISECONDS_FOR_SHOWING_MESSAGE));
    } catch (error) {
      handleHttpErrors(error, dispatch);
    }

  dispatch(setRequestInProcess(false, 'putCompanyDetails'));
};
