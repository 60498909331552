import * as React from "react";
import { Paper, Radio, FormControlLabel } from "@material-ui/core";
import ReduxTextField from "../../../components/forms/ReduxTextField";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import ReduxRadioButton from "../../../components/forms/ReduxRadioButton";
import { FieldGeneric } from "../../../components/forms/FieldGeneric";
import ReduxDatePicker from "../../../components/forms/ReduxDatePicker";

export const INVOICES_SEARCH_FORM_NAME = "InvoicesSearchForm";

class InvoicesSearchForm extends React.Component<
  InjectedFormProps<{}, {}> & {
    submitFilterChanges: (...any) => void;
    valuesData: { active: string; text: string };
  },
  any
> {
  render() {
    const { handleSubmit, reset } = this.props;

    return (
      <form
        className="mb-3"
        onSubmit={handleSubmit((values: { active: string; text: string }) => {
          this.props.submitFilterChanges(values.active, values.text);
        })}
      >
        <Paper className="pl-4 pr-4 pb-4">
          <div className="row">
            <div className="col-md-6 col-lg-3">
              <Field
                name="no"
                label="No."
                margin="normal"
                fullWidth
                component={ReduxTextField}
              />
            </div>
            <div className="col-md-6 col-lg-3">
              <FieldGeneric
                name="dateFrom"
                label="From"
                InputLabelProps={{
                  shrink: true
                }}
                component={ReduxDatePicker}
              />
            </div>
            <div className="col-md-6 col-lg-3">
              <FieldGeneric
                name="dateTo"
                label="To"
                InputLabelProps={{
                  shrink: true
                }}
                addEndOfDay={true}
                component={ReduxDatePicker}
              />
            </div>
            <div className="col-md-6 col-lg-3">
              <Field
                name="client"
                label="Client"
                margin="normal"
                fullWidth
                component={ReduxTextField}
              />
            </div>
            <div className="col-12 d-flex align-items-end justify-content-end flex-wrap pt-2">
              <div>
                <FieldGeneric
                  name="status"
                  margin="normal"
                  fullWidth
                  inline={true}
                  component={ReduxRadioButton}
                >
                  <FormControlLabel
                    value={"sent"}
                    control={<Radio />}
                    label="Sent"
                  />
                  <FormControlLabel
                    value={"paid"}
                    control={<Radio />}
                    label="Paid"
                  />
                  <FormControlLabel
                    value={""}
                    control={<Radio />}
                    label="All"
                  />
                </FieldGeneric>
              </div>
              <button
                className="btn-refresh"
                type="button"
                onClick={reset}
                style={{ marginBottom: 15 }}
              >
                Reset Filters
              </button>
            </div>
          </div>
        </Paper>
      </form>
    );
  }
}

const connectForm = reduxForm({
  form: INVOICES_SEARCH_FORM_NAME
})(InvoicesSearchForm);

export default connectForm;
