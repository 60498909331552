import * as React from "react";
import { NavLink, Route, Switch, withRouter } from "react-router-dom";
import RiskChart from "../risk-chart/RiskChart";
import TestDetails from "./TestDetails";
import { IAnyProps } from "interfaces/any-props.interface";
import BookingReport from "../../tester/bookings/booking-report/BookingReport";
import CompletedReports from "../../client/reports/completed-reports/CompletedReports";
import {
  ROLE_ACCOUNT_MANAGER,
  ROLE_ADMIN,
  ROLE_PLANNER,
  ROLE_TESTER,
  ROLE_VIEWER
} from "constants/roles";
import PermissionBlock from "components/auth/PermissionBlock";
import BaseReportPage from "../../client/reports/report-info/BaseReportPage";

class Test extends React.Component<
  IAnyProps,
  {
    value: string;
  }
> {
  baseUrl = this.props.match.url;

  state = {
    value: "details"
  };

  componentWillMount() {
    const path = this.props.location.pathname.split("/").pop();
    if (path) {
      this.setState({ value: path });
    }
  }

  ExecutiveSummaryPage = () => <BaseReportPage pageName="Executive Summary" />;
  TechnicalReportPage = () => <BaseReportPage pageName="Technical Report" />;

  render() {
    return (
      <div>
        <PermissionBlock roles={[ROLE_PLANNER, ROLE_ADMIN, ROLE_ACCOUNT_MANAGER]}>
          <div className="styled-tabs flex-column-in-mobile">
            <NavLink
              to={`${this.props.match.url}/details`}
              className="tab"
              activeClassName="active"
            >
              Details
            </NavLink>
            <NavLink
              to={`${this.props.match.url}/risk-chart`}
              className="tab"
              activeClassName="active"
            >
              Risk Chart
            </NavLink>
            <NavLink
              to={`${this.props.match.url}/report`}
              className="tab"
              activeClassName="active"
            >
              Completed Report(s)
            </NavLink>
            <NavLink
              to={`${this.props.match.url}/executive-summary`}
              className="tab"
              activeClassName="active"
            >
              Executive Summary
            </NavLink>
            <NavLink
              to={`${this.props.match.url}/technical-report`}
              className="tab"
              activeClassName="active"
            >
              Technical Report
            </NavLink>
          </div>
        </PermissionBlock>
        <PermissionBlock roles={[ROLE_VIEWER]}>
          <div className="styled-tabs flex-column-in-mobile">
            <NavLink
              to={`${this.props.match.url}/risk-chart`}
              className="tab"
              activeClassName="active"
            >
              Risk Chart
            </NavLink>
            <NavLink
              to={`${this.props.match.url}/executive-summary`}
              className="tab"
              activeClassName="active"
            >
              Executive Summary
            </NavLink>
            <NavLink
              to={`${this.props.match.url}/technical-report`}
              className="tab"
              activeClassName="active"
            >
              Technical Report
            </NavLink>
          </div>
        </PermissionBlock>
        <PermissionBlock roles={[ROLE_TESTER]}>
          <div className="styled-tabs flex-column-in-mobile">
            <NavLink
              to={`${this.props.match.url}/details`}
              className="tab"
              activeClassName="active"
            >
              Details
            </NavLink>
            <NavLink
              to={`${this.props.match.url}/risk-chart`}
              className="tab"
              activeClassName="active"
            >
              Risk Chart
            </NavLink>
            <NavLink
              to={`${this.props.match.url}/report`}
              className="tab"
              activeClassName="active"
            >
              Completed Report(s)
            </NavLink>
          </div>
        </PermissionBlock>
        <Switch>
          <Route
            path="/tester/bookings/:test/details"
            component={TestDetails}
          />
          <Route
            path="/tester/bookings/:test/risk-chart"
            component={RiskChart}
          />
          <Route
            path="/tester/bookings/:test/report"
            component={BookingReport}
          />

          <Route path="/client/reports/:test/details" component={TestDetails} />
          <Route
            path="/client/reports/:test/risk-chart"
            component={() => <RiskChart canExportTable />}
          />
          <Route
            path="/client/reports/:test/report"
            component={CompletedReports}
          />
          <Route
            path="/client/reports/:test/executive-summary"
            component={this.ExecutiveSummaryPage}
          />
          <Route
            path="/client/reports/:test/technical-report"
            component={this.TechnicalReportPage}
          />
        </Switch>
      </div>
    );
  }
}

export default withRouter(Test as any);
