import * as Types from '../constants/action-type';
import {
  createFilterAction,
  createLoaderAction,
  createCrudAction,
  createRequestAction
} from '../helpers/action-helper';
import {AMENDMENTS_SEARCH_APPLY, QUESTION_SEARCH_APPLY} from "../constants/action-type";
import {createAction} from "redux-actions";
import {setRequestInProcess} from "./request";
import {handleHttpErrors} from "../helpers/errors/http-error-handler";
import {questionModalClose, questionNewModalClose, amendmentsModalClose, amendmentsAnswerModalClose} from "./modals";
import {postQuestion, putQuestion, resetQuestions} from "../api/requests/question";
import {reset} from "redux-form";
import {
  AMENDMENTS_ANSWER_FORM_NAME, AMENDMENTS_FORM_NAME,
  QUESTION_FORM_NAME, QUESTION_NEW_FORM_NAME
} from "../constants/forms";
import {TestStatus} from "../constants/test-status";
import {changeTestStatusAction} from "./test";

export const questionLoader = createLoaderAction(Types.QUESTION);
export const questionRequest = createRequestAction(Types.QUESTION);

export const questionSearchAction = createAction(QUESTION_SEARCH_APPLY);
export const amendmentsSearchAction = createAction(AMENDMENTS_SEARCH_APPLY);

export const questionResetAction = (idTest) => async dispatch => {
  dispatch(setRequestInProcess(true, 'questionResetAction'));

  try {
    await resetQuestions(idTest);
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(setRequestInProcess(false, 'questionResetAction'));
};

export const questionModalSubmit = (question, idQuestion) => async dispatch => {
  dispatch(setRequestInProcess(true, 'questionModalSubmit'));

  let data;

  try {
    data = await putQuestion(idQuestion, question);

    dispatch(createCrudAction(Types.QUESTION).replaceItem(data));
    dispatch(questionModalClose());
    dispatch(reset(QUESTION_FORM_NAME));
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(setRequestInProcess(false, 'questionModalSubmit'));
};

export const questionNewModalSubmit = (idTest, question) => async dispatch => {
  dispatch(setRequestInProcess(true, 'questionModalSubmit'));

  let data;

  try {
    data = await postQuestion(question, idTest, false);

    dispatch(createCrudAction(Types.QUESTION).addItem(data));
    dispatch(questionNewModalClose());
    dispatch(reset(QUESTION_NEW_FORM_NAME));
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(setRequestInProcess(false, 'questionModalSubmit'));
};

export const amendmentsModalSubmit = (idTest, question) => async dispatch => {
  dispatch(setRequestInProcess(true, 'amendmentsModalSubmit'));

  let data;

  try {
    data = await postQuestion(question, idTest, true);

    dispatch(createCrudAction(Types.QUESTION).addItem(data));
    dispatch(amendmentsModalClose());
    dispatch(reset(AMENDMENTS_FORM_NAME));
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(setRequestInProcess(false, 'amendmentsModalSubmit'));
};

export const amendmentsAnswerModalSubmit = (question, idQuestion, testId?: number) => async dispatch => {
  dispatch(setRequestInProcess(true, 'amendmentsAnswerModalSubmit'));

  let data;

  try {
    data = await putQuestion(idQuestion, question);

    dispatch(createCrudAction(Types.QUESTION).replaceItem(data));
    dispatch(amendmentsAnswerModalClose());
    dispatch(reset(AMENDMENTS_ANSWER_FORM_NAME));
    if (testId) {
      dispatch(changeTestStatusAction(testId, TestStatus.STATUS_AMENDMENT_RESPONDED));
    }
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(setRequestInProcess(false, 'amendmentsAnswerModalSubmit'));
};