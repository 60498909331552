import React, { useContext } from "react";
import {
  DayCellProps,
  TimeSheetExpensesContextValueType
} from "types/timesheets-and-expenses";
import ConsultantDayCell from "./ConsultantDayCell";
import { TimeSheetExpensesContext } from "../../context/timeSheetsContext";
import { DayTimeExpensesLog } from "./DayTimeExpensesLog";
import { CellViewButton } from "./CellActionButton";
import {
  getAdminViewTimeLogLink,
  getClientViewTimeLogLink
} from "helpers/router-helper";

type AdminClientDayCellProps = DayCellProps & {
  isAdmin: boolean;
  canViewPlatformPrices?: boolean
};
const AdminClientDayCell = (props: AdminClientDayCellProps) => {
  const { days, expensesAmount, id, isAdmin, vat, canViewPlatformPrices = false } = props;

  const dayTimeExpensesLog = !!days || !!expensesAmount;

  const openModalLink = isAdmin
    ? getAdminViewTimeLogLink
    : getClientViewTimeLogLink;

  if (dayTimeExpensesLog) {
    return (
      <div className="timesheet-day-cell-wrap">
        <DayTimeExpensesLog days={days} expenses={expensesAmount} vat={vat} />
        <CellViewButton to={`${openModalLink()}/${id}`} />
      </div>
    );
  }
  return null;
};

export const DayCell = (props: DayCellProps) => {
  const { isConsultant, isAdmin } = useContext<
    TimeSheetExpensesContextValueType
  >(TimeSheetExpensesContext);

  if (isConsultant) {
    return <ConsultantDayCell {...props} />;
  }

  return <AdminClientDayCell {...props} isAdmin={isAdmin} />;
};
