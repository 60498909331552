import * as React from "react";
import {
  composeValidators,
  fieldMaxLength,
  fieldsNotEmpty,
} from "../../../helpers/validations/validation";
import { InjectedFormProps, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { postItemAction } from "../../../actions/risks";
import RiskForm from "./RiskForm";
import { withRouter } from "react-router-dom";
import routerModal from "../../../components/routerModal";
import { FIELD_TITLE_MAX_LENGTH, FIELD_DESCRIPTION_MAX_LENGTH } from "./const";

const FORM_NAME = "RiskCreate";
export interface IRiskUpdateData {
    title: string;
    description: string;
    impact: number;
    likelihood: number;
    test?: string;
    vulnerabilityScore?: number;
}

class RiskCreate extends React.Component<{ match: { params: { id: number } }; testId: number } & InjectedFormProps<{}, {}> & {
      postData: (values: IRiskUpdateData, formName: string, backRoute: string) => void;
      backRoute: string;
      vulnerabilities?: number;
    }
> {
  render() {
    const { handleSubmit, postData, backRoute, vulnerabilities } = this.props;

    return (
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(
          (values: IRiskUpdateData) => {
            const value = {
              test: "/api/tests/" + this.props.testId,
              title: values.title,
              description: values.description,
              impact: Number(values.impact),
              likelihood: Number(values.likelihood),
              vulnerabilityScore: Number(values.vulnerabilityScore),
            };

            if (!value.vulnerabilityScore) {
              delete value.vulnerabilityScore;
            }

            postData(value, FORM_NAME, backRoute);
          }
        )}
      >
        <RiskForm backRoute={backRoute} isVulnerabilities={vulnerabilities} />
      </form>
    );
  }
}

const formConnected = reduxForm({
  form: FORM_NAME,
  validate: composeValidators(
    fieldsNotEmpty(["title", "description", "impact", "likelihood"]),
    fieldMaxLength(["title"], FIELD_TITLE_MAX_LENGTH),
    fieldMaxLength(["description"], FIELD_DESCRIPTION_MAX_LENGTH),
  ),
})(RiskCreate);

const connected = connect(undefined, {
  postData: postItemAction,
})(formConnected);

export default routerModal(withRouter(connected as any)) as any;
