import React from 'react';
import { composeValidators, fieldsNotEmpty } from "../../../../helpers/validations/validation";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import { locationsRequest } from "../../../../actions/locations";
import { LocationsForm } from "./LocationsForm";
import { withStyles } from "@material-ui/core/styles/index";
import { withRouter } from 'react-router-dom'
import { defaultStyleWidth } from '../../../../styles/material/popup-style';
import routerModal from '../../../../components/routerModal';
import {bindActionCreators} from "redux";

const FORM_NAME = 'LocationsCreate';

class LocationsCreate extends React.Component {
  render() {
    const { handleSubmit, postData, backRoute } = this.props;

    return (
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit((values) => {
          postData(values, FORM_NAME, backRoute);
        })}
      >
        <LocationsForm backRoute={backRoute}/>
      </form>
    );
  }
}

const formConnected = reduxForm({
  form: FORM_NAME,
  validate: composeValidators(
    fieldsNotEmpty([
      'name',
      'address',
      'town',
      'postCode',
    ]),
  )
})(LocationsCreate);

const connected = connect(undefined, (dispatch) => ({
  postData: bindActionCreators(locationsRequest.postItem, dispatch),
}))(formConnected);

export default routerModal(withRouter(withStyles(defaultStyleWidth(400))(connected)));