import {convertorFromSwagger, convertorToSwagger} from '../convertors/test';
import {fetchRequestBody} from '../../helpers/request-helper';
import {getApiJSONLD} from '../swagger/api-factory';
import {IProposalGet} from "../../models/proposal.interface";


export const postProposalRequest = (model: IProposalGet) => {
  return getApiJSONLD()
    .then(api => api.postProposalCollection({
      proposal: convertorToSwagger(model),
    }))
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};

export const putProposalRequest = (id: string, model: IProposalGet) => {
  return getApiJSONLD()
    .then(api => api.putProposalItem({
      id,
      proposal: convertorToSwagger(model),
    }))
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};