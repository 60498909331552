import * as Types from "../constants/action-type";
import {
    createFilterAction,
    createLoaderAction,
    createCrudAction,
    createRequestAction
} from "../helpers/action-helper";
import { setRequestInProcess } from "./request";
import { getRequestFromType } from "../api/requests/request-factory";
import { openSnackbar } from "./snackbar";
import { handleHttpErrors } from "../helpers/errors/http-error-handler";
import { reset } from "redux-form";
import { push } from "react-router-redux";
import { getRequestName } from "../helpers/action-request-helper";
import { postImage } from "../api/requests/image";
import { API_IMAGES_ENDPOINT_URL } from "../constants/urls";

export const carouselFilter = createFilterAction(Types.CAROUSEL);
export const carouselLoader = createLoaderAction(Types.CAROUSEL);
export const carouselRequest = createRequestAction(Types.CAROUSEL);


const crudType = Types.CAROUSEL;

export const postItemCarousel = (model, formName, backRoute) => async dispatch => {
  dispatch(setRequestInProcess(true, getRequestName(crudType, 'postItem')));

  let data, createdFile;

  try {
    if (model.image instanceof File) {
      createdFile = await postImage(model.image);

      if (createdFile) {
        model.image = API_IMAGES_ENDPOINT_URL + createdFile.id;
      }
    }

    data = await (getRequestFromType(crudType)).postItem(model);

    dispatch(openSnackbar('Created', 4000));
    dispatch(createCrudAction(crudType).addItem(data));
    dispatch(reset(formName));
    dispatch(push(backRoute));
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(setRequestInProcess(false, getRequestName(crudType, 'postItem')));
};


export const putItemCarousel = (model, formName, backRoute) => async dispatch => {
  dispatch(setRequestInProcess(true, getRequestName(crudType, 'putItem')));

  let data, createdFile;

  try {
    if (model.image instanceof File) {
      createdFile = await postImage(model.image);

      if (createdFile) {
        model.image = API_IMAGES_ENDPOINT_URL + createdFile.id;
      }
    }

    data = await (getRequestFromType(crudType)).putItem(model);

    dispatch(openSnackbar('Updated', 4000));
    dispatch(createCrudAction(crudType).replaceItem(data));
    dispatch(reset(formName));
    dispatch(push(backRoute));
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(setRequestInProcess(false, getRequestName(crudType, 'putItem')));
};