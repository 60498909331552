import { ColumnType } from "components/table/Table";
import { IUsers } from "models/users.interface";
import React from "react";
import { ClientName } from "./columns";
import { formatDateWithTime } from "helpers/date-formatter";
import { Permission } from "constants/permission";
import {
  Archive,
  Delete,
  Reset2FA,
  ResetPassword
} from "containers/admin/testers/TesterList/columns";
import { OnReset } from "containers/admin/testers/TesterList";
import TableCellEdit from "components/table/TableCellEdit";
import { ACTIVE, ARCHIVED } from "constants/filter";
import { Switch } from "@material-ui/core";

type Types = {
  onDelete: OnReset;
  onArchive: OnReset;
  onUnArchive: OnReset;
  onResetPassword: OnReset;
  onReset2FA: OnReset;
  changeSubscriptionStatus: OnReset;
  baseUrl: string;
  showStatus: any;
};

const roleCompanyOwner = "Common Company Owner";

export function getClientListColumns({
  onDelete,
  onArchive,
  onUnArchive,
  onResetPassword,
  onReset2FA,
  baseUrl,
  showStatus,
  changeSubscriptionStatus
}: Types): Array<ColumnType<IUsers>> {
  const columns: Array<ColumnType<IUsers>> = [
    {
      key: "client_name",
      name: "Name",
      render(user) {
        return React.createElement(ClientName, { user });
      }
    },
    {
      key: "groupName",
      name: "Role"
    },
    {
      key: "email",
      name: "Email"
    },
    {
      key: "phone",
      name: "Telephone",
      render({ phone }) {
        return React.createElement("div", {
          className: "text-nowrap",
          children: phone
        });
      }
    },
    {
      key: "createdAt",
      name: "Date registered",
      render({ createdAt }) {
        return React.createElement("div", {
          className: "text-nowrap",
          children: formatDateWithTime(createdAt)
        });
      }
    },
    {
      key: "reset_2fa",
      name: "Reset 2FA",
      permissions: [Permission.CAN_MANAGE_CLIENTS],
      render(user) {
        return React.createElement(Reset2FA, {
          user,
          onReset: onReset2FA
        });
      }
    },
    {
      key: "reset_password",
      name: "Reset password",
      permissions: [Permission.CAN_MANAGE_CLIENTS],
      render(user) {
        return React.createElement(ResetPassword, {
          user,
          onReset: onResetPassword
        });
      }
    },
    {
      key: "status",
      name: "Status",
      render({ deletedAt }) {
        return deletedAt ? "Inactive" : "Active";
      }
    }
  ];

  if (showStatus !== ARCHIVED) {
    columns.push({
      key: "edit",
      name: "Edit",
      permissions: [Permission.CAN_MANAGE_CLIENTS],
      render(item) {
        return React.createElement(TableCellEdit, {
          item,
          baseUrl,
          status: showStatus,
          Wrapper: React.Fragment
        });
      }
    });
  }

  return [
    ...columns,
    {
      key: "subscription",
      name: "Subscription",
      permissions: [Permission.CAN_MANAGE_CLIENTS],
      render(user) {
        if (user.groupName !== roleCompanyOwner) {
          return;
        }

        return React.createElement(Switch, {
          key: user.id,
          checked: user.isSubscriptionActive,
          onChange: () => changeSubscriptionStatus(user)
        });
      }
    },
    {
      key: "activation",
      name: "De/Re activate",
      permissions: [Permission.CAN_MANAGE_CLIENTS],
      render(user) {
        return React.createElement(Archive, { user, onArchive, onUnArchive });
      }
    },
    {
      key: "delete",
      name: "Delete",
      permissions: [Permission.CAN_MANAGE_CLIENTS],
      render(user) {
        if (user.deletedAt) {
          return null;
        }

        return React.createElement(Delete, { user, onDelete });
      }
    }
  ];
}
