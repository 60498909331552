import { converterFromSwagger, converterToSwagger } from "../convertors/certificate";
import * as FilterActions from "../../constants/filter";
import { fetchRequestBody, dummyItemsPagination } from "../../helpers/request-helper";
import { getApi } from "../swagger/api-factory";
import { ICertificate } from "../../models/certificate.interface";
import { IAnyProps } from "../../interfaces/any-props.interface";

export const getItems = (page: number, itemsPerPage: number, show: string, text: string = '') => {
  const isShow = show || FilterActions.DEFAULT_FILTER;
  const parameters: IAnyProps = { page };

  parameters.items = itemsPerPage;
  parameters.show = isShow;

  if (text) {
    parameters.question = text;
  }

  return getApi()
    .then(api => api.getCertificateCollection(parameters))
    .then(fetchRequestBody)
    .then(dummyItemsPagination);
};

export const getItem = (id: string|number) => {
  return getApi()
    .then(api => api.getCertificateItem({ id }))
    .then(fetchRequestBody)
    .then(converterFromSwagger);
};

export const postItem = (model: ICertificate) => {
  return getApi()
    .then(api => api.postCertificateCollection({
      certificate: converterToSwagger(model),
    }))
    .then(fetchRequestBody)
    .then(converterFromSwagger);
};

export const putItem = (model: ICertificate) => {
  return getApi()
    .then(api => api.postCertificateCollection({
      id: model.id,
      certificate: converterToSwagger(model),
    }))
    .then(fetchRequestBody)
    .then(converterFromSwagger);
};

export const deleteItem = (id: string|number) => {
  return getApi()
    .then(api => api.deleteCertificateItem({ id, }))
    .then(fetchRequestBody);
};
