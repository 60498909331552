import React from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import Paper from "@material-ui/core/es/Paper/Paper";
import {getSpecialismsWithoutReportTemplatesAction} from "actions";
import {getRoles} from "selectors/auth";
import {ROLE_ADMIN, ROLE_SUPER_ADMIN} from "../../constants/roles";

class ReportTemplatesAlert extends React.Component {
  componentDidMount() {
    if (this.props.currentRoles.includes(ROLE_ADMIN) || this.props.currentRoles.includes(ROLE_SUPER_ADMIN)) {
      this.props.getSpecialismsWithoutReportTemplatesAction();
    }
  }

  render() {
    const {specialismsWithoutReportTemplates} = this.props;

    if (specialismsWithoutReportTemplates.length === 0) {
      return null;
    }

    return (<Paper
      className="p-3 mb-4 text-center"
      style={{backgroundColor: '#ffdddd'}}
    >
      No templates
      for {specialismsWithoutReportTemplates.map((item, index) => {
      if (index + 1 === specialismsWithoutReportTemplates.length) {
        return <b key={index}>{item}</b>;
      }

      return <b key={index}>{item}, </b>
    })}.
      Please finish the configuration <Link
      to="/admin/settings/report-templates">here</Link>.
    </Paper>);
  }
}

export default connect(state => ({
  specialismsWithoutReportTemplates: state.specialismsWithoutReportTemplates,
  currentRoles: getRoles(state),
}), {
  getSpecialismsWithoutReportTemplatesAction,
})(ReportTemplatesAlert);
