import React from "react";
import { NavLink, Redirect, Route, Switch } from "react-router-dom";
import Tests from "./tests/Tests";
import Project from "./project/Project";
import TestDetails from "../../client-tester/test-details/TestDetails";
import { Group } from "../../../constants/group";
import {
  getIsLoadedPermissions,
  getRoles,
  getUserGroup,
  getUserPermissions
} from "../../../selectors/auth";
import { connect } from "react-redux";
import TimeSheetAndExpensesTab from "./timeSheetsAndExpenses/TimeSheetAndExpenses";
import PermissionBlock from "../../../components/auth/PermissionBlock";
import {Permission} from "../../../constants/permission";

class TestsAndProjects extends React.Component {
  render() {
    const { userGroup } = this.props;

    if (userGroup === Group.VIEWER) {
      return (
        <div>
          <h4 className="main-content-header">Activities</h4>
          <div className="styled-tabs flex-column-in-mobile">
            <NavLink
              to="/client/tests-and-projects/tests"
              className="tab"
              activeClassName="active"
            >
              Activity
            </NavLink>
          </div>
          <Switch>
            <Route path="/client/tests-and-projects/tests" component={Tests} />
            <Redirect
              from="/client/tests-and-projects"
              to="/client/tests-and-projects/tests"
            />
          </Switch>
        </div>
      );
    }

    if (userGroup === Group.PLANNER) {
      return (
        <div>
          <div className="styled-tabs flex-column-in-mobile">
            <NavLink
              to="/client/tests-and-projects/tests"
              className="tab"
              activeClassName="active"
            >
              Activities
            </NavLink>
            <NavLink
              to="/client/tests-and-projects/projects"
              className="tab"
              activeClassName="active"
            >
              Projects
            </NavLink>
            <PermissionBlock permissions={[Permission.CAN_MANGE_TIME_EXPENSES]}>
              <NavLink
                to="/client/tests-and-projects/timesheet-and-expenses"
                className="tab"
                activeClassName="active"
              >
                Timesheet & Expenses
              </NavLink>
            </PermissionBlock>
          </div>
          <Switch>
            <Route
              path="/client/tests-and-projects/tests/:test"
              component={TestDetails}
            />
            <Route path="/client/tests-and-projects/tests" component={Tests} />
            <Route
              path="/client/tests-and-projects/projects"
              component={Project}
            />
            <Route
              path="/client/tests-and-projects/timesheet-and-expenses"
              component={TimeSheetAndExpensesTab}
            />
            <Redirect
              from="/client/tests-and-projects"
              to="/client/tests-and-projects/tests"
            />
          </Switch>
        </div>
      );
    }

    if (userGroup === Group.OWNER) {
      return (
        <div>
          <h4 className="main-content-header">Projects</h4>
          <div className="styled-tabs flex-column-in-mobile">
            <NavLink
              to="/client/tests-and-projects/tests"
              className="tab"
              activeClassName="active"
            >
              Activity
            </NavLink>
            <NavLink
              to="/client/tests-and-projects/projects"
              className="tab"
              activeClassName="active"
            >
              Projects
            </NavLink>
            <NavLink
              to="/client/tests-and-projects/timesheet-and-expenses"
              className="tab"
              activeClassName="active"
            >
              Timesheet & Expenses
            </NavLink>
          </div>
          <Switch>
            <Route
              path="/client/tests-and-projects/tests/:test"
              component={TestDetails}
            />
            <Route path="/client/tests-and-projects/tests" component={Tests} />
            <Route
              path="/client/tests-and-projects/projects"
              component={Project}
            />
            <Route
              path="/client/tests-and-projects/timesheet-and-expenses"
              component={TimeSheetAndExpensesTab}
            />
            <Redirect
              from="/client/tests-and-projects"
              to="/client/tests-and-projects/tests"
            />
          </Switch>
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    currentRoles: getRoles(state),
    isLoadedPermissions: getIsLoadedPermissions(state),
    userPermissions: getUserPermissions(state),
    userGroup: getUserGroup(state)
  };
}

export default connect(mapStateToProps, {})(TestsAndProjects);
