import React from "react";
import Header from "containers/guest/Header";
import TimeSheetsAndExpenses from "components/timesheets-and-expenses/TimeSheetsAndExpenses";

const Dashboard = () => {
  return (
    <div className="full-height">
      <Header />
      <TimeSheetsAndExpenses />
    </div>
  );
};

export default Dashboard;
