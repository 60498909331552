export type FILTER_TYPE =   'Active' |
                            'Inactive' |
                            'Archived' |
                            'All' |
                            'Both';

export const ACTIVE = 'Active';
export const INACTIVE = 'Inactive';
export const ARCHIVED = 'Archived';
export const ALL = 'All';
export const BOTH = 'Both';

export const DEFAULT_FILTER = ACTIVE;
export const TEXT = 'TEXT';
