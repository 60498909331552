import React from "react";
import AuditLogSearchForm from "./AuditFormSearchForm";
import AuditLogList from "./AuditLogList";

export const AuditLog = ():JSX.Element => {

  return (
    <>
      <AuditLogSearchForm />
      <AuditLogList />
    </>
  );
}