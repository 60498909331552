import * as React from 'react';
import { Route, Switch} from "react-router-dom";
import ApplicationSettingsList from "./ApplicationSettingsList";
// import CarouselUpdate from "./CarouselUpdate";
import {withRouter} from 'react-router-dom';
import ApplicationSettingUpdate from "./ApplicationSettingUpdate";

class ApplicationSetting extends React.Component<any, {}> {
  baseUrl = this.props.match.path;

  updateComponent = () => <ApplicationSettingUpdate backRoute={this.baseUrl}/>;

  render() {
    return (
      <div>
        <div className="crud-sections-header">
          <h4>Application Settings</h4>
        </div>
        <hr/>
        {/*<Switch>*/}
          {/*<Route path={this.baseUrl + "/:id"} render={this.updateComponent}/>*/}
        {/*</Switch>*/}
        <ApplicationSettingsList baseUrl={this.baseUrl}/>
        <Switch>
          <Route path={this.baseUrl + "/:id/edit"} render={this.updateComponent}/>
        </Switch>
      </div>
    );
  }
}

export default withRouter(ApplicationSetting);
