import React, { ChangeEvent, useRef } from 'react';
import { Button, FormHelperText } from "@material-ui/core";
import { Field } from "redux-form";
import { WrappedFieldProps } from "redux-form/lib/Field";

const AVAILABLE_FILES_EXTENSIONS = [
  'application/pdf,application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.oasis.opendocument.text',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.oasis.opendocument.presentation',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.oasis.opendocument.spreadsheet',
  'text/csv,text/plain',
  '.docx',
  '.ods',
  '.odt',
  '.odg',
  '.xlsx',
  '.xls',
  '.csv',
  '.pptx',
  '.ppt',
  'image/png',
  'image/jpeg',
  'image/gi',
].join(',');

type UploadReceiptButtonProps = WrappedFieldProps;

export function UploadReceiptButton({input, meta: {touched, error}}: UploadReceiptButtonProps) {
  const ref = useRef<HTMLInputElement>(null);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const [file] = event.target.files || [];

    if (file) {
      input.onChange(file);
    }
  };

  const onClickHandler = () => {
    ref.current?.click();
  }

  return (
    <>
      <Button
        variant="contained"
        color="default"
        onClick={onClickHandler}
      >
        Upload File
        <input
          ref={ref}
          type="file"
          accept={AVAILABLE_FILES_EXTENSIONS}
          onChange={onChange}
          hidden
        />
      </Button>
      {touched && error ? <p className="error-color w-100">{error}</p> : null}
    </>
  );
}
