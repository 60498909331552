import React from "react";
import {Button, Modal, withStyles} from "@material-ui/core";
import {FieldGeneric} from "../../../../../components/forms/FieldGeneric";
import ReduxDocumentFileField from "../../../../../components/forms/ReduxDocumentFileField";
import Grid from "@material-ui/core/Grid";
import {connect} from "react-redux";
import {reduxForm} from "redux-form";
import {CREATE_ADDITIONAL_DOCUMENT_FORM_NAME} from "../../../../../constants/forms";
import {
  composeValidators,
  fieldMaxLength,
  fieldMinLength,
  fieldsNotEmpty
} from "../../../../../helpers/validations/validation";
import {withRouter} from "react-router-dom";
import {defaultStyleWidth} from "../../../../../styles/material/popup-style";
import {getModalReportDocumentCreationItem} from "../../../../../selectors/modal";
import {bindActionCreators} from "redux";
import {addReportsAdditionalDocument} from "../../../../../actions";
import ReduxTextField from "../../../../../components/forms/ReduxTextField";

const CreateAdditionalDocumentForm = (props) => {
  const {
    classes,
    reset: resetForm,
    handleSubmit,
    addReportsAdditionalDocument,
    testId,
    closeModal,
    reloadItems
  } = props;

  const handleClose = () => {
    resetForm();
    closeModal();
  }

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={true}
      onClose={handleClose}
    >
      <div className={classes.paper}>
        <div className="crud-sections-header">
          <h4>Upload Additional Document</h4>
        </div>
        <hr/>
        <form noValidate autoComplete="off" onSubmit={handleSubmit((values) => addReportsAdditionalDocument({values, testId, closeModal: handleClose, reloadItems}))}>
          <FieldGeneric
            name="name"
            label="Document name*"
            component={ReduxTextField}
          />
          <FieldGeneric
            name="document"
            label="Choose additional document*"
            component={ReduxDocumentFileField}
          />
          <br/>
          <Grid container spacing={16}>
            <Grid item xs={6}>
              <Button fullWidth={true} type="button" variant="contained" onClick={handleClose}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button fullWidth={true} type="submit" variant="contained" color="primary">
                Continue
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Modal>
  )
}
function mapStateToProps(state) {
  return {
    isState: state,
    reportDocument: getModalReportDocumentCreationItem(state)
  };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addReportsAdditionalDocument,
    },
    dispatch
  );
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(CreateAdditionalDocumentForm);

const formed = reduxForm({
  form: CREATE_ADDITIONAL_DOCUMENT_FORM_NAME,
  validate: composeValidators(
    fieldsNotEmpty(['name', 'document']),
    fieldMinLength(['name'], 2),
    fieldMaxLength(['name'], 100),
  )
})(connectedComponent);

const routered = withRouter(formed as any);

export default withRouter(withStyles(defaultStyleWidth(320))(routered as any) as any) as any;
