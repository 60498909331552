import {IOptionItem} from "../interfaces/option-item.interface";

export enum Reminders {
  REMINDER_TYPE_NO_REMINDERS = 1,
  REMINDER_TYPE_ONE_MONTH_BEFORE = 2,
  REMINDER_TYPE_THREE_MONTH_BEFORE = 3,
}

export const REMINDERS_LIST: IOptionItem[] = [
  {
    key: Reminders.REMINDER_TYPE_NO_REMINDERS,
    value: 'No',
  },
  {
    key: Reminders.REMINDER_TYPE_ONE_MONTH_BEFORE,
    value: '1 month before',
  },
  {
    key: Reminders.REMINDER_TYPE_THREE_MONTH_BEFORE,
    value: '3 months before',
  },
];
