import React from 'react';
import { IUsers } from 'models/users.interface';
import { getStatusDescription } from 'constants/dbs-certificate-statuses';
import {
  DbsStatus,
  TesterNameColumn,
  Reset2FA,
  ResetPassword,
  SwitchCompanyManager,
  Archive,
  Delete,
} from './columns';
import { formatDateWithTime } from "helpers/date-formatter";
import { Permission } from "constants/permission";
import { ColumnType } from "components/table/Table";
import { OnReset } from "./constants";


type Types = {
  onResetPassword: OnReset,
  onReset2FA: OnReset,
  onArchive: OnReset,
  onUnArchive: OnReset,
  onDelete: OnReset;
  onSwitchCompanyManager: OnReset;
};

export function getTesterListColumns(
  {
    onResetPassword,
    onReset2FA,
    onArchive,
    onUnArchive,
    onDelete,
    onSwitchCompanyManager
  }: Types
): ColumnType<IUsers>[] {
  return [
    {
      key: 'dbs_status',
      name: 'DBS Status',
      render(user) {
        const dbsStatus = getStatusDescription(user.dBSCheckMinStatus)

        return React.createElement(DbsStatus, {
          user, dbsStatus
        });
      }
    },
    {
      key: 'name',
      name: 'Name',
      render(user) {
        return React.createElement(TesterNameColumn, {
          user,
        });
      }
    },
    {
      key: 'email',
      name: 'Email',
      render({email}) {
        return email;
      }
    },
    {
      key: 'phone',
      name: 'Telephone',
      render({phone}) {
        return React.createElement("div", {
          className: 'text-nowrap', children: phone
        })
      }
    },
    {
      key: 'createdAt',
      name: 'Date registered',
      render({createdAt}) {
        return React.createElement("div", {
          className: 'text-nowrap', children: formatDateWithTime(createdAt)
        })
      }
    },
    {
      key: 'reset_2fa',
      name: 'Reset 2FA',
      permissions: [Permission.CAN_MANAGE_TESTERS],
      render(user) {
        return React.createElement(Reset2FA, {
          user,
          onReset: onReset2FA
        });
      }
    },
    {
      key: 'reset_password',
      name: 'Reset password',
      permissions: [Permission.CAN_MANAGE_TESTERS],
      render(user) {
        return React.createElement(ResetPassword, {
          user,
          onReset: onResetPassword,
        });
      }
    },
    {
      key: 'status',
      name: 'Status',
      render({deletedAt}) {
        return deletedAt ? 'Inactive' : 'Active';
      }
    },
    {
      key: 'testing_company',
      name: 'Testing company',
      render({testingCompanyName}) {
        return testingCompanyName || '';
      }
    },
    {
      key: 'company_manager', name: 'Company manager', render(user) {
        return React.createElement(SwitchCompanyManager, {user, onSwitchCompanyManager})
      }
    },
    {
      key: 'activation',
      name: 'De/Re activate',
      permissions: [Permission.CAN_MANAGE_TESTERS],
      render(user) {
        return React.createElement(Archive, {user, onUnArchive, onArchive });
      },
    },
    {
      key: 'delete',
      name: 'Delete',
      permissions: [Permission.CAN_MANAGE_TESTERS],
      render(user) {
        return React.createElement(Delete, {user, onDelete});
      },
    }
  ];
}
