import React, { ReactNode, useMemo } from "react";
import { connect } from "react-redux";
import { IAnyProps } from "interfaces/any-props.interface";
import { RootState } from "reducers";
import { getUserPermissions } from "selectors/auth";
import { Permission } from "constants/permission";
import { IDocument } from "models/document.interface";

export type Column<T> = {
  key: string,
  render?: (item: T) => ReactNode,
  permissions?: string[];
  className?: string;
};

type Props<T> = {
  columns: Column<IDocument>[];
  index: string;
  data: T[];
  loading?: boolean;
  permissions?: Permission[];
}

function DocumentTable<T>({columns, data, index, loading = false, permissions = []}: Props<T>) {
  const cols = useMemo(() => {
    return columns.filter(({permissions: needs}) => {
      if (!needs) {
        return true;
      }

      return permissions.some((permission) => needs.includes(permission));
    });
  }, [columns]);

  if (loading) {
    return <div className="table-no-items">Loading...</div>;
  }

  if (!data.length) {
    return <div className="table-no-items">There are no items!</div>;
  }

  return <div className="table-responsive">
    <table className="block-table">
      <tbody>
      {data.map((item: T) => {
        const rowKey = item[index];

        return (
          <tr key={rowKey}>
            {cols.map(({render, key, className}) => <td
              key={`${rowKey}-${key}`}
              className={className}
            >
              {render ? render(item) : item[key]}
            </td>)}
          </tr>);
      })}
      </tbody>
    </table>
  </div>
}

export default connect<IAnyProps, IAnyProps, IAnyProps, RootState>((state) => {
  return {
    permissions: getUserPermissions(state),
  };
}, {})(DocumentTable);
