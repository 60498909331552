import * as React from "react";
import TestersList from "./TestersList";
import selector from "../../../../selectors/test";
import {testRequest} from "../../../../actions/test";
import {withRouter} from "react-router-dom";
import {dataFetcher} from "../../../../components/dataFetcher";
import {result} from "lodash";
import {Grid} from "@material-ui/core";
import {Link} from "react-router-dom";
import StatusBlock from "../../../common/StatusBlock";

class TestersBooking extends React.Component<any, {}> {
  render() {
    const testId = this.props.match.params.test;
    const testName = this.props.test && `${this.props.test.projectName}: ${this.props.test.hash}-${this.props.test.name}`;

    if (!this.props.test) {
      return (
        <div>
          Loading...
        </div>
      );
    }

    return (
      <Grid container spacing={16}>

        <Grid item xs={12} md={6}>
          <h4 className="main-content-header">
            {testName}
            <span className="status-label ml-3">
              {this.props.test.status && <StatusBlock status={this.props.test.status}/>}
            </span>
          </h4>
        </Grid>

        <Grid item xs={12} md={6} className="text-right">
          <Link to={`/client/schedule/test/${testId}/edit`} className="btn-edit">
            Edit
          </Link>
        </Grid>

        <Grid item xs={12} md={12}>
          <TestersList test={this.props.test}/>
        </Grid>

      </Grid>
    );
  }
}

const fetchedComponent: any = dataFetcher(
  TestersBooking,
  [
    {
      key: "test",
      selector: (state, props) => {
        return selector.getItemById(result(props, "match.params.test", false))(state);
      },
      action: (props) => testRequest.getItem(result(props, "match.params.test", false)),
      alwaysReceiveFreshData: true
    }
  ]
);

export default withRouter(fetchedComponent);
