import * as Types from '../constants/action-type';
import {openSnackbar} from "./snackbar";
import {setRequestInProcess} from "./request";
import {getProjectCostChartDataRequest} from "../api/requests/dashboard";

export const getProjectCostChartData = (parameters) => async dispatch => {
  dispatch(setRequestInProcess(true, 'projectCostChartData'));

  let data;

  try {
    data = await getProjectCostChartDataRequest(parameters);
    dispatch({type: Types.PROJECTS_COST_CHART, payload: data});
  } catch (e) {
    dispatch(setRequestInProcess(false, 'projectCostChartData'));
    dispatch(openSnackbar(e.message, 4000));
    data = e.message;

    return;
  }

  dispatch(setRequestInProcess(false, 'projectCostChartData'));

  return data;
};

export default getProjectCostChartData;
