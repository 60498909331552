import * as ActionTypes from "../constants/action-type";
import * as FilterTypes from "../constants/filter";
import {
  reducerCrudItems,
  mergeReducers,
  filterItems,
  reducerLoaderItems,
  paginationDefaultState
} from "../helpers/reducer-helper";
import { combineReducers } from "redux";

const mainReducer = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const loaderReducer = (
  state = { ...paginationDefaultState, itemsPerPage: 10 },
  action
) => {
  switch (action.type) {
    default:
      return state;
  }
};

const filterReducer = (
  state = {
    field: "",
    conditions: {
      active: FilterTypes.ACTIVE
    }
  },
  action
) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default combineReducers({
  items: combineReducers({
    items: mergeReducers(
      reducerCrudItems(ActionTypes.CONSULTANTS),
      mainReducer
    ),
    loader: mergeReducers(
      reducerLoaderItems(ActionTypes.CONSULTANTS),
      loaderReducer
    )
  }),
  filter: mergeReducers(filterItems(ActionTypes.CONSULTANTS), filterReducer)
});
