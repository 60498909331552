import {extractJSONLD, fetchRequestBody} from "../../helpers/request-helper";
import {getApiJSONLD, getApi} from "../swagger/api-factory";
import {converterFromSwagger} from "../convertors/question";

export const questionSearchRequest = (
  id: string | number,
  pageNumber: number,
  itemsPerPage: number,
  from: string,
  to: string
) => {
  return getApiJSONLD()
    .then(api => api.api_tests_questions_get_subresource({
      id,
      $queryParameters: {
        page: pageNumber,
        items: itemsPerPage,
      },
      createdAtAfter: from,
      createdAtBefore: to,
    }))
    .then(extractJSONLD(converterFromSwagger));
};

export const amendmentsSearchRequest = (
  id: string,
  pageNumber: number,
  itemsPerPage: number,
) => {
  return getApiJSONLD()
    .then(api => api.api_tests_questions_get_subresource({
      id,
      isAmendment: true,
      orderCreatedAt: "DESC",
      $queryParameters: {
        page: pageNumber,
        items: itemsPerPage,
      },
    }))
    .then(extractJSONLD(converterFromSwagger));
};

export const postQuestion = (content: string, test: number | string, isAmendment: boolean) => {
  return getApi()
    .then(api => api.postQuestionCollection({
      question: {
        content,
        test: `api/tests/${test}`,
        isAmendment
      }
    }))
    .then(fetchRequestBody)
    .then(converterFromSwagger);
};

export const putQuestion = (id: number | string, question: string) => {
  return getApi()
    .then(api => api.putQuestionItem({id, question: {answer: question}}))
    .then(fetchRequestBody)
    .then(converterFromSwagger);
};

export const resetQuestions = (id: number | string) => {
  return getApi()
    .then(api => api.reset_questionsTestItem({id}))
    .then(fetchRequestBody)
    .then(converterFromSwagger);
};
