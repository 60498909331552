import * as Types from "../constants/action-type";

export default function (state: {[key: string]: string} = {}, action) {
  switch (action.type) {
    case Types.FORM_SEARCH_FIELD_SET:
      return {...state, [action.payload.key]: action.payload.value };
    default:
      return state;
  }
}
