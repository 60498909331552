import React from "react";
import {Field} from "redux-form";
import ReduxTextField from "../../../../components/forms/ReduxTextField";
import {Button} from "@material-ui/core";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {Link} from "react-router-dom";
import ReduxWYSIWYGField from "../../../../components/forms/ReduxWYSIWYGField";
import ReduxTextPlaceholder from "../../../../components/forms/ReduxTextPlaceholder";

export const EmailTemplatesForm = ({backRoute}) => {
  return (
    <div>
      <h4>Email Template Details</h4>
      <div className="styled-modal-body">
        <Field
          inputProps={{maxLength: 100}}
          name="emailKey"
          label="Key"
          type="text"
          margin="normal"
          component={ReduxTextPlaceholder}
        />
        <Field
          inputProps={{maxLength: 150}}
          name="subject"
          label="Subject"
          type="text"
          margin="normal"
          component={ReduxTextField}
        />
        <hr/>
        <div>
          <Field
            name="body"
            label="Body"
            type="text"
            margin="normal"
            component={ReduxWYSIWYGField}
          />
        </div>
        <Field
          name="placeholders"
          label="Mandatory placeholders"
          margin="normal"
          converter={(data) => data.map(val => `[#${val.name}#]`)}
          component={ReduxTextPlaceholder}
        />
      </div>
      <div className="d-flex justify-content-between mt-4">
        <Button variant="contained" color="default" component={Link} to={backRoute}>
          Cancel
        </Button>
        <Button type="submit" variant="contained" color="primary">
          Save
        </Button>
      </div>
    </div>
  );
};
