import * as React from "react";
import { useCallback, useMemo, useState } from "react";
import { Field } from "redux-form";
import ReduxTextField from "components/forms/ReduxTextField";
import Grid from "@material-ui/core/Grid/Grid";
import { Button, Icon, IconButton } from "@material-ui/core";
import { IBackRoute } from "components/routerModal";
import { handleSpaceKeyPress } from "helpers/validations/emptyTestFieldValidation";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Group } from "constants/group";
import PermissionsBlock from "./PermissionsBlock";
import { RolesBlock } from "containers/admin/settings/admin-users/form/RolesBlock";

type Props = {
  group: Group;
};

const AdminUsersForm: React.FC<Props &
  RouteComponentProps &
  IBackRoute & {
    defaultRole?: string;
  }> = ({ backRoute, group, history, defaultRole = null }) => {
  const roles = useMemo(() => {
    const roles = {};

    if (group === Group.SUPER_ADMIN) {
      roles[Group.ADMIN] = "Admin";
    }

    roles[Group.ACCOUNT_MANAGER] = "Account Manager";

    return roles;
  }, []);

  const [role, setRole] = useState<null | keyof typeof roles>(
    defaultRole as keyof typeof roles
  );

  const onCancelClickHandler = useCallback(() => {
    history.push(backRoute);
  }, [backRoute, location]);

  const onRoleChangeHandler = useCallback((event, value) => {
    setRole(value);
  }, []);

  return (
    <Grid container spacing={40}>
      <Grid item xs={12}>
        <IconButton
          className="float-right"
          onClick={onCancelClickHandler}
          style={{ cursor: "pointer", margin: "-15px -15px 0 0" }}
        >
          <Icon>close</Icon>
        </IconButton>
        <h3>User Details</h3>
        <hr />
        <RolesBlock roles={roles} onChange={onRoleChangeHandler} />
        <Field
          name="firstName"
          label="First name *"
          component={ReduxTextField}
          margin="normal"
          autoFocus
        />
        <Field
          name="lastName"
          label="Last Name *"
          component={ReduxTextField}
          margin="normal"
        />
        <Field
          name="email"
          label="Email *"
          component={ReduxTextField}
          margin="normal"
          onKeyPress={handleSpaceKeyPress}
        />
        {role === Group.ACCOUNT_MANAGER && <PermissionsBlock formName="AdminUsersUpdate" />}
        <br />
        <br />
        <div className="d-flex justify-content-between mt-4">
          <Button
            variant="contained"
            color="default"
            onClick={onCancelClickHandler}
          >
            Cancel
          </Button>
          <Button type="submit" variant="contained" color="primary">
            Save
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

export default withRouter(AdminUsersForm);
