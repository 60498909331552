import React from 'react';
import {Route, Switch, withRouter} from "react-router-dom";
import ContentManagementList from "./ContentManagementList";
import ContentManagementUpdate from "./ContentManagementUpdate";


class ContentManagement extends React.Component {
  render() {
    const baseUrl = this.props.match.path;

    return (
      <div>
        <div>
          <h4>Content Management</h4>
        </div>
        <hr/>
        <Switch>
          <Route path={baseUrl + "/:id"} render={() => <ContentManagementUpdate backRoute={baseUrl}/>}/>
        </Switch>
        <ContentManagementList baseUrl={baseUrl}/>
      </div>
    );
  }
}

export default withRouter(ContentManagement);
