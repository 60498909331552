import * as React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Header from "./Header";
import { getAuth, getRoles } from "selectors/auth";
import {
  carouselRequest,
  contactsModalOpen,
  loginModalOpen,
  logOut,
  resetPassword,
  showDrawer
} from "actions";
import { getIsDrawerOpen } from "selectors/drawer";
import selector from "selectors/carousel";
import {
  ROLE_ACCOUNT_MANAGER,
  ROLE_ADMIN,
  ROLE_OWNER,
  ROLE_PLANNER,
  ROLE_PROJECT_CONSULTANT,
  ROLE_TESTER,
  ROLE_VIEWER
} from "constants/roles";
import Footer from "./Footer";
import { AdminUrls } from "constants/admin-urls";
import {
  CLIENT_DASHBOARD_ROUTE,
  CLIENT_PROJECT_TESTS_ROUTE,
  CONSULTANT_ROUTE,
  HOME_ROUTE,
  TESTER_ROUTE
} from "constants/routes";
import { RootState } from "reducers";
import { ConnectedProps } from "types";

type IHomePage = {} & PropsFromRedux;

const Home = ({ auth, loginModalOpen, roles }: IHomePage) => {
  const getLinkByRole = (): string => {
    switch (true) {
      case roles.includes(ROLE_ACCOUNT_MANAGER):
      case roles.includes(ROLE_ADMIN):
        return AdminUrls.ADMIN;
      case roles.includes(ROLE_TESTER):
        return TESTER_ROUTE;
      case roles.includes(ROLE_OWNER) || roles.includes(ROLE_PLANNER):
        return CLIENT_DASHBOARD_ROUTE;
      case roles.includes(ROLE_VIEWER):
        return CLIENT_PROJECT_TESTS_ROUTE;
      case roles.includes(ROLE_PROJECT_CONSULTANT):
        return CONSULTANT_ROUTE;
      default:
        return HOME_ROUTE;
    }
  };

  return (
    <div className="home-page">
      <Header />

      <div className="hero-block">
        <div className="hero-block-content">
          <h1>People, Process & Technology solutions</h1>
          {auth ? (
            <div className="homepage-main-btns-group">
              <Link to={getLinkByRole()} className="log-in-button">
                Control Panel
              </Link>
            </div>
          ) : (
            <div className="homepage-main-btns-group">
              <Link to="#" className="log-in-button" onClick={loginModalOpen}>
                Login
              </Link>
            </div>
          )}
        </div>
      </div>

      <Footer hasDigiCert />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  backgroundImages: selector.getItems(state),
  isLoaded: selector.getIsLoaded(state),
  isDrawerOpen: getIsDrawerOpen(state),
  auth: getAuth(state),
  roles: getRoles(state)
});

const mapDispatchToProps = {
  contactsModalOpen,
  loginModalOpen,
  showDrawer,
  resetPassword,
  logOut,
  loadItems: carouselRequest.getItems
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Home);
