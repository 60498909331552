import * as React from "react";
import TestsList from "./TestsList";
import TestsSearchForm from "./TestsSearchForm";
import { withRouter } from "react-router-dom";

interface IMatch {
  match: {
    path: string;
  };
}

const Tests = ({ match }: IMatch) => {
  const baseUrl = match.path;
  return (
    <div className="pb-4">
      <TestsSearchForm />
      <TestsList baseUrl={baseUrl} />
    </div>
  );
};

export default withRouter(Tests);
