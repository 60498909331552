import * as React from "react";
import { WithStyles } from "@material-ui/core";
import certificateSelector from "../../../selectors/certificate";
import {
  getTesterCertifications,
  testerCertificateArchive
} from "../../../actions/certificate";
import { dataFetcher } from "../../../components/dataFetcher";
import { ICertificate } from "../../../models/certificate.interface";
import Table from "@material-ui/core/Table/Table";
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableBody from "@material-ui/core/TableBody/TableBody";
import TableCell from "@material-ui/core/TableCell/TableCell";
import EnhancedTableHead from "../../../components/table/EnhancedTableHead";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Icon from "@material-ui/core/Icon/Icon";
import { connect } from "react-redux";
import { downloadSecureDocument } from "../../../api/requests/document-download";
import Button from "@material-ui/core/Button";
import { API_SECURE_DOCUMENTS_ENDPOINT_URL } from "../../../constants/urls";
import { formatDateWithSlashesDdMmYyyy } from "../../../helpers/date-formatter";
import moment from "moment";

interface IOwnProps {
  certificates: ICertificate[];
  certificateArchive: (id: any) => void;
  downloadDocumentFile: (...any) => void;
  getProps: (props: ICertificate[]) => void;
}

class CertificatesTable extends React.Component<
  IOwnProps & WithStyles & any,
  {}
> {
  downloadFile = event => {
    const evidenceDocumentId = event.currentTarget.dataset.evidenceid;
    if (evidenceDocumentId) {
      this.props.downloadSecureDocument(evidenceDocumentId);
    }
  };

  highlight = certificate => {
    const notExpiredCertificate =
      moment(certificate.validTo).format("YYYY-MM-DD") >
      moment().format("YYYY-MM-DD");
    if (!notExpiredCertificate) {
      return " box-shadow-danger";
    }

    return "";
  };

  render() {
    return (
      <Table>
        <EnhancedTableHead columns={["Name", "Valid", "Evidence", "Archive"]} />
        <TableBody>
          {this.props.certificates.map((item: ICertificate) => {
            return (
              <TableRow key={item.id} className={this.highlight(item)}>
                {item.certificateTypeView && (
                  <TableCell>
                    {item.certificateTypeView.shortName +
                      " - " +
                      item.certificateTypeView.fullName}
                  </TableCell>
                )}

                <TableCell>{this.getValidity(item)}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    data-evidenceid={item.evidence.replace(
                      API_SECURE_DOCUMENTS_ENDPOINT_URL,
                      ""
                    )}
                    onClick={this.downloadFile}
                  >
                    Download
                  </Button>
                </TableCell>
                <TableCell onClick={this.handleArchive(item)}>
                  <IconButton>
                    <Icon>archive</Icon>
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  }

  private readonly getValidity = (item: ICertificate) => {
    const dateFrom =
      item.validFrom && formatDateWithSlashesDdMmYyyy(item.validFrom);
    const dateTo = item.validTo && formatDateWithSlashesDdMmYyyy(item.validTo);

    return `${dateFrom} to ${dateTo}`;
  };

  private readonly handleArchive = (item: ICertificate) => () => {
    this.props.certificateArchive(item.id);
  };
}

export default connect(
  state => {
    return {
      certificates: certificateSelector.getItems(state)
    };
  },
  {
    certificateArchive: testerCertificateArchive,
    downloadSecureDocument
  }
)(CertificatesTable as any);

// export default dataFetcher<IProps>(connected, [
//   {
//     key: "certificates",
//     action: () => getTesterCertifications(),
//     selector: (state) => certificateSelector.getItemsObject(state),
//     alwaysReceiveFreshData: true,
//   }
// ]) as any;
