import * as Types from '../constants/action-type';

export default function(state = { status: false }, action) {
  switch (action.type) {
    case Types.INVITE_TO_COMPANY_CONFIRMATION:
      return {...state, ...action.payload};
    default:
      return state;
  }
};
