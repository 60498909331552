import * as React from "react";
import {connect} from "react-redux";
import {Modal, Button, Grid, Typography} from "@material-ui/core";
import {amendmentsAnswerModalClose} from "../../../actions/modals";
import {getIsOpenModalAmendmentsAnswer, getModalAmendmentsAnswerItem} from "../../../selectors/modal";
import {FieldGeneric} from "../../../components/forms/FieldGeneric";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {reduxForm, InjectedFormProps} from "redux-form";
import ReduxTextField from "../../../components/forms/ReduxTextField";
import {amendmentsAnswerModalSubmit} from "../../../actions/question";
import {IQuestion} from "../../../models/question.interface";
import {AMENDMENTS_ANSWER_FORM_NAME} from "../../../constants/forms";
import {composeValidators, fieldMaxLength, fieldsNotEmpty} from "../../../helpers/validations/validation";

interface IStateToProps {
  isOpenModal: any;
  item: IQuestion | false;
}

interface IDispatchToProps {
  modalClose: () => any;
  modalSubmit: (question, idQuestion, testId?: number) => any;
}

class AmendmentsModal extends React.Component<IStateToProps
  & IDispatchToProps
  & RouteComponentProps<any>
  & InjectedFormProps<any>> {
  render() {
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.props.isOpenModal}
        onClose={this.handleClose}
        className="d-flex overflow-auto"
      >
        <div className="styled-modal md">
          <div className="crud-sections-header">
            <h4>Clarification</h4>
          </div>
          <hr/>
          <form noValidate autoComplete="off" onSubmit={this.props.handleSubmit((values) => {
            if (this.props.item) {
              this.props.modalSubmit(values.answer, this.props.item.id, this.props.match.params.test);
            }
          })}>
            <div>
              <Typography style={{verticalAlign: "middle", display: "inline-flex"}}>
                {this.props.item && this.props.item.content}
              </Typography>
            </div>
            <FieldGeneric
              name="answer"
              rows={4}
              multiline={true}
              label="Answer *"
              margin="normal"
              component={ReduxTextField}
            />
            <Grid container spacing={16}>
              <Grid item xs={6}>
                <Button
                  fullWidth={true}
                  type="submit"
                  variant="contained"
                  onClick={this.handleClose}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth={true}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Modal>
    );
  }

  private readonly handleClose = () => {
    this.props.modalClose();
    this.props.untouch("answer");
    this.props.change("answer", "");
  };
}

function mapStateToProps(state) {
  return {
    isOpenModal: getIsOpenModalAmendmentsAnswer(state),
    item: getModalAmendmentsAnswerItem(state),
  };
}

const connectedComponent = connect<IStateToProps, IDispatchToProps>(mapStateToProps as any, {
  modalClose: amendmentsAnswerModalClose,
  modalSubmit: amendmentsAnswerModalSubmit,
})(AmendmentsModal);

const formed = reduxForm({
  form: AMENDMENTS_ANSWER_FORM_NAME,
  destroyOnUnmount: false,
  validate: composeValidators(
    fieldsNotEmpty(["answer"]),
    fieldMaxLength(["answer"], 2000),
  ),
})(connectedComponent);

const routered = withRouter(formed as any);

export default withRouter(routered as any) as any;
