import {getApi, getApiJSONLD} from "../swagger/api-factory";
import {extractJSONLD, fetchRequestBody} from "../../helpers/request-helper";
import {convertorFromSwagger} from "../convertors/default-convertor";
import {convertorToSwagger} from "../convertors/default-convertor";

export const getItems = () => {
  return getApiJSONLD()
    .then(api => api.getApplicationSettingCollection())
    .then(extractJSONLD(convertorFromSwagger));
};

export const getItem = (id) => {
  return getApiJSONLD()
    .then(api => api.getApplicationSettingItem({id}))
    .then(extractJSONLD(convertorFromSwagger));
};

export const getFilteredItems = (parameters: any) => {
  return getApiJSONLD()
    .then(api => api.getApplicationSettingCollection(parameters))
    .then(extractJSONLD(convertorFromSwagger));
};

export const putItem = (settingId: number, model) => {
  return getApi()
    .then(api => api.putApplicationSettingItem({
      id: settingId,
      applicationSetting: convertorToSwagger(model),
    }))
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};