import {getApi} from '../swagger/api-factory';
import {AdditionalCompaniesType, ServiceManagedType} from "../../types";


export const getFinancesRequest = () => {
  return getApi()
    .then(api => api.getApiAdminFinances({}))
};

export const putFinancesRequest = (model: object) => {
  return getApi()
    .then(api => api.putApiAdminFinances({finances: model}));
};

export const getFinanceItemRequest = (key: string | number) =>{
  return getApi()
    .then(api => api.getApiAdminFinancesByKey({key}));
};

export const getCompaniesListForManagedServicesRequest = () => {
  return getApi().then(api => api.getCompaniesListForManagedServices())
}

export const putServicesManagedCompaniesRequest = (companies: ServiceManagedType[]) => {
  return getApi().then(api=>api.putAdminManagedServices({managedServices: companies}));
}

export const getCompaniesListForAdditionalServicesRequest = () => {
  return getApi().then(api => api.validatedCompaniesCompanyCollection())
}

export const putAdditionalServicesCompaniesRequest = (params:AdditionalCompaniesType) => {
  return getApi().then(api=>api.updateCompaniesValidationCompanyCollection({companyIds: params}));
}

export const loadAdditionalServicesRequest = () => {
  return getApi().then(api=>api.getManagedServiceCollection());
}
