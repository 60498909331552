import getCrudSelectors from '../helpers/selector-helper';
import {createSelector} from "reselect";
import {IReport} from "../models/report.interface";

const getReport = state => state.reports;
const selectors = getCrudSelectors(getReport);

export const findByTestId = (id) => (state: IReport[]) => {
  return state.find(element => element.id === id);
};

const getByTestId = (id) => createSelector(
  selectors.getItems,
  findByTestId(id)
);

export {getByTestId};

export default selectors;
