import * as React from "react";
import {WrappedFieldInputProps} from "redux-form/lib/Field";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import MyUploadAdapter from "./ReduxCkeditorUploadAdapter";
import htmlToText from "html-to-text";
import {getFormattedNumber} from "../../helpers/currency";

interface IProps {
  input: WrappedFieldInputProps;
  label: string;
  imageUploadCallback: () => void;
  postWysiwygImage: () => Promise<any>;

  [key: string]: any;
}

interface IState {
  [key: string]: any;
}

const editorConfiguration = {
  extraPlugins: [MyCustomUploadAdapterPlugin],
  removePlugins: ["Link", "MediaEmbed"]
};

class ReduxWYSIWYGField extends React.Component<IProps, IState> {
  onChangeHandler = (event, editor) => {
    const data = editor.getData();
    this.props.input.onChange(data);
  };

  render() {
    const contentLength = htmlToText.fromString(this.props.input.value).length;
    const {minLength, maxLength, meta: {touched, error}} = this.props;

    return (
      <div>
        <CKEditor
          editor={ClassicEditor}
          data={this.props.input.value}
          onChange={this.onChangeHandler}
          config={{...editorConfiguration, placeholder: this.props.label}}
        />
        {maxLength && contentLength > maxLength &&
        <div className="text-danger mt-2">
          Maximum {getFormattedNumber(maxLength)} symbols(s).
        </div>
        }
        {minLength && contentLength < minLength &&
        <div className="text-danger mt-2">
          Minimum {getFormattedNumber(minLength)} symbol(s).
        </div>
        }
        {!!touched && !!error &&
          <div className="text-danger mt-2">
            {touched && error ? error : ""}
          </div>
        }
      </div>
    );
  }
}

function MyCustomUploadAdapterPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return new MyUploadAdapter(loader);
  };
}

export default ReduxWYSIWYGField;
