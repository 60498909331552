import React from 'react';
import Button from "@material-ui/core/Button/Button";
import {Link, Route, Switch} from "react-router-dom";
import SectorList from "./SectorList";
import SectorSearchForm from "./SectorSearchForm";
import SectorCreate from "./SectorCreate";
import SectorUpdate from "./SectorUpdate";
import {withRouter} from 'react-router-dom';
import {getCreateLink} from '../../../../helpers/router-helper';

class Sector extends React.Component {
  render() {
    const baseUrl = this.props.match.path;

    return (
      <div>
        <div className="crud-sections-header">
          <h4>Sector</h4>
          <Button component={Link} to={getCreateLink(baseUrl)} variant="contained" color="default">
            New Sector
          </Button>
        </div>
        <hr/>
        <Switch>
          <Route
            path={getCreateLink(baseUrl)}
            render={() => <SectorCreate backRoute={baseUrl}/>}
          />
          <Route
            path={baseUrl + "/:id"}
            render={() => <SectorUpdate backRoute={baseUrl}/>}
          />
        </Switch>
        <SectorSearchForm/>
        <SectorList baseUrl={baseUrl}/>
      </div>
    );
  }
}

export default withRouter(Sector);
