import React from 'react';
import {composeValidators, fieldsNotEmpty} from "../../../../helpers/validations/validation";
import {reduxForm} from "redux-form";
import {connect} from "react-redux";
import {locationsRequest} from "../../../../actions/locations";
import parameterFetcher from '../../../../components/parametersFetcher';
import selector from '../../../../selectors/locations';
import {LocationsForm} from "./LocationsForm";
import routerModal from '../../../../components/routerModal';

const FORM_NAME = 'LocationsUpdate';

class LocationsUpdate extends React.Component {
  render() {
    const {handleSubmit, putData, backRoute} = this.props;
    return (
      <form noValidate autoComplete="off"
            onSubmit={handleSubmit((values) => {
              putData(values, FORM_NAME, backRoute);
            })}>
        <LocationsForm backRoute={backRoute}/>
      </form>
    )
  }
}

const formConnected = reduxForm({
  form: FORM_NAME,
  validate: composeValidators(
    fieldsNotEmpty(['address', 'town', 'postCode',]),
  )
})(LocationsUpdate);

const connected = parameterFetcher(connect(
  undefined,
  {
    putData: locationsRequest.putItem,
  }
)(formConnected), FORM_NAME, locationsRequest.getItem, selector.getItemById);

export default routerModal(connected);