import React, { useState, useEffect } from "react";
import { Modal, Chip, MenuItem, MenuList } from "@material-ui/core";
import { getYearsList, getMonthsList, getCurrentMonth } from "./helpers";
import classnames from "classnames";
import { change } from "redux-form";
import InputLabel from "@material-ui/core/InputLabel";

type MonthItemType = { id: string; name: string };

const ReduxYearMonthPicker = props => {
  const {
    input,
    label,
    meta: { touched, error, dirty }
  } = props;
  const years: number[] = getYearsList();
  const months: MonthItemType[] = getMonthsList();
  const currentMonth = getCurrentMonth();
  const [openedPicker, setOpenedPicker] = useState<boolean>(false);
  const [selectedYear, setSelectedYear] = useState<number>(years[0]);
  const [selectedMonth, setSelectedMonth] = useState<string>(currentMonth);
  const [selectedDate, setSelectedDate] = useState<string>(
    `${selectedMonth} ${selectedYear}`
  );

  const closeModalPicker = () => setOpenedPicker(false);
  const resetYearMonthState = () => {
    setSelectedYear(years[0]);
    setSelectedMonth(currentMonth);
  };
  const cancelPicker = () => {
    if (!selectedDate) {
      resetYearMonthState();
    } else {
      const [month, year] = selectedDate.split(" ");
      setSelectedYear(+year);
      setSelectedMonth(month);
    }
    closeModalPicker();
  };
  const clearPicker = () => {
    resetYearMonthState();
    props.meta.dispatch(
      change(props.meta.form, input.name, `${currentMonth} ${years[0]}`)
    );
    setSelectedDate(`${currentMonth} ${years[0]}`);
    closeModalPicker();
  };
  const setDate = () => {
    props.meta.dispatch(
      change(props.meta.form, input.name, `${selectedMonth} ${selectedYear}`)
    );
    setSelectedDate(`${selectedMonth} ${selectedYear}`);
    closeModalPicker();
  };

  useEffect(() => {
    if (!dirty && selectedDate) {
      setSelectedDate(`${currentMonth} ${years[0]}`);
    }
  }, [dirty, selectedDate]);

  useEffect(() => {
    if (input.value) {
      return;
    }

    clearPicker();
  }, [input.value])

  return (
    <>
      <div
        className="year-month-picker-input"
        role="button"
        onClick={() => setOpenedPicker(true)}
      >
        <InputLabel
          style={{ fontSize: "medium" }}
          htmlFor="select-multiple"
          error={touched && error}
        >
          {selectedDate || label}
        </InputLabel>
      </div>
      <Modal
        aria-labelledby="Year month picker"
        aria-describedby="Year month picker"
        open={openedPicker}
        onClose={cancelPicker}
        className="d-flex overflow-auto"
      >
        <div className="styled-modal sm year-month-picker-modal">
          <div className="year-month-picker">
            <div className="picker-year">
              <MenuList className="picker-year__wrap">
                {years.map(year => {
                  const yearItemClassNames = classnames("picker-year__item", {
                    selected: selectedYear === year
                  });
                  return (
                    <MenuItem
                      key={year}
                      className={yearItemClassNames}
                      alignItems="center"
                      onClick={() => setSelectedYear(year)}
                      selected={selectedYear === year}
                    >
                      {year}
                    </MenuItem>
                  );
                })}
              </MenuList>
            </div>
            <div className="year-month-picker__month">
              {months.map(month => (
                <span
                  key={month.id}
                  className={month.name === selectedMonth ? "selected" : ""}
                >
                  <Chip
                    key={month.id}
                    label={month.name}
                    onClick={() => setSelectedMonth(month.name)}
                    color="primary"
                    variant="default"
                  />
                </span>
              ))}
            </div>
          </div>
          <div className="year-month-picker__actions">
            <button className="btn-cancel-default" onClick={clearPicker}>
              Clear
            </button>
            <button className="btn-cancel" onClick={cancelPicker}>
              Cancel
            </button>
            <button className="btn-save" onClick={setDate}>
              OK
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ReduxYearMonthPicker;
