export type LOADER_TYPE =   'SET_PAGE_NUMBER' |
                            'SET_IS_NOT_LOADED' |
                            'SET_IS_LOADED' |
                            'SET_ITEMS_PER_PAGE' |
                            'SET_TOTAL_ITEMS' |
                            'SET_PAGINATION_DATA';

export const SET_PAGE_NUMBER = 'SET_PAGE_NUMBER' as LOADER_TYPE;
export const SET_IS_NOT_LOADED = 'SET_IS_NOT_LOADED' as LOADER_TYPE;
export const SET_IS_LOADED = 'SET_IS_LOADED' as LOADER_TYPE;
export const SET_ITEMS_PER_PAGE = 'SET_ITEMS_PER_PAGE' as LOADER_TYPE;
export const SET_TOTAL_ITEMS = 'SET_TOTAL_ITEMS' as LOADER_TYPE;
export const SET_PAGINATION_DATA = 'SET_PAGINATION_DATA' as LOADER_TYPE;

export const MAX_PAGINATION_RESULTS = 1000000;
export const MAX_TESTERS_RESULTS = 100;
