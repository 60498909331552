import React from "react";
import ReduxRadioButton from "components/forms/ReduxRadioButton";
import { FieldGeneric } from "components/forms/FieldGeneric";
import { FormControlLabel, Radio } from "@material-ui/core";

type Props = {
  roles: Record<string, string>;
  onChange: (event, value: string) => void;
};

export function RolesBlock({ roles = {}, onChange }: Props) {
  return (
    <FieldGeneric
      name="role"
      margin="normal"
      fullWidth
      inline
      component={ReduxRadioButton}
      onChange={onChange}
    >
      {Object.entries(roles).map(([value, label]) => (
        <FormControlLabel
          key={value}
          value={value}
          control={<Radio />}
          label={label}
        />
      ))}
    </FieldGeneric>
  );
}
