import * as React from "react";
import moment from "moment";
import { connect } from "react-redux";
import CanvasJSReact from "../../../lib/canvasjs/canvasjs.react";
import getProjectCostChartData from "../../../actions/projectCostChart";
import { downloadCostsByDate } from "../../../api/requests/document-download";
import { getCurrency } from "../../../helpers/currency";
import { setFilteredCostChartData } from "../../../helpers/set-filtered-dashboard-data";
import { DashboardChartHeader } from "./DashboardChartHeader";

interface ILineChart {
  x: Date;
  y: number;
}

interface IProjectCost {
  id: number;
  name: string;
  amount: number;
  date: {
    date: string;
    timezone_type: number,
    timezone: string;
  },
}

interface IFormatedProject {
  amount: number;
  date: Date;
  name: string;
}

interface IChartData {
  type: string;
  legendText: string;
  showInLegend: boolean;
  legendMarkerType: string;
  yValueFormatString: string;
  dataPoints: ILineChart[];
}

interface IRangeOfDates {
  dateFrom?: string;
  dateTo?: string;
}

class DashboardLinesChart extends React.Component<{
  projectsCostChartData: IProjectCost[],
  rangeOfDates: IRangeOfDates,
  getProjectCostChartData: (params?: {}) => {},
  downloadCostsByDate: (params?: {}) => void,
}, {}> {
  projects: any = [];

  componentDidMount() {
    this.props.getProjectCostChartData();
  }

  componentWillReceiveProps(nextProps){
    const { rangeOfDates: nextRangeOfDate = {}, getProjectCostChartData } = nextProps;
    const { rangeOfDates = {} } = this.props;

    if(nextRangeOfDate.dateFrom !== rangeOfDates.dateFrom || nextRangeOfDate.dateTo !== rangeOfDates.dateTo) {
      setFilteredCostChartData(nextProps, getProjectCostChartData);
    }
  }

  customValueFormatter(e) {
    const hours = 1000 * 60 * 60;

    if (!e.chart.options.axisX) {
      e.chart.options.axisX = {};
    }

    if (e.trigger === "reset") {
      e.chart.options.axisX.valueFormatString = null;
    } else if (e.trigger === "zoom") {
      if ((((e.axisX.viewportMaximum - e.axisX.viewportMinimum) / (hours)) < 1)) {
        e.chart.options.axisX.valueFormatString = null;
      } else if (((e.axisX.viewportMaximum - e.axisX.viewportMinimum) / (hours * 24)) < 7) {
        e.chart.options.axisX.valueFormatString = "HH:mm";
      } else if (((e.axisX.viewportMaximum - e.axisX.viewportMinimum) / (hours * 24 * 30 * 30)) < 12) {
        e.chart.options.axisX.valueFormatString = "D-MMM-YYYY";
      } else {
        e.chart.options.axisX.valueFormatString = null;
      }
    }
  }

  dataGenerator() {
    const array = this.projects.map((project) => {
      let legendText: string = "";

      const dataPoints = project.map((item: IFormatedProject) => {
        legendText = item.name;

        return {
          x: item.date,
          y: item.amount
        };
      });

      return {
        type: "splineArea",
        legendText,
        showInLegend: true,
        legendMarkerType: "circle",
        yValueFormatString: "£###,###",
        dataPoints
      };
    });

    return  array.filter((el) => {
      return el != null;
    });
  }



  formatData(chartData: IProjectCost[]) {
    this.projects = [];

    chartData.map((item: IProjectCost) => {
      if (!this.projects[item.id]) {
        this.projects[item.id] = [{
          name: item.name,
          amount: item.amount,
          date: new Date(Date.parse(item.date.date))
        }];
      } else {
        this.projects[item.id].push({
          name: item.name,
          amount: item.amount,
          date: new Date(Date.parse(item.date.date))
        });
      }
    });
  }

  getTotalAmount(filteredCostChartData:  IProjectCost[]) {
    return filteredCostChartData.reduce((totalAmount, item) => totalAmount += item.amount, 0);
  }

  getOptions() {
    return {
      zoomEnabled: true,
      rangeChanging: this.customValueFormatter,
      axisY: {
        lineColor: "transparent",
        labelFontColor: "#778596",
        gridColor: "#EAECF0",
        tickLength: 13,
        tickColor: "transparent",
        labelFontSize: 11,
        valueFormatString:  "£###,###",
      },
      axisX: {
        lineColor: "transparent",
        labelFontColor: "#778596",
        gridColor: "#EAECF0",
        tickLength: 13,
        tickColor: "transparent",
        labelFontSize: 11,
        labelFormatter: (e) => {
          const momentValue = moment(e.value);
          return momentValue.format("DD/MM/YY");
        }
      },
      animationEnabled: true,
      data: this.dataGenerator()
    };
  }

  render() {
    const { projectsCostChartData = [], downloadCostsByDate, rangeOfDates } = this.props;

    if (!projectsCostChartData) {
      return (
        <div className="styled-block">
          <DashboardChartHeader
            downloadPDF={downloadCostsByDate}
            rangeOfDates={rangeOfDates}
            caption="Costs"
            formName="LineChartSearchForm"
          />
          <div className="table-no-items">Loading...</div>
        </div>
      );
    }

    this.formatData(projectsCostChartData);

    return (
      <div className="styled-block chart">
        <DashboardChartHeader
          downloadPDF={projectsCostChartData.length ? downloadCostsByDate : null}
          rangeOfDates={rangeOfDates}
          caption="Costs"
          formName="LineChartSearchForm"
        />
        {
          projectsCostChartData.length
          ? (
              <>
                <div className="total-data container text-center mb-4 chart-top">
                  <h2>&pound;{getCurrency(this.getTotalAmount(projectsCostChartData))}</h2>
                  <span>Total Spent</span>
                </div>
                <CanvasJSReact.CanvasJSChart options={this.getOptions()}/>
              </>
            )
          : (
              <div className="table-no-items">There are no items...</div>
            )
        }
      </div>
    );
  }
}

export default connect(
  (state: any) => ({
    projectsCostChartData: state.dashboard.projectsCostChartData,
    rangeOfDates: state.form.LineChartSearchForm ? state.form.LineChartSearchForm.values : {}
  }),
  {
    getProjectCostChartData,
    downloadCostsByDate
  }
)(DashboardLinesChart as any);
