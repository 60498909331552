import * as Types from "../constants/action-type";
import {combineReducers} from "redux";

const testerRightToWorkCountries = (state = [], action) => {
  switch (action.type) {
    case Types.TESTER_RIGHTS_TO_WORK_COUNTRIES:
      return [...action.payload];
    default:
      return state
  }
};

export default combineReducers({
  testerRightToWorkCountries,
});