import {Button, Grid, Modal, Typography, withStyles, WithStyles} from "@material-ui/core";
import * as React from "react";
import {
  getBookingCancelBackRoute,
  getBookingCancelStatus,
  getBookingCancelTestId,
  getIsOpenBookingCancel
} from "../../../../selectors/modal";
import {connect} from "react-redux";
import {bookingCancelModalClose} from "../../../../actions/modals";
import {defaultStyleWidth} from "../../../../styles/material/popup-style";
import {changeTestStatusAction, IChangeTestStatusAction} from "../../../../actions/test";
import {TestStatus} from "../../../../constants/test-status";
import {push} from "react-router-redux";

interface IStateToProps {
  isOpenModal: any;
  testId: number;
  status: TestStatus;
  backRoute: string;
}

interface IDispatchToProps {
  changeTestStatus: IChangeTestStatusAction;
  bookingCancelModalClose: () => any;
}

class BookingCancelModal extends React.Component<IStateToProps & IDispatchToProps & WithStyles<any>> {
  render() {
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.props.isOpenModal}
        onClose={this.handleClose}
        className="d-flex overflow-auto"
      >
        <div className="styled-modal sm">
          <h4>Are you sure?</h4>
          <hr/>
          {[
            TestStatus.STATUS_PROPOSAL_SUBMITTED_CANCELLED_BY_CUSTOMER,
            TestStatus.STATUS_PROPOSAL_SUBMITTED_CANCELLED_BY_TESTER
          ].indexOf(this.props.status) === -1 && (
            <p>
              Warning: Admin Fee will be applied!
            </p>
          )}
          <div className="text-right">
            <button
              className="btn-cancel"
              onClick={this.handleClose}>
              No
            </button>
            <button
              className="btn-accept"
              onClick={this.cancelBooking}>
              Cancel&nbsp;booking
            </button>
          </div>
        </div>
      </Modal>
    );
  }

  cancelBooking = () => {
    return this.props.changeTestStatus(
      this.props.testId,
      this.props.status,
      (data) => {
        this.props.bookingCancelModalClose();
        location.reload()
      }
    );
  };

  private readonly handleClose = () => {
    this.props.bookingCancelModalClose();
  };
}

function mapStateToProps(state) {
  return {
    isOpenModal: getIsOpenBookingCancel(state),
    testId: getBookingCancelTestId(state),
    status: getBookingCancelStatus(state),
    backRoute: getBookingCancelBackRoute(state),
  };
}

const connectedComponent = connect<IStateToProps, IDispatchToProps>(mapStateToProps, {
  changeTestStatus: changeTestStatusAction,
  bookingCancelModalClose,
})(BookingCancelModal);

export default withStyles(defaultStyleWidth(320))(connectedComponent);