import {catchError, endWith, map, startWith} from "rxjs/operators";
import {setRequestInProcess} from "../../actions/request";
import {handleHttpErrors} from "../../helpers/errors/http-error-handler";
import {of} from "rxjs/internal/observable/of";
import {concat} from "rxjs/internal/observable/concat";

export const errorComplition = (type: string) => {
  return [
    map((a) => {
      return {type};
    }),
    catchError((error) => {
      const actions: any[] = [];
      const dispatchSubstitute = (act: any) => {
        actions.push(of(act));
      };

      handleHttpErrors(error, dispatchSubstitute);

      return concat(of({type}), ...actions,);
    }),
  ];
};

export const startEndCatch = (name: string, defaultOnError?: any) => {
  return [
    catchError((error) => {
      const actions: any[] = [];
      const dispatchSubstitute = (act: any) => {
        actions.push(of(act));
      };

      handleHttpErrors(error, dispatchSubstitute);

      if (defaultOnError) {
        return concat(
          of(setRequestInProcess(false, name)),
          ...actions,
          defaultOnError
        );
      } else {
        return concat(
          of(setRequestInProcess(false, name)),
          ...actions
        );
      }
    }),
    startWith(setRequestInProcess(true, name)),
    endWith(setRequestInProcess(false, name)),
  ];
};
