export const noticeStyles = {
  card: {
    background: '#db3700',
    marginBottom: 40,
  },
  cardContent: {
    paddingBottom: 24,
    paddingTop: 24,
    color: "#fff"
  },
};
