import * as React from "react";
import Paper from "@material-ui/core/Paper";
import { connect } from "react-redux";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import selector from "../../../selectors/users";
import {
  Field,
  formValueSelector,
  InjectedFormProps,
  reduxForm
} from "redux-form";
import ReduxRadioButton from "../../../components/forms/ReduxRadioButton";
import * as FilterTypes from "../../../constants/filter";
import { bindActionCreators } from "redux";
import ReduxTextField from "../../../components/forms/ReduxTextField";
import { FieldGeneric } from "../../../components/forms/FieldGeneric";
import { usersFilter } from "../../../actions/users";
import { isChangesExist } from "../../../helpers/props-checker";
import { Button } from "@material-ui/core";
import ReduxDatePicker from "../../../components/forms/ReduxDatePicker";
import ReduxCheckbox from "../../../components/forms/ReduxCheckbox";
import ReduxSelectField from "../../../components/forms/ReduxSelectField";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import testingCompaniesSelector from "../../../selectors/testing-companies";
import { ACTIVE } from "../../../constants/filter";
import { testingCompaniesRequest } from "../../../actions/testing-companies";

export const FORM_NAME = "TestersSearchForm";
const selectorForm = formValueSelector(FORM_NAME);

const roles = [
  {
    id: "TestingCompanyManager",
    name: "Company Manager"
  },
  {
    id: "Tester",
    name: "AVORD Tester"
  }
];
class TestersSearchForm extends React.Component<
  InjectedFormProps<{}, {}> & {
    submitFilterChanges: (...any) => void;
    valuesData: { active: string; text: string };
    testingCompanies: string[];
    loadTestingCompanies: any;
  },
  any
> {
  componentWillReceiveProps(nextProps) {
    if (isChangesExist(["valuesData"], nextProps, this.props)) {
      this.props.submitFilterChanges(
        nextProps.valuesData.active,
        nextProps.valuesData.text
      );
    }
  }

  componentWillMount() {
    this.props.loadTestingCompanies(1, 100, ACTIVE);
  }

  renderOption = (item: any) => (
    <MenuItem key={item.id} value={item.id}>
      {item.name}
    </MenuItem>
  );

  render() {
    const { handleSubmit, reset, testingCompanies } = this.props;

    return (
      <form
        className="mb-4"
        onSubmit={handleSubmit((values: { active: string; text: string }) => {
          this.props.submitFilterChanges(values.active, values.text);
        })}
      >
        <Paper className="p-4">
          <div className="row">
            <div className="col-md-4">
              <Field
                name="name"
                label="Name"
                component={ReduxTextField}
                margin="normal"
              />
            </div>
            <div className="col-md-4">
              <Field
                name="email"
                label="Email"
                component={ReduxTextField}
                margin="normal"
              />
            </div>
            <div className="col-md-4">
              <FieldGeneric
                name="role"
                label="Role"
                component={ReduxSelectField}
                margin="normal"
              >
                <MenuItem value="">None</MenuItem>
                {roles.map(this.renderOption)}
              </FieldGeneric>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <FieldGeneric
                name="createdAt"
                label="From"
                InputLabelProps={{
                  shrink: true
                }}
                component={ReduxDatePicker}
                margin="normal"
              />
            </div>
            <div className="col-md-4">
              <FieldGeneric
                name="deletedAt"
                label="To"
                InputLabelProps={{
                  shrink: true
                }}
                addEndOfDay={true}
                component={ReduxDatePicker}
                margin="normal"
              />
            </div>
            <div className="col-md-4">
              <FieldGeneric
                name="userTestingCompanyFilter"
                label="Testing company"
                component={ReduxSelectField}
                margin="normal"
              >
                <MenuItem value="">None</MenuItem>
                {testingCompanies &&
                  testingCompanies.map(item => this.renderOption(item))}
              </FieldGeneric>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-lg-4">
              <Field
                name="phone"
                label="Telephone"
                component={ReduxTextField}
                margin="normal"
              />
            </div>
            <div
              className="col-md-4 col-lg-2"
              style={{
                display: "inline-flex",
                alignItems: "center"
              }}
            >
              <FieldGeneric
                name="userDbsFilter"
                label="DBSS financial checks only"
                component={ReduxCheckbox}
              />
            </div>
            <div className="col-md-8 col-lg-6 d-flex align-items-end justify-content-end flex-wrap">
              <div>
                <FieldGeneric
                  name="active"
                  inline={true}
                  component={ReduxRadioButton}
                  margin="normal"
                >
                  <FormControlLabel
                    value={FilterTypes.ACTIVE}
                    control={<Radio />}
                    label="Active"
                  />
                  <FormControlLabel
                    value={FilterTypes.ARCHIVED}
                    control={<Radio />}
                    label="Inactive"
                  />
                  <FormControlLabel
                    value={FilterTypes.ALL}
                    control={<Radio />}
                    label="Both"
                  />
                </FieldGeneric>
              </div>
              <button
                className="btn-refresh"
                type="button"
                onClick={reset}
                style={{ marginBottom: 15 }}
              >
                Reset Filters
              </button>
            </div>
          </div>
        </Paper>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    valuesData: {
      ...selectorForm(
        state,
        "name",
        "email",
        "phone",
        "createdAt",
        "deletedAt",
        "active",
        "userDbsFilter",
        "role",
        "userTestingCompanyFilter"
      )
    },
    initialValues: {
      text: selector.getFilterTextFields(state),
      active: selector.getFilterActivityStatus(state)
    },
    testingCompanies: testingCompaniesSelector.getItems(state)
  };
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      submitFilterChanges: usersFilter.submitFilterChanges,
      loadTestingCompanies: testingCompaniesRequest.getItems
    },
    dispatch
  );
};

const connectForm = reduxForm({
  form: FORM_NAME
})(TestersSearchForm);

export default connect(mapStateToProps, mapDispatchToProps)(connectForm);
