import React from "react";
import { Link } from "react-router-dom";

interface CellActionButtonProps {
  to: string;
}

interface CellActionBtnDefaultProps extends CellActionButtonProps {
  icon?: "btn-new" | "btn-edit" | "btn-view-icon";
}

interface CellActionButtonProps {
  to: string;
}

export const CellActionButton = (props: CellActionBtnDefaultProps) => {
  return (
    <span className="timesheet-cell-action timesheet-cell-action__button">
      <Link to={props.to} className={props.icon}></Link>
    </span>
  );
};

export const CellCreateButton = ({ to }: CellActionButtonProps) => (
  <CellActionButton to={to} icon="btn-new" />
);
export const CellEditButton = ({ to }: CellActionButtonProps) => (
  <CellActionButton to={to} icon="btn-edit" />
);
export const CellViewButton = ({ to }: CellActionButtonProps) => (
  <CellActionButton to={to} icon="btn-view-icon" />
);
