import { convertorFromSwagger, convertorToSwagger } from '../convertors/content-management';
import * as FilterActions from '../../constants/filter';
import { extractJSONLD, fetchRequestBody } from '../../helpers/request-helper';
import { getApi, getApiJSONLD } from '../swagger/api-factory';
import {IAnyProps} from "../../interfaces/any-props.interface";
import {CONTENT_MANAGEMENT_KEY} from "../../constants/content-management";
import {IContentManagement} from "../../models/content-management.interface";

export const getItems = (page, itemsPerPage, show, text = '') => {
  const isShow = show || FilterActions.DEFAULT_FILTER;
  const parameters: IAnyProps = { page };

  parameters.items = itemsPerPage;
  parameters.show = isShow;

  if (text) {
    parameters.question = text;
  }

  return getApiJSONLD()
    .then(api => api.getContentManagementCollection(parameters))
    .then(extractJSONLD(convertorFromSwagger));
};

export const getItem = (id) => {
  return getApi()
    .then(api => api.getContentManagementItem({ id }))
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};

export const getItemByKey = (key: CONTENT_MANAGEMENT_KEY): Promise<IContentManagement> => {
  return getApi()
    .then(api => api.getContentManagementCollection({ contentKey: key }))
    .then(extractJSONLD(convertorFromSwagger))
    .then(({items}) => items[0]);
};

export const putItem = (model) => {
  return getApi()
    .then(api => api.putContentManagementItem({
      id: model.id,
      contentManagement: convertorToSwagger(model),
    }))
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};
