import * as React from "react";
import {Modal} from "@material-ui/core";
import {LINKS} from "../../../../constants/links";

interface IProps {
  open: boolean;
  onClose: () => void;
  onAccept: () => void;
  isTesterOwnedByCompany: boolean;
}

class AcceptedInvoiceModal extends React.Component<IProps> {
  render() {
    const { open, onClose, onAccept, isTesterOwnedByCompany } = this.props;
    
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={onClose}
        className="d-flex overflow-auto"
      >
        <div className="styled-modal lg text-center">
          <div>
            <h4>Thank you for using AVORD</h4>
            <p>
              By accepting the report you agree to the following terms and conditions:&nbsp;
              <a href={LINKS.TERMS_AND_CONDITIONS} target="_blank">
                Terms &amp; Conditions
              </a>.
            </p>
          </div>
          <button className="btn-cancel pull-left" onClick={onClose}>
            {'Close'}
          </button>
          <button className="btn-continue pull-right" onClick={onAccept}>
            {isTesterOwnedByCompany ? 'Accept' : 'Accept & create an invoice'}
          </button>
        </div>
      </Modal>
    );
  }
}

export default AcceptedInvoiceModal;
