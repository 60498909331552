import * as React from 'react';
import {getTesterSpecialisms} from "../../../selectors/tester-profile";
import {createLoaderActionItem} from "../../../interfaces/reducer-item.interface";
import * as actionType from "../../../constants/action-type";
import {dataFetcher} from "../../../components/dataFetcher";
import {ISpecialism} from "../../../models/specialism.interface";
import {FieldGeneric} from "../../../components/forms/FieldGeneric";
import ReduxCheckbox from "../../../components/forms/ReduxCheckbox";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";


interface IProps {
  specialisms: ISpecialism[];
}

class SpecialismsEditList extends React.Component<IProps, {}> {
  state = {
    isGeneralChecked: this.props.specialisms[0].checked
  };

  toggleGeneral = () => {
    this.setState({isGeneralChecked: !this.state.isGeneralChecked})
  };

  render() {
    const generalSpecialism = this.props.specialisms[0];

    return (
      <div>
        <div key={generalSpecialism.id} className="d-flex align-items-center">
          <FieldGeneric name="specialisms.1" component={ReduxCheckbox} onClick={this.toggleGeneral}/>
          <h6 className="m-0">{generalSpecialism.name}</h6>
          {
            generalSpecialism.description &&
            <Tooltip
              disableFocusListener
              disableTouchListener
              title={generalSpecialism.description}
            >
              <Icon className="ml-2">info</Icon>
            </Tooltip>
          }
        </div>
        {
          this.props.specialisms.filter(item => item && item.id !== 1).map(specialism => {
            if (specialism.subTypes && specialism.subTypes.length !== 0) {
              return (
                <div key={specialism.id}>
                  <div className="d-flex align-items-center">
                    <h6 className="m-0 mt-2">{specialism.name}</h6>
                    {
                      specialism.description &&
                      <Tooltip
                        disableFocusListener
                        disableTouchListener
                        title={specialism.description}
                      >
                        <Icon className="ml-2">info</Icon>
                      </Tooltip>
                    }
                  </div>
                  <div>
                    {
                      (specialism.subTypes || []).map(specialismChild => {
                        return (
                          <div key={specialismChild.id} className="d-flex align-items-center ml-4">
                            <FieldGeneric
                              name={'specialisms.' + specialismChild.id}
                              component={ReduxCheckbox}
                              disabled={this.state.isGeneralChecked}
                            />
                            <p className="m-0">{specialismChild.name}</p>
                            {
                              specialismChild.description &&
                              <Tooltip
                                disableFocusListener
                                disableTouchListener
                                title={specialismChild.description}
                              >
                                <Icon className="ml-2">info</Icon>
                              </Tooltip>
                            }
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              );
            }

            return;
          })
        }
      </div>
    );
  }
}

export default dataFetcher<{}>(SpecialismsEditList, [
  {
    key: 'specialisms',
    action: () => {
      return createLoaderActionItem(actionType.TESTER_SPECIALISMS).get();
    },
    selector: (state, props) => getTesterSpecialisms(state),
  }
]);
