import * as React from "react";
import ReduxTextField from "components/forms/ReduxTextField";
import { FieldGeneric } from "components/forms/FieldGeneric";
import { connect } from "react-redux";
import { ACTIVE } from "constants/filter";
import { projectsForSchedulingRequest } from "actions/projects-for-scheduling";
import projectsSelector from "selectors/projects-for-scheduling";
import { dataFetcher } from "components/dataFetcher";
import { IProject } from "models/project.interface";
import { RouteComponentProps } from "react-router-dom";
import { formValueSelector, InjectedFormProps, reduxForm } from "redux-form";
import { Link, withRouter } from "react-router-dom";
import ReduxSelectField from "components/forms/ReduxSelectField";
import { Grid, MenuItem, Zoom } from "@material-ui/core";
import {
  composeValidators,
  fieldMaxLength,
  fieldsNotEmpty,
  wysiwygFieldsIsNotEmpty
} from "helpers/validations/validation";
import ReduxCheckbox from "components/forms/ReduxCheckbox";
import {
  dateFormNormalizer,
  testerFieldNotBlankAndNotRequired
} from "helpers/tester-form-normalizer";
import { ISpecialism } from "models/specialism.interface";
import specialismSelector from "selectors/specialisms";
import certificateTypesSelector from "selectors/certificate-type";
import favoriteTestersSelector from "selectors/favorite-testers";
import testSelector from "selectors/test";
import testsSelector, { isNameBlockedSelector } from "selectors/test";
import { specialismsRequest, certificateTypeRequest } from "actions";
import {
  changeTestStatusAction,
  testRequest,
  saveTestAction,
  saveTestActionAndBookTester,
  updateTest,
  updateTestActionAndBookTester
} from "actions/test";
import { favoriteTestersRequest } from "actions";
import { MAX_PAGINATION_RESULTS } from "constants/loader";
import { ITest } from "models/test.interface";
import { ILocation, ISelectLocation } from "models/location.interface";
import ReduxSelectSearchField from "components/forms/ReduxSelectSearchField";
import ReduxSpecialismsField from "components/forms/ReduxSpecialismsField";
import {
  approachModalOpen,
  deleteTestPopupOpen
} from "actions/modals";
import { WEEKEND_LIST } from "constants/weekend";
import { Recurring, RECURRING_LIST } from "constants/recurring";
import { APPROACHES_LIST } from "constants/approach";
import { REMINDERS_LIST } from "constants/reminders";
import * as _ from "lodash";
import { result } from "lodash";
import { IAnyProps } from "interfaces/any-props.interface";
import { createAction } from "redux-actions";
import { SET_IS_TESTER_NAME_BLOCKED } from "constants/action-type";
import TestDeleteModal from "../../client/schedule/test/TestDeleteModal";
import TestInfoModal from "../../client/schedule/test/TestInfoModal";
import { RADIUS_LIST } from "constants/radiuses";
import { extractId } from "helpers/converter-helper";
import Guidance from "../../common/Guidance";
import TestEditHelpBlock from "./TestEditHelpBlock";
import {
  testCanBeDeleted,
  TestStatus,
  testStatusShouldChangeToBookingRequested
} from "constants/test-status";
import TestSaveModal from "../../client/schedule/test/TestSaveModal";
import PermissionBlock from "components/auth/PermissionBlock";
import { Group } from "constants/group";
import * as Storage from "helpers/storage-helper";
import { SHOW_HELP_BLOCK } from "constants/storage";
import ReduxDatePicker from "components/forms/ReduxDatePicker";
import { push } from "react-router-redux";
import BookingProposal from "./BookingProposal";
import { bookingPageTestItemIsEmpty } from "actions/booking-page";
import { isChangesExist } from "helpers/props-checker";
import Icon from "@material-ui/core/Icon/Icon";
import TooltipIcon from "@material-ui/core/Tooltip/Tooltip";
import ReduxWYSIWYGField from "components/forms/ReduxWYSIWYGField";
import { ICertificateType } from "models/certificate-type.interface";
import { ITesterProfileGet } from "models/tester-profile.interface";
import { BASE_URL } from "constants/urls";
import {
  EMPTY_TESTER,
  EMPTY_TESTER_ID
} from "constants/favorite-testers";
import moment from "moment";
import { MAX_DAYS_FOR_RESPONSE } from "constants/test-update";
import { Permission } from "constants/permission";
import { getUserPermissions } from "selectors/auth";
import { YES_NO_OPTIONS_LIST } from "constants/yesNoOptions";
import { NEW_SEARCH_CONSULTANT_OPTION } from "./const";

const MAX_FIELD_DESCRIPTION_LENGTH = 10000;

const FORM_NAME = "TestUpdate";
const selector = formValueSelector(FORM_NAME);

const form_keys = [
  "requester",
  "project",
  "name",
  "location",
  "purchaseOrderNumber",
  "remote",
  "specialisms",
  "approach",
  "description",
  "dateFrom",
  "dateTo",
  "daysForResponse",
  "recurringType",
  "remindersType",
  "years",
  "includeWeekendsType",
  "radius",
  "certification",
  "certificateType",
  "years",
  "experience",
  "isGovernmentCleared",
  "isCheckRegisteredCompany",
  "isCrestRegisteredCompany",
  "indemnityInsurance",
  "publicLiability",
  "preFillTester",
  "isRetest",
  "vulnerabilities"
];

interface IDispatch {
  push: (url: string) => void;
  loadLocation: (id: any) => void;
  approachModalOpen: () => void;
  submitForm: (model, formName, backRoute, validationHandler) => void;
  updateForm: (id, model, formName, callBack?) => void;
  submitFormAndBookTester: (model, formName, validationHandler) => void;
  updateTestAndBookTester: (id, model, formName) => void;
  setNameBlocked: () => void;
  setNameUnblocked: () => void;
  openDeleteTestPopup: (id) => void;
  changeTestStatusAction: (...any) => void;
}

interface IProps
  extends RouteComponentProps<{ test?: string; preFillTesterId?: number }>,
    InjectedFormProps,
    IDispatch {
  projects: IProject[];
  specialisms: ISpecialism[];
  tests: ITest[];
  locations: ILocation[];
  formValues: IAnyProps;
  testStatus: TestStatus;
  isNameBlocked: boolean;
  test: any;
  baseUrl: string;
  certificateTypes: ICertificateType[];
  certificateSearchField: string;
  favoriteTesters: ITesterProfileGet[];
  preFillTesterId: number;
  currentUserPermissions: string[];
  vulnerabilities: number;
}

interface IState {
  showInfoPopup: boolean;
  showSaveModal: boolean;
  showHelpBlock: boolean;
  isInitializedForEdit: boolean;
  locations: ISelectLocation[];
  tests: ITest[];
  isExtendExisting: boolean;
  isSetTester: boolean;
}

const mappingErrors: Array<[string, string]> = [
  ["remote", "remote"],
  ["project", "project"],
  ["approach", "approach"],
  ["description", "description"],
  ["name", "name"],
  ["recurringType", "recurringType"],
  ["remindersType", "remindersType"]
];

const handleServerError = (error: IAnyProps) => {
  return mappingErrors.reduce((acc, data) => {
    const le = error.find(element => element.propertyPath === data[0]);
    return Object.assign(acc, le ? { [data[1]]: le.message } : {});
  }, {});
};

class TestUpdate extends React.Component<IProps, IState> {
  state: IState = {
    showInfoPopup: false,
    showSaveModal: false,
    showHelpBlock: true,
    isInitializedForEdit: false,
    locations: [],
    tests: [],
    isExtendExisting: false,
    isSetTester: false
  };

  handleSaveAndBook: () => void = this.props.handleSubmit((values: any) => {
    const description = this.props.formValues.description;

    if (!description || description === "<p></p>\n") {
      return;
    }

    if (description && description.length > 10000) {
      return;
    }

    if (!this.props.match.url.endsWith("edit")) {
      if (this.props.formValues.recurringType === Recurring.RECURRING_NO) {
        values.years = null;
        values.includeWeekendsType = null;

        this.props.submitFormAndBookTester(
          values,
          FORM_NAME,
          handleServerError
        );
      } else {
        this.props.submitFormAndBookTester(
          values,
          FORM_NAME,
          handleServerError
        );
      }
    } else {
      if (this.props.formValues.recurringType === Recurring.RECURRING_NO) {
        values.years = null;
        values.includeWeekendsType = null;

        this.props.updateTestAndBookTester(
          this.props.match.params.test,
          values,
          FORM_NAME
        );
      } else {
        this.props.updateTestAndBookTester(
          this.props.match.params.test,
          values,
          FORM_NAME
        );
      }
    }
  });

  handleSave: () => void = this.props.handleSubmit((values: any) => {
    const description = this.props.formValues.description;

    if (!description || description === "<p></p>\n") {
      return;
    }

    if (description && description.length > 10000) {
      return;
    }

    if (this.props.testStatus === TestStatus.STATUS_PROPOSAL_SUBMITTED) {
      if (!this.state.showSaveModal) {
        this.showSaveModal();
        return;
      }
    }

    if (this.props.match.url.endsWith("edit")) {
      let newTestStatus = this.props.testStatus;
      const test = this.props.formValues;

      if (testStatusShouldChangeToBookingRequested(Number(newTestStatus))) {
        newTestStatus = TestStatus.STATUS_BOOKING_REQUESTED_EDITED;
      } else {
        if (
          !test.dateFrom &&
          !test.dateTo &&
          this.props.testStatus === TestStatus.STATUS_SCHEDULED
        ) {
          newTestStatus = TestStatus.STATUS_DRAFT;
        } else if (
          !!test.dateFrom &&
          !!test.dateTo &&
          this.props.testStatus === TestStatus.STATUS_DRAFT
        ) {
          newTestStatus = TestStatus.STATUS_SCHEDULED;
        }
      }

      if (this.props.formValues.recurringType === Recurring.RECURRING_NO) {
        this.props.updateForm(
          this.props.match.params.test,
          {
            ...this.props.formValues,
            status: this.props.testStatus,
            years: null,
            includeWeekendsType: null
          },
          FORM_NAME,
          () => {
            this.props.changeTestStatusAction(
              this.props.match.params.test,
              newTestStatus,
              () => {
                this.props.history.push(
                  `/client/booking-requests/${this.props.match.params.test}`
                );
              }
            );
          }
        );
      } else {
        this.props.updateForm(
          this.props.match.params.test,
          {
            ...this.props.formValues,
            status: this.props.testStatus
          },
          FORM_NAME,
          () => {
            this.props.changeTestStatusAction(
              this.props.match.params.test,
              newTestStatus,
              () => {
                this.props.history.push(
                  `/client/booking-requests/${this.props.match.params.test}`
                );
              }
            );
          }
        );
      }

      this.hideSaveModal();
    } else {
      if (this.props.formValues.recurringType === Recurring.RECURRING_NO) {
        this.props.submitForm(
          {
            ...this.props.formValues,
            years: null,
            includeWeekendsType: null
          },
          FORM_NAME,
          "/client/schedule",
          handleServerError
        );
      } else {
        this.props.submitForm(
          this.props.formValues,
          FORM_NAME,
          "/client/schedule",
          handleServerError
        );
      }
    }
  });

  componentWillReceiveProps(nextProps) {
    if (
      this.props.match.url.endsWith("edit") &&
      this.props.test &&
      !this.state.isInitializedForEdit
    ) {
      this.setState({ isInitializedForEdit: true });
      this.setFormValues(this.props.formValues, this.props.test);
    }

    if (
      this.props.match.url.endsWith("copy") &&
      this.props.test &&
      !this.state.isInitializedForEdit
    ) {
      this.setState({ isInitializedForEdit: true });
      this.setFormValues(this.props.formValues, this.props.test);
    }

    if (isChangesExist(["formValues.project"], this.props, nextProps)) {
      const project = this.props.projects.find(
        (item: IProject) => item.id === nextProps.formValues.project
      );

      if (project) {
        const projectTests = this.props.tests.filter(
          (item: ITest) => item.project === nextProps.formValues.project
        );

        this.setState({ locations: project.locations, tests: projectTests });

        if (
          (this.props.match.url.endsWith("edit") ||
            this.props.match.url.endsWith("copy")) &&
          !this.props.formValues.project &&
          nextProps.formValues.project
        ) {
          return;
        }

        this.props.change(
          "purchaseOrderNumber",
          project?.purchaseOrderNumber || ""
        );
        this.props.change("location", null);
      }
    }

    if (
      !!this.props.favoriteTesters.length &&
      this.props.match.params.preFillTesterId
    ) {
      const { favoriteTesters } = this.props;
      const preFilledTester = favoriteTesters.find(
        (tester: ITesterProfileGet) =>
          tester.id === Number(this.props.match.params.preFillTesterId)
      );

      if (
        preFilledTester &&
        !this.props.formValues.preFillTester &&
        this.props.formValues.preFillTester !== 0
      ) {
        this.setPreFilledTester(preFilledTester);
      }
    }

    if (this.props.test) {
      [
        TestStatus.STATUS_DRAFT,
        TestStatus.STATUS_SCHEDULED,
        TestStatus.STATUS_BOOKING_REJECTED_BY_CUSTOMER,
        TestStatus.STATUS_BOOKING_REJECTED_BY_TESTER,
        TestStatus.STATUS_PROPOSAL_SUBMITTED_CANCELLED_BY_CUSTOMER,
        TestStatus.STATUS_PROPOSAL_SUBMITTED_CANCELLED_BY_TESTER
      ].indexOf(this.props.test.status) > 0
        ? this.setState({ isSetTester: false })
        : this.setState({ isSetTester: !!this.props.test.tester });
    }
  }

  renderLocation = (option: any) => (
    <MenuItem key={option.id} value={option.id}>
      {option.name}
    </MenuItem>
  );

  renderTest = option => (
    <MenuItem key={option.id} value={option.id}>
      {`${option.hash}-${option.name}`}
    </MenuItem>
  );

  renderOption = (value: any) => (
    <MenuItem key={value.key} value={value.key}>
      {value.value}
    </MenuItem>
  );

  renderFavoriteTesterOption = (tester: ITesterProfileGet) => (
    <MenuItem key={tester.id} value={tester.id} style={{ minHeight: 35 }}>
      {tester.id !== EMPTY_TESTER_ID ? (
        <>
          <img
            src={
              !!tester.profileImagePath
                ? BASE_URL + tester.profileImagePath
                : "/images/user-large.svg"
            }
            alt={tester.testerFirstName + " " + tester.testerLastName}
            className="rounded-profile-select img-fluid"
          />
          <span className={"span-in-select"}>
            {tester.testerFirstName + " " + tester.testerLastName}
          </span>
        </>
      ) : (
        NEW_SEARCH_CONSULTANT_OPTION
      )}
    </MenuItem>
  );

  renderFavoriteTesterLis(favoriteTesters: ITesterProfileGet[]) {
    const favoriteTestersList: ITesterProfileGet[] = favoriteTesters;

    if (
      favoriteTestersList.find(tester => tester.id === EMPTY_TESTER.id) ===
      undefined
    ) {
      favoriteTestersList.unshift(EMPTY_TESTER);
    }

    return favoriteTestersList.map(this.renderFavoriteTesterOption);
  }

  handleNeedHelp = event => {
    event.preventDefault();
    this.props.approachModalOpen();
  };

  handleInfoShow = event => {
    event.preventDefault();
    this.setState({ showInfoPopup: true });
  };

  handleInfoHide = () => {
    this.setState({ showInfoPopup: false });
  };

  handleChooseExistingTest = (ev, value) => {
    if (!value) {
      this.props.setNameUnblocked();
    }

    const test = this.props.tests.find(data => data.id === value);

    if (!test) {
      return;
    }

    this.setFormValues(this.props.formValues, test);
    this.props.setNameBlocked();
  };

  componentDidMount() {
    if (this.props) {
      const testId = Number(this.props.match.params.test);

      if (testId) {
        const test = this.props.tests.find(data => data.id === testId);

        if (test) {
          this.setFormValues(this.props.formValues, test as any);
          [TestStatus.STATUS_DRAFT, TestStatus.STATUS_SCHEDULED].indexOf(
            this.props.test.status
          ) > 0
            ? this.setState({ isSetTester: false })
            : this.setState({ isSetTester: !!test.tester });
        }
      }
    }
  }

  setFormValues = (values: IAnyProps, test: ITest) => {
    const valuesMerged: IAnyProps = form_keys.reduce((acc, key) => {
      if (!!test[key]) {
        acc[key] = test[key];
      }

      return acc;
    }, {});

    if (!!test.location) {
      valuesMerged.location = extractId(test.location["@id"] as string);
    }

    valuesMerged.remote = valuesMerged.remote ? 1 : 0;

    if (valuesMerged.specialisms) {
      valuesMerged.specialisms = valuesMerged.specialisms.map(v =>
        extractId(v["@id"])
      );
    }

    if (valuesMerged.certificateType) {
      valuesMerged.certificateType = (valuesMerged.certificateType as ICertificateType).id;
    }

    if (valuesMerged.preFillTester) {
      valuesMerged.preFillTester = (valuesMerged.preFillTester as ITesterProfileGet).id;
    }

    _.entries(valuesMerged).forEach(([key, value]) => {
      this.props.change(key, value);
    });
  };

  setPreFilledTester = (tester: ITesterProfileGet) => {
    this.props.change("preFillTester", tester.id);
  };

  handleOpenDeletePopup = e => {
    e.preventDefault();
    this.props.openDeleteTestPopup(this.props.match.params.test);
  };

  currentTestCanBeDeleted = () => {
    if (this.props.match.url.endsWith("edit")) {
      return testCanBeDeleted(Number(this.props.testStatus));
    }

    return false;
  };

  renderItem = item => (
    <MenuItem key={item.id} value={item.id}>
      {item.name}
    </MenuItem>
  );

  renderFavoriteTester = (tester: ITesterProfileGet) => {
    return (
      <MenuItem key={tester.id} value={tester.id}>
        <img
          src={
            !!tester.profileImagePath
              ? BASE_URL + tester.profileImagePath
              : "/images/user-large.svg"
          }
          alt={tester.testerFirstName + " " + tester.testerLastName}
          className="rounded-profile-select img-fluid"
        />
        <span className={"span-in-select"}>
          {tester.testerFirstName + " " + tester.testerLastName}
        </span>
      </MenuItem>
    );
  };

  toggleHelpBlock = () => {
    this.setState({ showHelpBlock: !this.state.showHelpBlock });
    Storage.setValue(SHOW_HELP_BLOCK, "false");
  };

  showSaveModal = () => {
    this.setState({ showSaveModal: true });
  };

  hideSaveModal = () => {
    this.setState({ showSaveModal: false });
  };

  showSaveAndBookButton = () => {
    if (!this.props.formValues.dateFrom || !this.props.formValues.dateTo) {
      return false;
    }

    if (this.props.match.url.endsWith("edit")) {
      return !testStatusShouldChangeToBookingRequested(this.props.testStatus);
    }

    return true;
  };

  handleClose = e => {
    e.preventDefault();

    if (this.props.match.url.endsWith("edit")) {
      this.props.push(
        `/client/booking-requests/${this.props.match.params.test}`
      );
    } else if (this.props.match.url.endsWith("copy")) {
      this.props.push(`/client/booking-requests`);
    } else {
      this.props.push(`/client/schedule`);
    }
  };

  toggleExtendExisting = () => {
    this.setState({ isExtendExisting: !this.state.isExtendExisting });
  };

  getFilteredItems = () => {
    if (Array.isArray(this.props.certificateTypes)) {
      const anyCertificate = [{ name: "Any", id: "Any" }];
      const normalizeCertificateTypes = this.props.certificateTypes.map(
        item => {
          return { name: item.shortName + " - " + item.fullName, ...item };
        }
      );

      return [...anyCertificate, ...normalizeCertificateTypes];
    }

    return [];
  };

  renderCertificateTypeItems = item => {
    return (
      <MenuItem key={item.id} value={item.id}>
        {item.name}
      </MenuItem>
    );
  };

  getRequiredByOptions = () => {
    const days: any = [{ key: -1, value: "Any" }];
    let value;
    for (let i = 1; i <= MAX_DAYS_FOR_RESPONSE; i++) {
      value = i > 1 ? `${i} days` : "1 day";
      days.push({ key: i, value });
    }
    return days;
  };

  render() {
    if (!this.props.favoriteTesters.length) {
      this.props.formValues.preFillTester = undefined;
    }
    return (
      <>
        <form noValidate autoComplete="off" onSubmit={this.handleSave}>
          <TestDeleteModal />
          <TestSaveModal
            open={this.state.showSaveModal}
            onClose={this.hideSaveModal}
            onSave={this.handleSave}
          />
          <TestInfoModal
            open={this.state.showInfoPopup}
            onClose={this.handleInfoHide}
          />

          <Grid container spacing={16}>
            <Grid item xs={12} lg={6}>
              <div className="crud-sections-header">
                {this.props.match.url.endsWith("test") && (
                  <h4 className="main-content-header">Schedule</h4>
                )}
                {this.props.match.url.endsWith("edit") && (
                  <h4 className="main-content-header">Edit</h4>
                )}
                {this.props.match.url.endsWith("copy") && (
                  <h4 className="main-content-header">Schedule (Copy)</h4>
                )}
              </div>
            </Grid>

            {/* Buttons */}

            <Grid item xs={12} lg={6} className="text-right">
              {this.currentTestCanBeDeleted() && (
                <button
                  className="btn-delete"
                  onClick={this.handleOpenDeletePopup}
                >
                  Delete
                </button>
              )}

              <Link to={"#"} onClick={this.handleClose}>
                <button className="btn-cancel">Cancel</button>
              </Link>

              <Link to={"#"} onClick={this.handleSave}>
                {this.props.testStatus ===
                TestStatus.STATUS_PROPOSAL_SUBMITTED ? (
                  <button type="submit" className="btn-save">
                    Save
                  </button>
                ) : (
                  <button type="submit" className="btn-save">
                    Save
                  </button>
                )}
              </Link>

              {this.showSaveAndBookButton() && (
                <Link to={"#"} onClick={this.handleSaveAndBook}>
                  <button className="btn-save">Save&nbsp;&&nbsp;Book</button>
                </Link>
              )}
            </Grid>

            <Grid item xs={12} lg={6}>
              <div className="test-block table-responsive">
                {/* Common Info */}

                <Grid container spacing={16} alignItems="center">
                  <Grid item xs={12}>
                    <h4 className="test-details-header mt-0">Common Info</h4>
                  </Grid>
                  <Grid item xs={8}>
                    <FieldGeneric
                      inputProps={{ maxLength: 250 }}
                      name="requester"
                      label="Requester"
                      component={ReduxTextField}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Grid item xs={4} className="pt-4 d-flex align-items-center">
                      <TooltipIcon
                        placement={"top-end"}
                        TransitionComponent={Zoom}
                        disableFocusListener
                        disableTouchListener
                        title="Project or Business owner name"
                      >
                        <Icon>info</Icon>
                      </TooltipIcon>
                    </Grid>

                  </Grid>
                  <Grid item xs={8}>
                    {!this.props.projects || !this.props.projects.length ? (
                      <div>There are no projects.</div>
                    ) : (
                      <FieldGeneric
                        name="project"
                        label="Project name *"
                        component={ReduxSelectField}
                        margin="normal"
                      >
                        {this.props.projects.map(option => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </FieldGeneric>
                    )}
                  </Grid>
                  <Grid item xs={4} className="pt-4">
                    <PermissionBlock
                      permissions={[
                        Permission.CAN_MANAGE_PROJECT,
                        Permission.CAN_CREATE_EDIT_PROJECT
                      ]}
                    >
                      <span className="link-to-center">
                        <Link
                          to="/client/tests-and-projects/projects/create"
                          target={"_blank"}
                          className="btn-new"
                        >
                          New&nbsp;project
                        </Link>
                      </span>
                    </PermissionBlock>
                    {!this.props.currentUserPermissions.includes(
                      Permission.CAN_MANAGE_PROJECT
                    ) &&
                      !this.props.currentUserPermissions.includes(
                        Permission.CAN_CREATE_EDIT_PROJECT
                      ) && (
                        <PermissionBlock group={Group.PLANNER}>
                          <span className="link-to-center">
                            <Link
                              to={"#"}
                              onClick={this.handleInfoShow}
                              className="btn-new"
                            >
                              New&nbsp;project
                            </Link>
                          </span>
                        </PermissionBlock>
                      )}
                  </Grid>
                  <Grid item xs={8}>
                    <FieldGeneric
                      inputProps={{ maxLength: 150 }}
                      name="name"
                      disabled={false}
                      label="New title *"
                      component={ReduxTextField}
                    />
                  </Grid>
                  <Grid item xs={4} className="pt-4">
                    <span
                      onClick={this.toggleExtendExisting}
                      className="btn-extend"
                    >
                      Extend&nbsp;Existing
                    </span>
                  </Grid>
                  <Grid item xs={8}>
                    {this.state.isExtendExisting && (
                      <FieldGeneric
                        onChange={this.handleChooseExistingTest}
                        name="existingTest"
                        label="Extend existing"
                        component={ReduxSelectSearchField}
                        items={this.state.tests}
                        allowDisable
                        renderItem={this.renderTest}
                      />
                    )}
                  </Grid>
                  <Grid item xs={4} />
                </Grid>

                {/* purchase number */}

                <Grid container spacing={16}>
                  <Grid item xs={12}>
                    <h4 className="test-details-header">Procurement details</h4>
                    <Grid item xs={8}>
                      <FieldGeneric
                        name="purchaseOrderNumber"
                        disabled={false}
                        label="Purchase order number"
                        component={ReduxTextField}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {/* Where will the Service take place? */}

                <Grid container spacing={16}>
                  <Grid item xs={12}>
                    <h4 className="test-details-header">
                      Where will the service take place
                    </h4>
                    <FieldGeneric
                      name="remote"
                      label="Remote"
                      defaultValue={"false"}
                      component={ReduxCheckbox}
                    />
                    {!this.props.formValues.remote && (
                      <Grid container spacing={16} alignItems="center">
                        <Grid item xs={8}>
                          <FieldGeneric
                            name="location"
                            label="Locations *"
                            component={ReduxSelectSearchField}
                            items={this.state.locations}
                            renderItem={this.renderLocation}
                          />
                        </Grid>
                        <Grid item xs={4} className="pt-4">
                          <PermissionBlock
                            permissions={[
                              Permission.CAN_MANAGE_LOCATIONS,
                              Permission.CAN_CREATE_EDIT_VIEW_LOCATIONS
                            ]}
                          >
                            <Link
                              to={"/client/admin/locations/create"}
                              target={"_blank"}
                              className="btn-new"
                            >
                              New&nbsp;location
                            </Link>
                          </PermissionBlock>
                          {!this.props.currentUserPermissions.includes(
                            Permission.CAN_MANAGE_LOCATIONS
                          ) &&
                            !this.props.currentUserPermissions.includes(
                              Permission.CAN_CREATE_EDIT_VIEW_LOCATIONS
                            ) && (
                              <PermissionBlock group={Group.PLANNER}>
                                <Link
                                  to={"#"}
                                  onClick={this.handleInfoShow}
                                  className="btn-new"
                                >
                                  New&nbsp;location
                                </Link>
                              </PermissionBlock>
                            )}
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                {/* How do you want the service to be continued */}

                <Grid container spacing={16} alignItems="center">
                  <Grid item xs={12}>
                    <h4 className="test-details-header mt-4">
                      How do you want the service to be continued
                    </h4>
                  </Grid>
                  <Grid item xs={8}>
                    <FieldGeneric
                      name="specialisms"
                      label="Type *"
                      component={ReduxSpecialismsField}
                      items={this.props.specialisms}
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={4} />
                  <Grid item xs={8}>
                    <FieldGeneric
                      name="approach"
                      label="Approach"
                      component={ReduxSelectField}
                      margin="normal"
                    >
                      {APPROACHES_LIST.map(this.renderOption)}
                    </FieldGeneric>
                  </Grid>
                  <Grid item xs={4} className="pt-4">
                    <span className="link-to-center">
                      <Link
                        to={"#"}
                        onClick={this.handleNeedHelp}
                        className="btn-need-help"
                      >
                        Need help?
                      </Link>
                    </span>
                  </Grid>
                  <Grid item xs={8}>
                    <FieldGeneric
                      name="vulnerabilities"
                      label="CVSS Scoring *"
                      component={ReduxSelectField}
                      margin="normal"
                    >
                      {YES_NO_OPTIONS_LIST.map(this.renderOption)}
                    </FieldGeneric>
                  </Grid>
                </Grid>

                {/* Details */}

                <Grid container spacing={16}>
                  <Grid item xs={12}>
                    <h4 className="test-details-header mt-4">Details</h4>
                    <label>Description of the needs/requirements *</label>
                    <FieldGeneric
                      name="description"
                      label="Description of the needs/requirements"
                      component={ReduxWYSIWYGField}
                    />
                  </Grid>
                </Grid>

                {/* Proposed date range */}

                <Grid container spacing={16}>
                  <Grid item xs={12}>
                    <h4 className="test-details-header mt-4">
                      Proposed date range
                    </h4>
                  </Grid>
                  <Grid item xs={4}>
                    <FieldGeneric
                      name="dateFrom"
                      label="From"
                      normalize={dateFormNormalizer}
                      InputLabelProps={{ shrink: true }}
                      disablePast={true}
                      component={ReduxDatePicker}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FieldGeneric
                      name="dateTo"
                      label="To"
                      normalize={dateFormNormalizer}
                      InputLabelProps={{ shrink: true }}
                      disablePast={true}
                      component={ReduxDatePicker}
                    />
                  </Grid>
                  <Grid item xs={4} />
                  <Grid item xs={8}>
                    <FieldGeneric
                      name="recurringType"
                      label="Recurring? *"
                      component={ReduxSelectField}
                      margin="normal"
                    >
                      {RECURRING_LIST.map(this.renderOption)}
                    </FieldGeneric>
                    {this.props.formValues.recurringType &&
                      this.props.formValues.recurringType !==
                        Recurring.RECURRING_NO && [
                        <div className="mb-3" key={0}>
                          <FieldGeneric
                            name="years"
                            label="For how many years?"
                            component={ReduxSelectField}
                            margin="normal"
                          >
                            <MenuItem key={0} value={0}>
                              Any
                            </MenuItem>
                            {Array.apply(null, Array(100))
                              .map((a, i) => i + 1)
                              .map(value => (
                                <MenuItem key={value} value={value}>
                                  {value}
                                </MenuItem>
                              ))}
                          </FieldGeneric>
                        </div>,
                        <div className="mb-3" key={1}>
                          <FieldGeneric
                            name="includeWeekendsType"
                            label="Include weekends *"
                            component={ReduxSelectField}
                            margin="normal"
                          >
                            {WEEKEND_LIST.map(this.renderOption)}
                          </FieldGeneric>
                        </div>
                      ]}
                  </Grid>
                  <Grid item xs={8}>
                    <FieldGeneric
                      name="remindersType"
                      label="Send reminder? *"
                      component={ReduxSelectField}
                      margin="normal"
                    >
                      {REMINDERS_LIST.map(this.renderOption)}
                    </FieldGeneric>
                  </Grid>
                  <Grid item xs={8}>
                    <FieldGeneric
                      name="daysForResponse"
                      label="Response required by *"
                      component={ReduxSelectField}
                      margin="normal"
                    >
                      {this.getRequiredByOptions().map(this.renderOption)}
                    </FieldGeneric>
                  </Grid>
                  <Grid item xs={4} className="pt-4 d-flex align-items-center">
                    <TooltipIcon
                      placement={"top-end"}
                      TransitionComponent={Zoom}
                      disableFocusListener
                      disableTouchListener
                      title="Note: The response time relates to the initial contact by the tester / consultant"
                    >
                      <Icon>info</Icon>
                    </TooltipIcon>
                  </Grid>
                  <Grid item xs={4} />
                </Grid>

                {/* Chosen Preferences */}

                <Grid container spacing={16}>
                  <Grid item xs={12}>
                    <h4 className="test-details-header mt-4">
                      Chosen Preferences
                    </h4>
                  </Grid>

                  {!!this.props.favoriteTesters.length && (
                    <Grid item xs={8}>
                      <FieldGeneric
                        name="preFillTester"
                        label="Consultant/Tester"
                        component={ReduxSelectField}
                        normalize={testerFieldNotBlankAndNotRequired}
                        renderItem={this.renderFavoriteTester}
                        disabled={this.state.isSetTester}
                        margin="normal"
                      >
                        {this.renderFavoriteTesterLis(
                          this.props.favoriteTesters
                        )}
                      </FieldGeneric>
                    </Grid>
                  )}

                  {(!this.props.formValues.preFillTester ||
                    this.props.formValues.preFillTester ===
                      EMPTY_TESTER_ID) && (
                    <>
                      <Grid item xs={8}>
                        {!this.props.formValues.remote && (
                          <FieldGeneric
                            name="radius"
                            label="Location radius"
                            component={ReduxSelectField}
                          >
                            {RADIUS_LIST.map(this.renderOption)}
                          </FieldGeneric>
                        )}
                      </Grid>
                      <Grid item xs={8}>
                        <FieldGeneric
                          name="certificateType"
                          label="Certifications"
                          component={ReduxSelectSearchField}
                          items={this.getFilteredItems()}
                          allowDisable
                          renderItem={this.renderCertificateTypeItems}
                        />
                      </Grid>
                      {/*
                        <Grid item xs={8}>
                        <FieldGeneric
                          name="experience"
                          label="Experience in years"
                          component={ReduxSelectField}
                        >
                          <MenuItem key={0} value={"Any"}>
                            Any
                          </MenuItem>
                          {Array.apply(null, Array(50))
                            .map((a, i) => i + 1 + "")
                            .map((value) => (
                              <MenuItem key={value} value={value}>
                                {value}
                              </MenuItem>
                            ))}
                        </FieldGeneric>
                      </Grid>
                      <Grid item xs={8}>
                        <FieldGeneric
                          name="publicLiability"
                          label="Public liability"
                          component={ReduxSelectField}
                        >
                          <MenuItem key={0} value={0}>
                            Any
                          </MenuItem>
                          {Array.apply(null, Array(INSURANCE_VALUES_COUNT - 1))
                            .map((a, i) => i + 1)
                            .map((value) => (
                              <MenuItem key={value} value={value}>
                                &pound;{value}m
                              </MenuItem>
                            ))}
                          <MenuItem key={0} value={INSURANCE_VALUES_COUNT}>
                            more than &pound;{INSURANCE_VALUES_COUNT - 1}m
                          </MenuItem>
                        </FieldGeneric>
                      </Grid>
                      <Grid item xs={8}>
                        <FieldGeneric
                          name="indemnityInsurance"
                          label="Indemnity Insurance"
                          component={ReduxSelectField}
                        >
                          <MenuItem key={0} value={0}>
                            Any
                          </MenuItem>
                          {Array.apply(null, Array(INSURANCE_VALUES_COUNT - 1))
                            .map((a, i) => i + 1)
                            .map((value) => (
                              <MenuItem key={value} value={value}>
                                &pound;{value}m
                              </MenuItem>
                            ))}
                          <MenuItem key={0} value={INSURANCE_VALUES_COUNT}>
                            more than &pound;{INSURANCE_VALUES_COUNT - 1}m
                          </MenuItem>
                        </FieldGeneric>
                      </Grid>
                      <Grid item xs={4} />
                      */}
                      <Grid item xs={12}>
                        <FieldGeneric
                          name="isCrestRegisteredCompany"
                          label="CREST registered company"
                          defaultValue={"false"}
                          component={ReduxCheckbox}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FieldGeneric
                          name="isCheckRegisteredCompany"
                          label="CHECK registered company"
                          defaultValue={"false"}
                          component={ReduxCheckbox}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <div className="d-flex">
                          <FieldGeneric
                            name="isGovernmentCleared"
                            label="Government Cleared"
                            defaultValue={"false"}
                            component={ReduxCheckbox}
                          />
                          <TooltipIcon
                            placement={"bottom-end"}
                            TransitionComponent={Zoom}
                            disableFocusListener
                            disableTouchListener
                            title="Contact with Consultant/Tester to provide further information"
                          >
                            <Icon className="ml-1 mt-2">info</Icon>
                          </TooltipIcon>
                        </div>
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12}>
                    <div className="d-flex">
                      <FieldGeneric
                        name="isRetest"
                        label="I would like a retest"
                        defaultValue={"false"}
                        component={ReduxCheckbox}
                      />
                      <TooltipIcon
                        placement={"bottom-end"}
                        TransitionComponent={Zoom}
                        disableFocusListener
                        disableTouchListener
                        title="The organization should take steps to remediate any exploitable vulnerability
                            within a reasonable period of time after the original test.
                            When the organization has completed these steps, the tester should perform a retest to
                            validate that the newly implemented controls mitigate the original risks"
                      >
                        <Icon className="ml-1 mt-2">info</Icon>
                      </TooltipIcon>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Guidance />
            </Grid>
          </Grid>
        </form>

        {/* BookingProposal */}

        <Grid container spacing={16}>
          <Grid item xs={12} lg={6}>
            {this.props.testStatus === TestStatus.STATUS_PROPOSAL_SUBMITTED && (
              <React.Fragment>
                <BookingProposal test={this.props.test} />
              </React.Fragment>
            )}
          </Grid>
        </Grid>

        {/* TestEditHelpBlock */}

        <Grid container spacing={16}>
          <Grid item xs={12} lg={6}>
            {!Storage.getValue(SHOW_HELP_BLOCK) &&
              this.props.testStatus ===
                TestStatus.STATUS_PROPOSAL_SUBMITTED && (
                <TestEditHelpBlock
                  showHelpBlock={this.state.showHelpBlock}
                  onToggleHelpBlock={this.toggleHelpBlock}
                />
              )}
          </Grid>
        </Grid>
      </>
    );
  }
}

const formConnected = reduxForm({
  form: FORM_NAME,
  validate: composeValidators(
    fieldMaxLength(["description"], MAX_FIELD_DESCRIPTION_LENGTH),
    wysiwygFieldsIsNotEmpty(["description"]),
    fieldsNotEmpty([
      "project",
      "test",
      "specialisms",
      "remindersType",
      "daysForResponse",
      "name",
      "location",
      "specialisms",
      "description",
      "recurringType",
      "includeWeekendsType"
    ]),
    values => {
      if (values.preFillTester === "" || values.preFillTester === null) {
        values.preFillTester = -1;
      }
      if (!values.dateTo || !values.dateFrom) {
        return;
      }

      const error = { dateFrom: "From should not be after To" };

      return new Date(values.dateFrom) >= new Date(values.dateTo) ? error : {};
    },
    values => {
      if (!values.dateTo || !values.dateFrom) {
        return;
      }

      const errorMessage = "Should be after the current day";

      if (
        new Date().setHours(0, 0, 0, 0) >
        new Date(values.dateTo).setHours(0, 0, 0, 0)
      ) {
        return { dateTo: errorMessage };
      }

      if (
        new Date().setHours(0, 0, 0, 0) >
        new Date(values.dateFrom).setHours(0, 0, 0, 0)
      ) {
        return { dateFrom: errorMessage };
      }

      return {};
    },
    values => {
      if (
        values.recurringType &&
        values.recurringType !== Recurring.RECURRING_NO
      ) {
        return fieldsNotEmpty(["includeWeekendsType"])(values);
      }

      return {};
    },
    values => {
      if (
        values.recurringType &&
        values.recurringType === Recurring.RECURRING_NO
      ) {
        values.years = null;
        values.includeWeekendsType = null;
      }

      if (!values.location) {
        return fieldsNotEmpty(["location"])(values);
      }

      return {};
    },
    values => {
      if (values.remote) {
        values.location = null;
        values.radius = null;
      }

      return {};
    },
    values => {
      if (!values.daysForResponse) {
        return fieldsNotEmpty(["daysForResponse"])(values);
      }
      const diff =
        moment(values.dateTo).diff(moment(values.dateFrom), "days") + 1;
      const diffErrorMessage =
        "Days For Response should be not more than From and To dates range amount";
      if (diff < values.daysForResponse) {
        return { daysForResponse: diffErrorMessage };
      }
      return {};
    },
    values => {
      if (values.vulnerabilities === undefined) {
        return {
          vulnerabilities: "Required"
        };
      }
      return {};
    }
  )
})(TestUpdate);

const mapStateToProps = (state, ownProps: IProps) => {
  const initialValues = {
    specialisms: []
  };

  let testStatus: TestStatus = TestStatus.STATUS_DRAFT;

  if (
    !!ownProps.tests &&
    ownProps.tests.length &&
    !!ownProps.match.params.test
  ) {
    const test = _.find(ownProps.tests, { id: +ownProps.match.params.test });

    if (!!test) {
      const picked = _.pick(test, form_keys);
      picked.location = extractId(picked.location as string);

      picked.remote = (picked.remote ? 1 : 0) as any;

      if (picked.specialisms) {
        picked.specialisms = (picked.specialisms as any).map(v =>
          extractId(v["@id"])
        );
      }

      testStatus = test.status as TestStatus;

      Object.assign(initialValues, picked);
    }
  }

  return {
    formValues: selector(state, ...form_keys),
    isNameBlocked: isNameBlockedSelector(state),
    initialValues,
    testStatus,
    currentUserPermissions: getUserPermissions(state)
  };
};

const connected = connect(mapStateToProps, {
  push,
  approachModalOpen,
  changeTestStatusAction,
  submitForm: saveTestAction,
  updateForm: updateTest,
  submitFormAndBookTester: saveTestActionAndBookTester,
  updateTestAndBookTester: updateTestActionAndBookTester,
  setNameBlocked: createAction(SET_IS_TESTER_NAME_BLOCKED, () => true),
  setNameUnblocked: createAction(SET_IS_TESTER_NAME_BLOCKED, () => false),
  openDeleteTestPopup: deleteTestPopupOpen
})(formConnected);

const fetched: any = dataFetcher(connected, [
  {
    key: "certificateTypes",
    selector: state => certificateTypesSelector.getItems(state),
    action: () => certificateTypeRequest.getItems(1, 200, ACTIVE),
    alwaysReceiveFreshData: true
  },
  {
    key: "projects",
    selector: state => projectsSelector.getItemsObject(state),
    action: () =>
      projectsForSchedulingRequest.getItems(1, MAX_PAGINATION_RESULTS, ACTIVE),
    alwaysReceiveFreshData: true
  },
  {
    key: "specialisms",
    action: () =>
      specialismsRequest.getItems(1, MAX_PAGINATION_RESULTS, ACTIVE),
    selector: state => specialismSelector.getItems(state),
    alwaysReceiveFreshData: true
  },
  {
    key: "tests",
    selector: state => testsSelector.getItemsObject(state),
    action: () => testRequest.getItems(1, MAX_PAGINATION_RESULTS, ACTIVE),
    alwaysReceiveFreshData: true
  },
  {
    key: "favoriteTesters",
    selector: state => favoriteTestersSelector.getItemsObject(state),
    action: () =>
      favoriteTestersRequest.getItems(1, MAX_PAGINATION_RESULTS, ACTIVE),
    alwaysReceiveFreshData: true
  },
  {
    key: "test",
    selector: (state, props) => {
      return testSelector.getItemById(
        result(props, "match.params.test", false)
      )(state);
    },
    action: props => {
      const testId = result(props, "match.params.test", false);

      if (testId) {
        return testRequest.getItem(result(props, "match.params.test", false));
      } else {
        return bookingPageTestItemIsEmpty();
      }
    },
    alwaysReceiveFreshData: true
  }
]);

export default withRouter(fetched);
