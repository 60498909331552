import * as React from "react";
import {composeValidators, fieldsNotEmpty} from "../../../../helpers/validations/validation";
import {InjectedFormProps, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {postSpecialismWithoutReportTemplatesAction} from "../../../../actions/specialisms-without-report-templates";
import ReportTemplatesForm from "./ReportTemplatesForm";
import {withStyles} from "@material-ui/core";
import {withRouter} from "react-router-dom";
import {defaultStyleWidth} from "../../../../styles/material/popup-style";
import routerModal from "../../../../components/routerModal";
import {WithStyles} from "@material-ui/core/styles/withStyles";

const FORM_NAME = "ReportTemplatesCreate";

class ReportTemplatesCreate extends React.Component<InjectedFormProps<{}, {}> & {
  postData: (...any) => void,
  backRoute: string
} & WithStyles<any>> {
  render() {
    const {handleSubmit, postData, backRoute} = this.props;

    return (
      <div className="styled-modal md">
        <form
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit((values) => {
            postData(values, FORM_NAME, backRoute);
          })}
        >
          <ReportTemplatesForm backRoute={backRoute}/>
        </form>
      </div>
    );
  }
}

const formConnected = reduxForm({
  form: FORM_NAME,
  validate: composeValidators(
    fieldsNotEmpty(["reportTemplate",]),
  )
})(ReportTemplatesCreate);

const connected = connect(undefined, {
  postData: postSpecialismWithoutReportTemplatesAction,
})(formConnected);

const withStyle = withStyles(defaultStyleWidth(400))(connected as any);

export default routerModal(withRouter(withStyle as any));
