import * as ActionTypes from "../constants/action-type";
import * as FilterTypes from "../constants/filter";
import {
  reducerCrudItems,
  mergeReducers,
  filterItems,
  reducerLoaderItems,
  paginationDefaultState,
} from "../helpers/reducer-helper";
import { combineReducers } from "redux";

export const currentUser = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.GET_USER:
      return (state = { ...state, ...action.payload });
    default:
      return state;
  }
};

const mainReducer = function (state = [], action) {
  switch (action.type) {
    default:
      return state;
  }
};

const loaderReducer = function (state = paginationDefaultState, action) {
  switch (action.type) {
    default:
      return state;
  }
};

const filterReducer = function (
  state = {
    field: "",
    conditions: {
      active: FilterTypes.ACTIVE,
    },
  },
  action
) {
  switch (action.type) {
    default:
      return state;
  }
};

const extraPermissions = function (state = {}, action) {
  switch (action.type) {
    case ActionTypes.SET_EXTRA_PERMISSIONS:
      return { ...state, extraPermissions: action.payload };
    default:
      return state;
  }
};

export default combineReducers({
  items: combineReducers({
    items: mergeReducers(reducerCrudItems(ActionTypes.USERS), mainReducer),
    loader: mergeReducers(reducerLoaderItems(ActionTypes.USERS), loaderReducer),
  }),
  filter: mergeReducers(filterItems(ActionTypes.USERS), filterReducer),
  permissions: extraPermissions,
});
