import * as Types from '../constants/action-type';
import {createCrudAction} from '../helpers/action-helper';
import {handleHttpErrors} from "../helpers/errors/http-error-handler";
import {getReportForTest, putItem} from "../api/requests/reports";
import {setRequestInProcess} from "./request";
import {
  getItems as getReportDocuments,
  updateReportDocumentsStatusesToSubmitted
} from "../api/requests/report-document";
import {MAX_PAGINATION_RESULTS} from "../constants/loader";
import {push} from "react-router-redux";

export const updateReport = (reportId, model, backUrl, reportsIdsForStatusesUpdate = [],) => async dispatch => {
  dispatch(setRequestInProcess(true, 'updateReport'));

  let data;

  try {
    await updateReportDocumentsStatusesToSubmitted(reportsIdsForStatusesUpdate);
    data = await putItem(reportId, model);

    dispatch(createCrudAction(Types.REPORTS).replaceItem(data));
    dispatch(push(backUrl));
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(setRequestInProcess(false, 'updateReport'));
};

export const getTestReport = (idTest: number) => async dispatch => {
  dispatch(setRequestInProcess(true, 'getTestReport'));

  try {
   const data = await getReportForTest(idTest);

    dispatch(createCrudAction(Types.REPORTS).addItem(data));
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(setRequestInProcess(false, 'getTestReport'));
}


export const getTestReportWithDocuments = (idTest: number) => async dispatch => {
  dispatch(setRequestInProcess(true, 'getTestReport'));

  let data = [], reportId;
  const key = 'id';

  try {
    data = await getReportForTest(idTest);
    reportId = data[key];

    dispatch(createCrudAction(Types.REPORTS).addItem(data));
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(setRequestInProcess(false, 'getTestReport'));

  dispatch(setRequestInProcess(true, 'getReportDocuments'));

  let reportDocuments = [], totalItems;
  try {
    ({
      items: reportDocuments,
      totalItems
    } = await getReportDocuments(1, MAX_PAGINATION_RESULTS, reportId ? reportId : 0));

    dispatch(createCrudAction(Types.REPORT_DOCUMENTS).addItems(reportDocuments));
  }
  catch (error) {
    handleHttpErrors(error, dispatch);
  }
  dispatch(setRequestInProcess(false, 'getReportDocuments'));
};
