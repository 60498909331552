import * as React from "react";
import {
  composeValidators,
  fieldIsEmail,
  fieldsNotEmpty,
} from "helpers/validations/validation";
import { InjectedFormProps, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { putSubuserAction, usersRequest } from "actions/users";
import parameterFetcher from "../../../components/parametersFetcher";
import selector from "selectors/users";
import ClientsUpdateForm from "./ClientsUpdateForm";
import { result } from "lodash";
import routerModal from "components/routerModal";
import { WithStyles } from "@material-ui/core/styles/withStyles";
import { Group } from "constants/group";
import { IAnyProps } from "interfaces/any-props.interface";
import {formValueSelector} from "redux-form";

import { CLIENT_SEARCH_FORM_NAME } from "./ClientsSearchForm";

const FORM_NAME = "UsersUpdate";

const selectorSearchForm = formValueSelector(CLIENT_SEARCH_FORM_NAME);

const mappingErrors: Array<[string, string]> = [
  ["user.email", "email"],
  ["user.phone", "phone"],
  ["user.firstName", "firstName"],
  ["user.lastName", "lastName"],
];

const handleServerError = (error: IAnyProps) => {
  return mappingErrors.reduce((acc, data) => {
    return Object.assign(
      acc,
      result(error, data[0], false) ? { [data[1]]: result(error, data[0]) } : {}
    );
  }, {});
};

class ClientUpdateModal extends React.Component<
  InjectedFormProps<{}, {}> & {
    putData: (model, formName, backRoute, handleServerError, pageNumber, itemsPerPage, showStatus, userFilter, name, email, phone, createdAt, deletedAt,) => void;
    backRoute: string;
    group: Group;
    loadPermissions: any;
    extraPermissions: object;
    currentUserPermissions: string[];
    pageNumber: string;
    itemsPerPage: string;
    showStatus: string;
    name: string;
    email: string;
    phone: string;
    createdAt: string;
    deletedAt:string;
  } & WithStyles<any>,
  any
> {
  render() {
    const {
      handleSubmit,
      putData,
      backRoute,
      initialValues,
      pageNumber,
      itemsPerPage,
      showStatus,
      name,
      email,
      phone,
      createdAt,
      deletedAt,
    } = this.props;

    return (
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit((values) => {
          putData(
            values,
            FORM_NAME,
            backRoute,
            handleServerError,
            pageNumber,
            itemsPerPage,
            showStatus,
            "Owner+Planner+Viewer+Employee+ExternalTester",
            name,
            email,
            phone,
            createdAt,
            deletedAt,
          );
        })}
      >
        <ClientsUpdateForm
          backRoute={backRoute}
          initialValues={initialValues}
        />
      </form>
    );
  }
}

const formConnected = reduxForm({
  form: FORM_NAME,
  validate: composeValidators(
    fieldsNotEmpty(["firstName", "lastName", "email", "phone"]),
    fieldIsEmail(["email"])
  ),
})(ClientUpdateModal);

const connected = parameterFetcher(
  connect(
    (state: any, props: any) => {
      return {
        initialValues: {
          ...props.initialValues,
        },
        itemsPerPage: selector.getItemsPerPage(state),
        pageNumber: selector.getPageNumber(state),
        showStatus: selector.getFilterActivityStatus(state),
        name: selectorSearchForm(state, "name") || "",
        email: selectorSearchForm(state, "email") || "",
        phone: selectorSearchForm(state, "phone") || "",
        createdAt: selectorSearchForm(state, "createdAt") || "",
        deletedAt: selectorSearchForm(state, "deletedAt") || "",
      };
    },
    {
      putData: putSubuserAction,
    }
  )(formConnected),
  FORM_NAME,
  usersRequest.getItem,
  selector.getItemById
);

export default routerModal(connected);
