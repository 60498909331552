import * as React from 'react';
import {IContentManagement} from "../models/content-management.interface";
import {CONTENT_MANAGEMENT_KEY} from "../constants/content-management";
import {dataFetcher} from "./dataFetcher";
import {getByKey} from '../selectors/content-management';
import {getItemByKey} from '../actions/content-management';
import {RawHTML} from "./RawHTML";
import Header from "../containers/guest/Header";

interface IDataFetcher {
  contentManagement: IContentManagement;
}

const contentManagementFetcher = (contentManagementKey: CONTENT_MANAGEMENT_KEY) => {
  class ComposedComponent extends React.Component<IDataFetcher, {}> {
    render() {
      return (
        <div>
          <Header/>
          <div className="page-content content-management">
            <div className="container">
              <RawHTML>{this.props.contentManagement.content}</RawHTML>
            </div>
          </div>
        </div>
      );
    }
  }

  return dataFetcher<IDataFetcher>(ComposedComponent, [{
    key: 'contentManagement',
    selector: (state, props) => getByKey(contentManagementKey)(state),
    action: () => getItemByKey(contentManagementKey),
  }]);
};

export default contentManagementFetcher;
