import * as React from "react";
import { ComponentType } from "react";
import { WrappedFieldProps } from "redux-form/lib/Field";
import { withStyles } from "@material-ui/core/styles";
import DatePicker from "material-ui-pickers/DatePicker";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import MomentUtils from "../../../node_modules/material-ui-pickers/utils/moment-utils";
import { FormHelperText } from "@material-ui/core";
import { DATE_PICKER_LABEL } from "../../constants/date-picker";
import InputLabel from "@material-ui/core/InputLabel";

const ReduxDatePicker: ComponentType<WrappedFieldProps & {
  [key: string]: any;
}> = ({
  input,
  label,
  disablePast = false,
  disableFuture = false,
  addEndOfDay = false,
  minDate = null,
  maxDate = null,
  meta: { touched, error },
  classes,
  customStyles = {},
  ...custom
}) => {
  const onChange = event => {
    if (event) {
      if (addEndOfDay) {
        input.onChange(
          event
            .add(23, "hours")
            .add(59, "minutes")
            .add(59, "seconds")
            .format() + ""
        );
      }
      input.onChange(event.format().toString());
    } else {
      input.onChange("");
    }
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <React.Fragment>
        <DatePicker
          {...input}
          style={{ width: "100%", ...customStyles }}
          label={
            <InputLabel
              style={{ fontSize: "x-large", whiteSpace: "nowrap" }}
              htmlFor="select-multiple"
              error={touched && error}
            >
              {label}
            </InputLabel>
          }
          format={DATE_PICKER_LABEL}
          invalidLabel={DATE_PICKER_LABEL}
          initialFocusedDate={new Date().setHours(0, 0, 0, 0)}
          showTodayButton={true}
          {...(minDate && { minDate })}
          {...(touched && error && { minDateMessage: "" })}
          {...(maxDate && { maxDate })}
          disablePast={disablePast}
          disableFuture={disableFuture}
          clearable
          onChange={onChange}
          margin="normal"
        />
        {touched && error ? (
          <FormHelperText style={{ color: "#f44336" }}>{error}</FormHelperText>
        ) : null}
      </React.Fragment>
    </MuiPickersUtilsProvider>
  );
};

export default withStyles({
  fullWidth: {
    width: "100%"
  }
})(ReduxDatePicker);
