import { fetchRequestBody } from "helpers/request-helper";
import { convertorFromSwagger } from "../convertors/default-convertor";
import { getApiJSON } from "../swagger/api-factory";
import { ICompanyDetails } from "models/company-details.interface";

export const getDetails = () => {
  return getApiJSON()
    .then(api => api.getApiCompanyDetails())
    .then(fetchRequestBody);
};

export const getDetailsById = (id: string) => {
  return getApiJSON()
    .then(api => api.getApiCompanyDetails({ $queryParameters: { id } }))
    .then(fetchRequestBody);
};

export const updateCompanyDetails = (model: ICompanyDetails) => {
  return getApiJSON()
    .then(api => api.putApiCompanyDetails({companyDetails: model}))
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};
