import * as React from "react";
import {connect} from "react-redux";
import {testLoader, testRequest, getCompletedTests} from "./actions/test";
import selector from "./selectors/test";
import {ITest} from "./models/test.interface";
import {IAnyProps} from "./interfaces/any-props.interface";


class CompletedTests extends React.Component<{
  baseUrl: string,
  data: ITest[],
  [key: string]: any
}, {}> {
  state = {
    completedTests: null
  };

  componentDidMount() {
    this.setState({completedTests: getCompletedTests()})
  }

  render() {
    if (!this.props.isLoaded) {
      return (
        <div>
          Loading...
        </div>
      );
    }

    if (!this.props.data.length) {
      return (
        <div>
          There are no items!
        </div>
      );
    }

    return (
      <div>
        <h1>Completed tests</h1>
      </div>
    )
  }
}

export default connect<IAnyProps, IAnyProps, IAnyProps>(
  (state) => ({
    data: selector.getItems(state),
    isLoaded: selector.getIsLoaded(state),
    itemsPerPage: selector.getItemsPerPage(state),
    pageNumber: selector.getPageNumber(state),
    showStatus: selector.getFilterActivityStatus(state),
    totalItems: selector.getTotalItems(state),
    filterText: selector.getFilterTextFields(state),

  }),
  {
    getCompletedTests,
    setItemsPerPage: testLoader.setItemsPerPage,
    setPageNumber: testLoader.setPageNumber,
    archiveItem: testRequest.archiveItem,
  }
)(CompletedTests);