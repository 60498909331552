import * as React from 'react';
import {getTesterSpecialisms} from "../../../selectors/tester-profile";
import {createLoaderActionItem} from "../../../interfaces/reducer-item.interface";
import * as actionType from "../../../constants/action-type";
import {dataFetcher} from "../../../components/dataFetcher";
import {ISpecialism} from "../../../models/specialism.interface";
import {getSpecialismsFlat} from "../../../helpers/specialisms";

interface IProps {
  specialisms: ISpecialism[];
}

class SpecialismsView extends React.Component<IProps, {}> {
  render() {
    return (
      <div>
        {getSpecialismsFlat(this.props.specialisms).map(a => a.name).join(', ')}
      </div>
    );
  }
}

export default dataFetcher<{}>(SpecialismsView, [
  {
    key: 'specialisms',
    action: () => {
      return createLoaderActionItem(actionType.TESTER_SPECIALISMS).get();
    },
    selector: (state, props) => getTesterSpecialisms(state),
  }
]);
