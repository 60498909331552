import * as React from "react";
import TestList from "./TestList";
import {withRouter} from "react-router-dom";
import TestsSearchForm from "./TestSearchForm";

class Tests extends React.Component<any, {}> {
  baseUrl = this.props.match.path;

  render() {
    return (
      <>
        <TestsSearchForm />
        <TestList baseUrl={this.baseUrl}/>
    </>
    );
  }
}

export default withRouter(Tests);