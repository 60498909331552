import * as React from "react";

import { RouteComponentProps, withRouter } from "react-router-dom";
import { ICountry } from "../../../models/dialing-code.interface";

import { styles } from "../../../styles/material/tesrer-card";
import { Card, WithStyles, withStyles } from "@material-ui/core";
import CardContent from "../../../../node_modules/@material-ui/core/CardContent/CardContent";
import Typography from "../../../../node_modules/@material-ui/core/Typography/Typography";
import RightsToWorkForm from "./RightsToWorkForm";
import { connect } from "react-redux";
import { getTesterRightsToWorkCountries } from "../../../actions/tester-rights-to-work-countries";

interface IProps extends WithStyles, RouteComponentProps<any> {
  rightsToWorkCountries: ICountry[];
  getTesterRightsToWorkCountries: () => void;
}

class RightsToWork extends React.Component<IProps> {
  baseUrl = this.props.match.path;

  componentDidMount() {
    this.props.getTesterRightsToWorkCountries();
  }

  render() {
    return (
      <Card className={this.props.classes.card}>
        <div className={this.props.classes.details}>
          <CardContent className={this.props.classes.content}>
            <Typography
              variant="headline"
              className={this.props.classes.header}
            >
              <span>Rights to work in the:</span>
            </Typography>
            <hr />
            <RightsToWorkForm countries={this.props.rightsToWorkCountries} />
          </CardContent>
        </div>
      </Card>
    );
  }
}

function mapStateToProps(state) {
  return {
    rightsToWorkCountries:
      state.testerRightToWorkCountries.testerRightToWorkCountries
  };
}

const connectedForm = connect(mapStateToProps, {
  getTesterRightsToWorkCountries
})(RightsToWork as any);

export default withRouter(withStyles(styles as any)(connectedForm) as any);
