import React, { useEffect } from "react";
import { formValueSelector } from "redux-form";
import projectsSelector from "selectors/project";
import { connect } from "react-redux";
import { WrappedFieldArrayProps } from "redux-form/lib/FieldArray";
import { CONSULTANT_FORM_NAME } from "constants/consultant";
import { selectOptionsSort } from "helpers/consultant-create-form";
import { ConnectedProps, SelectOptionType } from "types";
import { RootState } from "reducers";
import { ProjectsRepeaterItem } from "../types";
import { ProjectRepeaterRow } from "./ProjectRepeaterRow";
import { getAllProjects } from "actions/project";
import { bindActionCreators, Dispatch } from "redux";

type ProjectsRepeaterProps = WrappedFieldArrayProps<ProjectsRepeaterItem> &
  PropsFromRedux;

const searchFormSelector = formValueSelector(CONSULTANT_FORM_NAME);

const ProjectsRepeater = ({
  fields,
  getAllProjects,
  allProjects = [],
  consultantRate = 0
}: ProjectsRepeaterProps) => {
  const onAssignHandler = () => {
    fields.push({ project: 0, projectRate: 0 });
  };

  const onRemoveHandler = (index: number) => {
    fields.remove(index);
  };

  useEffect(() => {
    getAllProjects();
  }, []);

  return (
    <>
      {fields.map((name, index) => {
        const {
          project,
          projectRate = 0,
          daysInSow = 0,
          // @ts-ignore
          submitted = { total: 0 },
          // @ts-ignore
          approved = { total: 0 },
          // @ts-ignore
          invoiced = { total: 0, vat: 0, amount: 0 }
        } = fields.get(index);

        return (
          <ProjectRepeaterRow
            key={index}
            name={name}
            onRemove={() => onRemoveHandler(index)}
            consultantRate={consultantRate}
            daysInSow={daysInSow}
            projectRate={projectRate}
            allProjects={allProjects}
            submitted={submitted}
            approved={approved}
            invoiced={invoiced}
            project={project}
          />
        );
      })}

      <button className="btn-new" type="button" onClick={onAssignHandler}>
        Assign project
      </button>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  const allProjects: SelectOptionType[] = projectsSelector
    .getItems(state)
    .map(item => ({
      id: Number(item.id),
      name: item?.companyName
        ? `${item?.companyName} - ${item.name}`
        : item.name
    }))
    .sort(selectOptionsSort);

  return {
    allProjects,
    consultantRate: searchFormSelector(state, "consultantRate")
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getAllProjects
    },
    dispatch
  );
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ProjectsRepeater);
