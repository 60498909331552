import { OPEN_SNACKBAR } from '../constants/action-type';

export default function(state = {
  snackbar: {},
  popup: {},
}, action) {
  switch (action.type) {
    case OPEN_SNACKBAR:
      return {...state, snackbar: action.payload};
    default:
      return state;
  }
}
