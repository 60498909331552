import * as Types from '../constants/action-type';
import {
  createFilterAction,
  createLoaderAction,
  createRequestAction
} from '../helpers/action-helper';
import {setRequestInProcess} from "./request";
import {getRequestName} from "../helpers/action-request-helper";
import {getBookingRequestsRequest} from "../api/requests/test";
import {handleHttpErrors} from "../helpers/errors/http-error-handler";

export const testAndProjectFilter = createFilterAction(Types.TEST_AND_PROJECTS);
export const testAndProjectLoader = createLoaderAction(Types.TEST_AND_PROJECTS);
export const testAndProjectRequest = createRequestAction(Types.TEST_AND_PROJECTS);

export const getTestsListRequestsAction = (
  page: number,
  itemsPerPage: number,
  project: number[],
  status: string[],
  dateFrom: string,
  dateTo: string,
) => async dispatch => {
  dispatch(setRequestInProcess(true, getRequestName(Types.TEST_AND_PROJECTS, 'getTestsListRequests')));
  dispatch(testAndProjectLoader.setIsNotLoaded());

  let data = [], totalItems;

  try {
    ({items: data, totalItems} = await getBookingRequestsRequest(
      page,
      itemsPerPage,
      project,
      status,
      dateFrom,
      dateTo,
    ));
    dispatch(testAndProjectLoader.setPaginatioData(data, itemsPerPage, totalItems, page));
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(setRequestInProcess(false, getRequestName(Types.TEST_AND_PROJECTS, 'getTestsListRequests')));
};
