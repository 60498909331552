import * as React from "react";
import { WithStyles, withStyles } from "@material-ui/core";
import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent/CardContent";
import Button from "@material-ui/core/Button/Button";
import Typography from "@material-ui/core/Typography/Typography";
import { styles } from "../../../styles/material/tesrer-card";
import { ICertificate } from "../../../models/certificate.interface";
import { Link } from "react-router-dom";
import CertificatesTable from "./CertificatesTable";
import { RouteComponentProps, withRouter } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";
import { IAnyProps } from "../../../interfaces/any-props.interface";
import certificateSelector from "../../../selectors/certificate";
import { TableNoItems } from "../../../components/table/TableNoItems";

interface IOwnProps {
  certificates: ICertificate[];
  isLoaded: boolean;
}

interface IProps {
  getProps: (props: ICertificate[]) => void;
  [key: string]: any;
}

interface IState {
  certificateItems: ICertificate[];
}

class CertificatesCard extends React.Component<
  IProps & WithStyles & IOwnProps & RouteComponentProps<any>,
  IState
> {
  state = {
    certificateItems: []
  };

  baseUrl = this.props.match.path;

  getProps = (props: ICertificate[]) => {
    if (props) {
      this.setState({ certificateItems: [...props] });
    }

    this.setState({ certificateItems: [...props] });
  };

  highlight = () => {
    const notExpiredCertificate = this.props.certificates.find(
      (certificate: ICertificate) =>
        moment(certificate.validTo).format("YYYY-MM-DD") >
        moment().format("YYYY-MM-DD")
    );
    if (!notExpiredCertificate) {
      return " box-shadow-danger";
    }

    return "";
  };

  render() {
    if (!this.props.isLoaded) {
      return <TableNoItems asLoading hasWrapper />;
    }

    return (
      <div className={this.highlight()}>
        <Card className={this.props.classes.card}>
          <div className={this.props.classes.details}>
            <CardContent className={this.props.classes.content}>
              <Typography
                variant="headline"
                className={this.props.classes.header}
              >
                <span>Certifications</span>
                <span className={this.props.classes.rightAlign}>
                  <Link to={`${this.baseUrl}/create-certificate`}>
                    <Button size="small" color="primary">
                      New Certification
                    </Button>
                  </Link>
                </span>
              </Typography>
              <hr />
              <CertificatesTable />
            </CardContent>
          </div>
        </Card>
      </div>
    );
  }
}

const styled = withStyles(styles as any)(CertificatesCard);

const connected = connect<IAnyProps, IAnyProps, IAnyProps>(state => {
  return {
    certificates: certificateSelector.getItems(state),
    isLoaded: certificateSelector.getIsLoaded(state)
  };
})(styled);

export default withRouter(connected as any);
