import React, { useCallback, useMemo } from "react";
import {
  ExportRiskChartButton
} from "containers/client-tester/risk-chart/export/ExportRiskChartButton";
import { ITest } from "models/test.interface";
import moment from "moment/moment";
import { IRisk } from "containers/client/dashboard/DashboardCriticalRisks";
import { getConfig } from "./config";

type Props = { test: ITest, data: IRisk[], userRoles: string[] }

const ExportWrapper = ({test, data, userRoles}: Props) => {
  const fileName = `Risks of ${test.hash} - ${test.name} at ${moment().format('DD-MM-YYYY')}`;

  const columns = useMemo(() => {
    return getConfig(test, userRoles).filter(({canExport}) => {
      if (!canExport) {
        return true;
      }

      return canExport();
    })
  }, [test, userRoles])


  const convertToJson = useCallback((): string => {
    const result: Record<string, string | number | null>[] = [];

    data.forEach((record) => {
      const row = {};

      columns.forEach(({key, render}) => {
        row[key] = render(record);
      })

      result.push(row);
    })

    return JSON.stringify(result);
  }, [])

  const convertToCsv = useCallback((): string => {
    const headers = columns.map(({key}) => key)
      .filter((value) => !!value)
      .map((value) => `"${value}"`)
      .join(',');

    const values: string[] = [];

    data.forEach((record) => {
      const row: Array<string> = [];

      columns.forEach(({render}) => {
        row.push(`\"${(render(record) || '').toString().replaceAll('\"', '\'')}\"`);
      })

      values.push(row.join(','));
    })

    return [
      [headers],
      ...values,
    ].join('\n');
  }, [])

  return <>
    <ExportRiskChartButton
      disabled={data.length === 0}
      converter={{convert: convertToJson, type: 'application/json'}}
      fileName={`${fileName}.json`}
    >
      EXPORT JSON
    </ExportRiskChartButton>
    <ExportRiskChartButton
      disabled={data.length === 0}
      converter={{convert: convertToCsv, type: 'application/csv'}}
      fileName={`${fileName}.csv`}
    >
      EXPORT CSV
    </ExportRiskChartButton>
  </>
}

export default ExportWrapper;
