import * as React from "react";
import {connect} from "react-redux";
import {getTestersFiltered} from "../../../../actions/testers";
import selector from "../../../../selectors/testers";
import testSelector from "../../../../selectors/test";
import {IAnyProps} from "../../../../interfaces/any-props.interface";
import {ITesterProfileGet} from "../../../../models/tester-profile.interface";
import {MAX_PAGINATION_RESULTS, MAX_TESTERS_RESULTS} from "../../../../constants/loader";
import {ACTIVE} from "../../../../constants/filter";
import TestersView from "./TestersView";
import {withRouter} from "react-router-dom";
import {first, result} from "lodash";
import {ITest} from "../../../../models/test.interface";
import {dataFetcher} from "../../../../components/dataFetcher";
import applicationSettingsSelector from "../../../../selectors/application-settings";
import {getApplicationSettingByKey} from "../../../../actions/application-settings";
import {RawHTML} from "../../../../components/RawHTML";
import countriesSelector from "../../../../selectors/countries";
import {countriesRequest} from "../../../../actions/countries";

class TestersList extends React.Component<any & {
  baseUrl: string;
  data: ITesterProfileGet[];
  location: string;
  specialisms: any;
  approach: string;
  years: string;
  test: any;
  testData: ITest;
  loadItems: (...params) => void;
  [key: string]: any;
},
  {}> {
  componentDidMount() {
// const firstSetting = first(this.props.isBookingOpened);

// if (!firstSetting || !result(firstSetting, 'state', false)) {
    this.props.loadItems(1, MAX_TESTERS_RESULTS, ACTIVE, this.props.test.id);
// }
  }

  render() {
    if (!this.props.isLoaded) {
      return <div className="styled-block">Loading...</div>;
    }

    const firstSetting = first(this.props.isBookingOpened);

    if (!firstSetting || !result(firstSetting, "state", false)) {
      const message = result(firstSetting, "message", "");

      return (
        <div className="row text-center mt-5">
          <div className="col-md-12">
            <h4>
              <RawHTML>{message}</RawHTML>
            </h4>
            <hr/>
            <img src="/images/logo-icon-big.svg" alt="" width="320"/>
          </div>
        </div>
      );
    }

    if (!this.props.data.length) {
      return (
        <div className="styled-block">
          No testers found. Please try to change test preferences...
        </div>
      );
    }

    return (
      <div>
        {this.props.data.map((item: ITesterProfileGet) => (
          <TestersView key={item.id} item={item}/>
        ))}
      </div>
    );
  }
}

const routered = withRouter(connect<IAnyProps, IAnyProps, IAnyProps>(
  (state: any, props: any) => {
    return {
      testData: testSelector.getItemById(
        result(props, "match.params.test", false)
      )(state),
      data: selector.getItems(state),
      isLoaded: selector.getIsLoaded(state)
    };
  },
  {
    loadItems: getTestersFiltered
  }
)(TestersList as any) as any) as any;

export default dataFetcher<IAnyProps>(routered, [
  {
    key: "isBookingOpened",
    action: () => getApplicationSettingByKey("isBookingOpened"),
    selector: state => applicationSettingsSelector.getItemsObject(state),
    alwaysReceiveFreshData: true
  },
  {
    key: "countries",
    action: () => countriesRequest.getItems(1, MAX_PAGINATION_RESULTS, ACTIVE),
    selector: (state) => countriesSelector.getItemsObject(state),
    alwaysReceiveFreshData: true
  }
]);
