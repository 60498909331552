import { IOptionItem } from "../interfaces/option-item.interface";

export enum yesNoOptions {
    FALSE = 0,
    TRUE = 1,
}

export const YES_NO_OPTIONS_LIST: IOptionItem[] = [
  {
    key: yesNoOptions.TRUE,
    value: 'Yes',
  },
  {
    key: yesNoOptions.FALSE,
    value: 'No',
  },
];
