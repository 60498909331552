import { convertorFromSwagger, convertorToSwagger } from '../convertors/certificate-type';
import * as FilterActions from '../../constants/filter';
import { extractJSONLD, fetchRequestBody } from '../../helpers/request-helper';
import {getApi, getApiJSON, getApiJSONLD} from '../swagger/api-factory';
import {IAnyProps} from "../../interfaces/any-props.interface";

export const getItems = (page, itemsPerPage, show, text = '') => {
  const isShow = show || FilterActions.DEFAULT_FILTER;
  const parameters: IAnyProps = { page };

  parameters.items = itemsPerPage;
  parameters.show = isShow;

  if (text) {
    parameters.search = text;
  }

  return getApiJSONLD()
    .then(api => api.getCertificateTypeCollection(parameters))
    .then(extractJSONLD(convertorFromSwagger));
};

export const getItem = (id) => {
  return getApi()
    .then(api => api.getCertificateItem({ id }))
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};

export const postItem = (model) => {
  return getApi()
    .then(api => api.postCertificateTypeCollection({
    certificateType: convertorToSwagger(model),
    }))
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};

export const putItem = (model) => {
  return getApi()
    .then(api => api.putCertificateTypeItem({
      id: model.id,
      certificateType: convertorToSwagger(model),
    }))
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};

export const deleteItem = (id) => {
  return getApi()
    .then(api => api.deleteCertificateTypeItem({
      id,
    }))
    .then(fetchRequestBody);
};

export const unarchiveItem = (id) => {
  return getApiJSON()
    .then(api => api.unarchiveCertificateTypeItem({
      id,
      certificateType: {},
    }))
    .then(fetchRequestBody);
};
