import React from 'react';
import Paper from "@material-ui/core/Paper/Paper";
import {connect} from 'react-redux';
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Radio from "@material-ui/core/Radio/Radio";
import selector from '../../../../selectors/countries';
import {countriesFilter} from "../../../../actions/countries";
import {Field, reduxForm} from 'redux-form';
import ReduxRadioButton from '../../../../components/forms/ReduxRadioButton';
import * as FilterTypes from '../../../../constants/filter';
import {bindActionCreators} from 'redux';
import ReduxTextField from "../../../../components/forms/ReduxTextField";
import {formValueSelector} from 'redux-form';
import {isChangesExist} from '../../../../helpers/props-checker';

const FORM_NAME = 'CountrySearchForm';
const selectorForm = formValueSelector(FORM_NAME);

class CountrySearchForm extends React.Component {
  componentWillReceiveProps(nextProps) {
    if (isChangesExist(['valuesData'], nextProps, this.props)) {
      this.props.submitFilterChanges(nextProps.valuesData.active, nextProps.valuesData.text);
    }
  }

  render() {
    const {handleSubmit} = this.props;

    return (
      <form
        className="mb-3"
        onSubmit={handleSubmit((values) => {
          this.props.submitFilterChanges(values.active, values.text);
        })}>
        <Paper className="pl-4 pr-4 pb-4">
          <div className="row">
            <div className="col-md-12">
              <Field
                inputProps={{ maxLength: 20 }}
                name="text"
                label="Country"
                margin="normal"
                fullWidth
                component={ReduxTextField}
              />
            </div>
            <div className="col-md-12">
              <Field
                name="active"
                margin="normal"
                fullWidth
                inline={true}
                component={ReduxRadioButton}
              >
                <FormControlLabel
                  value={FilterTypes.ACTIVE}
                  control={<Radio/>}
                  label="Active"/>
                <FormControlLabel
                  value={FilterTypes.ARCHIVED}
                  control={<Radio/>}
                  label="Archived"/>
                <FormControlLabel
                  value={FilterTypes.ALL}
                  control={<Radio/>}
                  label="All"/>
              </Field>
            </div>
          </div>
        </Paper>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    valuesData: {...selectorForm(state, 'text', 'active')},
    initialValues: {
      text: selector.getFilterTextFields(state),
      active: selector.getFilterActivityStatus(state),
    },
  };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    submitFilterChanges: countriesFilter.submitFilterChanges,
  }, dispatch);
};

const connectForm = reduxForm({
  form: FORM_NAME,
})(CountrySearchForm);

export default connect(mapStateToProps, mapDispatchToProps)(connectForm);
