import * as React from 'react';
import Header from "../guest/Header";
import {createStyles, withStyles, WithStyles} from "@material-ui/core";
import Footer from "../guest/Footer";

const styles = createStyles({
  iframe: {
    width: '100%',
    height: '430px',
    border: 0
  }
});

class AboutAvordStaticPage extends React.Component<WithStyles<typeof styles>> {
  render() {
    return (
      <div>
        <Header/>
        <div className="page-content content-management">

          <section className="new-about">
            <div className="container">
              <h1>About AVORD</h1>
              <div className="video-block">
                <iframe
                  className={this.props.classes.iframe}
                  allowFullScreen
                  src="https://www.youtube.com/embed/h29sSzcAk_k?modestbranding=1&rel=0&ytp-pause-overlay=0&showinfo=0&autoplay=1"
                />
              </div>
            </div>
          </section>


          <section className="new-about objectives">
            <div className="container">
              <h2 className="text-center mb-5">AVORD Objectives</h2>

              <div className="row">
                <div className="col-md-4 left-column">
                  <h3 className="mb-3">Affordable</h3>
                  <p className="mb-5">
                    AVORD will reduce the cost of security testing by at least 30-40%, enabling small,
                    medium and large sized businesses to integrate security testing early on in the development
                    lifecycle and increase their security posture.
                  </p>
                  <h3 className="mb-3">Transparent</h3>
                  <p className="mb-5">
                    Organise, book and manage your testing teams time and cost in an intuative platform.
                    Understand your daily, monthly even yearly costs and work directly with each tester
                    to build those key relationships for a better security outcome.
                  </p>
                </div>
                <div className="col-md-4">
                  <img src="images/objectives.png" alt="AVORD Objectives" className="img-fluid mb-5"/>
                </div>
                <div className="col-md-4 right-column">
                  <h3 className="mb-3">Accessible</h3>
                  <p className="mb-5">
                    AVORD is the first platform that brings together security testers and businesses, cutting out
                    the middle men. Security testers are selected based on their experience and availability,
                    with businesses given the choice of who to work with.
                  </p>
                  <h3 className="mb-3">Less complex</h3>
                  <p>
                    AVORD is designed to make testing much easier to manage. The platform automates many of
                    the elements of the testing prodecure. Schedule and manage your tests with ease,
                    with full detailed analysis of costs for past and planned tests.
                  </p>
                </div>
              </div>

            </div>
          </section>

          <section className="new-about tests">
            <div className="container">

              <div className="text-center">
                <h2 className="mb-4">Types of tests & testing examples</h2>
                <p className="mb-4">
                  AVORD provides a full range of testing capabilities including security, functionality and performance
                  tests. Security testing is a process intended to reveal flaws in the security mechanisms of buildings,
                  systems or people that are fundemental in the success of any business. As many systems contains
                  confidential data it is essential that regular tests are performed to ensure critical assets are
                  properly protected.
                </p>
              </div>

              <div className="test-cars-wrapper">

                <div className="test-card">
                  <h4>Your systems</h4>
                  <ul>
                    <li>Software Testing</li>
                    <li>System Testing</li>
                    <li>Stress Testing</li>
                    <li>Code Reviews</li>
                    <li>Database Optimisation</li>
                  </ul>
                </div>

                <div className="test-card">
                  <h4>Your clients</h4>
                  <ul>
                    <li>Functional Testing</li>
                    <li>User Testing</li>
                    <li>Performance Testing</li>
                    <li>User Acceptance Testing</li>
                    <li>Scalability Testing</li>
                  </ul>
                </div>

                <div className="test-card">
                  <h4>Security</h4>
                  <ul>
                    <li>Penetration Testing</li>
                    <li>Vulnerability Testing</li>
                    <li>Social Engineering</li>
                    <li>Phishing simulation testing</li>
                    <li>PCI Testing</li>
                  </ul>
                </div>

              </div>

            </div>
          </section>

          <section className="new-about organise">
            <div className="container text-center">

              <h2 className="m-0">Organise and book your tests simple and easy</h2>
              <img src="images/schedule-a-test.svg" alt="Organise and book your tests simple and easy" className="img-fluid"/>

            </div>
          </section>

          <section className="new-about tester-profiles">
            <div className="container text-center">

              <h2 className="mb-4">Tester profiles</h2>
              <p>
                We ensure that every tester on the AVORD platform provides full details of their capabilities and work
                history. We also provide a DBS check for any tester who joins the platform. AVORD is about openess and
                transparancy on costs, testers and process.
              </p>
              <img src="images/tester-details.png" alt="Tester profiles" className="img-fluid"/>

            </div>
          </section>

          <section className="new-about confidentiality">
            <div className="container text-center">

              <h2 className="mb-4">Confidentiality</h2>
              <p>
                Confidentiality and discression is at the heart of the AVORD Platform
              </p>
              <img src="images/confidentiality.png" alt="Confidentiality" className="img-fluid my-4"/>

            </div>
          </section>

          <section className="new-about making-nations">
            <div className="container">
              Making nations safer places to do business
            </div>
          </section>

        </div>
        <Footer/>
      </div>
    )
  }
}

export default withStyles(styles)(AboutAvordStaticPage);