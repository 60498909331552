import * as React from "react";

interface IProps {
  srcDoc: string;
}

const Iframe = (props: IProps) => {
  const {srcDoc} = props;

  const doc = srcDoc + `<style>
    @import url('https://fonts.googleapis.com/css?family=Open+Sans');
    
    * {
      box-sizing: border-box;
      font-family: "Open Sans", sans-serif;
    }
    
    body {
      max-width: 706px;
      padding: 20px;
      margin: 0 auto;
      font: 16px/1.5 "Open Sans", sans-serif;
    }
    
    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    th, td {
      padding: 20px;
      border: 1px solid #cccccc;
    }
    
    img {
      display: block;
      max-width: 100%;
    }
    </style>`;

  return <iframe srcDoc={doc} className="wysiwyg-iframe"/>;
};

export default Iframe;