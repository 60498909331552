import * as Types from '../constants/action-type';
import {
  createFilterAction,
  createLoaderAction,
  createRequestAction
} from '../helpers/action-helper';
import {setRequestInProcess} from "./request";
import {handleHttpErrors} from "../helpers/errors/http-error-handler";
import {getRequestName} from "../helpers/action-request-helper";
import {getBookingRequestsRequest} from "../api/requests/test";

export const testerBookingRequestsFilter = createFilterAction(Types.TESTER_BOOKING_REQUESTS);
export const testerBookingRequestsLoader = createLoaderAction(Types.TESTER_BOOKING_REQUESTS);
export const testerBookingRequestsRequest = createRequestAction(Types.TESTER_BOOKING_REQUESTS);

export const getBookingRequestsAction = (
  page: number,
  itemsPerPage: number,
  project: number[],
  status: string[],
  dateFrom: string,
  dateTo: string,
  questionAsked: boolean,
) => async dispatch => {
  dispatch(setRequestInProcess(true, getRequestName(Types.TESTER_BOOKING_REQUESTS, 'getBookingRequests')));
  /** part responsible for a loader state */
  dispatch(createLoaderAction(Types.TESTER_BOOKING_REQUESTS).setIsNotLoaded());

  let data = [], totalItems;

  try {
    ({items: data, totalItems} = await getBookingRequestsRequest(
      page,
      itemsPerPage,
      project,
      status,
      dateFrom,
      dateTo,
      questionAsked,
    ));
    dispatch(createLoaderAction(Types.TESTER_BOOKING_REQUESTS).setPaginatioData(data, itemsPerPage, totalItems, page));
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(setRequestInProcess(false, getRequestName(Types.TESTER_BOOKING_REQUESTS, 'getBookingRequests')));
};