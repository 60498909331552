import * as React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCellEdit from "./TableCellEdit";
import TableCellArchive from "./TableCellArchive";
import TableCell from "@material-ui/core/TableCell/TableCell";
import { FILTER_TYPE } from "constants/filter";
import { ReactNode } from "react";

type Props<T> = {
  item: any;
  baseUrl: string;
  showStatus: FILTER_TYPE;
  onArchive: (item: T) => void;
  onUnarchive: (item: T) => void;
  needActions: boolean;
  children: ReactNode | ReactNode[];
};

class EnhancedTableRow<T> extends React.Component<Props<T>> {
  onArchive = () => {
    this.props.onArchive(this.props.item);
  };

  onUnarchive = () => {
    this.props.onUnarchive(this.props.item);
  };

  render() {
    const { item, needActions, showStatus, baseUrl } = this.props;
    let { children } = this.props;

    if (!Array.isArray(children)) {
      children = [children];
    }

    return (
      <TableRow key={item.id}>
        {(children as ReactNode[]).map((item, index) => (
          <TableCell key={index}>{item}</TableCell>
        ))}
        {needActions && (
          <>
            <TableCellEdit status={showStatus} item={item} baseUrl={baseUrl} />
            <TableCellArchive
              onArchive={this.onArchive}
              onUnarchive={this.onUnarchive}
              item={item}
            />
          </>
        )}
      </TableRow>
    );
  }
}

export default EnhancedTableRow;
