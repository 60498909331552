import * as Types from '../constants/action-type';
import {
  createFilterAction,
  createLoaderAction,
  createCrudAction,
  createRequestAction
} from '../helpers/action-helper';
import {CONTENT_MANAGEMENT_KEY} from "../constants/content-management";
import {getRequestName} from "../helpers/action-request-helper";
import {putItem} from "../api/requests/content-management";
import {reset} from "redux-form";
import {push} from "react-router-redux";
import {handleHttpErrors} from "../helpers/errors/http-error-handler";
import {setRequestInProcess} from "./request";
import {openSnackbar} from "./snackbar";

const crudType = Types.CONTENT_MANAGEMENT;

export const contentManagementCrud = createCrudAction(crudType);
export const contentManagementLoader = createLoaderAction(crudType);
export const contentManagementRequest = createRequestAction(crudType);

export const getItemByKey = (key: CONTENT_MANAGEMENT_KEY) => {
  return {
    type: Types.CONTENT_MANAGEMENT_GET_BY_KEY,
    payload: key,
  };
};

export const putContentManagementItem = (model, formName, backRoute) => async dispatch => {
  dispatch(setRequestInProcess(true, getRequestName(crudType, 'putItem')));

  if (model.content ===  "<p></p>\n") {
    dispatch(openSnackbar('Content cannot be empty', 4000));
    dispatch(setRequestInProcess(false, getRequestName(crudType, 'putItem')));
    return;
  }

  let data;

  try {
    data = await putItem(model);

    dispatch(contentManagementCrud.replaceItem(data));
    dispatch(reset(formName));
    dispatch(push(backRoute))
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(setRequestInProcess(false, getRequestName(crudType, 'putItem')));
};