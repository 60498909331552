import request from 'superagent';
import {BASE_URL, RESET_PASSWORD} from "../../constants/urls";

export const postEmail = (email) => {
  return new Promise((resolve, reject) => {
    request
    .post(`${BASE_URL}${RESET_PASSWORD}`)
    .send({email,})
    .end((err, res) => {
      if (err) {
        return reject(err);
      }

      resolve(JSON.parse(res.text));
    })
  });
};
