import {setRequestInProcess} from "./request";
import {getRequestName} from "../helpers/action-request-helper";
import * as Types from "../constants/action-type";
import {handleHttpErrors} from "../helpers/errors/http-error-handler";
import {SettingsFormValuesType} from "../types/client-admin-settings";
import {putEmailDomainsRequest} from "../api/requests/email-domains";
import {putIpRangesRequest} from "../api/requests/ip-ranges";

export const putEmailDomainsAndIpRangesRequestsAction = (values: SettingsFormValuesType, callback: () => void) => async dispatch => {
  dispatch(setRequestInProcess(true, getRequestName(Types.CLIENT_ADMIN_SETTINGS, 'putEmailDomainsAndIpRangesRequestsAction')));

  try {
    const {emailDomains, ipRanges} = values;
    await putEmailDomainsRequest(emailDomains);
    await putIpRangesRequest(ipRanges);

    callback();

  } catch (error) {
    handleHttpErrors(error, dispatch);
  }
  finally {
    dispatch(setRequestInProcess(false, getRequestName(Types.CLIENT_ADMIN_SETTINGS, 'putEmailDomainsAndIpRangesRequestsAction')));
  }
};