import React from "react";
import {connect} from "react-redux";
import getLastMessage from "../../../actions/lastMessage";
import Table from "@material-ui/core/es/Table/Table";
import TableHead from "@material-ui/core/es/TableHead/TableHead";
import TableRow from "@material-ui/core/es/TableRow/TableRow";
import TableCell from "@material-ui/core/es/TableCell/TableCell";
import TableBody from "@material-ui/core/es/TableBody/TableBody";
import {Link} from "react-router-dom";
import {Search} from "@material-ui/icons";
import {extractId} from "../../../helpers/converter-helper";
import {formatDateWithTime} from "../../../helpers/date-formatter";

class DashboardMessages extends React.Component {
  componentDidMount() {
    this.props.getLastMessage();
  }

  render() {
    const {lastMessages} = this.props;

    if (!lastMessages) {
      return (
        <div className="styled-block">
          <h4 className="block-header mb-4">Messages</h4>
          <div className="table-no-items">Loading...</div>
        </div>
      );
    }

    if (lastMessages.length === 0) {
      return (
        <div className="styled-block">
          <h4 className="block-header mb-4">Messages</h4>
          <div className="table-no-items">There are no items...</div>
        </div>
      );
    }

    return (
      <div className="styled-block">
        <h4 className="block-header mb-4">Messages</h4>
        <div className="table-responsive styled-table">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date submitted</TableCell>
                <TableCell>By</TableCell>
                <TableCell>Message</TableCell>
                <TableCell>View</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!!lastMessages && lastMessages.map((message) => {
                return (<TableRow>
                  <TableCell scope="row">
                    <span className="date">{formatDateWithTime(message.createdAt)}</span>
                  </TableCell>
                  <TableCell>
                    <span className="author-name">{message.authorName}</span>
                  </TableCell>
                  <TableCell>{message.content}</TableCell>
                  <TableCell>
                    {message.test &&
                    <Link to={`/client/reports/${extractId(message.test)}/details`}>
                      <Search/>
                    </Link>
                    }
                  </TableCell>
                </TableRow>);
              })}
            </TableBody>
          </Table>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    lastMessages: state.dashboard.lastMessage,
  }),

  {
    getLastMessage,
  }
)(DashboardMessages);