import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import { ITest } from "../../../../models/test.interface";
import AvordValidationModal from "../modal/AvordValidationModal";
import { avordValidationModalOpen } from "../../../../actions";

interface IProps {
  testId: number;
  openModal: () => void;
}

const AdminValidationButton = (props: IProps) => {
  const { testId, openModal } = props;

  return (
    <div className="text-right">
      <Button
        className="float-right mb-2 ml-3"
        variant="contained"
        color="primary"
        onClick={openModal}
      >
        Save & Validate
      </Button>

      <AvordValidationModal testId={testId}/>
    </div>
  );
};

const connected = connect(undefined, {
  openModal: avordValidationModalOpen,
})(AdminValidationButton as any);

export default withRouter(connected as any) as any;
