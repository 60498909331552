import 'rxjs';
import {flatMap, switchMap} from 'rxjs/operators';
import {ActionsObservable} from 'redux-observable';
import * as actionType from '../constants/action-type';
import {Action} from 'redux';
import * as Request from '../api/requests/specialisms';
import {fromPromise} from "rxjs/internal-compatibility";
import {ISpecialismChanges} from "../interfaces/specialisms-changes.interface";
import {startEndCatch} from "./pipes/errors";
import {createAction} from "redux-actions";
import {push} from "react-router-redux";
import {getSpecialismsWithoutReportTemplatesAction} from '../actions/specialisms-without-report-templates';
import {Observable} from "rxjs/index";

interface IActionChanges extends Action {
  payload: ISpecialismChanges;
}

export const specialismSaveBatch = (action$: ActionsObservable<IActionChanges>): Observable<any> => {
  return action$
    .ofType(actionType.SPECIALISM_SAVE_BATCH)
    .pipe(
      switchMap((action: IActionChanges) => {
        return fromPromise(Request.postItem(action.payload))
          .pipe(
            flatMap((data) => {
              return [
                getSpecialismsWithoutReportTemplatesAction(),
                createAction(actionType.DOCUMENT_POST_COMPLETED, () => data)(),
                push('/admin/settings/specialisms'),
              ];
            }),
            ...startEndCatch('specialismSaveBatch'),
          );
      }),
    );
};
