import React, {useState, useEffect} from "react";
import {Modal} from "@material-ui/core";

type ConfirmModalProps = {
  isOpened: boolean;
  onConfirm?: () => void;
  onClose?: () => void;
  okLabel?: string;
  cancelLabel?: string;
  title?: string;
  titleLabel?: string;
  confirmDescription?: string;
}

export const ConfirmModal = (props: ConfirmModalProps) => {
  const {
    isOpened = false,
    onClose,
    onConfirm,
    okLabel = 'Yes',
    cancelLabel = 'No',
    title = 'Are you sure?',
    confirmDescription,
    titleLabel
  } = props;

  const [isOpenedModal, setIsOpenedModal] = useState<boolean>(false);

  useEffect(() => {
    setIsOpenedModal(isOpened);
  }, [isOpened]);

  const onHandleConfirm = () => {
    if(typeof onConfirm === 'function') {
      onConfirm();
    }
  }
  const onHandleClose = () => {
    if(typeof onClose === 'function') {
      onClose();
    } else {
      setIsOpenedModal(false);
    }
  }

  return (
    <Modal
      aria-labelledby="confirm-modal-title"
      aria-describedby="simple-modal-description"
      open={isOpenedModal}
      onClose={onClose}
      className="d-flex overflow-auto"
    >
      <div className="styled-modal sm">
        {titleLabel && (<p>{titleLabel}</p>)}
        <h4>{title}</h4>
        <hr/>
        {confirmDescription && (<p>{confirmDescription}</p>)}
        <div className="text-right">
          <button className="btn-cancel" onClick={onHandleClose}>{cancelLabel}</button>
          <button type="submit" className="btn-save" onClick={onHandleConfirm}>{okLabel}</button>
        </div>
      </div>
    </Modal>
  );
}