import * as React from 'react';
import BookingsList from "./BookingsList";
import BookingsSearchForm from "./BookingsSearchForm";
import {Switch, Route, withRouter} from "react-router-dom";
import BookingDetails from "../../client-tester/test-details/Test";

class Bookings extends React.Component<{
  [key: string]: any,
}, {}> {
  baseUrl = this.props.match.path;

  main = () => (
    <React.Fragment>
      <div className="crud-sections-header">
        <h4>Bookings</h4>
      </div>
      <hr/>
      <BookingsSearchForm/>
      <BookingsList baseUrl={this.baseUrl} id={this.props.match.params.test}/>
    </React.Fragment>
  );

  testDetails = () => <BookingDetails {...{backRoute: this.baseUrl}}/>;

  render() {
    return (
      <div>
        <Switch>
          <Route exact path={this.baseUrl} render={this.main}/>
          <Route path={this.baseUrl + "/:test"} render={this.testDetails}/>
        </Switch>
      </div>
    );
  }
}

export default withRouter(Bookings as any);
