import * as React from "react";
import {
  composeValidators,
  fieldIsEmail,
  fieldMinValueForNumber,
  fieldsNotEmpty,
} from "../../../../helpers/validations/validation";
import {formValueSelector, InjectedFormProps, reduxForm} from "redux-form";
import { connect } from "react-redux";
import { putSubuserAction, usersRequest } from "../../../../actions/users";
import parameterFetcher from "../../../../components/parametersFetcher";
import selector, { getExtraPermissions } from "../../../../selectors/users";
import UsersForm from "./UsersForm";
import { result } from "lodash";
import routerModal from "../../../../components/routerModal";
import { WithStyles } from "@material-ui/core/styles/withStyles";
import { Group } from "../../../../constants/group";
import { getUserGroup, getUserPermissions } from "../../../../selectors/auth";
import { IAnyProps } from "../../../../interfaces/any-props.interface";
import { handleKeyPressOnPhoneField } from "../../../../helpers/validations/numberFieldValidator";
import projectSelector from "../../../../selectors/project";
import {getAllProjects, projectRequest} from "../../../../actions/project";
import {IProject} from "../../../../models/project.interface";
import {extractId} from "../../../../helpers/converter-helper";
import {ILocation} from "../../../../models/location.interface";
import {getAllLocations} from "../../../../actions";
import locationsSelector from "../../../../selectors/locations";

const FORM_NAME = "UsersUpdate";
const getFormValues = formValueSelector(FORM_NAME);

const mappingErrors: Array<[string, string]> = [
  ["user.email", "email"],
  ["user.phone", "phone"],
  ["user.firstName", "firstName"],
  ["user.lastName", "lastName"],
];

const handleServerError = (error: IAnyProps) => {
  return mappingErrors.reduce((acc, data) => {
    return Object.assign(
      acc,
      result(error, data[0], false) ? { [data[1]]: result(error, data[0]) } : {}
    );
  }, {});
};

const getAllowedIds = (allowedProjects = []) => {
  if(allowedProjects.length === 0) {
    return [];
  }
  return allowedProjects.map((user) => +extractId(user["@id"]));
}

class UsersUpdate extends React.Component<
  InjectedFormProps<{}, {}> & {
    putData: (model, formName, backRoute, handleServerError, pageNumber, itemsPerPage, showStatus) => void;
    getAllProjects: (...any) => void;
    getAllLocations: (...any) => void;
    backRoute: string;
    group: Group;
    loadPermissions: any;
    extraPermissions: object;
    currentUserPermissions: string[];
    pageNumber: string;
    itemsPerPage: string;
    showStatus: string;
    projects: IProject[],
    locations: ILocation[],
    valuesData: {
      projectsIds: number[];
      locationsIds: number[];
    }
  } & WithStyles<any>,
  any
> {
  componentDidMount() {
    this.props.getAllProjects();
    this.props.getAllLocations();
  }

  render() {
    const {
      handleSubmit,
      putData,
      group,
      backRoute,
      initialValues,
      extraPermissions,
      currentUserPermissions,
      pageNumber,
      itemsPerPage,
      showStatus,
      projects = [],
      locations = [],
      valuesData = {
        projectsIds: [],
        locationsIds: [],
      }
    } = this.props;

    return (
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit((values) => {
          putData(
            values,
            FORM_NAME,
            backRoute,
            handleServerError,
            pageNumber,
            itemsPerPage,
            showStatus
          );
        })}
      >
        <UsersForm
          backRoute={backRoute}
          group={group}
          initialValues={initialValues}
          extraPermissions={extraPermissions}
          currentUserPermissions={currentUserPermissions}
          isCreateUser={false}
          valuesData={valuesData}
          change={this.props.change}
          projects={projects}
          locations={locations}
        />
      </form>
    );
  }
}

const formConnected = reduxForm({
  form: FORM_NAME,
  validate: composeValidators(
    fieldsNotEmpty(["firstName", "lastName", "email", "phone", "userGroup"]),
    fieldIsEmail(["email"])
  ),
})(UsersUpdate);

const connected = parameterFetcher(
  connect(
    (state: any, props: any) => {
      const user = selector.getItemById(
        result(props, "match.params.id", false)
      )(state);
      return {
        group: getUserGroup(state),
        currentUserPermissions: getUserPermissions(state),
        extraPermissions: getExtraPermissions(state),
        initialValues: {
          ...props.initialValues,
          userGroup: user.groupName,
          projectsIds: getAllowedIds(user?.allowedProjects),
          locationsIds: getAllowedIds(user?.allowedLocations)
        },
        itemsPerPage: selector.getItemsPerPage(state),
        pageNumber: selector.getPageNumber(state),
        showStatus: selector.getFilterActivityStatus(state),
        projects: projectSelector.getItems(state),
        locations: locationsSelector.getItems(state),
        valuesData: getFormValues(state, "projectsIds", "locationsIds")
      };
    },
    {
      putData: putSubuserAction,
      getAllProjects: getAllProjects,
      getAllLocations: getAllLocations,
    }
  )(formConnected),
  FORM_NAME,
    usersRequest.getItem,
  selector.getItemById
);

export default routerModal(connected);
