import * as Types from "../constants/action-type";
import {ICalendarEvent} from "../reducers/calendar";
import {TestStatus} from "../constants/test-status";
import {Moment} from "moment";

export const contactsModalOpen = () => {
  return {type: Types.MODAL_CONTACT_OPEN};
};

export const contactsModalClose = () => {
  return {type: Types.MODAL_CONTACT_CLOSE};
};

export const thankYouForRegistrationModalOpen = () => {
  return {type: Types.MODAL_AFTER_REGISTRATION_OPEN};
};

export const thankYouForRegistrationModalClose = () => {
  return {type: Types.MODAL_AFTER_REGISTRATION_CLOSE};
};

export const loginModalOpen = () => {
  return {type: Types.MODAL_LOGIN_OPEN};
};

export const loginModalClose = () => {
  return {type: Types.MODAL_LOGIN_CLOSE};
};

export const forgotPasswordModalOpen = () => {
  return {type: Types.MODAL_FORGOT_PASSWORD_OPEN};
};

export const forgotPasswordModalClose = () => {
  return {type: Types.MODAL_FORGOT_PASSWORD_CLOSE};
};

export const changePasswordModalOpen = () => {
  return {type: Types.CHANGE_PASSWORD_MODAL_OPEN};
};

export const requestAccountDeletionModelOpen = () => {
  return {type: Types.REQUEST_ACCOUNT_DELETION_MODAL_OPEN};
};

export const requestAccountDeletionModelClose = () => {
  return {type: Types.REQUEST_ACCOUNT_DELETION_MODAL_CLOSE};
};

export const testerAccountModalOpen = () => {
  return {type: Types.TESTER_ACCOUNT_MODAL_OPEN};
};

export const testerAccountModalClose = () => {
  return {type: Types.TESTER_ACCOUNT_MODAL_CLOSE};
};

export const clientAccountModalOpen = () => {
  return {type: Types.CLIENT_ACCOUNT_MODAL_OPEN};
};

export const clientAccountModalClose = () => {
  return {type: Types.CLIENT_ACCOUNT_MODAL_CLOSE};
};

export const changePasswordModalClose = () => {
  return {type: Types.CHANGE_PASSWORD_MODAL_CLOSE};
};

export const approachModalOpen = () => {
  return {type: Types.APPROACH_MODAL_OPEN};
};

export const deleteTestPopupOpen = (id) => {
  return {
    type: Types.DELETE_TEST_MODAL_OPEN,
    payload: id,
  };
};

export const deleteTestPopupClose = () => {
  return {
    type: Types.DELETE_TEST_MODAL_CLOSE,
  };
};

export const deleteTestPopupSubmit = (id) => {
  return {
    type: Types.DELETE_TEST_MODAL_SUBMIT,
    payload: id,
  };
};

export const deleteTestWithoutRedirectPopupSubmit = (id: number, cb?: () => void ) => {
  return {
    type: Types.DELETE_TEST_MODAL_SUBMIT_WITHOUT_REDIRECT,
    payload: {id, cb},
  };
};

export const approachModalClose = () => {
  return {type: Types.APPROACH_MODAL_CLOSE};
};

export const showDrawer = () => {
  return {type: Types.SHOW_DRAWER};
};

export const hideDrawer = () => {
  return {type: Types.HIDE_DRAWER};
};

export const calendarModalOpen = (date: Moment, events: ICalendarEvent[]) => {
  return {type: Types.CALENDAR_MODAL_OPEN, payload: {date, events}};
};

export const calendarModalClose = () => {
  return {type: Types.CALENDAR_MODAL_CLOSE};
};

export const reassignModalOpen = (id) => {
  return {type: Types.REASSIGN_MODAL_OPEN, payload: id};
};

export const reassignModalGroup = (groupName) => {
  return {type: Types.REASSIGN_MODAL_GROUP, payload: groupName};
};

export const reassignModalClose = () => {
  return {type: Types.REASSIGN_MODAL_CLOSE};
};

export const reassignModalTitle = (modalTitle) => {
  return {type: Types.REASSIGN_MODAL_TITLE, payload: modalTitle};
};

export const questionModalOpen = (id) => {
  return {type: Types.QUESTION_MODAL_OPEN, payload: id};
};

export const questionModalClose = () => {
  return {type: Types.QUESTION_MODAL_CLOSE};
};

export const questionNewModalOpen = () => {
  return {type: Types.QUESTION_NEW_MODAL_OPEN};
};

export const questionNewModalClose = () => {
  return {type: Types.QUESTION_NEW_MODAL_CLOSE};
};

export const amendmentsModalOpen = (item) => {
  return {type: Types.AMENDMENTS_MODAL_OPEN, payload: item};
};

export const amendmentsModalClose = () => {
  return {type: Types.AMENDMENTS_MODAL_CLOSE};
};

export const amendmentsAnswerModalOpen = (item) => {
  return {type: Types.AMENDMENTS_ANSWER_MODAL_OPEN, payload: item};
};

export const amendmentsAnswerModalClose = () => {
  return {type: Types.AMENDMENTS_ANSWER_MODAL_CLOSE};
};

export const reportAcceptedModalOpen = () => {
  return {type: Types.REPORT_ACCEPTED_MODAL_OPEN};
};

export const reportAcceptedModalClose = () => {
  return {type: Types.REPORT_ACCEPTED_MODAL_CLOSE};
};

export const reportDocumentCreateModalOpen = (item, name = '') => {
  return {type: Types.REPORT_DOCUMENT_MODAL_OPEN, payload: {item, name}};
};

export const reportDocumentCreateModalClose = () => {
  return {type: Types.REPORT_DOCUMENT_MODAL_CLOSE};
};

export const bookingRejectModalOpen = (testId: number, status: TestStatus, backRoute: string) => {
  return {
    type: Types.BOOKING_REJECT_MODAL_OPEN,
    payload: {
      testId,
      status,
      backRoute
    }
  }
};

export const bookingRejectModalClose = () => {
  return {type: Types.BOOKING_REJECT_MODAL_CLOSE}
};

export const bookingRejectModalSubmit = () => {
  return {type: Types.BOOKING_REJECT_MODAL_SUBMIT}
};

export const bookingCancelModalOpen = (testId: number, status: TestStatus, backRoute: string) => {
  return {
    type: Types.BOOKING_CANCEL_MODAL_OPEN,
    payload: {
      testId,
      status,
      backRoute
    }
  }
};

export const bookingCancelModalClose = () => {
  return {type: Types.BOOKING_CANCEL_MODAL_CLOSE}
};

export const avordValidationModalOpen = (): {type: string} => {
  return {
    type: Types.AVORD_VALIDATION_MODAL_OPEN,
  }
};

export const avordValidationModalClose = (): {type: string}  => {
  return {type: Types.AVORD_VALIDATION_MODAL_CLOSE}
};

export const editProposalByTesterModalOpen = () => {
  return {
    type: Types.EDIT_PROPOSAL_BY_TESTER_MODAL_OPEN,
  }
};

export const editProposalByTesterModalClose = () => {
  return {type: Types.EDIT_PROPOSAL_BY_TESTER_MODAL_CLOSE}
};

export const acceptEditProposalByTesterModalOpen = () => {
  return {
    type: Types.ACCEPT_EDIT_PROPOSAL_BY_TESTER_MODAL_OPEN,
  }
};

export const acceptEditProposalByTesterModalClose = () => {
  return {type: Types.ACCEPT_EDIT_PROPOSAL_BY_TESTER_MODAL_CLOSE}
};

export const bookingCancelModalSubmit = () => {
  return {type: Types.BOOKING_CANCEL_MODAL_SUBMIT}
};

export const messageModalOpen = (id) => {
  return {type: Types.MESSAGE_MODAL_OPEN, payload: id};
};

export const messageModalClose = () => {
  return {type: Types.MESSAGE_MODAL_CLOSE};
};

export const messageNewModalOpen = () => {
  return {type: Types.MESSAGE_NEW_MODAL_OPEN};
};

export const messageNewModalClose = () => {
  return {type: Types.MESSAGE_NEW_MODAL_CLOSE};
};

export const twoFactorInitOpen = (code: string, url: string) => {
  return {
    type: Types.MODAL_TWO_FACTOR_INIT_OPEN,
    payload: {
      code,
      url
    }
  };
};

export const twoFactorInitClose = () => {
  return {type: Types.MODAL_TWO_FACTOR_INIT_CLOSE};
};

export const twoFactorCheckOpen = () => {
  return {type: Types.MODAL_TWO_FACTOR_CHECK_OPEN};
};

export const twoFactorCheckClose = () => {
  return {type: Types.MODAL_TWO_FACTOR_CHECK_CLOSE};
};