import React, { useState, useEffect } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import {
  composeValidators,
  fieldMaxLength,
  fieldsNotEmpty
} from "../../../../helpers/validations/validation";
import { formValueSelector, InjectedFormProps, reduxForm } from "redux-form";
import { connect } from "react-redux";
import routerModal from "../../../../components/routerModal";
import { getBackLink } from "../../../../helpers/router-helper";
import { bindActionCreators } from "redux";
import { TableNoItems } from "../../../../components/table/TableNoItems";
import selector from "../../../../selectors/timesheet-time-categories";
import TimeLogCategoriesForm from "./TimeLogCategoriesForm";
import {
  TIMESHEET_TIME_CATEGORIES_FORM_CREATE,
  TIMESHEET_TIME_CATEGORIES_FORM_NAME
} from "../../../../constants/timesheet-expenses";
import {
  CreateTimeCategoryFormValue,
  TimeCategoriesFilterParamsType,
  TimeCategoriesSearchFormType,
  TimeLogCategoryType
} from "../../../../types/timesheets-and-expenses";
import {
  createTimesheetTimeCategoryAction,
  getTimesheetTimeCategoriesAction,
  updateTimesheetTimeCategoryAction
} from "../../../../actions/timesheets-time-categories";
import { getCategoryNameById } from "../../../../api/requests/timesheets-time-categories";

interface TimeLogCategoryCreateProps
  extends RouteComponentProps<{ id?: string }> {
  handleSubmit: () => void;
  createNewCategory: (
    values: Pick<TimeLogCategoryType, "name">,
    callback: () => void
  ) => void;
  updateCategory: (
    values: Omit<TimeLogCategoryType, "deletedAt">,
    callback: () => void
  ) => void;
  itemsPerPage: number;
  pageNumber: number;
  searchFormFilters: TimeCategoriesSearchFormType;
  loadItems: (params: TimeCategoriesFilterParamsType) => void;
}
const searchFormSelector = formValueSelector(
  TIMESHEET_TIME_CATEGORIES_FORM_NAME
);

const TimeLogCategoryCreate: React.FC<TimeLogCategoryCreateProps &
  InjectedFormProps<CreateTimeCategoryFormValue>> = ({
  handleSubmit,
  match,
  history,
  invalid,
  pristine,
  submitting,
  change,
  createNewCategory,
  updateCategory,
  itemsPerPage,
  pageNumber,
  searchFormFilters,
  loadItems
}) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const backLink = getBackLink(match.path);
  const goBack = () => history.push(backLink);
  const submitCallback: () => void = () => {
    loadItems({ itemsPerPage, pageNumber, ...searchFormFilters });
    goBack();
  };
  const categoryId = match.params?.id;
  const isDisabledSubmit = invalid || submitting || pristine;

  useEffect(() => {
    if (categoryId) {
      getCategoryNameById(+categoryId)
        .then(({ name }) => {
          change("name", name);
          setIsLoaded(true);
        })
        .catch(error => {
          console.warn(error);
          submitCallback();
        });
    } else {
      setIsLoaded(true);
    }
  }, [categoryId]);

  return (
    <div className="">
      {isLoaded ? (
        <form
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(values => {
            if (categoryId) {
              updateCategory({ id: categoryId, ...values }, submitCallback);
            } else {
              createNewCategory(values, submitCallback);
            }
          })}
        >
          <TimeLogCategoriesForm
            goBack={goBack}
            isDisabled={isDisabledSubmit}
          />
        </form>
      ) : (
        <TableNoItems asLoading />
      )}
    </div>
  );
};

const formConnected = reduxForm<
  CreateTimeCategoryFormValue,
  TimeLogCategoryCreateProps
>({
  form: TIMESHEET_TIME_CATEGORIES_FORM_CREATE,
  validate: composeValidators(
    fieldsNotEmpty(["name"]),
    fieldMaxLength(["name"], 100)
  )
})(TimeLogCategoryCreate);

function mapStateToProps(state) {
  return {
    itemsPerPage: selector.getItemsPerPage(state),
    pageNumber: selector.getPageNumber(state),
    searchFormFilters: searchFormSelector(state, "name", "show")
  };
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      createNewCategory: createTimesheetTimeCategoryAction,
      updateCategory: updateTimesheetTimeCategoryAction,
      loadItems: getTimesheetTimeCategoriesAction
    },
    dispatch
  );
};
const connected = connect(mapStateToProps, mapDispatchToProps)(formConnected);

export default routerModal(withRouter(connected));
