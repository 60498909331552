import * as React from 'react';
import {InjectedFormProps, reduxForm, formValueSelector} from "redux-form";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {result} from "lodash";
import {WithStyles} from "@material-ui/core";
import {
  composeValidators,
  fieldIsEmail,
  fieldMaxLength,
  fieldsNotEmpty,
  fieldsNotEmptyDependsFromAdditionalField
} from "../../../helpers/validations/validation";
import {testersRequest, putItemAction} from "../../../actions/testers";
import {groupsRequest} from "../../../actions/groups";
import {testerExternalCompaniesRequest} from "../../../actions/tester-external-companies";
import {dataFetcher} from "../../../components/dataFetcher";
import parameterFetcher from '../../../components/parametersFetcher';
import routerModal, {IBackRoute} from '../../../components/routerModal';
import groupsSelector from "../../../selectors/groups";
import selector from '../../../selectors/favorite-testers';
import externalCompaniesSelector from "../../../selectors/tester-external-companies";
import {ACTIVE} from "../../../constants/filter";
import TesterForm from "./TesterForm";

interface IFormData {
  firstName: string;
  lastName: string;
  email: string;
  group: string;
  externalCompany: string;
  companyName?: string;
  bio: string;
}

const FORM_NAME = 'TesterUpdate';

class TesterUpdate extends React.Component<{ match: any } & InjectedFormProps<{}, {}> & {
  putData: (...any) => void,
  backRoute: string,
  groups: any,
  user: any,
  group: string,
  externalCompanies: any,
  externalCompany: string,
} & WithStyles<any>> {
  onFormSubmit = (values) => {
    let requestCompany;
    const { putData, backRoute } = this.props;
    const { firstName, lastName, email, group, companyName, user } = values;

    const requestUserData = {
      ...values,
      user: {
        ...user,
        firstName,
        lastName,
        email,
        group
      }
    };

    if (companyName) {
      requestCompany = {
        company: {
          name: companyName
        },
      }
    }

    putData({...requestUserData, ...requestCompany}, FORM_NAME, backRoute);
  };

  render() {
    const {
      handleSubmit,
      backRoute,
      groups,
      group,
      initialValues,
      externalCompanies,
      externalCompany,
      change
    } = this.props;

    return (
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit((values: IFormData) => this.onFormSubmit(values))}
      >
        <TesterForm
          title={"Update Tester"}
          backRoute={backRoute}
          initialValues={initialValues}
          groups={groups}
          group={group}
          externalCompanies={externalCompanies}
          externalCompany={externalCompany}
          updateField={change}
        />
      </form>
    );
  }
}

const formConnected = reduxForm({
  form: FORM_NAME,
  validate: composeValidators(
    fieldsNotEmpty(["firstName", "lastName", "email", "group", 'companyName']),
    fieldMaxLength(["email"], 50),
    fieldMaxLength(["firstName", "lastName"], 60),
    fieldMaxLength(["companyName"], 50),
    fieldIsEmail(["email"]),
    fieldMaxLength(['bio'], 2000),
    fieldsNotEmptyDependsFromAdditionalField(
      ["externalCompany"],
      ["companyName"],
      {},
      'Company or Other company name is required'
    )
  )
})(TesterUpdate);

const connected = parameterFetcher(
  connect(
    (state: any, props: any) => {
      const formSelector = formValueSelector(FORM_NAME);
      const group = formSelector(state, 'user.group');
      const externalCompany = formSelector(state, 'externalCompany');
      const data = selector.getItemById(result(props, "match.params.id", false))(state) || {};

      return { initialValues: data, group, externalCompany };
    },
    {
      putData: putItemAction
    }
  ) (formConnected),
  FORM_NAME,
  testersRequest.getItem,
  selector.getItemById
);

const componentWithData = dataFetcher(connected,
  [
    {
      key: 'groups',
      selector: (state) => groupsSelector.getItems(state),
      action: (props) => groupsRequest.getItems(1, 1000, ACTIVE, '', {parentUserGroups: props.auth.groupId}),
      alwaysReceiveFreshData: true,
    },
    {
      key: 'externalCompanies',
      selector: (state) => externalCompaniesSelector.getItems(state),
      action: () => testerExternalCompaniesRequest.getItems(1, 1000, ACTIVE),
      alwaysReceiveFreshData: true,
    },
  ]
);

export default routerModal(withRouter(componentWithData as any)) as React.ComponentClass<IBackRoute>;