import {Observable} from 'rxjs';
import {catchError, flatMap, switchMap} from 'rxjs/operators';
import {ActionsObservable} from 'redux-observable';
import * as actionType from '../constants/action-type';
import {setRequestInProcess} from '../actions/request';
import {of} from "rxjs/internal/observable/of";
import {fromPromise} from "rxjs/internal-compatibility";
import {openSnackbar} from '../actions/snackbar';
import {IAction} from "./index";
import {startEndCatch} from "./pipes/errors";
import {deleteTestPopupClose} from "../actions/modals";
import {deleteItem} from "../api/requests/test";
import {push} from "react-router-redux";

export const testDeleteSubmitEpic = (action$: ActionsObservable<IAction>): Observable<any> => {
  return action$
    .ofType(actionType.DELETE_TEST_MODAL_SUBMIT)
    .pipe(
      switchMap(action => {
        return fromPromise(deleteItem(action.payload))
          .pipe(
            flatMap((data) => {
              return [
                openSnackbar('The test has been deleted', 4000),
                deleteTestPopupClose(),
                push('/client/schedule'),
              ];
            }),
            ...startEndCatch('testDeleteSubmitEpic'),
          );
      }),
      catchError((error) => {
        return of(setRequestInProcess(false, 'testDeleteSubmitEpic'));
      })
    );
};

export const testDeleteWithoutRedirectSubmitEpic = (
  action$: ActionsObservable<IAction>
): Observable<any> => {
  return action$.ofType(actionType.DELETE_TEST_MODAL_SUBMIT_WITHOUT_REDIRECT).pipe(
    switchMap((action) => {
      return fromPromise(deleteItem(action.payload.id)).pipe(
        flatMap((data) => {
          if(typeof action.payload.cb === "function") {
            action.payload.cb();
          }

          return [
            openSnackbar("The test has been deleted", 4000),
            deleteTestPopupClose(),
          ];
        }),
        ...startEndCatch("testDeleteSubmitEpic")
      );
    }),
    catchError((error) => {
      return of(setRequestInProcess(false, "testDeleteSubmitEpic"));
    })
  );
};
