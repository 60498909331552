import * as React from "react";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Table from "@material-ui/core/Table/Table";
import {TablePagination, TableCell, TableRow, IconButton, Icon, Grid, Paper} from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody/TableBody";
import {questionLoader, questionRequest} from "../../../actions/question";
import {IAnyProps} from "../../../interfaces/any-props.interface";
import selector from "../../../selectors/question";
import {questionSearchAction, questionResetAction} from "../../../actions/question";
import EnhancedTableHead from "../../../components/table/EnhancedTableHead";
import {isChangesExist} from "../../../helpers/props-checker";
import {IQuestion} from "../../../models/question.interface";
import QuestionModal from "./QuestionModal";
import {questionModalOpen, questionNewModalOpen} from "../../../actions/modals";
import {formatDateWithTime} from "../../../helpers/date-formatter";
import PermissionBlock from "../../../components/auth/PermissionBlock";
import {ROLE_PLANNER, ROLE_VIEWER, ROLE_TESTER} from "../../../constants/roles";

interface IProps {
  questionSearch: (report: string) => void;
  questionReset: (id: string) => void;
  data: IQuestion[];
  questionNewModal: () => void;
  questionModal: (item) => void;
}

class QuestionTable extends React.Component<IProps & RouteComponentProps<any> & IAnyProps, {}> {
  componentDidMount(): void {
    this.props.questionSearch(this.props.match.params.test);
  }

  componentWillReceiveProps(nextProps) {
    if (isChangesExist(["pageNumber", "itemsPerPage",], nextProps, this.props)) {
      this.props.questionSearch(this.props.match.params.test);
    }
  }

  handleChangePage = (event, page) => {
    this.props.setPageNumber(page + 1);
  };

  handleChangeRowsPerPage = event => {
    this.props.setItemsPerPage(event.target.value);
  };

  openMessage = (item: IQuestion) => () => {
    this.props.questionModal(item);
  };

  render() {
    if (!this.props.isLoaded) {
      return (
        <div className="table-no-items">
          Loading...
        </div>
      );
    }

    if (!this.props.data.length) {
      return (
        <div className="table-no-items">
          There are no items!
        </div>
      );
    }

    return (
      <Grid container>
        <Grid item xs={12}>
          <QuestionModal/>
          <div className="styled-table table-responsive">
            <Table>
              <EnhancedTableHead columns={["Date submitted", "By", "Question", "Answer", "",]}/>
              <TableBody>
                {
                  this.props.data.map(item => {
                    return (
                      <TableRow key={item.id}>
                        <TableCell>
                          <span className="date">
                            <span
                              className="text-nowrap">{item.createdAt && formatDateWithTime(item.createdAt)}</span><br/>
                            <span
                              className="text-nowrap">{item.dateAnswered && `to ${formatDateWithTime(item.dateAnswered)}`}</span>
                          </span>
                        </TableCell>
                        <TableCell>
                          <span className="author-name">{item.authorName}</span>
                        </TableCell>
                        <TableCell>
                          {item.content}
                        </TableCell>
                        <TableCell>
                          {item.answer}
                        </TableCell>
                        <PermissionBlock roles={[ROLE_VIEWER, ROLE_PLANNER, ROLE_TESTER]}>
                          <TableCell>
                            {item.isCanBeAnswered && !item.answer && (
                                <button
                                    onClick={this.openMessage(item)}
                                    className="btn-new text-nowrap"
                                >
                                  Add answer
                                </button>
                            )}
                          </TableCell>
                        </PermissionBlock>
                      </TableRow>
                    );
                  })
                }
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={this.props.totalItems}
              rowsPerPage={this.props.itemsPerPage}
              page={this.props.pageNumber - 1}
              backIconButtonProps={{
                "aria-label": "Previous Page",
              }}
              nextIconButtonProps={{
                "aria-label": "Next Page",
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </div>
        </Grid>
      </Grid>
    );
  }
}

const routered = withRouter(QuestionTable);

const connected = connect<IAnyProps, IAnyProps, IAnyProps>((state) => {
  return {
    data: selector.getItems(state),
    isLoaded: selector.getIsLoaded(state),
    itemsPerPage: selector.getItemsPerPage(state),
    pageNumber: selector.getPageNumber(state),
    totalItems: selector.getTotalItems(state),
  };
}, {
  loadItems: questionRequest.getItems,
  setItemsPerPage: questionLoader.setItemsPerPage,
  setPageNumber: questionLoader.setPageNumber,
  questionSearch: questionSearchAction,
  questionReset: questionResetAction,
  questionNewModal: questionNewModalOpen,
  questionModal: questionModalOpen,
})(routered);

export default connected;
