import {
  EmailDomainsType,
  IPRangesType,
  SettingsFormValuesType
} from "types/client-admin-settings";
import { ProjectRepeater, ProjectsErrorType } from "types/consultant";
import {
  DailyTimeLogFormData,
  TimesheetDocumentType,
  WeeklyTimeLogFormData
} from "types/timesheets-and-expenses";
import { VALIDATION_ERROR_MESSAGES } from "constants/timesheet-expenses";
import moment from "moment";
import { MAX_TIMESHEET_FILE_SIZE } from "constants/document";

export const fieldsNotEmpty = (requiredFields, errorsInherited = {}) => {
  return values => {
    const errors = {};

    requiredFields.forEach(field => {
      if (!values[field] || !values[field].toString().trim()) {
        errors[field] = "Required";
      }
    });

    return { ...errorsInherited, ...errors };
  };
};

export const fieldsNotEmptyDependsCheckbox = (
  requiredFields,
  checkboxField,
  errorsInherited = {}
) => {
  return values => {
    const errors = {};

    requiredFields.forEach((field, key) => {
      if (!values[field] || !values[field].toString().trim()) {
        if (values[checkboxField[key]]) {
          errors[field] = "Required";
        }
      }
    });

    return { ...errorsInherited, ...errors };
  };
};

export const wysiwygFieldsIsNotEmpty = (
  requiredFields,
  errorsInherited = {}
) => {
  return values => {
    const errors = {};

    requiredFields.forEach(field => {
      if (!values[field] || values[field].toString() === "<p></p>\n") {
        errors[field] = "Required.";
      }
    });

    return { ...errorsInherited, ...errors };
  };
};

export const fieldsNotEmptyBoolean = (requiredFields, errorsInherited = {}) => {
  return values => {
    const errors = {};

    requiredFields.forEach(field => {
      if (!values[field] && values[field] !== false) {
        errors[field] = "Required";
      }
    });

    return { ...errorsInherited, ...errors };
  };
};

export const fieldMinLength = (fields, length, errorsInherited = {}) => {
  return values => {
    const errors = {};

    fields.forEach(field => {
      if (values[field] && values[field].length < length) {
        errors[field] = `Min length is ${length}`;
      }
    });

    return { ...errorsInherited, ...errors };
  };
};

export const fieldMaxLength = (fields, length, errorsInherited = {}) => {
  return values => {
    const errors = {};

    fields.forEach(field => {
      if (values[field] && values[field].length > length) {
        errors[field] = `Max length is ${length}`;
      }
    });

    return { ...errorsInherited, ...errors };
  };
};

export const fieldMaxValueForNumber = (
  fields,
  maxValue,
  errorsInherited = {}
) => {
  return values => {
    const errors = {};

    fields.forEach(field => {
      if (values[field] && Number(values[field]) > maxValue) {
        errors[field] = `Max value is ${maxValue}`;
      }
    });

    return { ...errorsInherited, ...errors };
  };
};

export const fieldMinValueForNumber = (
  fields,
  minValue,
  errorsInherited = {}
) => {
  return values => {
    const errors = {};

    fields.forEach(field => {
      if (values[field] && Number(values[field]) < minValue) {
        errors[field] = `Min value is ${minValue}`;
      }
    });

    return { ...errorsInherited, ...errors };
  };
};

const validateEmail = email => {
  const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

  return re.test(email);
};

export const fieldIsEmail = (fields, errorsInherited = {}) => {
  return values => {
    const errors = {};

    fields.forEach(field => {
      if (!validateEmail(values[field])) {
        errors[field] = "Should be an email";
      }
    });

    return { ...errorsInherited, ...errors };
  };
};

export const fieldIsNumber = (fields, errorsInherited = {}) => {
  return values => {
    const errors = {};

    fields.forEach(field => {
      const valueInNumber = Number(values[field]);
      if (isNaN(valueInNumber) || typeof valueInNumber !== "number") {
        errors[field] = "Should be a number";
      }
    });

    return { ...errorsInherited, ...errors };
  };
};

export const fieldIsNullableNumber = (fields, errorsInherited = {}) => {
  return values => {
    const errors = {};

    fields.forEach(field => {
      const valueInNumber = Number(values[field]);
      if (
        values[field] &&
        (isNaN(valueInNumber) || typeof valueInNumber !== "number")
      ) {
        errors[field] = "Should be a number";
      }
    });

    return { ...errorsInherited, ...errors };
  };
};

export const composeValidators = (...arg) => {
  return (values, props) => {
    const result = Array.from(arg).reduce((accumulator, validator) => {
      return { ...accumulator, ...validator(values, props) };
    }, {});

    return result;
  };
};

const validateLinkedInLink = link => {
  return link.includes("www.linkedin.com");
};

export const fieldsIsLinkedInLink = (fields, errorsInherited = {}) => {
  return values => {
    const errors = {};

    fields.forEach(field => {
      if (values[field] && !validateLinkedInLink(values[field])) {
        errors[field] = "Should be a valid LinkedIn link";
      }
    });

    return { ...errorsInherited, ...errors };
  };
};

const validateNewPassword = field => {
  const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[-!$%^&*()_+@|~=`{}\[\]:";'<>?,.\/]).{17,17}$/;

  return re.test(field);
};

export const fieldIsValidPassword = (fields, errorsInherited = {}) => {
  return values => {
    const errors = {};

    fields.forEach(field => {
      if (values[field] && !validateNewPassword(values[field])) {
        errors[field] =
          "Seventeen characters, at least one upper case letter, one lower case letter, one special symbol and one number.";
      }
    });

    return { ...errorsInherited, ...errors };
  };
};

export const fieldArePasswordsMatched = (fields, errorsInherited = {}) => {
  return values => {
    const errors = {};

    for (let i = 0; i < fields.length; i++) {
      if (values[fields[i]] !== values[fields[i + 1]]) {
        errors[fields[i + 1]] = "Passwords do not match.";
      }
    }

    return { ...errorsInherited, ...errors };
  };
};

export const fieldsNotEmptyDependsFromAdditionalField = (
  fields,
  additionalFields,
  errorsInherited = {},
  message: string = ""
) => {
  return values => {
    const errors = {};

    fields.forEach((field, key) => {
      if (!values[field] || !values[field].toString().trim()) {
        if (
          !additionalFields.some(additionalfield => values[additionalfield])
        ) {
          if (message) {
            errors[field] = message;
          } else {
            errors[field] = `${field} or ${additionalFields.join(
              " or "
            )} is required`;
          }
        }
      }
    });

    return { ...errorsInherited, ...errors };
  };
};

export const fieldsShouldBeEmptyOrFilledSimultaneously = (
  fields,
  errorsInherited = {}
) => {
  return values => {
    const error = {};

    if (fields.every(field => !values[field])) {
      return error;
    }

    if (!fields.every(field => values[field])) {
      fields.forEach(field => {
        if (!values[field]) {
          error[field] = "You should specify this field too";
        }
      });
    }
    return { ...error };
  };
};

export const validateDynamicFieldNameMinLength = (
  value: string = ""
): string | boolean =>
  value.length < 2 && "Fields cannot be less than 2 characters";

export const fieldIsRequired = (value: string) => {
  if (!value) {
    return "Required";
  }

  return "";
};

export const emailDomainsValidation = (
  domain: string,
  values: SettingsFormValuesType
) => {
  if (!domain) {
    return "Required";
  }
  const domainExpression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
  const regex = new RegExp(domainExpression);

  if (!domain.match(regex)) {
    return "Invalid domain";
  }

  const { emailDomains = [] } = values;
  const isDuplicatedDomain = emailDomains.filter(
    (row: EmailDomainsType) => row.domain === domain
  );

  if (isDuplicatedDomain.length > 1) {
    return "Domain name should be unique";
  }
  return "";
};

const getIsIPRange = (address: string = "") => address.indexOf("-") > 0;

const getIpAddressesFromRange = (ipRange: string) => {
  const [startIp = "", endIp = ""] = ipRange.split("-");

  return {
    startRangeIp: startIp.toString().trim(),
    endRangeIp: endIp.toString().trim()
  };
};

const isSameIpsInRange = (ipAddress: string) => {
  if (getIsIPRange(ipAddress)) {
    const { startRangeIp, endRangeIp } = getIpAddressesFromRange(ipAddress);
    return startRangeIp === endRangeIp;
  }

  return false;
};

const getIsDuplicatedIpRanges = (
  ipAddress: string,
  ipRangeValues: IPRangesType[]
) => {
  const isIPRange = getIsIPRange(ipAddress);

  if (isIPRange) {
    const { startRangeIp, endRangeIp } = getIpAddressesFromRange(ipAddress);

    const hasDuplicatedIPRanges = ipRangeValues.filter((row: IPRangesType) => {
      if (!getIsIPRange(row.address)) {
        return false;
      }

      const {
        startRangeIp: valuesItemIpRangeStart,
        endRangeIp: valuesItemIpRangeEnd
      } = getIpAddressesFromRange(row.address);

      return (
        startRangeIp === valuesItemIpRangeStart &&
        endRangeIp === valuesItemIpRangeEnd
      );
    });

    if (hasDuplicatedIPRanges.length > 1) {
      return "IP Range should be unique";
    }

    return "";
  }

  const hasDuplicatedIPAddress = ipRangeValues.filter(
    (row: IPRangesType) => row.address === ipAddress
  );

  if (hasDuplicatedIPAddress.length > 1) {
    return "IP Address should be unique";
  }

  return "";
};

export const ipRangeValidation = (
  ipAddress: string,
  values: SettingsFormValuesType
) => {
  if (!ipAddress) {
    return "Required";
  }

  const ipAddressOrRangeExpression = /^[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}(\s?\-\s?[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})?$/g;
  const ipAddressOrRange = new RegExp(ipAddressOrRangeExpression);

  if (!ipAddress.match(ipAddressOrRange)) {
    return ipAddress.indexOf("-") > 0
      ? "Invalid IP range"
      : "Invalid IP address";
  }

  if (isSameIpsInRange(ipAddress)) {
    return "You entered the same IP addresses in the range";
  }

  const { ipRanges } = values;
  const isDuplicatedIpRange = getIsDuplicatedIpRanges(ipAddress, ipRanges);

  return isDuplicatedIpRange;
};

//create consultant form validation
const projectNameValidation = (
  project: string | number,
  projectsNames: number[],
  index: number
) => {
  if (!project) {
    return "Required";
  }
  const nameDuplicated = projectsNames.indexOf(+project) !== index;
  if (nameDuplicated) {
    return "Duplicated project";
  }
  return "";
};
const projectRateValidation = (rate: string | number) => {
  if (!rate) {
    return "Required";
  }
  return "";
};

export const projectsRepeaterValidation = () => {
  return values => {
    const { projects = [] } = values;
    const projectsArrayErrors: ProjectsErrorType[] = [];
    if (!projects.length) {
      return { projects: projectsArrayErrors };
    }
    const projectsNames = projects.map(({ project }) => project);
    projects.forEach((project: ProjectRepeater, index: number) => {
      projectsArrayErrors[index] = {
        project: projectNameValidation(project.project, projectsNames, index),
        projectRate: projectRateValidation(project.projectRate)
      };
    });

    return { projects: projectsArrayErrors };
  };
};

const validateRequiredExpensesDocuments = (
  documents: TimesheetDocumentType[] = []
) => {
  const emptyDocumentsCount = () =>
    documents.filter((item: TimesheetDocumentType) => !item.document.name)
      .length;

  if (!documents.length) {
    return VALIDATION_ERROR_MESSAGES.REQUIRED_RECEIPT;
  }
  if (!!emptyDocumentsCount()) {
    return VALIDATION_ERROR_MESSAGES.UPLOAD_FILE;
  }
  return "";
};

export const timesheetDocumentsRepeaterValidation = () => {
  return ({ documents = [], expensesAmount }: DailyTimeLogFormData) => ({
    documents: documents.reduce((acc, curr, index) => {
      const copy = {
        ...acc,
        [index]: {}
      };

      if (!curr.document) {
        copy[index].document = "The file is required!";
      }

      if (Number(curr.document?.size) > MAX_TIMESHEET_FILE_SIZE) {
        copy[index].document = VALIDATION_ERROR_MESSAGES;
      }

      if (!curr?.type) {
        copy[index].type = "The type is required!";
      }

      return Object.keys(copy[index]).length > 0 ? copy : acc;
    }, {}),
    documents_error:
      expensesAmount && !documents.length
        ? VALIDATION_ERROR_MESSAGES.REQUIRED_RECEIPT
        : null
  });
};

export const validateTimeExpensesForm = () => {
  return values => {
    const {
      days,
      timeCategory,
      expensesAmount,
      expensesType,
      comment
    } = values;
    const COMMENT_MAX_LENGTH = 200;

    let daysError = "";
    let timeCategoryError = "";
    let expensesAmountError = "";
    let commentError = "";

    if (!days) {
      daysError =
        !expensesAmount || !!timeCategory
          ? VALIDATION_ERROR_MESSAGES.REQUIRED_SECTION_COMPETED
          : "";
      expensesAmountError = expensesAmount
        ? ""
        : VALIDATION_ERROR_MESSAGES.REQUIRED_SECTION_COMPETED;
    }

    if (!expensesAmount) {
      daysError = !days
        ? VALIDATION_ERROR_MESSAGES.REQUIRED_SECTION_COMPETED
        : "";
      expensesAmountError = days
        ? ""
        : VALIDATION_ERROR_MESSAGES.REQUIRED_SECTION_COMPETED;
    }
    if (!expensesAmount && expensesType) {
      expensesAmountError = VALIDATION_ERROR_MESSAGES.REQUIRED;
    }
    if (!expensesAmount && !days) {
      daysError = VALIDATION_ERROR_MESSAGES.REQUIRED_SECTION_COMPETED;
      expensesAmountError = VALIDATION_ERROR_MESSAGES.REQUIRED_SECTION_COMPETED;
    }

    if (days) {
      timeCategoryError = !timeCategory
        ? VALIDATION_ERROR_MESSAGES.REQUIRED
        : "";
    }
    if (comment && comment.length > COMMENT_MAX_LENGTH) {
      commentError = `Max length is ${COMMENT_MAX_LENGTH}`;
    }



    return {
      days_text: daysError,
      timeCategory: timeCategoryError,
      expensesAmount: expensesAmountError,
      comment: commentError
    };
  };
};

export const validateWeekTimeExpensesForm = (values: WeeklyTimeLogFormData) => {
  const { days, timeCategory, comment } = values;
  const COMMENT_MAX_LENGTH = 200;

  return {
    days_text: !days ? VALIDATION_ERROR_MESSAGES.REQUIRED : "",
    timeCategory: !timeCategory ? VALIDATION_ERROR_MESSAGES.REQUIRED : "",
    comment:
      comment && comment.length > COMMENT_MAX_LENGTH
        ? `Max length is ${COMMENT_MAX_LENGTH}`
        : ""
  };
};

export const validateDatesFromTo = (
  dateFromField: "dateFrom",
  dateToField: "dateTo"
) => {
  return values => {
    const dateFrom = moment(values[dateFromField]);
    const dateTo = moment(values[dateToField]);
    if (dateTo.isBefore(dateFrom)) {
      return {
        [dateToField]: `The date cannot be earlier than ${dateFrom.format(
          "DD/MM/YYYY"
        )}`
      };
    }
    return { [dateToField]: "" };
  };
};
