import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Switch, Route } from "react-router-dom";
import { createStore, applyMiddleware } from "redux";
import reducers from "./reducers";
import registerServiceWorker from "./registerServiceWorker";
import thunk from "redux-thunk";
import CssBaseline from "@material-ui/core/CssBaseline";
import LoaderIndicator from "./containers/common/LoaderIndicator";
import Cookies from "./containers/guest/Cookies";
import PrivateRoute from "./components/auth/PrivateRoute";
import GuestRoute from "./components/auth/GuestRoute";
import ClientRegistration from "./containers/guest/ClientRegistration";
import TesterRegistration from "./containers/guest/TesterRegistration";
import TermsAndConditions from "./containers/common/TermsAndConditions";
import PrivacyPolicy from "./containers/common/PrivacyPolicy";
import Modals from "./containers/common/Modals";
import InformationBar from "./containers/common/InformationBar";
import { NoRouteMatched } from "./components/NoRouteMatched";
import Admin from "./containers/admin/Admin";
import { composeWithDevTools } from "redux-devtools-extension";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./styles/index.css";
import "./styles/homepage.css";
import { createBrowserHistory as createHistory } from "history";
import { ConnectedRouter, routerMiddleware } from "react-router-redux";
import { asyncPermissionsAuthState } from "./helpers/initial-state";
import {
  ROLE_ADMIN,
  ROLE_OWNER,
  ROLE_TESTER,
  ROLE_PLANNER,
  ROLE_VIEWER,
  ROLE_SUPER_ADMIN,
  ROLE_PROJECT_CONSULTANT,
  ROLE_ACCOUNT_MANAGER
} from "./constants/roles";
import { createEpicMiddleware } from "redux-observable";
import { rootEpic } from "./epics";
import { createLogger } from "redux-logger";
import Tester from "./containers/tester/Tester";
import Client from "./containers/client/Client";
import JssProvider from "react-jss/lib/JssProvider";
import { create } from "jss";
import { createGenerateClassName, jssPreset } from "@material-ui/core/styles";
import { MuiThemeProvider } from "@material-ui/core";
import CompletedTests from "./CompletedTests";
import { theme } from "./styles/material/theme";
import ResetPasswordConfirmationPage from "./containers/guest/ResetPasswordConfirmationPage";
import AboutAvordStaticPage from "./containers/common/AboutAvordStaticPage";
import Home from "./containers/guest/Home";
import GAListener from "./components/GAListener";
import InviteToCompanyConfirmationPage from "./containers/guest/InviteToCompanyConfirmationPage";
import Consultant from "./containers/consultant/Consultant";

const epicMiddleware = createEpicMiddleware();
const history = createHistory();
const middleware = routerMiddleware(history);
const middlewares = [
  epicMiddleware,
  thunk,
  middleware,
  asyncPermissionsAuthState
];

if ("1" === process.env.REACT_APP_ENABLE_LOGGER) {
  const logger = createLogger({});

  middlewares.push(logger);
}

const store = createStore(
  reducers,
  composeWithDevTools(
    applyMiddleware(...middlewares, asyncPermissionsAuthState)
  )
);

epicMiddleware.run(rootEpic);

const generateClassName = createGenerateClassName();
const jss = create(jssPreset());

ReactDOM.render(
  <JssProvider jss={jss} generateClassName={generateClassName}>
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <ConnectedRouter history={history}>
          <GAListener>
            <div className="app-content">
              <CssBaseline />
              <LoaderIndicator />
              <Switch>
                <Route exact path="/" component={Home} />

                <GuestRoute
                  path="/client-registration"
                  component={ClientRegistration}
                />
                <GuestRoute
                  path="/tester-registration"
                  component={TesterRegistration}
                />

                <Route
                  path="/terms-and-conditions"
                  component={TermsAndConditions}
                />
                <Route path="/privacy-policy" component={PrivacyPolicy} />
                <Route path="/about" component={AboutAvordStaticPage} />
                <Route
                  path="/reset-password-confirmation/:resetPasswordToken"
                  component={ResetPasswordConfirmationPage}
                />
                <Route
                  path="/invite-to-company-confirmation/:inviteToCompanyToken"
                  component={InviteToCompanyConfirmationPage}
                />

                <PrivateRoute
                  role={ROLE_SUPER_ADMIN}
                  path="/completed-tests"
                  component={CompletedTests}
                />

                <PrivateRoute
                  roles={[ROLE_ADMIN, ROLE_ACCOUNT_MANAGER]}
                  path="/admin"
                  component={Admin}
                />
                <PrivateRoute
                  role={ROLE_TESTER}
                  path="/tester"
                  component={Tester}
                />
                <PrivateRoute
                  roles={[ROLE_OWNER, ROLE_PLANNER, ROLE_VIEWER, ROLE_ADMIN, ROLE_ACCOUNT_MANAGER]}
                  path="/client"
                  component={Client}
                />
                <PrivateRoute
                  roles={[ROLE_PROJECT_CONSULTANT]}
                  path="/consultant"
                  component={Consultant}
                />
                <Route path="*" component={NoRouteMatched} />
              </Switch>
              <Cookies />
              <Modals />
              <InformationBar />
            </div>
          </GAListener>
        </ConnectedRouter>
      </MuiThemeProvider>
    </Provider>
  </JssProvider>,
  document.getElementById("root")
);

if (
  "registerServiceWorker" in navigator &&
  process.env.NODE_ENV === "production"
) {
  window.addEventListener("load", () => {
    registerServiceWorker();
  });
}
