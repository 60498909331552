import * as React from "react";
import { Modal } from "@material-ui/core";

type Props = {
  onDownload: () => void;
  open: boolean;
  onClose: () => void;
};

class DocumentDeleteModal extends React.Component<Props> {
  onDownloadHandler = () => {
    this.props.onDownload();
  }

  render() {
    const {open, onClose} = this.props;

    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={onClose}
        className="d-flex overflow-auto"
      >
        <div className="styled-modal sm">
          <p>Download report confirmation</p>
          <h4>Are you sure?</h4>
          <hr />
          <p>Privacy warning this document might be sensitive. Are you sure you
            want to download?</p>
          <div className="text-right">
            <button className="btn-cancel" onClick={onClose}>
              No
            </button>
            <button className="btn-save" onClick={this.onDownloadHandler}>
              Yes
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

export default DocumentDeleteModal;
