import * as React from "react";
import Grid from "@material-ui/core/Grid/Grid";
import {ITesterProfileGet} from "../../../../models/tester-profile.interface";
import {Link, Redirect, withRouter, RouteComponentProps} from "react-router-dom";
import {getTesterLocationString} from "../../../../helpers/location-helper";
import {BASE_URL} from "../../../../constants/urls";
import StarRatingComponent from "react-star-rating-component";
import {connect} from "react-redux";
import {downloadSecureDocument} from "../../../../api/requests/document-download";
import TestersBookingConfirmationModal from "./TestersBookingConfirmationModal";
import {INSURANCE_VALUES_COUNT} from "../../../../constants/insurance";
import {STATUS_VALIDATION_PASSED} from "../../../../constants/dbs-certificate-statuses";
import {putItem} from "../../../../api/requests/favorite-testers";
import {ICountry} from "../../../../models/dialing-code.interface";
import {IGlobalState} from "../../../../reducers";
import countriesSelector from "../../../../selectors/countries";
import {IAnyProps} from "../../../../interfaces/any-props.interface";
import {EMPTY_COUNTRY_ID, UK_CODE} from "../../../../constants/countries";
import PermissionBlock from "../../../../components/auth/PermissionBlock";
import {ROLE_OWNER, ROLE_PLANNER} from "../../../../constants/roles";

interface IOwnProps {
  countries: ICountry[];

  [key: string]: any;
}

type IProps = {
    favoriteLink?: string | undefined;
    item: ITesterProfileGet;
    showSeeReviewsLink?: boolean;
    canRenderButton?: boolean;
  }
  & IOwnProps
  & RouteComponentProps<any>;

interface IState {
  redirect: boolean;
  isModalOpen: boolean;
}

class TestersView extends React.Component<IProps, IState> {
  static defaultProps: Partial<IProps> = {
    showSeeReviewsLink: true,
    canRenderButton: true
  };

  state = {
    redirect: false,
    isModalOpen: false
  };

  needRenderUKBlock = false;

  downloadFile = event => {
    const evidenceDocumentId = event.currentTarget.dataset.evidenceid;

    if (evidenceDocumentId) {
      this.props.downloadSecureDocument(evidenceDocumentId);
    }
  };

  handleBook = () => {
    if (this.props.item.isFavorite) {
      this.setState({redirect: true});
    } else {
      putItem(this.props.item.id)
        .then(
          () => {
            this.setState({redirect: true});
          }
        );
    }
  };

  toggleModal = () => {
    this.setState({isModalOpen: !this.state.isModalOpen});
  };

  renderInsurancesValue = value => {
    return value === INSURANCE_VALUES_COUNT
      ? `more then £${INSURANCE_VALUES_COUNT - 1}m`
      : `£${value}m`;
  };

  renderCertificates = certificateNames => {
    return certificateNames.map((item, index) => {
      return (
        <span key={index} className="tester-certificates">
            <span title={item.fullName} className="tester-certificate">{item.shortName}</span>
          {certificateNames.length - 1 === index ? "" : " | "}
        </span>
      );
    });
  };

  getIdFromIri(iri?: string) {
    if (iri) {
      const arrIri = iri.split("/");

      return Number(arrIri[arrIri.length - 1]);
    }

    return null;
  }

  getUk() {
    const {countries} = this.props;
    return countries.find((country: ICountry) => country.code === UK_CODE);
  }

  setNeedRenderUKBlock() {
    const {item} = this.props;
    let countryUkId: number = EMPTY_COUNTRY_ID;
    const countryUk = this.getUk();
    if (!!countryUk) {
      countryUkId = countryUk.id;
    }

    if (item) {
      if (this.getIdFromIri(item.country) === countryUkId) {
        this.needRenderUKBlock = true;
      } else if (item.rightsToWorkCountries) {
        item.rightsToWorkCountries.map((country) => {
          if (this.getIdFromIri(country) === countryUkId) {
            this.needRenderUKBlock = true;
          }
        });
      }
    }
  }

  render() {
    const {item, favoriteLink, showSeeReviewsLink, canRenderButton} = this.props;
    this.setNeedRenderUKBlock();

    if (this.state.redirect) {
      return <Redirect push to={`/client/schedule/test/pre-fill/${item.id}`}/>;
    }

    return (
      <div className="styled-block">
        <div className="tester-view pt-3">
          <Grid container spacing={16} justify={"space-between"}>
            <Grid item xs={2} className="profile-picture">
              <div className="picture">
                {!item.profileImagePath ? (
                  <img
                    src="/images/user-large.svg"
                    alt={item.testerFirstName + " " + item.testerLastName}
                    className="rounded-circle img-fluid"
                  />
                ) : (
                  <img
                    src={BASE_URL + item.profileImagePath}
                    alt={item.testerFirstName + " " + item.testerLastName}
                    className="rounded-profile-search img-fluid"
                  />
                )}
              </div>
            </Grid>
            <Grid item xs={7}>
              <h2>
                {item.testerFirstName} {item.testerLastName}
                {item.linkedinLink && (
                  <span style={{marginLeft: 10}}>
                  <a href={item.linkedinLink} target="_blank">
                    <img
                      src="/images/linkedin.png"
                      alt="Linkedin"
                      width={30}
                      height={30}
                    />
                  </a>
                </span>
                )}
              </h2>
              <Grid container spacing={16}>
                <Grid item xs={4}>
                  <h5>Average rating</h5>
                  <div className="d-flex">
                    <StarRatingComponent
                      className="rating-stars"
                      name="rang"
                      starCount={5}
                      editing={false}
                      value={!!item.rank ? item.rank : 0}
                    />
                    <span className="star-rating-rank">
                    {!!item.rank ? Number((item.rank).toFixed(1)) : 0}
                  </span>
                  </div>
                  {!!showSeeReviewsLink && (
                    <Link
                      className="underlined-link"
                      to={`/client/schedule/booking/${this.props.match.params.project}/${this.props.match.params.test}/${item.id}/reviews`}>
                      See all reviews
                    </Link>
                  )}
                </Grid>
                <Grid item xs={4}>
                  <h5>Location</h5>
                  <div>{getTesterLocationString(item)}</div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <div className="d-flex text-right align-items-center float-right">
              <span className="day-rate">
                <strong>
                  {item.currencyInfo &&
                  `${item.currencyInfo && item.currencyInfo.symbol}${
                    item.rate
                    }`}
                </strong>
                <span>/day</span>
              </span>
                {/* TODO: I left this link here in case you need to go back to the page "TesterBookingConfiramtion", not to show the "TesterBookingConfiramtionModal"...
                <Link to={`/client/schedule/booking/${this.props.match.params.project}/${this.props.match.params.test}/${item.id}/confirmation`}>
                */}
                {item.isFavorite &&
                  (<span title="Tester from your Virtual Team" className="btn-manage"/>)
                }
                <PermissionBlock roles={[ROLE_OWNER, ROLE_PLANNER]}>
                  {canRenderButton &&
                    (!favoriteLink
                      ? (
                        <button className="btn-book" onClick={this.toggleModal}>
                          Book
                        </button>
                      )
                      : (item.isFavorite &&
                        <button className="btn-book" onClick={this.handleBook}>
                          Book
                        </button>
                      )
                    )
                  }
                </PermissionBlock>
                {/* </Link> */}
              </div>
            </Grid>
            <Grid item xs={12}>
              <h5>Specialisms</h5>
              {(item.specialismsNames || []).map((item: string) => (
                <span key={item} className="specialism-item">
                {item}
              </span>
              ))}
            </Grid>
            <Grid item xs={3}>
              {item.insurancesData.publicLiability && item.insurancesData.indemnityInsurance && this.needRenderUKBlock &&
              <React.Fragment>
                <h5>
                  {"Insurance Value "}
                  <span title="Public liability covers physical damage caused by your work, e.g. injuries or property damage.
Professional indemnity covers clients' financial losses.">What&nbsp;is&nbsp;it?</span>
                </h5>
                <p className="insurance">
                  <span>PL</span>{" "}
                  {this.renderInsurancesValue(item.insurancesData.publicLiability)}
                  {" "}/{" "}
                  <span>PI</span>{" "}
                  {this.renderInsurancesValue(item.insurancesData.indemnityInsurance)}
                </p>
              </React.Fragment>
              }
            </Grid>
            <Grid item xs={3}>
              <h5>Qualified Experience</h5>
              <p>
                {!!item.workExperience ? (
                  <span> {item.workExperience} year{+item.workExperience !== 1 && "s"}</span>) : (
                  <span>No experience</span>
                )}
              </p>
            </Grid>
            <Grid item xs={6}>
              <h5>Certifications</h5>
              <p>{this.renderCertificates(item.certificatesNames)}</p>
            </Grid>
            <Grid item md={8}>
              <h5>Bio</h5>
              <div>{item.bio}</div>
            </Grid>
            <Grid item md={4}>
              <div className="dbs float-right">
                <ul className="dbs-list">
                  {item.checkRegisteredCompany && (
                    <li>CHECK registered company</li>
                  )}
                  {item.companyCrestCertificate && (
                    <li>CREST registered company</li>
                  )}
                  {item.isGovernmentCleared && <li>Government Cleared</li>}
                </ul>
                {item.dBSCheckMinStatus === STATUS_VALIDATION_PASSED && this.needRenderUKBlock && (
                  <img src="/images/DBS.svg" alt="DBS"/>
                )}
              </div>
            </Grid>
          </Grid>

          <TestersBookingConfirmationModal
            open={this.state.isModalOpen}
            onClose={this.toggleModal}
            tester={item}
          />
        </div>
      </div>
    );
  }
}

export default connect<IAnyProps, IAnyProps, IAnyProps>(
  (state: IGlobalState) => {
    return {
      countries: countriesSelector.getItems(state)
    };
  },
  {
    downloadSecureDocument,
  }
)(withRouter(TestersView));
