import {
  IConverterFromSwagger,
  IConverterToSwagger
} from "./converter.interface";

export const convertorFromSwagger: IConverterFromSwagger<any, any> = model => {
  return { ...model };
};

export const convertorToSwagger: IConverterToSwagger<any, any> = model => {
  return {
    ...model,
    locations: (model.locations || []).map(data => `/api/locations/${data}`),
    manager: model?.manager ? `/api/users/${model.manager}` : null
  };
};
