import React from "react";
import { ReceiptRow, AddReceiptButton } from "./receipt";
import { WrappedFieldArrayProps } from "redux-form/lib/FieldArray";
import { TimeExpensesCategory } from "types/timesheets-and-expenses";

type TimesheetDocumentsRepeaterProps = {
  expensesTypesList: TimeExpensesCategory[];
} & WrappedFieldArrayProps;

export function TimesheetDocumentsRepeater({
  expensesTypesList = [],
  fields
}: TimesheetDocumentsRepeaterProps) {
  const onAddNewReceiptHandler = () => {
    fields.push({
      document: null,
      type: null
    });
  };

  const onRemoveReceiptHandler = (index: number) => {
    fields.remove(index);
  };

  const onChangeHandler = (index: number, value) => {
    // @ts-ignore
    fields.splice(index, 1, value);
  };

  return (
    <div className="documents-repeater">
      {fields.map((member, index: number) => (
        <ReceiptRow
          key={index}
          member={member}
          expensesTypesList={expensesTypesList}
          onRemoveClick={() => onRemoveReceiptHandler(index)}
          onChange={item => onChangeHandler(index, item)}
          field={fields.get(index)}
        />
      ))}

      {fields.length < 5 && (
        <AddReceiptButton
          disabled={fields.length >= 5}
          onClick={onAddNewReceiptHandler}
        />
      )}
    </div>
  );
}
