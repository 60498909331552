import {setRequestInProcess} from "./request";
import {getRequestName} from "../helpers/action-request-helper";
import * as Types from "../constants/action-type";
import {createLoaderAction} from "../helpers/action-helper";
import {handleHttpErrors} from "../helpers/errors/http-error-handler";
import {getEmailDomainsRequest} from "../api/requests/email-domains";

export const emailDomainsLoader = createLoaderAction(Types.EMAIL_DOMAINS);

export const getEmailDomainsRequestsAction = () => async dispatch => {
  dispatch(setRequestInProcess(true, getRequestName(Types.EMAIL_DOMAINS, 'getEmailDomainsRequestsAction')));

  dispatch(emailDomainsLoader.setIsNotLoaded());

  let data = [], totalItems;

  try {
    ({items: data, totalItems} = await getEmailDomainsRequest());
    dispatch(emailDomainsLoader.setPaginatioData(data, totalItems, totalItems, 1));
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }
  finally {
    dispatch(setRequestInProcess(false, getRequestName(Types.EMAIL_DOMAINS, 'getEmailDomainsRequestsAction')));
  }
};
