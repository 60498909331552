import {
  converterFromSwagger,
  converterToSwagger,
} from "../convertors/insurances";
import * as FilterActions from "../../constants/filter";
import { fetchRequestBody, dummyItemsPagination } from "../../helpers/request-helper";
import { getApi } from "../swagger/api-factory";
import { IInsurance } from "../../models/insurance.interface";
import { IAnyProps } from "../../interfaces/any-props.interface";

export const getItems = (page: number, itemsPerPage: number, show: string, text: string = '') => {
  const isShow = show || FilterActions.DEFAULT_FILTER;
  const parameters: IAnyProps = { page };

  parameters.items = itemsPerPage;
  parameters.show = isShow;

  if (text) {
    parameters.question = text;
  }

  return getApi()
    .then(api => api.getInsuranceCollection(parameters))
    .then(fetchRequestBody)
    .then(dummyItemsPagination);
};

export const getItem = (id: string|number) => {
  return getApi()
    .then(api => api.getInsuranceItem({ id }))
    .then(fetchRequestBody)
    .then(converterFromSwagger);
};

export const postItem = (model: IInsurance) => {
  return getApi()
    .then(api => api.postInsuranceCollection({
      insurance: converterToSwagger(model),
    }))
    .then(fetchRequestBody)
    .then(converterFromSwagger);
};

export const putItem = (model: IInsurance) => {
  return getApi()
    .then(api => api.putInsuranceItem({
      id: model.id,
      insurance: converterToSwagger(model),
    }))
    .then(fetchRequestBody)
    .then(converterFromSwagger);
};

export const deleteItem = (id: string|number) => {
  return getApi()
    .then(api => api.deleteInsuranceItem({ id, }))
    .then(fetchRequestBody);
};
