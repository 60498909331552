import {Observable} from 'rxjs';
import {flatMap, switchMap} from 'rxjs/operators';
import {ActionsObservable} from 'redux-observable';
import * as actionType from '../constants/action-type';
import {fromPromise} from "rxjs/internal-compatibility";
import {IActionPayload} from "./index";
import {startEndCatch} from "./pipes/errors";
import {createAction} from "redux-actions";
import {formValueSelector} from "redux-form";
import {QUESTION_SEARCH_FORM_NAME} from "../containers/client-tester/questions/QuestionSearch";
import {questionSearchRequest} from "../api/requests/question";
import {createLoaderAction} from "../helpers/action-helper";
import selector from "../selectors/question";

export const questionsSearchApplyEpic = (action$: ActionsObservable<IActionPayload<any>>, state$): Observable<any> => {
  return action$
    .ofType(actionType.QUESTION_SEARCH_APPLY)
    .pipe(
      switchMap((action: IActionPayload<any>) => {
        const selectorForm = formValueSelector(QUESTION_SEARCH_FORM_NAME);

        const pageNumber = selector.getPageNumber(state$.value);
        const itemsPerPage = selector.getItemsPerPage(state$.value);

        return fromPromise(questionSearchRequest(
          action.payload,
          pageNumber,
          itemsPerPage,
          selectorForm(state$.value, 'dateFrom'),
          selectorForm(state$.value, 'dateTo')
        ))
          .pipe(
            flatMap(({items, totalItems}) => {
              return [
                createLoaderAction(actionType.QUESTION).setPaginatioData(items, itemsPerPage, totalItems, pageNumber),
              ];
            }),
            ...startEndCatch('questionsSearchApplyEpic'),
          );
      }),
    );
};
