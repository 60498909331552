import * as React from "react";
import {RouteComponentProps} from "react-router-dom";
import {withRouter} from "react-router-dom";
import {FieldGeneric} from "../../../components/forms/FieldGeneric";
import {reduxForm} from "redux-form";
import {connect} from "react-redux";
import {createAction} from "redux-actions";
import {QUESTION_SEARCH_APPLY} from "../../../constants/action-type";
import ReduxDatePicker from "../../../components/forms/ReduxDatePicker";

export const QUESTION_SEARCH_FORM_NAME = "QuestionSearch";

class QuestionSearch extends React.Component<RouteComponentProps<any> & {
  questionSearchApply: (...any) => void,
}, {}> {
  search = () => {
    this.props.questionSearchApply(this.props.match.params.test);
  };

  render() {
    return (
      <div className="py-4">
        <div className="row">
          <div className="col-md-4">
            <FieldGeneric
              name="dateFrom"
              label="From"
              InputLabelProps={{shrink: true}}
              component={ReduxDatePicker}
            />
          </div>
          <div className="col-md-4">
            <FieldGeneric
              name="dateTo"
              label="To"
              InputLabelProps={{shrink: true}}
              addEndOfDay={true}
              component={ReduxDatePicker}
            />
          </div>
          <div className="col-md-4 text-right">
            <button className="btn-search mt-4" onClick={this.search}>Search</button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {};
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    questionSearchApply: (id: string) => dispatch(createAction(QUESTION_SEARCH_APPLY, () => id)()),
  };
};

const routered = withRouter(QuestionSearch);

const connectForm = reduxForm({
  form: QUESTION_SEARCH_FORM_NAME,
})(routered as any);

const connected = connect(undefined, mapDispatchToProps)(connectForm);

export default connected;
