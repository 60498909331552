import {Dispatch} from "redux";
import {setRequestInProcess} from "./request";
import {
  getTestersRightsToWorkCountries,
  putTestersRightsToWorkCountries
} from "../api/requests/tester-rights-to-work-countries";
import {createAction} from "redux-actions";
import {TESTER_RIGHTS_TO_WORK_COUNTRIES} from "../constants/action-type";

interface IRightToWorkCountriesPut {
  rightsToWorkCountries: string[];
}

export const getTesterRightsToWorkCountries = () => async (dispatch: Dispatch) => {
  dispatch(setRequestInProcess(true, 'getTesterRightsToWorkCountries'));
  const countries = await getTestersRightsToWorkCountries();

  dispatch(setRequestInProcess(false, 'getTesterRightsToWorkCountries'));

  dispatch(createAction(TESTER_RIGHTS_TO_WORK_COUNTRIES, () => countries)());
};

export const putTesterRightsToWorkCountries = (countriesIds: number[]) => async (dispatch: Dispatch) => {
  dispatch(setRequestInProcess(true, 'putTesterRightsToWorkCountries'));

  const model: IRightToWorkCountriesPut = {
    rightsToWorkCountries: countriesIds.map((value => '/api/countries/' + value))
  };

  const countries = await putTestersRightsToWorkCountries(model);

  dispatch(setRequestInProcess(false, 'putTesterRightsToWorkCountries'));
  dispatch(createAction(TESTER_RIGHTS_TO_WORK_COUNTRIES, () => countries)());
};