import * as React from "react";
import {composeValidators, fieldsNotEmpty} from "../../../helpers/validations/validation";
import {InjectedFormProps, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {postItem} from "../../../actions/dbs-certificate";
import {Link} from "react-router-dom";
import routerModal, {IBackRoute} from "../../../components/routerModal";
import {WithStyles} from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button/Button";
import {FieldGeneric} from "../../../components/forms/FieldGeneric";
import Grid from "@material-ui/core/Grid/Grid";
import {IDBSCertificateLocal} from "../../../models/dbs-certificate.interface";
import "react-datepicker/dist/react-datepicker.css";
import ReduxDocumentFileField from "../../../components/forms/ReduxDocumentFileField";
import ReduxDatePicker from "../../../components/forms/ReduxDatePicker";
import ReduxTextField from "../../../components/forms/ReduxTextField";
import ReduxImageFileField from "../../../components/forms/ReduxImageFileField";

const FORM_NAME = "DBSCertificatesCreate";

class DBSCertificatesCreate extends React.Component<InjectedFormProps<{}, {}> & {
  postItem: (certificate: IDBSCertificateLocal, backRoute: string, formName: string) => void,
} & WithStyles<any> & IBackRoute> {
  render() {
    const {handleSubmit, postItem, backRoute} = this.props;

    return (
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit((values: IDBSCertificateLocal) => {
          postItem(values, backRoute, FORM_NAME);
        })}
      >
        <h4>Check details</h4>
        <hr/>
        <Grid container spacing={40}>
          <Grid item xs={12}>
            <FieldGeneric
              name="evidence"
              label="Certificate*"
              component={ReduxImageFileField}
            />

            <FieldGeneric
              name="referenceNo"
              label="Reference No *"
              component={ReduxTextField}
            />

            <Grid container>
              <Grid item xs={12}>

                <FieldGeneric
                  name="validFrom"
                  label="Validated *"
                  InputLabelProps={{
                    shrink: true
                  }}
                  component={ReduxDatePicker}
                />

              </Grid>
            </Grid>

            <FieldGeneric
              name="comment"
              label="Comments"
              component={ReduxTextField}
            />
            <hr/>
            <div className="d-flex justify-content-between mt-4">
              <Link to={this.props.backRoute}>
                <Button variant="contained" color="default">
                  Cancel
                </Button>
              </Link>
              <Button type="submit"
                      variant="contained"
                      color="primary">
                Save
              </Button>
            </div>
          </Grid>
        </Grid>
      </form>
    );
  }
}

const formConnected = reduxForm({
  form: FORM_NAME,
  validate: composeValidators(
    fieldsNotEmpty(["referenceNo", "evidence", "validFrom"])
  )
})(DBSCertificatesCreate);

const connected = connect(undefined, {
  postItem
})(formConnected);

export default routerModal(connected);