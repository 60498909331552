export const options = {
  gbp: {
    style: "currency",
    currency: "GBP"
  }
};

// TODO: Set options to return formatted price with currency symbol
export const getCurrency = (number) => {
  return new Intl.NumberFormat().format(number);
};

export const getFormattedNumber = (number) => {
  return new Intl.NumberFormat().format(number);
};
