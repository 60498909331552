import * as React from "react";
import {Link, Route, RouteComponentProps, Switch, withRouter} from "react-router-dom";
import VirtualTeamSearchForm from "./VirtualTeamSearchForm"
import VirtualTeamList from "./VirtualTeamList";
import {getCreateLink} from "../../../helpers/router-helper";
import TesterUpdate from "./TesterUpdate";
import TesterCreate from "./TesterCreate";

class VirtualTeam extends React.Component<RouteComponentProps<any>, {}> {
  baseUrl = this.props.match.path;

  createComponent = () => <TesterCreate backRoute={this.baseUrl}/>;
  updateComponent = () => <TesterUpdate backRoute={this.baseUrl}/>;

  render() {
    return (
      <div>
        <div className="crud-sections-header ">
          <h4 className="main-content-header">Virtual Team</h4>
          <Link className="pull-right" to={getCreateLink(this.baseUrl)}>
            <button className="btn-book">Add New Tester</button>
          </Link>
        </div>
        <Switch>
          <Route path={getCreateLink(this.baseUrl)} render={this.createComponent}/>
          <Route path={this.baseUrl + "/:id"} render={this.updateComponent}/>
        </Switch>
        <VirtualTeamSearchForm/>
        <VirtualTeamList baseUrl={this.baseUrl}/>
      </div>
    );
  }
}

export default withRouter(VirtualTeam);