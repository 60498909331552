import { IRisk } from "containers/client/dashboard/DashboardCriticalRisks";
import { formatDate, renderAction, renderStatus } from "helpers/risk-treatment";
import { ITest } from "models/test.interface";
import * as Roles from "constants/roles";
import { TestStatus } from "constants/test-status";

export type Converter = {
  type: string,
  convert: () => string;
}

type Columns<T> = {
  key: string;
  render: (record: T) => string | number | null;
  canExport?: () => boolean;
}

export const getConfig = (test: ITest, userRoles: string[]): Columns<IRisk>[] => {
  const includeStatus = [
    TestStatus.STATUS_REPORT_ACCEPTED,
    TestStatus.STATUS_COMPLETED,
  ].includes(test.status);

  return [
    {
      key: 'Risk',
      render: (record) => `${record.title}`,
    },
    {
      key: 'Description',
      render: (record) => `${record.description}`,
    },
    {
      key: 'Impact',
      render: (record) => record.impact,
    },
    {
      key: 'Likelihood',
      render: (record) => record.likelihood,
    },
    {
      key: 'CVSS Score',
      render: (record) => record.vulnerabilityScore || '0',
      canExport: () => !!test.vulnerabilities,
    },
    {
      key: 'Rating',
      render: (record) => record.impact * record.likelihood,
    },
    {
      key: 'Treatment',
      render: (record) => record.treatment && renderAction(record.treatment.action) || null,
      canExport: () => includeStatus && [Roles.ROLE_PLANNER, Roles.ROLE_VIEWER].some((roleSeek: string) => userRoles.indexOf(roleSeek) !== -1),
    },
    {
      key: 'Residual Risk Rating',
      render: (record) => record.treatment?.residualRiskRating || null,
      canExport: () => includeStatus && [Roles.ROLE_PLANNER, Roles.ROLE_VIEWER].some((roleSeek: string) => userRoles.indexOf(roleSeek) !== -1),
    },
    {
      key: 'Comment',
      render: (record) => record.treatment && `Last update: ${formatDate(
        record.treatment.updatedAt
      )} ${record.treatment.comment}` || null,
      canExport: () => includeStatus && [Roles.ROLE_PLANNER, Roles.ROLE_VIEWER].some((roleSeek: string) => userRoles.indexOf(roleSeek) !== -1),
    },
    {
      key: 'Status',
      render: (record) => record.treatment && renderStatus(record.treatment.status) || null,
      canExport: () => includeStatus && [Roles.ROLE_PLANNER, Roles.ROLE_VIEWER].some((roleSeek: string) => userRoles.indexOf(roleSeek) !== -1),
    },
  ]
};
