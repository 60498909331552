import { createCrudAction, createLoaderAction } from './action-helper';
import { openSnackbar } from 'actions/snackbar';
import { setRequestInProcess } from 'actions/request';
import { reset } from 'redux-form';
import { getRequestFromType } from 'api/requests/request-factory';
import { push } from "react-router-redux";
import { handleHttpErrors } from './errors/http-error-handler';
import { ADMIT_CRUD_TYPE } from "constants/action-type";

export function getRequestName(type: ADMIT_CRUD_TYPE, ...params) {
  const name = params.join(' ');

  return `${type} ${name}`;
}

export const postItem = (crudType: ADMIT_CRUD_TYPE) => (model, formName, backRoute) => async dispatch => {
  dispatch(setRequestInProcess(true, getRequestName(crudType, 'postItem')));

  let data;

  try {
    data = await (getRequestFromType(crudType) as any).postItem(model);

    dispatch(openSnackbar('Created', 4000));
    dispatch(createCrudAction(crudType).addItem(data));
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(reset(formName));
  dispatch(setRequestInProcess(false, getRequestName(crudType, 'postItem')));
  dispatch(push(backRoute));
};

export const putItem = (crudType: ADMIT_CRUD_TYPE) => (model, formName, backRoute) => async dispatch => {
  dispatch(setRequestInProcess(true, getRequestName(crudType, 'putItem')));

  let data;
  try {
    data = await (getRequestFromType(crudType) as any).putItem(model);

    dispatch(openSnackbar('Updated', 4000));
    dispatch(createCrudAction(crudType).replaceItem(data));
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(reset(formName));
  dispatch(setRequestInProcess(false, getRequestName(crudType, 'putItem')));
  dispatch(push(backRoute));
};

export const getItems = (crudType: ADMIT_CRUD_TYPE) => (page, itemsPerPage = undefined, showStatus = undefined, text = '', parameters = {}) => async dispatch => {
  dispatch(setRequestInProcess(true, getRequestName(crudType, 'getItems')));
  /** part responsible for a loader state */
  dispatch(createLoaderAction(crudType).setIsNotLoaded());
  let data = [], totalItems;

  try {
    ({items: data, totalItems} = await (getRequestFromType(crudType) as any)
      .getItems(page, itemsPerPage, showStatus, text, parameters));
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(createLoaderAction(crudType).setPaginatioData(data, itemsPerPage, totalItems, page));
  dispatch(setRequestInProcess(false, getRequestName(crudType, 'getItems')));
};

export const getItem = (crudType: ADMIT_CRUD_TYPE) => (id) => async dispatch => {
  dispatch(setRequestInProcess(true, getRequestName(crudType, 'getItem', '' + id)));

  let data;

  try {
    data = await (getRequestFromType(crudType) as any).getItem(id);
    dispatch(createCrudAction(crudType).addItem(data));
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(setRequestInProcess(false, getRequestName(crudType, 'getItem', '' + id)));
};

export const archiveItem = (crudType: ADMIT_CRUD_TYPE) => (id, page, itemsPerPage, showStatus) => async dispatch => {
  dispatch(setRequestInProcess(true, getRequestName(crudType, 'deleteItem', '' + id)));

  let dataDeleted;

  try {
    dataDeleted = await (getRequestFromType(crudType) as any).deleteItem(id);
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(setRequestInProcess(false, getRequestName(crudType, 'deleteItem', '' + id)));
  dispatch(getItems(crudType)(page, itemsPerPage, showStatus));
};

export const unarchiveItem = (crudType: ADMIT_CRUD_TYPE) => (id, page, itemsPerPage, showStatus) => async dispatch => {
  dispatch(setRequestInProcess(true, getRequestName(crudType, 'unarchiveItem', '' + id)));

  let dataUnarchived;

  try {
    dataUnarchived = await (getRequestFromType(crudType) as any).unarchiveItem(id);
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(setRequestInProcess(false, getRequestName(crudType, 'unarchiveItem', '' + id)));
  dispatch(getItems(crudType)(page, itemsPerPage, showStatus));
};
