import * as React from "react";
import Button from "@material-ui/core/Button/Button";
import { Link, Route } from "react-router-dom";
import WorkHistoryList from "./WorkHistoryList";
import {RouteComponentProps, withRouter} from "react-router";
import {styles} from "../../../styles/material/tesrer-card";
import {WithStyles, withStyles} from "@material-ui/core";
import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent/CardContent";
import Typography from "@material-ui/core/Typography/Typography";
import {IWorkHistory} from "src/models/work-history.interface";

interface IProps {
  getProps: (props: IWorkHistory) => void;
  baseUrl: string;
};

interface IState {
  workHistoryItems: IWorkHistory[];
}

class WorkHistory extends React.Component<IProps & WithStyles & RouteComponentProps<Route>, IState> {
  baseUrl = this.props.match.path;

  state = {
    workHistoryItems: [],
  };

  getProps = (props: IWorkHistory[]) => {
    if (props) {
      this.setState({workHistoryItems: [...props]});
    }

    this.setState({workHistoryItems: [...props]});
  };

  highlight = () => {
    if (!this.state.workHistoryItems.length) {
      return " box-shadow-danger";
    }

    return "";
  };

  render() {
    return (
      <div>
        <Card className={this.props.classes.card + this.highlight()}>
          <div className={this.props.classes.details}>
            <CardContent className={this.props.classes.content}>
              <Typography variant="headline" className={this.props.classes.header}>
                <span>
                  Work History
                </span>
                <span className={this.props.classes.rightAlign}>
                  <Link to={`${this.baseUrl}/work-history/create`}>
                    <Button size="small" color="primary">
                      New Work History
                  </Button>
                  </Link>
                </span>
              </Typography>
              <hr/>
              <WorkHistoryList baseUrl={this.baseUrl} getProps={this.getProps}/>
            </CardContent>
          </div>
        </Card>
      </div>
    );
  }
}

export default withRouter(withStyles(styles as any)(WorkHistory) as any);
