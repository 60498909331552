import * as React from 'react';
import {connect} from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import {closeSnackbar} from "../../actions";
import Button from "@material-ui/core/Button";
import {getSnackbar} from "../../selectors/information";

function InformationBar(props) {
  if (JSON.stringify(props.snackbar) === '{}') {
    return null;
  }

  function handleClose(){
    props.closeSnackbar();
  }

  const action = (
    <Button color="secondary" size="small" onClick={handleClose}>
      Close
    </Button>
  );

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={true}
        onClose={handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={props.snackbar.message}
        action={action}
        autoHideDuration={props.snackbar.time}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    snackbar: getSnackbar(state),
  }
}

export default connect(mapStateToProps, { closeSnackbar })(InformationBar);
