import * as React from "react";
import {RouteComponentProps} from "react-router-dom";
import {favoriteTestersLoader, favoriteTestersRequest,  getFavoriteTestersFiltered, deleteFavoriteAction} from "../../../actions/favorite-testers";
import selector from "../../../selectors/favorite-testers";
import {connect} from "react-redux";
import {IAnyProps} from "../../../interfaces/any-props.interface";
import {ITesterProfileGet} from "../../../models/tester-profile.interface";
import {TablePagination, TableBody, Table, TableHead, TableRow, TableCell} from "@material-ui/core";
import {formValueSelector} from "redux-form";
import {FORM_NAME} from "./VirtualTeamSearchForm";
import TesterRow from "./TesterRow";
import {isChangesExist} from "../../../helpers/props-checker";
import debounce from "debounce";
import {postEmail} from "../../../api/requests/reset-password";
import {openSnackbar} from "../../../actions/snackbar";
import {countriesRequest} from "../../../actions";
import {MAX_PAGINATION_RESULTS} from "../../../constants/loader";
import {ACTIVE} from "../../../constants/filter";

const searchFormSelector = formValueSelector(FORM_NAME);

interface IFilterValues {
  name: string;
  groups: any;
}

interface IOwnProps {
  data: ITesterProfileGet[];
  searchValues: {
    tester: object
  };
}

type IProps = {
    baseUrl: string,
    [key: string]: any,
    openSnackbar: (...any) => void,
  }
  & IOwnProps
  & IFilterValues
  & RouteComponentProps<any>;

class VirtualTeamList extends React.Component<IProps, {}> {
  debouncedLoad = debounce((nextProps) => {
    this.loadItems(nextProps);
  }, 500);

  handleChangePage = (event, page) => {
    this.props.setPageNumber(page + 1);
  };

  handleChangeRowsPerPage = event => {
    this.props.setItemsPerPage(event.target.value);
  };

  loadItems = (props: IProps) => {
    props.loadItems(
      props.pageNumber,
      props.itemsPerPage,
      props.name,
      props.groups
    );
  };

  deleteFavorite = (id) => (event) => {
    this.props.deleteFavoriteAction(id, this.props.pageNumber, this.props.itemsPerPage, this.props.name, this.props.groups);
  };

  resetPassword = (email) => (event) => {
    if (confirm('Do you want to reset password for this tester account?')) {
      postEmail(email)
      .then((nextProps) => {
        this.props.openSnackbar('Reset password email was successfully sent', 4000);
      });
    }
  };

  componentDidMount() {
    this.loadItems(this.props);
    this.props.loadCountries(1, MAX_PAGINATION_RESULTS, ACTIVE);
  }

  componentWillReceiveProps(nextProps) {
    if (isChangesExist(["pageNumber", "itemsPerPage", "name", "groups"], nextProps, this.props)) {
      if (isChangesExist(["name"], nextProps, this.props)) {
        this.debouncedLoad.clear();
        this.debouncedLoad(nextProps);
      } else {
        this.loadItems(nextProps);
      }
    }

    if (!nextProps.data.length && nextProps.pageNumber > 1) {
      this.props.setPageNumber(1);
    }
  }

  render() {
    if (!this.props.isLoaded) {
      return (
        <div className="styled-block">
          Loading
        </div>
      );
    }

    if (!this.props.data.length) {
      return (
        <div className="styled-block">
          There are no items!
        </div>
      );
    }
    const {data} = this.props;
    return (

      <div className="styled-block">
        <div className="styled-table table-responsive">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Profile</TableCell>
                <TableCell>Tester</TableCell>
                <TableCell>Type</TableCell>
                <TableCell className="text-nowrap">Reset Password</TableCell>
                <TableCell>Manage</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                data.map(item => {

                  return (
                    <TesterRow key={item.id} tester={item} deleteFavorite={this.deleteFavorite} resetPassword={this.resetPassword}/>
                  );
                })
              }
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={this.props.totalItems}
            rowsPerPage={this.props.itemsPerPage}
            page={this.props.pageNumber - 1}
            backIconButtonProps={{"aria-label": "Previous Page"}}
            nextIconButtonProps={{"aria-label": "Next Page"}}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </div>
      </div>
    );
  }
}

export default connect<IAnyProps, IAnyProps, IAnyProps & { baseUrl: string }>(
  state => ({
    data: selector.getItems(state),
    isLoaded: selector.getIsLoaded(state),
    itemsPerPage: selector.getItemsPerPage(state),
    pageNumber: selector.getPageNumber(state),
    totalItems: selector.getTotalItems(state),
    name: searchFormSelector(state, "name") || "",
    groups: searchFormSelector(state, "groups") || [],
    loadCountries: countriesRequest.getItems,
  }),
  {
    loadItem: favoriteTestersRequest.getItem,
    loadItems: getFavoriteTestersFiltered,
    setItemsPerPage: favoriteTestersLoader.setItemsPerPage,
    setPageNumber: favoriteTestersLoader.setPageNumber,
    openSnackbar,
    deleteFavoriteAction,
  }
)(VirtualTeamList);
