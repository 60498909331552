import { FilterParamsType } from "types/consultant";
import { getApiJSONLD } from "api/swagger/api-factory";
import { convertorFromSwagger } from "api/convertors/project";
import { extractJSONLD } from "helpers/request-helper";

export const getItems = async (
  companyId: number | string,
  params: FilterParamsType & { name?: string }
) => {
  return getApiJSONLD()
    .then(api => api.getCompanyProjects(companyId, params))
    .then(extractJSONLD(convertorFromSwagger));
};

export { putItem, postItem } from "./projects";
