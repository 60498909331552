import * as React from "react";
import Notice from "../Notice";
import ProfileCard from "./ProfileCard";
import BankDetailsCard from "../bank-details/BankDetailsCard";
import CertificatesCard from "../certificates/CertificatesCard";
import DBSCertificateCreate from "../dbs-certificates/DBSCertificatesCreate";
import DBSCertificatesCard from "../dbs-certificates/DBSCertificatesCard";
import InsurancesCard from "../insurances/InsurancesCard";
import WorkHistory from "../work-history/WorkHistory";
import {
  Route,
  RouteComponentProps,
  Switch,
  withRouter
} from "react-router-dom";
import BankDetailsEdit from "../bank-details/BankDetailsEdit";
import ProfileEdit from "./ProfileEdit";
import CertificatesCreate from "../certificates/CertificatesCreate";
import InsurancesCreate from "../insurances/InsurancesCreate";
import InsurancesUpdate from "../insurances/InsurancesUpdate";
import WorkHistoryCreate from "../work-history/WorkHistoryCreate";
import WorkHistoryUpdate from "../work-history/WorkHistoryUpdate";
import RightsToWork from "../rights-to-work/RightsToWork";
import { WithStyles } from "@material-ui/core";
import { ICountry } from "../../../models/dialing-code.interface";
import { UK_CODE } from "../../../constants/countries";
import { dataFetcher } from "components/dataFetcher";
import { getProfileFromJWT } from "../../../helpers/jwt-checker";
import { logOut } from "../../../actions/common";
import { createLoaderActionItem } from "../../../interfaces/reducer-item.interface";
import * as actionType from "../../../constants/action-type";
import {
  getBankDetails,
  getProfile,
  getTesterSpecialisms
} from "../../../selectors/tester-profile";
import countriesSelector from "../../../selectors/countries";
import {
  ITesterProfile,
  ITesterIncoming,
  ITesterProfileGet
} from "models/tester-profile.interface";
import { getTesterRightsToWorkCountries } from "actions/tester-rights-to-work-countries";
import { countriesRequest } from "../../../actions/countries";
import { ACTIVE } from "../../../constants/filter";
// import { changeReceiveBookingRequestsAction } from "../../../actions/users";
import { getTesterCertifications } from "../../../actions/certificate";
import certificateSelector from "../../../selectors/certificate";
import { getTesterInsurances } from "../../../actions/insurance";
import insuranceSelector from "../../../selectors/insurance";
import { getTesterDBSCertifications } from "../../../actions/dbs-certificate";
import DBScertificateSelector from "../../../selectors/dbs-certificate";
import { ISpecialism } from "../../../models/specialism.interface";
import { IBankDatails } from "../../../models/bank-datails.interface";
import { ICertificate } from "../../../models/certificate.interface";
import { IInsurance } from "../../../models/insurance.interface";
import { IDBSCertificate } from "../../../models/dbs-certificate.interface";

interface IProps extends WithStyles, RouteComponentProps<any> {
  countries: ICountry[];
  profile: ITesterProfile & ITesterIncoming & ITesterProfileGet;
  specialisms: ISpecialism[];
  bankDetails: IBankDatails;
  certificates: ICertificate[];
  insurances: IInsurance[];
  dbsCertificates: IDBSCertificate[];
  rightsToWorkCountries: ICountry[];
}

type Props = IProps & WithStyles<any> & RouteComponentProps<any> & any;

class ProfileDetails extends React.Component<Props, {}> {
  needRenderUKBlock = false;

  baseUrl = this.props.match.path;

  editBankDetails = () => <BankDetailsEdit backRoute={this.baseUrl} />;

  createCertificateComponent = () => (
    <CertificatesCreate backRoute={this.baseUrl} />
  );
  createDBSCertificateComponent = () => (
    <DBSCertificateCreate backRoute={this.baseUrl} />
  );

  createInsuranceComponent = () => (
    <InsurancesCreate backRoute={this.baseUrl} />
  );
  updateInsuranceComponent = () => (
    <InsurancesUpdate backRoute={this.baseUrl} />
  );

  createWorkHistoryComponent = () => (
    <WorkHistoryCreate backRoute={this.baseUrl} />
  );
  updateWorkHistoryComponent = () => (
    <WorkHistoryUpdate backRoute={this.baseUrl} />
  );

  checkCountry() {
    const { countries, profile, rightsToWorkCountries } = this.props;

    const countryUK = countries.find(
      (country: ICountry) => country.code === UK_CODE
    );
    this.needRenderUKBlock =
      profile?.country === countryUK?.id ||
      rightsToWorkCountries.some(
        (rCountry: ICountry) => rCountry.id === countryUK?.id
      );
  }

  profileDetails = () => {
    return (
      <div>
        <Notice isUk={this.needRenderUKBlock} />
        <ProfileCard />
        <div className="separator" />
        <BankDetailsCard />
        <div className="separator" />
        <CertificatesCard />
        <div className="separator" />
        <RightsToWork />
        {this.needRenderUKBlock && (
          <>
            <div className="separator" />
            <InsurancesCard />
            <div className="separator" />
            <DBSCertificatesCard />
          </>
        )}
        <div className="separator" />
        <WorkHistory />
        <Switch>
          <Route
            path={`${this.baseUrl}/edit-bank-details`}
            render={this.editBankDetails}
          />
          <Route
            path={`${this.baseUrl}/create-certificate`}
            render={this.createCertificateComponent}
          />
          <Route
            path={`${this.baseUrl}/create-insurance`}
            render={this.createInsuranceComponent}
          />
          <Route
            path={`${this.baseUrl}/insurances/:id`}
            render={this.updateInsuranceComponent}
          />
          <Route
            path={`${this.baseUrl}/create-dbs-certificate`}
            render={this.createDBSCertificateComponent}
          />
          <Route
            path={`${this.baseUrl}/work-history/create`}
            render={this.createWorkHistoryComponent}
          />
          <Route
            path={`${this.baseUrl}/work-history/:id`}
            render={this.updateWorkHistoryComponent}
          />
        </Switch>
      </div>
    );
  };
  profileEdit = () => <ProfileEdit backRoute={this.baseUrl} />;

  render() {
    this.checkCountry();

    return (
      <Switch>
        <Route
          path={this.baseUrl + "/edit-profile"}
          render={this.profileEdit}
        />
        <Route path={this.baseUrl} render={this.profileDetails} />
      </Switch>
    );
  }
}

const componentWithRouter = withRouter(ProfileDetails);

export default (dataFetcher<IProps>(componentWithRouter, [
  {
    key: "profile",
    action: () => {
      const profile = getProfileFromJWT();

      if (!profile) {
        return logOut();
      } else {
        return createLoaderActionItem(actionType.TESTER_PROFILE).get();
      }
    },
    selector: (state, props) => getProfile(state),
    alwaysReceiveFreshData: true
  },
  {
    key: "countries",
    action: () => countriesRequest.getItems(1, 1000, ACTIVE),
    selector: (state, props) => countriesSelector.getItemsObject(state),
    alwaysReceiveFreshData: true
  },
  {
    key: "rightsToWorkCountries",
    action: () => () => getTesterRightsToWorkCountries,
    selector: (state, props) =>
      state.testerRightToWorkCountries.testerRightToWorkCountries,
    alwaysReceiveFreshData: true
  },
  {
    key: "bankDetails",
    action: () => createLoaderActionItem(actionType.TESTER_BANK_DETAILS).get(),
    selector: (state, props) => getBankDetails(state),
    alwaysReceiveFreshData: true
  },
  {
    key: "specialisms",
    action: () => {
      return createLoaderActionItem(actionType.TESTER_SPECIALISMS).get();
    },
    selector: (state, props) => getTesterSpecialisms(state),
    alwaysReceiveFreshData: true
  },
  {
    key: "certificates",
    action: () => getTesterCertifications(),
    selector: (state, props) => certificateSelector.getItemsObject(state),
    alwaysReceiveFreshData: true
  },
  {
    key: "insurances",
    action: () => getTesterInsurances(),
    selector: (state, props) => insuranceSelector.getItemsObject(state),
    alwaysReceiveFreshData: true
  },
  {
    key: "dbsCertificates",
    action: () => getTesterDBSCertifications(),
    selector: (state, props) => DBScertificateSelector.getItemsObject(state),
    alwaysReceiveFreshData: true
  }
]) as unknown) as React.ComponentClass<{}>;
