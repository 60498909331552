import React, {useEffect} from "react";
import {
  Icon,
  Grid,
  Zoom,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper/Paper";
import TooltipIcon from "@material-ui/core/Tooltip/Tooltip";
import Table from "@material-ui/core/Table/Table";
import EnhancedTableHead from "../../../../components/table/EnhancedTableHead";
import TableBody from "@material-ui/core/TableBody/TableBody";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableRow from "@material-ui/core/TableRow";
import emailDomainsSelector from "../../../../selectors/email-domains";
import {bindActionCreators} from "redux";
import {getEmailDomainsRequestsAction} from "../../../../actions/email-domains";
import {connect} from "react-redux";
import {TableNoItems} from "../../../../components/table/TableNoItems";
import {Link} from "react-router-dom";

export const EmailDomainRestrictionTooltipText = () => (
  <span>
      This section allows you to limit user creation within
      the specified email domains on the&nbsp;
    <Link to={`/client/admin/users`} className="underlined-link--white">Users Management</Link> and&nbsp;
    <Link to={`/client/virtual-team`} className="underlined-link--white">Virtual Team</Link> pages.
      If you wish to remove domain-based restrictions, simply leave all these fields empty.
    </span>
)

const EmailDomainRestrictionList = (props) => {
  const {loadEmailDomains, data, isLoaded, openEditMode} = props;

  useEffect(() => {
    loadEmailDomains();
  }, []);


  return (
    <div>
      <div className="block-header-wrapper flex-column-in-mobile">
        <h4>
          Email Domain Restriction
          <TooltipIcon
            placement={"top-start"}
            TransitionComponent={Zoom}
            disableFocusListener
            interactive
            title={<EmailDomainRestrictionTooltipText />}
          >
            <Icon style={{marginLeft: "5px", marginBottom: "2px", verticalAlign: "middle"}}>info</Icon>
          </TooltipIcon>
        </h4>
        <button className="btn-edit mb=2" onClick={openEditMode}>
          Edit
        </button>
      </div>
      {!isLoaded && <TableNoItems asLoading hasWrapper />}
      {isLoaded && data.length === 0 && <TableNoItems hasWrapper />}
      {isLoaded && data.length > 0 && (
        <Paper className="p-4 mb-4">
          <div className="table-responsive styled-table">
            <Grid container>
              <Grid item xs={12}>
                <Table padding="dense">
                  <EnhancedTableHead
                    columns={['Domains']}
                  />
                  <TableBody>
                    {data.map(({domain}, index: number) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>
                            {domain}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </div>
        </Paper>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    data: emailDomainsSelector.getItems(state),
    isLoaded: emailDomainsSelector.getIsLoaded(state),
  };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    loadEmailDomains: getEmailDomainsRequestsAction,
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailDomainRestrictionList);
