import * as React from 'react';
import {GUIDANCE_SCHEDULE} from "../../constants/content-management";
import {dataFetcher} from "../../components/dataFetcher";
import {getItemByKey} from "../../actions/content-management";
import {getByKey} from "../../selectors/content-management";
import {IContentManagement} from "../../models/content-management.interface";
import {RawHTML} from "../../components/RawHTML";

interface IProps {
  contentManagement: IContentManagement;
}

class Guidance extends React.Component<IProps, {}> {
  render() {
    return (
      <div className="guidance-block">
        <RawHTML>{this.props.contentManagement.content}</RawHTML>
      </div>
    );
  }
}

const fetched: any = dataFetcher(
  Guidance,
  [{
    key: 'contentManagement',
    selector: (state) => getByKey(GUIDANCE_SCHEDULE)(state),
    action: () => getItemByKey(GUIDANCE_SCHEDULE),
  }]
);

export default fetched;