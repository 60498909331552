import * as React from "react";
import {IAnyProps} from "../../../../interfaces/any-props.interface";
import {connect} from "react-redux";
import {getCalendar} from "../../../../selectors/calendar";
import {ICalendarEvent, ICalendarReducer} from "../../../../reducers/calendar";
import {
  setCalendarDateAction,
  setCalendarEventsAction,
  setCalendarRangeAction,
  setCalendarViewTypeAction
} from "../../../../actions/calendar";
import moment from "moment";
import {Moment} from "moment";
import CalendarModal from "./CalendarModal";
import {Link} from "react-router-dom";
import Calendar from "react-calendar";
import {calendarModalOpen} from "../../../../actions/modals";
import {Button, Icon, Paper, Tooltip} from "@material-ui/core";
import StatusBlock from "../../../common/StatusBlock";
import {formatDateWithSlashesDdMmYyyy} from "../../../../helpers/date-formatter";
import {TestStatus} from "../../../../constants/test-status";

type IMapStateToProps = ICalendarReducer;

interface IDispatchProps {
  setCalendarDate: (year?: number | undefined, month?: number | undefined) => void;
  setCalendarViewType: (type: "month" | "year") => void;
  calendarModalOpen: (date: Moment, events: ICalendarEvent[]) => void;
}

type IProps = IAnyProps & IMapStateToProps & IDispatchProps;

interface IState {
  showCalendar: boolean;
}

interface IRow {
  event?: ICalendarEvent;
  curDate?: Moment;
}

export interface IRenderTitle {
  projectName: string,
  name: string,
  hash: string,
  proposal?: {
    dateStart: string,
    dateEnd: string,
    fullTotalCostForClient: number;
    currency: {
      symbol: string
    },
  },
  cost?: string
}


export const renderTitle = ({projectName, name, hash, proposal}: IRenderTitle) => {
  const defaultProjectData = (
    <>
      <div>Project : {projectName}</div>
      <div>Test title : {hash}-{name}</div>
    </>
  );

  if (proposal){
    const {dateStart, dateEnd, currency, fullTotalCostForClient} = proposal

    return (
        <>
          {defaultProjectData}
          <div>Proposed date from/to : {formatDateWithSlashesDdMmYyyy(dateStart)} - {formatDateWithSlashesDdMmYyyy(dateEnd)}</div>
          <div>Total : {currency?.symbol}{fullTotalCostForClient}</div>
        </>
    )
  }

  return defaultProjectData
}


class CalendarMonthView extends React.Component<IProps, IState> {
  state = {
    showCalendar: false
  };

  firstRow: IRow = {
    event: undefined,
    curDate: undefined
  };

  secondRow: IRow = {
    event: undefined,
    curDate: undefined
  };

  previousWeek: number = -1;

  hasProposal = (hasProposal): boolean => !!hasProposal
  isBookingProposalEdited = (status) => status === TestStatus.STATUS_BOOKING_REQUESTED_EDITED

  handleClickToday = () => {
    this.props.setCalendarDate(new Date().getFullYear(), new Date().getMonth());
  };

  handleClickPrev = () => {
    if (this.props.month === 0) {
      this.props.setCalendarDate(this.props.year - 1, 11);
    } else {
      this.props.setCalendarDate(this.props.year, this.props.month - 1);
    }
  };

  handleClickNext = () => {
    if (this.props.month === 11) {
      this.props.setCalendarDate(this.props.year + 1, 0);
    } else {
      this.props.setCalendarDate(this.props.year, this.props.month + 1);
    }
  };

  getEventsToday = (events: ICalendarEvent[], date: Moment) => {
    return events.filter(event => {
      if(this.hasProposal(event?.proposal) && !this.isBookingProposalEdited(event.status)){
        return date.isSameOrAfter(event?.proposal?.dateStart) && date.isSameOrBefore(event?.proposal?.dateEnd);
      }

      return date.isSameOrAfter(event.start) && date.isSameOrBefore(event.end);
    });
  };

  handleShowPopup = (date: Moment, events: ICalendarEvent[]) => () => {
    this.props.calendarModalOpen(date, events);
  };

  getUnusedEvents(events: ICalendarEvent[]) {
    const unusedEvents: ICalendarEvent[] = [];
    const firstID = this.firstRow.event ? this.firstRow.event.id : -1;
    const secondID = this.secondRow.event ? this.secondRow.event.id : -1;

    events.map((event) => {
      if (event.id !== firstID
        && event.id !== secondID) {
        unusedEvents.push(event);
      }
    });

    return unusedEvents;
  }

  renderAllEvents(
    events: ICalendarEvent[],
    curDate: Moment,
    week: number,
    index: number
  ) {
    if (!events.length) {
      return "";
    }

    let firstRowNewValue = false;
    let secondRowNewValue = false;

    if (week !== this.previousWeek) {
      firstRowNewValue = true;
      secondRowNewValue = true;
      this.previousWeek = week;
    }

    const firstEvent = events && events.find((event) =>
      event.id === (this.firstRow.event
      ? this.firstRow.event.id
      : -1)
    );
    const secondEvent = events.find((event) =>
      event.id === (this.secondRow.event
      ? this.secondRow.event.id
      : -1)
    );

    let unusedEvents: ICalendarEvent[] = this.getUnusedEvents(events);

    if (!firstEvent) {
      if (unusedEvents.length > 0) {
        this.firstRow = {
          event: unusedEvents[0],
          curDate
        };
        firstRowNewValue = true;
      } else {
        this.firstRow = {
          event: undefined,
          curDate
        };
      }
    }

    unusedEvents = this.getUnusedEvents(events);

    if (!secondEvent) {
      if (unusedEvents.length > 0) {
        this.secondRow = {
          event: unusedEvents[0],
          curDate
        };
        secondRowNewValue = true;
      } else {
        this.secondRow = {
          event: undefined,
          curDate
        };
      }
    }

    unusedEvents = this.getUnusedEvents(events);

    return [
      this.renderEvent(
        this.firstRow.event ? this.firstRow.event : "",
        curDate,
        firstRowNewValue
      ),
      this.renderEvent(
        this.secondRow.event ? this.secondRow.event : "",
        curDate,
        secondRowNewValue
      ),
      unusedEvents.length > 0 ? (<div
        key={`${week} ${index} more`}
        className="more"
        onClick={this.handleShowPopup(curDate, events)}
      >
        {`${unusedEvents.length} more`}
      </div>) : ""
    ];
  }

  renderEvent = (event, currDate, needName) => {
    if (event === "") {
      return (
        <div key={event.id} className={"empty-event"}/>
      );
    }

    const startDate = this.hasProposal(event?.proposal) && !this.isBookingProposalEdited(event.status) ? moment(event?.proposal?.dateStart) : moment(event.dateFrom);
    const endDate = this.hasProposal(event?.proposal) && !this.isBookingProposalEdited(event.status)? moment(event?.proposal?.dateEnd) : moment(event.dateTo);

    if (startDate.format("YYYY-MM-DD") === endDate.format("YYYY-MM-DD")) {
      return (
        <div key={event.id} className={"event event-oneday"}>
          <Link to={`/client/schedule/test/${event.id}`}>
            {event.status && <StatusBlock status={event.status} noText={true}/>}
            <Tooltip
                className="ml-1"
                placement={"bottom-end"}
                disableFocusListener
                disableTouchListener
                title={renderTitle(event)}>
              <span>{event.name}</span>
            </Tooltip>
          </Link>
        </div>
      );
    }

    if (currDate.format("YYYY-MM-DD") === startDate.format("YYYY-MM-DD")) {
      return (
        <div key={event.id} className={"event event-start"}>
          <Link to={`/client/schedule/test/${event.id}`}>
            {event.status && <StatusBlock status={event.status} noText={true}/>}
            <Tooltip
                className="ml-1"
                placement={"bottom-end"}
                disableFocusListener
                disableTouchListener
                title={renderTitle(event)}>
              <span>{event.name}</span>
            </Tooltip>
          </Link>
        </div>
      );
    }

    if (currDate.format("YYYY-MM-DD") === endDate.format("YYYY-MM-DD")) {
      return (
        <div key={event.id} className={"event event-end"}>
          <Link to={`/client/schedule/test/${event.id}`}>
            &nbsp;
          </Link>
        </div>
      );
    }

    return (
      <div key={event.id} className={"event"}>
        {needName
          ? (
            <Link to={`/client/schedule/test/${event.id}`}>
              {event.status && <StatusBlock status={event.status} noText={true}/>}
              <Tooltip
                  className="ml-1"
                  placement={"bottom-end"}
                  disableFocusListener
                  disableTouchListener
                  title={renderTitle(event)}>
                <span>{event.name}</span>
              </Tooltip>
            </Link>
          )
          : (
            <Link to={`/client/schedule/test/${event.id}`}>
              &nbsp;
            </Link>
          )
        }

      </div>
    );
  };

  renderRow = (date: Moment, week: number) => {
    const events = this.props.events;

    return Array.apply(null, new Array(7)).map((a, index) => {
      const dateCurrent = date.clone()
        .add(week, "w")
        .add(index, "d");

      const isChosenMonth = dateCurrent.year() === this.props.year && dateCurrent.month() === this.props.month;

      if (!isChosenMonth) {
        return (
          <td key={`${week} ${index}`} className={"calendar-cell other-month"}>
            <div className={"date-number other"}>
              {dateCurrent.format("DD")}
            </div>
          </td>
        );
      }

      const eventsToday = this.getEventsToday(events, dateCurrent);

      const formattedCurrentDate = dateCurrent.format().slice(0, 10);
      const formattedNow = new Date().toISOString().slice(0, 10);
      const isToday = formattedCurrentDate === formattedNow ? " today" : "";

      return (
        <td key={`${week} ${index}`} className={"calendar-cell"}>
          <div className={`date-number${isToday}`}>
            {dateCurrent.format("DD")}
          </div>
          {this.renderAllEvents(eventsToday, dateCurrent, week, index)}
        </td>
      );
    });
  };

  renderNames = (date: Moment) => {
    return Array.apply(null, new Array(7)).map((a, index) => {
      const dateAct = date.clone()
        .add(index, "d");

      return (
        <td key={`-1 ${index}`} className={"calendar-names "}>
          {dateAct.format("dddd")}
        </td>
      );
    });
  };

  renderWeeks = (date: Moment) => {
    let week = 0;
    const rows: any = [];
    const startOfMonth = this.getStartOfMonth(date);
    const dateEndOfMonth = date.clone()
      .endOf("month")
      .endOf("isoWeek")
      .add(1, "d");

    const isExistsWeeks = () => {
      const dateEnd = startOfMonth.clone()
        .add(week, "w")
        .add(7, "d");

      return dateEnd.isBefore(dateEndOfMonth);
    };

    rows.push(<tr key={startOfMonth.format('YYYY-MM-DD')}>{this.renderNames(startOfMonth)}</tr>);

    while (isExistsWeeks()) {
      rows.push(<tr key={week}>{this.renderRow(startOfMonth, week)}</tr>);
      week++;
    }

    return rows;
  };

  getStartOfMonth = (date: Moment) => {
    if (this.props.month === 0) {
      return date.clone()
        .year(this.props.year - 1)
        .month(11)
        .date(31)
        .startOf("isoWeek");
    } else {
      return date.clone()
        .startOf("month")
        .startOf("isoWeek");
    }
  };

  onDateChange = (date: Date) => {
    this.props.setCalendarDate(date.getFullYear(), date.getMonth());
    this.setState({showCalendar: false});
  };

  handleChangeCalendar = () => {
    this.setState({showCalendar: !this.state.showCalendar});
  };

  render() {
    const date = moment([this.props.year, this.props.month, 1]);

    return (
      <div>
        <CalendarModal/>

        <div className="calendar-head">

          <div className="month-year-buttons">
            <button
              disabled
              onClick={this.props.setCalendarViewType.bind(null, "month")}
            >month
            </button>
            <button
              onClick={this.props.setCalendarViewType.bind(null, "year")}
            >year
            </button>
          </div>

          <div className="calendar-prev-next-buttons">

            <button onClick={this.handleClickPrev} className="prev">
              <img src="/images/calendar-arrow-prev.svg" alt="Previous month"/>
            </button>

            <span>
              {date.format("MMMM YYYY")}

              {/* TODO: This calendar view was in previous version of design...

              <IconButton onClick={this.handleChangeCalendar}>
                <Icon>calendar_today</Icon>
              </IconButton>
              {this.state.showCalendar ?
                <Paper className="datepicker-custom p-4">
                  <div className="close-month-calendar">
                    <Button onClick={this.handleChangeCalendar} color="default" variant="outlined">
                      Close
                    </Button>
                  </div>
                  <Calendar
                    locale={"en"}
                    onChange={this.onDateChange}
                  />
                </Paper> : null}

                */}

            </span>

            <button onClick={this.handleClickNext} className="next">
              <img src="/images/calendar-arrow-next.svg" alt="Next month"/>
            </button>

          </div>

          <div>
            <button
              className="calendar-btn-today"
              // onClick={this.handleChangeCalendar}
              onClick={this.handleClickToday}
            >today
            </button>

            {this.state.showCalendar ?
              <Paper className="datepicker-custom p-4">
                <div className="close-month-calendar">
                  <Button onClick={this.handleChangeCalendar} color="default" variant="outlined">
                    Close
                  </Button>
                </div>
                <Calendar
                  locale={"en"}
                  onChange={this.onDateChange}
                />
              </Paper> : null}
          </div>

        </div>

        <table className="calendar month">
          <tbody>
            {this.renderWeeks(date)}
          </tbody>
        </table>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return getCalendar(state);
};

export default connect(mapStateToProps, {
  setCalendarViewType: setCalendarViewTypeAction,
  setCalendarRange: setCalendarRangeAction,
  setCalendarEvents: setCalendarEventsAction,
  setCalendarDate: setCalendarDateAction,
  calendarModalOpen
})(CalendarMonthView);
