import * as React from "react";
import { WrappedFieldProps } from "redux-form/lib/Field";
import { ComponentType } from "react";
import Button from "@material-ui/core/Button/Button";
import { InputLabel } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { result } from "lodash";

const ReduxDocumentFileField: ComponentType<WrappedFieldProps & {
  [key: string]: any;
}> = ({ input, label, meta: { touched, error }, id, classes, ...custom }) => {
  let file;

  const onChange = (event: React.ChangeEvent<any>) => {
    input.onChange(event.target.files[0]);
  };
  const handleClick = (event: React.ChangeEvent<any>) => {
    file.click();
  };

  return (
    <div className={classes.fullWidth}>
      {label && (
        <InputLabel htmlFor="select-multiple" error={touched && error}>
          {label}
        </InputLabel>
      )}
      <div>
        <Button variant="contained" color="default" onClick={handleClick}>
          Upload File
          <input
            type="file"
            accept="
                 application/pdf,application/msword,
                 application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                 application/vnd.oasis.opendocument.text,
                 application/vnd.ms-powerpoint,
                 application/vnd.openxmlformats-officedocument.presentationml.presentation,
                 application/vnd.oasis.opendocument.presentation,
                 application/vnd.ms-excel,
                 application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                 application/vnd.oasis.opendocument.spreadsheet,
                 text/csv,text/plain,
                 .docx,
                 .ods,
                 .odt,
                 .odg,
                 .xlsx,
                 .xls,
                 .csv,
                 .pptx,
                 .ppt,
                 image/png,
                 image/jpeg,
                 image/gif"
            ref={ref => (file = ref)}
            onChange={onChange}
            hidden
          />
        </Button>
        <div className={"filename"}>{result(input, "value.name", "")}</div>
      </div>
    </div>
  );
};

export default withStyles({
  fullWidth: {
    width: "100%",
    height: 90,
    marginTop: 20
  }
})(ReduxDocumentFileField);
