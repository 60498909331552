import * as Types from "../constants/action-type";
import {
  createLoaderAction,
  createCrudAction,
  createRequestAction
} from "../helpers/action-helper";
import {setRequestInProcess} from "./request";
import {getItems, deleteFavorite} from "../api/requests/favorite-testers";
import {handleHttpErrors} from "../helpers/errors/http-error-handler";
import { openSnackbar } from "./snackbar";

export const favoriteTestersLoader = createLoaderAction(Types.FAVORITE_TESTERS);
export const favoriteTestersRequest = createRequestAction(Types.FAVORITE_TESTERS);

const crudType = Types.FAVORITE_TESTERS;

export const getFavoriteTestersFiltered = (page, itemsPerPage, name, groups) => async dispatch => {
  dispatch(setRequestInProcess(true, "getFavoriteTestersFiltered"));
  /** part responsible for a loader state */
  dispatch(createLoaderAction(crudType).setIsNotLoaded());

  let data = [], totalItems;

  try {
    ({
      items: data,
      totalItems
    } = await getItems(page, itemsPerPage, "Active", name, groups));
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(createLoaderAction(crudType).setPaginatioData(data, itemsPerPage, totalItems, page));
  dispatch(setRequestInProcess(false, "getFavoriteTestersFiltered"));
};

export const deleteFavoriteAction = (testerId, page, itemsPerPage, name, groups) => async dispatch => {

  dispatch(setRequestInProcess(true, 'deleteFavoriteAction'));

  let data;
  try {
    data = await deleteFavorite(testerId);
    dispatch(createCrudAction(Types.FAVORITE_TESTERS).removeItem(testerId));
  } catch (error) {
    handleHttpErrors(error, dispatch);
    dispatch(setRequestInProcess(false, 'deleteFavoriteAction'));
    return;
  }
  dispatch(getFavoriteTestersFiltered(page, itemsPerPage, name, groups));
  dispatch(openSnackbar("Tester successfully removed from favorites", 4000));

  dispatch(setRequestInProcess(false, 'deleteFavoriteAction'));
};
