import {getApiJSONLD} from "../swagger/api-factory";
import {extractJSONLD} from "../../helpers/request-helper";
import {convertorFromSwagger} from "../convertors/ip-ranges";
import {IPRangesType} from "../../types/client-admin-settings";

export const getIpRanges = async () => {
  return getApiJSONLD()
    .then(api => api.getApiCompanyIpWhiteLists())
    .then(extractJSONLD(convertorFromSwagger));
};

export const putIpRangesRequest = async (ipRanges: IPRangesType[]) => {
  return getApiJSONLD()
    .then(api => api.postApiCompanyIpWhiteLists({ipRanges}));
}