import * as React from "react";
import {connect} from "react-redux";
import {Modal, WithStyles} from "@material-ui/core";
import {reportAcceptedModalClose} from "../../../../actions/modals";
import {getIsOpenModalAcceptReport} from "../../../../selectors/modal";
import {FieldGeneric} from "../../../../components/forms/FieldGeneric";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {reduxForm, InjectedFormProps} from "redux-form";
import ReduxTextField from "../../../../components/forms/ReduxTextField";
import {REPORT_ACCEPTED_FORM_NAME} from "../../../../constants/forms";
import {acceptReportModalSubmit} from "../../../../actions/review";
import StarRatingComponent from "react-star-rating-component";
import Grid from "@material-ui/core/Grid";
import {composeValidators, fieldMaxLength, fieldsNotEmpty} from "../../../../helpers/validations/validation";

interface IStateToProps {
  isOpenModal: any;
}

interface IDispatchToProps {
  modalClose: () => any;
  modalSubmit: (idReport, idTest, rang, comment) => any;
}

class ReportAcceptedModal extends React.Component<IStateToProps
  & IDispatchToProps
  & WithStyles<any>
  & RouteComponentProps<any>
  & InjectedFormProps<any>
  & {
  reportId: any,
  testId: any,
}> {

  state = {
    rating: 3
  };

  onStarClick = (event) => {
    this.setState({rating: event});
  };

  render() {
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.props.isOpenModal}
        onClose={this.handleClose}
        className="d-flex overflow-auto"
      >
        <div className="styled-modal md">
          <p>
            Thank you for using AVORD.<br/>
            Please take some time to submit feedback on your tester.
          </p>
          <h4>Submit Feedback</h4>
          <hr/>
          <div className="styled-modal-body">
            <form noValidate autoComplete="off" onSubmit={this.props.handleSubmit((values) => {
              this.props.modalSubmit(this.props.reportId, this.props.testId, this.state.rating, values.comment);
            })}>
              <div style={{fontSize: 24}}>
                <StarRatingComponent
                  name="rang"
                  starCount={5}
                  value={this.state.rating}
                  onStarClick={this.onStarClick}
                />
              </div>
              <FieldGeneric
                name="comment"
                rows={4}
                multiline={true}
                label="Comment *"
                margin="normal"
                component={ReduxTextField}
              />
              <div className="text-right">
                <button className="btn-cancel" onClick={this.handleClose}>
                  Cancel
                </button>
                <button type="submit" className="btn-accept">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    );
  }

  private readonly handleClose = () => {
    this.props.modalClose();
    this.props.reset();
  };
}

function mapStateToProps(state) {
  return {
    isOpenModal: getIsOpenModalAcceptReport(state)
  };
}

const connectedComponent = connect<IStateToProps, IDispatchToProps>(mapStateToProps, {
  modalClose: reportAcceptedModalClose,
  modalSubmit: acceptReportModalSubmit
})(ReportAcceptedModal);

const formed = reduxForm({
  form: REPORT_ACCEPTED_FORM_NAME,
  validate: composeValidators(
    fieldsNotEmpty(["comment"]),
    fieldMaxLength(["comment"], 300)
  )
})(connectedComponent);

const routered = withRouter(formed as any);

export default withRouter(routered as any) as any;
