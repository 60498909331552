import {ICountry} from "../models/dialing-code.interface";


export const sortDialingCodes = (dialingCodes) => {
  const sortedDialingCodes: ICountry[] = [];

  if (dialingCodes.length) {
    dialingCodes.map((item: ICountry) => {
      if (item.name === 'United States') {
        sortedDialingCodes.push(item);
      }

      if (item.name === 'United Kingdom') {
        sortedDialingCodes.unshift(item);
      }
    });

    const withoutUK = dialingCodes.filter((item: ICountry) => {
      return item.name !== 'United Kingdom';
    });

    const withoutUS = withoutUK.filter((item: ICountry) => {
      return item.name !== 'United States';
    });

    withoutUS.map((item: ICountry) => {
      sortedDialingCodes.push(item);
    });
  }

  return sortedDialingCodes;
};