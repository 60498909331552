import * as React from "react";
import {connect} from "react-redux";
import {withStyles, Modal, Typography, Grid, Button, WithStyles} from "@material-ui/core";
import {requestAccountDeletionModelClose, requestAccountDeletingSubmit} from "../../actions";
import {getIsOpenModalRequestAccountDeleting} from "../../selectors/modal";
import {defaultStyleWidth} from "../../styles/material/popup-style";

interface IStateToProps {
  isOpenModal: any;
}

interface IDispatchToProps {
  requestAccountDeletionModelClose: (...any) => any;
  requestAccountDeletingSubmit: () => void;
}

class RequestDeletionModal extends React.Component<IStateToProps & IDispatchToProps & WithStyles<any>> {
  render() {
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.props.isOpenModal}
        onClose={this.handleClose}
        className="d-flex overflow-auto"
      >
        <div className="styled-modal xs">
          <h4>Are you sure?</h4>
          <hr/>
          <p>
            An email with the request will be sent to AVORD administrators.
          </p>
          <div className="text-right">
            <button
              className="btn-cancel"
              onClick={this.handleClose}>
              No
            </button>
            <button
              className="btn-accept"
              onClick={this.handleChange}>
              Yes
            </button>
          </div>
        </div>
      </Modal>
    );
  }

  private readonly handleClose = () => {
    this.props.requestAccountDeletionModelClose();
  };

  private readonly handleChange = () => {
    this.props.requestAccountDeletingSubmit();
  };
}

function mapStateToProps(state) {
  return {
    isOpenModal: getIsOpenModalRequestAccountDeleting(state),
  };
}

const connectedComponent = connect<IStateToProps, IDispatchToProps>(mapStateToProps, {
  requestAccountDeletionModelClose,
  requestAccountDeletingSubmit,
})(RequestDeletionModal);

export default withStyles(defaultStyleWidth(320))(connectedComponent);
