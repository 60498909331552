import { converterFromSwagger, converterToSwagger } from '../convertors/work-history';
import * as FilterActions from '../../constants/filter';
import { extractJSONLD, fetchRequestBody } from '../../helpers/request-helper';
import {getApiJSONLD, getApi} from '../swagger/api-factory';
import {IAnyProps} from "../../interfaces/any-props.interface";

export const getItems = (page, itemsPerPage, show, text = '') => {
  const isShow = show || FilterActions.DEFAULT_FILTER;
  const parameters: IAnyProps = { page };

  parameters.show = isShow;

  return getApiJSONLD()
    .then(api => api.getWorkHistoryCollection(parameters))
    .then(extractJSONLD(converterFromSwagger));
};

export const getItem = (id) => {
  return getApi()
    .then(api => api.getWorkHistoryItem({ id }))
    .then(fetchRequestBody)
    .then(converterFromSwagger);
};

export const postItem = (model) => {
  return getApi()
    .then(api => api.postWorkHistoryCollection({
      workHistory: converterToSwagger(model),
    }))
    .then(fetchRequestBody)
    .then(converterFromSwagger);
};

export const putItem = (model) => {
  return getApi()
    .then(api => api.putWorkHistoryItem({
      id: model.id,
      workHistory: converterToSwagger(model),
    }))
    .then(fetchRequestBody)
    .then(converterFromSwagger);
};

export const deleteItem = (id) => {
  return getApi()
    .then(api => api.deleteWorkHistoryItem({
      id,
    }))
    .then(fetchRequestBody);
};
