import {ISpecialism} from "../models/specialism.interface";

export const getSpecialismsFlat = (specialisms: ISpecialism[]): ISpecialism[] => {
  const reduce = (data) => data.reduce((acc, data) => {
    if (data.checked) {
      const childNames = (data.subTypes || []);

      return [...acc, data, ...childNames];
    }

    const childNames2 = reduce(data.subTypes || []);

    return [...acc, ...childNames2];
  }, []);

  const names = reduce(specialisms);

  return names;
};

export const getSpecialismsL1Flat = (specialisms: ISpecialism[]): ISpecialism[] => {
  const reduce = (data) => data.reduce((acc, data) => {
    if (data.checked) {
      return [...acc, data];
    }

    let L1ShouldBeChecked = false;
    data.subTypes.map(subType => {
      if (subType.checked) {
        L1ShouldBeChecked = true;
      }
    });

    if (L1ShouldBeChecked) {
      return [...acc, ...data];
    }

    return acc;
  }, []);

  if (!specialisms) {
    return [];
  }

  const names = reduce(specialisms);

  return names;
};
