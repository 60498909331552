import React from 'react';
import { ITest } from "models/test.interface";
import { Link } from "react-router-dom";

type ViewDetailsProps = {
  test: ITest;
}

export function ViewDetails({test}: ViewDetailsProps) {
  return (
    <Link to={`/client/reports/${test.id}/details/`}>
      <button type="submit" className="btn-view">
        View&nbsp;Details
      </button>
    </Link>
  );
}
