import getCrudSelectors from "../helpers/selector-helper";
import { createSelector } from "reselect";
import { RootState } from "reducers";
import { IUsers } from "models/users.interface";

const getUsers = (state: RootState) => state.users;

const crudSelectors = getCrudSelectors<IUsers>(getUsers);

const getFilterName = state => state.name;
const getFilterEmail = state => state.email;
const getFilterPhone = state => state.phone;
const getFilterDate = state => state.date;

const getFilterTextName = createSelector(
  crudSelectors.getFilter,
  getFilterName
);

const getFilterTextEmail = createSelector(
  crudSelectors.getFilter,
  getFilterEmail
);

const getFilterTextPhone = createSelector(
  crudSelectors.getFilter,
  getFilterPhone
);

const getFilterTextDate = createSelector(
  crudSelectors.getFilter,
  getFilterDate
);

export const getExtraPermissions = state =>
  state.users.permissions.extraPermissions;

export default crudSelectors;
