export type CONTENT_MANAGEMENT_KEY =  'TermsAndConditions' |
                                      'PrivacyPolicy' |
                                      'AboutAvord' |
                                      'ClientBookingTerm' |
                                      'RulesEngagement' |
                                      'GuidanceSchedule' |
                                      'GuidanceRiskChart' |
                                      'UserAgreement';

export const TERMS_CONDITIONS = 'TermsAndConditions' as CONTENT_MANAGEMENT_KEY;
export const PRIVACY_POLICY = 'PrivacyPolicy' as CONTENT_MANAGEMENT_KEY;
export const ABOUT_AVORD = 'AboutAVORD' as CONTENT_MANAGEMENT_KEY;
export const CLIENT_BOOKING_TERM = 'ClientBookingTerm' as CONTENT_MANAGEMENT_KEY;
export const RULES_ENGAGEMENT = 'RulesEngagement' as CONTENT_MANAGEMENT_KEY;
export const GUIDANCE_SCHEDULE = 'GuidanceSchedule' as CONTENT_MANAGEMENT_KEY;
export const GUIDANCE_RISK_CHART = 'GuidanceRiskChart' as CONTENT_MANAGEMENT_KEY;
export const USER_AGREEMENT = 'UserAgreement' as CONTENT_MANAGEMENT_KEY;
