import * as Types from '../constants/action-type';
import {
    createFilterAction,
    createLoaderAction,
    createRequestAction
} from '../helpers/action-helper';

export const faqFilter = createFilterAction(Types.FAQ);
export const faqLoader = createLoaderAction(Types.FAQ);
export const faqRequest = createRequestAction(Types.FAQ);
