import * as React from 'react';
import {SpecialismUpdateListElement} from "./SpecialismUpdateListElement";
import {IAnyProps} from "../../../../interfaces/any-props.interface";
import {WrappedFieldArrayProps} from "redux-form/lib/FieldArray";
import {ISpecialism} from "../../../../models/specialism.interface";
import Paper from "@material-ui/core/Paper/Paper";

export const SpecialismUpdateList: React.SFC<WrappedFieldArrayProps<ISpecialism | IAnyProps> & {level: number}> =
  ({ fields, level = 0}) => {
  return (
    <ul className="specialisms-list">
      {fields.map((member, index) => {
        const listElement = <SpecialismUpdateListElement
          key={index}
          fields={fields}
          index={index}
          level={level}
          member={member}
        />;

        if (!level) {
          return (
            <Paper className="p-5 mb-3">
              {listElement}
            </Paper>
          );
        }

        return listElement;
      })}
    </ul>
  );
};
