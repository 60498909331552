import React from "react";
import Table from "@material-ui/core/Table/Table";
import TableBody from "@material-ui/core/TableBody/TableBody";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { locationsRequest, locationsLoader } from "../../../../actions/locations";
import selector from "../../../../selectors/locations";
import { TablePagination } from "@material-ui/core";
import EnhancedTableHead from "../../../../components/table/EnhancedTableHead";
import EnhancedTableRow from "../../../../components/table/EnhancedTableRow";
import { isChangesExist } from "../../../../helpers/props-checker";
import debounce from "debounce";
import { getLocationString } from "../../../../helpers/location-helper";
import { Permission } from "constants/permission";
import { Group } from "../../../../constants/group";
import { getUserGroup, getUserPermissions } from "../../../../selectors/auth";

class LocationsList extends React.Component {
  debouncedLoad = debounce((nextProps) => {
    this.loadItems(nextProps);
  }, 500);

  loadItems = (props) => {
    props.loadItems(
      props.pageNumber,
      props.itemsPerPage,
      props.showStatus,
      props.filterText
    );
  };

  onUnarchive = (item) => {
    this.props.unarchiveItem(
      item.id,
      this.props.pageNumber,
      this.props.itemsPerPage,
      this.props.showStatus,
      this.props.filterText
    );
  };

  onArchive = (item) => {
    this.props.archiveItem(
      item.id,
      this.props.pageNumber,
      this.props.itemsPerPage,
      this.props.showStatus,
      this.props.filterText
    );
  };

  componentDidMount() {
    const { group, currentUserPermissions, history } = this.props;

    this.loadItems(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (
      isChangesExist(
        ["pageNumber", "itemsPerPage", "showStatus", "filterText"],
        nextProps,
        this.props
      )
    ) {
      if (isChangesExist(["filterText"], nextProps, this.props)) {
        this.debouncedLoad.clear();
        this.debouncedLoad(nextProps);
      } else {
        this.loadItems(nextProps);
      }
    }
  }

  handleChangePage = (event, page) => {
    this.props.setPageNumber(page + 1);
  };

  handleChangeRowsPerPage = (event) => {
    this.props.setItemsPerPage(event.target.value);
  };

  render() {
    if (!this.props.isLoaded) {
      return <div className="table-no-items">Loading...</div>;
    }

    if (!this.props.data.length) {
      return <div className="table-no-items">There are no items!</div>;
    }

    return (
      <div className="table-responsive styled-table styled-block">
        <Table>
          <EnhancedTableHead
            columns={["Name", "Address"]}
            status={
              this.props.currentUserPermissions.includes(
                Permission.CAN_CREATE_EDIT_VIEW_LOCATIONS
              ) || this.props.group === Group.OWNER
                ? this.props.showStatus
                : ""
            }
          />
          <TableBody>
            {this.props.data.map((item) => {
              return (
                <EnhancedTableRow
                  key={item.id}
                  item={item}
                  baseUrl={this.props.baseUrl}
                  showStatus={this.props.showStatus}
                  onArchive={this.onArchive}
                  onUnarchive={this.onUnarchive}
                  needActions={
                    this.props.currentUserPermissions.includes(
                      Permission.CAN_CREATE_EDIT_VIEW_LOCATIONS
                    ) || this.props.group === Group.OWNER
                  }
                >
                  {item.name}
                  {getLocationString(item)}
                </EnhancedTableRow>
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={this.props.totalItems}
          rowsPerPage={this.props.itemsPerPage}
          page={this.props.pageNumber - 1}
          backIconButtonProps={{ "aria-label": "Previous Page" }}
          nextIconButtonProps={{ "aria-label": "Next Page" }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    data: selector.getItems(state),
    isLoaded: selector.getIsLoaded(state),
    itemsPerPage: selector.getItemsPerPage(state),
    pageNumber: selector.getPageNumber(state),
    showStatus: selector.getFilterActivityStatus(state),
    totalItems: selector.getTotalItems(state),
    filterText: selector.getFilterTextFields(state),
    group: getUserGroup(state),
    currentUserPermissions: getUserPermissions(state),
  }),
  {
    loadItems: locationsRequest.getItems,
    setItemsPerPage: locationsLoader.setItemsPerPage,
    setPageNumber: locationsLoader.setPageNumber,
    archiveItem: locationsRequest.archiveItem,
    unarchiveItem: locationsRequest.unarchiveItem,
  }
)(withRouter(LocationsList));
