import React, { useState } from "react";

type ProjectsCellProps = {
  projects: string[];
};

export const ProjectsCell = ({projects = []}: ProjectsCellProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  if (!projects.length) {
    return null;
  }

  const shouldExpand = projects.length > 5;
  const projectsArray =
    !isExpanded && shouldExpand ? projects.slice(0, 5) : projects;

  const label = !isExpanded ? "Show all..." : "Hide";

  return (
    <div className="show-more-cell">
      {`${projectsArray.join("; ")}; `}
      {shouldExpand && (
        <button onClick={() => setIsExpanded(!isExpanded)}>
          <span>{label}</span>
        </button>
      )}
    </div>
  );
};
