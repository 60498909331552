import * as React from "react";
import { Link, Route, Switch } from "react-router-dom";
import TestersList from "./TestersList";
import TesterDetails from "./TesterDetails";
import { withRouter } from "react-router-dom";
import TestersSearchForm from "./TestersSearchForm";

class Testers extends React.Component<any, {}> {
  baseUrl = this.props.match.path;

  testerList = () => (
    <div className="pb-4">
      <TestersSearchForm />
      <TestersList baseUrl={this.baseUrl} />
    </div>
  );

  testerDetails = () => <TesterDetails backRoute={this.baseUrl} />;

  render() {
    return (
      <Switch>
        <Route exact path={this.baseUrl} render={this.testerList} />
        <Route path={this.baseUrl + "/:id"} render={this.testerDetails} />
      </Switch>
    );
  }
}

export default withRouter(Testers);
