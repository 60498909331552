import React from "react";
import Table from "@material-ui/core/Table/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import classnames from "classnames";
import moment from "moment";
import {
  ConsultantProfileType,
  TIME_SHEETS_USER_TYPE,
  TimeExpensesWeekType,
  TimesheetPoNumberRoute
} from "types/timesheets-and-expenses";
import TableBody from "@material-ui/core/TableBody/TableBody";
import Paper from "@material-ui/core/Paper";
import ConsultantDayCell from "components/timesheets-and-expenses/components/TableCells/ConsultantDayCell";
import WeekHeadingCheckbox from "components/timesheets-and-expenses/components/TableCells/WeekHeadingCheckbox";
import WeekProjectCheckbox from "components/timesheets-and-expenses/components/TableCells/WeekProjectCheckbox";
import { TE_STATUS } from "constants/timesheet-expenses";
import { getTotalDaysAndExpenses } from "helpers/timesheets-expenses";
import TotalCell from "components/timesheets-and-expenses/components/TableCells/TotalCell";
import PoNumberCell from "components/timesheets-and-expenses/components/TableCells/PoNumberCell";
import StatusCell from "components/timesheets-and-expenses/components/TableCells/StatusCell";
import { NotesCell } from "components/timesheets-and-expenses/components/TableCells/NotesCell";

type WeekRowProps = {
  days: Date[];
  consultant: ConsultantProfileType;
  reportedWeeks?: TimeExpensesWeekType[];
  yearWeekNumber: string;
};

function getTimesheetWeekHeading(from: Date, to: Date) {
  return `${moment(from).format("MMMM DD, Y")} - ${moment(to).format("MMMM DD, Y")}. Week #${moment(from).isoWeek()}`;
}

export function WeekRow({
  days,
  consultant,
  yearWeekNumber,
  reportedWeeks = []
}: WeekRowProps) {
  return (
    <div className="mb-2 pb-4 mt-2">
      <h4 className="timesheet-week-heading">
        {getTimesheetWeekHeading(days[0], days[days.length-1])}
      </h4>

      <Paper style={{ overflowX: "auto", width: "100%" }}>
        <Table className="timesheet-table">
          <TableHead>
            <TableRow>
              <WeekHeadingCheckbox
                projectsWeeks={reportedWeeks}
                userType={TIME_SHEETS_USER_TYPE.CONSULTANT}
              />
              <TableCell>Project</TableCell>
              <TableCell>Manager</TableCell>
              {days.map((date, index) => {
                const dayClassNames = classnames("timesheet-cell-week-day", {
                  weekend: index > 4
                });

                const day = moment(date).format("ddd DD/MMM");

                return (
                  <TableCell
                    className={dayClassNames}
                    key={day}
                    style={{ minWidth: 75 }}
                  >
                    {day}
                  </TableCell>
                );
              })}
              <TableCell style={{ minWidth: 75 }}>Total</TableCell>
              <TableCell align="center" style={{ minWidth: 120 }}>
                <Tooltip
                  title={
                    <span style={{ fontSize: 14 }}>Purchase Order Number</span>
                  }
                  placement={"top-start"}
                >
                  <span>PO Number</span>
                </Tooltip>
              </TableCell>
              <TableCell align="center" style={{ minWidth: 120 }}>
                <Tooltip
                  title={
                    <span style={{ fontSize: 14 }}>
                      Expenses Purchase Order Number
                    </span>
                  }
                  placement={"top-start"}
                >
                  <span>Expenses PO</span>
                </Tooltip>
              </TableCell>
              <TableCell align="center" width={85}>
                Status
              </TableCell>
              <TableCell className="timesheet-cell-notes">Notes</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(consultant.projects || []).map((project, index) => {
              const reportedWeek = reportedWeeks.find(
                item => item.project === project.project.id
              );

              const {
                totalDays,
                totalExpensesAmount
              } = getTotalDaysAndExpenses(reportedWeek?.days || []);

              return (
                <TableRow key={index}>
                  <WeekProjectCheckbox
                    projectWeekId={reportedWeek?.id}
                    status={reportedWeek?.status || TE_STATUS.STATUS_DEFAULT}
                    totalDays={totalDays}
                    totalExpensesAmount={totalExpensesAmount}
                    userType={TIME_SHEETS_USER_TYPE.CONSULTANT}
                  />
                  <TableCell>{project?.project?.name}</TableCell>
                  <TableCell>{reportedWeek?.managerInfo?.name ? reportedWeek.managerInfo.name : project?.project?.manager_info?.name}</TableCell>
                  {days.map((date, index) => {
                    const report = reportedWeek?.days.find(item => {
                      return (
                        item.date === moment(date).format("YYYY-MM-DD") &&
                        project.project.id === item.project
                      );
                    }) || {
                      day: `${moment(date).format("YYYY-MM-DD")}-${
                        project.project.id
                      }`,
                      project: project.project.id,
                      days: 0
                    };

                    return (
                      <TableCell
                        className={classnames("timesheet-cell-week-day", {
                          weekend: index > 4
                        })}
                        key={moment(date).format("ddd DD/MMM")}
                        style={{ minWidth: 75 }}
                      >
                        <ConsultantDayCell
                          {...report}
                          status={
                            reportedWeek?.status || TE_STATUS.STATUS_DEFAULT
                          }
                        />
                      </TableCell>
                    );
                  })}
                  <TableCell className="timesheet-cell-total">
                    <TotalCell
                      isActiveWeek
                      totalExpensesAmount={totalExpensesAmount}
                      totalDays={totalDays}
                      weekId={
                        reportedWeek?.yearWeek ||
                        `${yearWeekNumber}-${project.project.id}-${consultant.id}`
                      }
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    className="timesheet-cell-po-number"
                  >
                    <PoNumberCell
                      statusId={
                        reportedWeek?.status || TE_STATUS.STATUS_DEFAULT
                      }
                      weekId={reportedWeek?.id as number}
                      type={TimesheetPoNumberRoute.PO_NUMBER}
                      poNumberValue={reportedWeek?.purchaseOrderNumber || null}
                      hasTotalDays={!!totalDays}
                      hasTotalExpenses={!!totalExpensesAmount}
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    className="timesheet-cell-po-number"
                  >
                    <PoNumberCell
                      statusId={
                        reportedWeek?.status || TE_STATUS.STATUS_DEFAULT
                      }
                      weekId={reportedWeek?.id as number}
                      type={TimesheetPoNumberRoute.EXPENSES_PO_NUMBER}
                      poNumberValue={
                        reportedWeek?.expensesPurchaseOrderNumber || null
                      }
                      hasTotalDays={!!totalDays}
                      hasTotalExpenses={!!totalExpensesAmount}
                    />
                  </TableCell>
                  <TableCell align="center" width={70}>
                    <StatusCell
                      statusId={
                        reportedWeek?.status || TE_STATUS.STATUS_DEFAULT
                      }
                      weekId={reportedWeek?.id}
                      weekNumber={yearWeekNumber.substring(4)}
                      consultantName={reportedWeek?.consultantName || ""}
                      isEmptyTimeAndExpenses={false}
                      invoiceNumber={reportedWeek?.invoiceNumber}
                      expensesInvoiceNumber={
                        reportedWeek?.expensesInvoiceNumber
                      }
                    />
                  </TableCell>
                  <TableCell className="timesheet-cell-notes">
                    <NotesCell
                      notes={reportedWeek?.notesThread.reverse() || []}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
}
