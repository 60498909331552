import React from 'react';
import { composeValidators, fieldsNotEmpty, fieldMaxLength } from "../../../../helpers/validations/validation";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import { sectorRequest } from "../../../../actions/sector";
import parameterFetcher from '../../../../components/parametersFetcher';
import selector from '../../../../selectors/sector';
import { SectorForm } from "./SectorForm";
import routerModal from '../../../../components/routerModal';

const FORM_NAME = 'SectorUpdate';

class SectorUpdate extends React.Component {
  render() {
    const { handleSubmit, putData, backRoute } = this.props;

    return (
      <form noValidate autoComplete="off"
        onSubmit={handleSubmit((values) => {
          putData(values, FORM_NAME, backRoute);
        })}>
        <SectorForm backRoute={backRoute}/>
      </form>
    )
  }
}

const formConnected = reduxForm({
  form: FORM_NAME,
  validate: composeValidators(
    fieldsNotEmpty(['name',]),
    fieldMaxLength(['name'], 100),
  )
})(SectorUpdate);

const connected = parameterFetcher(connect(undefined, {
  putData: sectorRequest.putItem,
})(formConnected), FORM_NAME, sectorRequest.getItem, selector.getItemById);

export default routerModal(connected);
