import { getApi, getApiJSON, getApiJSONLD } from "../swagger/api-factory";
import { extractJSONLD, fetchRequestBody } from "../../helpers/request-helper";
import {
  convertorFromSwagger,
  convertorToSwagger
} from "../convertors/default-convertor";
import * as FilterActions from "../../constants/filter";
import { IAnyProps } from "../../interfaces/any-props.interface";
import { convertorSubUserToSwagger } from "../convertors/sub-user";

export const getItems = (
  page,
  itemsPerPage,
  show,
  name,
  email,
  phone,
  userFilter,
  registeredFrom,
  registeredTo,
  userDbsFilter,
  testingCompany,
  userClientCompanyFilter,
  groupName = []
) => {
  const isShow = show || FilterActions.DEFAULT_FILTER;
  const parameters: IAnyProps = { page };

  parameters.items = itemsPerPage;
  parameters.show = isShow;

  if (name) {
    parameters.search = name;
  }

  if (userDbsFilter) {
    parameters.userDbsFilter = userDbsFilter;
  }

  if (email) {
    parameters.email = email;
  }

  if (phone) {
    parameters.phone = phone;
  }

  if (userFilter) {
    parameters.userFilter = userFilter;
  }

  if (registeredFrom) {
    parameters.createdAtAfter = registeredFrom;
  }

  if (registeredTo) {
    parameters.createdAtBefore = registeredTo;
  }

  if (testingCompany) {
    parameters.userTestingCompanyFilter = testingCompany;
  }

  if (userClientCompanyFilter) {
    parameters.userClientCompanyFilter = userClientCompanyFilter;
  }

  if (Array.isArray(groupName) && groupName.length > 0) {
    parameters.groupName = groupName;
  }

  return getApiJSONLD()
    .then(api => api.getUserCollection(parameters))
    .then(extractJSONLD(convertorFromSwagger));
};

export const postItem = model => {
  return getApiJSON()
    .then(api =>
      api.postUserCollection({
        user: convertorToSwagger(model)
      })
    )
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};

export const postSubuserRequest = model => {
  return getApiJSON()
    .then(api =>
      api.postApiUsersCreateSubUser({
        subUser: convertorToSwagger(model)
      })
    )
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};

export const reassignTesterRequest = (from, to) => {
  return getApiJSON()
    .then(api =>
      api.postApiTestsReassign({
        planners: {
          planner_from_id: from,
          planner_to_id: to
        }
      })
    )
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};

export const reassignCompanyTester = (from, to) => {
  return getApiJSON()
    .then(api =>
      api.reassignCompanyTester({
        testers: {
          tester_from_id: from,
          tester_to_id: to
        }
      })
    )
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};

export const putSubuserRequest = (id, model) => {
  return getApiJSON()
    .then(api =>
      api.putApiUsersClientUserById({
        id,
        user: convertorSubUserToSwagger(model)
      })
    )
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};

export const getItem = (id: string | number) => {
  return getApi()
    .then(api => api.getUserItem({ id }))
    .then(fetchRequestBody);
};

export const putItem = model => {
  return getApi()
    .then(api =>
      api.putUserItem({
        id: model.id,
        user: { ...model }
      })
    )
    .then(fetchRequestBody);
};

export const archiveItem = (id: string | number) => {
  return getApi().then(api =>
    api.archiveUserItem({
      id,
      user: {}
    })
  );
};

export const unarchiveItem = (id: string | number) => {
  return getApi().then(api =>
    api.unarchiveUserItem({
      id,
      user: {}
    })
  );
};

export const deleteItem = (id: string | number) => {
  return getApi().then(api => api.deleteUserItem({ id }));
};

export const reset2FA = id => {
  return getApi().then(api => api.sendReset2FA({ id }));
};

export const getDynamicPermissions = () => {
  return getApi().then(api => api.getDynamicPermissions());
};

export const changeTesterRoleRequest = (
  testerId,
  userRole,
  testingCompanyId,
  newTestingCompany
) => {
  if (userRole === "AVORD Tester") {
    return getApi().then(api =>
      api.createCompanyManager({
        testerId,
        testingCompany: { testingCompanyId, newTestingCompany }
      })
    );
  }
  return getApi().then(api => api.deleteCompanyManager({ testerId }));
};

export const changeReceiveBookingRequestsRequest = (
  testerProfileId,
  available_for_booking
) => {
  return getApi().then(api =>
    api.changeReceiveBookingRequests({
      testerProfileId,
      availableForBooking: { available_for_booking }
    })
  );
};

export const sendInviteToTesterRequest = email => {
  return getApi().then(api => api.sendInviteToTester({ email: { email } }));
};

export const registerTesterByManagerRequest = model => {
  return getApi().then(api => api.registerTesterByManager({ model }));
};

export const updateClientByAdminRequest = (clientAccountId, model) => {
  return getApi().then(api =>
    api.updateClientByAdmin({
      clientAccountId,
      model
    })
  );
};

export const unlinkTesterRequest = id => {
  return getApi().then(api => api.unlinkTester({ id }));
};

export const getUsersConsultants = (filter) => {
    return getApiJSONLD().then((api) => api.getUserCollection({ filter }));
};

export const putAssignConsultantAction = consultant => {
  return getApiJSONLD().then(api => api.postAssignConsultant({ consultant }));
};
