import * as ActionTypes from '../constants/action-type';
import {ITesterProfile} from "../models/tester-profile.interface";
import {IReducerItem} from "../interfaces/reducer-item.interface";
import {loaderItemReducerFactory, mergeReducers} from "../helpers/reducer-helper";
import {combineReducers} from "redux";
import {IBankDatails} from "../models/bank-datails.interface";
import {ISpecialism} from "../models/specialism.interface";

export interface ITesterProfileState {
  profile: IReducerItem<ITesterProfile>;
  bankDetails: IReducerItem<IBankDatails>;
  specialisms: IReducerItem<ISpecialism[]>;
  specialismsEditOpen: boolean;
}

const specialismsOpenReducer = (state = false, action) => {
  switch (action.type) {
    case ActionTypes.TESTER_SPECIALISMS_EDIT_OPEN:
      return true;
    case ActionTypes.TESTER_SPECIALISMS_EDIT_CLOSE:
      return false;
    default:
      return state;
  }
};

const changeReceiveBookingRequestsReducer = (state={}, action) => {
  switch(action.type) {
    case ActionTypes.CHANGE_RECEIVE_BOOKING_REQUESTS:
      return ({...state, availableForBooking: action.payload });
    default:
      return state;

  }
};

export default combineReducers({
  profile: loaderItemReducerFactory(ActionTypes.TESTER_PROFILE),
  bankDetails: loaderItemReducerFactory(ActionTypes.TESTER_BANK_DETAILS),
  specialisms: loaderItemReducerFactory(ActionTypes.TESTER_SPECIALISMS),
  specialismsEditOpen: specialismsOpenReducer,
});
