import * as Types from "constants/action-type";
import {
  createFilterAction,
  createLoaderAction,
  createRequestAction
} from "helpers/action-helper";
import { Dispatch } from "redux";
import { FilterParamsType } from "types/consultant";
import { setRequestInProcess } from "actions/request";
import { handleHttpErrors } from "helpers/errors/http-error-handler";
import { getItems } from "api/requests/company-projects";

export const projectsFilter = createFilterAction(Types.COMPANY_PROJECTS);
export const projectsLoader = createLoaderAction(Types.COMPANY_PROJECTS);
export const projectsRequest = createRequestAction(Types.COMPANY_PROJECTS);

export const getCompanyProjectsAction = (
  companyId: number | string,
  {
    filterText = "",
    ...params
  }: FilterParamsType & {
    filterText?: string;
    name?: string;
  }
) => async (dispatch: Dispatch) => {
  dispatch(setRequestInProcess(true, "getCompanyProjectsAction"));
  dispatch(projectsLoader.setIsNotLoaded());

  try {
    if (filterText) {
      params.name = filterText;
    }

    if (params.pageNumber !== undefined) {
      params.page = params.pageNumber;
    }

    if (params.itemsPerPage !== undefined) {
      params.items = params.itemsPerPage;
    }

    const { items, totalItems } = await getItems(companyId, params);

    dispatch(
      projectsLoader.setPaginatioData(
        items,
        params?.itemsPerPage || 10,
        totalItems,
        params?.pageNumber || 1
      )
    );
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(setRequestInProcess(false, "getAllProjects"));
};
