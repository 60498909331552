import * as React from "react";
import Paper from "@material-ui/core/Paper";
import { connect } from "react-redux";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {
  Field,
  formValueSelector,
  InjectedFormProps,
  reduxForm
} from "redux-form";
import ReduxRadioButton from "components/forms/ReduxRadioButton";
import * as FilterTypes from "constants/filter";
import { bindActionCreators, Dispatch } from "redux";
import ReduxTextField from "components/forms/ReduxTextField";
import { FieldGeneric } from "components/forms/FieldGeneric";
import { usersFilter } from "actions";
import { isChangesExist } from "helpers/props-checker";
import { RootState } from "reducers";
import { ConnectedProps } from "types";
import ReduxSelectSearchField from "components/forms/ReduxSelectSearchField";
import { Checkbox, ListItemText, MenuItem } from "@material-ui/core";
import { Group } from "constants/group";

export const FORM_NAME = "AdminUsersSearchForm";
const selectorForm = formValueSelector(FORM_NAME);

type FormData = {
  text: string;
  active: string;
  groupName?: string[];
};

type Props = PropsFromRedux;

class AdminUsersSearchForm extends React.Component<
  Props & InjectedFormProps<FormData, {}>,
  {}
> {
  componentWillReceiveProps(nextProps: Props) {
    if (isChangesExist(["valuesData"], nextProps, this.props)) {
      this.props.submitFilterChanges(
        nextProps.valuesData.active,
        nextProps.valuesData.text
      );
    }
  }

  onResetHandler = () => {
    this.props.reset();
  };

  getOptions = () => {
    const options = [
      {
        id: Group.ACCOUNT_MANAGER,
        label: "Account Manager"
      }
    ];

    if (this.props.group === Group.SUPER_ADMIN) {
      return [
        {
          id: Group.ADMIN,
          label: "Admin"
        },
        ...options
      ];
    }

    return options;
  };

  render() {
    const { handleSubmit, groupNamesSelected = [] } = this.props;
    const groupOption = this.getOptions();

    return (
      <form
        className="mb-3"
        onSubmit={handleSubmit(values => {
          this.props.submitFilterChanges(values.text, values.active);
        })}
      >
        <Paper className="pl-4 pr-4 pb-4">
          <div className="row align-items-center">
            <div className="col-xs-12 col-4">
              <Field
                name="text"
                label="Find"
                margin="normal"
                component={ReduxTextField}
              />
            </div>
            <div className="col-xs-12 col-4">
              <FieldGeneric
                name="groupName"
                label="Roles"
                multiple
                component={ReduxSelectSearchField}
                items={groupOption}
                renderItem={(option: { id: string; label: string }) => (
                  <MenuItem key={option.id} value={option.id}>
                    <Checkbox
                      checked={groupNamesSelected.includes(option.id)}
                    />
                    <ListItemText primary={option.label} />
                  </MenuItem>
                )}
                renderValue={(selected: string[]) => {
                  const items = selected.reduce<string[]>((acc, current) => {
                    const search = groupOption.find(({ id }) => id === current);

                    if (!search) {
                      return acc;
                    }

                    return [...acc, search.label];
                  }, []);

                  return items.join(", ");
                }}
              />
            </div>
            <div className="col-xs-12 col-md-4 align-self-end text-right">
              <FieldGeneric
                name="active"
                margin="normal"
                inline
                component={ReduxRadioButton}
              >
                <FormControlLabel
                  value={FilterTypes.ACTIVE}
                  control={<Radio />}
                  label="Active"
                  className="mb-0"
                />
                <FormControlLabel
                  value={FilterTypes.ARCHIVED}
                  control={<Radio />}
                  label="Archived"
                  className="mb-0"
                />
                <FormControlLabel
                  value={FilterTypes.ALL}
                  control={<Radio />}
                  label="Both"
                  className="mb-0"
                />
              </FieldGeneric>
              <button
                className="btn-refresh ml-0 mt-2 d-inline-block"
                type="button"
                onClick={this.onResetHandler}
              >
                Reset Filters
              </button>
            </div>
          </div>
        </Paper>
      </form>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    valuesData: { ...selectorForm(state, "text", "active", "groupName") },
    groupNamesSelected: selectorForm(state, "groupName"),
    group: state.auth.group
  };
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      submitFilterChanges: usersFilter.submitFilterChanges
    },
    dispatch
  );
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const connectForm = reduxForm<FormData, {}>({
  form: FORM_NAME,
  initialValues: {
    groupName: [],
    text: "",
    active: FilterTypes.ACTIVE
  }
})(AdminUsersSearchForm);

export default connector(connectForm);
