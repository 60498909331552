import {Observable} from 'rxjs';
import {catchError, flatMap, switchMap} from 'rxjs/operators';
import {ActionsObservable} from 'redux-observable';
import * as actionType from '../constants/action-type';
import {setRequestInProcess} from '../actions/request';
import {result} from 'lodash';
import {of} from "rxjs/internal/observable/of";
import * as Request from '../api/requests/tester-account';
import {fromPromise} from "rxjs/internal-compatibility";
import {openSnackbar} from "../actions/snackbar";
import {IAction} from "./index";
import {startEndCatch} from "./pipes/errors";
import {requestAccountDeletionModelClose} from "../actions/modals";

export const requestAccountDeletionEpic = (action$: ActionsObservable<IAction>): Observable<any> => {
  return action$
    .ofType(actionType.REQUEST_ACCOUNT_DELETION_MODAL_SUBMIT)
    .pipe(
      switchMap(action => {
        return fromPromise(Request.requestAccountDeletion())
          .pipe(
            flatMap((data) => {
              return [
                openSnackbar('Your request for deletion has been sent', 4000),
                requestAccountDeletionModelClose(),
              ];
            }),
            ...startEndCatch('requestAccountDeletionEpic'),
          );
      }),
      catchError((error) => {
        return of(setRequestInProcess(false, 'requestAccountDeletionEpic'));
      })
    );
};
