import {LOADER_ITEM_TYPE} from "../constants/action-type";
import {createAppActionName} from "../helpers/action-helper";

export interface IReducerItem<T> {
  item: T | null;
  loaded: boolean;
  error: boolean;
}

export function createLoaderReducerItem<T>(): IReducerItem<T> {
  return {
    item: null,
    loaded: false,
    error: false,
  };
}

enum EnumLoader {
  GET     = 'GET',
  LOADED  = 'LOADED',
  ERROR   = 'ERROR',
  ADD     = 'ADD',
  PUT     = 'PUT',
  POST    = 'POST',
}

export function createLoaderActionItem(type: LOADER_ITEM_TYPE) {
  return {
    get: (id?) => createAppActionName(type)(EnumLoader.GET, id),
    loaded: (isLoaded = true) => createAppActionName(type)(EnumLoader.LOADED, isLoaded),
    error: () => createAppActionName(type)(EnumLoader.ERROR),
    add: (item) => createAppActionName(type)(EnumLoader.ADD, item),
    put: (item?) => createAppActionName(type)(EnumLoader.PUT, item),
    post: (item) => createAppActionName(type)(EnumLoader.POST, item),
  };
}
