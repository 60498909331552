import ExtendableBuiltin from '../extandable-builtin';

export default class UnauthorizedError extends ExtendableBuiltin(Error) {
  constructor(message) {
    super();
    this.message = message; 
    this.stack = (new Error()).stack;
    this.name = 'UnauthorizedError';
  }
}
