import { useEffect, useRef } from "react";

export function usePrevValue<T>(value: T, initialState: T): T {
  const ref = useRef(initialState);
  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}
