import * as React from "react";
import { Modal } from "@material-ui/core";
import ReduxTextField from "../../../components/forms/ReduxTextField";
import { Field, reduxForm } from "redux-form";
import Paper from "@material-ui/core/Paper";
import {composeValidators, fieldsNotEmpty, fieldIsEmail} from "../../../helpers/validations/validation";
import {connect} from "react-redux";
import {registerTesterByManager} from "../../../actions/users";
import { IAnyProps } from "../../../interfaces/any-props.interface";
import { result } from "lodash";
import selector from "../../../selectors/users";

interface IStateToProps {
  visible: boolean;
  onClose: any;
  register: any;
}

const mappingErrors: Array<[string, string]> = [
  ["testerProfile.user.email", "email"],
  ["testerProfile.user.phone", "phone"],
  ["testerProfile.user.firstName", "firstName"],
  ["testerProfile.user.lastName", "lastName"],
];

const handleServerError = (error: IAnyProps) => {
  return mappingErrors.reduce((acc, data) => {
    return Object.assign(
      acc,
      result(error, data[0], false) ? { [data[1]]: result(error, data[0]) } : {}
    );
  }, {});
};

const CREATE_FORM_NAME = "createTesterForm";

class CreateTesterModal extends React.Component<IStateToProps & any> {

  handleSubmit=(values)=> {
    this.props.register(
      {...values, isTermsAndConditionsApproved: true},
      CREATE_FORM_NAME,
      handleServerError,
      this.handleClose,
      this.props.reset,
      this.props.pageNumber,
      this.props.itemsPerPage,
      this.props.showStatus,
      this.props.filterText
    );
  }

  render() {
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.props.visible}
        onClose={this.handleClose}
        className="d-flex overflow-auto"
      >
        <div className="styled-modal sm">
          <h4>Create and add tester to the company</h4>
          <hr />
          <form noValidate autoComplete="off" onSubmit={this.props.handleSubmit((values)=>this.handleSubmit(values))}>
              <div className="row">
                <div className="col-md-6">
                  <Field
                    name="firstName"
                    label="First name *"
                    margin="normal"
                    component={ReduxTextField}
                  />
                </div>
                <div className="col-md-6">
                  <Field
                    name="addressLine1"
                    label="Address *"
                    margin="normal"
                    component={ReduxTextField}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Field
                    name="lastName"
                    label="Last name *"
                    margin="normal"
                    component={ReduxTextField}
                  />
                </div>
                <div className="col-md-6">
                  <Field
                    name="town"
                    label="Town/City *"
                    margin="normal"
                    component={ReduxTextField}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Field
                    name="email"
                    label="Email *"
                    margin="normal"
                    component={ReduxTextField}
                  />
                </div>
                <div className="col-md-6">
                  <Field
                    name="state"
                    label="State/Province"
                    margin="normal"
                    component={ReduxTextField}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Field
                    name="phone"
                    label="Telephone *"
                    margin="normal"
                    component={ReduxTextField}
                  />
                </div>
                <div className="col-md-6">
                  <Field
                    name="postCode"
                    label="Post/zip code *"
                    margin="normal"
                    component={ReduxTextField}
                  />
                </div>
              </div>
            <div className="text-right" style={{ marginTop: "10px" }}>
              <button onClick={this.handleClose} className="btn-cancel">Cancel</button>
              <button type="submit" className="btn-save">Add</button>
            </div>
          </form>
        </div>
      </Modal>
    );
  }
  private readonly handleClose = () => {
    this.props.reset();
    this.props.onClose();
  };
}

const connected= connect<any>(
  state => ({
    itemsPerPage: selector.getItemsPerPage(state),
    pageNumber: selector.getPageNumber(state),
    showStatus: selector.getFilterActivityStatus(state),
    totalItems: selector.getTotalItems(state),
    filterText: selector.getFilterTextFields(state)
  }),
  {
    register: registerTesterByManager,
  }
)(CreateTesterModal);

const formed = reduxForm({
  form: CREATE_FORM_NAME,
  validate: composeValidators(
    fieldIsEmail(["email"]),
    fieldsNotEmpty(["email", "postCode", "phone", "town", "lastName", "firstName", "addressLine1"]),
  ),
})(connected);

export default formed as any;
