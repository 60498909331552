import getCrudSelectors from '../helpers/selector-helper';
import {createSelector} from "reselect";

const getTests = state => state.test;

const selectors = getCrudSelectors(getTests);

export const isNameBlockedSelector = createSelector(
  getTests,
  state => state.isNameBlocked
);

export default selectors;
