import * as React from "react";
import {Modal} from "@material-ui/core";

interface IProps {
  open: boolean;
  onClose: () => void;
  src: any;
  title: string;
}

class CertificateModal extends React.Component<IProps> {
  render() {
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.props.open}
        onClose={this.props.onClose}
        className="d-flex overflow-auto"
      >
        <div className="styled-modal lg text-center">
          <button className="btn-cancel float-right" onClick={this.props.onClose}>
            Close
          </button>
          <h4 className="text-left">
            {this.props.title}
          </h4>
          <div className="styled-modal-body pb-4">
            <img src={this.props.src} alt="Certificate" className='certificate-modal_img'/>
          </div>
        </div>
      </Modal>
    );
  }
}

export default CertificateModal;