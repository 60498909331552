import { IConverterFromSwagger } from "./converter.interface";
import { IAnyProps } from "../../interfaces/any-props.interface";
import { IUsers } from "../../models/users.interface";

export const convertorSubUserToSwagger: IConverterFromSwagger<any, any> = (
  modelIn: IUsers & IAnyProps
) => {
  const model: Partial<IAnyProps> = { ...modelIn };

  if (modelIn.userGroup) {
    model.group = modelIn.userGroup;
    model.groupName = modelIn.userGroup;
  }

  return model;
};
