import React from "react";
import { Field, FieldArray } from "redux-form";
import ReduxTextField from "components/forms/ReduxTextField";
import { handleSpaceKeyPress } from "helpers/validations/emptyTestFieldValidation";
import {
  handleKeyPressOnNumberField,
  handleKeyPressOnPhoneField
} from "helpers/validations/numberFieldValidator";
import Tooltip from "@material-ui/core/Tooltip";
import { Card, CardContent, CardHeader, Icon, Zoom } from "@material-ui/core";
import ProjectsRepeater from "containers/admin/consultants/components/ProjectsRepeater";

export function ConsultantFields() {
  return (
    <div className="consultant-fields">
      <Card className="consultant-fields__card">
        <CardHeader title="Profile" />
        <CardContent>
          <Field
            name="companyName"
            label="Company Name"
            component={ReduxTextField}
            margin="dense"
          />
          <Field
            name="firstName"
            label="First name *"
            component={ReduxTextField}
            margin="dense"
          />
          <Field
            name="lastName"
            label="Last Name *"
            component={ReduxTextField}
            margin="dense"
          />
          <Field
            name="email"
            label="Email *"
            component={ReduxTextField}
            margin="dense"
            onKeyPress={handleSpaceKeyPress}
          />
          <Field
            name="phone"
            label="Telephone *"
            type={`tel`}
            component={ReduxTextField}
            margin="dense"
            onKeyPress={handleKeyPressOnPhoneField}
          />
          <Field
            name="consultantRate"
            label="£ Consultant Day Rate *"
            component={ReduxTextField}
            margin="dense"
            normalize={(value: string) => (value ? +value : "")}
            onKeyPress={handleKeyPressOnNumberField}
            inputProps={{ min: 0 }}
            type="number"
            style={{ maxWidth: 250 }}
          />
        </CardContent>
      </Card>
      <Card className="consultant-fields__card">
        <CardHeader
          title={
            <h5 className="d-flex align-items-center">
              Projects
              <Tooltip
                placement="top-start"
                TransitionComponent={Zoom}
                disableFocusListener
                disableTouchListener
                title="If project is not listed, contact company manager."
                className="ml-2"
              >
                <Icon style={{ fontSize: 20 }}>info</Icon>
              </Tooltip>
            </h5>
          }
        />
        <CardContent>
          <FieldArray<{}>
            name="projects"
            component={ProjectsRepeater}
            rerenderOnEveryChange
          />
        </CardContent>
      </Card>
    </div>
  );
}
