export const AUDIT_LOG_ACTIONS = [
  {id: 1,
    name: "LOGIN"
  },
  {id: 2,
    name: "FAILED_LOGIN"
  },
  {id: 3,
    name: "DOWNLOAD_REPORT"
  },
]