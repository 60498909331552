import {ActionsObservable} from "redux-observable";
import {IActionPayload} from "./index";
import {Observable} from "rxjs";
import * as actionType from "../constants/action-type";
import {map, switchMap} from "rxjs/operators";
import {fromPromise} from "rxjs/internal-compatibility";
import {postImage} from "../api/requests/image";
import {createAction} from "redux-actions";
import {startEndCatch} from "./pipes/errors";

export const postImageEpic = (action$: ActionsObservable<IActionPayload<File>>): Observable<any> => {
  return action$
    .ofType(actionType.PROFILE_IMAGE_POST)
    .pipe(
      switchMap((action: IActionPayload<File>)=> {
        return fromPromise(postImage(action.payload))
          .pipe(
            map((data) => {
              return createAction(actionType.PROFILE_IMAGE_POST_COMPLETED, () => data)();
            }),
            ...startEndCatch('postImageEpic'),
          );
      }),
    );
};
