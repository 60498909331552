import * as React from "react";
import moment from "moment";
import DashboardLinesChartSearch from "./search-forms/DashboardLinesChartSearch";
import DashboardCriticalRiskSearch from "./search-forms/DashboardCriticalRiskSearch";
import DashboardColumnChartSearch from "./search-forms/DashboardColumnChartSearch";

export const DashboardChartHeader = (props) => {
  const { downloadPDF, rangeOfDates = {}, caption = '', formName } = props;
  let { dateFrom = '', dateTo = '' } = rangeOfDates;
  const lastDateInRange = moment().endOf('month').format('YYYY-MM-DDTHH:mm:ss').toString();

  dateTo = dateTo ? moment(dateTo).format('YYYY-MM-DDTHH:mm:ss') : lastDateInRange;
  dateFrom = dateFrom ? moment(dateFrom).format('YYYY-MM-DDTHH:mm:ss') : '';

  const handleDownloadPDF = () => {
    downloadPDF({
      dateFrom,
      dateTo
    });
  };

  const getSearchForm = () => {
    const { formName = '' } = props;

    if (formName === 'ColumnChartSearchForm') {
      return <DashboardColumnChartSearch />
    }

    if (formName === 'CriticalRiskSearchForm') {
      return <DashboardCriticalRiskSearch />
    }

    return <DashboardLinesChartSearch />
  }

  return (
    <div className="row">
      <h4 className="block-header col-8">
        {caption}
      </h4>

      <div className="block-header col-4">
        {
          downloadPDF && (
            <button className="btn-download-pdf pull-right" onClick={handleDownloadPDF} >
              {'Download PDF'}
            </button>
          )
        }
      </div>

      {getSearchForm()}
    </div>
  );
};
