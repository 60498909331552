import * as React from "react";
import { Link } from "react-router-dom";
import { IUsers } from "models/users.interface";
import { DBS_STATUS } from "constants/dbs-certificate-statuses";

type DbsStatusProps = {
  user: IUsers, dbsStatus?: DBS_STATUS,
};

export function DbsStatus({user, dbsStatus}: DbsStatusProps) {
  const dbsCellStyle = dbsStatus ? {color: dbsStatus.color} : {};

  function getChildren() {
    if (user.deletedAt) {
      return dbsStatus ? dbsStatus.name : "";
    }

    return <Link
      to={"/admin/testers/" + user.testerProfileId}
    >
      {dbsStatus ? dbsStatus.name : ""}
    </Link>;
  }

  return <div style={dbsCellStyle}>
    {getChildren()}
  </div>
}
