import React, { useCallback } from "react";
import Icon from "@material-ui/core/Icon/Icon";
import { OnReset } from "../constants";
import { IUsers } from "models/users.interface";
import { ConfirmWrapper } from "./ConfirmWrapper";

type ArchiveProps = {
  user: IUsers,
  onUnArchive: OnReset;
  onArchive: OnReset;
};

export function Archive({ user, onArchive, onUnArchive}: ArchiveProps) {
  const onConfirm = useCallback(() => {
    if (user.deletedAt) {
      onUnArchive(user);
      return;
    }

    onArchive(user);
  }, [user]);

  return <ConfirmWrapper onConfirm={onConfirm}>
      <Icon style={{color: "#778596"}}>
        {user.deletedAt ? "unarchive" : "archive"}
      </Icon>
  </ConfirmWrapper>
}
