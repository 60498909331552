import React, { useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { TableNoItems } from "components/table/TableNoItems";
import { ConsultantFields } from "containers/admin/consultants/components/ConsultantFields";
import { InjectedFormProps, reduxForm } from "redux-form";
import { FormValuesType } from "types/consultant";
import { CONSULTANT_FORM_NAME } from "constants/consultant";
import {
  composeValidators,
  fieldIsEmail,
  fieldsNotEmpty,
  projectsRepeaterValidation
} from "helpers/validations/validation";

type ConsultantFormProps = {
  onSubmit: (values: FormValuesType) => void;
  isLoading?: boolean;
  goBack: () => void;
  initialValues?: FormValuesType;
};

const ConsultantForm = ({
  isLoading = false,
  onSubmit,
  goBack,
  handleSubmit,
  valid,
  submitting,
  initialValues,
  change
}: ConsultantFormProps & InjectedFormProps<FormValuesType>) => {
  useEffect(() => {
    if (!initialValues) {
      return;
    }

    Object.entries(initialValues).forEach(([key, value]) => {
      change(key, value);
    });
  }, []);

  return (
    <form
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(values => onSubmit(values))}
    >
      <div className="row align-items-center justify-content-between">
        <div className="col-auto">
          <h4>Manage Consultant</h4>
        </div>
        <div className="col-auto">
          <button className="btn-cancel" type="button" onClick={goBack}>
            Cancel
          </button>
          <button
            className="btn-save"
            type="submit"
            disabled={!valid || submitting || isLoading}
          >
            Save
          </button>
        </div>
      </div>
      {isLoading && (
        <Paper className="mt-4 pt-4 pb-4">
          <TableNoItems asLoading />
        </Paper>
      )}
      {!isLoading && <ConsultantFields />}
    </form>
  );
};

export default reduxForm<FormValuesType, ConsultantFormProps>({
  form: CONSULTANT_FORM_NAME,
  validate: composeValidators(
    fieldsNotEmpty([
      "firstName",
      "lastName",
      "email",
      "phone",
      "projects",
      "consultantRate"
    ]),
    fieldIsEmail(["email"]),
    projectsRepeaterValidation()
  )
})(ConsultantForm);
