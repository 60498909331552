import * as React from 'react';
import Paper from "@material-ui/core/Paper/Paper";
import {connect} from "react-redux";
import {specialismsRequest, specialismsLoader} from "../../../../actions/specialisms";
import selector from "../../../../selectors/specialisms";

interface IParams{
  backRoute: string;
}

interface IMapProps {
  data: any;
  isLoaded: any;
  itemsPerPage: any;
  pageNumber: any;
  showStatus: any;
  totalItems: any;
  filterText: any;
}

interface IDispatchProps {
  loadItems: any;
  setItemsPerPage: any;
  setPageNumber: any;
  archiveItem: any;
}


class SpecialismList extends React.Component<IParams & IMapProps & IDispatchProps, {}> {
  loadItems = (props) => {
    props.loadItems(
      props.pageNumber,
      props.itemsPerPage,
      props.showStatus,
      props.filterText
    );
  };

  componentDidMount() {
    this.loadItems(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.pageNumber !== this.props.pageNumber
      || nextProps.itemsPerPage !== this.props.itemsPerPage
      || nextProps.showStatus !== this.props.showStatus
      || nextProps.filterText !== this.props.filterText
    ) {
      this.loadItems(nextProps);
    }
  }


  render() {
    if (!this.props.isLoaded) {
      return (
        <div>
          Loading
        </div>
      );
    }

    if (!this.props.data.length) {
      return (
        <div>
          There are no items!
        </div>
      );
    }

    return (
      <div className="separated-list">
        {this.props.data.map((item) => (
          <div className="row" key={item.id}>
            <div className="col-md-12">
              <Paper className="p-5 mb-3">
                <div className="row">
                  <div className="col-md-4">
                    <h6>{item.name}</h6>
                  </div>
                  <div className="col-md-8">
                    <p>{item.description}</p>
                  </div>
                </div>
                {!!item.subTypes.length && <hr/>}
                <div className="subtypes">
                  {item.subTypes.map((item) => (
                    <div className="row" key={item.id}>
                      <div className="col-md-3">
                        <h6>{item.name}</h6>
                      </div>
                      <div className="col-md-9">
                        <p>{item.description}</p>
                      </div>
                    </div>)
                  )}
                </div>
              </Paper>
            </div>
          </div>)
        )}
      </div>
    );
  }
}

export default connect<IMapProps, IDispatchProps>((state) => {
  return {
    data: selector.getItems(state),
    isLoaded: selector.getIsLoaded(state),
    itemsPerPage: selector.getItemsPerPage(state),
    pageNumber: selector.getPageNumber(state),
    showStatus: selector.getFilterActivityStatus(state),
    totalItems: selector.getTotalItems(state),
    filterText: selector.getFilterTextFields(state),
  };
}, {
  loadItems: specialismsRequest.getItems,
  setItemsPerPage: specialismsLoader.setItemsPerPage,
  setPageNumber: specialismsLoader.setPageNumber,
  archiveItem: specialismsRequest.archiveItem,
})(SpecialismList);
