import React from "react";
import {
  AppBar,
  ListItemText,
  List,
  ListItem,
  Divider
} from "@material-ui/core";
import { NavLink, Redirect, Route, Switch } from "react-router-dom";
import AdminUsers from "./admin-users/AdminUsers";
import Carousel from "./carousel/Carousel";
import ContentManagement from "./content/ContentManagement";
import EmailTemplates from "./email-templates/EmailTemplates";
import Country from "./countries/Country";
import Sector from "./sector/Sector";
import Certificate from "./certificate/Certificate";
import Finance from "./finance/Finance";
import Faq from "./faq/Faq";
import ReportTemplates from "./report-templates/ReportTemplates";
import Specialism from "./specialism/Specialism";
import TimeLogCategories from "./timesheet-time-categories/TimeLogCategories";
import ExpensesTypes from "./timesheet-expenses-types/ExpensesTypes";
import { adminMenuItems } from "constants/menus";
import { connect } from "react-redux";
import { Permission } from "constants/permission";
import PermissionBlock from "components/auth/PermissionBlock";
import ApplicationSetting from "./application-settings/ApplicationSetting";
import { ROLE_ADMIN, ROLE_SUPER_ADMIN } from "constants/roles";

class Settings extends React.Component {
  renderItem = item => {
    return (
      <ListItem
        button
        divider
        component={NavLink}
        to={item.url}
        key={item.url}
        activeStyle={{ backgroundColor: "rgba(0, 0, 0, 0.08)" }}
      >
        <ListItemText primary={item.title} />
      </ListItem>
    );
  };

  render() {
    const baseUrl = this.props.match.path;

    return (
      <div className="row">
        <div className="col-md-3 pb-5 col-lg-2">
          <AppBar position="static" color="default">
            <List component="nav">
              <Divider />
              <PermissionBlock permissions={[Permission.CAN_MANAGE_ADMINS]}>
                <ListItem
                  button
                  divider
                  component={NavLink}
                  to={baseUrl + "/admin-users"}
                  key={baseUrl + "/admin-users"}
                  activeStyle={{ backgroundColor: "rgba(0, 0, 0, 0.08)" }}
                >
                  <ListItemText primary="Admin Users" />
                </ListItem>
              </PermissionBlock>
              {adminMenuItems(baseUrl).map(this.renderItem)}
            </List>
          </AppBar>
        </div>
        <div className="col-md-9 col-lg-10">
          <PermissionBlock roles={[ROLE_SUPER_ADMIN, ROLE_ADMIN]}>
            <Switch>
              <Redirect
                from={"/admin/settings/"}
                exact
                to={"/admin/settings/admin-users"}
              />
              <Route
                path={"/admin/settings/admin-users"}
                component={AdminUsers}
              />
              <Route path={"/admin/settings/carousel"} component={Carousel} />
              <Route
                path={"/admin/settings/content"}
                component={ContentManagement}
              />
              <Route
                path={"/admin/settings/email-templates"}
                component={EmailTemplates}
              />
              <Route path={"/admin/settings/countries"} component={Country} />
              <Route path={"/admin/settings/sector"} component={Sector} />
              <Route
                path={"/admin/settings/timesheet-categories"}
                component={TimeLogCategories}
              />
              <Route
                path={"/admin/settings/expenses-types"}
                component={ExpensesTypes}
              />
              <Route
                path={"/admin/settings/certificate"}
                component={Certificate}
              />
              <Route path={"/admin/settings/finance"} component={Finance} />
              <Route path={"/admin/settings/faq"} component={Faq} />
              <Route
                path={"/admin/settings/report-templates"}
                component={ReportTemplates}
              />
              <Route
                path={"/admin/settings/specialisms"}
                component={Specialism}
              />
              <Route
                path={"/admin/settings/application-settings"}
                component={ApplicationSetting}
              />
            </Switch>
          </PermissionBlock>
        </div>
      </div>
    );
  }
}

export default connect(state => {
  return {
    auth: state.auth
  };
})(Settings);
