import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
type LinkCellProps = {
  baseUrl: string;
  title: ReactNode;
};

export const LinkCell = ({ baseUrl, title }: LinkCellProps) => (
  <Link to={baseUrl}>{title}</Link>
);
