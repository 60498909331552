import * as React from 'react';
import { composeValidators, fieldsNotEmpty } from "../../../../helpers/validations/validation";
import {InjectedFormProps, reduxForm} from "redux-form";
import { connect } from "react-redux";
import { carouselRequest, putItemCarousel } from "../../../../actions/carousel";
import parameterFetcher from '../../../../components/parametersFetcher';
import selector from '../../../../selectors/carousel';
import CarouselForm from "./CarouselForm";
import routerModal from '../../../../components/routerModal';
import {WithStyles} from "@material-ui/core/styles/withStyles";

const FORM_NAME = 'CarouselUpdate';

class CarouselUpdate extends React.Component<InjectedFormProps<{}, {}> & {
  putData: (...any) => void,
  backRoute: string
} & WithStyles<any>, any> {
  render() {
    const { handleSubmit, putData, backRoute } = this.props;

    return (
      <form noValidate autoComplete="off"
        onSubmit={handleSubmit((values) => {
          putData(values, FORM_NAME, backRoute);
        })}>
        <CarouselForm backRoute={backRoute}/>
      </form>
    )
  }
}

const formConnected = reduxForm({
  form: FORM_NAME,
  validate: composeValidators(
    fieldsNotEmpty(['imagePath', 'position',]),
  )
})(CarouselUpdate);

const connected = parameterFetcher(
  connect(undefined, {
    putData: putItemCarousel,
  })(formConnected),
  FORM_NAME,
  carouselRequest.getItem,
  selector.getItemById
);

export default routerModal(connected);