import React from 'react';
import ReduxSelectSearchField from "components/forms/ReduxSelectSearchField";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import { Checkbox } from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import { Field } from "redux-form";


type Props = {
  name: string;
  options: { id: number; name: string }[],
  value?: number
}

export function TypeSelect({name, options, value}: Props) {
  return <Field
    label="Type"
    name={name}
    component={ReduxSelectSearchField}
    items={options}
    renderItem={(option: { id: number; name: string }) => (
      <MenuItem key={option.id} value={option.id}>
        <Checkbox checked={value === option.id} />
        <ListItemText primary={option.name} />
      </MenuItem>
    )}
    renderValue={(selectedKey: number) => options.find(
      item => item.id === selectedKey
    )?.name}
  />
}
