import * as Types from '../constants/action-type';
import {openSnackbar} from "./snackbar";
import {setRequestInProcess} from "./request";
import {getLastQuestionRequest} from "../api/requests/dashboard";


const getLastQuestion = (parameters = {}) => async dispatch => {
  dispatch(setRequestInProcess(true, 'lastQuestion'));

  let data;

  try {
    data = await getLastQuestionRequest(parameters = {})
      .then(res => {
        dispatch({type: Types.LAST_QUESTION, payload: res});
      });
  } catch (error) {
    dispatch(setRequestInProcess(false, 'lastQuestion'));
    dispatch(openSnackbar(error.message, 4000));
  }

  dispatch(setRequestInProcess(false, 'lastQuestion'));

  return data;
};

export default getLastQuestion;