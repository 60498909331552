import {Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {ActionsObservable} from 'redux-observable';
import * as actionType from '../constants/action-type';
import {result} from 'lodash';
import * as Request from '../api/requests/content-management';
import {fromPromise} from "rxjs/internal-compatibility";
import {IAction} from "./index";
import {startEndCatch} from "./pipes/errors";
import {createCrudAction} from "../helpers/action-helper";
import {CONTENT_MANAGEMENT} from "../constants/action-type";

export const getContentManagementByKeyEpic = (action$: ActionsObservable<IAction>): Observable<any> => {
  return action$
    .ofType(actionType.CONTENT_MANAGEMENT_GET_BY_KEY)
    .pipe(
      switchMap((action)=> {
        return fromPromise(Request.getItemByKey(action.payload))
          .pipe(
            map((data) => {
              return createCrudAction(CONTENT_MANAGEMENT).addItem(data);
            }),
            ...startEndCatch('getContentManagementByKeyEpic'),
          );
      }),
    );
};
