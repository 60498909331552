import * as React from 'react';
import {Paper, MenuItem, ListItemText, Checkbox, Button} from "@material-ui/core";
import {connect} from 'react-redux';
import {testerBookingsFilter} from "../../../actions/tester-bookings";
import {formValueSelector, InjectedFormProps, reduxForm} from 'redux-form';
import {FieldGeneric} from '../../../components/forms/FieldGeneric';
import {bindActionCreators} from 'redux';
import ReduxSelectField from "../../../components/forms/ReduxSelectField";
import {getTestStatusNameForTesterBookingsTab, TestStatus} from "../../../constants/test-status";
import selector from "../../../selectors/users";
import ReduxDatePicker from "../../../components/forms/ReduxDatePicker";

export const BOOKINGS_FORM_NAME = 'BookingsSearchForm';
const selectorForm = formValueSelector(BOOKINGS_FORM_NAME);
export const ALL_STATUSES_BOOKINGS = [
  TestStatus.STATUS_BOOKED,
  TestStatus.STATUS_BOOKING_CANCELLED_BY_CLIENT,
  TestStatus.STATUS_BOOKING_CANCELLED_BY_TESTER,
  TestStatus.STATUS_REPORT_SUBMITTED,
  TestStatus.STATUS_AMENDMENT_SENT,
  TestStatus.STATUS_AMENDMENT_RESPONDED,
  TestStatus.STATUS_REPORT_ACCEPTED,
  TestStatus.STATUS_COMPLETED,
];

type IProps = InjectedFormProps<{}, {}> & {
  submitFilterChanges: (...any) => void,
  valuesData: { active: string, text?: string, [key: string]: any },
  dispatch: (...any) => void,
};

class BookingsSearchForm extends React.Component<IProps, any> {
  renderStatuses = selected => selected
    .map(getTestStatusNameForTesterBookingsTab)
    .join(', ');

  checkStatuses = e => {
    e.stopPropagation();
    this.props.change('status', ALL_STATUSES_BOOKINGS);
  };

  uncheckStatuses = e => {
    e.stopPropagation();
    this.props.change('status', []);
  };

  render() {
    const {handleSubmit} = this.props;

    return (
      <form
        onSubmit={handleSubmit((values: { status: string, dateFrom: string, dateTo: string }) => {
          this.props.submitFilterChanges(values.status, values.dateFrom, values.dateTo);
        })}>
        <Paper className="p-4">
          <div className="row">
            <div className="col-md-6">
              <FieldGeneric
                name="dateFrom"
                margin="normal"
                label="From"
                InputLabelProps={{
                  shrink: true,
                }}
                component={ReduxDatePicker}
              />
            </div>
            <div className="col-md-6">
              <FieldGeneric
                name="dateTo"
                margin="normal"
                label="To"
                InputLabelProps={{
                  shrink: true,
                }}
                addEndOfDay={true}
                component={ReduxDatePicker}
              />
            </div>
            <div className="col-md-12">
              <FieldGeneric
                name="status"
                label="Status"
                fullWidth
                multiple
                component={ReduxSelectField}
                renderValue={this.renderStatuses}
              >
                <MenuItem key={0}>
                  <Button color="primary" onClick={this.checkStatuses}>Check all</Button>
                  <Button color="primary" onClick={this.uncheckStatuses}>Uncheck all</Button>
                </MenuItem>
                {ALL_STATUSES_BOOKINGS.map((status) => (
                  <MenuItem key={status} value={status}>
                    <Checkbox checked={(this.props.valuesData.status || []).indexOf(status) > -1}/>
                    <ListItemText primary={getTestStatusNameForTesterBookingsTab(status)}/>
                  </MenuItem>
                ))}
              </FieldGeneric>
            </div>
          </div>
        </Paper>
        <br/>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    valuesData: selectorForm(state, 'status', 'dateFrom', 'dateTo', ),
    initialValues: {
      status: ALL_STATUSES_BOOKINGS,
      dateFrom: selector.getFilterTextFields(state),
      dateTo: selector.getFilterTextFields(state),
    },
  };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    submitFilterChanges: testerBookingsFilter.submitFilterChanges,
  }, dispatch);
};

const connectForm = reduxForm({
  form: BOOKINGS_FORM_NAME,
})(BookingsSearchForm);

const connected = connect(mapStateToProps, mapDispatchToProps)(connectForm);

export default connected;