import * as React from "react";
import ExternalProfileCard from "./ExternalProfileCard";
import {
  Route,
  RouteComponentProps,
  Switch,
  withRouter
} from "react-router-dom";
import {WithStyles} from "@material-ui/core";
import {ICountry} from "../../../models/dialing-code.interface";
import {dataFetcher} from "components/dataFetcher";
import {getProfileFromJWT} from "../../../helpers/jwt-checker";
import {logOut} from "../../../actions/common";
import {createLoaderActionItem} from "../../../interfaces/reducer-item.interface";
import * as actionType from "../../../constants/action-type";
import {getProfile} from "../../../selectors/tester-profile";
import {
  ITesterProfile,
  ITesterIncoming,
  ITesterProfileGet,
} from "models/tester-profile.interface";

interface IProps extends WithStyles, RouteComponentProps<any> {
  countries: ICountry[];
  profile: ITesterProfile & ITesterIncoming & ITesterProfileGet;
}

type Props = IProps & WithStyles<any> & RouteComponentProps<any> & any;

class ExternalProfile extends React.Component<Props, {}> {
  baseUrl = this.props.match.path;

  profileDetails = () => {
    return (
      <div>
        <ExternalProfileCard />
      </div>
    );
  };

  render() {
    return (
      <Switch>
        <Route path={this.baseUrl} render={this.profileDetails}/>
      </Switch>
    );
  }
}

const componentWithRouter = withRouter(ExternalProfile);

export default dataFetcher<IProps>(componentWithRouter, [
  {
    key: "profile",
    action: () => {
      const profile = getProfileFromJWT();

      if (!profile) {
        return logOut();
      } else {
        return createLoaderActionItem(actionType.TESTER_PROFILE).get();
      }
    },
    selector: (state, props) => getProfile(state),
    alwaysReceiveFreshData: true
  }
]) as React.ComponentClass<{}>;
