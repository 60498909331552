import * as React from 'react';
import {connect} from 'react-redux';
import {withStyles, Modal, Typography, Grid, Button, WithStyles} from '@material-ui/core';
import {clientAccountSubmit, clientAccountModalClose} from '../../actions';
import {getIsOpenModalClientAccount} from "../../selectors/modal";
import {defaultStyleWidth} from '../../styles/material/popup-style';
import ReduxTextField from "../../components/forms/ReduxTextField";
import {FieldGeneric} from "../../components/forms/FieldGeneric";
import {InjectedFormProps, reduxForm} from "redux-form";
import {composeValidators, fieldIsEmail, fieldsNotEmpty} from "../../helpers/validations/validation";
import {getUserEmail, getUserFirstName, getUserLastName, getUserPhone} from "../../selectors/auth";
import {handleSpaceKeyPress} from "../../helpers/validations/emptyTestFieldValidation";
import {handleKeyPressOnPhoneField} from "../../helpers/validations/numberFieldValidator";

const FORM_NAME = 'ClientAccountModal';

interface IStateToProps {
  isOpenModal: any;
  initialValues: {firstName: string, lastName: string, email: string, phone: string};
}

interface IDispatchToProps {
  clientAccountModalClose: () => any;
  clientAccountSubmit: (firstName: string, lastName: string, email: string, phone: string) => void;
}

type IProps = InjectedFormProps<{}, {}> & IStateToProps & IDispatchToProps & WithStyles<any>;

class ClientAccountModal extends React.Component<IProps> {
  componentWillReceiveProps(nextProps: Readonly<IProps>, nextContext: any): void {
    if (nextProps.isOpenModal && !this.props.isOpenModal) {
      this.props.change('lastName', this.props.initialValues.lastName);
      this.props.change('firstName', this.props.initialValues.firstName);
      this.props.change('email', this.props.initialValues.email);
      this.props.change('phone', this.props.initialValues.phone);
    }
  }

  render() {
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.props.isOpenModal}
        onClose={this.handleClose}
      >
        <form
          noValidate
          autoComplete="off"
          onSubmit={this.props.handleSubmit((values: any) => {
            this.props.clientAccountSubmit(values.firstName, values.lastName, values.email, values.phone);
          })}>
          <div className={this.props.classes.paper}>
            <Typography variant="title" component="h3" id="modal-title">
              User Details
            </Typography>
            <hr/>
            <div>
              <FieldGeneric
                name="firstName"
                label="First Name *"
                component={ReduxTextField}
              />
              <FieldGeneric
                name="lastName"
                label="Last Name *"
                component={ReduxTextField}
              />
              <FieldGeneric
                name="email"
                onKeyPress={handleSpaceKeyPress}
                label="Email *"
                component={ReduxTextField}
              />
              <FieldGeneric
                name="phone"
                label="Telephone *"
                component={ReduxTextField}
                onKeyPress={handleKeyPressOnPhoneField}
              />
            </div>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <div className="d-flex justify-content-between mt-4">
                  <Button
                    variant="contained"
                    color="default"
                    className="mr-5"
                    onClick={this.handleClose}>
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit">
                    Save
                  </Button>
                </div>
              </Grid>
            </Grid>
          </div>
        </form>
      </Modal>
    );
  }

  private readonly handleClose = () => {
    this.props.clientAccountModalClose();
  };
}

function mapStateToProps(state) {
  return {
    isOpenModal: getIsOpenModalClientAccount(state),
    initialValues: {
      firstName: getUserFirstName(state),
      lastName: getUserLastName(state),
      email: getUserEmail(state),
      phone: getUserPhone(state),
    },
  };
}

const fetched = reduxForm({
  form: FORM_NAME,
  validate: composeValidators(
    fieldsNotEmpty(['email', 'phone', 'firstName', 'lastName',]),
    fieldIsEmail(['email',]),
  )
})(ClientAccountModal);

const connectedComponent = connect<IStateToProps, IDispatchToProps>(mapStateToProps, {
  clientAccountModalClose,
  clientAccountSubmit,
})(fetched);

export default withStyles(defaultStyleWidth(320))(connectedComponent as any);
