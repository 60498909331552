import * as React from "react";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Table from "@material-ui/core/Table/Table";
import {TablePagination, TableCell, TableRow, IconButton, Icon, Grid} from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody/TableBody";
import {messageLoader, messageRequest} from "../../../actions/message";
import {IAnyProps} from "../../../interfaces/any-props.interface";
import selector from "../../../selectors/message";
import {messageSearchAction, messageResetAction} from "../../../actions/message";
import EnhancedTableHead from "../../../components/table/EnhancedTableHead";
import {isChangesExist} from "../../../helpers/props-checker";
import {IMessage} from "../../../models/message.interface";
import MessageModal from "./MessageModal";
import {messageModalOpen, messageNewModalOpen} from "../../../actions/modals";
import {formatDateWithTime} from "../../../helpers/date-formatter";

interface IProps {
  messageSearch: (id: string) => void;
  messageReset: (id: string) => void;
  data: IMessage[];
  messageNewModal: () => void;
  messageModal: (item) => void;
}

class MessageTable extends React.Component<IProps & RouteComponentProps<any> & IAnyProps, {}> {
  componentDidMount(): void {
    this.props.messageSearch(this.props.match.params.test);
    this.props.messageReset(this.props.match.params.test);
  }

  componentWillReceiveProps(nextProps) {
    if (isChangesExist(["pageNumber", "itemsPerPage"], nextProps, this.props)) {
      this.props.messageSearch(this.props.match.params.test);
    }
  }

  handleChangePage = (event, page) => {
    this.props.setPageNumber(page + 1);
  };

  handleChangeRowsPerPage = event => {
    this.props.setItemsPerPage(event.target.value);
  };

  openMessage = (item: IMessage) => () => {
    this.props.messageModal(item);
  };

  newMessage = () => {
    this.props.messageNewModal();
  };

  render() {
    if (!this.props.isLoaded) {
      return (
        <div className="table-no-items">
          Loading...
        </div>
      );
    }

    if (!this.props.data.length) {
      return (
        <div className="table-no-items">
          There are no items!
        </div>
      );
    }

    return (
      <Grid container>
        <Grid item xs={12}>
          <MessageModal/>
          <div className="table-responsive styled-table">
            <Table padding={"dense"}>
              <EnhancedTableHead columns={["Date submitted", "By", "Message", "Edit"]}/>
              <TableBody>
                {
                  this.props.data.map(item => {
                    return (
                      <TableRow key={item.id}>
                        <TableCell className="date">
                          {item.createdAt && formatDateWithTime(item.createdAt)}
                        </TableCell>
                        <TableCell className="author-name">
                          {item.authorName}
                        </TableCell>
                        <TableCell>
                          {item.content}
                        </TableCell>
                        {item.isCanBeEdited ?
                          <TableCell>
                            <IconButton onClick={this.openMessage(item)}>
                              <Icon>
                                edit
                              </Icon>
                            </IconButton>
                          </TableCell> :
                          <TableCell>
                            <div style={{height:"48px"}}/>
                          </TableCell>
                        }
                      </TableRow>
                    );
                  })
                }
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={this.props.totalItems}
              rowsPerPage={this.props.itemsPerPage}
              page={this.props.pageNumber - 1}
              backIconButtonProps={{
                "aria-label": "Previous Page"
              }}
              nextIconButtonProps={{
                "aria-label": "Next Page"
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </div>
        </Grid>
      </Grid>
    );
  }
}

const routered = withRouter(MessageTable);

const connected = connect<IAnyProps, IAnyProps, IAnyProps>((state) => {
  return {
    data: selector.getItems(state),
    isLoaded: selector.getIsLoaded(state),
    itemsPerPage: selector.getItemsPerPage(state),
    pageNumber: selector.getPageNumber(state),
    totalItems: selector.getTotalItems(state)
  };
}, {
  loadItems: messageRequest.getItems,
  setItemsPerPage: messageLoader.setItemsPerPage,
  setPageNumber: messageLoader.setPageNumber,
  messageSearch: messageSearchAction,
  messageReset: messageResetAction,
  messageNewModal: messageNewModalOpen,
  messageModal: messageModalOpen
})(routered);

export default connected;
