import * as _ from 'lodash';

export const isChangesExist = (fields, ...props) => {
  const extractedProps = props.map((prop) => _.values(_.pick(prop, fields)));
 
  for (const extractedProp of extractedProps) {
    if (JSON.stringify(extractedProps[0]) !== JSON.stringify(extractedProp)) {
      return true;
    }
  }

  return false;
};
