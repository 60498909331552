import * as Types from '../constants/action-type';

export default function (state = {
  anchorEl: null,
}, action) {
  switch (action.type) {
    case Types.SHOW_ACCOUNT_MENU:
      return {...state, anchorEl: action.payload.currentTarget};
    case Types.HIDE_ACCOUNT_MENU:
      return {...state, anchorEl: null};
    default:
      return state;
  }
}
