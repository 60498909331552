import React, {useState} from "react";
import EmailDomainRestrictionList from "./EmailDomainRestrictionList";
import WhiteListedRangeList from "./WhiteListedIPRangeList";
import AdminSettingsForm from "./AdminSettingsForm";

const AdminSettings = () => {
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  if(isEditMode) {
    return <AdminSettingsForm closeEditMode={() => setIsEditMode(false)} />
  }
  return (
    <>
      <EmailDomainRestrictionList openEditMode={() => setIsEditMode(true)} />
      <WhiteListedRangeList />
    </>
  );
}

export default AdminSettings;