import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { formValueSelector, InjectedFormProps, reduxForm } from "redux-form";
import { FieldGeneric } from "../../../components/forms/FieldGeneric";
import ReduxDatePicker from "../../../components/forms/ReduxDatePicker";
import { IconButton } from "@material-ui/core";
import Icon from "@material-ui/core/Icon/Icon";
import {
  composeValidators,
  fieldsNotEmpty,
  validateDatesFromTo
} from "../../../helpers/validations/validation";
import {
  EditServiceDatesStatuses,
  TestStatus
} from "../../../constants/test-status";
import { ConfirmModal } from "../../../components/ConfirmModal";
import { IconButtonProps } from "@material-ui/core/IconButton";

export const UPDATE_DATES_FORM_NAME = "UpdateTestServiceDates";
const selectorForm = formValueSelector(UPDATE_DATES_FORM_NAME);

type UpdateTestDatesFormData = {
  dateFrom?: string;
  dateTo?: string;
};

interface UpdateTestDatesFormProps {
  initialDateFrom: string;
  initialDateTo: string;
  status: TestStatus;
  valuesData: UpdateTestDatesFormData;
  handleSubmit?: () => void;
  onCancel: () => void;
  onSave: (values: UpdateTestDatesFormData) => void;
}

const UpdateTestDatesForm: React.FC<UpdateTestDatesFormProps &
  InjectedFormProps<UpdateTestDatesFormData>> = ({
  handleSubmit,
  status,
  valuesData,
  onSave,
  onCancel,
  asyncValidate,
  valid
}): JSX.Element => {
  const [isOpenedModal, setIsOpenedModal] = useState<boolean>(false);
  const saveButtonProps =
    valid && EditServiceDatesStatuses.includes(status)
      ? ({
          onClick: () => setIsOpenedModal(true),
          type: "button"
        } as IconButtonProps)
      : ({
          type: "submit"
        } as IconButtonProps);

  return (
    <form onSubmit={handleSubmit(onSave)}>
      <div className="row">
        <div className="col-sm-12 col-md-4">
          <FieldGeneric
            name="dateFrom"
            label="From*"
            InputLabelProps={{
              shrink: true
            }}
            component={ReduxDatePicker}
          />
        </div>
        <div className="col-sm-12 col-md-4">
          <FieldGeneric
            name="dateTo"
            label="To*"
            InputLabelProps={{ shrink: true }}
            addEndOfDay={true}
            component={ReduxDatePicker}
            onChange={asyncValidate}
          />
        </div>
        <div className="col-sm-12 col-md-4 d-flex align-items-end">
          <IconButton {...saveButtonProps}>
            <Icon>save</Icon>
          </IconButton>
          <IconButton onClick={onCancel} type="button">
            <Icon>cancel</Icon>
          </IconButton>
        </div>
        <ConfirmModal
          isOpened={isOpenedModal}
          onConfirm={() => onSave(valuesData)}
          onClose={() => setIsOpenedModal(false)}
          confirmDescription="Are you sure that you want to amend the booked dates?"
        />
      </div>
    </form>
  );
};

function mapStateToProps(state, props) {
  return {
    valuesData: { ...selectorForm(state, "dateFrom", "dateTo") },
    initialValues: {
      dateFrom: props.initialDateFrom,
      dateTo: props.initialDateTo
    }
  };
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch);
};

const connectForm = reduxForm<
  UpdateTestDatesFormData,
  UpdateTestDatesFormProps
>({
  form: UPDATE_DATES_FORM_NAME,
  validate: composeValidators(
    fieldsNotEmpty(["dateFrom", "dateTo"]),
    validateDatesFromTo("dateFrom", "dateTo")
  )
})(UpdateTestDatesForm);

const connected = connect(mapStateToProps, mapDispatchToProps)(connectForm);

export default connected;
