import React from "react";
import { Link } from "react-router-dom";

type AddTimeLogBtnType = {
  to: string;
};

export const AddTimeLogBtn = (props: AddTimeLogBtnType) => {
  return (
    <span className="timesheet-cell-add timesheet-cell-action">
      <Link to={props.to} className="btn-new"></Link>
    </span>
  );
};
