import {
  convertorFromSwagger,
  convertorToSwagger
} from "api/convertors/project";
import * as FilterActions from "constants/filter";
import { extractJSONLD, fetchRequestBody } from "helpers/request-helper";
import { getApi, getApiJSON, getApiJSONLD } from "api/swagger/api-factory";
import { IAnyProps } from "interfaces/any-props.interface";
import { IProject } from "models/project.interface";

export const getItems = (
  page: number,
  itemsPerPage: number,
  show: string,
  text: string = ""
) => {
  const isShow = show || FilterActions.DEFAULT_FILTER;
  const parameters: IAnyProps = { page };

  parameters.items = itemsPerPage;
  parameters.show = isShow;

  if (text) {
    parameters.name = text;
  }

  return getApiJSONLD()
    .then(api => api.getProjectCollection(parameters))
    .then(extractJSONLD(convertorFromSwagger));
};

export const getItem = (id: string | number) => {
  return getApi()
    .then(api => api.getProjectItem({ id }))
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};

export const reassignProjectRequest = (from, to) => {
  return getApiJSON()
    .then(api =>
      api.postApiProjectsReassign({
        projects: {
          project_from_id: from,
          project_to_id: to
        }
      })
    )
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};

export const postItem = (model: IProject) => {
  return getApi()
    .then(api =>
      api.postProjectCollection({
        project: convertorToSwagger(model)
      })
    )
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};

export const putItem = (model: IProject) => {
  return getApi()
    .then(api =>
      api.putProjectItem({
        id: model.id,
        project: convertorToSwagger(model)
      })
    )
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};

export const deleteItem = (id: string | number) => {
  return getApi()
    .then(api => api.deleteProjectItem({ id }))
    .then(fetchRequestBody);
};
