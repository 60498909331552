import {combineReducers} from "redux";
import * as Types from "../constants/action-type";

const lastQuestion = (state = [], action) => {
  switch (action.type) {
    case Types.LAST_QUESTION:
      return [...action.payload];
    default:
      return state;
  }
};

const lastRisks = (state = [], action) => {
  switch (action.type) {
    case Types.LAST_RISKS:
      return [...action.payload];
    default:
      return state;
  }
};

const lastMessage = (state = [], action) => {
  switch (action.type) {
    case Types.LAST_MESSAGE:
      return [...action.payload];
    default:
      return state;
  }
};

const lastReports = (state = [], action) => {
  switch (action.type) {
    case Types.LAST_REPORTS:
      return [...action.payload];
    default:
      return state;
  }
};

const testsChartData = (state = [], action) => {
  switch (action.type) {
    case Types.TESTS_CHART:
      return [...action.payload];
    default:
      return state;
  }
};

const projectsCostChartData = (state = [], action) => {
  switch (action.type) {
    case Types.PROJECTS_COST_CHART:
      return action.payload;
    default:
      return state;
  }
};

const dashboard = combineReducers({
  lastQuestion,
  lastRisks,
  lastMessage,
  lastReports,
  testsChartData,
  projectsCostChartData,
});

export default dashboard;