import * as React from "react";
import { connect } from "react-redux";
import { Modal, WithStyles } from "@material-ui/core";
import { approachModalClose } from "../../actions/modals";
import { getIsOpenModalApproach } from "../../selectors/modal";

interface IStateToProps {
  isOpenModal: boolean;
}

interface IDispatchToProps {
  approachModalClose: () => void;
}

class ApproachesModal extends React.Component<
  IStateToProps & IDispatchToProps & WithStyles<any>
> {
  render() {
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.props.isOpenModal}
        onClose={this.handleClose}
        className="d-flex overflow-auto"
      >
        <div className="styled-modal md">
          <h4>What's the difference between Black, White and Grey Box</h4>
          <hr />
          <div className="styled-modal-body">
            <h5 className="block-header mb-4">Black Box Hackers</h5>
            <p>
              Like all hackers, black box hackers usually have extensive
              knowledge about breaking into computer networks and bypassing
              security protocols. They are also responsible for writing malware,
              which is a method used to gain access to these systems. Their
              primary motivation is usually for personal or financial gain, but
              they can also be involved in cyber espionage, protest or perhaps
              are just addicted to the thrill of cybercrime. Black box hackers
              can range from amateurs getting their feet wet by spreading
              malware, to experienced hackers that aim to steal data,
              specifically financial information, personal information and login
              credentials. Not only do black box hackers seek to steal data,
              they also seek to modify or destroy data as well.
            </p>
            <h5 className="block-header mb-4">White Box Hackers</h5>
            <p>
              White box hackers choose to use their powers for good rather than
              evil. Also known as “ethical hackers,” white box hackers can
              sometimes be paid employees or contractors working for companies
              as security specialists that attempt to find security holes via
              hacking. White box hackers employ the same methods of hacking as
              black boxes, with one exception- they do it with permission from
              the owner of the system first, which makes the process completely
              legal. White box hackers perform penetration testing, test
              in-place security systems and perform vulnerability assessments
              for companies. There are even courses, training, conferences and
              certifications for ethical hacking.
            </p>
            <h5 className="block-header mb-4">Grey Box Hackers</h5>
            <p>
              As in life, there are grey areas that are neither black nor white.
              Grey box hackers are a blend of both black box and white box
              activities. Often, grey box hackers will look for vulnerabilities
              in a system without the owner’s permission or knowledge. If issues
              are found, they will report them to the owner, sometimes
              requesting a small fee to fix the issue. If the owner does not
              respond or comply, then sometimes the hackers will post the newly
              found exploit online for the world to see. These types of hackers
              are not inherently malicious with their intentions; they’re just
              looking to get something out of their discoveries for themselves.
              Usually, grey box hackers will not exploit the found
              vulnerabilities. However, this type of hacking is still considered
              illegal because the hacker did not receive permission from the
              owner prior to attempting to attack the system. Although the word
              hacker tends to evoke negative connotations when referred to, it
              is important to remember that all hackers are not created equal.
              If we didn’t have white box hackers diligently seeking out threats
              and vulnerabilities before the black boxes can find them, then
              there would probably be a lot more activity involving
              cybercriminals exploiting vulnerabilities and collecting sensitive
              data than there is now.
            </p>
          </div>
          <hr />
          <div className="text-right">
            <button onClick={this.handleClose} className="btn-cancel">
              Close
            </button>
          </div>
        </div>
      </Modal>
    );
  }

  private readonly handleClose = () => {
    this.props.approachModalClose();
  };
}

function mapStateToProps(state) {
  return {
    isOpenModal: getIsOpenModalApproach(state),
  };
}

const connectedComponent = connect<IStateToProps, IDispatchToProps>(
  mapStateToProps,
  {
    approachModalClose,
  }
)(ApproachesModal);

export default connectedComponent as any;
