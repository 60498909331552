import React from "react";
import classnames from "classnames";

type TableNoItemsProps = {
  asLoading?: boolean;
  text?: string;
  hasWrapper?: boolean;
  className?: string;
};
export const TableNoItems = (props: TableNoItemsProps) => {
  const { hasWrapper = false, className = "" } = props;
  const styledBlock = classnames("", {
    "styled-block": hasWrapper
  });
  let componentMessage = "There are no items!";
  if (props?.asLoading) {
    componentMessage = "Loading...";
  }
  if (props?.text) {
    componentMessage = props.text;
  }
  //const wrapperClassName = !!props?.hasWrapper ? `styled-block ${classNames}` : classNames;

  return (
    <div className={`${styledBlock} ${className}`}>
      <div className="table-no-items">{componentMessage}</div>
    </div>
  );
};
