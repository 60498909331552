import * as React from 'react';
import {RouteComponentProps} from "react-router-dom";
import BookingRequestsList from "./BookingRequestsList";
import BookingRequestsSearchForm from "../../common/BookingRequestsSearchForm";
import {withRouter} from 'react-router-dom';

class BookingRequests extends React.Component<RouteComponentProps<any>, {}> {
  baseUrl = this.props.match.path;

  render() {
    return (
      <div>
        <div>
          <div className="crud-sections-header">
            <h4>Booking Requests</h4>
          </div>
          <hr/>
          <BookingRequestsSearchForm />
          <BookingRequestsList baseUrl={this.baseUrl} />
        </div>
      </div>
    );
  }
}

export default withRouter(BookingRequests);
