import React, { useEffect } from "react";
import debounce from "debounce";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper/Paper";
import { TableNoItems } from "components/table/TableNoItems";
import { connect } from "react-redux";
import { formatDateWithTime } from "helpers/date-formatter";
import { bindActionCreators, Dispatch } from "redux";
import {
  archiveConsultantAction,
  consultantsLoader,
  deleteConsultantAction,
  getConsultantsRequestsAction,
  unArchiveConsultantAction
} from "actions/consultants";
import selector from "selectors/consultants";
import { formValueSelector } from "redux-form";
import { getFilterPreviewValue } from "helpers/component-prev-values";
import { FilterParamsType } from "types/consultant";
import TableCellArchive from "components/table/TableCellArchive";
import TableCellDelete from "components/table/TableCellDelete";
import TableCellEdit from "components/table/TableCellEdit";
import { TableCellResetPassword } from "components/table/TableCellResetPassword";
import { postEmail, resetAction2FA } from "actions";
import { ALL, ARCHIVED } from "constants/filter";
import { CONSULTANT_SEARCH_FORM } from "constants/consultant";
import { ProjectsCell } from "./ProjectsCell";
import { RootState } from "reducers";
import { ConnectedProps } from "types";
import { getProjectNames } from "../helpers/getProjectNames";
import { ROLE } from "constants/roles";
import { Permission } from "constants/permission";
import { getRoles, getUserPermissions } from "selectors/auth";

type ConsultantListProps = {
  baseUrl: string;
  roles?: ROLE[];
  permissions?: Permission[];
};

const searchFormSelector = formValueSelector(CONSULTANT_SEARCH_FORM);

const getShouldUpdateValues = (
  prevSearchValues: FilterParamsType,
  searchValues: FilterParamsType
) => {
  const shouldReloadList =
    JSON.stringify(prevSearchValues) !== JSON.stringify(searchValues);

  return {
    shouldReloadList,
    isTextFieldUpdated:
      prevSearchValues?.nameEmailPhone !== searchValues?.nameEmailPhone
  };
};

const ConsultantsList = (props: ConsultantListProps & PropsFromRedux) => {
  const {
    data,
    isLoaded,
    pageNumber,
    itemsPerPage,
    totalItems,
    searchFormFilters,
    setItemsPerPage,
    setPageNumber,
    loadItems,
    baseUrl,
    postEmail,
    deleteConsultant,
    archiveConsultant,
    unArchiveConsultant,
    resetAction2FA,
    permissions = [],
    roles = []
  } = props;

  const filterInitialValues = {
    consultantProject: [],
    pageNumber: 1,
    itemsPerPage: 25
  };

  const searchValues = { ...searchFormFilters, pageNumber, itemsPerPage };
  const prevSearchValues = getFilterPreviewValue(
    searchValues,
    filterInitialValues
  );
  const isInactiveFilter = searchFormFilters?.show === ARCHIVED;
  const shouldRerenderList = getShouldUpdateValues(
    prevSearchValues,
    searchValues
  );

  useEffect(() => {
    loadItems(searchValues);
  }, []);

  useEffect(() => {
    const { shouldReloadList, isTextFieldUpdated } = shouldRerenderList;
    const debounceLoadItems = debounce(loadItems, 500);

    if (isTextFieldUpdated) {
      debounceLoadItems(searchValues);
    } else if (shouldReloadList) {
      loadItems(searchValues);
    }
  }, [shouldRerenderList]);

  const handleChangeRowsPerPage = event => {
    setItemsPerPage(event.target.value);
  };

  const handleChangePage = (_, page: number) => {
    setPageNumber(page + 1);
  };

  if (!isLoaded) {
    return <TableNoItems hasWrapper asLoading />;
  }

  if (!data.length) {
    return <TableNoItems hasWrapper />;
  }

  const centeredCellStyles = {
    style: { whiteSpace: "pre-wrap" },
    padding: "checkbox",
    align: "center"
  } as const;

  const hasEditPermission = permissions.includes(Permission.CAN_MANAGE_CONSULTANTS);

  return (
    <Paper style={{ overflowX: "auto", width: "100%" }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ whiteSpace: "pre-wrap" }}>Name</TableCell>
            <TableCell {...centeredCellStyles}>Email</TableCell>
            <TableCell {...centeredCellStyles}> Projects</TableCell>
            <TableCell {...centeredCellStyles}>Telephone</TableCell>
            <TableCell {...centeredCellStyles}>Created At</TableCell>
            {hasEditPermission && (
              <TableCell {...centeredCellStyles}>Reset password</TableCell>
            )}
            {hasEditPermission && (
              <TableCell {...centeredCellStyles}>Reset 2FA</TableCell>
            )}
            {hasEditPermission && !isInactiveFilter && (
              <TableCell {...centeredCellStyles} width={80}>
                Edit
              </TableCell>
            )}
            <TableCell {...centeredCellStyles}>Status</TableCell>
            {hasEditPermission && (
              <TableCell {...centeredCellStyles} width={100}>
                De/Re
                <br />
                activate
              </TableCell>
            )}
            {hasEditPermission && (
              <TableCell {...centeredCellStyles} width={80}>
                Delete
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(item => {
            const consultantName = `${item.firstName} ${item.lastName}`;

            const editCell = hasEditPermission ? (
              item.deletedAt && searchFormFilters?.show === ALL ? (
                <TableCell />
              ) : (
                <TableCellEdit
                  status={!item.deletedAt ? "Active" : "Inactive"}
                  item={item}
                  baseUrl={baseUrl}
                  padding="checkbox"
                  align="center"
                />
              )
            ) : null;

            return (
              <TableRow key={item.id}>
                <TableCell>{consultantName}</TableCell>
                <TableCell padding="checkbox" align="center">
                  {item.email}
                </TableCell>
                <TableCell
                  padding="checkbox"
                  align="center"
                  style={{ maxWidth: 300 }}
                >
                  <ProjectsCell
                    projects={getProjectNames(item.consultantProjects)}
                  />
                </TableCell>
                <TableCell padding="checkbox" align="center">
                  {item.phone}
                </TableCell>
                <TableCell padding="checkbox" align="center">
                  {item?.createdAt && formatDateWithTime(item.createdAt)}
                </TableCell>
                {hasEditPermission && (
                  <TableCellResetPassword
                    onReset={() => postEmail(item.email, "")}
                    modalMessage="You want to reset password?"
                    width={150}
                    padding="checkbox"
                    align="center"
                  />
                )}
                {hasEditPermission && (
                  <TableCellResetPassword
                    onReset={() => resetAction2FA(item.id)}
                    modalMessage="You want to reset 2FA?"
                    width={120}
                    padding="checkbox"
                    align="center"
                  >
                    Reset 2FA
                  </TableCellResetPassword>
                )}
                {editCell}
                <TableCell padding="checkbox" align="center">
                  {!item.deletedAt ? "Active" : "Inactive"}
                </TableCell>
                {hasEditPermission && (
                  <TableCellArchive
                    onArchive={() =>
                      archiveConsultant(item, searchFormFilters?.show)
                    }
                    onUnarchive={() =>
                      unArchiveConsultant(item, searchFormFilters?.show)
                    }
                    item={item}
                    padding="checkbox"
                    align="center"
                  />
                )}
                {hasEditPermission && (
                  <TableCellDelete
                    onDelete={() => deleteConsultant(item.id, consultantName)}
                    item={item}
                    padding="checkbox"
                    align="center"
                  />
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <TablePagination
        component="div"
        count={totalItems}
        rowsPerPage={Number(itemsPerPage || 0)}
        page={pageNumber - 1}
        backIconButtonProps={{ "aria-label": "Previous Page" }}
        nextIconButtonProps={{ "aria-label": "Next Page" }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

const mapStateToProps = (state: RootState) => ({
  data: selector.getItems(state),
  isLoaded: selector.getIsLoaded(state),
  itemsPerPage: selector.getItemsPerPage(state),
  pageNumber: selector.getPageNumber(state),
  showStatus: selector.getFilterActivityStatus(state),
  totalItems: selector.getTotalItems(state),
  filterText: selector.getFilterTextFields(state),
  searchFormFilters: searchFormSelector(
    state,
    "nameEmailPhone",
    "consultantProject",
    "dateFrom",
    "dateTo",
    "show"
  ),
  roles: getRoles(state),
  permissions: getUserPermissions(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      postEmail,
      loadItems: getConsultantsRequestsAction,
      setItemsPerPage: consultantsLoader.setItemsPerPage,
      setPageNumber: consultantsLoader.setPageNumber,
      deleteConsultant: deleteConsultantAction,
      archiveConsultant: archiveConsultantAction,
      unArchiveConsultant: unArchiveConsultantAction,
      resetAction2FA
    },
    dispatch
  );
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ConsultantsList);
