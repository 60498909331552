import React from "react";
import { Grid } from "@material-ui/core";
import RemoveRepeaterButton from "./RemoveReceiptButton";
import { UploadReceiptButton } from "./UploadReceiptButton";
import DownloadDocument from "./DownloadDocument";
import { TypeSelect } from "./TypeSelect";
import { Field } from "redux-form";
import { IDocument } from "models/document.interface";

type RowItem = {
  id?: number;
  document: File | IDocument;
  type?: number;
};

type ReceiptRowProps = {
  expensesTypesList: { id: number; name: string }[];
  onRemoveClick: () => void;
  onChange: (item: RowItem) => void;
  field: RowItem;
  member: string;
};

export function ReceiptRow({
  field,
  onRemoveClick,
  expensesTypesList,
  member
}: ReceiptRowProps) {
  return (
    <Grid container spacing={8} alignItems="center">
      <Grid item xs={12} sm={6}>
        <div className="documents-repeater__info">
          <RemoveRepeaterButton onRemove={onRemoveClick} />
          {field.document && <DownloadDocument document={field.document} />}
          {!field.document && (
            <Field
              name={`${member}.document`}
              component={UploadReceiptButton}
            />
          )}
        </div>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TypeSelect
          name={`${member}.type`}
          options={expensesTypesList}
          value={field.type}
        />
      </Grid>
    </Grid>
  );
}
