import React, { useState, useContext, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  Field,
  FieldArray,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
  getFormSyncErrors,
  getFormValues
} from "redux-form";
import { connect } from "react-redux";
import routerModal from "components/routerModal";
import { bindActionCreators, Dispatch } from "redux";
import { Checkbox, ListItemText, MenuItem } from "@material-ui/core";
import ReduxTextField from "components/forms/ReduxTextField";
import { FieldGeneric } from "components/forms/FieldGeneric";
import ReduxRadioButton from "components/forms/ReduxRadioButton";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  composeValidators,
  validateTimeExpensesForm,
  timesheetDocumentsRepeaterValidation,
  fieldMinValueForNumber
} from "helpers/validations/validation";
import { handleKeyPressOnDecimalNumberField } from "helpers/validations/numberFieldValidator";
import { TimeSheetExpensesContext } from "../../context/timeSheetsContext";
import { getTimeLogModalTitle } from "helpers/date-formatter";
import { TimesheetDocumentsRepeater } from "./TimesheetDocumentsRepeater";
import {
  clearArchivedDocumentsListAction,
  clearSelectedDayDataAction,
  createDayTimeLogAction,
  deleteDailyTimeExpensesLogAction,
  editDayTimeLogAction,
  getTimesheetDayDataByIdAction
} from "actions/time-expenses";
import {
  CreateDailyTimeLogFormData,
  DailyTimeLogFormData,
  DayByIdActionType,
  DeleteDailyTimeExpensesLogActionType,
  EditDailyTimeLogFormData,
  TimeExpensesCategory,
  WeekDayDataHelperType
} from "types/timesheets-and-expenses";
import selector from "selectors/time-expenses";
import { ConfirmModal } from "components/ConfirmModal";
import {
  customSelectHandler,
  getWeekDayData
} from "helpers/timesheets-expenses";
import { ReduxSelectSearchFieldCustom } from "./ReduxSelectSearchFieldCustom";
import { TIME_SHEETS_SEARCH_FORM } from "constants/timesheet-expenses";

interface DailyTimeLogFormProps
  extends RouteComponentProps<{ id?: string; date?: string }> {
  handleSubmit: () => void;
  createDayTimeLogAction: (data: CreateDailyTimeLogFormData) => void;
  editDayTimeLogAction: (data: EditDailyTimeLogFormData) => void;
  getTimesheetDayDataByIdAction: (id: string) => void;
  deleteDailyTimeExpensesLogAction: (
    props: DeleteDailyTimeExpensesLogActionType
  ) => void;
  clearSelectedDayDataAction: () => void;
  categorySelected?: number;
  amountValue?: number;
  selectedDayData: DayByIdActionType | null;
  timeCategoriesList: TimeExpensesCategory[];
  expensesTypesList: TimeExpensesCategory[];
  archivedDocuments: number[];
  clearArchivedDocumentsList: () => void;
  isSubmittedWeek: boolean;
  formData?: {};
}

export const TE_DAY_TIME_LOG_FORM_NAME = "TEDayTimeLogForm";
const searchFormSelector = formValueSelector(TE_DAY_TIME_LOG_FORM_NAME);

const DailyTimeLogForm: React.FC<DailyTimeLogFormProps &
  InjectedFormProps<DailyTimeLogFormData>> = ({
  handleSubmit,
  change,
  categorySelected,
  amountValue,
  match,
  createDayTimeLogAction,
  editDayTimeLogAction,
  getTimesheetDayDataByIdAction,
  clearSelectedDayDataAction,
  deleteDailyTimeExpensesLogAction,
  selectedDayData,
  timeCategoriesList,
  expensesTypesList,
  archivedDocuments,
  clearArchivedDocumentsList,
  isSubmittedWeek,
  formData = {}
}) => {
  const [dayData, setDayData] = useState<null | WeekDayDataHelperType>(null);
  const [isOpenedConfirmModal, setIsOpenedConfirmModal] = useState<boolean>(
    false
  );
  const [isReadyToRender, setIsReadyToRender] = useState<boolean>(false);
  const dayId = match.params?.id;
  const dayDate = match.params?.date;
  const { goBackHandler } = useContext(TimeSheetExpensesContext);
  const project = dayData?.projectRoute || "";
  const date = dayData?.date || "";
  const formTitle = getTimeLogModalTitle(date);
  const timeCategoriesHandler = customSelectHandler("timeCategory", change);
  const daysHandler = customSelectHandler("days", change);
  const cancelHandler = () => {
    if (dayId) {
      clearArchivedDocumentsList();
    }
    goBackHandler();
  };

  useEffect(() => {
    if (dayDate) {
      const data = getWeekDayData(dayDate);
      setDayData({ ...data });
      setIsReadyToRender(true);
    }
    if (dayId) {
      getTimesheetDayDataByIdAction(dayId);
    }
  }, []);

  useEffect(() => {
    if (selectedDayData && dayId) {
      const { day, ...rest } = selectedDayData;
      const data = getWeekDayData(day);
      setDayData({ ...data });
      Object.entries(rest).map(([key, value]) => {
        change(key, value);
        return [key, value];
      });
      setIsReadyToRender(true);
    }
  }, [selectedDayData]);

  useEffect(() => {
    return clearSelectedDayDataAction;
  }, []);

  const renderComponent = !isReadyToRender ? (
    <>Loading...</>
  ) : (
    <>
      <form
        className="timesheet-form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(values => {
          if (dayId) {
            editDayTimeLogAction({
              ...values,
              date,
              project,
              id: dayId,
              archivedDocuments,
              goBackHandler,
              queryParams: formData
            });
          } else {
            createDayTimeLogAction({
              ...values,
              date,
              project,
              goBackHandler,
              queryParams: formData
            });
          }
        })}
      >
        <div className="row">
          <div className="col-12">
            <h4 className="mb-2">{formTitle}</h4>
            <p className="mb-2">
              At least one “Time” or/and “Expenses” section must be completed.
            </p>
          </div>
        </div>
        <div className="timesheet-form-section mb-0">
          <div className="row">
            <div className="col-12">
              <h5>Time</h5>
              <div className="select-margin-0 timesheet-form-item-required">
                <FieldGeneric
                  name="timeCategory"
                  label="Category"
                  fullWidth
                  component={ReduxSelectSearchFieldCustom}
                  onChange={timeCategoriesHandler}
                  items={timeCategoriesList}
                  renderItem={option => (
                    <MenuItem key={option.id} value={option.id}>
                      <Checkbox checked={categorySelected === option.id} />
                      <ListItemText primary={option.name} />
                    </MenuItem>
                  )}
                  renderValue={() => {
                    return timeCategoriesList.find(
                      item => item.id === categorySelected
                    )?.name;
                  }}
                />
              </div>
              <FieldGeneric
                name="days"
                margin="normal"
                inline
                onChange={daysHandler}
                component={ReduxRadioButton}
              >
                <FormControlLabel
                  value={"1"}
                  control={<Checkbox />}
                  label="1 day"
                  className="mb-0"
                />
                <FormControlLabel
                  value={"0.5"}
                  control={<Checkbox />}
                  label="0.5 days"
                  className="mb-0"
                />
              </FieldGeneric>
              <Field
                name="days_text"
                component={ReduxTextField}
                type="hidden"
                margin="none"
                className="days-error-field timesheet-form-item-required"
              />
            </div>
          </div>
        </div>
        <div className="timesheet-form-section pt-0 mb-0">
          <div className="row">
            <div className="col-12">
              <h5>Expenses</h5>
            </div>
            <div className="col-md-6">
              <Field
                name="expensesAmount"
                label="£ Amount"
                component={ReduxTextField}
                margin="normal"
                onKeyPress={handleKeyPressOnDecimalNumberField}
                inputProps={{ min: 0 }}
                type="number"
                className="timesheet-form-item-required"
              />
            </div>
            <div className="col-md-6">
              <Field
                name="vat"
                label="£ VAT"
                component={ReduxTextField}
                margin="normal"
                onKeyPress={handleKeyPressOnDecimalNumberField}
                inputProps={{ min: 0 }}
                type="number"
              />
            </div>
            {amountValue && (
              <div className="col-12 pt-4 pb-1">
                <FieldArray
                  name="documents"
                  component={TimesheetDocumentsRepeater}
                  props={{ expensesTypesList }}
                  rerenderOnEveryChange
                />
                <Field
                  name="documents_error"
                  component={ReduxTextField}
                  type="hidden"
                  className="mt-0 mb-0 form-field-hidden"
                />
              </div>
            )}
          </div>
        </div>
        <div className="timesheet-form-section">
          <div className="row">
            <div className="col-12">
              <h5>Comment</h5>
              <Field
                name="comment"
                label="Comment"
                component={ReduxTextField}
                margin="dense"
              />
            </div>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-12">
            <div className="d-flex justify-content-end mt-2">
              {dayId && (
                <button
                  type="button"
                  className="btn-delete mr-4"
                  onClick={() => setIsOpenedConfirmModal(true)}
                >
                  Delete
                </button>
              )}
              <button
                type="button"
                className="btn-cancel mr-4"
                onClick={cancelHandler}
              >
                Cancel
              </button>
              <button type="submit" className="btn-accept">
                {isSubmittedWeek ? "Save" : "Save as Draft"}
              </button>
            </div>
          </div>
        </div>
      </form>
      {dayId && (
        <ConfirmModal
          isOpened={isOpenedConfirmModal}
          onClose={() => setIsOpenedConfirmModal(false)}
          onConfirm={() =>
            deleteDailyTimeExpensesLogAction({
              id: dayId,
              weekDay: dayData?.weekDay as string,
              projectId: dayData?.projectId as number,
              yearWeek: dayData?.yearWeek as string,
              cb: goBackHandler,
              queryParams: formData
            })
          }
        />
      )}
    </>
  );

  return <div className="">{renderComponent}</div>;
};

const ConnectedForm = reduxForm<DailyTimeLogFormData, DailyTimeLogFormProps>({
  form: TE_DAY_TIME_LOG_FORM_NAME,
  validate: composeValidators(
    validateTimeExpensesForm(),
    timesheetDocumentsRepeaterValidation(),
    fieldMinValueForNumber(["expensesAmount", "vat"], 1)
  )
})(DailyTimeLogForm);

function mapStateToProps(state, props) {
  const { params } = props.match;
  const isSubmittedWeek =
    !!params?.id || selector.isSubmittedProjectWeek(params?.date)(state);

  return {
    submitErrors: getFormSyncErrors(TE_DAY_TIME_LOG_FORM_NAME)(state),
    selectedDayData: selector.getSelectedDayData(state),
    timeCategoriesList: selector.getTimeCategoriesList(state),
    expensesTypesList: selector.getExpensesTypesList(state),
    archivedDocuments: selector.getArchivedDocumentsList(state),
    categorySelected: searchFormSelector(state, "timeCategory"),
    amountValue: searchFormSelector(state, "expensesAmount"),
    initialValues: {
      documents: []
    },
    isSubmittedWeek,
    formData: getFormValues(TIME_SHEETS_SEARCH_FORM)(state)
  };
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      createDayTimeLogAction,
      editDayTimeLogAction,
      getTimesheetDayDataByIdAction,
      deleteDailyTimeExpensesLogAction,
      clearSelectedDayDataAction,
      clearArchivedDocumentsList: clearArchivedDocumentsListAction
    },
    dispatch
  );
};
const connected = connect(mapStateToProps, mapDispatchToProps)(ConnectedForm);

export default routerModal(connected);
