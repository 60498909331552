import {
  REQUEST_IN_PROCESS,
  SET_EXTRA_PERMISSIONS,
  CHANGE_RECEIVE_BOOKING_REQUESTS
} from "../constants/action-type";

export function setRequestInProcess(inProcess, requestType) {
  return {
    type: REQUEST_IN_PROCESS,
    payload: {
      requestType,
      inProcess,
    },
  };
}

export function setResponseWithPermissionsToStore(payload) {
  return {
    type: SET_EXTRA_PERMISSIONS,
    payload: payload,
  };
}

export function changeReceiveBookingRequests(payload) {
  return {
    type: CHANGE_RECEIVE_BOOKING_REQUESTS,
    payload: payload,
  };
}
