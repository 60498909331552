import moment from "moment";
import { format } from "date-fns";

export const getYearsList = (back = 25): number[] => {
  const year = new Date().getFullYear();
  return Array.from(
    { length: back },
    (v, i) => year - back + i + 1
  ).sort((a, b) => (a > b ? -1 : 1));
};

export const getMonthsList = () => {
  const months = moment.months();

  return months.map((month, index) => {
    const monthId: number = index + 1;
    const id: string = monthId > 9 ? `${monthId}` : `0${monthId}`;

    return { id, name: month };
  });
};

export const getCurrentMonth = () =>
  moment()
    .format("MMMM")
    .toString();
export const getYearMonth = () => {
  return moment().format("MMMM YYYY");
};

export const convertMonthNameToNumber = (monthName: string) => {
  return moment()
    .month(monthName)
    .format("MM");
};

export const convertDateSearchToRequestFormat = (date: string) => {
  const [month, year] = date.split("/");
  return `${year}-${month}`;
};
