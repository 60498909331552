import * as React from 'react';
import Card from '@material-ui/core/Card/Card';
import CardContent from '@material-ui/core/CardContent/CardContent';
import Typography from '@material-ui/core/Typography/Typography';
import {Link, withRouter, RouteComponentProps} from "react-router-dom";
import Button from '@material-ui/core/Button/Button';
import Grid from '@material-ui/core/Grid/Grid';
import {bookTesterAction} from "../../../../actions/testers";
import {connect} from "react-redux";
import {LINKS} from "../../../../constants/links";

interface IDispatch {
  bookTesterAction: (test, tester) => void;
}

class TestersBookingConfirmation extends React.Component<RouteComponentProps<any> & IDispatch, {}> {
  handleBook = (event) => {
    event.preventDefault();
    this.props.bookTesterAction(this.props.match.params.test, this.props.match.params.tester);
  };

  render() {
    return (
      <Card>
        <CardContent style={{padding: 16}}>
          <Typography variant="headline" align="center">
            Thank you for using AVORD
          </Typography>
          <div className="separator"/>
          <p>
            We'll send your booking request to the tester.
            If they have any questions you will get an email notification and you can reply from the bookings tab.
            Once the scope is agreed the tester will submit the number of days the work will take.
            If you agree we'll take payment and hold it in escrow until you confirm the work is complete.
          </p>
          <p>
             By submitting booking request you agree to the following terms and conditions:&nbsp;
            <a href={LINKS.TERMS_AND_CONDITIONS} target="_blank">
              Terms &amp; Conditions
            </a>.
          </p>
          <br/>
          <div className="separator"/>
          <Grid container spacing={24}>
            <Grid item xs={3}>
              <Link to={
                `/client/schedule/booking/${this.props.match.params.project}/${this.props.match.params.test}/testers`
              }>
                <Button variant="contained" size="small" color="default" fullWidth>
                  Cancel
                </Button>
              </Link>
            </Grid>
            <Grid item xs={6}/>
            <Grid item xs={3}>
              <Button variant="contained" size="small" color="primary" onClick={this.handleBook} fullWidth>
                Continue
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    )
  }
}

export default connect(undefined, {
  bookTesterAction,
})(withRouter(TestersBookingConfirmation) as any);