import * as React from "react";
import {Link} from "react-router-dom";
import {MenuItem} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import ReduxTextField from "../../../components/forms/ReduxTextField";
import {FieldGeneric} from "../../../components/forms/FieldGeneric";
import ReduxSelectField from "../../../components/forms/ReduxSelectField";
import ReduxSelectSearchField from "../../../components/forms/ReduxSelectSearchField";

interface IProps {
  title: string;
  backRoute: string;
  initialValues: any;
  groups: any;
  group: string;
  externalCompanies: any;
  externalCompany?: string;
  updateField: (field: string, value: any) => void;
}

interface IState {
  showExternalCompany: boolean;
  showOtherCompany: boolean,
}

class TesterForm extends React.PureComponent<IProps, IState> {
  state = {
    showExternalCompany: false,
    showOtherCompany: false,
  };

  componentDidMount(): void {
    this.setInitialValues();
  }
  
  componentDidUpdate(prevProps) {
    const { group, groups, externalCompany, externalCompanies } = this.props;

    if (prevProps.group !== group || prevProps.groups !== groups) {
      this.handleGroupsUpdate(group);
      this.handleExternalCompanyUpdate(null, externalCompany);
    } else if (prevProps.externalCompay !== externalCompany || prevProps.externalCompanies !== externalCompanies) {
      this.handleExternalCompanyUpdate(null, externalCompany);
    }
  }

  setInitialValues() {
    const { updateField, initialValues, groups } = this.props;

    if (initialValues.user) {
      updateField('email', initialValues.user.email);
      updateField('firstName', initialValues.user.firstName);
      updateField('lastName', initialValues.user.lastName);
      updateField('group', initialValues.user.group);

      if (this.hasExternalCompany(initialValues.user.group, groups)) {
        this.setState({
          showExternalCompany: !!initialValues.externalCompany,
          showOtherCompany: !initialValues.externalCompany
        });
      } else {
        this.setState({
          showExternalCompany: false,
          showOtherCompany: false,
        })
      }
    }
  }

  hasExternalCompany(selectedGroup, groups) {
    const group = groups.find(element => element['@id'] === selectedGroup);

    return group ? group.hasExternalCompany : false;
  }

  getSelectedGroup(group) {
    if (typeof group === 'string') {
      return group;
    }

    return Object.keys(group).map(item => typeof group[item] === 'string' ? group[item] : '').join('');
  }
  
  handleGroupsUpdate = (iri) => {
    if (!iri) {
      return; 
    }

    const selectedGroup = this.getSelectedGroup(iri);
    const showExternalCompany = this.hasExternalCompany(selectedGroup, this.props.groups);
    const { updateField, initialValues } = this.props;

    this.setState({ showExternalCompany });

    if (!showExternalCompany) {
      updateField('externalCompany', null);
      updateField('companyName', null);
    } else if (initialValues.companyName) {
      updateField('companyName', initialValues.companyName);
    }
  };

  handleExternalCompanyUpdate = (value, iri) => {
    const showOtherCompany = !iri;
    this.setState({ showOtherCompany });
  };

  renderItem = (option: any) => (
    <MenuItem
      key={option['@id']}
      value={option['@id']}>
      {option.name}
    </MenuItem>
  );

  render() {
    const externalCompanies = [{'@id': '', name: 'Other company name'}, ...this.props.externalCompanies];

    return (
      <Grid container spacing={40}>
        <Grid item xs={12}>
          <h3>{this.props.title}</h3>
          <FieldGeneric
            name="firstName"
            label="First name *"
            component={ReduxTextField}
          />
          <FieldGeneric
            name="lastName"
            label="Last name *"
            component={ReduxTextField}
          />
          <FieldGeneric
            name="email"
            label="Email *"
            component={ReduxTextField}
          />
          <FieldGeneric
            name="group"
            label="Tester type *"
            component={ReduxSelectField}
            onChange={this.handleGroupsUpdate}
          >
            {this.props.groups.map(option => this.renderItem(option))}
          </FieldGeneric>
          {
            this.state.showExternalCompany && (
              <FieldGeneric
                name="externalCompany"
                label="Company *"
                component={ReduxSelectSearchField}
                items={externalCompanies}
                renderItem={this.renderItem}
                onChange={this.handleExternalCompanyUpdate}
                displayEmpty
              />
            )
          }

          {
            this.state.showExternalCompany
            && this.state.showOtherCompany
            && (
              <FieldGeneric
                name="companyName"
                label="Other company name *"
                component={ReduxTextField}
              />
            )
          }
          
          <FieldGeneric
            inputProps={{maxLength: 2001, style:{overflow: "hidden"}}}
            multiline
            name="bio"
            label="Comment"
            component={ReduxTextField}
          />
          <div className="text-right mt-4">
            <Link className="btn-cancel" to={this.props.backRoute}>
              Cancel
            </Link>
            <button type="submit" className="btn-accept">Save</button>
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default TesterForm;
