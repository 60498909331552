import React from "react";
type DayTimeExpensesLogProps = {
  days?: number;
  expenses?: number;
  vat?: number;
};

export const DayTimeExpensesLog = ({
  days,
  expenses,
  vat
}: DayTimeExpensesLogProps) => {
  const vatAmount = vat ? +vat : 0;
  const expensesAmount = expenses ? +expenses : 0;
  const sum = +expensesAmount + vatAmount;
  const result = sum % 1   === 0 ? sum : sum.toFixed(2);

  if (days && expensesAmount) {
    return (
      <span>
        {days}d +
        <br />£{result}
      </span>
    );
  }
  if (days) {
    return <span>{days}d</span>;
  }

  return <span>{`£${result}`}</span>;
};
