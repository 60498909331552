import React from "react";
import DashboardMessages from "./DashboardMessages";
import DashboardBookingRequestQuestion from "./DashboardBookingRequestQuestion";
import DashboardCriticalRisks from "./DashboardCriticalRisks";
import DashboardBookingReports from "./DashboardBookingReports";
import DashboardColumnChart from "./DashboardColumnChart";
import DashboardLinesChart from "./DashboardLinesChart";

const Dashboard = () => {
  return (
    <div>
      {/*<h4 className="main-content-header">Dashbord</h4>*/}
      <div className="row">
        <div className="col-lg-12 col-xl-6">
          <DashboardColumnChart/>
        </div>
        <div className="col-lg-12 col-xl-6">
          <DashboardLinesChart/>
        </div>
        <div className="col-lg-12 col-xl-6">
          <DashboardBookingRequestQuestion/>
        </div>
        <div className="col-lg-12 col-xl-6">
          <DashboardMessages/>
        </div>
        <div className="col-lg-12">
          <DashboardBookingReports/>
        </div>
        <div className="col-lg-12">
          <DashboardCriticalRisks/>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
