import { ConsultantProjects } from "types/consultant";

export function getProjectNames(projects: ConsultantProjects[]) {
  return projects.reduce<string[]>((acc, curr) => {
    const { company, project } = curr;

    if (!company) {
      return [...acc, project?.name || ""];
    }

    return [...acc, `${company.name} - ${project?.name}`];
  }, []);
}
