import {ISpecialism} from "../models/specialism.interface";
import {extractId} from "./converter-helper";

export function extractL1SpecialismNames(specialisms: ISpecialism[]): string[] {
  return specialisms
    .map((specialism: ISpecialism) => specialism.parentType ? specialism.parentType.name : specialism.name)
    .filter((item, index, arr) => arr.indexOf(item) === index);
}

export function extractL1SpecialismIds(specialisms: ISpecialism[]): number[] {
  return specialisms
    .map((specialism: ISpecialism) => specialism.parentType ?
      extractId(specialism.parentType["@id"]) : extractId(specialism["@id"]))
    .filter((item, index, arr) => arr.indexOf(item) === index);
}

export function extractAllSpecialismIds(specialisms: ISpecialism[]): number[] {
  return specialisms
    .map((specialism: ISpecialism) => extractId(specialism["@id"]))
    .filter((item, index, arr) => arr.indexOf(item) === index);
}

export function extractSpecialisms(specialisms: ISpecialism[]): any[] {
  const newArray: any = [];
  const oldArray: any = specialisms;

  oldArray.forEach(oldItem => {
    const index = newArray.findIndex((newItem: { l1: string, l2: string[] }) => newItem.l1 === oldItem.parentType.name);

    if (oldItem.parentType === null) {
      newArray.push({
        l1: oldItem.name,
        l2: []
      });
    } else if (index === -1) {
      newArray.push({
        l1: oldItem.parentType.name,
        l2: [oldItem.name]
      });
    } else {
      newArray[index] = {
        ...newArray[index],
        l2: [
          ...newArray[index].l2,
          oldItem.name
        ]
      };
    }
  });

  return newArray;
}

