import { ColumnType } from "components/table/Table";
import { IUsers } from "models/users.interface";
import React from "react";
import {
  Archive,
  ResetPassword
} from "containers/admin/testers/TesterList/columns";
import TableCellEdit from "components/table/TableCellEdit";

export function getConfig({
  baseUrl,
  status,
  onReset,
  onArchive,
  onUnArchive
}): Array<ColumnType<IUsers>> {
  return [
    {
      key: "name",
      name: "Name",
      render(user) {
        return `${user.firstName}  ${user.lastName}`;
      }
    },
    {
      key: "email",
      name: "Email",
      render(user) {
        return user.email;
      }
    },
    {
      key: "groupName",
      name: "Role",
      render(user) {
        return user.groupName;
      }
    },
    {
      key: "deleted_at",
      name: "Reset Password",
      render(user) {
        return React.createElement(ResetPassword, {
          user,
          onReset
        });
      }
    },
    {
      key: "edit",
      name: "Edit",
      render(item) {
        return React.createElement(TableCellEdit, {
          item,
          baseUrl,
          status,
          Wrapper: React.Fragment
        });
      }
    },
    {
      key: "archive",
      name: "Archive",
      render(item) {
        return React.createElement(Archive, {
          user: item,
          onArchive,
          onUnArchive
        });
      }
    }
  ];
}
