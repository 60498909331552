import {IConverterToSwagger} from "./converter.interface";

export const convertorToSwagger: IConverterToSwagger<any, any> = model => {
  if (model.name) {
    model.name = model.name.trim();
  }

  if (model.address) {
    model.address = model.address.trim();
  }

  if (model.addressLine2) {
    model.addressLine2 = model.addressLine2.trim();
  }

  if (model.addressLine3) {
    model.addressLine3 = model.addressLine3.trim();
  }

  if (model.town) {
    model.town = model.town.trim();
  }

  if (model.state) {
    model.state = model.state.trim();
  }

  if (model.postCode) {
    model.postCode = model.postCode.trim();
  }

  return model;
};
