import * as React from "react";
import {
  composeValidators,
  fieldMaxLength,
  fieldMinLength,
  fieldsNotEmpty
} from "../../../helpers/validations/validation";
import { InjectedFormProps, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { result } from "lodash";
import parameterFetcher from "../../../components/parametersFetcher";
import routerModal from "../../../components/routerModal";
import selector from "../../../selectors/risks";
import {
  riskTreatmentsRequest,
  putItemAction
} from "../../../actions/risk-treatments";
import RiskTreatmentForm from "./RiskTreatmentForm";
import { IRiskTreatmentFormValues } from "./RiskTreatmentCreate";
import { FIELD_DESCRIPTION_MAX_LENGTH, MIN_FIELD_LENGTH } from "./const";

interface IFormValues extends IRiskTreatmentFormValues {
  id: string;
}

const FORM_NAME = "RiskTreatmentUpdate";

class RiskTreatmentUpdate extends React.Component<
  { match: { params: { id: string } } } & InjectedFormProps<IFormValues, {}> & {
      putData: (value: {}, formName: string, backRoute: string) => void;
      backRoute: string;
    }
> {
  render() {
    const { handleSubmit, putData, backRoute, initialValues } = this.props;

    return (
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit((values: IFormValues) => {
          putData(values, FORM_NAME, backRoute);
        })}
      >
        <RiskTreatmentForm
          backRoute={backRoute}
          initialValues={initialValues}
        />
      </form>
    );
  }
}

const formConnected = reduxForm({
  form: FORM_NAME,
  validate: composeValidators(
    fieldsNotEmpty(["action", "residualRiskRating", "comment", "status"]),
    fieldMinLength(["comment"], MIN_FIELD_LENGTH),
    fieldMaxLength(["comment"], FIELD_DESCRIPTION_MAX_LENGTH)
  )
})(RiskTreatmentUpdate);

const connected = parameterFetcher(
  connect(
    (state: any, props: any) => {
      const risk = selector.getItemById(
        result(props, "match.params.id", false)
      )(state);

      return risk
        ? {
            initialValues: {
              risk,
              action: risk.treatment.action,
              status: risk.treatment.status,
              comment: risk.treatment.comment,
              residualRiskRating: risk.treatment.residualRiskRating,
              id: risk.treatment.id
            }
          }
        : { initialValues: { risk: {} } };
    },
    {
      putData: putItemAction
    }
  )(formConnected),
  FORM_NAME,
  riskTreatmentsRequest.getItem,
  selector.getItemById
);

export default routerModal(connected);
