import React from "react";
import Button from "@material-ui/core/Button/Button";
import Icon from "@material-ui/core/Icon/Icon";

export const AddRepeaterFieldButton = (props) => {
  const {fields: {name, push}} = props;
  const initialValues = {
    emailDomains: {domain: ""},
    ipRanges: {address: "", note: ""}
  }


  return (
    <Button
      className="action-button"
      variant="fab"
      type="button"
      color="secondary"
      onClick={() => push(initialValues[name])}
      style={{height: 40, width: 40}}
    >
      <Icon>add</Icon>
    </Button>
  )
}