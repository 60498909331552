import * as React from 'react';
import {Field} from 'redux-form';
import ReduxTextField from "../../../../components/forms/ReduxTextField";
import Icon from "@material-ui/core/Icon/Icon";
import Button from "@material-ui/core/Button/Button";
import {FieldsProps} from "redux-form/lib/FieldArray";
import {IManagedServices} from "../../../../models/managed-services.interface";
import ReduxSelectField from "../../../../components/forms/ReduxSelectField";
import {FieldGeneric} from "../../../../components/forms/FieldGeneric";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import { validateDynamicFieldNameMinLength } from "../../../../helpers/validations/validation";
import { ICompanyOption } from "./ManagedServicesUpdateList";

interface IProps {
  fields: FieldsProps<IManagedServices>;
  index: number;
  member: string;
  level: number;
  companies: ICompanyOption[];
  isAdditionalServices?: boolean;
}

export class ManagedServicesUpdateListElement extends React.Component<IProps, {}> {
    removeField = () => this.props.fields.remove(this.props.index);
    render() {
      const { member, companies, isAdditionalServices } = this.props;
        return (
            <li>
                <div className="row">
                    <div className="col-sm-1 d-flex align-items-center">
                       <Button
                           className="action-button array-button"
                           variant="fab"
                           type="button"
                           color="secondary"
                           onClick={this.removeField}>
                           <Icon>delete</Icon>
                       </Button>
                    </div>
                    <div className="col-sm-2">
                        <FieldGeneric
                            name={`${member}.company`}
                            component={ReduxSelectField}
                            label="Client"
                        >
                            <MenuItem value="">
                                None
                            </MenuItem>
                            {companies.map((company) => {
                                return(
                                    <MenuItem key={company.id} value={company.id}>
                                        {company.name}
                                    </MenuItem>
                                )
                            })}
                        </FieldGeneric>
                    </div>
                  {!isAdditionalServices && (<>
                    <div className="col-sm-2">
                        <Field
                            name={`${member}.price`}
                            type="number"
                            component={ReduxTextField}
                            label="Price per day"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">£</InputAdornment>,
                            }}
                        />
                    </div>
                    <div className="col-sm-2">
                        <Field
                            name={`${member}.daysPercent`}
                            type="number"
                            component={ReduxTextField}
                            label="Days from proposal"
                            InputProps={{
                                endAdornment: <InputAdornment position="start">%</InputAdornment>,
                            }}
                        />
                    </div>

                    <div className="col-sm-3">
                        <Field
                            inputProps={{minLength: 2, maxLength: 50}}
                            validate={validateDynamicFieldNameMinLength}
                            name={`${member}.description`}
                            type="text"
                            component={ReduxTextField}
                            label="Service name"
                        />
                    </div>
                  </>)}
                </div>
            </li>
        );
    }
}