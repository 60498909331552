import React, { useEffect } from "react";
import { TIME_SHEETS_SEARCH_FORM } from "constants/timesheet-expenses";
import { formValueSelector } from "redux-form";
import selector from "selectors/time-expenses";
import { getUserPermissions } from "selectors/auth";
import { bindActionCreators } from "redux";

import { connect } from "react-redux";
import { getTimeExpensesWeeksAction } from "actions/time-expenses";
import {
  ReducedWeekType,
  TimeSheetsFilterParamsType,
  TimeSheetWeeksListProps
} from "types/timesheets-and-expenses";
import { getFilterPreviewValue } from "helpers/component-prev-values";
import { TimeSheetWeek } from "./TimeSheetWeek";
import { TableNoItems } from "../../table/TableNoItems";
import { joinEmptyActiveWeeks } from "helpers/timesheets-expenses";
import { getYearMonth } from "../../forms/YearMonthPicker/helpers";
import { Permission } from "constants/permission";

const searchFormSelector = formValueSelector(TIME_SHEETS_SEARCH_FORM);

const getShouldUpdateValues = (
  prevSearchValues: TimeSheetsFilterParamsType,
  searchValues: TimeSheetsFilterParamsType
) => {
  return JSON.stringify(prevSearchValues) !== JSON.stringify(searchValues);
};

const TimeSheetWeeksList = (props: TimeSheetWeeksListProps) => {
  const {
    weeksItems,
    searchFormFilters,
    loadItems,
    isLoaded,
    selectedProjectWeeks,
    permissions = [],
    asConsultant = false,
    projectsList = []
  } = props;

  let weeks = weeksItems;

  if (asConsultant) {
    weeks = joinEmptyActiveWeeks(
      weeksItems,
      projectsList,
      searchFormFilters.yearMonth
    );
  }

  const prevSearchValues = getFilterPreviewValue(searchFormFilters, {});
  const shouldRerenderList = getShouldUpdateValues(
    prevSearchValues,
    searchFormFilters
  );

  useEffect(() => {
    if (shouldRerenderList) {
      loadItems(searchFormFilters);
    }
  }, [shouldRerenderList]);

  if (!isLoaded) {
    return <TableNoItems asLoading hasWrapper className="mt-4" />;
  }

  if (isLoaded && !weeks.length) {
    return <TableNoItems hasWrapper className="mt-4" />;
  }

  return (
    <div className="pt-4">
      {weeks.map((week: ReducedWeekType) => {
        return (
          <TimeSheetWeek
            week={week}
            key={week.yearWeek}
            selectedProjectWeeks={selectedProjectWeeks}
            canManageClients={permissions.includes(Permission.CAN_MANAGE_CLIENTS)}
            canManageConsultants={permissions.includes(Permission.CAN_MANAGE_CONSULTANTS)}
            canManageTimeExpenses={permissions.includes(Permission.CAN_MANAGE_TIME_EXPENSES)}
            canViewPlatformPrices={permissions.includes(Permission.CAN_VIEW_PLATFORM_INVOICES_AND_COSTS)}
          />
        );
      })}
    </div>
  );
};

function mapStateToProps(state, props) {
  let yearMonth = searchFormSelector(state, "yearMonth") ?? getYearMonth();
  let searchFormFilters = { yearMonth };
  if (props?.asAdmin) {
    searchFormFilters = searchFormSelector(
      state,
      "yearMonth",
      "companies",
      "projects",
      "consultants",
      "statuses"
    );
  }
  return {
    permissions: getUserPermissions(state),
    weeksItems: selector.getTimeExpensesWeeks(state),
    selectedProjectWeeks: selector.getSelectedProjectWeeks(state),
    isLoaded: selector.getIsLoaded(state),
    searchFormFilters
  };
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      loadItems: getTimeExpensesWeeksAction
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeSheetWeeksList);
