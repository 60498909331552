import {ITesterProfileState} from "../reducers/tester-profile";
import {createSelector} from "reselect";

const getTesterProfile: (any: any) => ITesterProfileState = state => state.testerProfile;

export const getProfile = createSelector(
  getTesterProfile,
  state => state.profile
);

export const getBankDetails = createSelector(
  getTesterProfile,
  state => state.bankDetails
);

export const getTesterSpecialisms = createSelector(
  getTesterProfile,
  state => state.specialisms
);

export const getSpecialismsEditOpen = createSelector(
  getTesterProfile,
  state => state.specialismsEditOpen
);
