import React from "react";
import {connect} from "react-redux";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Radio from "@material-ui/core/Radio/Radio";
import selector from "../../../../selectors/locations";
import {locationsFilter} from "../../../../actions/locations";
import {Field, reduxForm} from "redux-form";
import * as FilterTypes from "../../../../constants/filter";
import {bindActionCreators} from "redux";
import ReduxRadioButton from "../../../../components/forms/ReduxRadioButton";
import ReduxTextField from "../../../../components/forms/ReduxTextField";
import {formValueSelector} from "redux-form";
import {isChangesExist} from "../../../../helpers/props-checker";

const FORM_NAME = "LocationsSearchForm";
const selectorForm = formValueSelector(FORM_NAME);

class LocationsSearchForm extends React.Component {
  componentWillReceiveProps(nextProps) {
    if (isChangesExist(["valuesData"], nextProps, this.props)) {
      this.props.submitFilterChanges(nextProps.valuesData.active, nextProps.valuesData.text);
    }
  }

  render() {
    const {handleSubmit} = this.props;

    return (
      <form
        className="mb-3"
        onSubmit={handleSubmit((values) => {
          this.props.submitFilterChanges(values.active, values.text);
        })}>
        <div className="styled-block">
          <div className="row">
            <div className="col-md-12">
              <Field
                name="text"
                label="Find"
                margin="normal"
                fullWidth
                component={ReduxTextField}
              />
            </div>
            <div className="col-md-8">
              <Field
                name="active"
                margin="normal"
                fullWidth
                inline={true}
                component={ReduxRadioButton}
              >
                <FormControlLabel
                  value={FilterTypes.ACTIVE}
                  control={<Radio/>}
                  label="Active"/>
                <FormControlLabel
                  value={FilterTypes.ARCHIVED}
                  control={<Radio/>}
                  label="Archived"/>
                <FormControlLabel
                  value={FilterTypes.ALL}
                  control={<Radio/>}
                  label="All"/>
              </Field>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    valuesData: {...selectorForm(state, "text", "active")},
    initialValues: {
      find: selector.getFilterTextFields(state),
      active: selector.getFilterActivityStatus(state),
    },
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    submitFilterChanges: bindActionCreators(locationsFilter.submitFilterChanges, dispatch),
  };
};

const connectForm = reduxForm({
  form: FORM_NAME,
})(LocationsSearchForm);

export default connect(mapStateToProps, mapDispatchToProps)(connectForm);
