import * as React from "react";
import {Field} from "redux-form";
import ReduxTextField from "../../../../components/forms/ReduxTextField";
import Grid from "@material-ui/core/Grid/Grid";
import {Button} from "@material-ui/core";
import {Link} from "react-router-dom";
import {ButtonProps} from "@material-ui/core/Button/Button";
import {IBackRoute} from "../../../../components/routerModal";

const FaqForm: React.SFC<IBackRoute> = ({backRoute}) => {
  return (
    <Grid container spacing={40}>
      <Grid item xs={12}>
        <Field
          name="question"
          label="Question *"
          rows={2}
          multiline={true}
          component={ReduxTextField}
        />
        <Field
          name="answer"
          label="Answer *"
          rows={10}
          multiline={true}
          component={ReduxTextField}
        />
        <br/>
        <br/>
        <div className="d-flex justify-content-between mt-4">
          <Button variant="contained"
                  color="default"
                  component={Link as React.ReactType<ButtonProps>}
                  {...{to: backRoute}}>
            Cancel
          </Button>
          <Button type="submit" variant="contained" color="primary">
            Save
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

export {FaqForm};
