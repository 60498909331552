import React, { ReactNode } from "react";
import { LinkCell } from "./LinkCell";
type TextLinkCellProps = {
  id: string | number;
  name: ReactNode;
  asLink?: boolean;
  baseUrl: string;
};

export const TextLinkCell = ({
  id,
  name,
  asLink,
  baseUrl
}: TextLinkCellProps) => {
  if (!name) {
    return null;
  }
  if (asLink) {
    const url = `${baseUrl}/${id}`;
    return <LinkCell baseUrl={url} title={name} />;
  }
  return <>{name}</>;
};
