import { convertorFromSwagger, convertorToSwagger } from '../convertors/specialisms';
import * as FilterActions from '../../constants/filter';
import { fetchRequestBody, dummyItemsPagination } from '../../helpers/request-helper';
import { getApi } from '../swagger/api-factory';
import {ISpecialism} from "../../models/specialism.interface";
import {IAnyProps} from "../../interfaces/any-props.interface";
import {ISpecialismChanges} from "../../interfaces/specialisms-changes.interface";

export const getItems = (page: number, itemsPerPage: number, show: string, text: string = '') => {
  const isShow = show || FilterActions.DEFAULT_FILTER;
  const parameters: IAnyProps = { page };

  parameters.items = itemsPerPage;
  parameters.show = isShow;

  if (text) {
    parameters.question = text;
  }

  return getApi()
    .then(api => api.getApiSpecialisms(parameters))
    .then(fetchRequestBody)
    .then(dummyItemsPagination);
};

export const getItem = (id: string|number) => {
  return getApi()
    .then(api => api.getSpecialismItem({ id }))
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};

export const postItem = (model: ISpecialismChanges) => {
  return getApi()
    .then(api => api.postApiSpecialisms({
      specialisms: model,
    }))
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};

export const putItem = (model: ISpecialism) => {
  return getApi()
    // .then(api => api.put({
    //   id: model.id,
    //   specialism: convertorToSwagger(model),
    // }))
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};

export const deleteItem = (id: string|number) => {
  return getApi()
    // .then(api => api.deleteSpecialismItem({ id, }))
    .then(fetchRequestBody);
};
