import React from "react";
type InfoMessageProps = {
  message?: string;
};

export const InfoMessage = ({ message }: InfoMessageProps) => {
  if (!message) {
    return null;
  }

  return (
    <div>
      <span className="timesheet-info-message">{message}</span>
    </div>
  );
};
