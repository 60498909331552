import React, { useCallback } from "react";
import Icon from "@material-ui/core/Icon/Icon";
import { IUsers } from "models/users.interface";
import { OnReset } from "../constants";
import { ConfirmWrapper } from "./ConfirmWrapper";

type DeleteProps = {
  user: IUsers,
  onDelete: OnReset;
};

export function Delete({user, onDelete}: DeleteProps) {
  const onConfirm = useCallback(() => {
    onDelete(user);
  }, []);

  return <ConfirmWrapper
    onConfirm={onConfirm}
    message="The account will be completely deleted"
    buttonText="DELETE FOREVER"
  >
    <Icon style={{color: "#778596"}}>delete</Icon>
  </ConfirmWrapper>
}
