import * as React from "react";
import TextField from "@material-ui/core/TextField";
import { WrappedFieldProps } from "redux-form/lib/Field";
import { ComponentType } from "react";

type IComponentType = ComponentType<
  WrappedFieldProps & {
    [key: string]: any;
  }
>;

const ReduxTextField: IComponentType = ({
  input,
  label,
  margin = "normal",
  meta: { touched, error },
  id,
  ...custom
}) => {
  return (
    <TextField
      fullWidth={true}
      error={!!touched && !!error}
      helperText={touched && error ? error : ""}
      {...input}
      {...custom}
      label={label}
      margin={margin}
    />
  );
};

export default ReduxTextField;
