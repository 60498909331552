import React from "react";
import { FormValuesType } from "types/consultant";
import ConsultantForm from "../components/ConsultantForm";
import { bindActionCreators, Dispatch } from "redux";
import { createNewConsultantAction } from "actions/consultants";
import { connect } from "react-redux";
import { ConnectedProps } from "types";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { AdminUrls } from "constants/admin-urls";

type Props = PropsFromRedux & RouteComponentProps;

export function CreateConsultantPage({ history, createNewConsultant }: Props) {
  const goBackHandler = () => {
    history.push(AdminUrls.CONSULTANTS);
  };

  const onSubmitHandler = (values: FormValuesType) => {
    createNewConsultant(values, goBackHandler);
  };

  return <ConsultantForm goBack={goBackHandler} onSubmit={onSubmitHandler} />;
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      createNewConsultant: createNewConsultantAction
    },
    dispatch
  );

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default withRouter(connector(CreateConsultantPage));
