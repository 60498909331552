import * as React from 'react';
import {
  composeValidators, fieldMaxLength, fieldMinLength,
  fieldsNotEmpty
} from "../../../helpers/validations/validation";
import {InjectedFormProps, reduxForm} from "redux-form";
import { connect } from "react-redux";
import { workHistoryRequest } from "../../../actions/work-history";
import WorkHistoryForm from "./WorkHistoryForm";
import { withStyles } from "@material-ui/core/styles/index";
import { withRouter } from 'react-router-dom'
import { defaultStyleWidth } from '../../../styles/material/popup-style';
import routerModal from '../../../components/routerModal';
import {WithStyles} from "@material-ui/core/styles/withStyles";

const FORM_NAME = 'WorkHistoryCreate';

class WorkHistoryCreate extends React.Component<InjectedFormProps<{}, {}> & {
  postData: (...any) => void,
  backRoute: string
} & WithStyles<any>> {
  render() {
    const { handleSubmit, postData, backRoute } = this.props;

    return (
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit((values) => {
          postData(values, FORM_NAME, backRoute);
        })}
      >
        <WorkHistoryForm backRoute={backRoute}/>
      </form>
    );
  }
}

const formConnected = reduxForm({
  form: FORM_NAME,
  validate: composeValidators(
    fieldsNotEmpty(['employer', 'jobTitle', 'dateFrom', 'dateTo',]),
    fieldMinLength(['employer', 'jobTitle',], 3),
    fieldMaxLength(['employer', 'jobTitle',], 55),
    (values) => {
      const error = {dateFrom: 'From should not be after To'};

      return (new Date(values.dateFrom) >= new Date(values.dateTo)) ? error : {};
    },
    (values) => {
      const errorMessage = 'Should be before the current day';

      if(new Date() < new Date(values.dateTo)){
        return {dateTo: errorMessage};
      }

      if(new Date() < new Date(values.dateFrom)){
        return {dateFrom: errorMessage};
      }

      return {};
    },
  )
})(WorkHistoryCreate);

const connected = connect(undefined, {
  postData: workHistoryRequest.postItem,
})(formConnected);

/** Todo: cut */
const withStyle = withStyles(defaultStyleWidth(400))(connected as any);

export default routerModal(withRouter(withStyle as any));