import * as React from "react";
import { Link, Route, RouteComponentProps, Switch } from "react-router-dom";
import AdminUsersList from "./AdminUsersList";
import AdminUsersSearchForm from "./AdminUsersSearchForm";
import AdminUsersCreate from "./AdminUsersCreate";
import AdminUsersUpdate from "./AdminUsersUpdate";
import { withRouter } from "react-router-dom";
import { getCreateLink } from "helpers/router-helper";

class AdminUsers extends React.Component<RouteComponentProps<{}>, {}> {
  baseUrl = this.props.match.path;

  createComponent = () => <AdminUsersCreate backRoute={this.baseUrl} />;
  updateComponent = () => <AdminUsersUpdate backRoute={this.baseUrl} />;

  render() {
    return (
      <div>
        <div className="crud-sections-header">
          <h4>Admin Panel Users</h4>
          <Link to={getCreateLink(this.baseUrl)}>
            <button className="btn-new">New User</button>
          </Link>
        </div>
        <Switch>
          <Route
            path={getCreateLink(this.baseUrl)}
            render={this.createComponent}
          />
          <Route path={this.baseUrl + "/:id"} render={this.updateComponent} />
        </Switch>
        <AdminUsersSearchForm />
        <AdminUsersList baseUrl={this.baseUrl} />
      </div>
    );
  }
}

export default withRouter(AdminUsers);
