import React from "react";
import Paper from "@material-ui/core/Paper/Paper";
import Table from "@material-ui/core/Table/Table";
import TableBody from "@material-ui/core/TableBody/TableBody";
import { connect } from "react-redux";
import {
  countriesRequest,
  countriesLoader
} from "../../../../actions/countries";
import selector from "../../../../selectors/countries";
import { TablePagination } from "@material-ui/core";
import EnhancedTableHead from "../../../../components/table/EnhancedTableHead";
import PropTypes from "prop-types";
import { isChangesExist } from "../../../../helpers/props-checker";
import debounce from "debounce";
import EnhancedTableRow from "../../../../components/table/EnhancedTableRow";
import { BASE_URL } from "../../../../constants/urls";

class CountryList extends React.Component {
  debouncedLoad = debounce(nextProps => {
    this.loadItems(nextProps);
  }, 500);

  loadItems = props => {
    props.loadItems(
      props.pageNumber,
      props.itemsPerPage,
      props.showStatus,
      props.filterText
    );
  };

  componentDidMount() {
    this.loadItems(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (
      isChangesExist(
        ["pageNumber", "itemsPerPage", "showStatus", "filterText"],
        nextProps,
        this.props
      )
    ) {
      if (isChangesExist(["filterText"], nextProps, this.props)) {
        this.debouncedLoad.clear();
        this.debouncedLoad(nextProps);
      } else {
        this.loadItems(nextProps);
      }
    }

    if (!nextProps.data.length && nextProps.pageNumber > 1) {
      this.props.setPageNumber(1);
    }
  }

  handleChangePage = (event, page) => {
    this.props.setPageNumber(page + 1);
  };

  handleChangeRowsPerPage = event => {
    this.props.setItemsPerPage(event.target.value);
  };

  onUnarchive = item => {
    this.props.unarchiveItem(
      item.id,
      this.props.pageNumber,
      this.props.itemsPerPage,
      this.props.showStatus
    );
  };

  onArchive = item => {
    this.props.archiveItem(
      item.id,
      this.props.pageNumber,
      this.props.itemsPerPage,
      this.props.showStatus
    );
  };

  render() {
    if (!this.props.isLoaded) {
      return <div>Loading</div>;
    }

    if (!this.props.data.length) {
      return <div>There are no items!</div>;
    }

    return (
      <Paper style={{ overflowX: "auto", width: "100%" }} className="mb-4">
        <Table>
          <EnhancedTableHead
            columns={["Flag", "Country", "Code"]}
            status={this.props.showStatus}
          />
          <TableBody>
            {this.props.data.map(item => {
              return (
                <EnhancedTableRow
                  key={item.id}
                  item={item}
                  baseUrl={this.props.baseUrl}
                  showStatus={this.props.showStatus}
                  onArchive={this.onArchive}
                  onUnarchive={this.onUnarchive}
                  needActions
                >
                  {item.flagImagePath && (
                    <img
                      className="table-image"
                      src={BASE_URL + item.flagImagePath}
                      alt={item.name}
                    />
                  )}
                  {item.name}
                  {"+" + item.dialingCode}
                </EnhancedTableRow>
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={this.props.totalItems}
          rowsPerPage={this.props.itemsPerPage}
          page={this.props.pageNumber - 1}
          backIconButtonProps={{
            "aria-label": "Previous Page"
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page"
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Paper>
    );
  }
}

CountryList.propTypes = {
  baseUrl: PropTypes.string.isRequired
};

export default connect(
  state => {
    return {
      data: selector.getItems(state),
      isLoaded: selector.getIsLoaded(state),
      itemsPerPage: selector.getItemsPerPage(state),
      pageNumber: selector.getPageNumber(state),
      showStatus: selector.getFilterActivityStatus(state),
      totalItems: selector.getTotalItems(state),
      filterText: selector.getFilterTextFields(state)
    };
  },
  {
    loadItems: countriesRequest.getItems,
    setItemsPerPage: countriesLoader.setItemsPerPage,
    setPageNumber: countriesLoader.setPageNumber,
    archiveItem: countriesRequest.archiveItem,
    unarchiveItem: countriesRequest.unarchiveItem
  }
)(CountryList);
