import {fetchRequestBody} from '../../helpers/request-helper';
import {getApi, getApiJSON, getApiJSONLD} from '../swagger/api-factory';
import {convertorFromSwagger} from '../convertors/default-convertor';


export const getTestersReviewsRequest = (id: string | number) => {
  return getApiJSON()
    .then(api => api.api_tester_profiles_reviews_get_subresource({id}))
    .then(fetchRequestBody);
};

export const postReview = (reportId: number | string, rang: number | string, comment: string) => {
  return getApi()
    .then(api => api.postApiReviews({review: {
      reportId,
      rang,
      comment
    }}))
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};
