import * as React from "react";
import {
  composeValidators,
  fieldIsEmail,
  fieldsNotEmpty
} from "helpers/validations/validation";
import { InjectedFormProps, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { usersRequest } from "actions";
import parameterFetcher from "components/parametersFetcher";
import selector from "selectors/users";
import AdminUsersForm from "./form/AdminUsersForm";
import routerModal from "components/routerModal";
import { WithStyles } from "@material-ui/core/styles/withStyles";

const FORM_NAME = "AdminUsersUpdate";

class AdminUsersUpdate extends React.Component<
  InjectedFormProps<{}, {}> & {
    putData: (...any) => void;
    backRoute: string;
    initialValues: { role?: string };
  } & WithStyles<any>,
  any
> {
  render() {
    const { handleSubmit, putData, backRoute, initialValues } = this.props;

    return (
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(values => {
          putData(values, FORM_NAME, backRoute);
        })}
      >
        <AdminUsersForm
          backRoute={backRoute}
          defaultRole={initialValues.role}
        />
      </form>
    );
  }
}

const formConnected = reduxForm({
  form: FORM_NAME,
  validate: composeValidators(
    fieldsNotEmpty(["firstName", "lastName", "email", "role"]),
    fieldIsEmail(["email"])
  )
})(AdminUsersUpdate);

const connected = parameterFetcher(
  connect(undefined, {
    putData: usersRequest.putItem
  })(formConnected),
  FORM_NAME,
  usersRequest.getItem,
  selector.getItemById,
  item => {
    let permissions = item.dynamicPermissions;

    if (Array.isArray(permissions)) {
      permissions = {};

      item.dynamicPermissions.forEach(item => {
        permissions[item.name] = true;
      });
    }

    return {
      ...item,
      role: item.groupName,
      dynamicPermissions: undefined,
      permissions
    };
  }
);

export default routerModal(connected);
