import { convertorFromSwagger, convertorToSwagger } from '../convertors/default-convertor';
import * as FilterActions from '../../constants/filter';
import {fetchRequestBody, dummyItemsPagination, extractJSONLD} from '../../helpers/request-helper';
import { getApi, getApiJSONLD } from '../swagger/api-factory';
import {IAnyProps} from "../../interfaces/any-props.interface";
import {IProject} from "../../models/project.interface";

export const getInvoicesRequest = (
  page,
  itemsPerPage,
  showStatus,
  no: string,
  dateFrom: Date,
  dateTo: Date,
  client: string,
  po: string,
  status: string,
) => {
  const parameters: IAnyProps = { page };

  parameters.items = itemsPerPage;
  parameters.xeroInvoiceNumber = no;
  parameters.dateStrictlyBefore = dateTo;
  parameters.dateStrictlyAfter = dateFrom;
  parameters.client = client;
  parameters.status = status;
  parameters.purpose = [1,2,];

  return getApiJSONLD()
    .then(api => api.getInvoiceCollection(parameters))
    .then(extractJSONLD(convertorFromSwagger));
};

export const getInvoiceRequest = (id: string|number) => {
  return getApi()
    .then(api => api.getInvoiceItem({ id }))
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};

export const downloadInvoiceRequest = (id: string|number) => {
  return getApi()
    .then(api => api.downloadInvoiceItem({ id }))
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};
