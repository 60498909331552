import { TM_ACTION_TYPES } from "../constants/action-type";
import {
  DayByIdActionType,
  ReducedWeekType,
  TimeExpensesCategory,
  TimesheetManagerType,
  TimesheetPoNumberType,
  TimesheetPoNumberUpdateParams,
  UpdatePoNumberPayload
} from "../types/timesheets-and-expenses";
import { PO_NUMBER_TYPE } from "../constants/timesheet-expenses";

export type TimeExpensesStateType = {
  isLoaded: boolean;
  timeExpensesWeeks: ReducedWeekType[];
  selectedDayData: DayByIdActionType | null;
  selectedProjectWeeks: number[];
  timeCategoriesList: TimeExpensesCategory[];
  expensesTypesList: TimeExpensesCategory[];
  archivedDocuments: number[];
  timesheetManagers: TimesheetManagerType[];
};

type Keys = keyof typeof TM_ACTION_TYPES;
type Values = typeof TM_ACTION_TYPES[Keys];

export type TimeExpensesActionType = {
  type: Values;
  payload: any;
};

export const timeExpensesDefaultValue: TimeExpensesStateType = {
  isLoaded: false,
  timeExpensesWeeks: [],
  selectedDayData: null,
  selectedProjectWeeks: [],
  timeCategoriesList: [],
  expensesTypesList: [],
  archivedDocuments: [],
  timesheetManagers: []
};

export const getTimeExpensesActions = () => ({
  setIsLoaded: (isLoaded: boolean) => ({
    type: TM_ACTION_TYPES.SET_IS_LOADED,
    payload: isLoaded
  }),
  setTimeExpensesWeeks: (items: any[], totalItems: number) => ({
    type: TM_ACTION_TYPES.SET_TIME_EXPENSES_WEEKS,
    payload: { items, totalItems }
  }),
  setSelectedDayData: (data: DayByIdActionType | null) => ({
    type: TM_ACTION_TYPES.SET_SELECTED_DAY_DATA,
    payload: { data }
  }),
  addSelectedProjectWeeksItems: (data: number[]) => ({
    type: TM_ACTION_TYPES.ADD_SELECTED_PROJECT_WEEKS,
    payload: data
  }),
  removeSelectedProjectWeeksItems: (data: number[]) => ({
    type: TM_ACTION_TYPES.REMOVE_SELECTED_PROJECT_WEEKS,
    payload: data
  }),
  clearSelectedProjectWeeksItems: () => ({
    type: TM_ACTION_TYPES.CLEAR_SELECTED_PROJECT_WEEKS,
    payload: []
  }),
  setTimeCategoriesList: (categories: TimeExpensesCategory[] = []) => ({
    type: TM_ACTION_TYPES.SET_TIME_CATEGORIES_LIST,
    payload: categories
  }),
  setExpensesTypesList: (categories: TimeExpensesCategory[] = []) => ({
    type: TM_ACTION_TYPES.SET_EXPENSES_TYPES_LIST,
    payload: categories
  }),
  addArchivedDocument: (id: number) => ({
    type: TM_ACTION_TYPES.ADD_ARCHIVED_DOCUMENT,
    payload: id
  }),
  clearArchivedDocumentsList: () => ({
    type: TM_ACTION_TYPES.CLEAR_ARCHIVED_DOCUMENTS_LIST,
    payload: []
  }),
  setTimesheetManagersList: (managers: TimesheetManagerType[]) => ({
    type: TM_ACTION_TYPES.SET_TIMESHEET_MANAGERS_LIST,
    payload: managers
  }),
  setTimesheetWeekPoNumber: (payload: UpdatePoNumberPayload) => ({
    type: TM_ACTION_TYPES.SET_TIMESHEET_PO_NUMBER,
    payload
  })
});

export default function(
  state: TimeExpensesStateType = timeExpensesDefaultValue,
  action: TimeExpensesActionType
) {
  switch (action.type) {
    case TM_ACTION_TYPES.SET_IS_LOADED:
      return { ...state, isLoaded: action.payload };
    case TM_ACTION_TYPES.SET_TIME_EXPENSES_WEEKS:
      return {
        ...state,
        timeExpensesWeeks: action.payload.items,
        timeExpensesWeeksTotal: action.payload.totalItems
      };
    case TM_ACTION_TYPES.SET_SELECTED_DAY_DATA:
      return {
        ...state,
        selectedDayData: action.payload.data
      };
    case TM_ACTION_TYPES.ADD_SELECTED_PROJECT_WEEKS:
      return {
        ...state,
        selectedProjectWeeks: [...state.selectedProjectWeeks, ...action.payload]
      };
    case TM_ACTION_TYPES.REMOVE_SELECTED_PROJECT_WEEKS:
      const selectedProjectWeeks = state.selectedProjectWeeks.filter(
        item => !action.payload.includes(item)
      );
      return {
        ...state,
        selectedProjectWeeks
      };
    case TM_ACTION_TYPES.CLEAR_SELECTED_PROJECT_WEEKS:
      return {
        ...state,
        selectedProjectWeeks: []
      };
    case TM_ACTION_TYPES.SET_TIME_CATEGORIES_LIST:
      return {
        ...state,
        timeCategoriesList: action.payload
      };
    case TM_ACTION_TYPES.SET_EXPENSES_TYPES_LIST:
      return {
        ...state,
        expensesTypesList: action.payload
      };
    case TM_ACTION_TYPES.ADD_ARCHIVED_DOCUMENT:
      const { archivedDocuments } = state;
      return {
        ...state,
        archivedDocuments: [...archivedDocuments, action.payload]
      };
    case TM_ACTION_TYPES.CLEAR_ARCHIVED_DOCUMENTS_LIST:
      return {
        ...state,
        archivedDocuments: action.payload
      };
    case TM_ACTION_TYPES.SET_TIMESHEET_MANAGERS_LIST:
      return {
        ...state,
        timesheetManagers: action.payload
      };
    case TM_ACTION_TYPES.SET_TIMESHEET_PO_NUMBER:
      const { id, data } = action.payload;

      return {
        ...state,
        timeExpensesWeeks: state.timeExpensesWeeks.map(timeExpensesWeek => {
          const projectsWeeks = timeExpensesWeek.projectsWeeks.map(week =>
            week.id === +id ? { ...week, ...data } : week
          );

          return { ...timeExpensesWeek, projectsWeeks };
        })
      };
    default:
      return state;
  }
}
