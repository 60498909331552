import {
  converterFromSwaggerCalendar,
  convertorFromSwagger,
  convertorToSwagger
} from "api/convertors/test";
import { convertorFromSwagger as defaultConvertorFromSwagger } from "api/convertors/default-convertor";
import * as FilterActions from "constants/filter";
import { fetchRequestBody, extractJSONLD } from "helpers/request-helper";
import { getApi, getApiJSONLD } from "api/swagger/api-factory";
import { IAnyProps } from "interfaces/any-props.interface";
import { ITest } from "models/test.interface";
import { format } from "date-fns";
import { MAX_PAGINATION_RESULTS } from "constants/loader";
import { TestStatus } from "constants/test-status";
import { ALL } from "constants/filter";
import { UpdateServiceDateType } from "types/test-info";

interface IBookingParams {
  page: number;
  items: number;
  statuses: string;
  dateFromAfter?: string;
  dateFromBefore?: string;
  show: string;
  projectIds: string;
  questionAsked?: boolean;
}

export const getItems = (
  page: number,
  itemsPerPage: number,
  show: string,
  text: string = ""
) => {
  const isShow = show || FilterActions.DEFAULT_FILTER;
  const parameters: IAnyProps = { page };

  parameters.items = itemsPerPage;
  parameters.show = isShow;

  if (text) {
    parameters.name = text;
  }

  return getApiJSONLD()
    .then(api => api.getTestCollection(parameters))
    .then(extractJSONLD(convertorFromSwagger));
};

export const getTestsRequest = (
  page: number,
  itemsPerPage: number,
  project: number[],
  status: string[],
  dateFromTestStartDate?: string,
  dateToTestStartDate?: string,
  questionAsked?: boolean,
  statuses?: string,
  name?: string,
  plannerName?: string,
  clientCompany?: number,
  testerName?: string,
  testingCompany?: number
) => {
  const parameters = {};

  const allParameters: object = {
    page,
    items: itemsPerPage,
    status,
    statuses,
    dateFromAfter: dateFromTestStartDate,
    dateFromBefore: dateToTestStartDate,
    plannerNameEmail: plannerName,
    testerNameEmail: testerName,
    testingCompany,
    clientCompany,
    name
  };

  Object.keys(allParameters).forEach(key => {
    if (allParameters[key]) {
      parameters[key] = allParameters[key];
    }
  });

  return getApiJSONLD()
    .then(api => api.getTestCollection(parameters))
    .then(extractJSONLD(convertorFromSwagger));
};

export const getBookingRequestsRequest = (
  page: number,
  itemsPerPage: number,
  project: number[],
  status: string[],
  dateFromTestStartDate?: string,
  dateToTestStartDate?: string,
  questionAsked?: boolean
) => {
  const parameters = {};
  const projectIds = project && project.join(",");

  const allParameters: IBookingParams = {
    page,
    items: itemsPerPage,
    statuses: status.join(","),
    dateFromAfter: dateFromTestStartDate,
    dateFromBefore: dateToTestStartDate,
    show: ALL,
    projectIds,
    questionAsked
  };

  Object.keys(allParameters).forEach(key => {
    if (allParameters[key]) {
      parameters[key] = allParameters[key];
    }
  });

  return getApiJSONLD()
    .then(api => api.getTestCollection(parameters))
    .then(extractJSONLD(convertorFromSwagger));
};

export const getItem = (id: string | number) => {
  return getApi()
    .then(api => api.getTestItem({ id }))
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};

export const postItem = (model: ITest) => {
  return getApi()
    .then(api =>
      api.postTestCollection({
        test: convertorToSwagger(model)
      })
    )
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};

export const postCopiedItem = (model: any) => {
  return getApi()
    .then(api =>
      api.postTestCollection({
        test: convertorToSwagger(model)
      })
    )
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};

export const putItem = (id: string, model: ITest) => {
  return getApi()
    .then(api =>
      api.putTestItem({
        id,
        test: convertorToSwagger(model)
      })
    )
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};
export const setFeedback = (id: string) => {
  return getApi()
    .then(api =>
      api.set_feedback_sentTestItem({
        id,
        test: { feedbackSend: true }
      })
    )
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};

export const getInvoiceURL = (id: number) => {
  return getApi()
    .then(api => api.invoice_linkTestItem({ id }))
    .then(fetchRequestBody);
};

export const deleteItem = (id: string | number) => {
  return getApi()
    .then(api => api.deleteTestItem({ id }))
    .then(fetchRequestBody);
};

export const changeTestStatus = (
  id: string | number,
  testStatus: TestStatus
) => {
  return getApi()
    .then(api =>
      api.change_statusTestItem({
        id,
        test: { status: testStatus }
      })
    )
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};

export const getTestDocuments = (
  id: string | number,
  pageNumber?: string | number,
  itemsPerPage?: string | number
) => {
  pageNumber = pageNumber || 1;
  itemsPerPage = itemsPerPage || MAX_PAGINATION_RESULTS;
  return getApi()
    .then(api =>
      api.api_tests_test_documents_get_subresource({
        id,
        $queryParameters: {
          page: pageNumber,
          items: itemsPerPage
        }
      })
    )
    .then(extractJSONLD(defaultConvertorFromSwagger));
};

export const getTestsForCalendar = (start: Date, end: Date) => {
  const parameters: IAnyProps = {};

  parameters.items = MAX_PAGINATION_RESULTS;
  parameters.dateFromBefore = format(new Date(start), "YYYY-MM-DD");
  parameters.dateToAfter = format(new Date(end), "YYYY-MM-DD");

  return getApiJSONLD()
    .then(api => api.getTestCollection(parameters))
    .then(extractJSONLD(converterFromSwaggerCalendar))
    .then(({ items }) => items);
};

export const savePONumberRequest = (PONumber, testId) => {
  return getApi()
    .then(api =>
      api.savePONumber({
        purchaseOrderNumber: { purchaseOrderNumber: PONumber },
        id: testId
      })
    )
    .then(fetchRequestBody);
};

export const updateServiceDatesRequest = async ({
  dateFrom,
  dateTo,
  testId
}: UpdateServiceDateType) => {
  return getApi()
    .then(api =>
      api.updateServiceDates({
        dates: { dateFrom, dateTo },
        id: testId
      })
    )
    .then(fetchRequestBody);
};
