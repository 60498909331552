import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { IAnyProps } from "../../../../interfaces/any-props.interface";
import Grid from "@material-ui/core/Grid";
import ReduxTextField from "../../../../components/forms/ReduxTextField";
import Button from "@material-ui/core/Button";
import { REPORT_DETAILS_CREATE_FORM } from "../../../../constants/forms";
import {
  composeValidators,
  fieldMaxLength,
  fieldsNotEmpty
} from "../../../../helpers/validations/validation";
import { connect } from "react-redux";
import { updateReport } from "../../../../actions/reports";
import {
  changeTestStatusAction,
  IChangeTestStatusAction,
  testRequest
} from "../../../../actions/test";
import { TestStatus } from "../../../../constants/test-status";
import { IReport } from "../../../../models/report.interface";
import { formValueSelector, InjectedFormProps, reduxForm } from "redux-form";
import { FieldGeneric } from "../../../../components/forms/FieldGeneric";
import { Paper } from "@material-ui/core";
import ReduxTextPlaceholder from "../../../../components/forms/ReduxTextPlaceholder";
import { IReportDocument } from "../../../../models/report-documents.interface";
import { openSnackbar } from "../../../../actions";
import { MILLISECONDS_FOR_SHOWING_MESSAGE } from "../../../../actions/const";
import { ReportDocumentStatus } from "../../../../constants/report-document-status";
import testSelector from "../../../../selectors/test";
import { result } from "lodash";
import { ITest } from "../../../../models/test.interface";
import { dataFetcher } from "../../../../components/dataFetcher";
import selector from "../../../../selectors/test";
import { IState } from "../../../../interfaces/state.interface";
import { getByTestId } from "../../../../selectors";
import { ButtonWithTooltip } from "../../../../components/ButtonWithTooltip";

const selectorForm = formValueSelector(REPORT_DETAILS_CREATE_FORM);
const INTRODUCTION_FIELD_MAX_LENGTH = 2000;

interface IProps {
  formSubmit: (reportId, model, backUrl, reportIdsForStatusUpdate) => void;
  changeTestStatus: IChangeTestStatusAction;
  test: ITest;
  canBeSubmitted: boolean;
  report: IReport;
  reportDocuments: IReportDocument[];
  valuesData: {
    summaryIntroduction;
    summaryGathering;
    technicalIntroduction;
    technicalGathering;
  };
}

class ReportDetailsForm extends React.Component<
  RouteComponentProps<any> & IAnyProps & IProps & InjectedFormProps<{}, {}>,
  {}
> {
  disableSummarySaveButton(reportsIdsForStatusesUpdate) {
    const {
      valuesData = {
        summaryIntroduction: null,
        technicalIntroduction: null
      },
      testStatus
    } = this.props;
    const isAllowedStatus = [
      TestStatus.STATUS_AMENDMENT_SENT,
      TestStatus.STATUS_BOOKED,
      TestStatus.STATUS_AMENDMENT_RESPONDED
    ].some(status => status === testStatus);
    const isEmptyFormFields =
      !valuesData?.summaryIntroduction || !valuesData?.technicalIntroduction;
    const isUploadedFileAfterSubmit =
      TestStatus.STATUS_REPORT_SUBMITTED && reportsIdsForStatusesUpdate.length;
    if (isUploadedFileAfterSubmit && !isEmptyFormFields) {
      return false;
    }
    if (!isAllowedStatus || isEmptyFormFields) {
      return true;
    }

    return false;
  }

  canBeSubmittedRequest() {
    const {
      valuesData = {
        summaryIntroduction: null,
        technicalIntroduction: null
      },
      testStatus
    } = this.props;
    const isAllowedStatus = [
      TestStatus.STATUS_BOOKED,
      TestStatus.STATUS_AMENDMENT_RESPONDED,
      TestStatus.STATUS_REPORT_SUBMITTED
    ].some(status => status === testStatus);
    return (
      isAllowedStatus &&
      !!valuesData?.summaryIntroduction &&
      !!valuesData?.technicalIntroduction &&
      testStatus !== TestStatus.STATUS_COMPLETED
    );
  }

  canBeSummariesEdited() {
    const { testStatus } = this.props;

    return (
      [
        TestStatus.STATUS_AMENDMENT_SENT,
        TestStatus.STATUS_BOOKED,
        TestStatus.STATUS_AMENDMENT_RESPONDED
      ].indexOf(testStatus) > -1
    );
  }

  handleSaveSummary = (values, reportIdsForStatusUpdate) => (event: any) => {
    this.props.formSubmit(
      this.props.report.id,
      { ...values },
      this.props.match.url,
      reportIdsForStatusUpdate
    );
  };
  getSubmitReportTooltip = (
    testStatus,
    valuesData,
    hasReportsInAmendmentsStatus,
    reportDocuments
  ) => {
    if (testStatus === TestStatus.STATUS_AMENDMENT_SENT) {
      return "You must answer to all clarifications ";
    }
    if (hasReportsInAmendmentsStatus) {
      return "You must reupload the documents in Clarifications requested status";
    }
    if (
      !valuesData?.summaryIntroduction ||
      !valuesData?.technicalIntroduction
    ) {
      return "Summary fields must be filled in";
    }
    if (!reportDocuments.length) {
      return "Add Report document";
    }
    return "";
  };

  componentDidUpdate() {
    if (this.props.submitSucceeded) {
      this.props.changeTestStatus(
        this.props.match.params.test,
        TestStatus.STATUS_REPORT_SUBMITTED
      );
    }
  }

  render() {
    const {
      valuesData,
      test,
      reportDocuments,
      openSnackbar,
      changeTestStatus,
      handleSubmit
    } = this.props;
    const isCompleted = test.status === TestStatus.STATUS_COMPLETED;
    const hasReportsInAmendmentsStatus = reportDocuments.some(
      ({ status }) =>
        status === ReportDocumentStatus.STATUS_AMENDMENTS_REQUESTED
    );
    const reportsIdsForStatusesUpdate = reportDocuments
      .filter(
        ({ status }) =>
          status === ReportDocumentStatus.STATUS_UPLOADED ||
          status === ReportDocumentStatus.STATUS_REUPLOADED
      )
      .map(item => item?.id);
    const submitReportTooltip = this.getSubmitReportTooltip(
      test.status,
      valuesData,
      hasReportsInAmendmentsStatus,
      reportDocuments
    );

    return (
      <div>
        <form
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(() => {
            if (hasReportsInAmendmentsStatus) {
              openSnackbar(
                "You must reupload the document",
                MILLISECONDS_FOR_SHOWING_MESSAGE
              );
            }
          })}
        >
          <Grid container spacing={16}>
            <Grid item xs={12} sm={12}>
              <h4 className="mt-4">Summaries</h4>
              {/*<Paper className="p-4">*/}
              <div className="styled-block">
                <h5>Executive Summary</h5>
                <FieldGeneric
                  inputProps={{ maxLength: 2000 }}
                  name="summaryIntroduction"
                  margin="normal"
                  label="Introduction *"
                  rows={3}
                  multiline={true}
                  component={
                    this.canBeSummariesEdited() ||
                    reportsIdsForStatusesUpdate.length
                      ? ReduxTextField
                      : ReduxTextPlaceholder
                  }
                />
                <h5 className="mt-4">Technical Report</h5>
                <FieldGeneric
                  inputProps={{ maxLength: 2000 }}
                  name="technicalIntroduction"
                  margin="normal"
                  label="Introduction *"
                  rows={3}
                  multiline={true}
                  component={
                    this.canBeSummariesEdited() ||
                    reportsIdsForStatusesUpdate.length
                      ? ReduxTextField
                      : ReduxTextPlaceholder
                  }
                />
              </div>
              {/*</Paper>*/}
            </Grid>
            {!isCompleted && (
              <>
                <Grid item xs={6}>
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    className="float-left"
                    onClick={this.handleSaveSummary(valuesData, [])}
                    disabled={this.disableSummarySaveButton(
                      reportsIdsForStatusesUpdate
                    )}
                  >
                    Save Summaries
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <ButtonWithTooltip
                    type="submit"
                    variant="contained"
                    color="primary"
                    className="float-right"
                    onClick={this.handleSaveSummary(
                      valuesData,
                      reportsIdsForStatusesUpdate
                    )}
                    tooltipText={submitReportTooltip}
                    disabled={
                      !this.canBeSubmittedRequest() ||
                      hasReportsInAmendmentsStatus ||
                      !reportsIdsForStatusesUpdate.length
                    }
                  >
                    Submit Report
                  </ButtonWithTooltip>
                </Grid>
              </>
            )}
          </Grid>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state: IState, props: IProps) => {
  if (!props.report && !props.test) {
    return;
  }

  return {
    test: testSelector.getItemById(result(props, "match.params.test", false))(
      state
    ),
    reportDocuments: state.reportDocuments.items.items,
    report: getByTestId(props.test.id)(state),
    valuesData: {
      ...selectorForm(state, "summaryIntroduction", "technicalIntroduction")
    },
    initialValues: {
      summaryIntroduction: props?.report?.summaryIntroduction,
      technicalIntroduction: props?.report?.technicalIntroduction
    }
  };
};

const mapDispatchToProps = {
  formSubmit: updateReport,
  changeTestStatus: changeTestStatusAction,
  openSnackbar
};

const connectForm = reduxForm({
  form: REPORT_DETAILS_CREATE_FORM,
  validate: composeValidators(
    fieldsNotEmpty(["technicalIntroduction", "summaryIntroduction"]),
    fieldMaxLength(
      ["technicalIntroduction", "summaryIntroduction"],
      INTRODUCTION_FIELD_MAX_LENGTH
    )
  )
})(ReportDetailsForm);

const connected = connect(mapStateToProps, mapDispatchToProps)(connectForm);

export default withRouter(connected as any) as React.ComponentClass;
