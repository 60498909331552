import {Observable} from 'rxjs';
import {flatMap, switchMap, take} from 'rxjs/operators';
import {ActionsObservable} from 'redux-observable';
import * as actionType from '../constants/action-type';
import * as Request from '../api/requests/dbs-certificates';
import {fromPromise} from "rxjs/internal-compatibility";
import {IActionPayload} from "./index";
import {startEndCatch} from "./pipes/errors";
import {IPostDBSCertificateAction} from "../actions/dbs-certificate";
import {createAction} from "redux-actions";
import {merge} from "rxjs/internal/observable/merge";
import {reset} from "redux-form";
import {push} from "react-router-redux";
import {createCrudAction} from "../helpers/action-helper";
import {of} from "rxjs/internal/observable/of";
import {IDocument} from "../models/document.interface";
import {deleteItem as deleteDBSCertificate} from "../api/requests/dbs-certificates";
import {API_SECURE_DOCUMENTS_ENDPOINT_URL} from "../constants/urls";

export const postDBSCertificateEpic = (action$: ActionsObservable<IActionPayload<IPostDBSCertificateAction>>): Observable<any> => {
  return action$
    .ofType(actionType.DBS_CERTIFICATE_POST)
    .pipe(
      switchMap((action: IActionPayload<IPostDBSCertificateAction>)=> {
        return merge(
          of(createAction(actionType.SECURED_DOCUMENT_POST, () => action.payload.dbsCertificate.evidence)()),
          action$
            .ofType(actionType.SECURED_DOCUMENT_POST_COMPLETED)
            .pipe(
              take(1),
              switchMap((fileIdentifier: IActionPayload<IDocument>) => {
                return fromPromise(Request.postItem({
                  ...action.payload.dbsCertificate,
                  evidence: fileIdentifier.payload.id ?
                    '' + API_SECURE_DOCUMENTS_ENDPOINT_URL + fileIdentifier.payload.id :
                    '',
                }))
                .pipe(
                  flatMap(data => {
                    return [
                      reset(action.payload.formName),
                      push(action.payload.backRoute),
                      createCrudAction(actionType.DBS_CERTIFICATES).addItem(data),
                    ];
                  }),
                  ...startEndCatch('postDBSCertificateEpic')
                );
              })
            )
        );
      }),
    );
};

export const archiveDBSCertificateEpic = (action$: ActionsObservable<IActionPayload<string>>): Observable<any> => {
  return action$
    .ofType(actionType.DBS_CERTIFICATE_ARCHIVE)
    .pipe(
      switchMap((action: IActionPayload<string>) => {
        return fromPromise(deleteDBSCertificate(action.payload))
          .pipe(
            flatMap((data) => {
              return [
                createAction(actionType.DBS_CERTIFICATES_GET)(),
              ];
            }),
            ...startEndCatch('archiveDBSCertificateEpic'),
          );
      }),
    );
};
