import React from "react";
import {composeValidators, fieldsNotEmpty, fieldMaxLength} from "../../../../helpers/validations/validation";
import {reduxForm} from "redux-form";
import {connect} from "react-redux";
import {certificateTypeRequest} from "../../../../actions/certificate-type";
import {CertificateForm} from "./CertificateForm";
import {withRouter} from "react-router-dom";
import routerModal from "../../../../components/routerModal";

const FORM_NAME = "CertificateCreate";

class CertificateCreate extends React.Component {
  render() {
    const {handleSubmit, postData, backRoute} = this.props;

    return (
      <div className="styled-modal md">
        <form
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit((values) => {
            postData(values, FORM_NAME, backRoute);
          })}
        >
          <CertificateForm backRoute={backRoute}/>
        </form>
      </div>
    );
  }
}

const formConnected = reduxForm({
  form: FORM_NAME,
  validate: composeValidators(
    fieldsNotEmpty(["shortName", "fullName"]),
    fieldMaxLength(["shortName"], 20),
    fieldMaxLength(["fullName"], 100),
  )
})(CertificateCreate);

const connected = connect(undefined, {
  postData: certificateTypeRequest.postItem,
})(formConnected);

export default routerModal(withRouter(connected));
