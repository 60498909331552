import React from "react";
import Button from "@material-ui/core/Button/Button";
import {Link, Route, Switch} from "react-router-dom";
import CountryList from "./CountryList";
import CountrySearchForm from "./CountrySearchForm";
import CountryCreate from "./CountryCreate";
import CountryUpdate from "./CountryUpdate";
import {withRouter} from "react-router-dom";
import {getCreateLink} from "../../../../helpers/router-helper";

class Country extends React.Component {
  render() {
    const baseUrl = this.props.match.path;

    return (
      <div>
        <div>
          <div className="crud-sections-header">
            <h4>Countries</h4>
            <Button component={Link} to={getCreateLink(baseUrl)} variant="contained" color="default">
              New Country
            </Button>
          </div>
          <hr/>
          <Switch>
            <Route path={getCreateLink(baseUrl)}
                   render={() => <CountryCreate backRoute={baseUrl}/>}/>
            <Route path={baseUrl + "/:id"}
                   render={() => <CountryUpdate backRoute={baseUrl}/>}/>
          </Switch>
          <CountrySearchForm/>
          <CountryList baseUrl={baseUrl}/>
        </div>
      </div>
    );
  }
}

export default withRouter(Country);
