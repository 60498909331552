import getCrudSelectors from '../helpers/selector-helper';
import {createSelector} from "reselect";

const getFavoriteTesters = state => state.favoriteTesters;

const crudSelectors =  getCrudSelectors(getFavoriteTesters);

const getFilterName = state => state.name;

const getFilterTextName = createSelector(
  crudSelectors.getFilter,
  getFilterName
);

export default crudSelectors;
