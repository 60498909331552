import * as React from 'react';
import {Grid, TableBody, WithStyles, withStyles} from "@material-ui/core";
import {withRouter, RouteComponentProps} from "react-router-dom";
import {TableRow, TableCell, Table, IconButton, Icon, Typography, Card, CardContent} from '@material-ui/core';
import EnhancedTableHead from '../../../../components/table/EnhancedTableHead';
import {format} from "date-fns";
import {getTesterProfileBankDetailsRequest} from "../../../../api/requests/admin-tester-profile";
import {ICertificate} from "../../../../models/certificate.interface";

import {styles} from '../../../../styles/material/tesrer-card';
import {result} from 'lodash';
import {IAnyProps} from "../../../../interfaces/any-props.interface";
import HiddenBankDetailsField from "../../../../components/common/tester/HiddenBankDetailsField";

type IProps = RouteComponentProps<any> & WithStyles;

interface IState {
  isLoaded: boolean;
  bankDetails: BankDetails | null;
}

class BankDetails extends React.Component<IProps, IState> {
  state = {
    isLoaded: false,
    bankDetails: null,
  };

  componentWillMount() {
    this.fetchData();
  }

  componentWillReceiveProps(nextProps: Readonly<IProps>, nextContext: any): void {
    this.setState({isLoaded: false, bankDetails: null});
    this.fetchData();
  }

  fetchData() {
    getTesterProfileBankDetailsRequest(this.props.match.params.id)
      .then(data => {
        this.setState({bankDetails: data, isLoaded: true});
      });
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div>
          Loading...
        </div>
      );
    }

    return (
      <Card className={this.props.classes.card}>
        <div className={this.props.classes.details}>
          <CardContent className={this.props.classes.content}>
            <Typography variant="headline" className={this.props.classes.header}>
              <span>
                Bank Details
              </span>
            </Typography>
            <hr/>
            {
              this.state.bankDetails === null ?
                <TableRow key={0}>
                  <TableCell colSpan={3}>There are no bank details.</TableCell>
                </TableRow>
                :<Grid container >
                  <Grid item md={1}/>
                  <Grid item md={5}>
                  <span className="mr-4">
                    Account no.
                  </span>
                    <HiddenBankDetailsField hiddenData={result(this.state.bankDetails, 'account_no')}/>
                  </Grid>
                  <Grid item md={5}>
                  <span className="mr-4">
                    Sort code
                  </span>
                    <HiddenBankDetailsField hiddenData={result(this.state.bankDetails, 'sort_code')}/>
                  </Grid>
                  <Grid item md={1}/>
                </Grid>
            }
          </CardContent>
        </div>
      </Card>
    )
  }
}

const routered = withRouter(withStyles(styles as any)(BankDetails) as any);

export default routered as React.ComponentClass<{}>;
