import { Tooltip } from "@material-ui/core";
import * as React from "react";
import { ITest } from "models/test.interface";

type PlannerNameProps = {
  test: ITest;
}

export function PlannerName({test}: PlannerNameProps) {
  return <Tooltip
    placement={"bottom-end"}
    disableFocusListener
    disableTouchListener
    title={test.plannerEmail}
  >
    <div>{test.plannerName}</div>
  </Tooltip>;
}
