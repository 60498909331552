import * as React from "react";
import TextField from "@material-ui/core/TextField";
import {WrappedFieldProps} from "redux-form/lib/Field";
import {WithStyles, withStyles} from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import {IAnyProps} from "../../interfaces/any-props.interface";
import FormHelperText from "@material-ui/core/FormHelperText";
import {ICountry} from "../../models/dialing-code.interface";
import {dataFetcher} from "../dataFetcher";
import countriesSelector from "../../selectors/countries";
import {countriesRequest} from "../../actions/countries";
import {ACTIVE} from "../../constants/filter";
import {sortDialingCodes} from "../../helpers/dialing-codes-sorting";

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    width: "100%",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
});

const MenuProps = {
  PaperProps: {
    style: {
      width: 250,
    },
    span: {
      overflow: "hidden",
      textOverflow: "ellipsis !important",
    }
  },
};

interface IProps {
  items: ICountry[];
  setLocationSearchText: (name: string) => void;
  searchText: string;
}

class ReduxSingleCountryField extends React.Component<WrappedFieldProps & IAnyProps & WithStyles<"chips" | "formControl" | "chip"> & IProps,
  {
    value: any;
    searchText: string;
  }> {
  state = {
    value: this.props.input.value,
    searchText: ""
  };

  componentDidMount(): void {
    this.setState({value: this.props.input.value});
  }

  componentDidUpdate(prevProps) {
    if (prevProps.input.value !== this.props.input.value) {
      this.setState({value: this.props.input.value});
    }
  }

  handleChange = (event) => {
    this.setState({value: event.target.value});
    this.props.input.onChange(event.target.value);
  };

  renderName = (location: ICountry) => {
    return (
      <MenuItem
        key={location.id}
        value={location.id}
      >
        <ListItemText
          disableTypography
          primary={location.name}
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}/>
      </MenuItem>
    );
  };

  close = (event) => {
    event.stopPropagation();
  };

  handleSearchChange = (event) => {
    this.setState({searchText: event.target.value});
  };

  render() {
    if (!this.props.items) {
      return (
        <div>
          Loading...
        </div>
      );
    }

    const {label, classes, meta: {error}} = this.props;

    let {meta: {touched}} = this.props;

    if (this.state.value === 0) {
      touched = true;
    }

    return (
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="select-multiple-checkbox" error={!!touched && !!error}>{label}</InputLabel>
        <Select
          {...this.props.input}
          {...(this.state && {value: this.state.value})}
          fullWidth={true}
          onChange={this.handleChange}
          MenuProps={MenuProps}
          error={!!touched && !!error}
        >
          <MenuItem
            key={"Search"}
            onClick={this.close}
          >
            <TextField
              value={this.state.searchText}
              onChange={this.handleSearchChange}
              fullWidth={true}
              onClick={this.close}
            />
          </MenuItem>
          {this.props.items.filter((location: ICountry) => {
            return (location.name || "").toLocaleLowerCase().indexOf(this.state.searchText.toLocaleLowerCase()) !== -1;
          }).map(this.renderName)}
        </Select>
        {
          !!touched && error ?
            <FormHelperText style={{color: "#f44336"}}>{error}</FormHelperText> :
            null
        }
      </FormControl>
    );
  }
}

const fetched = dataFetcher(ReduxSingleCountryField, [{
  key: "items",
  selector: (state, props) => sortDialingCodes(countriesSelector.getItems(state)),
  action: () => countriesRequest.getItems(1, 1000, ACTIVE),
  alwaysReceiveFreshData: true,
}]);

export default ((withStyles(styles as any, {withTheme: true})(fetched)) as any);