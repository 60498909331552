import React, {useEffect} from "react";
import {Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Zoom} from "@material-ui/core";
import {TableNoItems} from "../../../../components/table/TableNoItems";
import {connect} from "react-redux";
import {formatDateWithTime} from "../../../../helpers/date-formatter";
import {bindActionCreators} from "redux";
import {auditLogLoader, getAuditLogsRequestsAction} from "../../../../actions/audit-log";
import selector from "../../../../selectors/audit-log";
import {formValueSelector} from "redux-form";
import {AUDIT_LOG_SEARCH_FORM} from "./AuditFormSearchForm";
import {GetFilterPreviewValue} from "../../../../helpers/component-prev-values";
import {AuditLogFilterParamsType, AuditLogResponseType} from "../../../../types";
import debounce from "debounce";
import {AUDIT_LOG_ACTIONS} from "./constants";

type AuditLogListProps = {
  data: Array<AuditLogResponseType>;
  isLoaded: boolean;
  pageNumber: number;
  itemsPerPage: number;
  totalItems: number;
  searchFormFilters: {
    searchText?: string;
    actions?: Array<number>;
    dateFrom?: string;
    dateTo?: string;
  };
  setItemsPerPage: (value: number) => void;
  setPageNumber: (value: number) => void;
  loadItems: (params: AuditLogFilterParamsType) => void;
}

const searchFormSelector = formValueSelector(AUDIT_LOG_SEARCH_FORM);
const getShouldUpdateValues = (prevSearchValues:AuditLogFilterParamsType, searchValues:AuditLogFilterParamsType) => {
  const shouldReloadList = JSON.stringify(prevSearchValues) !== JSON.stringify(searchValues);

  return {
    shouldReloadList,
    isTextFieldUpdated: prevSearchValues?.searchText !== searchValues?.searchText
  }
}

const AuditLogList = (props: AuditLogListProps) => {
  const {
    data,
    isLoaded,
    pageNumber,
    itemsPerPage,
    totalItems,
    searchFormFilters,
    setItemsPerPage,
    setPageNumber,
    loadItems
    } = props;

  const filterInitialValues = {
    actions: [],
    pageNumber: 1,
    itemsPerPage: 25
  };

  const searchValues = {...searchFormFilters, pageNumber, itemsPerPage};
  const prevSearchValues = GetFilterPreviewValue(searchValues, filterInitialValues);
  const shouldRerender = getShouldUpdateValues(prevSearchValues, searchValues);

  useEffect(() => {
    loadItems(searchValues);
  }, []);

  useEffect(() => {
    const {shouldReloadList, isTextFieldUpdated} = shouldRerender;
    const debounceLoadItems = debounce(loadItems, 500);

    if(isTextFieldUpdated) {
      debounceLoadItems(searchValues);
    } else if(shouldReloadList) {
      loadItems(searchValues);
    }
  }, [shouldRerender]);



  const handleChangeRowsPerPage = (event) => {
    setItemsPerPage(event.target.value);
  };

  const  handleChangePage = (_, page: number) => {
    setPageNumber(page + 1);
  };

  if(!isLoaded) {
   return <TableNoItems hasWrapper asLoading />;
  }

  if(!data.length) {
    return <TableNoItems hasWrapper />;
  }

  return (
    <div className="styled-block">
      <div className="table-responsive styled-table">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Actor</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Action</TableCell>
              <TableCell>Note</TableCell>
              <TableCell align="right">Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              data.map((item: AuditLogResponseType) => {
                const selectedAction = AUDIT_LOG_ACTIONS.find(({id}) => item.actionType === id);
                const actionName = !!selectedAction ? selectedAction.name : "";
                return (
                  <TableRow key={item.id}>
                    <TableCell>{item.actor}</TableCell>
                    <TableCell>{item.ipAddress}</TableCell>
                    <TableCell>{actionName}</TableCell>
                    <TableCell>{item.note}</TableCell>
                    <TableCell align="right">
                      {item?.createdAt && formatDateWithTime(item.createdAt)}
                    </TableCell>
                  </TableRow>
                );
              })
            }
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={totalItems}
          rowsPerPage={itemsPerPage}
          page={pageNumber - 1}
          backIconButtonProps={{"aria-label": "Previous Page"}}
          nextIconButtonProps={{"aria-label": "Next Page"}}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    data: selector.getItems(state),
    isLoaded: selector.getIsLoaded(state),
    itemsPerPage: selector.getItemsPerPage(state),
    pageNumber: selector.getPageNumber(state),
    showStatus: selector.getFilterActivityStatus(state),
    totalItems: selector.getTotalItems(state),
    filterText: selector.getFilterTextFields(state),
    searchFormFilters: searchFormSelector(state, "searchText", "actions", "dateFrom", "dateTo"),
  };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    loadItems: getAuditLogsRequestsAction,
    setItemsPerPage: auditLogLoader.setItemsPerPage,
    setPageNumber:auditLogLoader.setPageNumber,
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AuditLogList);
