import * as Types from '../constants/action-type';
import {createAction} from "redux-actions";

export const setCalendarViewTypeAction = (type: string) => createAction(Types.CALENDAR_SET_VIEW, () => type)();
export const setCalendarRangeAction = (start: Date, end: Date) => {
  return {
    type:Types.CALENDAR_SET_DATE_RANGE,
    payload: {
      start,
      end,
    },
  };
};

export const setCalendarDateAction = (year?: number, month?: number) => {
  return {
    type:Types.CALENDAR_SET_DATE,
    payload: {
      year,
      month,
    },
  };
};
export const setCalendarEventsAction = (events) =>
  createAction(Types.CALENDAR_SET_EVENTS, () => events)();
