import * as React from "react";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import ReduxTextField from "../../components/forms/ReduxTextField";
import ReduxSelectField from "../../components/forms/ReduxSelectField";
import { InjectedFormProps, reduxForm } from "redux-form";
import {
  composeValidators,
  fieldIsEmail,
  fieldIsNumber,
  fieldsNotEmpty
} from "../../helpers/validations/validation";
import ReduxCheckbox from "../../components/forms/ReduxCheckbox";
import { FieldGeneric } from "../../components/forms/FieldGeneric";
import { IRegistrationClient } from "../../models/registration-client.interface";
import { connect } from "react-redux";
import { registrationClient } from "../../actions/common";
import { result } from "lodash";
import { first } from "lodash";
import { IAnyProps } from "../../interfaces/any-props.interface";
import dialingCodeSelector from "../../selectors/countries";
import sectorSelector from "../../selectors/sector";
import * as dialingCodeRequest from "../../actions/countries";
import { sectorRequest } from "../../actions/sector";
import { ACTIVE } from "../../constants/filter";
import { dataFetcher } from "../../components/dataFetcher";
import { ICountry } from "../../models/dialing-code.interface";
import { ISector } from "../../models/sector.interface";
import { BASE_URL } from "../../constants/urls";
import { Grid, Zoom } from "@material-ui/core";
import { thankYouForRegistrationModalOpen } from "../../actions/modals";
import { handleSpaceKeyPress } from "../../helpers/validations/emptyTestFieldValidation";
import Header from "./Header";
import { getDialingCodeFromCountry } from "../../helpers/converter-helper";
import { getApplicationSettingByKey } from "../../actions/application-settings";
import applicationSettingsSelector from "../../selectors/application-settings";
import { RawHTML } from "../../components/RawHTML";
import Footer from "./Footer";
import { LINKS } from "../../constants/links";
import { PhoneFields } from "./TesterRegistration";

const FORM_NAME = "ClientRegistration";

interface IDispatchProps {
  registerClient: (
    model: IRegistrationClient,
    formName: string,
    validationHandler: (error: any) => IAnyProps
  ) => void;
  fetchDialingCode: (...any) => any;
  thankYouForRegistrationModalOpen: (...any) => any;
}

interface IDataFetcher {
  dialingCodes: ICountry[];
  sectors: ISector[];
  isClientRegistrationOpenedSetting: any;
}

const mappingErrors: Array<[string, string]> = [
  ["company.sector", "sectorId"],
  ["company.name", "companyName"],
  ["user.firstName", "firstName"],
  ["user.lastName", "lastName"],
  ["user.email", "email"],
  ["user.phone", "phone"],
];

const handleServerError = (error: IAnyProps) => {
  return mappingErrors.reduce((acc, data) => {
    return Object.assign(acc, result(error, data[0], false) ? {[data[1]]: result(error, data[0])} : {});
  }, {});
};

class ClientRegistration extends React.Component<InjectedFormProps<{}> & IDispatchProps & IDataFetcher, {}> {
  handleSubmit = this.props.handleSubmit((values: IRegistrationClient & {
    dialingCode: string;
    subscriptionType: number;
  }) => {
    this.props.registerClient({
        dialingCode: undefined,
        ...values,
        phone: `+${getDialingCodeFromCountry(values.dialingCode, this.props.dialingCodes)}${values.phone}`
      } as IRegistrationClient,
      FORM_NAME,
      handleServerError
    );
  });

  checkboxLabel = (
    <div>
      {`I agree to the AVORD `}
      <a href={LINKS.TERMS_AND_CONDITIONS} target="_blank">
        Terms &amp; Conditions
      </a>
      {` and `}
      <a href={LINKS.PRIVACY_POLICY} target="_blank">
        Privacy Policy
      </a>
    </div>
  );

  renderCodes = (option: ICountry) => (
    <MenuItem
      key={option.id}
      value={option.name}
    >
      {option.flagImagePath && <img src={BASE_URL + option.flagImagePath} height="16px" alt={option.name}/>}
      &nbsp;<span style={{width: "55px"}}>{`+${option.dialingCode} `}</span> {option.name}
    </MenuItem>
  );

  renderForm = () => {
    const firstSetting = first(this.props.isClientRegistrationOpenedSetting);

    if (!firstSetting || !result(firstSetting, "state", false)) {
      const message = result(firstSetting, "message", "");

      return (
        <div className="row text-center mt-5">
          <div className="col-md-12">
            <h4>
              <RawHTML>
                {message}
              </RawHTML>
            </h4>
            <hr/>
            <img src="/images/logo-icon-big.svg" alt="" width="320"/>
          </div>
        </div>
      );
    }

    return (
      <form onSubmit={this.handleSubmit} noValidate autoComplete="off">
        <h1>Register for our management tool!</h1>
        <hr/>
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <FieldGeneric
              inputProps={{minLength: 1, maxLength: 100}}
              name="companyName"
              label="Company name *"
              margin="dense"
              component={ReduxTextField}
            />
            <FieldGeneric
              name="sectorId"
              label="Sector *"
              margin="dense"
              component={ReduxSelectField}
              style={{minWidth: "230px"}}
            >
              {this.props.sectors.map(option => (
                <MenuItem
                  key={option.id}
                  value={option.id}
                  style={{marginRight: "20px"}}>
                  {option.name}
                </MenuItem>
              ))}
            </FieldGeneric>
            <FieldGeneric
              inputProps={{minLength: 1, maxLength: 50}}
              name="firstName"
              label="First name *"
              margin="dense"
              component={ReduxTextField}
            />
            <FieldGeneric
              inputProps={{minLength: 1, maxLength: 50}}
              name="lastName"
              label="Last name *"
              margin="dense"
              component={ReduxTextField}
            />
            <FieldGeneric
              inputProps={{maxLength: 60}}
              name="email"
              label="Email *"
              component={ReduxTextField}
              onKeyPress={handleSpaceKeyPress}
              fullWidth={true}
            />
            <PhoneFields
                dialingCodes={this.props.dialingCodes}
                renderCodes={this.renderCodes}
            />
          </div>
          <div className="col-lg-4 col-md-2"/>
          <div className="col-md-4">
            <br/><br/>
            <img src="/images/logo-icon-big.svg" alt="" width="100%"/>
          </div>
        </div>
        <hr/>
        <div className="row">
          <div className="col-md-6">
            <Grid container>

              <Grid item xs={10}>
                <FieldGeneric
                  name="is_agree"
                  label={this.checkboxLabel as any}
                  component={ReduxCheckbox}
                />
              </Grid>
              <Grid item md={7} xs={12}>
                <br/>
                <Button fullWidth={true} type="submit" variant="contained" color="primary" className="mb-5">
                  Register and start using AVORD
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </form>
    );
  };

  render() {
    return (
      <div>
        <Header/>
        <div className="page-content">
          <div className="container">
            {this.renderForm()}
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}

const validators = composeValidators(
  fieldsNotEmpty([
    "firstName",
    "lastName",
    "email",
    "companyName",
    "sectorId",
    "phone",
    "dialingCode",
    "is_agree"
  ]),
  fieldIsEmail(["email"]),
  fieldIsNumber(["phone"]),
);

function mapStateToProps(state) {
  return {
    prefixes: dialingCodeSelector.getItems(state),
    sectors: sectorSelector.getItems(state),
  };
}

const connected = connect(mapStateToProps, {
  registerClient: registrationClient,
  fetchDialingCode: dialingCodeRequest.countriesRequest.getItems,
  thankYouForRegistrationModalOpen,
})(reduxForm({
  form: FORM_NAME,
  validate: validators
})(ClientRegistration));

export default dataFetcher<IDataFetcher>(connected, [
  {
    key: "dialingCodes",
    selector: (state, props) => dialingCodeSelector.getItems(state),
    action: () => dialingCodeRequest.countriesRequest.getItems(1, 1000, ACTIVE),
  },
  {
    key: "sectors",
    selector: (state, props) => sectorSelector.getItems(state),
    action: () => sectorRequest.getItems(1, 1000, ACTIVE),
  },
  {
    key: "isClientRegistrationOpenedSetting",
    selector: (state, props) => applicationSettingsSelector.getItemsObject(state),
    action: () => getApplicationSettingByKey("isClientRegistrationOpened"),
    alwaysReceiveFreshData: true
  }
]);
