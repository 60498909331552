import {convertorFromSwagger, convertorToSwagger} from '../convertors/default-convertor';
import {fetchRequestBody, extractJSONLD} from '../../helpers/request-helper';
import {getApiJSONLD} from '../swagger/api-factory';
import {converterToSwagger} from "../convertors/risk";


export const getItems = async (testId) => {
  return await getApiJSONLD()
    .then(api => api.api_tests_risks_get_subresource({ id: testId }))
    .then(extractJSONLD(convertorFromSwagger));
};

export const deleteItem = async (testId) => {
  return await getApiJSONLD()
    .then(api => api.deleteRiskItem({ id: testId }));
};

export const putItem = (id, model) => {
  return getApiJSONLD()
    .then(api => api.putRiskItem({
      id,
      risk: converterToSwagger(model),
    }))
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};

export const postItem = (model) => {
  return getApiJSONLD()
    .then(api => api.postRiskCollection({
      risk: converterToSwagger(model),
    }))
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};