import {ITesterProfileGet} from "../models/tester-profile.interface";

export function getTesterPriceRange(profile: any & ITesterProfileGet): string {
  if (profile.rate && profile.currencyInfo && profile.currencyInfo.symbol) {
    const currency = profile.currencyInfo.symbol;
    const amount = profile.rate;

    return `${currency}${amount} per day`;
  }

  return '';
}