import React from "react";
import { FieldGeneric } from "../../../../components/forms/FieldGeneric";
import ReduxDatePicker from "../../../../components/forms/ReduxDatePicker";
import ReduxSelectField from "../../../../components/forms/ReduxSelectField";
import { Button, Checkbox, ListItemText, MenuItem } from "@material-ui/core";
import { AUDIT_LOG_ACTIONS } from "./constants";

import { Field, formValueSelector, reduxForm, FormProps } from "redux-form";
import ReduxTextField from "../../../../components/forms/ReduxTextField";
import { connect } from "react-redux";

export const AUDIT_LOG_SEARCH_FORM = "AuditLOgSearchForm";
const searchFormSelector = formValueSelector(AUDIT_LOG_SEARCH_FORM);
const isActionSelected = (actionsSelected: number[], id: number) =>
  actionsSelected.some((actionId: number): boolean => actionId === id);

type AuditLogSearchFormProps = {
  actionsSelected?: Array<number>;
  change: (field: string, value: unknown) => void;
  reset: () => void;
};

const AuditLogSearchForm = (props: AuditLogSearchFormProps) => {
  const { reset, actionsSelected = [], change } = props;

  const joinActions = (selectedActions): string => {
    if (Array.isArray(selectedActions) && !selectedActions.length) {
      return "";
    }
    return AUDIT_LOG_ACTIONS.filter(({ id }) =>
      selectedActions.some((actionId: number) => actionId === id)
    )
      .map(action => action.name)
      .join(", ");
  };
  const checkAllActions = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    const allActionsIds: number[] = AUDIT_LOG_ACTIONS.map(
      ({ id }: { id: number }): number => id
    );
    event.stopPropagation();
    change("actions", allActionsIds);
  };

  const uncheckAllActions = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    event.stopPropagation();
    change("actions", []);
  };

  return (
    <form>
      <div className="table-responsive styled-table styled-block">
        <div className="row">
          <div className="col-md-4">
            <Field
              name="searchText"
              label="Search by Email/Address/Actor/Note"
              component={ReduxTextField}
              margin="normal"
            />
          </div>
          <div className="col-md-4">
            <FieldGeneric
              name="actions"
              label="Actions"
              fullWidth
              multiple
              component={ReduxSelectField}
              renderValue={joinActions}
            >
              <MenuItem>
                <Button type="button" color="primary" onClick={checkAllActions}>
                  Check all
                </Button>
                <Button color="primary" onClick={uncheckAllActions}>
                  Uncheck all
                </Button>
              </MenuItem>
              {AUDIT_LOG_ACTIONS.map(action => (
                <MenuItem key={action.id} value={action.id}>
                  <Checkbox
                    checked={isActionSelected(actionsSelected, action.id)}
                  />
                  <ListItemText primary={action.name} />
                </MenuItem>
              ))}
            </FieldGeneric>
          </div>
          <div className="col-md-2">
            <FieldGeneric
              name="dateFrom"
              label="From"
              InputLabelProps={{
                shrink: true
              }}
              component={ReduxDatePicker}
            />
          </div>
          <div className="col-md-2">
            <FieldGeneric
              name="dateTo"
              label="To"
              InputLabelProps={{ shrink: true }}
              addEndOfDay={true}
              component={ReduxDatePicker}
            />
          </div>
          <div className="col-md-12 pt-2">
            <Button variant="contained" color="primary" onClick={reset}>
              Reset filters
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};

function mapStateToProps(state) {
  return {
    actionsSelected: searchFormSelector(state, "actions")
  };
}

const connectForm = reduxForm({
  form: AUDIT_LOG_SEARCH_FORM,
  initialValues: {
    actions: []
  }
})(AuditLogSearchForm);

export default connect(mapStateToProps)(connectForm);
