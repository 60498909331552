import * as React from "react";
import { FieldGeneric } from "../../../components/forms/FieldGeneric";
import ReduxMultipleCountryField from "../../../components/forms/ReduxMultipleCountryField";
import { InjectedFormProps, reduxForm } from "redux-form";
import { Button, Grid, Icon, IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import { IAnyProps } from "../../../interfaces/any-props.interface";
import countriesSelector from "../../../selectors/countries";
import { countriesRequest } from "../../../actions/countries";
import { ACTIVE } from "../../../constants/filter";
import { ICountry } from "../../../models/dialing-code.interface";
import { putTesterRightsToWorkCountries } from "../../../actions/tester-rights-to-work-countries";
import { sortDialingCodes } from "../../../helpers/dialing-codes-sorting";
import { fieldsNotEmpty } from "../../../helpers/validations/validation";
import { TableNoItems } from "../../../components/table/TableNoItems";

const FORM_NAME = "RightsToWorkForm";

interface IForm {
  countries: number[];
}

interface IState {
  isEditMode: boolean;
}

interface IProps extends InjectedFormProps<IForm> {
  countries: ICountry[];
  items: ICountry[];
  putTesterRightsToWorkCountries: (countriesIds: number) => void;
  requestCountries: (...any) => void;
  isLoaded: boolean;
}

class RightsToWorkForm extends React.Component<IProps, IState> {
  state = {
    isEditMode: false
  };

  handleClick = () => {
    this.setState({ isEditMode: !this.state.isEditMode });
  };

  renderButton = () => {
    if (this.state.isEditMode) {
      return (
        <IconButton
          onClick={this.handleClick}
          color={"primary"}
          style={{
            verticalAlign: "middle"
          }}
        >
          <Icon>save</Icon>
        </IconButton>
      );
    }

    return (
      <IconButton
        onClick={this.handleClick}
        type="submit"
        color={"primary"}
        style={{
          verticalAlign: "middle"
        }}
      >
        <Icon>edit</Icon>
      </IconButton>
    );
  };

  handleSubmit = values => {
    this.props.putTesterRightsToWorkCountries(values.countries);
  };

  render(): React.ReactNode {
    if (!this.props.isLoaded) {
      return <TableNoItems asLoading />;
    }

    return (
      <form
        noValidate
        autoComplete="off"
        onSubmit={this.props.handleSubmit(this.handleSubmit)}
      >
        <Grid alignItems={"center"} container={true} spacing={24}>
          <Grid item={true} md={11} xs={11}>
            <FieldGeneric
              name="countries"
              label="Countries"
              type="number"
              disabled={!this.state.isEditMode}
              items={this.props.items}
              component={ReduxMultipleCountryField}
            />
          </Grid>

          <Grid
            item={true}
            md={1}
            xs={1}
            style={{
              textAlign: "center"
            }}
          >
            {this.renderButton()}
          </Grid>
        </Grid>
      </form>
    );
  }
}

const mapStateToProps = (state, props) => {
  const countriesList = countriesSelector.getItems(state);
  const countriesIds = props.countries.map(value => value.id);
  return {
    items: sortDialingCodes(countriesList),
    isLoaded: countriesSelector.getIsLoaded(state),
    ...(countriesIds.length && {
      initialValues: {
        countries: countriesIds
      }
    })
  };
};

const componentWithForm = reduxForm({
  form: FORM_NAME,
  validate: fieldsNotEmpty(["countries"])
})(RightsToWorkForm);

export default connect<IAnyProps, IAnyProps, IAnyProps>(mapStateToProps, {
  putTesterRightsToWorkCountries,
  requestCountries: countriesRequest.getItems
})(componentWithForm);
