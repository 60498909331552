import * as Types from '../constants/action-type';

export interface IResetPasswordConfirmationState {
  status: boolean;
  message?: string
}

const defaultState: IResetPasswordConfirmationState = {
  status: false
};

export default function(state: IResetPasswordConfirmationState = defaultState, action) {
  switch (action.type) {
    case Types.RESET_PASSWORD_CONFIRMATION:
      return {...state, passwordResetPayload: action.payload.passwordResetPayload};
    default:
      return state;
  }
};