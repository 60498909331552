import { ColumnType } from "components/table/Table";
import { ITest } from "models/test.interface";
import React from "react";
import {
  TestName,
  ClientName,
  PlannerName,
  ViewDetails,
} from "./columns";
import { getCurrency } from "helpers/currency";
import { getTestStatusName } from "constants/test-status";
import TesterTableCell from "containers/common/TesterTableCell";
import { Permission } from "constants/permission";

export function getTestsListColumns(): ColumnType<ITest>[] {
  return [
    {
      key: 'test_name',
      name: "Start",
      render(test) {
        return React.createElement(TestName, { test });
      }
    },
    {
      key: 'purchaseOrderNumber',
      name: "PO Number",
    },
    {
      key: 'cost',
      name: "Cost",
      permissions: [Permission.CAN_VIEW_PLATFORM_INVOICES_AND_COSTS],
      render({ proposal}) {
        if (proposal && proposal.currency) {
          return `${proposal.currency.symbol}${getCurrency(proposal.fullTotalCostForClient)}`;
        }

        return '';
      }
    },
    {
      key: 'client_company',
      name: "Client Company",
      render(test) {
        return React.createElement(ClientName, { test });
      }
    },
    {
      key: 'planner',
      name: "Planner",
      render(test) {
        return React.createElement(PlannerName, { test });
      }
    },
    {
      key: 'consultant',
      name: "Consultant/Tester",
      render({tester, testerEmail}) {
        if (tester) {
          return React.createElement(TesterTableCell, {tester, testerEmail, Wrapper: React.Fragment})
        }
      }
    },
    {
      key: 'status',
      name: "Status",
      render({ status}) {
        return status ? getTestStatusName(status) : null;
      }
    },
    {
      key: 'action',
      name: "Action",
      render(test) {
        return React.createElement(ViewDetails, { test });
      }
    }
  ];
}
