export const handleKeyPressOnNumberField = (event) => {
  if (event.ctrlKey || event.metaKey || event.altKey) {
    return;
  }

  const char = event.key;

  if (!char) { return; }

  if (char < '0' || char > '9') {
    event.preventDefault();
  }
};

export function handleKeyPressOnDecimalNumberField(event) {
  if (event.ctrlKey || event.metaKey || event.altKey) {
    return;
  }

  const value = (event.target.value || '') + event.key;

  if (!/^[0-9]+(\.[0-9]{0,2})?$/.test(value)) {
    event.preventDefault();
  }
}

export const handleKeyPressOnPhoneField = (event) => {
  if (event.ctrlKey || event.metaKey || event.altKey) {
    return;
  }

  const char = event.key;

  if (!char) { return; }

  if ((char < '0' || char > '9') && char !== '+') {
    event.preventDefault();
  }
};

export const handleKeyPressOnSoWField = (event) => {
  if (event.ctrlKey || event.metaKey || event.altKey) {
    return;
  }

  const char = event.key;

  if (!char) { return; }

  if (!/^[0-9.]$/.test(char)) {
    event.preventDefault();
  }
};
