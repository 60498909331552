import * as Types from 'constants/action-type';
import {
  ADDITIONAL_DOCUMENT_SEARCH_APPLY,
  DOCUMENT_SEARCH_APPLY
} from 'constants/action-type';
import { createLoaderAction } from 'helpers/action-helper';
import { createAction } from "redux-actions";
import { handleHttpErrors } from "helpers/errors/http-error-handler";
import {
  createAdditionalTestDocument,
  createTestDocument,
  getSoWDocumentIdRequest,
  postDocument
} from "api/requests/document";
import { setRequestInProcess } from "./request";
import { openSnackbar } from "./snackbar";
import { MAX_FILE_SIZE } from "constants/document";
import { ITest } from "models/test.interface";
import { IDocument } from "models/document.interface";

export const documentSearchAction = createAction<number|string|undefined>(DOCUMENT_SEARCH_APPLY);
export const additionalDocumentSearchAction = createAction<number|string|undefined>(ADDITIONAL_DOCUMENT_SEARCH_APPLY);

export const archiveTestDocument = (test: ITest, document: IDocument) => {
  return {
    type: Types.DOCUMENT_ARCHIVE,
    payload: {
      testId: test.id,
      documentId: document.id,
    },
  };
};

export const archiveAdditionalTestDocument = (test: ITest, document: IDocument) => {
  return {
    type: Types.ADDITIONAL_DOCUMENT_ARCHIVE,
    payload: {
      testId: test.id,
      documentId: document.id,
    },
  };
};

export const documentsLoader = createLoaderAction(Types.DOCUMENT);

export const postDocumentItem = (file: File) => async dispatch => {
  if (file.size > MAX_FILE_SIZE) {
    dispatch(openSnackbar('File is too big', 4000));
    return;
  }

  dispatch(setRequestInProcess(true, 'postDocument'));

  let data;

  try {
    data = await postDocument(file);

    return data;
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(setRequestInProcess(false, 'postDocument'));
};

export const uploadNewTestDocument = (test: ITest, file: File) => async dispatch => {
  if (file.size > MAX_FILE_SIZE) {
    dispatch(openSnackbar('File is too big', 4000));
    return;
  }

  dispatch(setRequestInProcess(true, 'postDocument'));

  try {
    return await createTestDocument(test, file);
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(setRequestInProcess(false, 'postDocument'));
};

export const uploadNewAdditionalTestDocument = (test: ITest, file: File) => async dispatch => {
  if (file.size > MAX_FILE_SIZE) {
    dispatch(openSnackbar('File is too big', 4000));
    return;
  }

  dispatch(setRequestInProcess(true, 'postDocument'));

  try {
    return await createAdditionalTestDocument(test, file);
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(setRequestInProcess(false, 'postDocument'));
};

export const getSoWDocumentIdAction = (testId) => async dispatch => {
  dispatch(setRequestInProcess(true, 'getDocumentId'));

  try {
    const data = await getSoWDocumentIdRequest(testId);

    return data.body;
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }
  dispatch(setRequestInProcess(true, 'getDocumentId'));
}
