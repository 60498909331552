import {IConverterFromSwagger, IConverterToSwagger} from "./converter.interface";
import {IDBSCertificate} from "../../models/dbs-certificate.interface";

export const converterFromSwagger: IConverterFromSwagger<IDBSCertificate, any> = model => {
  return {
    ...model,
  };
};

export const converterToSwagger: IConverterToSwagger<IDBSCertificate, any> = model => {
  return {
    ...model,
    evidence: model.evidence,
  };
};
