import * as React from 'react';
import {GUIDANCE_RISK_CHART} from "../../../constants/content-management";
import {dataFetcher} from "../../../components/dataFetcher";
import {getItemByKey} from "../../../actions/content-management";
import {getByKey} from "../../../selectors/content-management";
import {IContentManagement} from "../../../models/content-management.interface";
import {RawHTML} from "../../../components/RawHTML";
import Paper from "@material-ui/core/Paper";

interface IProps {
  contentManagement: IContentManagement;
}

class RiskChartGuidance extends React.Component<IProps,{}> {
  render() {
    return (
      <Paper className="risk-chart-guidance mt-4 p-4">
         <h5>Guidance</h5>
         <Paper className="table-responsive">
            <RawHTML>{this.props.contentManagement.content}</RawHTML>
         </Paper>
      </Paper>
    );
  }
}

const fetched = dataFetcher(
  RiskChartGuidance,
  [{
    key: 'contentManagement',
    selector: (state, props) => getByKey(GUIDANCE_RISK_CHART)(state),
    action: () => getItemByKey(GUIDANCE_RISK_CHART),
  }]
);

export default fetched as any;

// Old Risk Chart Guidance
// import * as  React from 'react';
// import {Paper} from '@material-ui/core';
//
//
// const RiskChartGuidance = () => {
//   return (
//     <Paper className="risk-chart-guidance mt-4 p-4">
//       <h5>Guidance</h5>
//       <Paper className="table-responsive">
//         <table className="risk-assessment-matrix text-center">
//           <tr>
//             <td colSpan={9} className="gray big">RISK ASSESSMENT MATRIX</td>
//           </tr>
//           <tr className="gray big">
//             <td colSpan={4}>&nbsp;</td>
//             <td colSpan={5}>IMPACT</td>
//           </tr>
//           <tr className="gray">
//             <td colSpan={4}>&nbsp;</td>
//             <td>Risks that bring no real negative consequences or pose no significant threat to the project or organisation</td>
//             <td>Risks that have a small potential for negative consequences, but will not significantly impact overall success</td>
//             <td>Risks that could potentially bring negative consequences, posing a moderate threat to the project or organisation</td>
//             <td>Risks with substantial negative consequences that will seriously impact the success of the project or organisation</td>
//             <td>Risks with extreme negative consequences that could cause the entire project to fail or severely impactdaily operations of the organisation.</td>
//           </tr>
//           <tr className="text-center gray big">
//             <td colSpan={4} rowSpan={2}>IMPACT CATEGORY</td>
//             <td>Very minor</td>
//             <td>Minor</td>
//             <td>Moderate</td>
//             <td>Major</td>
//             <td>Catastrophic</td>
//           </tr>
//           <tr className="text-center gray big">
//             <td>1</td>
//             <td>2</td>
//             <td>3</td>
//             <td>4</td>
//             <td>5</td>
//           </tr>
//           <tr>
//             <td className="gray big" rowSpan={5}>LIKELIHOOD</td>
//             <td className="gray big">Almost certain</td>
//             <td className="gray">The event (risk) is expected to occur.The event will occur more than once a year</td>
//             <td className="gray big">5</td>
//             <td className="orange big">5</td>
//             <td className="orange big">10</td>
//             <td className="red big">15</td>
//             <td className="red big">20</td>
//             <td className="red big">25</td>
//           </tr>
//           <tr>
//             <td className="gray big">Likely</td>
//             <td className="gray">The event will probably occurThe event is likely to occur approximately once a year
//             </td>
//             <td className="gray big">4</td>
//             <td className="green big">4</td>
//             <td className="orange big">8</td>
//             <td className="orange big">12</td>
//             <td className="red big">16</td>
//             <td className="red big">20</td>
//           </tr>
//           <tr>
//             <td className="gray big">Possible</td>
//             <td className="gray">The event might occur at some timeThe risk is probably going to occur roughly once
//               every 5 years
//             </td>
//             <td className="gray big">3</td>
//             <td className="green big">3</td>
//             <td className="orange big">6</td>
//             <td className="orange big">9</td>
//             <td className="orange big">12</td>
//             <td className="red big">15</td>
//           </tr>
//           <tr>
//             <td className="gray big">Unlikely</td>
//             <td className="gray">The event could occur at some time but it's improbable The event is likely to occur
//               once every 5-10 years
//             </td>
//             <td className="gray big">2</td>
//             <td className="green big">2</td>
//             <td className="green big">4</td>
//             <td className="orange big">6</td>
//             <td className="orange big">8</td>
//             <td className="orange big">10</td>
//           </tr>
//           <tr>
//             <td className="gray big">Rare</td>
//             <td className="gray">The event may occur but only in exceptional circumstancesLikely to occur with less frequency than 10 years</td>
//             <td className="gray big">1</td>
//             <td className="green big">1</td>
//             <td className="green big">2</td>
//             <td className="green big">3</td>
//             <td className="green big">4</td>
//             <td className="orange big">5</td>
//           </tr>
//         </table>
//       </Paper>
//     </Paper>
//   )
// };
//
// export default RiskChartGuidance;