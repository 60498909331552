import * as React from "react";
import Paper from "@material-ui/core/Paper/Paper";
import { connect } from "react-redux";
import {
  usersLoader,
  getUsersFiltered,
  archiveItem,
  unarchiveItem,
  getUser,
  putUserAction,
  resetAction2FA,
  deleteItem
} from "actions/users";
import selector from "selectors/users";
import {
  TablePagination
} from "@material-ui/core";
import { isChangesExist } from "helpers/props-checker";
import { IAnyProps } from "interfaces/any-props.interface";
import debounce from "debounce";
import { postEmail, clientAccountModalOpen } from "actions";
import { formValueSelector } from "redux-form";
import { CLIENT_SEARCH_FORM_NAME } from "../ClientsSearchForm";
import Reset2FAConfirmModal from "../../modals/Reset2FAConfirmModal";
import { TableNoItems } from "components/table/TableNoItems";
import { RootState } from "reducers";
import Table from "components/table/Table";
import {
  getClientListColumns
} from "containers/admin/clients/ClientsList/config";
import { OnReset } from "containers/admin/testers/TesterList";

const selectorSearchForm = formValueSelector(CLIENT_SEARCH_FORM_NAME);

interface IFilterValues {
  name: string;
  email: string;
  phone: string;
  role: string;
  createdAt: string;
  deletedAt: string;
  userClientCompanyFilter: string;
}

interface ClientsList {
  isConfirmModalOpen: boolean;
  userId: string | null;
}

type IProps = {
  baseUrl: string;
  [key: string]: any;
} & IFilterValues &
  ClientsList;

class ClientsList extends React.Component<IProps, {}> {
  state = {
    isConfirmModalOpen: false,
    userId: null,
    isArchive: false
  };

  showConfirmModal: OnReset = (user) => {
    this.setState({ isConfirmModalOpen: true, userId: user.id });
  };

  hideConfirmModal = () => {
    this.setState({ isConfirmModalOpen: false });
  };

  handleSendLink: OnReset = (user) => {
    this.props.postEmail(user.email);
  };

  loadItems = (props: IProps) => {
    props.loadItems(
      props.pageNumber,
      props.itemsPerPage,
      props.showStatus,
      props.name,
      props.email,
      props.phone,
      props.role,
      props.createdAt,
      props.deletedAt,
      "",
      "",
      props.userClientCompanyFilter
    );
  };

  debouncedLoad = debounce(nextProps => {
    this.loadItems(nextProps);
  }, 500);

  changeSubscriptionStatus: OnReset = (user) => {
    user.isSubscriptionActive = !user.isSubscriptionActive;

    this.props.changeSubscriptionStatus(
      user,
      this.props.pageNumber,
      this.props.itemsPerPage,
      this.props.showStatus,
      this.props.name,
      this.props.email,
      this.props.phone,
      this.props.role,
      this.props.createdAt,
      this.props.deletedAt,
      this.props.userClientCompanyFilter,
    );
  };

  onUnarchive: OnReset = (user) => {
    this.props.unarchiveItem(
      user.id,
      this.props.pageNumber,
      this.props.itemsPerPage,
      this.props.showStatus,
      this.props.name,
      this.props.email,
      this.props.phone,
      this.props.role,
      this.props.createdAt,
      this.props.deletedAt,
      this.props.userClientCompanyFilter
    );
  };

  onArchive: OnReset = (user) => {
    this.props.archiveItem(
      user.id,
      this.props.pageNumber,
      this.props.itemsPerPage,
      this.props.showStatus,
      this.props.name,
      this.props.email,
      this.props.phone,
      this.props.role,
      this.props.createdAt,
      this.props.deletedAt,
      this.props.userClientCompanyFilter
    );
    this.setOnArchive(true);
  };

  onDelete: OnReset = (user) => {
    this.props.deleteItem(
      user.id,
      this.props.pageNumber,
      this.props.itemsPerPage,
      this.props.showStatus,
      this.props.name,
      this.props.email,
      this.props.phone,
      this.props.role,
      this.props.createdAt,
      this.props.deletedAt,
      this.props.userClientCompanyFilter
    );
  };

  setOnArchive = value => {
    this.setState({isArchive: value});
  };

  componentDidMount() {
    this.loadItems(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (
      isChangesExist(
        [
          "pageNumber",
          "itemsPerPage",
          "showStatus",
          "name",
          "email",
          "phone",
          "role",
          "createdAt",
          "deletedAt",
          "userClientCompanyFilter"
        ],
        nextProps,
        this.props
      )
    ) {
      if (
        isChangesExist(
          [
            "name",
            "email",
            "phone",
            "role",
            "createdAt",
            "deletedAt",
            "userClientCompanyFilter"
          ],
          nextProps,
          this.props
        )
      ) {
        this.debouncedLoad.clear();
        this.debouncedLoad(nextProps);
      } else {
        this.loadItems(nextProps);
      }
    }

    if (!nextProps.data.length && nextProps.pageNumber > 1) {
      this.props.setPageNumber(1);
    }
  }

  handleChangePage = (event, page) => {
    this.props.setPageNumber(page + 1);
  };

  handleChangeRowsPerPage = event => {
    this.props.setItemsPerPage(event.target.value);
  };

  render() {
    if (!this.props.isLoaded) {
      return <TableNoItems asLoading hasWrapper />;
    }

    if (!this.props.data.length) {
      return <TableNoItems hasWrapper />;
    }

    const {baseUrl, data, showStatus} = this.props;

    return (
      <div>
        {/* <UsersReassignModal isArchive={this.state.isArchive} setOnArchive={this.setOnArchive} onArchive={this.onArchive}/> */}
        <Paper style={{overflowX: "auto", width: "100%"}}>
          <Table columns={getClientListColumns({
            onResetPassword: this.handleSendLink,
            onDelete: this.onDelete,
            onArchive: this.onArchive,
            onUnArchive: this.onUnarchive,
            onReset2FA: this.showConfirmModal,
            changeSubscriptionStatus: this.changeSubscriptionStatus,
            baseUrl,
            showStatus,
          })} data={data} index="id" />
          <TablePagination
            component="div"
            count={this.props.totalItems}
            rowsPerPage={this.props.itemsPerPage}
            page={this.props.pageNumber - 1}
            backIconButtonProps={{"aria-label": "Previous Page"}}
            nextIconButtonProps={{"aria-label": "Next Page"}}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
        <Reset2FAConfirmModal
          open={this.state.isConfirmModalOpen}
          onClose={this.hideConfirmModal}
          onReset={this.props.onReset2FA}
          id={this.state.userId}
        />
      </div>
    );
  }
}

export default connect<IAnyProps, IAnyProps, IAnyProps & { baseUrl: string }, RootState>(
  state => ({
    data: selector.getItems(state),
    isLoaded: selector.getIsLoaded(state),
    itemsPerPage: selector.getItemsPerPage(state),
    pageNumber: selector.getPageNumber(state),
    showStatus: selector.getFilterActivityStatus(state),
    totalItems: selector.getTotalItems(state),
    name: selectorSearchForm(state, "name") || "",
    email: selectorSearchForm(state, "email") || "",
    phone: selectorSearchForm(state, "phone") || "",
    role:
      selectorSearchForm(state, "role") ||
      "Owner+Planner+Viewer+Employee+ExternalTester",
    userClientCompanyFilter:
      selectorSearchForm(state, "userClientCompanyFilter") || "",
    createdAt: selectorSearchForm(state, "createdAt") || "",
    deletedAt: selectorSearchForm(state, "deletedAt") || ""
  }),
  {
    postEmail,
    loadItem: getUser,
    loadItems: getUsersFiltered,
    setItemsPerPage: usersLoader.setItemsPerPage,
    setPageNumber: usersLoader.setPageNumber,
    archiveItem: archiveItem("Client"),
    unarchiveItem: unarchiveItem("Client"),
    changeSubscriptionStatus: putUserAction,
    onReset2FA: resetAction2FA,
    deleteItem: deleteItem("Client"),
    clientAccountModalOpen
  }
)(ClientsList);
