import * as React from 'react';
import {connect} from 'react-redux';
import {withStyles, Modal, Typography, Grid, Button} from '@material-ui/core';
import {
  forgotPasswordModalClose,
  twoFactorInitClose
} from '../../../actions';
import {getIsOpenTwoFactorInit} from "../../../selectors/modal";
import {defaultStyleWidth} from '../../../styles/material/popup-style';
import * as QRCode from 'qrcode.react';
import {twoFactorCheckOpen} from "../../../actions";

class TwoFactorInitModal extends React.Component<any> {
  handleClose = () => {
    this.props.twoFactorInitClose();
  };

  handleClick = () => {
    this.props.twoFactorInitClose();
    this.props.twoFactorCheckOpen();
  };

  render() {
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.props.twoFactorInit}
        onClose={this.handleClose}
      >
        <div className={this.props.classes.paper}>
          <Grid item={true} xs={12}>
            <Typography variant="title" component="h3" id="two-factor-init-modal-title">
              Two-factor authentication
            </Typography>
            <br/>
              <small>
                Please scan the following QR code with Mobile Authenticator
                (eg: Google Authenticator, Duo Mobile, Authy, Microsoft Authenticator etc)
                to set-up two-factor authentication.
              </small>
              <br/>
              <QRCode
                value={this.props.twoFactorInit.url}
                renderAs="svg"
                size={240}
                className="qr-code-center"
              />
              <small>
                If you can't scan the QR code please enter the following code manually in your Mobile
                Authenticator&nbsp;application:&nbsp;<b>{this.props.twoFactorInit.code}</b>
              </small>
              <br/><br/>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={this.handleClick}>
                Continue
              </Button>
          </Grid>
        </div>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    twoFactorInit: getIsOpenTwoFactorInit(state),
  };
}

const connectedComponent = connect(mapStateToProps, {
  forgotPasswordModalClose,
  twoFactorInitClose,
  twoFactorCheckOpen,
})(TwoFactorInitModal);

export default withStyles(defaultStyleWidth(360))(connectedComponent);
