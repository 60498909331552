import * as React from "react";
import { WithStyles, withStyles } from "@material-ui/core";
import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent/CardContent";
import Button from "@material-ui/core/Button/Button";
import Typography from "@material-ui/core/Typography/Typography";
import { Link, Route, Switch } from "react-router-dom";
import { styles } from "../../../styles/material/tesrer-card";
import {
  getBankDetails,
  getTesterSpecialisms
} from "../../../selectors/tester-profile";
import { createLoaderActionItem } from "../../../interfaces/reducer-item.interface";
import * as actionType from "../../../constants/action-type";
import { dataFetcher } from "../../../components/dataFetcher";
import { IBankDatails } from "../../../models/bank-datails.interface";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid/Grid";
import HiddenBankDetailsField from "../../../components/common/tester/HiddenBankDetailsField";
import { connect } from "react-redux";
import { IAnyProps } from "../../../interfaces/any-props.interface";
import certificateSelector from "../../../selectors/certificate";
import insuranceSelector from "../../../selectors/insurance";
import DBScertificateSelector from "../../../selectors/dbs-certificate";
import { TableNoItems } from "../../../components/table/TableNoItems";

interface IOwnProps {
  bankDetails: IBankDatails;
  bankDetailsIsLoaded: boolean;
}

type IProps = IOwnProps & WithStyles;

class BankDetailsCard extends React.Component<
  IProps & RouteComponentProps<any>,
  {}
> {
  baseUrl = this.props.match.path;

  render() {
    if (!this.props.bankDetailsIsLoaded) {
      return <TableNoItems asLoading hasWrapper />;
    }

    const highlight = () => {
      const { account_no, sort_code } = this.props.bankDetails;

      if (!account_no || !sort_code) {
        return " box-shadow-danger";
      }

      return "";
    };

    return (
      <Card className={this.props.classes.card + highlight()}>
        <div className={this.props.classes.details}>
          <CardContent className={this.props.classes.content}>
            <Typography
              variant="headline"
              className={this.props.classes.header}
            >
              <span>Bank Details</span>
              <span className={this.props.classes.rightAlign}>
                <Link to={`${this.baseUrl}/edit-bank-details`}>
                  <Button size="small" color="primary">
                    Edit
                  </Button>
                </Link>
              </span>
            </Typography>
            <hr />
            <Grid container>
              <Grid item xs={12} sm={6} md={6}>
                <span className="mr-4">Account no.</span>
                <HiddenBankDetailsField
                  hiddenData={this.props.bankDetails.account_no || ""}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <span className="mr-4">Sort code</span>
                <HiddenBankDetailsField
                  hiddenData={this.props.bankDetails.sort_code || ""}
                />
              </Grid>
            </Grid>
          </CardContent>
        </div>
      </Card>
    );
  }
}

const connected = connect<IAnyProps, IAnyProps, IAnyProps>(state => {
  const bankDetailsState = getBankDetails(state);
  return {
    bankDetails: bankDetailsState.item,
    bankDetailsIsLoaded: bankDetailsState.loaded
  };
})(BankDetailsCard);

export default withRouter(withStyles(styles as any)(connected)) as any;
