import * as React from 'react';
import { getHeaderColumns } from '../../helpers/crud-columns';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {FILTER_TYPE} from "../../constants/filter";

class EnhancedTableHead extends React.Component<{
  columns: string[],
  status?: FILTER_TYPE
}, any> {
  render() {
    const { columns, status } = this.props;
    const columnsMerged = status ? getHeaderColumns(columns, status) : columns;

    return (
      <TableHead>
        <TableRow>
          {columnsMerged.map((column, index) => {
            return (
              <TableCell
                style={{whiteSpace: "pre-wrap"}}
                key={index}
                // className="text-nowrap"
              >
                {column}
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

export default EnhancedTableHead;
