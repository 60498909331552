import React, { useState } from "react";
import * as Roles from "constants/roles";
import TestStatusBlock from "components/auth/TestStatusBlock";
import { TestStatus } from "constants/test-status";
import PermissionBlock from "components/auth/PermissionBlock";
import { ITest } from "models/test.interface";
import DocumentDeleteModal from "../DocumentDeleteModal";
import { IDocument } from "models/document.interface";

type Props = {
  test: ITest;
  document: IDocument;
  requiredStatuses?: TestStatus[]
  onDelete: () => void;
}

export function DeleteDocumentColumn({test, requiredStatuses = [], onDelete}: Props) {
  const [open, setOpen] = useState(false);

  const onClickHandler = () => {
    setOpen(true);
  };

  const onCloseHandler = () => {
    setOpen(false);
  };

  const onDeleteHandler = () => {
    onDelete();
    onCloseHandler();
  };

  return <PermissionBlock roles={[Roles.ROLE_PLANNER]}>
    <TestStatusBlock
      currentStatus={test.status}
      requiredStatuses={requiredStatuses}
    >
      <button
        className="btn-delete"
        onClick={onClickHandler}
      >
        Delete
      </button>

      <DocumentDeleteModal
        open={open}
        onDelete={onDeleteHandler}
        onClose={onCloseHandler}
      />
    </TestStatusBlock>
  </PermissionBlock>
}
