import {createSelector} from 'reselect';
import {requestAccountDeletingSubmit} from "../actions";
import {IStateModal} from "../reducers/modal";
import {TestStatus} from "../constants/test-status";
import moment from "moment";

const getModals: (any: any) => IStateModal = state => state.modals;

export const getIsOpenModalForgotPassword = createSelector(
  getModals,
  state => state.isOpenForgotPassword
);

export const getIsOpenModalChangePassword = createSelector(
  getModals,
  state => state.isOpenChangePassword
);

export const getIsOpenModalRequestAccountDeleting = createSelector(
  getModals,
  state => state.isOpenRequestDeletion
);

export const getIsOpenModalTesterAccount = createSelector(
  getModals,
  state => state.isOpenTesterAccount
);

export const getIsOpenModalClientAccount = createSelector(
  getModals,
  state => state.isOpenClientAccount
);

export const getIsOpenModalLogin = createSelector(
  getModals,
  state => state.isOpenLogin
);

export const getIsOpenModalContactUs = createSelector(
  getModals,
  state => state.isOpenContacts
);

export const getIsOpenThankYouForRegistrationModal = createSelector(
  getModals,
  state => state.isOpenAfterRegistrationModal
);

export const getIsOpenModalApproach = createSelector(
  getModals,
  state => state.isOpenApproach
);

export const getIsOpenModalReasign = createSelector(
  getModals,
  state => !!state.isOpenReassign
);

export const getModalReasignId = createSelector(
  getModals,
  state => state.isOpenReassign
);

export const getModalReassignGroup = createSelector(
  getModals,
  state => state.modalGroup
);

export const getModalReassignTitle = createSelector(
  getModals,
  state => state.modalTitle
);

export const getIsOpenModalQuestion = createSelector(
  getModals,
  state => !!state.isOpenQuestion
);
export const getModalQuesionItem = createSelector(
  getModals,
  state => state.isOpenQuestion
);

export const getIsOpenModalQuestionNew = createSelector(
  getModals,
  state => state.isOpenQuestionNew
);

export const getIsOpenModalAmendments = createSelector(
  getModals,
  state => !!state.isOpenAmendments
);

export const getModalAmendmentsItem = createSelector(
  getModals,
  state => state.isOpenAmendments
);

export const getIsOpenModalAmendmentsAnswer = createSelector(
  getModals,
  state => !!state.isOpenAmendmentsAnswer
);

export const getModalAmendmentsAnswerItem = createSelector(
  getModals,
  state => state.isOpenAmendmentsAnswer
);

export const getIsOpenModalAcceptReport = createSelector(
  getModals,
  state => state.isOpenAcceptReport
);

export const getIsOpenModalCreateReportDocument = createSelector(
  getModals,
  state => !!state.isOpenCreateReportDocument
);

export const getModalReportDocumentCreationItem = createSelector(
  getModals,
  state => state.isOpenCreateReportDocument
);

export const getIsOpenModalCalendar = createSelector(
  getModals,
  state => !!state.modalCalendar
);

export const getOpenModalCalendarEvents = createSelector(
  getModals,
  state => state.modalCalendar ? state.modalCalendar.events : []
);

export const getOpenModalCalendarDate = createSelector(
  getModals,
  state => state.modalCalendar ? state.modalCalendar.date : moment()
);

export const getIsOpenModalDeleteTest = createSelector(
  getModals,
  state => !!state.openDeleteTest
);

export const getDeleteTestId = createSelector(
  getModals,
  state => state.openDeleteTest
);

export const getIsOpenBookingReject = createSelector(
  getModals,
  state => !!state.openBookingReject ? state.openBookingReject.status !== TestStatus.STATUS_DRAFT : false
);

export const getBookingRejectTestId = createSelector(
  getModals,
  state => state.openBookingReject ? state.openBookingReject.testId : 0
);

export const getBookingRejectBackRoute = createSelector(
  getModals,
  state => state.openBookingReject ? state.openBookingReject.backRoute : ''
);

export const getBookingRejectStatus = createSelector(
  getModals,
  state => state.openBookingReject ? state.openBookingReject.status : TestStatus.STATUS_DRAFT
);

export const getIsOpenBookingCancel = createSelector(
  getModals,
  state => state.openBookingCancel ? state.openBookingCancel.status !== TestStatus.STATUS_DRAFT : false
);

export const getBookingCancelTestId = createSelector(
  getModals,
  state => state.openBookingCancel ? state.openBookingCancel.testId : 0
);

export const getBookingCancelBackRoute = createSelector(
  getModals,
  state => state.openBookingCancel ? state.openBookingCancel.backRoute : ''
);

export const getBookingCancelStatus = createSelector(
  getModals,
  state => state.openBookingCancel ? state.openBookingCancel.status : TestStatus.STATUS_DRAFT
);

export const getIsOpenModalMessage = createSelector(
  getModals,
  state => !!state.isOpenMessage
);
export const getModalMessageItem = createSelector(
  getModals,
  state => state.isOpenMessage
);

export const getIsOpenModalMessageNew = createSelector(
  getModals,
  state => state.isOpenMessageNew
);

export const getIsOpenTwoFactorInit = createSelector(
  getModals,
  state => state.isOpenTwoFactorInit
);

export const getIsOpenTwoFactorCheck = createSelector(
  getModals,
  state => state.isOpenTwoFactorCheck
);

export const getAvordValidateOpenModal = createSelector(
    getModals,
    state => state.avordValidation
);

export const getEditProposalByTesterOpenModal = createSelector(
    getModals,
    state => state.editProposalByTester
);

export const getAcceptEditProposalModeOpenModal = createSelector(
    getModals,
    state => state.acceptEditProposalByTester
);
