import * as React from 'react';
import {composeValidators, fieldMaxLength, fieldsNotEmpty} from "../../../../helpers/validations/validation";
import {InjectedFormProps, reduxForm} from "redux-form";
import { connect } from "react-redux";
import { faqRequest } from "../../../../actions/faq";
import { FaqForm } from "./FaqForm";
import { withStyles } from "@material-ui/core";
import { withRouter } from 'react-router-dom'
import { defaultStyleWidth } from '../../../../styles/material/popup-style';
import routerModal from '../../../../components/routerModal';
import {WithStyles} from "@material-ui/core/styles/withStyles";

const FORM_NAME = 'FaqCreate';

class FaqCreate extends React.Component<InjectedFormProps<{}, {}> & {
    postData: (...any) => void,
    backRoute: string
} & WithStyles<any>> {
  render() {
    const { handleSubmit, postData, backRoute } = this.props;

    return (
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit((values) => {
          postData(values, FORM_NAME, backRoute);
        })}
      >
        <FaqForm backRoute={backRoute}/>
      </form>
    );
  }
}

const formConnected = reduxForm({
  form: FORM_NAME,
  validate: composeValidators(
    fieldsNotEmpty(['question', 'answer',]),
    fieldMaxLength(['answer'], 2000),
    fieldMaxLength(['question'], 150),
  )
})(FaqCreate);

const connected = connect(undefined, {
  postData: faqRequest.postItem,
})(formConnected);

export default routerModal(withRouter(connected));
