export enum Group {
  OWNER = "Common Company Owner",
  ADMIN = "Admin",
  SUPER_ADMIN = "SuperAdmin",
  PLANNER = "Planner",
  VIEWER = "Viewer",
  GUEST = "Guests group",
  TESTER = "AVORD Tester",
  MANAGER = "Testing Company Manager",
  EXTERNAL_THIRD_PARTY = "External Third Party",
  EMPLOYEE = "Employee",
  AVORD_CONSULTANT_INPUT = "AVORD Consultant Input",
  ACCOUNT_MANAGER = "Account Manager",
}

export enum GroupTypes {
  CLIENT = 1,
  TESTER = 2,
  ADMIN = 3
}
