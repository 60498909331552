import * as React from "react";
import {connect} from "react-redux";
import {Modal} from "@material-ui/core";
import {messageNewModalClose} from "../../../actions/modals";
import {getIsOpenModalMessageNew} from "../../../selectors/modal";
import {FieldGeneric} from "../../../components/forms/FieldGeneric";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {reduxForm, InjectedFormProps} from "redux-form";
import ReduxTextField from "../../../components/forms/ReduxTextField";
import {messageNewModalSubmit} from "../../../actions/message";
import {MESSAGE_NEW_FORM_NAME} from "../../../constants/forms";
import {composeValidators, fieldMaxLength, fieldsNotEmpty} from "../../../helpers/validations/validation";

interface IStateToProps {
  isOpenModal: any;
}

interface IDispatchToProps {
  modalClose: () => any;
  modalSubmit: (idTest, message) => any;
}

class MessageNewModal extends React.Component<IStateToProps
  & IDispatchToProps
  & RouteComponentProps<any>
  & InjectedFormProps<any>> {
  render() {
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.props.isOpenModal}
        onClose={this.handleClose}
        className="d-flex overflow-auto"
      >
        <div className="styled-modal sm">
          <form noValidate autoComplete="off" onSubmit={this.props.handleSubmit((values) => {
            this.props.modalSubmit(this.props.match.params.test, values.message.trim());
          })}>
            <h4>New Message</h4>
            <hr/>
            <div className="styled-modal-body">
              <FieldGeneric
                name="message"
                rows={4}
                multiline={true}
                label="Message *"
                margin="normal"
                component={ReduxTextField}
              />
            </div>
            <div className="text-right mt-4">
              <button onClick={this.handleClose} className="btn-cancel">Cancel</button>
              <button type="submit" className="btn-save">Save</button>
            </div>
          </form>
        </div>
      </Modal>
    );
  }

  private readonly handleClose = () => {
    this.props.modalClose();
    this.props.untouch("message");
    this.props.change("message", "");
  };
}

function mapStateToProps(state) {
  return {
    isOpenModal: getIsOpenModalMessageNew(state)
  };
}

const connectedComponent = connect<IStateToProps, IDispatchToProps>(mapStateToProps, {
  modalClose: messageNewModalClose,
  modalSubmit: messageNewModalSubmit
})(MessageNewModal);

const formed = reduxForm({
  form: MESSAGE_NEW_FORM_NAME,
  destroyOnUnmount: false,
  validate: composeValidators(
    fieldsNotEmpty(["message"]),
    fieldMaxLength(["message"], 2000)
  )
})(connectedComponent);

const routered = withRouter(formed as any);

export default withRouter(routered as any) as any;
