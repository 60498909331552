import {Observable} from 'rxjs';
import {ActionsObservable} from 'redux-observable';
import * as actionType from '../constants/action-type';
import {flatMap, map, switchMap} from 'rxjs/operators';
import {postDocument} from '../api/requests/secured-document';
import {fromPromise} from "rxjs/internal-compatibility";
import {IActionPayload} from "./index";
import {startEndCatch} from "./pipes/errors";
import {createAction} from "redux-actions";
import {setRequestInProcess} from '../actions';

export const postSecuredDocumentEpic = (action$: ActionsObservable<IActionPayload<File>>): Observable<any> => {
  return action$
    .ofType(actionType.SECURED_DOCUMENT_POST)
    .pipe(
      switchMap((action: IActionPayload<File>)=> {
        return fromPromise(postDocument(action.payload))
          .pipe(
            map((data) => {
              return createAction(actionType.SECURED_DOCUMENT_POST_COMPLETED, () => data)();
            }),
            ...startEndCatch('postSecuredDocumentEpic'),
          );
      }),
    );
};