import * as _ from 'lodash';

const convertModelToSwagger = (model) => {
  return _.pick(model, ['id', 'shortName', 'fullName']);
};

export const convertorFromSwagger = model => {
  if (Array.isArray(model)) {
    return [...model];
  }

  return {...model};
};

export const convertorToSwagger = model => {
  if (Array.isArray(model)) {
    return [...model];
  }

  return convertModelToSwagger(model);
};
