import moment from "moment-timezone";

function getMoment() {
  return moment()
    .tz("Europe/London");
}

export const getIsActiveAsPreviousWeek = (
  year: number,
  weekNumber: number,
  subtractWeeks = 1,
): boolean => {
  const mondayNoonInUK = getMoment()
    .set({
      hour: 12,
      minute: 0,
      second: 0,
      millisecond: 0
    });

  const searchWeek = getMoment()
    .set({
      week: getMoment().isBefore(mondayNoonInUK) ?  weekNumber + 1 : weekNumber,
    });

  const heightsWeekNumber = getMoment()
    .isoWeek();

  const lowWeekNumber = heightsWeekNumber - subtractWeeks;

  const searchWeekNumber = searchWeek.isoWeek();
  const previousWeekYear = searchWeek.year();

  return (
    searchWeekNumber <= heightsWeekNumber &&
    searchWeekNumber >= lowWeekNumber &&
    year === previousWeekYear
  );
};
