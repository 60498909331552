import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {bindActionCreators, Dispatch} from "redux";
import { History } from "history";
import { Button } from "@material-ui/core";
import { AdminUrls } from "constants/admin-urls";
import PermissionBlock from "components/auth/PermissionBlock";
import { ROLE } from 'constants/roles';
import { Permission } from "constants/permission";
import { adminValidateAction } from "actions";
import AdminValidationButton from "./AdminValidationButton";
import { ROLE_ACCOUNT_MANAGER, ROLE_ADMIN } from "constants/roles";
import { getRoles, getUserPermissions } from 'selectors/auth';
import { IState } from 'interfaces/state.interface';

interface IAdminBackToTestProps {
  history: History;
  showValidateButton?: boolean;
  testId: number;
  userRoles: ROLE[];
  userPermissions: Permission[];
}

const AdminBackToTest = ({
  history,
  showValidateButton,
  testId,
  userRoles,
  userPermissions,
}: IAdminBackToTestProps) => {
  const backToTests = (): void => {
    history.push(AdminUrls.TESTS);
  };

  const isValidationAllowed = userRoles.includes(ROLE_ADMIN)
    || (
      userRoles.includes(ROLE_ACCOUNT_MANAGER)
      && userPermissions.includes(Permission.CAN_MANAGE_AVORD_CONSULTANCY_COMPANY_MANAGER)
    );

  return (
    <div>
      <PermissionBlock roles={[ROLE_ADMIN, ROLE_ACCOUNT_MANAGER]}>
        {showValidateButton && isValidationAllowed && <AdminValidationButton testId={testId} />}
        <Button
          className="float-right mb-2"
          variant="contained"
          color="default"
          onClick={backToTests}
        >
          Back to tests
        </Button>
      </PermissionBlock>
    </div>
  );
};

const mapStateToProps = (state: IState) => ({
  userRoles: getRoles(state),
  userPermissions: getUserPermissions(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      adminValidate: adminValidateAction,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AdminBackToTest as any)) as any;
