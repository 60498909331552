import { createSelector } from "reselect";
import { TimeExpensesStateType } from "../reducers/time-expenses";
import {
  ReducedWeekType,
  TimeExpensesWeekType
} from "../types/timesheets-and-expenses";
import { TE_STATUS } from "../constants/timesheet-expenses";
import { getTotalDaysAndExpenses } from "../helpers/timesheets-expenses";

const getRootState = state => state.timeExpenses;

const getIsLoaded = createSelector(
  getRootState,
  (state: TimeExpensesStateType) => state.isLoaded
);

const getTimeCategoriesList = createSelector(
  getRootState,
  (state: TimeExpensesStateType) => state.timeCategoriesList
);
const getExpensesTypesList = createSelector(
  getRootState,
  (state: TimeExpensesStateType) => state.expensesTypesList
);

const getTimeExpensesWeeks = createSelector(
  getRootState,
  (state: TimeExpensesStateType) => state.timeExpensesWeeks
);
const getSelectedDayData = createSelector(
  getRootState,
  (state: TimeExpensesStateType) => state.selectedDayData
);
const getSelectedProjectWeeks = createSelector(
  getRootState,
  (state: TimeExpensesStateType) => state.selectedProjectWeeks
);
const getArchivedDocumentsList = createSelector(
  getRootState,
  (state: TimeExpensesStateType) => state.archivedDocuments
);
const getTimesheetManagersList = createSelector(
  getRootState,
  (state: TimeExpensesStateType) => state.timesheetManagers
);
const getSelectedApprovedProjectWeeks = createSelector(
  getRootState,
  (state: TimeExpensesStateType) => {
    let allProjectsWeeks: TimeExpensesWeekType[] = [];
    const timeExpensesWeeks: ReducedWeekType[] = state.timeExpensesWeeks;
    timeExpensesWeeks.map(week => {
      if (week?.projectsWeeks) {
        allProjectsWeeks = [...allProjectsWeeks, ...week.projectsWeeks];
      }
    });
    return allProjectsWeeks
      .filter(
        (week: TimeExpensesWeekType) =>
          week.status === TE_STATUS.STATUS_APPROVED &&
          state.selectedProjectWeeks.includes(week.id as number)
      )
      .map((week: TimeExpensesWeekType) => {
        const { totalDays, totalExpensesAmount } = getTotalDaysAndExpenses(
          week.days
        );
        const poNumbers: string[] = [];
        if (totalDays && week.purchaseOrderNumber) {
          poNumbers.push(week.purchaseOrderNumber);
        }
        if (totalExpensesAmount && week.expensesPurchaseOrderNumber) {
          poNumbers.push(week.expensesPurchaseOrderNumber);
        }

        return {
          id: `${week.weekNumber}-${week.project}-${week.consultant}`,
          weekNumber: week.weekNumber,
          name: `${week.companyName} - ${week.projectName}`,
          consultant: week.consultantName,
          poNumbers
        };
      });
  }
);
const getConsultantNameByConsultantProfileId = consultantProfileId =>
  createSelector(getRootState, (state: TimeExpensesStateType) => {
    let allProjectsWeeks: TimeExpensesWeekType[] = [];
    const timeExpensesWeeks: ReducedWeekType[] = state.timeExpensesWeeks;
    timeExpensesWeeks.map(week => {
      if (week?.projectsWeeks) {
        allProjectsWeeks = [...allProjectsWeeks, ...week.projectsWeeks];
      }
    });

    return (
      allProjectsWeeks.find(
        (week: TimeExpensesWeekType) => week.consultant === consultantProfileId
      )?.consultantName || ""
    );
  });

const isSubmittedProjectWeek = (dayDate?: string) =>
  createSelector(getRootState, (state: TimeExpensesStateType) => {
    if (!dayDate) {
      return false;
    }

    const [consultantId, projectId] = dayDate.split("-").reverse();

    const allProjectsWeeks: TimeExpensesWeekType[] = state.timeExpensesWeeks.flatMap(
      (week: ReducedWeekType) => week?.projectsWeeks ?? []
    );

    const weekStatus =
      allProjectsWeeks.find(
        (week: TimeExpensesWeekType) =>
          week.project.toString() === projectId &&
          week.consultant.toString() === consultantId
      )?.status || 0;

    return weekStatus > 1;
  });

export default {
  getIsLoaded,
  getTimeExpensesWeeks,
  getSelectedDayData,
  getTimeCategoriesList,
  getExpensesTypesList,
  getSelectedProjectWeeks,
  getSelectedApprovedProjectWeeks,
  getArchivedDocumentsList,
  getTimesheetManagersList,
  getConsultantNameByConsultantProfileId,
  isSubmittedProjectWeek
};
