import * as React from "react";
import { Modal, Select, MenuItem, TextField } from "@material-ui/core";
import {connect} from "react-redux";
import testingCompaniesSelector from "../../../selectors/testing-companies";
import {FORM_NAME} from "./TestersSearchForm";
import {formValueSelector} from "redux-form";
import { openSnackbar } from "../../../actions/snackbar";

const selectorSearchForm = formValueSelector(FORM_NAME);

class AssignCompanyManagerModal extends React.Component<any & any> {
  state = {
    company: "",
    companyName: ""
  }

  onClickHandler = () => {
      const companyName = this.state.companyName.trim();
      if(!this.state.company && this.props.testingCompanies.filter(company=>company.name===companyName).length) {
        this.props.showCompanyNameError();
        return;
      }
      this.props.onConfirm(
        this.props.testerProfileId,
        this.props.userGroup,
        this.props.pageNumber,
        this.props.itemsPerPage,
        this.props.showStatus,
        this.props.name,
        this.props.email,
        this.props.phone,
        this.props.role || "TesterAndManager",
        this.props.createdAt,
        this.props.deletedAt,
        this.props.userDbsFilter,
        this.props.userTestingCompanyFilter,
        this.props.userClientCompanyFilter,
        this.state.company,
        companyName,
      );
    if(this.state.company || this.state.companyName){
      this.props.onClose();
    }
  };

  onCloseHandler = () => {
    this.setState({
      company: "",
      companyName: ""
    });
    this.props.onClose();
  }

  handleChange = (event) => {
    this.setState({ company: event.target.value });
  };

  handleCompanyInput = (event)=> {
    this.setState({ companyName: event.target.value });
  }

  render() {
    const {testingCompanies} = this.props;
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.props.open}
        onClose={this.onCloseHandler}
        className="d-flex overflow-auto"
      >
        <div className="styled-modal sm">
          <h4>Please select Testing Company for the manager</h4>
          <hr />
          <div>
          <Select
            fullWidth
            onChange={this.handleChange}
            value={this.state.company}
            disabled={!!this.state.companyName.length}
          >
            <MenuItem value="">
              None
            </MenuItem>
            {testingCompanies.map(item => {
              return (
                <MenuItem
                  key={item.id}
                  value={item.id}
                >
                  {item.name}
                </MenuItem>
              );
            })}
          </Select>
            <div
              style={{margin: "10px 10px 20px"}}
            >
              OR
            </div>
            <TextField fullWidth className="mb-4" type="text" placeholder="Add a new Testing Company" disabled={!!this.state.company} onChange={this.handleCompanyInput} />
          </div>
          <div className="text-right">
            <button className="btn-cancel" onClick={this.onCloseHandler}>
              Cancel
            </button>
            <button
              type="submit"
              className="btn-save"
              onClick={this.onClickHandler}
            >
              ASSIGN AND CONTINUE
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

export default connect<any>(
  state => ({
    testingCompanies: testingCompaniesSelector.getItems(state),
    name: selectorSearchForm(state, "name") || "",
    email: selectorSearchForm(state, "email") || "",
    phone: selectorSearchForm(state, "phone") || "",
    createdAt: selectorSearchForm(state, "createdAt") || "",
    deletedAt: selectorSearchForm(state, "deletedAt") || "",
    userDbsFilter: selectorSearchForm(state, "userDbsFilter") || "",
    userTestingCompanyFilter: selectorSearchForm(state, "userTestingCompanyFilter") || "",
    role: selectorSearchForm(state, "role") || "",
  }),
  (dispatch) => {
    return ({
      showCompanyNameError: ()=>dispatch(openSnackbar("Testing Company already exist", 4000)),
    });
  }
)(AssignCompanyManagerModal) as React.ComponentClass<any>;
