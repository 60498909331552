import React from "react";
import { Checkbox, TableCell } from "@material-ui/core";
import selector from "../../../../selectors/time-expenses";
import { bindActionCreators, Dispatch } from "redux";
import {
  addSelectedProjectWeeksAction,
  removeSelectedProjectWeeksAction
} from "actions/time-expenses";
import { connect } from "react-redux";
import {
  TIME_SHEETS_USER_TYPE,
  TimeExpensesWeekType
} from "types/timesheets-and-expenses";
import { TE_STATUS } from "constants/timesheet-expenses";
import { getTotalDaysAndExpenses } from "helpers/timesheets-expenses";

type WeekHeadingCheckboxProps = {
  projectsWeeks: TimeExpensesWeekType[];
  selectedProjectWeeks: number[];
  addItems?: (ids: number[]) => void;
  removeItems?: (ids: number[]) => void;
  userType: TIME_SHEETS_USER_TYPE;
};

const consultantFilter = (week: TimeExpensesWeekType) => {
  const { totalDays, totalExpensesAmount } = getTotalDaysAndExpenses(week.days);
  const hasTimeExpensesLog = totalDays > 0 || totalExpensesAmount > 0;
  return (
    !!week?.id &&
    (week.status === TE_STATUS.STATUS_DRAFT ||
      week.status === TE_STATUS.STATUS_REJECTED) &&
    hasTimeExpensesLog
  );
};

const clientFilter = (week: TimeExpensesWeekType) => {
  return week.status === TE_STATUS.STATUS_SUBMITTED;
};

const adminFilter = (week: TimeExpensesWeekType) => {
  return week.status === TE_STATUS.STATUS_APPROVED;
};

const WeekHeadingCheckbox = (props: WeekHeadingCheckboxProps) => {
  const {
    projectsWeeks,
    addItems,
    removeItems,
    selectedProjectWeeks,
    userType
  } = props;
  let activeFilter = consultantFilter;
  if (userType === TIME_SHEETS_USER_TYPE.CLIENT) {
    activeFilter = clientFilter;
  }
  if (userType === TIME_SHEETS_USER_TYPE.ADMIN) {
    activeFilter = adminFilter;
  }

  const projectsWeeksIds = projectsWeeks
    .filter(activeFilter)
    .map((week: TimeExpensesWeekType) => week.id as number);

  const isChecked =
    !!projectsWeeksIds.length &&
    projectsWeeksIds.every(id => selectedProjectWeeks.includes(id));

  const toggleHandler = () => {
    if (isChecked && removeItems) {
      removeItems(projectsWeeksIds);
    }
    if (!isChecked && addItems) {
      const ids = projectsWeeksIds.filter(
        id => !selectedProjectWeeks.includes(id)
      );
      addItems(ids);
    }
  };

  return (
    <TableCell align="center" className="timesheet-cell-checkbox">
      <Checkbox
        onChange={toggleHandler}
        disabled={!projectsWeeksIds.length}
        checked={isChecked}
      />
    </TableCell>
  );
};

function mapStateToProps(state) {
  return {
    selectedProjectWeeks: selector.getSelectedProjectWeeks(state)
  };
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      addItems: addSelectedProjectWeeksAction,
      removeItems: removeSelectedProjectWeeksAction
    },
    dispatch
  );
};

const connected = connect(
  mapStateToProps,
  mapDispatchToProps
)(WeekHeadingCheckbox);

export default connected;
