import * as React from "react";
import {withRouter} from "react-router-dom";
import { connect } from "react-redux";
import {Dispatch} from "redux";
import { IReport } from "../../../../models/report.interface";
import { dataFetcher } from "../../../../components/dataFetcher";
import { getTestReport } from "../../../../actions/reports";
import selector from "../../../../selectors/reports";
import { downloadReportFile } from "../../../../api/requests/document-download";
import AdminBackToTest from "../../../client-tester/test-details/button/AdminBackToTest";
import {IWithRouter} from "../../../../interfaces/with-router.interface";

interface IBaseReportPageProps {
  pageName: string;
  report?: IReport;
  downloadReportFile: (id: number) => (dispatch: Dispatch) => Promise<void>;
  match: {
    params: { test: string };
  };
}

type BaseReportPageProps = IBaseReportPageProps & IWithRouter

const BaseReportPage = (props: BaseReportPageProps): JSX.Element => {
  const { report, pageName, downloadReportFile, match } = props;
  const showIntroduction = pageName === "Executive Summary" ? report && report.summaryIntroduction : report && report.technicalIntroduction
  const showInformationGathering = pageName === "Executive Summary" ? report && report.summaryGathering : report && report.technicalGathering
  const noDataReport = !report || !report.summaryIntroduction  || !report.technicalIntroduction || +match.params.test !== report.id

  const downloadFile = (): void => {
    const documentId = report && report.id;
    if (documentId) {
      if (downloadReportFile) {
        downloadReportFile(documentId);
      }
    }
  };

  const renderIfNotSubmitted = (): JSX.Element => {
    return (
      <>
        <AdminBackToTest />
        <div className="styled-block table-no-items">
          Report not submitted yet!
        </div>
      </>
    );
  };

  const renderReportInformation = (): JSX.Element => {
    return (
      <>
        <AdminBackToTest />
        <div className="styled-block">
          <div className="block-header-wrapper mb-4">
            <h4 className="block-header">{pageName}</h4>
            <button className="btn-download-pdf" onClick={downloadFile}>
              Download PDF
            </button>
          </div>
          <p>
            Introduction:{" "}
            {showIntroduction}
          </p>
          {showInformationGathering && (
              <p>
                Information gathering:{" "}
                {showInformationGathering}
              </p>
          )}
        </div>
      </>
    );
  };

  return (
    <>
      {noDataReport
        ? renderIfNotSubmitted()
        : renderReportInformation()}
    </>
  );
};

function mapStateToProps(state, props) {

  const reportById = state.reports.items.items
    .findIndex((item) =>
      item.id === +props.match.params.test
    );

  return {
    report: state.reports.items.items[reportById],
  };
}

const connectedComponent = connect(mapStateToProps, {
  downloadReportFile,
})(BaseReportPage);

const fetched = dataFetcher<IBaseReportPageProps>(connectedComponent, [
  {
    key: "report",
    selector: (state) => selector.getItems(state)[0],
    action: (props) => getTestReport(props.match.params.test),
    alwaysReceiveFreshData: true,
  },
]);

// @ts-ignore There is a known issue in TypeScript, which doesn't allow decorators to change the signature of the classes
export default withRouter(fetched);
