import React from "react";
import { Field } from "redux-form";
import ReduxTextField from "../../../../components/forms/ReduxTextField";
import Grid from "@material-ui/core/Grid/Grid";
import { Button } from "@material-ui/core";

const ExpensesTypesForm = ({
  goBack,
  isDisabled
}: {
  goBack: () => void;
  isDisabled: boolean;
}) => {
  return (
    <Grid container spacing={40}>
      <Grid item xs={12}>
        <Field name="name" label="Name *" component={ReduxTextField} />
        <br />
        <br />
        <div className="d-flex justify-content-between mt-4">
          <Button
            type="button"
            variant="contained"
            color="default"
            onClick={goBack}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isDisabled}
          >
            Save
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

export default ExpensesTypesForm;
