import React from 'react';


class Payment extends React.Component {
  render() {
    return (
      <div>
        <h1>Payment Batches</h1>
        <hr/>
      </div>
    )
  }
}

export default Payment;
