import * as React from 'react';
import {Field, FieldArray} from 'redux-form';
import ReduxTextField from "../../../../components/forms/ReduxTextField";
import Icon from "@material-ui/core/Icon/Icon";
import Button from "@material-ui/core/Button/Button";
import {FieldsProps} from "redux-form/lib/FieldArray";
import {ISpecialism} from "../../../../models/specialism.interface";
import {result} from 'lodash';
import SpecialismAddButton from "./SpecialismAddButton";
import {IAnyProps} from "../../../../interfaces/any-props.interface";
import {SpecialismUpdateList} from "./SpecialismUpdateList";

interface IProps {
  fields: FieldsProps<ISpecialism | IAnyProps>;
  index: number;
  member: string;
  level: number;

  [key: string]: any;
}

export class SpecialismUpdateListElement extends React.Component<IProps, {}> {
  removeField = () => this.props.fields.remove(this.props.index);
  list: React.SFC<any> = ({...rest}) => <SpecialismUpdateList level={this.props.level + 1} {...rest} />;

  render() {
    const {fields, member, index, level} = this.props;

    return (
      <li>
        <div className="row">
          <div className="col-sm-2">
            {
              member !== 'members[0]' &&
              <Button
                className="action-button"
                variant="fab"
                type="button"
                color="secondary"
                onClick={this.removeField}>
                <Icon>delete</Icon>
              </Button>
            }
          </div>
          <Field
            inputProps={{minLength: 1, maxLength: 100}}
            className="col-sm-3"
            name={`${member}.name`}
            type="text"
            component={ReduxTextField}
            placeholder="Name"/>
          <div className="col-sm-1"/>
            <Field
              inputProps={{minLength: 1, maxLength: 255}}
              className="col-sm-6"
              name={`${member}.description`}
              type="text"
              component={ReduxTextField}
              placeholder="Description"/>
          </div>
          {
            !level && !!result(fields.get(index), 'subTypes.length', false) &&
            <div style={{marginLeft: 50, marginTop: 20}}>
              <FieldArray name={`${member}.subTypes`} component={this.list}/>
            </div>
          }
          {
            !level && member !== 'members[0]' &&
            <div style={{marginLeft: 50}}>
              <FieldArray name={`${member}.subTypes`} component={SpecialismAddButton}/>
            </div>
          }
      </li>
    );
  }
}
