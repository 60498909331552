import React from "react";
import {connect} from "react-redux";
import getLastQuestion from "../../../actions/lastQuestion";
import {BASE_URL} from "../../../constants/urls";
import {formatDateWithTime} from "../../../helpers/date-formatter";
import {Link} from "react-router-dom";
import {Search} from "@material-ui/icons";
import {extractId} from "../../../helpers/converter-helper";

class DashboardBookingRequestQuestion extends React.Component {
  componentDidMount() {
    this.props.getLastQuestion();
  }

  render() {
    const {questions} = this.props;

    if (!questions) {
      return (
        <div className="styled-block">
          <h4 className="block-header mb-4">Pending Questions</h4>
          <div className="table-no-items">Loading...</div>
        </div>
      );
    }

    if (!questions.length) {
      return (
        <div className="styled-block">
          <h4 className="block-header mb-4">Pending Questions</h4>
          <div className="table-no-items">There are no items...</div>
        </div>
      );
    }

    return (
      <div className="styled-block">
        <h4 className="block-header mb-4">Pending Questions</h4>
        <div className="table-responsive styled-table">
          <table>
            <tbody>
            {questions.map((question) => (
              <tr key={question.id}>
                <td>
                  <div className="tester-image-name">
                    {question.authorPicture ?
                      <img src={`${BASE_URL}${question.authorPicture}`} alt={question.authorName}/> :
                      <img src={`/images/user.svg`} alt="No images"/>
                    }
                    <div>
                      <div className="author-name">{question.authorName}</div>
                      <span className="date">{formatDateWithTime(question.createdAt)}</span>
                    </div>
                  </div>
                </td>
                <td>{question.content}</td>
                <td>
                  <Link to={`/client/booking-requests/${extractId(question.test)}`}>
                    <Search/>
                  </Link>
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    questions: state.dashboard.lastQuestion
  }),
  {
    getLastQuestion
  }
)(DashboardBookingRequestQuestion);