import * as React from 'react';
import Button from "@material-ui/core/Button/Button";
import {Link, Route, Switch, withRouter} from "react-router-dom";
import CarouselList from "./CarouselList";
import CarouselSearchForm from "./CarouselSearchForm";
import CarouselCreate from "./CarouselCreate";
import CarouselUpdate from "./CarouselUpdate";
import {getCreateLink} from '../../../../helpers/router-helper';

class Carousel extends React.Component<any, {}> {
  baseUrl = this.props.match.path;

  createComponent = () => <CarouselCreate backRoute={this.baseUrl}/>;
  updateComponent = () => <CarouselUpdate backRoute={this.baseUrl}/>;

  render() {
    return (
      <div>
        <div className="crud-sections-header">
          <h4>Carousel</h4>
          <Link to={getCreateLink(this.baseUrl)}>
            <Button variant="contained" color="default">
              New Carousel
            </Button>
          </Link>
        </div>
        <hr/>
        <Switch>
          <Route path={getCreateLink(this.baseUrl)} render={this.createComponent}/>
          <Route path={this.baseUrl + "/:id"} render={this.updateComponent}/>
        </Switch>
        <CarouselSearchForm/>
        <CarouselList baseUrl={this.baseUrl}/>
      </div>
    );
  }
}

export default withRouter(Carousel);
