import * as React from 'react';
import Divider from "@material-ui/core/Divider/Divider";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import Menu from "@material-ui/core/Menu/Menu";
import IconButton from "@material-ui/core/IconButton/IconButton";
import {getAccountMenu} from "../../selectors/account-menu";
import {
  hideAccountMenu,
  loginModalOpen,
  logOut,
  showAccountMenu,
  changePasswordModalOpen,
  requestAccountDeletionModelOpen,
  testerAccountModalOpen,
  clientAccountModalOpen
} from "../../actions";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {
  ROLE,
  ROLE_ADMIN,
  ROLE_TESTER,
  ROLE_OWNER,
  ROLE_PLANNER,
  ROLE_VIEWER,
  ROLE_INTERNAL, ROLE_CONSULTANT
} from "../../constants/roles";
import {Avatar, ListItemText} from "@material-ui/core";

interface IStateToProps {
  anchorEl: any;
  email: string;
  name: string;
}

interface IDispatchToProps {
  loginModalOpen: (...any) => any;
  logOut: (...any) => any;
  showAccountMenu: (...any) => any;
  hideAccountMenu: (...any) => any;
  changePasswordModalOpen: (...any) => any;
  requestAccountDeletionModelOpen: () => void;
  testerAccountModalOpen: () => void;
  clientAccountModalOpen: () => void;
}

interface IOwnProps {
  roles: ROLE[];
}

class AccountButtons extends React.Component<IStateToProps & IDispatchToProps & IOwnProps, {}> {
  render() {
    return (
      <div>
        <IconButton
          aria-owns={this.props.anchorEl ? 'menu-appbar' : undefined}
          aria-haspopup="true"
          onClick={this.props.showAccountMenu}
          color="inherit"
        >
          <Avatar className="avord-gradient">{this.props.name.split(" ").slice(0,2).map((item) => item.slice(0,1))}</Avatar>
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={this.props.anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={!!this.props.anchorEl}
          onClose={this.props.hideAccountMenu}
        >
          {this.accountButtons.reduce((acc, element, currentIndex, array) => {
            if (array.length === currentIndex + 1) {
              return [...acc, element];
            }

            return [...acc, element, <Divider key={currentIndex * 100}/>]
          }, [])}
        </Menu>
      </div>
    )
  }

  private readonly handleChangePassword = () => {
    this.props.hideAccountMenu();
    this.props.changePasswordModalOpen();
  };

  private readonly handleRequestAccountDeletion = () => {
    this.props.hideAccountMenu();
    this.props.requestAccountDeletionModelOpen();
  };

  private readonly testerAccountModalOpen = () => {
    this.props.hideAccountMenu();
    this.props.testerAccountModalOpen();
  };

  private readonly clientAccountModalOpen = () => {
    this.props.hideAccountMenu();
    this.props.clientAccountModalOpen();
  };

  private readonly handleLogout = () => {
    this.props.logOut();
    this.props.hideAccountMenu();
  };

  private get accountButtons() {
    const buttons: any[] = [];
    const addChangePassword = () => {
      buttons.unshift(
        <MenuItem style={{height: 'auto'}} key={1}>
          <ListItemText>
            <small className="text-muted">Signed in as</small>
            <div>{this.props.email}</div>
          </ListItemText>
        </MenuItem>
      );
      buttons.push(
        <MenuItem onClick={this.handleChangePassword} key={2}>
          <ListItemText>Change password</ListItemText>
        </MenuItem>
      );
    };

    if (this.props.roles.indexOf(ROLE_ADMIN) >= 0) {
      buttons.push(
        <MenuItem {...{component: Link, to: "/admin"}} onClick={this.props.hideAccountMenu} key={3}>
          <ListItemText>Admin Panel</ListItemText>
        </MenuItem>
      );
      addChangePassword();
    }

    if (this.props.roles.indexOf(ROLE_TESTER) >= 0) {
      buttons.push(
        <MenuItem {...{component: Link, to: "/tester"}} onClick={this.props.hideAccountMenu} key={4}>
          <ListItemText>Control Panel</ListItemText>
        </MenuItem>
      );

      if (this.props.roles.indexOf(ROLE_INTERNAL) >= 0 || this.props.roles.indexOf(ROLE_CONSULTANT) >= 0) {
        buttons.push(
          <MenuItem onClick={this.testerAccountModalOpen} key={5}>
            <ListItemText>My account</ListItemText>
          </MenuItem>
        );
      }

      addChangePassword();

      if (this.props.roles.indexOf(ROLE_INTERNAL) >= 0 || this.props.roles.indexOf(ROLE_CONSULTANT) >= 0) {
        buttons.push(
          <MenuItem {...{component: Link, to: "/tester"}} onClick={this.handleRequestAccountDeletion} key={6}>
            <ListItemText>Request account deletion</ListItemText>
          </MenuItem>
        );
      }
    }

    if (this.props.roles.indexOf(ROLE_OWNER) >= 0 || this.props.roles.indexOf(ROLE_PLANNER) >= 0) {
      buttons.push(
        <MenuItem {...{component: Link, to: "/client/dashboard"}} onClick={this.props.hideAccountMenu} key={9}>
          <ListItemText>Control panel</ListItemText>
        </MenuItem>
      );
    }

    if (this.props.roles.indexOf(ROLE_VIEWER) >= 0) {
      buttons.push(
        <MenuItem {...{component: Link, to: "/client/tests-and-projects/tests"}} onClick={this.props.hideAccountMenu} key={10}>
          <ListItemText>Control panel</ListItemText>
        </MenuItem>
      );
    }

    if (this.props.roles.indexOf(ROLE_OWNER) >= 0 ||
      this.props.roles.indexOf(ROLE_PLANNER) >= 0 ||
      this.props.roles.indexOf(ROLE_VIEWER) >= 0) {
      buttons.push(
        <MenuItem onClick={this.clientAccountModalOpen} key={11}>
          <ListItemText>My account</ListItemText>
        </MenuItem>
      );
      addChangePassword();
    }

    buttons.push(
      <MenuItem {...{component: Link, to: "/"}} onClick={this.handleLogout} divider key={12}>
        <ListItemText>Log Out</ListItemText>
      </MenuItem>
    );

    return buttons;
  }
}

function mapStateToProps(state) {
  return {
    anchorEl: getAccountMenu(state),
    email: state.auth.email,
    name: state.auth.name,
  };
}

export default connect<IStateToProps, IDispatchToProps, IOwnProps>(mapStateToProps, {
  loginModalOpen,
  logOut,
  showAccountMenu,
  hideAccountMenu,
  changePasswordModalOpen,
  requestAccountDeletionModelOpen,
  testerAccountModalOpen,
  clientAccountModalOpen,
})(AccountButtons);
