import * as React from "react";
import {connect} from "react-redux";
import {Modal} from "@material-ui/core";
import {messageModalClose} from "../../../actions/modals";
import {getIsOpenModalMessage, getModalMessageItem} from "../../../selectors/modal";
import {FieldGeneric} from "../../../components/forms/FieldGeneric";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {reduxForm, InjectedFormProps} from "redux-form";
import ReduxTextField from "../../../components/forms/ReduxTextField";
import {messageModalSubmit} from "../../../actions/message";
import {IMessage} from "../../../models/message.interface";
import {MESSAGE_FORM_NAME} from "../../../constants/forms";
import {composeValidators, fieldMaxLength, fieldsNotEmpty} from "../../../helpers/validations/validation";

interface IStateToProps {
  isOpenModal: any;
  item: IMessage | false;
}

interface IDispatchToProps {
  modalClose: () => any;
  modalSubmit: (message, idMessage) => any;
}

class MessageModal extends React.Component<IStateToProps
  & IDispatchToProps
  & RouteComponentProps<any>
  & InjectedFormProps<any>> {
  render() {
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.props.isOpenModal}
        onClose={this.handleClose}
        className="d-flex overflow-auto"
      >
        <div className="styled-modal sm">
          <form noValidate autoComplete="off" onSubmit={this.props.handleSubmit((values) => {
            if (this.props.item) {
              this.props.modalSubmit(values.message.trim(), this.props.item.id);
            }
          })}>
            <h4>Message</h4>
            <hr/>
            <div className="styled-modal-body">
              <FieldGeneric
                name="message"
                rows={4}
                multiline={true}
                label="Details *"
                margin="normal"
                component={ReduxTextField}
              />
            </div>
            <div className="text-right mt-4">
              <button className="btn-cancel" onClick={this.handleClose}>Cancel</button>
              <button type="submit" className="btn-save">Save</button>
            </div>
          </form>
        </div>
      </Modal>
    );
  }

  private readonly handleClose = () => {
    this.props.modalClose();
    this.props.reset();
  };
}

function mapStateToProps(state) {
  // TODO: Fix content in initialized field.
  // let content: string = '';
  //
  // if (!(typeof message === "boolean")) {
  //   content = message.content;
  //   return {
  //     isOpenModal: getIsOpenModalMessage(state),
  //     item: message,
  //     initialValues: {
  //       message: content
  //     }
  //   };
  // }
  const message = getModalMessageItem(state);

  return {
    isOpenModal: getIsOpenModalMessage(state),
    item: message
  };
}

const formed = reduxForm({
  form: MESSAGE_FORM_NAME,
  validate: composeValidators(
    fieldsNotEmpty(["message"]),
    fieldMaxLength(["message"], 2000)
  )
})(MessageModal);

const connectedComponent = connect<IStateToProps, IDispatchToProps>(mapStateToProps as any, {
  modalClose: messageModalClose,
  modalSubmit: messageModalSubmit
})(formed);

const routered = withRouter(connectedComponent as any);

export default withRouter(routered as any) as any;
