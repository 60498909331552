import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import debounce from "debounce";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper/Paper";
import { TableNoItems } from "../../../../components/table/TableNoItems";
import selector from "../../../../selectors/timesheet-expenses-types";
import { getFilterPreviewValue } from "../../../../helpers/component-prev-values";
import TableCellArchive from "../../../../components/table/TableCellArchive";
import TableCellEdit from "../../../../components/table/TableCellEdit";
import {
  ARCHIVED,
  ACTIVE,
  FILTER_TYPE,
  ALL
} from "../../../../constants/filter";
import { TIMESHEET_EXPENSES_TYPES_FORM_NAME } from "../../../../constants/timesheet-expenses";
import {
  TimeCategoriesFilterParamsType,
  TimeCategoriesSearchFormType,
  TimeLogCategoryType
} from "../../../../types/timesheets-and-expenses";
import {
  archiveTimesheetExpensesTypeAction,
  getTimesheetExpensesTypesAction,
  TimesheetExpensesTypesLoader,
  unArchiveTimesheetExpensesTypeAction
} from "../../../../actions/timesheets-expenses-types";
import { getArchiveCellTitle } from "../../../../helpers/timesheets-expenses";

type ExpensesTypesListProps = {
  data: Array<TimeLogCategoryType>;
  isLoaded: boolean;
  pageNumber: number;
  itemsPerPage: number;
  totalItems: number;
  searchFormFilters: TimeCategoriesSearchFormType;
  setItemsPerPage: (value: number) => void;
  setPageNumber: (value: number) => void;
  loadItems: (params: TimeCategoriesFilterParamsType) => void;
  baseUrl: string;
  archiveExpensesType: (
    item: TimeLogCategoryType,
    filter?: FILTER_TYPE
  ) => void;
  unArchiveExpensesType: (
    item: TimeLogCategoryType,
    filter?: FILTER_TYPE
  ) => void;
};

const searchFormSelector = formValueSelector(
  TIMESHEET_EXPENSES_TYPES_FORM_NAME
);
const getShouldUpdateValues = (
  prevSearchValues: TimeCategoriesFilterParamsType,
  searchValues: TimeCategoriesFilterParamsType
) => {
  const shouldReloadList =
    JSON.stringify(prevSearchValues) !== JSON.stringify(searchValues);

  return {
    shouldReloadList,
    isTextFieldUpdated: prevSearchValues?.name !== searchValues?.name
  };
};

const ExpensesTypesList = (props: ExpensesTypesListProps) => {
  const {
    data,
    isLoaded,
    pageNumber,
    itemsPerPage,
    totalItems,
    searchFormFilters,
    setItemsPerPage,
    setPageNumber,
    loadItems,
    baseUrl,
    archiveExpensesType,
    unArchiveExpensesType
  } = props;

  const filterInitialValues = {
    pageNumber: 1,
    itemsPerPage: 25
  };

  const searchValues = { ...searchFormFilters, pageNumber, itemsPerPage };
  const prevSearchValues = getFilterPreviewValue(
    searchValues,
    filterInitialValues
  );
  const isInactiveFilter = searchFormFilters?.show === ARCHIVED;
  const shouldRerenderList = getShouldUpdateValues(
    prevSearchValues,
    searchValues
  );

  useEffect(() => {
    const { shouldReloadList, isTextFieldUpdated } = shouldRerenderList;
    const debounceLoadItems = debounce(loadItems, 500);

    if (isTextFieldUpdated) {
      debounceLoadItems(searchValues);
    } else if (shouldReloadList) {
      loadItems(searchValues);
    }
  }, [shouldRerenderList]);

  const handleChangeRowsPerPage = event => {
    setItemsPerPage(event.target.value);
  };

  const handleChangePage = (_, page: number) => {
    setPageNumber(page + 1);
  };

  if (!isLoaded) {
    return <TableNoItems hasWrapper asLoading />;
  }

  if (!data.length) {
    return <TableNoItems hasWrapper />;
  }

  const centeredCellStyles = {
    style: { whiteSpace: "pre-wrap" },
    padding: "checkbox",
    align: "center"
  } as const;

  return (
    <>
      <Paper style={{ overflowX: "auto", width: "100%" }} className="mb-4">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              {!isInactiveFilter && (
                <TableCell {...centeredCellStyles} width={80}>
                  Edit
                </TableCell>
              )}
              <TableCell {...centeredCellStyles} width={100}>
                {getArchiveCellTitle(searchFormFilters?.show)}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map(item => {
              const editCell =
                item.deletedAt && searchFormFilters?.show === ALL ? (
                  <TableCell />
                ) : (
                  <TableCellEdit
                    status={!item.deletedAt ? ACTIVE : ARCHIVED}
                    item={item}
                    baseUrl={baseUrl}
                    padding="checkbox"
                    align="center"
                  />
                );
              return (
                <TableRow key={item.id}>
                  <TableCell>{item.name}</TableCell>
                  {editCell}
                  <TableCellArchive
                    onArchive={() =>
                      archiveExpensesType(item, searchFormFilters?.show)
                    }
                    onUnarchive={() =>
                      unArchiveExpensesType(item, searchFormFilters?.show)
                    }
                    item={item}
                    padding="checkbox"
                    align="center"
                  />
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        <TablePagination
          component="div"
          count={totalItems}
          rowsPerPage={itemsPerPage}
          page={pageNumber - 1}
          backIconButtonProps={{ "aria-label": "Previous Page" }}
          nextIconButtonProps={{ "aria-label": "Next Page" }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
};

function mapStateToProps(state) {
  return {
    data: selector.getItems(state),
    isLoaded: selector.getIsLoaded(state),
    itemsPerPage: selector.getItemsPerPage(state),
    pageNumber: selector.getPageNumber(state),
    totalItems: selector.getTotalItems(state),
    searchFormFilters: searchFormSelector(state, "name", "show")
  };
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      loadItems: getTimesheetExpensesTypesAction,
      setItemsPerPage: TimesheetExpensesTypesLoader.setItemsPerPage,
      setPageNumber: TimesheetExpensesTypesLoader.setPageNumber,
      archiveExpensesType: archiveTimesheetExpensesTypeAction,
      unArchiveExpensesType: unArchiveTimesheetExpensesTypeAction
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpensesTypesList);
