import * as React from 'react';
import {connect} from 'react-redux';
import {withStyles, Modal, Typography, Grid, Button, WithStyles} from '@material-ui/core';
import {deleteTestPopupClose, deleteTestPopupSubmit} from '../../../../actions';
import {getDeleteTestId, getIsOpenModalDeleteTest} from "../../../../selectors/modal";
import {defaultStyleWidth} from '../../../../styles/material/popup-style';

interface IStateToProps {
  isOpenModal: any;
  testId: number;
}

interface IDispatchToProps {
  deleteTestPopupClose: () => any;
  deleteTestPopupSubmit: (id) => void;
}

class TestDeleteModal extends React.Component<IStateToProps & IDispatchToProps & WithStyles<any>> {
  render() {
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.props.isOpenModal}
        onClose={this.handleClose}
      >
        <div className={this.props.classes.paper}>
          <Typography variant="title" component="h3" id="modal-title">
            Test deletion
          </Typography>
          <hr/>
          <Typography component="p" id="modal-title">
            Are you sure?
          </Typography>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <div className="d-flex justify-content-between mt-4">
                <Button
                  variant="contained"
                  color="default"
                  className="mr-5"
                  onClick={this.handleClose}>
                  No
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleChange}>
                  Yes
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </Modal>
    );
  }

  private readonly handleClose = () => {
    this.props.deleteTestPopupClose();
  };

  private readonly handleChange = () => {
    this.props.deleteTestPopupSubmit(this.props.testId);
  };
}

function mapStateToProps(state) {
  return {
    isOpenModal: getIsOpenModalDeleteTest(state),
    testId: getDeleteTestId(state),
  };
}

const connectedComponent = connect<IStateToProps, IDispatchToProps>(mapStateToProps, {
  deleteTestPopupClose,
  deleteTestPopupSubmit,
})(TestDeleteModal);

export default withStyles(defaultStyleWidth(320))(connectedComponent);
