import React from "react";
import { Link } from "react-router-dom";
import { formatDateWithTime } from "helpers/date-formatter";
import { ITest } from "models/test.interface";

type TestNameProps = {
  test: ITest;
}

export function TestName({test}: TestNameProps) {
  return <>
    <div>
      <Link to={`/client/reports/${test.id}/details/`}>
        {test.hash + " - " + test.name}
      </Link>
    </div>
    {test.dateFrom && formatDateWithTime(test.dateFrom)}
  </>
}
