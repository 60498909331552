import * as React from "react";
import Icon from "@material-ui/core/Icon/Icon";
import Button from "@material-ui/core/Button/Button";
import {WrappedFieldArrayProps} from "redux-form/lib/FieldArray";
import {ISpecialism} from "../../../../models/specialism.interface";
import {IAnyProps} from "../../../../interfaces/any-props.interface";

export default class SpecialismAddButton extends React.Component<WrappedFieldArrayProps<ISpecialism | IAnyProps>> {
  newItem = () => this.props.fields.push({name: '', description: '', subTypes: []});

  render() {
    return (
      <Button
        className="action-button"
        variant="fab"
        type="button"
        color="secondary"
        onClick={this.newItem}>
        <Icon>add</Icon>
      </Button>
    )
  }
}
