import React from "react";
import { Button, Grid, Tooltip, Icon } from "@material-ui/core";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { reduxForm, Field, FieldArray } from "redux-form";
import ReduxTextField from "../../../../components/forms/ReduxTextField";
import {
  getFinanceItem,
  getFinances,
  putAdditionalServicesCompaniesAction,
  putFinances,
  putManagedServicesCompaniesAction
} from "../../../../actions/finances";
import Paper from "@material-ui/core/Paper/Paper";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import {
  composeValidators,
  fieldIsNumber,
  fieldsNotEmpty
} from "../../../../helpers/validations/validation";
import { ManagedServicesUpdateList } from "./ManagedServicesUpdateList";
import ManagedServicesAddButton from "./ManagedServicesAddButton";
import {
  AdditionalCompaniesType,
  ServiceManagedType,
  ObjectType
} from "../../../../types";

const FORM_NAME = "FinanceUpdate";

type CompanyForManagedServicesType = {
  id: number;
  name: string;
};

type ServiceManagedValidationType = {
  company?: string;
  daysPercent?: string;
  price?: string;
  description?: string;
};
type AdditionalServicesErrorType = {
  company?: string;
};
type ValidationErrorsType = {
  servicesManaged?: ServiceManagedValidationType[];
  additionalServices?: AdditionalServicesErrorType[];
};

type FinanceUpdateProps = {
  loadItems: () => void;
  putItems: (obj: ObjectType) => void;
  putCompanies: (companies: ServiceManagedType[]) => void;
  putAdditionalCompanies: ({ companyIds }: AdditionalCompaniesType) => void;
  handleSubmit: any;
  finances: {
    companiesForManagedServices: CompanyForManagedServicesType[];
  };
};

class FinanceUpdate extends React.Component<FinanceUpdateProps> {
  componentDidMount() {
    this.props.loadItems();
  }

  renderManagedServicesUpdateList = ({ ...props }) => {
    return (
      <ManagedServicesUpdateList
        {...props}
        companies={this.props.finances?.companiesForManagedServices || []}
      />
    );
  };

  render() {
    return (
      <form
        noValidate
        autoComplete="off"
        onSubmit={this.props.handleSubmit(
          async ({
            servicesManaged,
            managedServices,
            additionalServices,
            companiesForManagedServices,
            ...values
          }) => {
            const obj = {};

            Object.keys(values).forEach(key => {
              obj[key.replace("____", ".")] = values[key];
            });

            this.props.putItems(obj);

            servicesManaged.forEach(service => {
              delete service.name;
              delete service.subTypes;
            });
            this.props.putCompanies(servicesManaged);

            const normalizeAdditionalCompanies = additionalServices.map(
              service => service.company
            );

            this.props.putAdditionalCompanies({
              companyIds: normalizeAdditionalCompanies
            });
          }
        )}
      >
        <h6>Admin Fee</h6>
        <Paper className="p-4 mb-4">
          <Grid container>
            <Grid item xs={12} md={4}>
              <Field
                name="client_margin"
                label="Client Margin"
                component={ReduxTextField}
                margin="normal"
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  )
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={8}>
            <Grid item xs={4}>
              <Field
                name="threshold_GBP"
                label="Threshold GBP"
                component={ReduxTextField}
                margin="normal"
                type="number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">&pound;</InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                name="threshold_USD"
                label="Threshold USD"
                component={ReduxTextField}
                margin="normal"
                type="number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item container xs={4} className="finances-tooltip">
              <Grid item xs={11}>
                <Field
                  name="threshold_EUR"
                  label="Threshold EUR"
                  component={ReduxTextField}
                  margin="normal"
                  type="number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">&euro;</InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={1}>
                <Tooltip
                  placement={"bottom-end"}
                  disableFocusListener
                  disableTouchListener
                  title="Tester's Day Rate."
                >
                  <Icon>info</Icon>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            xs={12}
            md={4}
            className="justify-content-between d-inline-flex"
          >
            <Grid item xs={11}>
              <Field
                name="tester_margin"
                label="Tester Margin"
                component={ReduxTextField}
                margin="normal"
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={1} className="finances-tooltip">
              <Tooltip
                placement={"bottom-end"}
                disableFocusListener
                disableTouchListener
                title="Above the threshold Tester will pay the Margin."
              >
                <Icon>info</Icon>
              </Tooltip>
            </Grid>
          </Grid>
        </Paper>

        <h6>
          Admin Fee Cancelled (%of the day rate per day from Booking canceled)
        </h6>
        <Paper className="p-4 mb-4">
          <div className="row">
            <div className="col-md-2">
              <Field
                name="admin_fee_client"
                label="Client"
                component={ReduxTextField}
                margin="normal"
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  )
                }}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-2">
              <Field
                name="admin_fee_tester"
                label="Tester"
                component={ReduxTextField}
                margin="normal"
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  )
                }}
              />
            </div>
          </div>
        </Paper>
        <div>
          <h6 className="finances-page-tooltip">
            AVORD managed services
            <Tooltip
              placement={"bottom-end"}
              disableFocusListener
              disableTouchListener
              title="Clients with additional AVORD managed services in SoW document and invoice"
            >
              <Icon className="mb-1">info</Icon>
            </Tooltip>
          </h6>
        </div>
        <Paper className="p-4 mb-4">
          <FieldArray
            name="servicesManaged"
            component={this.renderManagedServicesUpdateList}
            rerenderOnEveryChange
          />
          <FieldArray
            name="servicesManaged"
            component={ManagedServicesAddButton}
          />
        </Paper>
        <div>
          <h6 className="finances-page-tooltip">
            Additional Services
            <Tooltip
              placement={"bottom-end"}
              disableFocusListener
              disableTouchListener
              title="By adding a customer to this section, the AVORD validation required for all tester proposals"
            >
              <Icon className="mb-1">info</Icon>
            </Tooltip>
          </h6>
        </div>
        <Paper className="p-4 mb-4">
          <FieldArray
            name="additionalServices"
            component={this.renderManagedServicesUpdateList}
            rerenderOnEveryChange
          />
          <FieldArray
            name="additionalServices"
            component={ManagedServicesAddButton}
          />
        </Paper>
        <div className="d-flex justify-content-between mt-4 pb-4">
          <Button
            variant="contained"
            color="default"
            component={Link}
            to={"/admin/settings/finance"}
          >
            Cancel
          </Button>
          <Button type="submit" variant="contained" color="primary">
            Save
          </Button>
        </div>
      </form>
    );
  }
}

const mapStateToProps = state => {
  if (JSON.stringify(state.finances) !== "{}") {
    const obj = {};

    Object.keys(state.finances).forEach(key => {
      obj[key.replace(".", "____")] = state.finances[key];
    });

    return {
      finances: obj,
      initialValues: obj
    };
  }

  return {
    finances: state.finances
  };
};

const componentWithForm = reduxForm({
  form: FORM_NAME,
  validate: composeValidators(
    fieldsNotEmpty([
      "client_margin",
      "threshold_GBP",
      "threshold_USD",
      "threshold_EUR",
      "tester_margin",
      "admin_fee_client",
      "admin_fee_tester"
    ]),
    fieldIsNumber([
      "client_margin",
      "threshold_GBP",
      "threshold_USD",
      "threshold_EUR",
      "tester_margin",
      "admin_fee_client",
      "admin_fee_tester"
    ]),
    values => {
      const { servicesManaged, additionalServices } = values;
      const errors: ValidationErrorsType = {};
      if (servicesManaged) {
        const serviceErrorsArray: ServiceManagedValidationType[] = [];
        servicesManaged.forEach((service, index) => {
          let serviceErrors: ServiceManagedValidationType = {};
          if (!service || !service.daysPercent) {
            serviceErrors = { ...serviceErrors, daysPercent: "Required" };
          } else if (service.daysPercent > 100 || service.daysPercent < 0) {
            serviceErrors = {
              ...serviceErrors,
              daysPercent: "Should be between 0 and 100"
            };
          }
          if (!service || !service.price) {
            serviceErrors.price = "Required";
            serviceErrors = { ...serviceErrors, price: "Required" };
          }
          if (!service || !service.description) {
            serviceErrors = { ...serviceErrors, description: "Required" };
          }
          if (!service || !service.company) {
            serviceErrors.company = "Required";
            serviceErrors = { ...serviceErrors, company: "Required" };
          } else if (
            servicesManaged.filter(serv => serv.company === service.company)
              .length > 1
          ) {
            serviceErrors = { ...serviceErrors, company: "Should be unique" };
          }
          serviceErrorsArray[index] = serviceErrors;
        });
        if (serviceErrorsArray.length) {
          errors.servicesManaged = serviceErrorsArray;
        }
      }
      if (additionalServices) {
        const serviceErrorsArray: AdditionalServicesErrorType[] = [];
        additionalServices.forEach((service, index) => {
          const serviceErrors: AdditionalServicesErrorType = {};
          if (!service || !service.company) {
            serviceErrors.company = "Required";
          } else if (
            additionalServices.filter(
              field => field.company === service.company
            ).length > 1
          ) {
            serviceErrors.company = "Should be unique";
          }
          serviceErrorsArray[index] = serviceErrors;
        });
        if (serviceErrorsArray.length) {
          errors.additionalServices = serviceErrorsArray;
        }
      }

      return errors;
    }
  )
})(FinanceUpdate);

export default connect(
  mapStateToProps,

  {
    loadItems: getFinances,
    putItems: putFinances,
    loadItem: getFinanceItem,
    putCompanies: putManagedServicesCompaniesAction,
    putAdditionalCompanies: putAdditionalServicesCompaniesAction
  }
)(componentWithForm);
