import * as React from "react";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Icon from "@material-ui/core/Icon/Icon";
import TableCell from "@material-ui/core/TableCell";
import AreYouSureModal from "./AreYouSureModal";
import { TableCellProps } from "@material-ui/core/TableCell/TableCell";

interface TableCellArchiveProps extends TableCellProps {
  onArchive: () => any;
  onUnarchive: () => any;
  item: { [key: string]: any };
}
class TableCellArchive extends React.Component<TableCellArchiveProps, any> {
  state = {
    isConfirmModalOpen: false
  };

  hideConfirmModal = () => {
    this.setState({ isConfirmModalOpen: false });
  };

  onConfirm = () => {
    if (this.props.item.deletedAt) {
      this.props.onUnarchive();
    } else {
      this.props.onArchive();
    }
  };
  showConfirmModal = e => {
    e.preventDefault();
    this.setState({ isConfirmModalOpen: true });
  };

  render() {
    const { onArchive, onUnarchive, item, ...rest } = this.props;
    return (
      <>
        <AreYouSureModal
          open={this.state.isConfirmModalOpen}
          onClose={this.hideConfirmModal}
          onConfirm={this.onConfirm}
          id={item.id}
        />
        <TableCell {...rest}>
          <IconButton onClick={this.showConfirmModal}>
            <Icon style={{ color: "#778596" }}>
              {item.deletedAt ? "unarchive" : "archive"}
            </Icon>
          </IconButton>
        </TableCell>
      </>
    );
  }
}

export default TableCellArchive;
