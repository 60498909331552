import * as React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router-dom";
import { IAnyProps } from "../../../interfaces/any-props.interface";
import WorkHistory from "./work-history/WorkHistory";
import BankDetails from "./bank-details/BankDetails";
import Certification from "./ceritificaion/Certification";
import Insurance from "./insurance/Insurance";
import DBSCertificates from './dbs-certificates/DBSCertificates';
import Profile from './profile/Profile';
import DBSCertificateUpdate from './dbs-certificates/DBSCertificateUpdate'
import {getTesterProfileRequest} from "../../../api/requests/admin-tester-profile";

interface IState {
  testerProfile: {
    dBSCheckMinStatus?: number;
  }
}

type IProps = {
  getUser: (...any) => void,
  user: IAnyProps,
  backRoute: string
} & RouteComponentProps<any>;

class TesterDetails extends React.Component<IProps> {
  state: IState;

  baseUrl = this.props.match.url;

  updateWorkHistoryComponent = () => <DBSCertificateUpdate backRoute={this.baseUrl}/>;

  componentWillMount() {
    this.fetchData();
  }

  fetchData() {
    getTesterProfileRequest(this.props.match.params.id)
      .then(data => {
        this.setState({testerProfile: data});
      });
  }
  
  render() {
    return (
      <div className="mt-4">
        {
          this.state && this.state.testerProfile ?
            <Profile testerProfile={this.state.testerProfile}/>
          : ''
        }
        <div className="separator" />
        <BankDetails />
        <div className="separator" />
        <Certification />
        {
          (this.state && this.state.testerProfile.dBSCheckMinStatus) ?
            (
              <>
                <div className="separator" />
                <Insurance />
                <div className="separator" />
                <DBSCertificates/>
              </>
            )
          : ''
        }
        <div className="separator" />
        <WorkHistory/>
        <Switch>
          <Route path={`${this.baseUrl}/update-dbs-certificate/:id`} render={this.updateWorkHistoryComponent}/>
        </Switch>
      </div>
    )
  }
}

const routered = withRouter(TesterDetails) as React.ComponentClass<{ backRoute: string }>;

export default routered;
