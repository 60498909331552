import {Dispatch} from "redux";
import {setRequestInProcess} from "./request";
import {getFilteredItems, getItems, putItem} from "../api/requests/application-settings";
import {handleHttpErrors} from "../helpers/errors/http-error-handler";
import {createCrudAction, createRequestAction} from "../helpers/action-helper";
import * as Types from "../constants/action-type";
import {getRequestName} from "../helpers/action-request-helper";
import {openSnackbar} from "./snackbar";
import {push} from "react-router-redux";
import {reset} from "redux-form";

const crudType = Types.APPLICATION_SETTINGS;

export const applicationSettingsRequest = createRequestAction(crudType);

interface IApplicationSetting {
  id: number;
  key: string;
  state: boolean;
  message?: string;
}

export const getApplicationSettings = () => async (dispatch: Dispatch) => {
  dispatch(setRequestInProcess(true, 'getApplicationSettings'));
  let data: any = [];

  try {
    data = await getItems();
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(createCrudAction(Types.APPLICATION_SETTINGS).addItems(data.items));

  dispatch(setRequestInProcess(false, 'getApplicationSettings'));
};

export const getApplicationSettingByKey = (settingKey: string) => async (dispatch: Dispatch) => {
  dispatch(setRequestInProcess(true, 'getApplicationSettings'));
  let data: any = [];

  try {
    data = await getFilteredItems({
      settingKey
    });
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(createCrudAction(Types.APPLICATION_SETTINGS).addItems(data.items));

  dispatch(setRequestInProcess(false, 'getApplicationSettings'));
};

export const updateApplicationSetting = (settingId: number, data: any) => async  (dispatch: Dispatch) => {
  let response;
  try {
    response = await putItem(settingId, data);
    dispatch(createCrudAction(Types.APPLICATION_SETTINGS).replaceItem(response));
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }
};

export const putApplicationSetting = (model, formName, backRoute) => async dispatch => {
  dispatch(setRequestInProcess(true, getRequestName(crudType, 'putApplicationSetting')));

  let data;

  try {

    data = await putItem(model.id, model);

    dispatch(openSnackbar('Updated', 4000));
    dispatch(createCrudAction(crudType).replaceItem(data));
    dispatch(reset(formName));
    dispatch(push(backRoute));
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(setRequestInProcess(false, getRequestName(crudType, 'putApplicationSetting')));
};

