import React from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { FieldGeneric } from "components/forms/FieldGeneric";
import Paper from "@material-ui/core/Paper/Paper";
import { TIME_SHEETS_SEARCH_FORM } from "constants/timesheet-expenses";
import ReduxYearMonthPicker from "components/forms/YearMonthPicker/ReduxYearMonthPicker";

const SearchByMontForm = () => {
  return (
    <form className="search-by-month-form">
      <Paper className="table-responsive styled-table styled-block">
        <FieldGeneric
          name="yearMonth"
          component={ReduxYearMonthPicker}
          margin="dense"
        />
      </Paper>
    </form>
  );
};

const connectForm = reduxForm({
  form: TIME_SHEETS_SEARCH_FORM
})(SearchByMontForm);

export default connect()(connectForm);
