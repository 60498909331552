import * as Types from '../constants/action-type';
import {createCrudAction} from '../helpers/action-helper';
import {setRequestInProcess} from "./request";
import {handleHttpErrors} from "../helpers/errors/http-error-handler";
import {reportAcceptedModalClose} from "./modals";
import {postReview} from "../api/requests/tester-reviews";
import {reset} from "redux-form";
import { REPORT_ACCEPTED_FORM_NAME } from "../constants/forms";
import {openSnackbar} from "./snackbar";
import {setFeedback} from "../api/requests/test";

export const acceptReportModalSubmit = (idReport, idTest, rang, comment) => async dispatch => {
  dispatch(setRequestInProcess(true, 'acceptReportModalSubmit'));

  if (!comment) {
    dispatch(openSnackbar('You should provide a comment for feedback', 4000));
    dispatch(setRequestInProcess(false, 'acceptReportModalSubmit'));
    return;
  }

  let data;

  try {
    data = await postReview(idReport, rang, comment.trim());

    dispatch(createCrudAction(Types.REVIEW).addItem(data));
    dispatch(reportAcceptedModalClose());
    dispatch(reset(REPORT_ACCEPTED_FORM_NAME));
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(setRequestInProcess(false, 'acceptReportModalSubmit'));

  dispatch(setRequestInProcess(true, 'setFeedbackSendAction'));

  try {
    data = await setFeedback(idTest);
    dispatch(createCrudAction(Types.TEST).addItem(data));
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(setRequestInProcess(false, 'setFeedbackSendAction'));
};