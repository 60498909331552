import { IConverterFromSwagger, IConverterToSwagger } from "./converter.interface";
import { IInsurance } from "../../models/insurance.interface";

export const converterFromSwagger: IConverterFromSwagger<IInsurance, any> = model => {
  return {
    ...model,
  };
};

export const converterToSwagger: IConverterToSwagger<IInsurance, any> = model => {
  return {
    ...model,
    evidence: model.evidence,
    validFrom: model.validFrom,
    validTo: model.validTo,
  };
};
