import React,  { ComponentType } from "react";
import Modal from "@material-ui/core/Modal/Modal";
import { withStyles } from "@material-ui/core/styles/index";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";

export interface IBackRoute {
  backRoute: string;
}

interface IClassesProps {
  classes?: { paper?: string };
}

type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export default function (ChildComponent: ComponentType, size: Size = 'md') {
  class RouterModal extends React.Component<IBackRoute & IClassesProps & RouteComponentProps> {

    onCloseHandler = () => {
      this.props.history.push(this.props.backRoute);
    };

    render() {
      const {...props} = this.props;

      return (
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={true}
          onClose={this.onCloseHandler}
          className="d-flex overflow-auto"
        >
          <div className={`styled-modal ${size}`}>
            <ChildComponent {...props} />
          </div>
        </Modal>
      );
    }
  }

  return withRouter(withStyles({})(RouterModal));
}
