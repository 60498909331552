import {BASE_URL} from "../../constants/urls";
import {postImage} from "../../api/requests/image";

class MyUploadAdapter {
  loader: any;

  constructor(loader) {
    this.loader = loader;
  }

  upload() {
    return this.loader.file
      .then(file => new Promise(resolve => {
        if (file instanceof File) {
          resolve(postImage(file));
        }
      }))
      .then(createdFile => {
          let url;

          if (createdFile) {
            url = BASE_URL + createdFile.imagePath;
          }

          return new Promise(resolve => resolve({default: url}));
        }
      );
  }
}

export default MyUploadAdapter;