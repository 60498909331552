import { Link } from "react-router-dom";
import * as React from "react";
import { IUsers } from "models/users.interface";

type TesterNameColumnProps = { user: IUsers };

export function TesterNameColumn({user}: TesterNameColumnProps) {
  const fullName = `${user.firstName} ${user.lastName}`;

  if (user.deletedAt) {
    return <span>{fullName}</span>
  }

  return <Link
    to={`/admin/testers/${user.testerProfileId}`}
  >
    {fullName}
  </Link>;
}
