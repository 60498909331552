import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getConsultantData } from "api/requests/consultants";
import { FormValuesType } from "types/consultant";
import { bindActionCreators, Dispatch } from "redux";
import { updateConsultantAction } from "actions/consultants";
import { connect } from "react-redux";
import { ConnectedProps } from "types";
import ConsultantForm from "../components/ConsultantForm";
import { AdminUrls } from "constants/admin-urls";

type Props = PropsFromRedux & RouteComponentProps<{ id?: string }>;

function EditConsultantPage({ match, history, updateConsultant }: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [consultant, setConsultant] = useState<FormValuesType>();

  const consultantId = Number(match.params.id);

  const goBackHandler = () => {
    history.push(AdminUrls.CONSULTANTS);
  };

  useEffect(() => {
    getConsultantData(consultantId)
      .then(item => {
        setConsultant(item);
      })
      .catch(error => {
        console.warn(error);
      })
      .finally(() => {
        setIsLoading(true);
      });
  }, [consultantId]);

  const onSubmitHandler = (values: FormValuesType) => {
    updateConsultant(values, goBackHandler);
  };

  return (
    <ConsultantForm
      isLoading={!isLoading}
      goBack={goBackHandler}
      onSubmit={onSubmitHandler}
      initialValues={consultant}
    />
  );
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      updateConsultant: updateConsultantAction
    },
    dispatch
  );

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default withRouter(connector(EditConsultantPage));
