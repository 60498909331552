import {fetchRequestBody} from '../../helpers/request-helper';
import {getApiJSON} from '../swagger/api-factory';


export const getTesterProfileWorkHistoryRequest = (id: string | number) => {
  return getApiJSON()
    .then(api => api.api_tester_profiles_work_histories_get_subresource({id}))
    .then(fetchRequestBody);
};

export const getTesterProfileCertificatesRequest = (id: string | number) => {
  return getApiJSON()
    .then(api => api.api_tester_profiles_certificates_get_subresource({id}))
    .then(fetchRequestBody);
};

export const getTesterProfileDBSCertificatesRequest = (id: string | number) => {
  return getApiJSON()
    .then(api => api.api_tester_profiles_dbs_checks_get_subresource({id}))
    .then(fetchRequestBody)
};

export const getTesterProfileInsurancesRequest = (id: string | number) => {
  return getApiJSON()
    .then(api => api.api_tester_profiles_insurances_get_subresource({id}))
    .then(fetchRequestBody);
};

export const getTesterProfileBankDetailsRequest = (id: string | number) => {
  return getApiJSON()
    .then(api => api.getApiTesterProfileByIdBankDetails({id}))
    .then(fetchRequestBody);
};

export const getTesterProfileRequest = (id: string | number) => {
  return getApiJSON()
    .then(api => api.getTesterProfileItem({id}))
    .then(fetchRequestBody);
};