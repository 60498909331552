import * as Types from '../constants/action-type';
import {
  createCrudAction, createFilterAction,
  createLoaderAction,
  createRequestAction
} from '../helpers/action-helper';
import {getRequestName} from "../helpers/action-request-helper";
import {putItem} from "../api/requests/email-templates";
import {reset} from "redux-form";
import {push} from "react-router-redux";
import {handleHttpErrors} from "../helpers/errors/http-error-handler";
import {setRequestInProcess} from "./request";
import {openSnackbar} from "./snackbar";

const crudType = Types.EMAIL_TEMPLATES;

export const emailTemplatesCrud = createCrudAction(Types.EMAIL_TEMPLATES);
export const emailTemplatesFilter = createFilterAction(Types.EMAIL_TEMPLATES);
export const emailTemplatesLoader = createLoaderAction(Types.EMAIL_TEMPLATES);
export const emailTemplatesRequest = createRequestAction(Types.EMAIL_TEMPLATES);

export const submitFilter = (type, text) => async dispatch => {
  dispatch(emailTemplatesFilter.setTextFilter(text));
  dispatch(emailTemplatesFilter.setActiveFilter(type));

  await emailTemplatesRequest.getItems(1, 10, type, text);
};

export const putEmailTemplateItem = (model, formName, backRoute) => async dispatch => {
  dispatch(setRequestInProcess(true, getRequestName(crudType, 'putItem')));
  dispatch(openSnackbar('Updated', 4000));

  let placeholderMissing = false;
  model.placeholders.map(placeholder => {
    if (!model.body.includes(`[#${placeholder.name}#]`)) {
      placeholderMissing = true;
    }
  });

  if (placeholderMissing) {
    dispatch(openSnackbar('Email body should include all mandatory placeholders', 4000));
    dispatch(setRequestInProcess(false, getRequestName(crudType, 'putItem')));
    return;
  }


  let data;

  try {
    data = await putItem(model);

    dispatch(emailTemplatesCrud.replaceItem(data));
    dispatch(reset(formName));
    dispatch(push(backRoute))
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(setRequestInProcess(false, getRequestName(crudType, 'putItem')));
};