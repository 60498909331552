import getCrudSelectors from '../helpers/selector-helper';
import {createSelector} from "reselect";
import {result} from 'lodash';

const getFormSearchFields = state => state.formSearchFields;

export const getFormSearchField = (key: string) => createSelector(
  getFormSearchFields,
  state => result(state, key, '')
);
