import * as React from "react";
import { InjectedFormProps, reduxForm } from "redux-form";
import {
  composeValidators,
  fieldsNotEmpty,
} from "helpers/validations/validation";
import { connect } from "react-redux";
import selector from "selectors/company-details";
import sectorSelector from "selectors/sector";
import { ICompanyDetails } from "models/company-details.interface";
import { ISector } from "models/sector.interface";
import {
  getCompanyDetails,
  putCompanyDetails,
} from "actions/company-details";
import { IBackRoute } from "components/routerModal";
import { dataFetcher } from "components/dataFetcher";
import { sectorRequest } from "actions";
import { WithStyles } from "@material-ui/core";
import { MAX_PAGINATION_RESULTS } from "constants/loader";
import { ACTIVE } from "constants/filter";
import ReduxTextField from "components/forms/ReduxTextField";
import { FieldGeneric } from "components/forms/FieldGeneric";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem";
import ReduxSelectField from "components/forms/ReduxSelectField";
import { login } from "api/requests/auth";

const FORM_NAME = "CompanyDetailsUpdate";

type ICompanyFormProps = ICompanyDetails & ISector[];

interface IOwnProps {
  companyDetails: ICompanyDetails;
  sectors: ISector[];
}

interface IComponentProps {
  showHeader?: boolean;
  companyId?: number;
  children?: React.ReactElement<React.Component>;
}

interface IDispatchProps {
  putCompanyDetails: (
    companyDetails: ICompanyDetails,
    backRoute: string,
    formName: string
  ) => void;
}

type IProps = IOwnProps &
  WithStyles &
  IBackRoute &
  InjectedFormProps<ICompanyFormProps> &
  IDispatchProps &
  IComponentProps;

const CompanyDetailsUpdate = (props: IProps) => {
  const {
    companyId,
    backRoute,
    showHeader,
    children,
    sectors,
    handleSubmit,
    putCompanyDetails,
  } = props;

  const renderOption = (value: ISector) => (
    <MenuItem key={value.id} value={value.id}>
      {value.name}
    </MenuItem>
  );

  return (
    <form
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit((values) => {
        if (companyId) {
          values.companyId = props.companyId as number;
        }
        putCompanyDetails(values, backRoute, FORM_NAME);
      })}
    >
      {showHeader ? (
        children
      ) : (
        <div className="text-right">
          <Link to={backRoute} className="btn-cancel">
            Cancel
          </Link>
          <button type="submit" className="btn-save">
            Save
          </button>
        </div>
      )}
      <div className="styled-block mt-3">
        <div>
          <Grid container>
            <Grid item xs={12}>
              {showHeader && (
                <h4 className="test-details-header mt-4">Company</h4>
              )}
              <FieldGeneric
                name="companyName"
                label="Company name *"
                component={ReduxTextField}
              />
              <FieldGeneric
                name="sector"
                label="Sector *"
                component={ReduxSelectField}
                margin="normal"
              >
                {sectors.map(renderOption)}
              </FieldGeneric>
              <h4 className="test-details-header mt-4">Invoice Contact</h4>
              <FieldGeneric
                name="contactFirstName"
                label="First name *"
                component={ReduxTextField}
              />
              <FieldGeneric
                name="contactLastName"
                label="Last name *"
                component={ReduxTextField}
              />
              <FieldGeneric
                name="contactEmail"
                label="Email *"
                component={ReduxTextField}
              />
              <FieldGeneric
                name="contactPhone"
                label="Telephone *"
                component={ReduxTextField}
              />
              <h4 className="test-details-header mt-4">Invoice Address</h4>
              <FieldGeneric
                name="addressLine1"
                label="Address line 1 *"
                component={ReduxTextField}
              />
              <FieldGeneric
                name="addressLine2"
                label="Address line 2"
                component={ReduxTextField}
              />
              <FieldGeneric
                name="addressLine3"
                label="Address line 3"
                component={ReduxTextField}
              />
              <FieldGeneric
                name="town"
                label="Town/City *"
                component={ReduxTextField}
              />
              <FieldGeneric
                name="state"
                label="State/Province *"
                component={ReduxTextField}
              />
              <FieldGeneric
                name="postCode"
                label="Post/zip code *"
                component={ReduxTextField}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </form>
  );
};

const componentWithForm = reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
  validate: composeValidators(
    fieldsNotEmpty([
      "companyName",
      "sector",
      "contactFirstName",
      "contactLastName",
      "contactEmail",
      "contactPhone",
      "addressLine1",
      "town",
      "state",
      "postCode",
    ])
  ),
})(CompanyDetailsUpdate);

const connected = connect(
  (state) => {
    // TODO: refactor it in future
    const items = selector.getItems(state);
    const companyDetails = items[items.length - 1];

    return {
      initialValues: {
        ...companyDetails,
      },
    };
  },
  {
    putCompanyDetails,
  }
)(componentWithForm);

export default dataFetcher<IProps & IBackRoute>(connected, [
  {
    key: "companyDetails",
    action: (props) => getCompanyDetails(props.companyId),
    selector: (state, props) => selector.getItems(state)[0],
    alwaysReceiveFreshData: true
  },
  {
    key: "sectors",
    action: () => sectorRequest.getItems(1, MAX_PAGINATION_RESULTS, ACTIVE),
    selector: (state) => sectorSelector.getItems(state),
  },
]) as unknown as React.ComponentClass<IBackRoute & IComponentProps>;
