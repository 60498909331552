import * as React from "react";
import { Link, Route, Switch } from "react-router-dom";
import CompanyDetailsPage from "./CompanyDetailsPage";
import CompanyDetailsUpdate from "./CompanyDetailsUpdate";
import { IAnyProps } from "interfaces/any-props.interface";
import { withRouter } from "react-router-dom";
import { dataFetcher } from "components/dataFetcher";
import { getCompanyDetails } from "actions/company-details";
import selector from "selectors/company-details";

class CompanyDetails extends React.Component<IAnyProps, {}> {
  baseUrl = this.props.match.path;

  companyDetails = () => {
    const {companyDetails} = this.props;

    return <CompanyDetailsPage companyDetails={companyDetails} />;
  }

  companyDetailsUpdate = () => {
    return <CompanyDetailsUpdate
      backRoute={this.baseUrl}
    />;
  }

  render() {
    return (
      <div>
        {this.props.location.pathname !== `${this.baseUrl}/edit` &&
          <div className="crud-sections-header">
            <Link to={`${this.baseUrl}/edit`} className="btn-edit">Edit</Link>
          </div>
        }
        <Switch>
          <Route exact path={this.baseUrl} render={this.companyDetails} />
          <Route
            path={this.baseUrl + "/edit"}
            render={this.companyDetailsUpdate}
          />
        </Switch>
      </div>
    );

  }
}

const fetched = dataFetcher(
  CompanyDetails,
  [{
    key: "companyDetails",
    selector: (state) => {
      return selector.getItems(state)[0];
    },
    action: (props) => {
      return getCompanyDetails()
    },
  }]
);

export default withRouter(fetched);
