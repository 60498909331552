import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Table from "@material-ui/core/Table/Table";
import TableBody from "@material-ui/core/TableBody/TableBody";
import { IAnyProps } from "../../../../interfaces/any-props.interface";
import EnhancedTableHead from "../../../../components/table/EnhancedTableHead";
import { ISpecialism } from "../../../../models/specialism.interface";
import { IReport } from "../../../../models/report.interface";
import CompleteReportRow from "./CompleteReportRow";
import { getTestReportWithDocuments } from "../../../../actions/reports";
import { dataFetcher } from "../../../../components/dataFetcher";
import { getByTestId } from "../../../../selectors/reports";
import { ITest } from "../../../../models/test.interface";
import { IReportDocument } from "../../../../models/report-documents.interface";
import documentSelector from "../../../../selectors/report-documents";
import ReportDocumentCreateModal from "./ReportDocumentCreateModal";
import ReportDetailsForm from "./ReportDetailsForm";
import {
  reportCanBeSubmitted,
  TestStatus
} from "../../../../constants/test-status";
import { reportDocumentCanBeSubmitted } from "../../../../constants/report-document-status";
import TooltipIcon from "@material-ui/core/Tooltip/Tooltip";
import { Zoom } from "@material-ui/core";
import Icon from "@material-ui/core/Icon/Icon";
import { reportDocumentCreateModalOpen } from "../../../../actions";
import { getIsOpenModalCreateReportDocument } from "../../../../selectors/modal";
import { TableNoItems } from "../../../../components/table/TableNoItems";

type IProps = RouteComponentProps<any> &
  IAnyProps & {
    testSpecialisms: ISpecialism[];
    reportDocuments: IReportDocument[];
    test: ITest;
    report: IReport;
    loadReport: (...any) => void;
    loadReportDocuments: (...any) => void;
  };

class CompleteReports extends React.Component<
  IProps,
  {
    isLoaded: boolean;
    canBeSubmitted: boolean;
  }
> {
  baseUrl = this.props.match.url;

  state = {
    isLoaded: false,
    canBeSubmitted: false
  };
  openCreateReportDocumentModal = () =>
    this.props.reportDocumentCreateModal(true);

  loadReportItem = props => {
    props.loadReport(props.match.params.test).then(data => {
      this.isReportCanBeSubmitted();
      this.setState({ isLoaded: true });
    });
  };

  componentWillReceiveProps(nextProps) {
    this.isReportCanBeSubmitted();
  }

  isReportCanBeSubmitted = () => {
    if (!this.props.report) {
      return;
    }

    if (!reportCanBeSubmitted(this.props.test.status)) {
      this.setState({
        canBeSubmitted: false
      });

      return;
    }

    let isAllReportDocumentsSubmitted = true;

    this.props.reportDocuments.map(item => {
      if (!reportDocumentCanBeSubmitted(item?.status)) {
        isAllReportDocumentsSubmitted = false;
      }
    });

    this.setState({
      canBeSubmitted: isAllReportDocumentsSubmitted
    });
  };

  componentWillMount() {
    this.loadReportItem(this.props);
  }

  render() {
    const { reportDocuments } = this.props;
    const showAddReportButton =
      reportDocuments.length < 5 &&
      this.props.test.status !== TestStatus.STATUS_COMPLETED &&
      this.props.test.status !== TestStatus.STATUS_REPORT_SUBMITTED;
    if (!this.state.isLoaded) {
      return <TableNoItems asLoading hasWrapper />;
    }

    return (
      <React.Fragment>
        <div className="block-header-wrapper">
          <h4 className="mt-4">
            Complete Reports
            <TooltipIcon
              placement={"top"}
              TransitionComponent={Zoom}
              disableFocusListener
              disableTouchListener
              title="Make sure you have completed each section before uploading  and pressing submit to the client. Note: when re-uploading documents there needs to be a document version update for client document control"
            >
              <Icon
                style={{
                  marginLeft: "5px",
                  marginBottom: "2px",
                  verticalAlign: "middle"
                }}
              >
                info
              </Icon>
            </TooltipIcon>
          </h4>
          {showAddReportButton && (
            <button
              className="btn-new"
              onClick={this.openCreateReportDocumentModal}
            >
              Add Report Document
            </button>
          )}
        </div>
        <div className="styled-block">
          <div className="table-responsive styled-table">
            {this.props.isOpenModal && <ReportDocumentCreateModal />}
            <Table padding="dense">
              <EnhancedTableHead
                columns={["Report Document Name", "Status", "Date", "", "", ""]}
              />
              <TableBody>
                {reportDocuments.map((item: IReportDocument, index) => {
                  return (
                    <CompleteReportRow
                      reportDocument={item}
                      report={this.props.report}
                      testStatus={this.props.test.status}
                      key={index}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </div>
        </div>
        {
          <ReportDetailsForm
            {...{
              test: this.props.test,
              testStatus: this.props.test.status,
              report: this.props.report,
              canBeSubmitted: this.state.canBeSubmitted
            }}
          />
        }
      </React.Fragment>
    );
  }
}

const fetched = dataFetcher(CompleteReports);

const routered = withRouter(fetched as any);

const connected = connect(
  (state: any, ownProps: IProps) => ({
    report: getByTestId(ownProps.test.id)(state),
    reportDocuments: documentSelector.getItems(state),
    isOpenModal: getIsOpenModalCreateReportDocument(state)
  }),

  {
    loadReport: getTestReportWithDocuments,
    reportDocumentCreateModal: reportDocumentCreateModalOpen
  }
)(routered);

export default connected as any;
