import {createSelector} from 'reselect';

const getRootState = state => state.information;

export const getSnackbar = createSelector(
  getRootState,
  state => state.snackbar
);

export const getPopup = createSelector(
  getRootState,
  state => state.popup
);
