import { setRequestInProcess } from "./request";
import {
  adminValidateRequest,
  getAdditionalServicesRequest,
} from "../api/requests/adminValidate";
import * as Types from "../constants/action-type";
import {
  IAdditionalServices,
  IEmptyAdditionalServices
} from "../containers/client-tester/test-details/modal/AvordValidationModal";

export const addAdditionalServices = (data: {
  additionalServices: IAdditionalServices[] | IEmptyAdditionalServices;
}) => ({
  type: Types.ADDITIONAL_SERVICE_GET_ITEM,
  payload: data,
});


export const adminValidateAction = (id: number, model: IAdditionalServices[] | IEmptyAdditionalServices) => async dispatch => {
  dispatch(setRequestInProcess(true, 'adminValidate'));
  try {
    await adminValidateRequest(model);
  } catch (e) {
    dispatch(setRequestInProcess(false, 'adminValidate'));
    return;
  }
  dispatch(setRequestInProcess(false, 'adminValidate'));
  dispatch(location.reload());
};

export const getAdditionalServicesList = (id: number) =>  async dispatch => {
  dispatch(setRequestInProcess(true, 'getAdditionalServicesList'));

  const data = await getAdditionalServicesRequest(id);

  const payload = {
    additionalServices: data.body,
  };
  

  dispatch(addAdditionalServices(payload));
  dispatch(setRequestInProcess(false, 'getAdditionalServicesList'));
};