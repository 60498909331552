import * as React from "react";
import {
  composeValidators,
  fieldIsEmail,
  fieldsNotEmpty,
} from "../../../../helpers/validations/validation";
import {formValueSelector, InjectedFormProps, reduxForm} from "redux-form";
import { connect } from "react-redux";
import { postSubuserAction } from "../../../../actions/users";
import UsersForm from "./UsersForm";
import { withStyles } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { defaultStyleWidth } from "../../../../styles/material/popup-style";
import routerModal from "../../../../components/routerModal";
import { WithStyles } from "@material-ui/core/styles/withStyles";
import { Group } from "../../../../constants/group";
import { result } from "lodash";
import { getUserGroup, getUserPermissions } from "../../../../selectors/auth";
import { IAnyProps } from "../../../../interfaces/any-props.interface";
import { getExtraPermissions } from "../../../../selectors/users";
import selector from "../../../../selectors/users";
import locationsSelector from "../../../../selectors/locations";
import {getAllProjects} from "../../../../actions/project";
import projectSelector from "../../../../selectors/project";
import {IProject} from "../../../../models/project.interface";
import {ILocation} from "../../../../models/location.interface";
import {getAllLocations} from "../../../../actions";

export const FORM_NAME = "UsersCreate";
const getFormValues = formValueSelector(FORM_NAME);

type IProps = InjectedFormProps<{}, {}> & {
  postData: (...any) => void;
  getAllProjects: (...any) => void;
  getAllLocations: (...any) => void;
  postSubuserData: (...any) => void;
  backRoute: string;
  group: Group;
  groupName: string;
  loadPermissions: any;
  extraPermissions: object;
  currentUserPermissions: string[];
  itemsPerPage: number;
  pageNumber: number;
  showStatus: string;
  filterText: string;
  projects: IProject[],
  locations: ILocation[],
  valuesData: {
    projectsIds: number[];
    locationsIds: number[];
  }
} & WithStyles<any>;

const mappingErrors: Array<[string, string]> = [
  ["user.email", "email"],
  ["user.phone", "phone"],
  ["user.firstName", "firstName"],
  ["user.lastName", "lastName"],
];

const handleServerError = (error: IAnyProps) => {
  return mappingErrors.reduce((acc, data) => {
    return Object.assign(
      acc,
      result(error, data[0], false) ? { [data[1]]: result(error, data[0]) } : {}
    );
  }, {});
};

class UsersCreate extends React.Component<IProps> {
  componentDidMount() {
    this.props.getAllProjects();
    this.props.getAllLocations();
  }

  render() {
    const {
      handleSubmit,
      postSubuserData,
      group,
      backRoute,
      initialValues,
      itemsPerPage,
      pageNumber,
      showStatus,
      filterText,
      valuesData,
      extraPermissions,
      currentUserPermissions,
      projects = [],
      locations = []
    } = this.props;

    return (
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit((values: any) => {
          postSubuserData(values, FORM_NAME, backRoute, handleServerError, pageNumber, itemsPerPage, showStatus, filterText);
        })}
      >
        <UsersForm
          backRoute={backRoute}
          group={group}
          initialValues={initialValues}
          change={this.props.change}
          valuesData={valuesData}
          extraPermissions={extraPermissions}
          currentUserPermissions={currentUserPermissions}
          projects={projects}
          locations={locations}
          isCreateUser
        />
      </form>
    );
  }
}

const formConnected = reduxForm({
  form: FORM_NAME,
  validate: composeValidators(
    fieldsNotEmpty(["firstName", "lastName", "email", "phone"]),
    fieldIsEmail(["email"])
  ),
})(UsersCreate);

const connected = connect(
  (state: any) => ({
    group: getUserGroup(state),
    initialValues: {
      userGroup: Group.PLANNER,
      projectsIds: [],
      locationsIds: []
    },
    extraPermissions: getExtraPermissions(state),
    currentUserPermissions: getUserPermissions(state),
    itemsPerPage: selector.getItemsPerPage(state),
    pageNumber: selector.getPageNumber(state),
    showStatus: selector.getFilterActivityStatus(state),
    filterText: selector.getFilterTextFields(state),
    projects: projectSelector.getItems(state),
    locations: locationsSelector.getItems(state),
    valuesData: getFormValues(state, "projectsIds", "locationsIds"),
  }),
  {
    postSubuserData: postSubuserAction,
    getAllProjects: getAllProjects,
    getAllLocations: getAllLocations,
  }
)(formConnected);

/** Todo: cut */
const withStyle = withStyles(defaultStyleWidth(400))(connected as any);

export default routerModal(withRouter(withStyle as any));
