import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { FieldGeneric } from "components/forms/FieldGeneric";
import ReduxSelectSearchField from "components/forms/ReduxSelectSearchField";
import { SelectOptionType } from "types";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import { Field } from "redux-form";
import ReduxTextField from "components/forms/ReduxTextField";
import { handleKeyPressOnNumberField, handleKeyPressOnSoWField } from "helpers/validations/numberFieldValidator";
import { getRatePercent } from "helpers/consultant-create-form";

const normalizeFloat = (value: string) => {
  if (!value) return '';
  const floatValue = parseFloat(value);
  if (isNaN(floatValue)) return '';
  const decimalPart = floatValue % 1;
  if (decimalPart === 0 || decimalPart === 0.5) {
    return floatValue;
  }
  return '';
};

type ProjectRepeaterRowProps = {
  onRemove: () => void;
  consultantRate: number;
  projectRate: number;
  allProjects: SelectOptionType[];
  name: string;
  project?: number;
  daysInSow?: number;
  submitted: { total: number };
  approved: { total: number };
  invoiced: { total: number; vat: number; amount: number };
};

export function ProjectRepeaterRow({
  onRemove,
  consultantRate,
  projectRate,
  allProjects,
  daysInSow = 0,
  submitted,
  approved,
  invoiced,
  name,
  project
}: ProjectRepeaterRowProps) {
  const projectName = allProjects.find(p => p.id === project)?.name || undefined;
  const projectPercent = getRatePercent(consultantRate, projectRate);

  return (
    <div className="project-repeater__row mb-2">
      <div className="project-repeater__row-cell repeater-remove-button-wrap">
        <button className="btn-remove" type="button" onClick={onRemove} />
      </div>
      <div className="project-repeater__row-cell project-repeater__project">
        <FieldGeneric
          name={`${name}.project`}
          label="Project"
          fullWidth
          component={ReduxSelectSearchField}
          title={projectName}
          items={allProjects}
          renderItem={(option: SelectOptionType) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          )}
        />
      </div>
      <div className="project-repeater__row-cell project-repeater__project-rate">
        <Field
          name={`${name}.projectRate`}
          component={ReduxTextField}
          margin="none"
          normalize={(value: string) => (value ? +value : "")}
          label="£ Client Day Rate *"
          onKeyPress={handleKeyPressOnNumberField}
          inputProps={{ min: 0 }}
          type="number"
        />
      </div>
      {projectPercent !== null && (
        <div className="project-repeater__row-cell project-repeater__project-percent">
          <div className="form-text-wrap project-repeater__percent">
            {`${projectPercent} %`}
          </div>
        </div>
      )}
      <div className="project-repeater__row-cell project-repeater__sow-days">
        <Tooltip title="Leave as blank, or 0 if not specified in SoW">
          <Field
            name={`${name}.daysInSow`}
            component={ReduxTextField}
            margin="none"
            normalize={normalizeFloat}
            label="Days in SoW"
            onKeyPress={handleKeyPressOnSoWField}
            inputProps={{ min: 0, step: 0.5 }}
            type="number"
          />
        </Tooltip>
      </div>
      <div className="project-repeater__row-cell project-repeater__days-stats">
      {[
        submitted.total > 0 ? `Submitted ${submitted.total} days` : '',
        approved.total > 0 ? `Approved ${approved.total} days` : '',
        invoiced.total > 0 ? `Invoiced ${invoiced.total} days ${
          invoiced.amount > 0 || invoiced.vat > 0 ? `(
            ${invoiced.amount > 0 ? `£${invoiced.amount}` : ''}
            ${invoiced.amount > 0 && invoiced.vat > 0 ? ' + ' : ''}
            ${invoiced.vat > 0 ? `£${invoiced.vat}` : ''}
          )` : ''
        }` : '',
        daysInSow ? `${daysInSow - submitted.total - approved.total - invoiced.total} days left` : ''
      ].filter(Boolean).join(', ')}
      </div>
    </div>
  );
}
