import moment from "moment";
import {ACTIONS, STATUSES} from "../containers/client-tester/risk-chart/RiskTreatmentConstants";

export function getColorByRiskRating(number) {
  switch (true) {
    case (number <= 5):
      return 'rgb(0, 180, 0)';
    case (number <= 15):
      return 'rgb(255, 153, 0)';
    case (number <= 25):
      return 'rgb(255, 0, 0)';
    default:
      return 'inherit';
  }
}

export function formatDate(date: string) {
  return moment(new Date(date)).format("DD-MM-YYYY H:mm");
}

export function renderAction(id) {
  const filtered = ACTIONS.find(e => e.id === id);
  return filtered ? filtered.label : '';
}

export function renderStatus(id) {
  const filtered = STATUSES.find(e => e.id === id);
  return filtered ? filtered.label : '';
}
