import * as React from "react";
import {composeValidators, fieldsNotEmpty} from "../../../helpers/validations/validation";
import {formValueSelector, InjectedFormProps, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {postItem} from "../../../actions/certificate";
import {certificateTypeRequest} from "../../../actions/certificate-type";
import {Link} from "react-router-dom";
import routerModal, {IBackRoute} from "../../../components/routerModal";
import {WithStyles} from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button/Button";
import {FieldGeneric} from "../../../components/forms/FieldGeneric";
import ReduxSelectSearchField from "../../../components/forms/ReduxSelectSearchField";
import MenuItem from "@material-ui/core/MenuItem";
import {ACTIVE} from "../../../constants/filter";
import {ICertificateLocal} from "../../../models/certificate.interface";
import "react-datepicker/dist/react-datepicker.css";
import ReduxDocumentFileField from "../../../components/forms/ReduxDocumentFileField";
import ReduxDatePicker from "../../../components/forms/ReduxDatePicker";
import selector from "../../../selectors/certificate-type";
import {IAnyProps} from "../../../interfaces/any-props.interface";
import {ITest} from "../../../models/test.interface";
import {createAction} from "redux-actions";
import {SET_IS_TESTER_NAME_BLOCKED} from "../../../constants/action-type";
import ReduxImageFileField from "../../../components/forms/ReduxImageFileField";

const FORM_NAME = "CertificatesCreate";
const formSelector = formValueSelector(FORM_NAME);

const form_keys = [
  "name",
  "existingTest",
  "evidence",
  "validFrom",
  "validTo"
];

class CertificatesCreate extends React.Component<InjectedFormProps<{}, {}> & {
  postItem: (certificate: ICertificateLocal, backRoute: string, formName: string) => void,
  loadItems: any,
  data: any,
  searchFieldValue: any,
  setNameBlocked: () => void;
  setNameUnblocked: () => void;
  formValues: IAnyProps;
  tests: ITest[];
} & WithStyles<any> & IBackRoute, {
  form: any
}> {

  renderCertificateTypeItems = item => {
    return (
      <MenuItem
        key={item.id}
        value={item.id}>
        {item.shortName + " - " + item.fullName}
      </MenuItem>
    );
  };

  componentDidMount() {
    this.props.loadItems(1, 200, ACTIVE);
  }

  getFilteredItems = () => {
    return this.props.data.map(item => {
      item.name = item.shortName + " - " + item.fullName;
      return item;
    });
  };

  render() {
    const {handleSubmit, postItem, backRoute} = this.props;

    return (
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit((values: ICertificateLocal) => {
          postItem(values, backRoute, FORM_NAME);
        })}
      >
        <h4>Certification Details</h4>
        <hr/>
        <div className="styled-modal-body">
          <FieldGeneric
            name="certificateType"
            label="Name *"
            component={ReduxSelectSearchField}
            items={this.getFilteredItems()}
            allowDisable
            renderItem={this.renderCertificateTypeItems}
          />
          <FieldGeneric
            name="evidence"
            label="File path *"
            component={ReduxImageFileField}
          />
          <div className="row">
            <div className="col-md-6">
              <FieldGeneric
                name="validFrom"
                label="From *"
                InputLabelProps={{
                  shrink: true
                }}
                component={ReduxDatePicker}
              />
            </div>
            <div className="col-md-6">
              <FieldGeneric
                name="validTo"
                label="To *"
                InputLabelProps={{
                  shrink: true
                }}
                component={ReduxDatePicker}
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between mt-4">
          <Link to={this.props.backRoute}>
            <Button variant="contained" color="default">
              Cancel
            </Button>
          </Link>
          <Button variant="contained" color="primary" type="submit">
            Save
          </Button>
        </div>
      </form>
    );
  }
}

const formConnected = reduxForm({
  form: FORM_NAME,
  validate: composeValidators(
    fieldsNotEmpty(["certificateType", "evidence", "validFrom", "validTo"]),
    (values) => {
      const error = {validFrom: "From should not be after To"};

      return (new Date(values.validFrom) >= new Date(values.validTo)) ? error : {};
    }
  )
})(CertificatesCreate);

const connected = connect((state: any) => {
  return {
    data: selector.getItems(state)
  };
}, {
  postItem,
  loadItems: certificateTypeRequest.getItems,
  setNameBlocked: createAction(SET_IS_TESTER_NAME_BLOCKED, () => true),
  setNameUnblocked: createAction(SET_IS_TESTER_NAME_BLOCKED, () => false)
})(formConnected);

export default routerModal(connected);