import * as React from "react";
import { useState } from "react";
import { withRouter } from "react-router-dom";
import { History } from "history";
import { Modal } from "@material-ui/core";
import { ITest } from "../../../models/test.interface";
import "./style.css";
import { connect } from "react-redux";
import { deleteTestWithoutRedirectPopupSubmit } from "../../../actions";

interface ITestProps extends ITest {
  unpaidInvoice?: boolean;
}

interface ITableActionProps {
  item: ITestProps;
  history: History;
  refreshItems: () => void;
  deleteTestWithoutRedirectPopupSubmit: (id: number, cb?: () => void) => void;
}

const TableAction = ({
  item,
  history,
  deleteTestWithoutRedirectPopupSubmit,
  refreshItems
}: ITableActionProps) => {
  const [isShowModal, setIsShowModal] = useState(false);
  const { status, id, unpaidInvoice = false } = item;
  const statusAsNumber = Number(status);
  const allowedToEditStatusList = [1, 2, 3, 4, 5, 6, 16, 17, 18, 19];
  const unableToDeleteTest = status === 10 && !!unpaidInvoice;

  const isShowEditButton = allowedToEditStatusList.some(
    allowStatus => allowStatus === statusAsNumber
  );

  if (isShowModal && id) {
    const AbleToDeleteTestModalContent = () => (
      <div className="styled-modal sm">
        <h4>Confirm File Deletion</h4>
        <p>
          Are you sure you want to proceed with the deletion of this file? This
          action is irreversible and will permanently remove the file and all
          associated data
        </p>

        <hr />
        <div className="text-right">
          <button className="btn-cancel" onClick={() => setIsShowModal(false)}>
            No
          </button>
          <button
            type="submit"
            className="btn-save"
            onClick={() => {
              deleteTestWithoutRedirectPopupSubmit(id);
              refreshItems();
              setIsShowModal(false);
            }}
          >
            Yes
          </button>
        </div>
      </div>
    );
    const UnableToDeleteTestModalContent = () => (
      <div className="styled-modal sm">
        <h4>Unable to Delete:</h4>
        <p>Until invoice related to activity has been paid.</p>

        <hr />
        <div className="d-flex justify-content-end">
          <button
            className="btn-save"
            onClick={() => {
              setIsShowModal(false);
            }}
          >
            Ok
          </button>
        </div>
      </div>
    );

    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={isShowModal}
        onClose={() => setIsShowModal(false)}
        className="d-flex overflow-auto"
      >
        {unableToDeleteTest ? (
          <UnableToDeleteTestModalContent />
        ) : (
          <AbleToDeleteTestModalContent />
        )}
      </Modal>
    );
  }

  return (
    <div className="menu-nav">
      <div className="dropdown-container" tabIndex={-1}>
        <div className="three-dots"></div>
        <div className="dropdown">
          {isShowEditButton && (
            <div
              className="dropdown-item"
              onClick={() => {
                history.push(`/client/booking-requests/${id}/edit`);
              }}
            >
              Edit
            </div>
          )}
          <div className="dropdown-item" onClick={() => setIsShowModal(true)}>
            Delete
          </div>
        </div>
      </div>
    </div>
  );
};

const connectedComponent = connect(null, {
  deleteTestWithoutRedirectPopupSubmit
})(TableAction);

export default withRouter(connectedComponent);
