import React from "react";
import {
  Field,
  formValueSelector,
  InjectedFormProps,
  reduxForm
} from "redux-form";
import { connect } from "react-redux";
import routerModal from "../../../../components/routerModal";
import { bindActionCreators, Dispatch } from "redux";
import { Checkbox, ListItemText, MenuItem } from "@material-ui/core";
import ReduxTextField from "../../../forms/ReduxTextField";
import { FieldGeneric } from "../../../forms/FieldGeneric";
import ReduxRadioButton from "../../../forms/ReduxRadioButton";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  composeValidators,
  fieldsNotEmpty,
  validateTimeExpensesForm,
  validateWeekTimeExpensesForm
} from "../../../../helpers/validations/validation";
import { RouteComponentProps } from "react-router-dom";
import {
  customSelectHandler,
  getWeekDataById
} from "../../../../helpers/timesheets-expenses";
import selector from "../../../../selectors/time-expenses";
import {
  CreateWeeklyTimeLogFormData,
  DailyTimeLogFormData,
  TimeExpensesCategory,
  WeeklyTimeLogFormData
} from "../../../../types/timesheets-and-expenses";
import { createWeeklyTimeLogAction } from "../../../../actions/time-expenses";
import { ReduxSelectSearchFieldCustom } from "./ReduxSelectSearchFieldCustom";

export const TE_WEEK_TIME_LOG_FORM_NAME = "TEWeekTimeLogForm";
interface TEWeekTimeLogFormProps extends RouteComponentProps<{ id: string }> {
  handleSubmit: () => void;
  categorySelected?: number;
  goBackHandler: () => void;
  timeCategoriesList: TimeExpensesCategory[];
  createWeeklyTimeLog: (params: CreateWeeklyTimeLogFormData) => void;
}

const searchFormSelector = formValueSelector(TE_WEEK_TIME_LOG_FORM_NAME);

const TEWeekTimeLogForm: React.FC<TEWeekTimeLogFormProps &
  InjectedFormProps<WeeklyTimeLogFormData>> = ({
  handleSubmit,
  valid,
  submitting,
  dirty,
  change,
  categorySelected = [],
  goBackHandler,
  timeCategoriesList,
  createWeeklyTimeLog,
  match: {
    params: { id }
  }
}) => {
  const isDisabled = !valid || submitting || !dirty;
  const { project, weekStartDate, title, activeDaysCount } = getWeekDataById(
    id
  );
  const timeCategoriesHandler = customSelectHandler("timeCategory", change);
  const daysHandler = customSelectHandler("days", change);

  return (
    <div>
      <form
        className="timesheet-form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(values => {
          createWeeklyTimeLog({
            ...values,
            date: weekStartDate,
            project,
            goBackHandler
          });
        })}
      >
        <div className="row">
          <div className="col-12">
            <h4>{title}</h4>
          </div>
        </div>
        <div className="timesheet-form-section mb-0">
          <div className="row">
            <div className="col-12">
              <h5>Time</h5>
              <div className="select-margin-0 timesheet-form-item-required">
                <FieldGeneric
                  name="timeCategory"
                  label="Category"
                  fullWidth
                  component={ReduxSelectSearchFieldCustom}
                  onChange={timeCategoriesHandler}
                  items={timeCategoriesList}
                  renderItem={option => (
                    <MenuItem key={option.id} value={option.id}>
                      <Checkbox checked={categorySelected === option.id} />
                      <ListItemText primary={option.name} />
                    </MenuItem>
                  )}
                  renderValue={() => {
                    return timeCategoriesList.find(
                      item => item.id === categorySelected
                    )?.name;
                  }}
                />
              </div>
            </div>
            <div className="col-12">
              <FieldGeneric
                name="days"
                margin="dense"
                inline={true}
                onChange={daysHandler}
                component={ReduxRadioButton}
              >
                <FormControlLabel
                  value={"1"}
                  control={<Checkbox />}
                  label="1 day"
                  className="mb-0"
                />
                <FormControlLabel
                  value={"0.5"}
                  control={<Checkbox />}
                  label="0.5 days"
                  className="mb-0"
                />
              </FieldGeneric>
              <Field
                name="days_text"
                component={ReduxTextField}
                type="hidden"
                margin="none"
                className="days-error-field timesheet-form-item-required"
              />
            </div>
          </div>
        </div>
        <div className="timesheet-form-section">
          <div className="row">
            <div className="col-12">
              <h5>Comment</h5>
              <Field
                name="comment"
                label="Comment"
                component={ReduxTextField}
                margin="none"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="d-flex justify-content-end mt-4">
              <button
                type="button"
                className="btn-cancel mr-4"
                onClick={goBackHandler}
              >
                Cancel
              </button>
              <button className="btn-accept" type="submit">
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

const ConnectedForm = reduxForm<WeeklyTimeLogFormData, TEWeekTimeLogFormProps>({
  form: TE_WEEK_TIME_LOG_FORM_NAME,
  validate: composeValidators((values: WeeklyTimeLogFormData) => {
    return validateWeekTimeExpensesForm(values);
  })
})(TEWeekTimeLogForm);

function mapStateToProps(state) {
  return {
    categorySelected: searchFormSelector(state, "timeCategory"),
    timeCategoriesList: selector.getTimeCategoriesList(state)
  };
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      createWeeklyTimeLog: createWeeklyTimeLogAction
    },
    dispatch
  );
};
const connected = connect(mapStateToProps, mapDispatchToProps)(ConnectedForm);

export default routerModal(connected);
