import {Observable} from 'rxjs';
import {flatMap, switchMap} from 'rxjs/operators';
import {ActionsObservable} from 'redux-observable';
import * as actionType from '../constants/action-type';
import {result} from 'lodash';
import {fromPromise} from "rxjs/internal-compatibility";
import {IActionPayload} from "./index";
import {startEndCatch} from "./pipes/errors";
import {createLoaderActionItem} from "../interfaces/reducer-item.interface";
import {IPutBankDetailsAction} from "../actions/bank-details";
import {putBankAccountDetails} from "../api/requests/tester-bank-details";
import {merge} from "rxjs/internal/observable/merge";
import {of} from "rxjs/internal/observable/of";
import {reset} from "redux-form";
import {push} from "react-router-redux";

export const putBankDetailsEpic = (action$: ActionsObservable<IActionPayload<IPutBankDetailsAction>>): Observable<any> => {
  return action$
    .ofType(createLoaderActionItem(actionType.TESTER_BANK_DETAILS).put().type)
    .pipe(
      switchMap((action: IActionPayload<IPutBankDetailsAction>) => {
        return merge(
          of(createLoaderActionItem(actionType.TESTER_BANK_DETAILS).loaded(false)),
          fromPromise(putBankAccountDetails(action.payload.item))
            .pipe(
              flatMap((data) => {
                return [
                  reset(action.payload.formName),
                  push(action.payload.backRoute),
                  createLoaderActionItem(actionType.TESTER_BANK_DETAILS).add(action.payload.item),
                ];
              }),
              ...startEndCatch(
                'putBankDetailsEpic',
                of(createLoaderActionItem(actionType.TESTER_BANK_DETAILS).loaded(true))
              ),
            ),
        );
      })
    );
};
