import * as React from 'react';
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import PermissionBlock from "components/auth/PermissionBlock";
import * as Roles from "constants/roles";
import { ROLE } from "constants/roles";
import { TestStatus } from "constants/test-status";
import TestStatusBlock from "components/auth/TestStatusBlock";
import { Permission } from "constants/permission";
import { ITest } from "models/test.interface";

type Props = RouteComponentProps & { test: ITest }

// TODO: update component. Remove duplication
class RiskChartHeader extends React.Component<Props> {
  baseUrl = this.props.match.url;

  hasAccess = (role: ROLE) => (userRoles: ROLE[], userPermissions: Permission[]) => {
    if (userRoles.includes(role)) {
      return true;
    }

    return userPermissions.includes(Permission.CAN_MANAGE_AVORD_CONSULTANCY_COMPANY_MANAGER);
  };

  render() {
    const { test } = this.props;

    return (
      <div>
        <div className="block-header-wrapper">
          <h5 className="block-header">Risk Chart</h5>
          <PermissionBlock has={this.hasAccess(Roles.ROLE_TESTER)}>
            <TestStatusBlock
              currentStatus={test.status}
              requiredStatuses={[
                TestStatus.STATUS_BOOKED,
                TestStatus.STATUS_REPORT_SUBMITTED,
                TestStatus.STATUS_AMENDMENT_SENT,
                TestStatus.STATUS_AMENDMENT_RESPONDED,
              ]}>
              <Link to={this.baseUrl + "/new-risk"}>
                <button className="btn-new">
                  New&nbsp;Risk
                </button>
              </Link>
            </TestStatusBlock>
          </PermissionBlock>
          <PermissionBlock has={this.hasAccess(Roles.ROLE_PLANNER)}>
            <TestStatusBlock
              currentStatus={test.status}
              requiredStatuses={[
                TestStatus.STATUS_DRAFT,
                TestStatus.STATUS_SCHEDULED,
                TestStatus.STATUS_BOOKING_REQUESTED,
                TestStatus.STATUS_BOOKING_REQUESTED_EDITED,
                TestStatus.STATUS_COMPLETED,
              ]}>
              <Link to={this.baseUrl + "/new-risk"}>
                <button className="btn-new">
                  New&nbsp;Risk
                </button>
              </Link>
            </TestStatusBlock>
          </PermissionBlock>
        </div>
      </div>
    );
  }
}

export default withRouter(RiskChartHeader);
