import React from "react";
import { Route, Switch } from "react-router-dom";
import { getCreateLink } from "helpers/router-helper";
import ListConsultantPage from "./pages/ListConsultantPage";
import CreateConsultantPage from "./pages/CreateConsultantPage";
import EditConsultantPage from "./pages/EditConsultantPage";

import { AdminUrls } from "constants/admin-urls";

export function Consultants() {
  return (
    <Switch>
      <Route
        exact
        path={AdminUrls.CONSULTANTS}
        render={() => <ListConsultantPage />}
      />
      <Route
        path={getCreateLink(AdminUrls.CONSULTANTS)}
        render={() => <CreateConsultantPage />}
      />
      <Route
        path={`${AdminUrls.CONSULTANTS}/:id`}
        render={() => <EditConsultantPage />}
      />
    </Switch>
  );
}
