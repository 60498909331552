import * as React from "react";
import BigCalendar from "react-big-calendar";
import * as moment from "moment";
import { Link, withRouter,RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { getCalendar } from "../../../../selectors/calendar";
import {
  setCalendarViewTypeAction,
  setCalendarEventsAction,
  setCalendarRangeAction,
  setCalendarDateAction,
} from "../../../../actions/calendar";
import { ICalendarReducer } from "../../../../reducers/calendar";
import CalendarYearView from "./CalendarYearView";
import CalendarMonthView from "./CalendarMonthView";
import Grid from "@material-ui/core/Grid/Grid";

BigCalendar.setLocalizer(BigCalendar.momentLocalizer(moment));

type IMapStateToProps = ICalendarReducer;

interface IDispatchProps {
  setCalendarViewType: (type: string) => void;
  setCalendarRange: (start, end) => void;
  setCalendarDate: (year: number, month: number) => void;
  setCalendarEvents: (events: any[]) => void;
}

type IProps = RouteComponentProps<any> & IDispatchProps & IMapStateToProps;

class Calendar extends React.Component<IProps, {}> {
  componentWillMount(): void {
    this.props.setCalendarViewType("month");
  }

  render() {
    return (
      <div>
        <Grid container spacing={16}>
          <Grid item xs={6}>
            <h4 className="main-content-header">Calendar</h4>
          </Grid>
          <Grid item xs={6} className="text-right">
            <Link to={`/client/schedule/test`}>
              <button className="btn-schedule-a-test">Schedule</button>
            </Link>
            <Link to={`/client/schedule/booking`}>
              <button className="btn-book">Book</button>
            </Link>
          </Grid>
        </Grid>
        <div className="styled-block p-0 mt-4">
          {this.props.view === "year" ? (
            <CalendarYearView />
          ) : (
            <CalendarMonthView />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return getCalendar(state);
};

const connected = connect<IMapStateToProps, IDispatchProps>(mapStateToProps, {
  setCalendarViewType: setCalendarViewTypeAction,
  setCalendarRange: setCalendarRangeAction,
  setCalendarEvents: setCalendarEventsAction,
  setCalendarDate: setCalendarDateAction,
})(Calendar);

export default withRouter(connected);
