export const extractId = (str?: string) => {
  if (!str) {
    return 0;
  }

  if (typeof str !== "string") {
    return 0;
  }

  const res = (str || "").split("/").pop();

  return !!res ? +res : 0;
};

export const getDialingCodeFromCountry = (country: string, codes: any[]) => {
  return codes.find(code => code.name === country).dialingCode;
};

export const replaceNToBr = (str: string) => {
  return str.replace(new RegExp("\r?\n", "g"), "<br>");
};
