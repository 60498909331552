import * as React from "react";
import ReduxTextField from "../../../components/forms/ReduxTextField";
import { MenuItem } from "@material-ui/core";
import { Link } from "react-router-dom";
import { FieldGeneric } from "../../../components/forms/FieldGeneric";
import ReduxSelectField from "../../../components/forms/ReduxSelectField";
import Grid from "@material-ui/core/Grid";
import {
  ACTIONS,
  STATUSES,
  MAX_RESIDUAL_RISK_RATING,
} from "./RiskTreatmentConstants";
import { ICriticalRisk } from "../../client/dashboard/DashboardCriticalRisks";
import {MAX_FIELD_LENGTH, MIN_FIELD_LENGTH} from "./const";

interface IRiskTreatFormProps {
  backRoute: string;
  initialValues: {
    risk?: ICriticalRisk;
  };
}

class RiskTreatmentForm extends React.Component<IRiskTreatFormProps> {
  renderResidualRiskRating = () => {
    const [, ...values] = Array.from(
      Array(MAX_RESIDUAL_RISK_RATING + 1).keys()
    );

    return values.map((item) => (
      <MenuItem
        key={item}
        value={item}>
        {item}
      </MenuItem>
    ));
  };

  renderLookup = (arr) => arr.map((item) => (
    <MenuItem
      key={item.label}
      value={item.id}>
      {item.label}
    </MenuItem>
  ));

  render() {
    const { backRoute, initialValues } = this.props;

    const risk = initialValues.risk as ICriticalRisk;

    const showVulnerability = !!risk.vulnerabilityScore;

    return (
      <Grid container spacing={40}>
        <Grid item xs={12}>
          <h3>Risk Treatment Actions</h3>
          <dl className="row">
            <dt className="col-4 text-dark-gray">Risk Title</dt>
            <dd className="col-8">{risk.title}</dd>

            <dt className="col-4 text-dark-gray">Description</dt>
            <dd className="col-8">{risk.description}</dd>

            <dt className="col-4 text-dark-gray">Impact</dt>
            <dd className="col-8">{risk.impact}</dd>

            <dt className="col-4 text-dark-gray">Likelihood</dt>
            <dd className="col-8">{risk.likelihood}</dd>

            {showVulnerability && (
              <>
                <dt className="col-4 text-dark-gray">CVSS Scoring</dt>
                <dd className="col-8">{risk.vulnerabilityScore}</dd>
              </>
            )}

            <dt className="col-4 text-dark-gray">Rating</dt>
            <dd className="col-8">{risk.impact * risk.likelihood}</dd>
          </dl>
          <FieldGeneric
            name="action"
            label="Treatment Action *"
            component={ReduxSelectField}
          >
            {this.renderLookup(ACTIONS)}
          </FieldGeneric>
          <FieldGeneric
            name="residualRiskRating"
            label="Residual Risk Rating *"
            component={ReduxSelectField}
          >
            {this.renderResidualRiskRating()}
          </FieldGeneric>
          <FieldGeneric
            inputProps={{
              minLength: MIN_FIELD_LENGTH,
              maxLength: MAX_FIELD_LENGTH,
              style: { overflow: "hidden" },
            }}
            multiline
            name="comment"
            label="Explanation how risk is treated *"
            component={ReduxTextField}
          />
          <FieldGeneric
            name="status"
            label="Status *"
            component={ReduxSelectField}
          >
            {this.renderLookup(STATUSES)}
          </FieldGeneric>

          <div className="text-right mt-4">
            <Link className="btn-cancel" to={backRoute}>
              Cancel
            </Link>
            <button type="submit" className="btn-accept">
              Save
            </button>
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default RiskTreatmentForm;
