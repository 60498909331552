import * as React from 'react';
import Button from "@material-ui/core/Button/Button";
import { Link, Route, Switch } from "react-router-dom";
import SpecialismList from "./SpecialismList";
import SpecialismUpdate from "./SpecialismUpdate";
import {RouteComponentProps, withRouter} from 'react-router-dom';

class Specialism extends React.Component<RouteComponentProps<{}>, {}> {
  editComponent = () => <SpecialismUpdate backRoute={this.props.match.path} />;
  listComponent = () => <SpecialismList backRoute={this.props.match.path} />;

  render() {
    const baseUrl = this.props.match.path;

    return (
      <div>
        <div>
          <div className="crud-sections-header">
            <h4>Specialisms (Test types)</h4>
            {
              this.props.location.pathname === `${baseUrl}/edit` ?
                <Link to={`${baseUrl}`} color="default" className="text-center">
                  <Button variant="contained">
                    View
                  </Button>
                </Link> :
                <Link to={`${baseUrl}/edit`} color="default" className="text-center">
                  <Button variant="contained">
                    Edit
                  </Button>
                </Link>
            }
          </div>
          <hr />
          <Switch>
            <Route path={baseUrl + "/edit"} render={this.editComponent} />
            <Route exact path={baseUrl} component={this.listComponent}/>
          </Switch>
        </div>
      </div>
    );
  }
}

export default withRouter(Specialism);
