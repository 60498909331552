import {getApiJSON} from "../swagger/api-factory";
import {fetchRequestBody} from "../../helpers/request-helper";

export const getTestersRightsToWorkCountries = () => {
  return getApiJSON()
    .then(api => api.getApiTesterProfileRightsToWork())
    .then(fetchRequestBody);
};

export const putTestersRightsToWorkCountries = (model) => {
  return getApiJSON()
    .then(api => api.putApiTesterProfileRightsToWork({accountData: model}))
    .then(fetchRequestBody);
};
