import * as React from "react";
import { Field } from "redux-form";
import Icon from "@material-ui/core/Icon/Icon";
import Button from "@material-ui/core/Button/Button";
import { FieldsProps } from "redux-form/lib/FieldArray";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import { IManagedServices } from "../../../models/managed-services.interface";
import ReduxTextField from "../../../components/forms/ReduxTextField";
import { handleKeyPressOnNumberField } from "../../../helpers/validations/numberFieldValidator";

interface IProps {
  fields: FieldsProps<IManagedServices>;
  index: number;
  member: string;
  level: number;
}

const MIN_FIELD_VALUE = 1

export class AdditionalServicesUpdateListElement extends React.Component<IProps> {
  state = {
    days: this.props.fields.get(this.props.index).daysPercent || 0,
    rate: this.props.fields.get(this.props.index).price || 0,
  };
  removeField = () => this.props.fields.remove(this.props.index);

  changeStateHandler = (state: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = +event.target.value;
    this.setState({
      [state]: value,
    });
  };

  render() {
    const { days, rate } = this.state;
    const { member, index } = this.props;

    return (
      <li>
        <div className="row">
          <div className="col-sm-1 d-flex align-items-center">
            <Button
              className="action-button array-button"
              variant="fab"
              type="button"
              color="secondary"
              onClick={this.removeField}
            >
              <Icon>delete</Icon>
            </Button>
          </div>
          <div className="col-md-2">
            <Field
              name={`${member}.name`}
              label="Service"
              margin="normal"
              fullWidth
              component={ReduxTextField}
            />
          </div>
          <div className="col-md-2">
            <Field
              name={`${member}.description`}
              label="Description"
              margin="normal"
              fullWidth
              component={ReduxTextField}
            />
          </div>
          <div className="col-md-2">
            <Field
              name={`${member}.daysPercent`}
              label="Days"
              type="number"
              margin="normal"
              fullWidth
              inputProps={{min: MIN_FIELD_VALUE}}
              onChange={this.changeStateHandler("days")}
              onKeyPress={handleKeyPressOnNumberField}
              component={ReduxTextField}
            />
          </div>
          <div className="col-md-2">
            <Field
              name={`${member}.price`}
              label="Day rate"
              type="number"
              margin="normal"
              fullWidth
              inputProps={{min: MIN_FIELD_VALUE}}
              onChange={this.changeStateHandler("rate")}
              onKeyPress={handleKeyPressOnNumberField}
              component={ReduxTextField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">&pound;</InputAdornment>
                ),
              }}
            />
          </div>
          <div className="d-flex align-items-center mt-3">
            <b>Total:</b> &pound;{" "}
            {days * rate || this.props.fields.get(index).total}
          </div>
        </div>
      </li>
    );
  }
}
