import {setRequestInProcess} from "./request";
import {Dispatch} from "redux";
import {
  confirmInviteToCompanyRequest,
} from "../api/requests/invite-to-company-confirmation";
import {result} from "lodash";
import {createAction} from "redux-actions";
import * as Types from "../constants/action-type";

export const confirmInviteToCompany = (parameters) => async  (dispatch: Dispatch) => {
  dispatch(setRequestInProcess(true, 'inviteToCompanyConfirmation'));

  let response: any = {
    success: false
  };

  try {
    response = await confirmInviteToCompanyRequest(parameters);
  } catch (error) {
    response={...response, message: result(error, "response.body.hydra:description")};
  }

  dispatch(setRequestInProcess(false, 'inviteToCompanyConfirmation'));

  const payload = {
      status: response.success,
      message: response.message
  };

  dispatch(createAction(Types.INVITE_TO_COMPANY_CONFIRMATION, () => payload)());
};

export default confirmInviteToCompany;
