import * as React from 'react';
import Header from "../containers/guest/Header";

export const NoRouteMatched: React.SFC<{ location: { pathname: string } }> = ({location}) => (
  <div>
    <Header/>
    <div className="page-content">
      <div className="container">
        <h1>404</h1>
        <h3>Page not found</h3>
      </div>
    </div>
  </div>
);
