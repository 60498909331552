import * as React from "react";
import Table from "@material-ui/core/Table/Table";
import TableBody from "@material-ui/core/TableBody/TableBody";
import {connect} from "react-redux";
import {testAndProjectRequest, testAndProjectLoader, getTestsListRequestsAction} from "../../../../actions/test-and-projects";
import selector from "../../../../selectors/test-and-projects";
import {TableHead, TablePagination} from "@material-ui/core";
import {Zoom} from "@material-ui/core";
import {isChangesExist} from "../../../../helpers/props-checker";
import {IAnyProps} from "../../../../interfaces/any-props.interface";
import {ITest} from "../../../../models/test.interface";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import debounce from "debounce";
import {Link} from "react-router-dom";
import {reportCanBeViewedByViewer, checkTestIsNotDraftStatus, ALL_TEST_STATUSES_ARRAY} from "../../../../constants/test-status";
import PermissionBlock from "../../../../components/auth/PermissionBlock";
import {ROLE_OWNER, ROLE_PLANNER, ROLE_VIEWER} from "../../../../constants/roles";
import StatusBlock from "../../../common/StatusBlock";
import {putItem, deleteFavorite} from "../../../../api/requests/favorite-testers";
import TesterTableCell from "../../../common/TesterTableCell";
import Tooltip from "@material-ui/core/Tooltip";
import { openSnackbar } from "../../../../actions/snackbar";
import {bindActionCreators} from "redux";
import Icon from "@material-ui/core/Icon/Icon";
import TableAction from "../../reports/TableAction";
import {formValueSelector} from "redux-form";
import {TESTS_FILTER_FORM_NAME} from "./TestSearchForm";
import {formatDateWithTime} from "../../../../helpers/date-formatter";

const searchFormSelector = formValueSelector(TESTS_FILTER_FORM_NAME);

class TestList extends React.Component<{
  baseUrl: string,
  data: ITest[],
  [key: string]: any,
  searchValues: {
    project: number[],
    status: string[],
    dateFrom: string,
    dateTo: string,
  };
}, {}> {
  debouncedLoad = debounce((nextProps) => {
    this.loadItems(nextProps);
  }, 500);

  loadItems = (props) => {
    props.loadItems(
      props.pageNumber,
      props.itemsPerPage,
      props.searchValues.project,
      !!props.searchValues.status && props.searchValues.status.length ?
        props.searchValues.status :
        ALL_TEST_STATUSES_ARRAY,
      props.searchValues.dateFrom,
      props.searchValues.dateTo
    );
  };

  onArchive = (item) => {
    this.props.archiveItem(
      item.id,
      this.props.pageNumber,
      this.props.itemsPerPage,
      this.props.showStatus,
      this.props.filterText
    );
  };

  componentDidMount() {
    this.loadItems(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (isChangesExist(["pageNumber", "itemsPerPage"], nextProps, this.props) ||
      isChangesExist(["project", "status", "dateFrom", "dateTo"], nextProps.searchValues, this.props.searchValues)
    ) {
      this.loadItems(nextProps);
    }
  }

  handleChangePage = (event, page) => {
    this.props.setPageNumber(page + 1);
  };

  handleChangeRowsPerPage = event => {
    this.props.setItemsPerPage(event.target.value);
  };

  handleOnClick = (tester) => (event) => {
    if (tester && !!tester.id) {
      if (tester.isFavorite) {
        deleteFavorite(tester.id)
        .then(()=>{
          this.debouncedLoad(this.props);
          this.props.showSuccessMessage("Tester successfully removed from favorites");
        });
      } else {
        putItem(tester.id)
        .then(()=>{
          this.debouncedLoad(this.props);
          this.props.showSuccessMessage("Tester successfully added to favorites");
        });
      }
    }
  };

  refreshItems = () => {
    this.loadItems(this.props);
  }

  getWrapperStyle = (item: ITest) => reportCanBeViewedByViewer(Number(item.status)) === null ? 'justify-content-end' : 'justify-content-between'


  render() {
    if (!this.props.isLoaded) {
      return (
        <div className="styled-block table-no-items">
          Loading...
        </div>
      );
    }

    if (!this.props.data.length) {
      return (
        <div className="styled-block table-no-items">
          There are no items!
        </div>
      );
    }

    return (
      <div className="styled-block">
        <h4 className="block-header mb-4">Activity</h4>
        <div className="table-responsive styled-table">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Project</TableCell>
                <PermissionBlock
                  roles={[
                    ROLE_PLANNER,
                    ROLE_OWNER
                  ]}
                >
                <TableCell>Virtual<br/>Team</TableCell>
                </PermissionBlock>
                <TableCell>Consultant/Tester</TableCell>
                <TableCell>PO number</TableCell>
                <TableCell>Planner</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                this.props.data.map(item => {
                  const tester = item.tester ? item.tester : item.preFillTester;
                  const hasStartDate = !!item?.dateStart && checkTestIsNotDraftStatus(item.status);
                  const startDate = hasStartDate ? <p>{formatDateWithTime(item.dateStart as string)}</p> : null;

                  return (
                    <TableRow key={item.id}>
                      <TableCell>
                        <PermissionBlock roles={[ROLE_VIEWER]}>
                          {reportCanBeViewedByViewer(Number(item.status)) ?
                            <Link to={`/client/reports/${item.id}/risk-chart`}>{item.hash + "-" + item.name}</Link> :
                            item.hash + "-" + item.name
                          }
                          {startDate}
                        </PermissionBlock>
                        <PermissionBlock roles={[ROLE_PLANNER]}>
                          <Link to={`${this.props.baseUrl}/${item.id}`}>{item.hash + "-" + item.name}</Link>
                          {startDate}
                        </PermissionBlock>
                      </TableCell>
                      <TableCell>{item.projectName}</TableCell>
                      <PermissionBlock
                        roles={[
                          ROLE_PLANNER,
                          ROLE_OWNER
                        ]}
                      >
                      <TableCell padding="dense">
                        {!!tester && tester.user && !tester.user.deletedAt && (
                          <div className="tester-image-name" onClick={this.handleOnClick(tester)}>
                            {
                              (tester.isFavorite
                                ? (
                                  <Tooltip
                                    placement="bottom-end"
                                    TransitionComponent={Zoom}
                                    disableFocusListener
                                    disableTouchListener
                                    title="Remove from favorites"
                                  >
                                    <span className="btn-manage"/>
                                  </Tooltip>
                                )
                                : (
                                  <Tooltip
                                    placement="bottom-end"
                                    TransitionComponent={Zoom}
                                    disableFocusListener
                                    disableTouchListener
                                    title="Add to favorites"
                                  >
                                    <span className="btn-manage-inactive"/>
                                  </Tooltip>
                                )
                              )
                            }
                          </div>
                        )}
                      </TableCell>
                    </PermissionBlock>

                      <TesterTableCell tester={tester}/>
                      <TableCell>
                        {!item.purchaseOrderNumber ? (
                            <div style={{marginLeft: "25px"}}>
                              <Tooltip
                                  placement="bottom-end"
                                  TransitionComponent={Zoom}
                                  disableFocusListener
                                  disableTouchListener
                                  title="Purchase Order number missing"
                              >
                                <Icon style={{color:"#FF0000"}}>games_icon</Icon>
                              </Tooltip>
                            </div>
                        ) : (
                            <div style={{maxWidth:"100px", wordBreak: "break-all"}}>
                              {item.purchaseOrderNumber}
                            </div>
                        )}
                      </TableCell>
                      <TableCell>{item.plannerName}</TableCell>
                      <TableCell>
                        {item.status && <StatusBlock status={item.status}/>}
                      </TableCell>
                      <TableCell>
                        <PermissionBlock roles={[ROLE_VIEWER]}>
                          <div className={`d-flex align-items-center ${this.getWrapperStyle(item)}`}>
                          {reportCanBeViewedByViewer(Number(item.status)) &&
                          <Link to={`/client/reports/${item.id}/risk-chart`}>
                            <button type="submit" className="btn-view">
                              View&nbsp;Details
                            </button>
                          </Link>
                          }
                            <TableAction
                                item={item}
                                refreshItems={this.refreshItems}
                            />
                          </div>
                        </PermissionBlock>
                        <PermissionBlock roles={[ROLE_PLANNER]}>
                          <div className='d-flex align-items-center justify-content-end' >
                          <Link to={`${this.props.baseUrl}/${item.id}`}>
                            <button type="submit" className="btn-view">
                              View&nbsp;Details
                            </button>
                          </Link>
                            <TableAction
                                item={item}
                                refreshItems={this.refreshItems}
                            />
                          </div>
                        </PermissionBlock>
                      </TableCell>
                    </TableRow>
                  );
                })
              }
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={this.props.totalItems}
            rowsPerPage={this.props.itemsPerPage}
            page={this.props.pageNumber - 1}
            backIconButtonProps={{
              "aria-label": "Previous Page"
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page"
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </div>
      </div>
    );
  }
}

export default connect<IAnyProps, IAnyProps, IAnyProps>((state) => {
  return {
    data: selector.getItems(state),
    isLoaded: selector.getIsLoaded(state),
    itemsPerPage: selector.getItemsPerPage(state),
    pageNumber: selector.getPageNumber(state),
    showStatus: selector.getFilterActivityStatus(state),
    totalItems: selector.getTotalItems(state),
    filterText: selector.getFilterTextFields(state),
    searchValues: searchFormSelector(state, "project", "status", "dateFrom", "dateTo"),
  };
}, dispatch => ({
  loadItems: bindActionCreators(getTestsListRequestsAction, dispatch),
  setItemsPerPage: bindActionCreators(testAndProjectLoader.setItemsPerPage, dispatch),
  setPageNumber: bindActionCreators(testAndProjectLoader.setPageNumber,dispatch),
  archiveItem: bindActionCreators(testAndProjectRequest.archiveItem,dispatch),
  showSuccessMessage: (message)=>dispatch(openSnackbar(message, 4000))
}))(TestList);
