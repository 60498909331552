import React from "react";
import Grid from "@material-ui/core/Grid/Grid";
import { ACCOUNT_MANAGER_PERMISSIONS_LABELS } from "constants/permission";
import { Field, change } from "redux-form";
import ReduxSwitch from "components/forms/ReduxSwitch";
import { connect } from "react-redux";

type Props = {
  formName: string;
  dispatch: any;
};

function PermissionsBlock({ formName, dispatch }: Props) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, value: string, previousValue: string, name: string) => {

    if (name === 'permissions.CAN_VIEW_CONSULTANTS' && !value) {
      dispatch(change(formName, "permissions.CAN_MANAGE_CONSULTANTS", false));
    }
    if (name === 'permissions.CAN_MANAGE_CONSULTANTS' && value) {
      dispatch(change(formName, "permissions.CAN_VIEW_CONSULTANTS", true));
    }
    if (name === 'permissions.CAN_VIEW_TIME_EXPENSES' && !value) {
      dispatch(change(formName, "permissions.CAN_MANAGE_TIME_EXPENSES", false));
    }
    if (name === 'permissions.CAN_MANAGE_TIME_EXPENSES' && value) {
      dispatch(change(formName, "permissions.CAN_VIEW_TIME_EXPENSES", true));
    }
  };

  return (
    <Grid container>
      {Object.entries(ACCOUNT_MANAGER_PERMISSIONS_LABELS).map(
        ([value, label]) => (
          <Grid item sm={6} key={value}>
            <Field
              name={`permissions.${value}`}
              margin="normal"
              label={label}
              component={ReduxSwitch}
              defaultValue=""
              onChange={handleChange}
            />
          </Grid>
        )
      )}
    </Grid>
  );
}

export default connect()(PermissionsBlock);
