export const QUESTION_FORM_NAME = 'QuestionModal';
export const QUESTION_NEW_FORM_NAME = 'QuestionNewModal';
export const AMENDMENTS_FORM_NAME = 'AmendmentsRequestModal';
export const AMENDMENTS_ANSWER_FORM_NAME = 'AmendmentsModal';
export const REPORT_ACCEPTED_FORM_NAME = 'ReportAcceptedModal';
export const CREATE_REPORT_DOCUMENT_FORM_NAME = 'ReportDocumentCreateModal';
export const CREATE_ADDITIONAL_DOCUMENT_FORM_NAME = 'AdditionalDocumentCreateModal';
export const REPORT_DETAILS_CREATE_FORM = 'ReportDetailsForm';

export const MESSAGE_FORM_NAME = 'MessageModal';
export const MESSAGE_NEW_FORM_NAME = 'MessageNewModal';