import * as React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import {connect} from "react-redux";
import {getIsInProcess} from "../../selectors/loading";

const stopPropagation = (event)=>{
  event.stopPropagation();
};

function LoaderIndicator(props) {
  if (!props.inProcess) {
    return null;
  }

  return (
    <div style={{
      zIndex: 9999,
      position: 'fixed',
      top: '0px',
      width: '100%',
      background: '#5d5d5d47',
      height: '100%',
    }} onClick={stopPropagation}>
      <LinearProgress variant="query"/>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    inProcess: getIsInProcess(state),
  };
}

export default connect(mapStateToProps)(LoaderIndicator);
