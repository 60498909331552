import {createSelector} from "reselect";
import {ITesterReviewState} from "../reducers/tester-reviews";
import {result} from 'lodash';

const getTesterReviewsObj: (any: any) => ITesterReviewState = state => state.testerReviews;

export const getTesterReviews = (id: string) => createSelector(
  getTesterReviewsObj,
  state => result(state, id, [])
);
