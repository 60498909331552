import { convertorFromSwagger } from "../convertors/default-convertor";
import { fetchRequestBody, extractJSONLD } from "../../helpers/request-helper";
import { getApiJSONLD } from "../swagger/api-factory";

export const getItems = async testId => {
  return await getApiJSONLD()
    .then(api => api.api_tests_risks_get_subresource({ id: testId }))
    .then(extractJSONLD(convertorFromSwagger));
};

export const deleteItem = async testId => {
  return await getApiJSONLD().then(api => api.deleteRiskItem({ id: testId }));
};

export const putItem = (id, model) => {
  const converted = {
    id,
    riskTreatment: model
  };

  return getApiJSONLD()
    .then(api => api.putRiskTreatmentItem(converted))
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};

export const postItem = model => {
  const converted = {
    riskTreatment: { ...model, risk: model.risk["@id"] || model.risk }
  };

  return getApiJSONLD()
    .then(api => api.postRiskTreatmentCollection(converted))
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};
