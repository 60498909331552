import React, { useState } from "react";
import SearchByMonthForm from "./SearchByMonthForm";
import { InfoMessage } from "./InfoMessage";
import { TE_STATUS } from "constants/timesheet-expenses";
import selector from "selectors/time-expenses";
import { connect } from "react-redux";
import UpdateSelectedWeeksStatusForm
  from "./forms/UpdateSelectedWeeksStatusForm";
import TimeSheetWeeksList from "./TimeSheetWeeksList";

const infoMessage =
  "Please action all timesheets weekly by Monday 12:00 PM";

type ClientTimeSheetsComponentProps = {
  selectedProjectWeeks: number[];
};

export const ClientTimeSheetsComponent = (
  props: ClientTimeSheetsComponentProps
) => {
  const {selectedProjectWeeks} = props;
  const [isOpenedRejectAllModal, setIsOpenedRejectAllModal] = useState<boolean>(
    false
  );
  const [isOpenedApproveAllModal, setIsOpenedApproveAllModal] = useState<
    boolean
  >(false);

  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center pt-2 pb-4 flex-wrap"
        style={{width: "100%"}}
      >
        <SearchByMonthForm />
        <div>
          <button
            className="btn-reject mr-2 mb-3 mb-sm-0"
            onClick={() => setIsOpenedRejectAllModal(true)}
            disabled={!selectedProjectWeeks.length}
          >
            Reject Selected
          </button>
          <button
            className="btn-accept mr-2"
            onClick={() => setIsOpenedApproveAllModal(true)}
            disabled={!selectedProjectWeeks.length}
          >
            Approve selected
          </button>
        </div>
      </div>
      <InfoMessage message={infoMessage} />
      <TimeSheetWeeksList />
      {isOpenedRejectAllModal && (
        <UpdateSelectedWeeksStatusForm
          closeModal={() => setIsOpenedRejectAllModal(false)}
          confirmLabel="Reject"
          description="You are going to reject selected weeks. Please leave a comment if necessary."
          status={TE_STATUS.STATUS_REJECTED}
        />
      )}
      {isOpenedApproveAllModal && (
        <UpdateSelectedWeeksStatusForm
          closeModal={() => setIsOpenedApproveAllModal(false)}
          confirmLabel="Approve"
          description="You are going to approve selected weeks. Please leave a comment if necessary."
          status={TE_STATUS.STATUS_APPROVED}
        />
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    selectedProjectWeeks: selector.getSelectedProjectWeeks(state)
  };
}

const connected = connect(mapStateToProps)(ClientTimeSheetsComponent);

export default connected;
