import * as React from 'react';
import {composeValidators, fieldMaxLength, fieldsNotEmpty} from "../../../../helpers/validations/validation";
import {InjectedFormProps, reduxForm} from "redux-form";
import { connect } from "react-redux";
import { faqRequest } from "../../../../actions/faq";
import parameterFetcher from '../../../../components/parametersFetcher';
import selector from '../../../../selectors/faq';
import { FaqForm } from "./FaqForm";
import routerModal from '../../../../components/routerModal';
import {WithStyles} from "@material-ui/core/styles/withStyles";

const FORM_NAME = 'FaqUpdate';

class FaqUpdate extends React.Component<InjectedFormProps<{}, {}> & {
  putData: (...any) => void,
  backRoute: string
} & WithStyles<any>, any> {
  render() {
    const { handleSubmit, putData, backRoute } = this.props;

    return (
      <form noValidate autoComplete="off"
        onSubmit={handleSubmit((values) => {
          putData(values, FORM_NAME, backRoute);
        })}>
        <FaqForm backRoute={backRoute}/>
      </form>
    )
  }
}

const formConnected = reduxForm({
  form: FORM_NAME,
  validate: composeValidators(
    fieldsNotEmpty(['question', 'answer',]),
    fieldMaxLength(['answer'], 2000),
    fieldMaxLength(['question'], 150),
  )
})(FaqUpdate);

const connected = parameterFetcher(
  connect(undefined, {
    putData: faqRequest.putItem,
  })(formConnected),
  FORM_NAME,
  faqRequest.getItem,
  selector.getItemById
);

export default routerModal(connected);
