import * as React from 'react';
import Button from "@material-ui/core/Button/Button";
import {Link, Route, Switch} from "react-router-dom";
import ReportTemplatesList from "./ReportTemplatesList";
import ReportTemplatesCreate from "./ReportTemplatesCreate";
import ReportTemplatesUpdate from "./ReportTemplatesUpdate";
import {withRouter} from 'react-router-dom';
import {getCreateLink} from '../../../../helpers/router-helper';
import {getSpecialismsWithoutReportTemplatesAction} from "../../../../actions/specialisms-without-report-templates";
import {connect} from "react-redux";


class ReportTemplates extends React.Component<{
  getSpecialismsWithoutReportTemplatesAction: (...any) => void,
  [key: string]: any,
}, {}> {
  baseUrl = this.props.match.path;

  componentDidMount() {
    this.props.getSpecialismsWithoutReportTemplatesAction();
  }

  createComponent = () => <ReportTemplatesCreate backRoute={this.baseUrl}/>;
  updateComponent = () => <ReportTemplatesUpdate backRoute={this.baseUrl}/>;

  render() {
    const {specialismsWithoutReportTemplates} = this.props;

    return (
      <div>
        <div className="crud-sections-header">
          <h4>Report Templates</h4>
          {!!specialismsWithoutReportTemplates.length &&
          <Link to={getCreateLink(this.baseUrl)}>
            <Button variant="contained" color="default">
              New template
            </Button>
          </Link>}
        </div>
        <hr/>
        <Switch>
          <Route path={getCreateLink(this.baseUrl)} render={this.createComponent}/>
          <Route path={this.baseUrl + "/:id"} render={this.updateComponent}/>
        </Switch>
        <ReportTemplatesList baseUrl={this.baseUrl}
                             specialismsWithoutReportTemplates={specialismsWithoutReportTemplates}/>
      </div>
    );
  }
}

const connected = connect(
  (state: any) => ({
    specialismsWithoutReportTemplates: state.specialismsWithoutReportTemplates,
  }),

  {
    getSpecialismsWithoutReportTemplatesAction,
  }
)(ReportTemplates as any);

export default withRouter(connected as any);
