import {IConverterFromSwagger, IConverterToSwagger} from "./converter.interface";
import {IRegistrationTester} from "../../models/registration-tester.interface";

export const converterFromSwagger: IConverterFromSwagger<IRegistrationTester, any/*RegistrationTesterSwagger*/> = model => {
  return {...model, is_agree: model.isTermsAndConditionsApproved};
};

export const converterToSwagger: IConverterToSwagger<IRegistrationTester, any/*RegistrationTesterSwagger*/> = model => {
  return {...model, isTermsAndConditionsApproved: model.is_agree};
};
