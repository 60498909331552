import React, { useEffect, useState } from "react";
import { Button, Checkbox, ListItemText, MenuItem } from "@material-ui/core";
import { connect } from "react-redux";
import { formValueSelector, InjectedFormProps, reduxForm } from "redux-form";
import { FieldGeneric } from "../../../../components/forms/FieldGeneric";
import { bindActionCreators } from "redux";
import ReduxSelectField from "../../../../components/forms/ReduxSelectField";
import {
  ALL_TEST_STATUSES_ARRAY,
  getTestStatusName
} from "../../../../constants/test-status";
import ReduxDatePicker from "../../../../components/forms/ReduxDatePicker";
import { testAndProjectFilter } from "../../../../actions/test-and-projects";
import {
  TestsFilterParamsForCSVType,
  ITestsClientFilterParamsForCSV
} from "../../../../types";
import { downloadTestsCSVDocument } from "../../../../api/requests/document-download";
import selector from "../../../../selectors/test-and-projects";
import { ITest } from "../../../../models/test.interface";
import { getItems } from "../../../../api/requests/projects";
import * as FilterActions from "../../../../constants/filter";

const PROJECT_PAGE_BY_DEFAULT = 1;
const PROJECTS_PER_PAGE_BY_DEFAULT = 1000;

export const TESTS_FILTER_FORM_NAME = "TestsFilterForm";
const selectorForm = formValueSelector(TESTS_FILTER_FORM_NAME);

export interface IProject {
  id: number;
  name: string;
  status: number;
}

type IProps = InjectedFormProps<{}, {}> & {
  submitFilterChanges: (value: string) => void;
  downloadTestsCSVDocument: (params: TestsFilterParamsForCSVType) => void;
  valuesData: {
    active: string;
    text?: string;
    status: number[];
    project: number[];
    dateFrom?: string;
    dateTo?: string;
  };
  testsList: ITest[];
};

type ProjectOption = {
  id: number;
  name: string;
};

const TestSearchForm = (props: IProps): JSX.Element => {
  const {
    testsList = [],
    valuesData,
    change,
    downloadTestsCSVDocument
  } = props;
  const [projects, setProjects] = useState<ProjectOption[]>([]);

  useEffect(() => {
    getItems(
      PROJECT_PAGE_BY_DEFAULT,
      PROJECTS_PER_PAGE_BY_DEFAULT,
      FilterActions.DEFAULT_FILTER
    ).then((data: { items: IProject[]; totalItems: number }) => {
      const projectsOptions: ProjectOption[] = data.items.map(
        (project: IProject) => ({
          name: project.name,
          id: project.id
        })
      );
      setProjects(projectsOptions);
    });
  }, []);

  let filterParams: ITestsClientFilterParamsForCSV = {
    dateFrom: valuesData?.dateFrom,
    dateTo: valuesData?.dateTo
  };
  if (Array.isArray(valuesData?.status) && valuesData.status.length) {
    filterParams = { ...filterParams, statuses: valuesData.status };
  }
  if (Array.isArray(valuesData?.project) && valuesData.project.length) {
    filterParams = { ...filterParams, projects: valuesData.project };
  }

  const joinProjects = (selected: number[]): string =>
    selected
      .map(
        selectedId => projects.find(project => project.id === selectedId)?.name
      )
      .filter(optionName => !!optionName)
      .join(", ");

  const joinStatuses = (selected: number[]): string =>
    selected.map(getTestStatusName).join(", ");

  const checkStatuses = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    change("status", ALL_TEST_STATUSES_ARRAY);
  };

  const uncheckStatuses = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    event.stopPropagation();
    change("status", []);
  };

  const checkProjects = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    change(
      "project",
      projects.map(a => a.id)
    );
  };

  const uncheckProjects = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    event.stopPropagation();
    change("project", []);
  };

  return (
    <>
      <div className="text-right mb-4">
        <button
          className="btn-export full-width-in-mobile"
          disabled={!testsList.length}
          onClick={() => downloadTestsCSVDocument(filterParams)}
        >
          DOWNLOAD CSV
        </button>
      </div>
      <form>
        <div className="table-responsive styled-table styled-block">
          <div className="row">
            <div className="col-md-4 col-lg-2">
              <FieldGeneric
                name="dateFrom"
                label="From"
                InputLabelProps={{
                  shrink: true
                }}
                component={ReduxDatePicker}
              />
            </div>
            <div className="col-md-4 col-lg-2">
              <FieldGeneric
                name="dateTo"
                label="To"
                InputLabelProps={{ shrink: true }}
                addEndOfDay={true}
                component={ReduxDatePicker}
              />
            </div>
            <div className="col-md-4 col-lg-4">
              <FieldGeneric
                name="project"
                label="Project"
                fullWidth
                multiple
                component={ReduxSelectField}
                renderValue={joinProjects}
              >
                <MenuItem>
                  <Button color="primary" onClick={checkProjects}>
                    Check all
                  </Button>
                  <Button color="primary" onClick={uncheckProjects}>
                    Uncheck all
                  </Button>
                </MenuItem>
                {projects.map(project => (
                  <MenuItem key={project.id} value={project.id}>
                    <Checkbox
                      checked={
                        (valuesData.project || []).indexOf(project.id) > -1
                      }
                    />
                    <ListItemText primary={project.name} />
                  </MenuItem>
                ))}
              </FieldGeneric>
            </div>
            <div className="col-md-12 col-lg-4">
              <FieldGeneric
                name="status"
                label="Status"
                fullWidth
                multiple
                component={ReduxSelectField}
                renderValue={joinStatuses}
              >
                <MenuItem>
                  <Button color="primary" onClick={checkStatuses}>
                    Check all
                  </Button>
                  <Button color="primary" onClick={uncheckStatuses}>
                    Uncheck all
                  </Button>
                </MenuItem>
                {ALL_TEST_STATUSES_ARRAY.map(status => (
                  <MenuItem key={status} value={status}>
                    <Checkbox
                      checked={(valuesData.status || []).indexOf(status) > -1}
                    />
                    <ListItemText primary={getTestStatusName(status)} />
                  </MenuItem>
                ))}
              </FieldGeneric>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

function mapStateToProps(state: IProject) {
  return {
    testsList: selector.getItems(state),
    valuesData: selectorForm(state, "project", "status", "dateFrom", "dateTo"),
    initialValues: {
      project: [],
      status: []
    }
  };
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      submitFilterChanges: testAndProjectFilter.submitFilterChanges,
      downloadTestsCSVDocument
    },
    dispatch
  );
};

const connectForm = reduxForm({
  form: TESTS_FILTER_FORM_NAME
})(TestSearchForm);

export default connect(mapStateToProps, mapDispatchToProps)(connectForm);
