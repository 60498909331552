import * as React from "react";
import MessageSearch from "./MessageSearch";
import MessageTable from "./MessageTable";
import {Grid} from "@material-ui/core";
import BasePageTitle from "../../common/BasePageTitle";

function MessagesBlock() {
  return(
    <div className="styled-block" id="messages">
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <BasePageTitle title="Messages" text="New Message" />
        </Grid>

        <Grid item xs={12}>
          <MessageSearch />
        </Grid>

        <Grid item xs={12}>
          <MessageTable />
        </Grid>
      </Grid>
    </div>
  );
}

export default MessagesBlock;