import * as React from "react";
import { Field, FormSection } from "redux-form";
import ReduxTextField from "../../../../components/forms/ReduxTextField";
import {
  Button,
  Checkbox,
  FormControlLabel,
  ListItemText,
  MenuItem,
  Radio
} from "@material-ui/core";
import { Link } from "react-router-dom";
import ReduxSwitch from "../../../../components/forms/ReduxSwitch";
import ReduxRadioButton from "../../../../components/forms/ReduxRadioButton";
import { FieldGeneric } from "../../../../components/forms/FieldGeneric";
import { Group } from "../../../../constants/group";
import { handleKeyPressOnPhoneField } from "../../../../helpers/validations/numberFieldValidator";
import { handleSpaceKeyPress } from "../../../../helpers/validations/emptyTestFieldValidation";
import { Permission } from "../../../../constants/permission";
import ReduxSelectField from "../../../../components/forms/ReduxSelectField";
import Chip from "@material-ui/core/Chip";
import { IProject } from "../../../../models/project.interface";
import { ILocation } from "../../../../models/location.interface";
import {
  ProjectListItem,
  SelectLocationItemType
} from "../../../../types/client-admin-user";

class UsersForm extends React.Component<
  {
    backRoute: string;
    group: Group;
    initialValues: any;
    extraPermissions: object;
    currentUserPermissions: string[];
    isCreateUser: boolean;
    projects: IProject[];
    locations: ILocation[];
    valuesData: {
      projectsIds: number[];
      locationsIds: number[];
    };
    change: (field: string, value: any) => void;
  },
  any
> {
  state = {
    isPlanner: this.props.initialValues.userGroup === "Planner"
  };

  componentWillMount() {
    if (
      this.props.isCreateUser &&
      !this.props.currentUserPermissions.includes(
        Permission.CAN_MANAGE_PLANNERS
      ) &&
      this.props.group !== Group.OWNER
    ) {
      this.props.initialValues.userGroup = Group.VIEWER;
    }
  }

  viewerClickHandler = () => {
    this.setState({ isPlanner: false });
  };

  plannerClickHandler = () => {
    this.setState({ isPlanner: true });
  };

  renderPermissions = () => (
    <div className="row">
      {this.props.extraPermissions &&
        Object.keys(this.props.extraPermissions).map(
          (permissionName, index) => {
            return (
              <div key={index} className="col-sm-12 col-md-6">
                <Field
                  name={permissionName}
                  component={ReduxSwitch}
                  label={this.props.extraPermissions[permissionName]}
                />
              </div>
            );
          }
        )}
    </div>
  );

  disableRadio = (permissions, group) => {
    return !(
      group === Group.OWNER ||
      (permissions.includes(Permission.CAN_MANAGE_PLANNERS) &&
        permissions.includes(Permission.CAN_MANAGE_VIEWERS))
    );
  };

  getProjectsList = (
    projectsArray: ProjectListItem[] = [],
    projectsLocations: number[] = []
  ) => {
    const projects = projectsArray.map(({ id, name, locations }) => ({
      id: id as number,
      name,
      isChecked: projectsLocations.some(item => item === id),
      locations
    }));

    return projects;
  };

  checkAllProjects = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    const { locationsIds = [] } = this.props.valuesData;
    const projectsList = this.props.projects.map(({ id }) => id as number);
    const locationsList = this.getProjectsUniqueLocationsIds(
      this.props.projects
    );
    const locationsL = new Set([...locationsIds, ...locationsList]);
    this.props.change("projectsIds", projectsList);
    this.props.change(
      "locationsIds",
      locationsL.size > 0 ? [...locationsL] : []
    );
  };
  uncheckAllProjects = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    const { locationsIds = [] } = this.props.valuesData;
    const { selectedProjectsLocationsIds } = this.getSelectedProjectsData();
    const locationsList = locationsIds.filter(
      locationId =>
        !this.checkIdExistInArray(selectedProjectsLocationsIds, locationId)
    );
    this.props.change("projectsIds", []);
    this.props.change("locationsIds", [...locationsList]);
  };
  checkAllAdditionalLocations = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    event.stopPropagation();
    const allLocations = this.getAllLocationsList().map(({ id }) => +id);
    this.props.change("locationsIds", allLocations);
  };

  uncheckAllAdditionalLocations = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    event.stopPropagation();
    this.props.change("locationsIds", []);
  };

  getUniqueLocationsList = (projectsArray: ProjectListItem[]) => {
    const { locationsIds = [] } = this.props.valuesData;
    let uniqueLocations: SelectLocationItemType[] = [];
    projectsArray.map(({ locations }) => {
      locations.map(({ id, name }) => {
        const locationIndex = uniqueLocations.findIndex(
          item => +item.id === +id
        );
        if (locationIndex === -1) {
          const isChecked = locationsIds.some(item => item === +id);
          uniqueLocations = [...uniqueLocations, { id, name, isChecked }];
        }
      });
    });
    return uniqueLocations;
  };

  getProjectsById = (id: number) => {
    return (
      this.props.projects.find(project => project.id === id)?.locations || []
    );
  };

  getSelectedProjectsData = () => {
    const selectedProjectsLocationsIds: Set<number> = new Set([]);
    const { projectsIds = [] } = this.props.valuesData;
    const selectedProjects = this.props.projects.filter(({ id }) =>
      projectsIds.some(item => item === (id as number))
    );
    const selectedProjectsIds = selectedProjects.map(({ id }) => id as number);

    selectedProjects.map(({ locations }) => {
      locations.map(location => {
        selectedProjectsLocationsIds.add(+location.id);
        return location;
      });
    });
    return {
      selectedProjects,
      selectedProjectsIds,
      selectedProjectsLocationsIds:
        selectedProjectsLocationsIds.size > 0
          ? [...selectedProjectsLocationsIds]
          : []
    };
  };

  getProjectsUniqueLocationsIds = (projectsArray: IProject[]) => {
    const locationsIds: Set<number> = new Set([]);
    projectsArray.map(({ locations }) => {
      locations.map(({ id }) => locationsIds.add(+id));
    });

    return locationsIds.size > 0 ? [...locationsIds] : [];
  };
  getRestCheckedProjectsLocationsIds = (projectId: number) => {
    const { selectedProjects } = this.getSelectedProjectsData();
    const restProjects = selectedProjects.filter(
      ({ id }) => +projectId !== (id as number)
    );

    return this.getProjectsUniqueLocationsIds(restProjects);
  };

  addLocations = (selectedProjectLocationsIds: number[] = []) => {
    const { locationsIds = [] } = this.props.valuesData;
    const locations = [...locationsIds, ...selectedProjectLocationsIds];
    const updatedLocationsList =
      locations.length === 0 ? [] : new Set([...locations]);
    this.props.change("locationsIds", [...updatedLocationsList]);
  };

  checkIdExistInArray = (locationsIdsArray: number[] = [], id: number) => {
    return locationsIdsArray.some(locationId => locationId === id);
  };

  removeLocations = (
    projectId: number,
    selectedProjectLocationsIds: number[]
  ) => {
    const { locationsIds } = this.props.valuesData;
    const restSelectedProjectsLocation = this.getRestCheckedProjectsLocationsIds(
      projectId
    );

    const idExistInSelectedProjectLocations = (id: number) =>
      this.checkIdExistInArray(selectedProjectLocationsIds, id);
    const idExistInRestSelectedProjectsLocations = (id: number) =>
      this.checkIdExistInArray(restSelectedProjectsLocation, id);

    const locationsList = locationsIds.filter(id => {
      if (idExistInRestSelectedProjectsLocations(id)) {
        return true;
      }
      if (
        idExistInSelectedProjectLocations(id) &&
        !idExistInRestSelectedProjectsLocations(id)
      ) {
        return false;
      }

      return true;
    });
    this.props.change("locationsIds", [...locationsList]);
  };

  updateListOfAllowedLocations = (projectId: number, isChecked: boolean) => {
    const projectLocations = this.getProjectsById(projectId);
    const selectedProjectLocationsIds = projectLocations.map(({ id }) => +id);
    if (!isChecked) {
      this.addLocations(selectedProjectLocationsIds);
    } else {
      this.removeLocations(projectId, selectedProjectLocationsIds);
    }
  };

  getAllLocationsList = (): SelectLocationItemType[] => {
    const { locationsIds = [] } = this.props.valuesData;
    const { selectedProjectsLocationsIds } = this.getSelectedProjectsData();

    const allLocations = [...locationsIds, ...selectedProjectsLocationsIds];
    return this.props.locations.map((location: ILocation) => {
      const locationId = location.id as number;
      return {
        id: locationId,
        name: location.name as string,
        isChecked: allLocations.some(id => +id === +locationId)
      };
    });
  };

  render() {
    const {
      currentUserPermissions = [],
      group,
      isCreateUser,
      valuesData = {
        projectsIds: [],
        locationsIds: []
      },
      projects
    } = this.props;

    const { projectsIds = [], locationsIds = [] } = valuesData;
    const projectsList = this.getProjectsList(projects, projectsIds);
    const locationsList = this.getAllLocationsList();

    return (
      <div>
        {isCreateUser ? (
          <FieldGeneric
            name="userGroup"
            margin="normal"
            fullWidth
            inline={true}
            component={ReduxRadioButton}
          >
            <FormControlLabel
              value={Group.PLANNER}
              control={<Radio />}
              label="Planner"
              onClick={this.plannerClickHandler}
              disabled={this.disableRadio(currentUserPermissions, group)}
            />
            <FormControlLabel
              value={Group.VIEWER}
              control={<Radio />}
              label="Viewer"
              onClick={this.viewerClickHandler}
              disabled={this.disableRadio(currentUserPermissions, group)}
            />
          </FieldGeneric>
        ) : (
          !this.disableRadio(currentUserPermissions, group) && (
            <FieldGeneric
              name="userGroup"
              margin="normal"
              fullWidth
              inline={true}
              component={ReduxRadioButton}
            >
              <FormControlLabel
                value={Group.PLANNER}
                control={<Radio />}
                label="Planner"
                onClick={this.plannerClickHandler}
              />
              <FormControlLabel
                value={Group.VIEWER}
                control={<Radio />}
                label="Viewer"
                onClick={this.viewerClickHandler}
              />
            </FieldGeneric>
          )
        )}
        <Field
          inputProps={{ minLength: 1, maxLength: 50 }}
          name="firstName"
          label="First name *"
          component={ReduxTextField}
          margin="normal"
          className="mt-0"
        />
        <Field
          inputProps={{ minLength: 1, maxLength: 50 }}
          name="lastName"
          label="Last Name *"
          component={ReduxTextField}
          margin="normal"
        />
        <Field
          inputProps={{ minLength: 1, maxLength: 60 }}
          name="email"
          label="Email *"
          component={ReduxTextField}
          onKeyPress={handleSpaceKeyPress}
          margin="normal"
        />
        <Field
          inputProps={{ minLength: 1, maxLength: 20 }}
          name="phone"
          label="Telephone *"
          type={`tel`}
          component={ReduxTextField}
          margin="normal"
          onKeyPress={handleKeyPressOnPhoneField}
        />
        <div>
          <FieldGeneric
            name="projectsIds"
            label="Projects Locations"
            fullWidth
            multiple
            component={ReduxSelectField}
            renderValue={(values: number[]) => {
              return projectsList
                .filter((item: ProjectListItem) =>
                  values.some(value => value === item?.id)
                )
                .map((item: ProjectListItem) => (
                  <Chip key={item.id} label={item.name} className="ml-1" />
                ));
            }}
          >
            <MenuItem>
              <Button color="primary" onClick={this.checkAllProjects}>
                Check all
              </Button>
              <Button color="primary" onClick={this.uncheckAllProjects}>
                Uncheck all
              </Button>
            </MenuItem>
            {projectsList.map(({ id, name, isChecked, locations }) => {
              return (
                <MenuItem value={id} key={id} className="select-option-item">
                  <button
                    type="button"
                    className="select-option-btn"
                    onClick={() =>
                      this.updateListOfAllowedLocations(id, isChecked)
                    }
                  >
                    <Checkbox checked={isChecked} />
                    <ListItemText
                      primary={name}
                      className="select-option-list-item"
                    />
                  </button>
                </MenuItem>
              );
            })}
          </FieldGeneric>
        </div>
        <div>
          <FieldGeneric
            name="locationsIds"
            label="Additional Project Locations"
            fullWidth
            multiple
            component={ReduxSelectField}
            renderValue={(values: number[]) => {
              return locationsList
                .filter((item: SelectLocationItemType) =>
                  values.some(value => value === item.id)
                )
                .map((item: SelectLocationItemType) => (
                  <Chip key={item.id} label={item.name} className="ml-1" />
                ));
            }}
          >
            <div>
              <Button
                color="primary"
                onClick={this.checkAllAdditionalLocations}
              >
                Check all
              </Button>
              <Button
                color="primary"
                onClick={this.uncheckAllAdditionalLocations}
              >
                Uncheck all
              </Button>
            </div>
            {locationsList.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                <Checkbox
                  checked={locationsIds.some(locationId => +locationId === +id)}
                />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </FieldGeneric>
        </div>
        {(group === Group.OWNER ||
          currentUserPermissions.includes(
            Permission.CAN_MANAGE_PLANNER_EXTRA_PERMISSIONS
          )) &&
          this.state.isPlanner && (
            <div
              className="extra-permissions mt-4"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <span>Extra permissions</span>
              <FormSection
                name="dynamicPermissions"
                component={this.renderPermissions}
              />
            </div>
          )}
        <div className="text-right mt-4">
          <Link className="btn-cancel" to={this.props.backRoute}>
            Cancel
          </Link>
          <button type="submit" className="btn-accept">
            Save
          </button>
        </div>
      </div>
    );
  }
}

export default UsersForm;
