import React from "react";
import { Column } from "./DocumentTable";
import { DownloadDocumentColumn } from "./columns/DownloadDocumentColumn";
import { formatDateWithTime } from "helpers/date-formatter";
import { IDocument } from "models/document.interface";
import { DeleteDocumentColumn } from "./columns/DeleteDocumentColumn";
import { ALL_TEST_STATUSES_ARRAY, TestStatus } from "constants/test-status";
import { ITest } from "models/test.interface";

export const documentTableColumns = (
  test: ITest,
  downloadDocumentFile: (test: ITest, doc: IDocument) => void,
  deleteDocumentFile: (test: ITest, doc: IDocument) => void,
): Column<IDocument>[] => [
  {
    key: 'download',
    className: 'td-download',
    render(document) {
      return React.createElement(DownloadDocumentColumn, {
        document,
        downloadDocumentFile: () => {
          downloadDocumentFile(test, document);
        },
      })
    },
  },
  {
    key: 'date',
    className: 'td-date',
    render(document) {
      return formatDateWithTime(document.dateUpload);
    },
  },
  {
    key: 'delete',
    className: 'td-delete',
    render(document) {
      return React.createElement(DeleteDocumentColumn, {
        test,
        document,
        onDelete: () => {
          deleteDocumentFile(test, document);
        },
        requiredStatuses: [
          TestStatus.STATUS_DRAFT,
          TestStatus.STATUS_SCHEDULED,
          TestStatus.STATUS_BOOKING_REQUESTED,
          TestStatus.STATUS_BOOKING_REQUESTED_EDITED,
        ]
      });
    },
  },];

export const additionalDocumentTableColumns = (
  test: ITest,
  downloadDocumentFile: (test: ITest, doc: IDocument) => void,
  deleteDocumentFile: (test: ITest, doc: IDocument) => void,
): Column<IDocument>[] => [
  {
    key: 'download',
    className: 'td-download',
    render(document) {
      return React.createElement(DownloadDocumentColumn, {
        document,
        downloadDocumentFile: () => {
          downloadDocumentFile(test, document);
        },
      })
    },
  },
  {
    key: 'date',
    className: 'td-date',
    render(document) {
      return formatDateWithTime(document.dateUpload);
    },
  },
  {
    key: 'delete',
    className: 'td-delete',
    render(document) {
      return React.createElement(DeleteDocumentColumn, {
        test,
        document,
        onDelete: () => {
          deleteDocumentFile(test, document);
        },
        requiredStatuses: ALL_TEST_STATUSES_ARRAY,
      });
    },
  },
];
