import {useState} from "react";
import {AdditionalDocumentType} from "../types";
import {getReportAdditionalDocuments} from "../api/requests/reports";

export const useAdditionalDocumentsList = () => {
  const [additionalDocumentsList, setAdditionalDocumentsList] = useState<Array<AdditionalDocumentType>>([]);
  const getAdditionalDocumentsItems = async (testId: string | number) => {
    const data = await getReportAdditionalDocuments(testId);
    setAdditionalDocumentsList(Object.values(data));
  }

  return {additionalDocumentsList, getAdditionalDocumentsItems };
}