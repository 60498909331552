import {extractJSONLD, fetchRequestBody} from '../../helpers/request-helper';
import {getApi, getApiJSON, getApiJSONLD} from '../swagger/api-factory';
import {ICertificate} from "../../models/certificate.interface";
import {convertorFromSwagger as defaultConvertorFromSwagger} from '../convertors/default-convertor';
import {ITesterProfile} from "../../models/tester-profile.interface";
import {ISpecialism} from "../../models/specialism.interface";
import {IBankDatails} from "../../models/bank-datails.interface";
import {ACTIVE} from "../../constants/filter";
import {IAnyProps} from "../../interfaces/any-props.interface";
import {converterToSwagger, converterFromSwagger} from "../convertors/tester-profile";
import {IInsurance} from "../../models/insurance.interface";

export const requestAccountDeletion = () => {
  return getApi()
    .then(api => api.postApiAccountDelete({}))
    .then(fetchRequestBody);
};

export const requestOwnTesterProfile = (): Promise<ITesterProfile> => {

  return getApiJSON()
    .then(api => api.getTesterProfileCollection())
    .then(fetchRequestBody)
    .then((data: ITesterProfile[]) => {
      return converterFromSwagger(data[0]);
    });
};

export const putTesterProfile = (id: string | number, profile: IAnyProps): Promise<IAnyProps> => {
  return getApiJSON()
    .then(api => api.putApiTesterProfilesByIdUpdate({
      id,
      testerSettings: converterToSwagger(profile),
    }))
    .then(fetchRequestBody);
};

export const putTesterSpecialisms = (ids: string[]): Promise<IAnyProps> => {
  return getApiJSON()
    .then(api => api.putApiTesterSpecialisms({
      specialisms: {specialisms: ids.map(a => +a)},
    }))
    .then(fetchRequestBody);
};

export const requestOwnTesterSpecialisms = (): Promise<ISpecialism[]> => {
  return getApi()
    .then(api => api.getApiTesterSpecialisms())
    .then(fetchRequestBody);
};

export const requestOwnTesterBankDetails = (): Promise<IBankDatails> => {
  return getApi()
    .then(api => api.getApiBankDetails())
    .then(fetchRequestBody);
};

export const requestCertificatesProfile = (id: string | number): Promise<ICertificate[]> => {
  return getApiJSONLD()
    .then(api => api.api_tester_profiles_certificates_get_subresource({id, show: ACTIVE}))
    .then(extractJSONLD(defaultConvertorFromSwagger))
    .then(data => data.items);
};

export const requestDBSCertificatesProfile = (id: string | number): Promise<ICertificate[]> => {
  return getApiJSONLD()
    .then(api => api.api_tester_profiles_dbs_checks_get_subresource({id, show: ACTIVE}))
    .then(extractJSONLD(defaultConvertorFromSwagger))
    .then(data => data.items);
};

export const requestAccountUpdate = (email, phone, firstName, lastName): Promise<ICertificate[]> => {
  return getApi()
    .then(api => api.putApiTesterAccount({accountData: {email, telephone: phone, firstName, lastName}}));
};

export const requestInsurancesProfile = (id: string | number): Promise<IInsurance[]> => {
  return getApiJSONLD()
    .then(api => api.api_tester_profiles_insurances_get_subresource({id, show: ACTIVE}))
    .then(extractJSONLD(defaultConvertorFromSwagger))
    .then(data => data.items);
};
