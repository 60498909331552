import { REQUEST_IN_PROCESS } from '../constants/action-type';

export const removeInArray = (array, item) => {
  let index = array.indexOf(item);

  index = index < 0 ? 0 : index;

  return [
    ...array.slice(0, index),
    ...array.slice(index + 1),
  ]
};

export default function(state = {
  inProcess: false,
  items: [],
}, action) {
  switch (action.type) {
    case REQUEST_IN_PROCESS:
      if (!action.payload.inProcess) {
        const items = removeInArray(state.items, action.requestType).filter(a => !!a);

        return {
          ...state,
          items,
          inProcess: action.payload.inProcess,
        };
      }

      return {
        ...state,
        items: [...state.items, action.payload.requestType],
        inProcess: action.payload.inProcess,
      };
    default:
      return state;
  }
}
