import * as React from 'react';
import Paper from "@material-ui/core/Paper/Paper";
import {connect} from 'react-redux';
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Radio from "@material-ui/core/Radio/Radio";
import selector from '../../../../selectors/carousel';
import {carouselFilter} from "../../../../actions/carousel";
import {formValueSelector, InjectedFormProps, reduxForm} from 'redux-form';
import ReduxRadioButton from '../../../../components/forms/ReduxRadioButton';
import {FieldGeneric} from '../../../../components/forms/FieldGeneric';
import * as FilterTypes from '../../../../constants/filter';
import {bindActionCreators} from 'redux';
import {isChangesExist} from "../../../../helpers/props-checker";

const FORM_NAME = 'CarouselSearchForm';
const selectorForm = formValueSelector(FORM_NAME);


class CarouselSearchForm extends React.Component<InjectedFormProps<{}, {} > & {
  submitFilterChanges: (...any) => void,
  valuesData: {active: string, text?: string},
}, any> {
  componentWillReceiveProps(nextProps) {
    if (isChangesExist(['valuesData'], nextProps, this.props)) {
      this.props.submitFilterChanges(nextProps.valuesData.active, nextProps.valuesData.text);
    }
  }

  render() {
    const {handleSubmit} = this.props;

    return (
      <form
        onSubmit={handleSubmit((values: {active: string}) => {
          this.props.submitFilterChanges(values.active);
        })}>
        <Paper className="p-4">
          <div className="row">
            <div className="col-md-12">
              <FieldGeneric
                className="p-0"
                name="active"
                margin="normal"
                fullWidth
                inline={true}
                component={ReduxRadioButton}
              >
                <FormControlLabel
                  value={FilterTypes.ACTIVE}
                  control={<Radio/>}
                  label="Active"/>
                <FormControlLabel
                  value={FilterTypes.ARCHIVED}
                  control={<Radio/>}
                  label="Archived"/>
                <FormControlLabel
                  value={FilterTypes.ALL}
                  control={<Radio/>}
                  label="All"/>
              </FieldGeneric>
            </div>
          </div>
        </Paper>
        <br/>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    valuesData: { ...selectorForm(state, 'text', 'active') },
    initialValues: {
      find: selector.getFilterTextFields(state),
      active: selector.getFilterActivityStatus(state),
    },
  };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    submitFilterChanges: carouselFilter.submitFilterChanges,
  }, dispatch);
};

const connectForm = reduxForm({
  form: FORM_NAME,
})(CarouselSearchForm);

export default connect(mapStateToProps, mapDispatchToProps)(connectForm);
