import * as React from "react";
import {
  Link,
  Route,
  RouteComponentProps,
  Switch,
  withRouter
} from "react-router-dom";
import DocumentCreate from "./DocumentCreate";
import PermissionBlock from "components/auth/PermissionBlock";
import * as Roles from "constants/roles";
import { TestStatus } from "constants/test-status";
import TestStatusBlock from "components/auth/TestStatusBlock";
import { ITest } from "models/test.interface";
import DocumentTables from "containers/client-tester/documents/DocumentTables";

type Props = RouteComponentProps<{ test: string }> & { test: ITest };

class DocumentsBlock extends React.Component<Props> {
  baseUrl = this.props.match.url;
  basePath = this.props.match.path;

  createDocument = () => (
    <DocumentCreate backRoute={this.baseUrl} test={this.props.test} />
  );

  render() {
    const { test } = this.props;

    return (
      <div className="styled-block">
        <div className="block-header-wrapper">
          <h4 className="block-header">Documents</h4>
          <PermissionBlock roles={[Roles.ROLE_PLANNER]}>
            <TestStatusBlock
              currentStatus={test.status}
              requiredStatuses={[
                TestStatus.STATUS_DRAFT,
                TestStatus.STATUS_SCHEDULED,
                TestStatus.STATUS_BOOKING_REQUESTED,
                TestStatus.STATUS_BOOKING_REQUESTED_EDITED,
                TestStatus.STATUS_PROPOSAL_SUBMITTED,
                TestStatus.STATUS_PROPOSAL_SUBMITTED_CANCELLED_BY_CUSTOMER,
                TestStatus.STATUS_PROPOSAL_SUBMITTED_CANCELLED_BY_TESTER,
                TestStatus.STATUS_BOOKED,
                TestStatus.STATUS_REPORT_SUBMITTED,
                TestStatus.STATUS_REPORT_ACCEPTED
              ]}
            >
              <Link to={this.baseUrl + "/new-test-document"}>
                <button className="btn-new">New document</button>
              </Link>
            </TestStatusBlock>
          </PermissionBlock>
        </div>
        <DocumentTables test={test} />
        <Switch>
          <Route
            path={this.basePath + "/new-test-document"}
            render={this.createDocument}
          />
        </Switch>
      </div>
    );
  }
}

export default withRouter(DocumentsBlock);
