import {createSelector} from 'reselect';
import { RootState } from "reducers";

export const getById = (id) => state => {
  return state.find(element => +element.id === +id);
};

type CrudOptions<T extends Object> = {
  // todo add filter types
  filter,
  items: {
    items: T[],
    loader: {
      isLoaded: boolean,
      itemsPerPage?: number,
      pageNumber: number,
      totalItems: number,
    }
  },
}

/** Filter */
function getCrudSelectors<T>(mainSelector: (state: RootState) => CrudOptions<T>) {
  const getFilter = createSelector(
    mainSelector,
    state => state.filter
  );

  const getFilterText = state => state.field;
  const getFilterActive = state => state.conditions.active;

  const getItemsObject = createSelector(
    mainSelector,
    state => state.items
  );

  /** Common */
  const getItems = createSelector(
    getItemsObject,
    state => state.items
  );

  const getLoader = createSelector(
    getItemsObject,
    state => state.loader
  );

  const getItemById = (id) => createSelector(
    getItems,
    getById(id)
  );
  /** Common */

  /** Loader */
  const getIsLoaded = createSelector(
    getLoader,
    state => state.isLoaded
  );

  const getPageNumber = createSelector(
    getLoader,
    state => state.pageNumber
  );

  const getItemsPerPage = createSelector(
    getLoader,
    state => state.itemsPerPage
  );

  const getTotalItems = createSelector(
    getLoader,
    state => state.totalItems
  );
  /** Loader */

  /** Filter */
  const getFilterTextFields = createSelector(
    getFilter,
    getFilterText
  );

  const getFilterActivityStatus = createSelector(
    getFilter,
    getFilterActive
  );

  return {
    getItems,
    getItemById,
    getItemsObject,
    getIsLoaded,
    getPageNumber,
    getItemsPerPage,
    getTotalItems,
    getFilter,
    getFilterTextFields,
    getFilterActivityStatus,
  }
}

export default getCrudSelectors;
