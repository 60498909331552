import * as React from "react";
import {
  composeValidators,
  fieldMaxLength,
  fieldMinLength,
  fieldsNotEmpty
} from "../../../helpers/validations/validation";
import { InjectedFormProps, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { result } from "lodash";
import parameterFetcher from "../../../components/parametersFetcher";
import routerModal from "../../../components/routerModal";
import selector from "../../../selectors/risks";
import {
  postItemAction,
  riskTreatmentsRequest
} from "../../../actions/risk-treatments";
import RiskTreatmentForm from "./RiskTreatmentForm";
import { ICriticalRisk } from "../../client/dashboard/DashboardCriticalRisks";
import { MIN_FIELD_LENGTH, MAX_FIELD_LENGTH } from "./const";

const FORM_NAME = "RiskTreatmentCreate";

export interface IRiskTreatmentFormValues {
  action: number;
  comment: string;
  residualRiskRating: number;
  risk: ICriticalRisk;
  status: number;
}

class RiskTreatmentCreate extends React.Component<
  { match: { params: { id: string } } } & InjectedFormProps<
    IRiskTreatmentFormValues,
    {}
  > & {
      postData: (value: {}, formName: string, backRoute: string) => void;
      backRoute: string;
    }
> {
  render() {
    const { handleSubmit, postData, backRoute, initialValues } = this.props;

    return (
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit((values: IRiskTreatmentFormValues) => {
          postData(values, FORM_NAME, backRoute);
        })}
      >
        <RiskTreatmentForm
          backRoute={backRoute}
          initialValues={initialValues}
        />
      </form>
    );
  }
}

const formConnected = reduxForm({
  form: FORM_NAME,
  validate: composeValidators(
    fieldsNotEmpty(["action", "residualRiskRating", "comment", "status"]),
    fieldMinLength(["comment"], MIN_FIELD_LENGTH),
    fieldMaxLength(["comment"], MAX_FIELD_LENGTH)
  )
})(RiskTreatmentCreate);

const connected = parameterFetcher(
  connect(
    (state: any, props: any) => {
      const risk = selector.getItemById(
        result(props, "match.params.id", false)
      )(state);

      return risk
        ? { initialValues: { risk } }
        : { initialValues: { risk: {} } };
    },
    {
      postData: postItemAction
    }
  )(formConnected),
  FORM_NAME,
  riskTreatmentsRequest.getItem,
  selector.getItemById
);

export default routerModal(connected);
