import * as React from "react";
import { Modal } from "@material-ui/core";
import ReduxTextField from "../../../components/forms/ReduxTextField";
import { Field, reduxForm } from "redux-form";
import {composeValidators, fieldsNotEmpty, fieldIsEmail} from "../../../helpers/validations/validation";
import { sendInviteToTester } from "../../../actions/users";
import {connect} from "react-redux";

interface IStateToProps {
  visible: boolean;
  onClose: any;
  sendInvite: any;
}

class InviteTesterModal extends React.Component<IStateToProps & any> {
  handleSubmit=(email)=> {
    this.props.sendInvite(email);
    this.handleClose();
  }

  render() {
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.props.visible}
        onClose={this.handleClose}
        className="d-flex overflow-auto"
      >
        <div className="styled-modal sm">
          <h4>Invite AVORD Tester</h4>
          <p>Please enter email address of existing on the platform tester and press the send button. We will send an email with further instructions:</p>
          <hr />
          <form noValidate autoComplete="off" onSubmit={this.props.handleSubmit((values)=>this.handleSubmit(values.email))}>
            <Field
              name="email"
              label="Email"
              margin="normal"
              component={ReduxTextField}
            />
            <div className="text-right" style={{ marginTop: "10px" }}>
              <button onClick={this.handleClose} className="btn-cancel">Cancel</button>
              <button type="submit" className="btn-save">Send</button>
            </div>
          </form>
        </div>
      </Modal>
    );
  }
  private readonly handleClose = () => {
    this.props.onClose();
    this.props.untouch("email");
    this.props.change("email", "");
  };
}

const connected= connect<any>(
  state => ({}),
  {
    sendInvite: sendInviteToTester
  }
)(InviteTesterModal);
const formed = reduxForm({
  form: "inviteTesterForm",
  validate: composeValidators(
    fieldIsEmail(['email' ]),
    fieldsNotEmpty(["email"]),
  ),
})(connected);

export default formed as any;
