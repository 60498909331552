import React from "react";
import {
  ROLE,
  ROLE_OWNER,
  ROLE_ADMIN,
  ROLE_PROJECT_CONSULTANT,
  ROLE_PLANNER,
  ROLE_ACCOUNT_MANAGER
} from "../constants/roles";
import {
  TE_STATUS_ARRAY,
  TE_STATUS_NAMES,
  TE_STATUS
} from "constants/timesheet-expenses";
import {
  AssignedProjectsType,
  ReducedWeekType,
  TimeExpensesCategory,
  TimeExpensesWeekType,
  TimeSheetsFilterParamsType,
  WeekDayDataHelperType,
  WeekDayType
} from "types/timesheets-and-expenses";
import {
  formatDateWithSlashesDdMmYyyy,
  getCurrentYearWeek,
  getIsActiveDate,
  getIsCurrentWeek,
  getIsWeekOfActiveSearchMonth,
  getWeekDates,
  getWeekStartAndEndDates,
  getYearWeek,
  getYearWeekOfPreviousWeek
} from "./date-formatter";
import { getYearMonth } from "components/forms/YearMonthPicker/helpers";
import { ACTIVE, ALL, ARCHIVED, FILTER_TYPE } from "constants/filter";
import { getIsActiveAsPreviousWeek } from "./date-timezone";
import moment from "moment-timezone";

export const getUserIsAdmin = (roles: ROLE[]): boolean =>
  roles.some((role: ROLE): boolean => role === ROLE_ADMIN);
export const isUserAccountManager = (roles: ROLE[]): boolean =>
  roles.some((role: ROLE): boolean => role === ROLE_ACCOUNT_MANAGER);
export const getUserIsOwner = (roles: ROLE[]): boolean =>
  roles.some((role: ROLE): boolean => role === ROLE_OWNER);
export const containPlannerRole = (roles: ROLE[]): boolean =>
  roles.some((role: ROLE): boolean => role === ROLE_PLANNER);
export const getUserIsConsultant = (roles: ROLE[]): boolean =>
  roles.some((role: ROLE): boolean => role === ROLE_PROJECT_CONSULTANT);

export const isOptionSelected = (selectedOptions: number[], id: number) =>
  selectedOptions.some((optionId: number): boolean => optionId === id);

type CheckAllParamsType = {
  allOptionsIds: number[];
  fieldName: string;
  change: (field: string, optionIds: number[]) => void;
};

type SelectOptionType = {
  id: number;
  name: string;
};

type JoinOptionsLabelsType = {
  selectedOptionsIds: number[];
  selectOptions: SelectOptionType[];
};

export const checkAllOptions = (
  e: React.MouseEvent<HTMLElement, MouseEvent>,
  params: CheckAllParamsType
) => {
  const { allOptionsIds, fieldName, change } = params;
  e.stopPropagation();
  change(fieldName, allOptionsIds);
};

export const joinSelectedOptionsLabels = ({
  selectedOptionsIds,
  selectOptions
}: JoinOptionsLabelsType): string => {
  if (!Array.isArray(selectOptions) || !selectedOptionsIds.length) {
    return "";
  }

  const selectedOptionsLabels: string = selectOptions
    .filter((option: SelectOptionType) =>
      selectedOptionsIds.some((optionId: number) => optionId === option?.id)
    )
    .map(option => option.name)
    .join(", ");

  return selectedOptionsLabels.length > 30
    ? `${selectedOptionsLabels.slice(0, 30)}...`
    : selectedOptionsLabels;
};

export const getTEStatusIdByName = (
  title: TE_STATUS_NAMES
): TE_STATUS | undefined => {
  return TE_STATUS_ARRAY.find(item => item.name === title)?.id;
};
export const getTEStatusNameById = (
  id: TE_STATUS
): TE_STATUS_NAMES | undefined => {
  return TE_STATUS_ARRAY.find(item => item.id === id)?.name;
};

export const createDayObject = (
  date: string,
  project: number,
  consultant: number
): WeekDayType => ({
  day: `${date}-${project}-${consultant}`,
  consultant,
  project,
  date,
  timesheetDocuments: []
});

function sortWeeksObject(obj: Record<string, TimeExpensesWeekType[]>) {
  const sortedKeys: string[] = Object.keys(obj).sort((a, b) => +b - +a);
  const sortedValues: ReducedWeekType[] = sortedKeys.map(key => ({
    yearWeek: key,
    projectsWeeks: obj[key]
  }));
  return sortedValues;
}

export function reduceArrayByWeekNumber(data: TimeExpensesWeekType[]) {
  const reducedData = data.reduce((accumulator, currentValue) => {
    const weekNumber = currentValue.weekNumber;

    if (!accumulator[weekNumber]) {
      accumulator[weekNumber] = [];
    }

    accumulator[weekNumber].push(currentValue);

    return accumulator;
  }, {});

  return sortWeeksObject(reducedData);
}

export const getWeekTitle = (year: number, weekNumber: number): string => {
  const { startDate, endDate } = getWeekStartAndEndDates(year, weekNumber);

  return `${startDate} - ${endDate}. Week #${weekNumber}`;
};

export const getYearAndWeekNumber = (
  yearWeek: string
): { year: number; weekNumber: number } => {
  const year = yearWeek.substring(0, 4);
  const weekNumber = yearWeek.substring(4);

  return {
    year: +year,
    weekNumber: +weekNumber
  };
};

export const getConsultantWeekIsActive = (
  yearWeek: string,
  subtractWeeks: number = 1
): boolean => {
  const { year, weekNumber } = getYearAndWeekNumber(yearWeek);
  const isCurrentWeek = getIsCurrentWeek(year, weekNumber);
  const isActiveAsPreviewsWeek = getIsActiveAsPreviousWeek(
    year,
    weekNumber,
    subtractWeeks
  );

  return isCurrentWeek || isActiveAsPreviewsWeek;
};

export const createEmptyProjectsWeeks = (
  yearWeek: string,
  projectsList: AssignedProjectsType[]
) => {
  return projectsList.map(
    (project: AssignedProjectsType): TimeExpensesWeekType => {
      const { year, weekNumber } = getYearAndWeekNumber(yearWeek);
      const weekDates = getWeekDates(year, weekNumber);
      const days = weekDates.map(weekDate =>
        createDayObject(weekDate, project.id, project.consultantId)
      );
      return {
        consultant: project.consultantId,
        consultantName: project.consultantName,
        consultantUserId: 0,
        days: days,
        manager: null,
        managerInfo: null,
        notesThread: [],
        project: project.id,
        projectName: project.name,
        status: 0,
        weekNumber: +yearWeek,
        yearWeek: `${year}-${weekNumber}-${project.id}-${project.consultantId}`,
        companyId: 0,
        companyName: "",
        isActiveWeek: true,
        hasLoggedDays: false,
        purchaseOrderNumber: null,
        expensesPurchaseOrderNumber: null,
        invoiceNumber: null,
        expensesInvoiceNumber: null
      };
    }
  );
};

export const getDateFromDayParam = (dayData: string) => {
  const [year, month, day] = dayData.split("-");
  return `${year}-${month}-${day}`;
};

export const getTotalDaysAndExpenses = (
  days: WeekDayType[]
): { totalDays: number; totalExpensesAmount: number } => {
  let totalDays = 0;
  let totalExpensesAmount = 0;
  for (const day of days) {
    if (day?.days) {
      totalDays = totalDays + Number(day.days);
    }
    if (day?.expensesAmount) {
      const vat = day?.vat ?? 0;
      totalExpensesAmount = totalExpensesAmount + day.expensesAmount + vat;
    }
  }

  return {
    totalDays,
    totalExpensesAmount
  };
};

export const getWeekDayData = (weekDay: string): WeekDayDataHelperType => {
  const [year, month, day, projectId, consultantId] = weekDay.split("-");
  const date = `${year}-${month}-${day}`;
  return {
    year,
    month,
    day,
    date,
    weekDay,
    projectId: +projectId,
    consultantId: +consultantId,
    projectRoute: `/api/projects/${projectId}`,
    consultantRoute: `/api/consultants/${consultantId}`,
    yearWeek: getYearWeek(date)
  };
};

export const getWeekDataById = (id: string) => {
  const [year, week, project, consultant] = id.split("-");
  const weekDates = getWeekDates(+year, +week);
  const activeDays = weekDates.filter(date => getIsActiveDate(date));
  const activeDaysCount = activeDays.length;
  const weekStartDate = weekDates[0];

  return {
    project,
    consultant,
    weekStartDate,
    title: `Time for ${formatDateWithSlashesDdMmYyyy(
      weekStartDate
    )} to ${formatDateWithSlashesDdMmYyyy(weekDates[4])}`,
    activeDaysCount
  };
};

export const getCategoryNameById = (
  id: number,
  categories: TimeExpensesCategory[] = [],
  defaultValue = ""
) => {
  if (!categories.length) {
    return defaultValue;
  }
  return (
    categories?.find(category => category?.id === id)?.name || defaultValue
  );
};
export const getHasApprovedWeeks = (weeks: ReducedWeekType[]): boolean => {
  if (!Array.isArray(weeks) || !weeks.length) {
    return false;
  }
  let projectsWeeksArray: TimeExpensesWeekType[] = [];
  for (const week of weeks) {
    if (week?.projectsWeeks) {
      projectsWeeksArray = [...projectsWeeksArray, ...week.projectsWeeks];
    }
  }
  return projectsWeeksArray.some(
    week => week.status === TE_STATUS.STATUS_APPROVED
  );
};

export const joinEmptyActiveWeeks = (
  weeksItems: ReducedWeekType[],
  projectsList: AssignedProjectsType[],
  searchYearMonth = getYearMonth()
): ReducedWeekType[] => {
  const currentMonth = moment();
  const selectedMonth = moment(searchYearMonth, "MMMM YYYY");

  const currentWeekYearWeek: string = getCurrentYearWeek();

  // console.log(weeksItems);

  const prevWeekYearWeek: string = getYearWeekOfPreviousWeek();
  const searchCurrentWeekMonth = getIsWeekOfActiveSearchMonth({
    yearWeek: currentWeekYearWeek,
    searchYearMonth
  });

  const addEmptyWeekIfNeeded = (
    weekYear: string,
    projectsList: AssignedProjectsType[],
    existingWeek?: ReducedWeekType
  ): ReducedWeekType[] => {
    const projectsWeeks: TimeExpensesWeekType[] = createEmptyProjectsWeeks(
      weekYear,
      projectsList
    );

    if (!existingWeek) {
      return [
        { yearWeek: weekYear, projectsWeeks },
        ...weeksItems.filter(week => week.yearWeek !== weekYear)
      ];
    }

    const updatedProjectsWeeks = projectsWeeks.map(item => {
      const weekIndex = existingWeek.projectsWeeks.findIndex(
        projectWeek => projectWeek.project === item.project
      );
      return weekIndex !== -1 ? existingWeek.projectsWeeks[weekIndex] : item;
    });

    const updatedWeek: ReducedWeekType = {
      yearWeek: weekYear,
      projectsWeeks: updatedProjectsWeeks
    };

    return [
      updatedWeek,
      ...weeksItems.filter(week => week.yearWeek !== weekYear)
    ];
  };

  const currentWeek = weeksItems.find(
    week => week.yearWeek === currentWeekYearWeek
  );

  if (
    searchCurrentWeekMonth &&
    !currentWeek?.projectsWeeks.find(week => week?.days.some(({ id }) => !!id))
  ) {
    weeksItems = addEmptyWeekIfNeeded(
      currentWeekYearWeek,
      projectsList,
      currentWeek
    );
  }

  return weeksItems;
};

export const getArchiveCellTitle = (filter: FILTER_TYPE = ACTIVE): string => {
  let filterStatus = "Archive";
  if (filter === ARCHIVED) {
    filterStatus = "Unarchive";
  }
  if (filter === ALL) {
    filterStatus = "Archive/Unarchive";
  }
  return filterStatus;
};

export const customSelectHandler = (
  name: string,
  change: (field: string, value: any) => void
) => (e?: React.ChangeEvent<any>, value?: string, prevValue?: string) => {
  if (e) {
    e.preventDefault();
    if (value !== prevValue) {
      change(name, value);
    } else {
      change(name, "");
    }
  }
};

export const consultantCanEditDay = (status: TE_STATUS): boolean => {
  const availableStatuses = [
    TE_STATUS.STATUS_DRAFT,
    TE_STATUS.STATUS_REJECTED,
    TE_STATUS.STATUS_SUBMITTED
  ];

  return availableStatuses.includes(status);
};

export const consultantCanCreateDay = (status: TE_STATUS): boolean => {
  const availableStatuses = [
    TE_STATUS.STATUS_DRAFT,
    TE_STATUS.STATUS_DEFAULT,
    TE_STATUS.STATUS_REJECTED,
    TE_STATUS.STATUS_SUBMITTED
  ];

  return availableStatuses.includes(status);
};

export const consultantCanViewDay = (status: TE_STATUS): boolean => {
  const unavailableStatuses = [
    TE_STATUS.STATUS_DRAFT,
    TE_STATUS.STATUS_REJECTED
  ];

  return !unavailableStatuses.includes(status);
};

export const getTimesheetBillFileName = (
  consultantName: string | null,
  weekNumber: string
): string => {
  const consultant = consultantName
    ? consultantName.trim().replace(" ", "_")
    : "";

  return `Bill_for_${consultant}_week_${weekNumber}`;
};

export const getTimesheetQueryParams = (
  filterParams: TimeSheetsFilterParamsType
) => {
  return Object.entries(filterParams).reduce(
    (params: TimeSheetsFilterParamsType, [key, value]) => {
      if (key === "yearMonth" && value) {
        params.yearMonth = value as string;
      } else if (Array.isArray(value) && value.length > 0) {
        params[key] = value;
      }
      return params;
    },
    {}
  );
};
