import {CHANGE_PASSWORD_MODAL_CLOSE, CONTACT_US_FORM_SENDED} from "../constants/action-type";
import {postContactUsData} from "../api/requests/common";
import * as Types from '../constants/action-type'
import {setRequestInProcess} from './request';
import {reset, stopSubmit} from 'redux-form';
import {openSnackbar} from './snackbar';
import * as Storage from '../helpers/storage-helper';
import {push} from "react-router-redux";
import {IRegistrationTester} from "../models/registration-tester.interface";
import {registrationTesterRequest} from "../api/requests/registration-tester";
import {IAnyProps} from "../interfaces/any-props.interface";
import {result} from 'lodash';
import {IRegistrationClient} from "../models/registration-client.interface";
import {registrationClientRequest} from "../api/requests/registration-client";
import {fetchMyData, invalidateJWTRequest, login} from "../api/requests/auth";
import {postEmail as postEmailRequest} from "../api/requests/reset-password";
import {postPasswords} from "../api/requests/change-password";
import {defaultPermissionsState} from "../reducers/auth";
import {
  ROLE_ADMIN,
  ROLE_OWNER,
  ROLE_PLANNER,
  ROLE_SUPER_ADMIN,
  ROLE_TESTER,
  ROLE_VIEWER,
} from "../constants/roles";
import {loginModalClose, thankYouForRegistrationModalOpen, twoFactorCheckOpen, twoFactorInitOpen} from "./modals";
import {getTwoFactorInit} from "../api/requests/two-factor-auth";
import {getLoginRedirectLinkAndMessage} from "../helpers/login-redirect-by-user-role";

export const postContactUs = (parameters, formName) => async dispatch => {
  dispatch(setRequestInProcess(true, 'postContactUs'));

  if (!parameters.name || !parameters.message || !parameters.email) {
    dispatch(openSnackbar('Please, provide data for your request', 4000));
    dispatch(setRequestInProcess(false, 'postContactUs'));
    return;
  }

  parameters.name = parameters.name.trim();
  parameters.message = parameters.message.trim();

  const data = await postContactUsData(parameters);

  dispatch({type: CONTACT_US_FORM_SENDED, payload: data});
  dispatch({type: Types.MODAL_CONTACT_CLOSE});
  dispatch(reset(formName));
  dispatch(openSnackbar('Sent contact form', 4000));
  dispatch(setRequestInProcess(false, 'postContactUs'));
};

export const redirectToControlPanel = (userRoles, isXeroContactExists = false) => async dispatch => {
  const {loginRedirectLink, loginRedirectMessage} = getLoginRedirectLinkAndMessage(userRoles, isXeroContactExists);

  dispatch(setRequestInProcess(true, 'redirectToControlPanel'));
  dispatch(push(loginRedirectLink));

  if(!!loginRedirectMessage) {
    dispatch(openSnackbar(loginRedirectMessage, 8000));
  }

  dispatch(setRequestInProcess(true, 'redirectToControlPanel'));
};

// Login
export const postLogin = (username, password, formName = '') => async dispatch => {
  dispatch(setRequestInProcess(true, 'postLoginUs'));

  let data;
  try {
    data = await login(username, password);
  } catch (e) {
    dispatch(setRequestInProcess(false, 'postLoginUs'));
    if (result(e, 'response.body.message')) {
      dispatch(openSnackbar('Error: ' + result(e, 'response.body.message'), 4000));
    } else {
      dispatch(openSnackbar('Error: Bad credentials', 4000));
    }
    return;
  }

  Storage.setValue('token', data.token);

  try {
    const twoFactorResponse: {success, code, url} = await getTwoFactorInit();
    dispatch(loginModalClose());
    dispatch(setRequestInProcess(false, 'postLoginUs'));
    dispatch(twoFactorInitOpen(twoFactorResponse.code, twoFactorResponse.url));
  } catch (e) {
    dispatch(setRequestInProcess(false, 'postLoginUs'));
    dispatch(loginModalClose());
    dispatch(twoFactorCheckOpen());
  }
};

export const logOut = () => dispatch => {
  const refreshToken = Storage.getValue('refresh_token');
  Storage.removeValue('token');
  Storage.removeValue('refresh_token');

  dispatch(clearStore());
  dispatch(push('/'));
  dispatch(setGuestPermissionsAction(true));

  /** Will not wait */
  if (refreshToken) {
    invalidateJWTRequest(refreshToken);
  }
};

// reset password
export const resetPassword = () => {
  return {type: Types.RESET_PASSWORD};
};

export const postEmail = (email, formName) => async dispatch => {
  if(!email || !email.length) {
    dispatch(stopSubmit(formName, {email: 'Should be an email'}));

    return;
  }

  dispatch(setRequestInProcess(true, 'postResetPassword'));

  try {
    await postEmailRequest(email);
  } catch (e) {
    dispatch(setRequestInProcess(false, 'postResetPassword'));

    return;
  }

  dispatch(
    openSnackbar(
      'We sent instructions to your email. If you didn\'t receive it check your entered email and try again',
      4000
    )
  );
  dispatch(setRequestInProcess(false, 'postResetPassword'));
};

// change password
export const postChangePassword = (passwords: {
  current_password:string,
  new_password: string,
  repeated_new_password:string
}, email: string, formName: string) => async dispatch => {
  dispatch(setRequestInProcess(true, 'postChangePassword'));

  let data;
  try {
    data = await postPasswords(passwords);
  } catch (e) {
    dispatch(setRequestInProcess(false, 'postResetPassword'));

    if (e.message === 'Unauthorized' ) {
      dispatch(openSnackbar('Wrong credentials', 4000));
    } else if (e.response.status === 400) {
      dispatch(openSnackbar(e.response.body.error, 4000));
    }

    return;
  }

  Storage.setValue('token', data.token);
  Storage.setValue('refresh_token', data.refresh_token);

  const permissions = await fetchMyData();

  dispatch(setPermissionsAction(permissions));
  dispatch({type: CHANGE_PASSWORD_MODAL_CLOSE});
  dispatch(openSnackbar('Password has been changed', 4000));
  dispatch(setRequestInProcess(false, 'postChangePassword'));
  dispatch(reset(formName));
};

// registrationTester
export const registrationTester = (
  model: IRegistrationTester,
  formName: string,
  validationHandler: (error: any) => IAnyProps
) => async dispatch => {
  dispatch(setRequestInProcess(true, 'registrationTester'));

  try {
    await registrationTesterRequest(model);
    dispatch(thankYouForRegistrationModalOpen());
  } catch (e) {
    dispatch(setRequestInProcess(false, 'registrationTester'));

    if (e.response.status === 400) {
      dispatch(stopSubmit(formName, validationHandler(result(e, 'response.body.violations', {}))));
    }

    return;
  }

  dispatch(push('/'));
  dispatch(openSnackbar('Registration completed! Check your email', 4000));
  dispatch(setRequestInProcess(false, 'registrationTester'));
};

// registrationClient
export const registrationClient = (
  model: IRegistrationClient,
  formName: string,
  validationHandler: (error: any) => IAnyProps
) => async dispatch => {
  dispatch(setRequestInProcess(true, 'registrationClient'));

  try {
    await registrationClientRequest(model);
    dispatch(thankYouForRegistrationModalOpen());
  } catch (e) {
    dispatch(setRequestInProcess(false, 'registrationClient'));

    if (e.response.status === 400) {
      dispatch(stopSubmit(formName, validationHandler(result(e, 'response.body.violations', {}))));
    }

    return;
  }

  dispatch(push('/'));
  dispatch(openSnackbar('Registration completed! Check your email', 4000));
  dispatch(setRequestInProcess(false, 'registrationClient'));
};

export const requestAccountDeletingSubmit = () => {
  return {type: Types.REQUEST_ACCOUNT_DELETION_MODAL_SUBMIT};
};

export const testerAccountSubmit = (email: string, phone: string, firstName: string, lastName: string) => {
  return {
    type: Types.TESTER_ACCOUNT_MODAL_SUBMIT,
    payload: {
      email,
      phone,
      firstName,
      lastName
    }
  };
};

export const clientAccountSubmit = (firstName: string, lastName: string, email: string, phone: string) => {
  return {
    type: Types.CLIENT_ACCOUNT_MODAL_SUBMIT,
    payload: {
      firstName,
      lastName,
      email,
      phone,
    }
  };
};

export const setPermissionsAction = (data) => {
  return {type: Types.SET_PERMISSIONS, payload: {...data, isLoaded: true}};
};

export const setGuestPermissionsAction = (isLoaded = false) => {
  return {
    type: Types.SET_PERMISSIONS,
    payload: {
      ...defaultPermissionsState,
      isLoaded,
    },
  };
};

export const clearStore = () => {
  return {
    type: Types.CLEAR_STORE,
    payload: {}
  };
};