import React from 'react';
import {Field} from "redux-form";
import ReduxTextField from "../../../../components/forms/ReduxTextField";
import Grid from "@material-ui/core/Grid/Grid";
import {Button} from "@material-ui/core";
import {Link} from "react-router-dom";

const SectorForm = ({backRoute}) => {
  return (
    <Grid container spacing={40}>
      <Grid item xs={12}>
        <Field
          name="name"
          label="Name *"
          component={ReduxTextField}
        />
        <br/>
        <br/>
        <div className="d-flex justify-content-between mt-4">
          <Button variant="contained" color="default" component={Link} to={backRoute}>
            Cancel
          </Button>
          <Button type="submit" variant="contained" color="primary">
            Save
          </Button>
        </div>
      </Grid>
    </Grid>
  )
};


export {SectorForm};
