export enum InvoiceStatus {
  INVOICE_STATUS_DRAFT      = 'DRAFT',
  INVOICE_STATUS_SUBMITTED  = 'SUBMITTED',
  INVOICE_STATUS_DELETED    = 'DELETED',
  INVOICE_STATUS_AUTHORISED = 'AUTHORISED',
  INVOICE_STATUS_PAID       = 'PAID',
  INVOICE_STATUS_VOIDED     = 'VOIDED',
}

export const SENT_STATUSES_FOR_SEARCH_FORM = [
  InvoiceStatus.INVOICE_STATUS_DRAFT,
  InvoiceStatus.INVOICE_STATUS_SUBMITTED,
  InvoiceStatus.INVOICE_STATUS_DELETED,
  InvoiceStatus.INVOICE_STATUS_AUTHORISED,
  InvoiceStatus.INVOICE_STATUS_VOIDED
];