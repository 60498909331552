import * as Storage from './storage-helper';
import {ROLE_GUEST} from '../constants/roles';
import {result} from 'lodash';
import * as jwtDecode from 'jwt-decode';
import { REFRESH_TOKEN_KEY, TOKEN_KEY } from "../constants/storage";
import moment from "moment";
import {TOKEN_LEEWAY} from "../constants/request";

export const isExpitedJWT = (jwt = '') => {
  const token = jwt || Storage.getValue(TOKEN_KEY);
  let jwtDecoded;

  try {
    jwtDecoded = jwtDecode(token);

    /**
     * Explanation: Because in some browsers a token is created "in the future" (it can be seen from the iat field),
     * there are leeway, that is subtracted from the token expiration field, to send request to refresh one minute before
     * the token expiration time
     */
    return ((jwtDecoded.exp - TOKEN_LEEWAY) <= moment().utc().unix());
  } catch (e) {
    return false;
  }
};

export const isActiveJWT = (jwt = '') => {
  const token = jwt || Storage.getValue(TOKEN_KEY);
  let jwtDecoded;

  try {
    jwtDecoded = jwtDecode(token);

    return (new Date(jwtDecoded.exp * 1000) > new Date()) && Storage.getValue(REFRESH_TOKEN_KEY);
  } catch (e) {
    return false;
  }
};

export const getRoleFromJWT = (jwt = '') => {
  const token = jwt || Storage.getValue(TOKEN_KEY);
  let jwtDecoded;

  try {
    jwtDecoded = jwtDecode(token);
  } catch (error) {
    return [ROLE_GUEST];
  }

  return result(jwtDecoded, 'roles', [ROLE_GUEST]);
};

export interface IJWTProfile {
  id: string;
  [key: string]: any;
}

export const getProfileFromJWT = (jwt = ''): IJWTProfile | null => {
  const token = jwt || Storage.getValue(TOKEN_KEY);
  let jwtDecoded;

  try {
    jwtDecoded = jwtDecode(token);
  } catch (error) {
    return null;
  }

  return jwtDecoded;
};

