import * as React from "react";
import {withStyles, Modal, Grid, WithStyles} from "@material-ui/core";
import {defaultStyleWidth} from "../../../../styles/material/popup-style";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {bookTesterAction} from "../../../../actions/testers";
import {ITesterProfile} from "../../../../models/tester-profile.interface";
import {LINKS} from "../../../../constants/links";

interface IProps {
  open: boolean;
  onClose: () => void;
  tester: ITesterProfile;
  bookTesterAction: (testId: number, testerId: number) => void;
}

class TestersBookingConfirmationModal extends React.Component<IProps & RouteComponentProps<any> & WithStyles> {
  handleBook = () => {
    const testId = Number(this.props.match.params.test);
    const testerId = Number(this.props.tester.id);

    this.props.bookTesterAction(testId, testerId);
  };

  render() {
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.props.open}
        onClose={this.props.onClose}
        className="d-flex overflow-auto"
      >
        <div className="styled-modal sm">
          <h4>Thank you for using AVORD</h4>
          <p>
            We'll send your booking request to the tester.
            If they have any questions you will get an email notification and you can reply from the bookings tab.
            Once the scope is agreed the tester will submit the number of days the work will take.
            If you agree we'll take payment and hold it in escrow until you confirm the work is complete.
          </p>
          <p>
            By submitting booking request you agree to the following terms and conditions:&nbsp;
            <a href={LINKS.TERMS_AND_CONDITIONS} target="_blank">
              Terms &amp; Conditions
            </a>
          </p>
          <Grid container spacing={16}>
            <Grid item xs={12} className="text-right">
              <button className="btn-cancel" onClick={this.props.onClose}>Cancel</button>
              <button className="btn-continue" onClick={this.handleBook}>Continue</button>
            </Grid>
          </Grid>
        </div>
      </Modal>
    );
  }
}

const connectedComponent = connect<IProps>(
  undefined,
  {
    bookTesterAction,
  }
)(withRouter(TestersBookingConfirmationModal));

export default withStyles(defaultStyleWidth(640))(connectedComponent) as any;
