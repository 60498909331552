import {Modal} from "@material-ui/core";
import * as React from "react";
import {connect} from "react-redux";
import {InjectedFormProps, reduxForm} from "redux-form";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import {History} from "history";
import { getAvordValidateOpenModal } from "../../../../selectors/modal";
import {avordValidationModalClose} from "../../../../actions/modals";
import {adminValidateAction} from "../../../../actions";

export interface IEmptyAdditionalServices {
  testId: number
}

export interface IAdditionalServices {
  test: number;
  name: string;
  description: string;
  daysPercent: string;
  price: string;
  subTypes: string[];
}

interface IAdminBackToTestProps {
  history: History;
  showValidateButton?: boolean;
  testId: number;
  adminValidate: (
    id: number,
    values: IAdditionalServices[] | { testId: number }
  ) => void;
  isOpenModal: boolean;
  closeModal: () => void;
}

interface IFormData {
  additionalServices: IAdditionalServices[]
}

const AvordValidationModal: React.SFC<IAdminBackToTestProps & InjectedFormProps<IFormData>> = (
    {closeModal, isOpenModal, testId, handleSubmit, adminValidate}
) => {

  return (
      <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={isOpenModal}
          onClose={closeModal}
          className="d-flex overflow-auto"
      >
        <div className="styled-modal sm">
          <h4>Are you sure?</h4>
          <hr/>
          <div className="text-right">
            <form
                onSubmit={handleSubmit(
                    async ({additionalServices = []}) => {
                      additionalServices.forEach((service) => {
                        service.test = testId;
                        delete service.subTypes;
                      });
                      const valuesData = !!additionalServices.length ? additionalServices : {testId}
                      adminValidate(testId, valuesData);
                    }
                )}
            >
              <button className="btn-cancel" onClick={closeModal}>
                No
              </button>
              <button className="btn-accept" type="submit">
                Yes
              </button>
            </form>
          </div>
        </div>
      </Modal>
  );
}

function mapStateToProps(state) {
  return {
    isOpenModal: getAvordValidateOpenModal(state),
  };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
      {
        closeModal: avordValidationModalClose,
        adminValidate: adminValidateAction,
      },
      dispatch
  );
};

const connectForm = reduxForm({
  form: "AdditionalServiceEdit",
})(AvordValidationModal as any);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(connectForm as any)) as any;
