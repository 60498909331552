import * as React from 'react';
import {
  composeValidators, fieldMaxLength, fieldMinLength,
  fieldsNotEmpty
} from "../../../helpers/validations/validation";
import {InjectedFormProps, reduxForm} from "redux-form";
import { connect } from "react-redux";
import { workHistoryRequest } from "../../../actions/work-history";
import parameterFetcher from '../../../components/parametersFetcher';
import selector from '../../../selectors/work-history';
import WorkHistoryForm from "./WorkHistoryForm";
import routerModal from '../../../components/routerModal';
import {WithStyles} from "@material-ui/core/styles/withStyles";

const FORM_NAME = 'WorkHistoryUpdate';

class WorkHistoryUpdate extends React.Component<InjectedFormProps<{}, {}> & {
  putData: (...any) => void,
  backRoute: string
} & WithStyles<any>, any> {
  render() {
    const { handleSubmit, putData, backRoute } = this.props;

    return (
      <form noValidate autoComplete="off"
        onSubmit={handleSubmit((values) => {
          putData(values, FORM_NAME, backRoute);
        })}>
        <WorkHistoryForm backRoute={backRoute}/>
      </form>
    )
  }
}

const formConnected = reduxForm({
  form: FORM_NAME,
  validate: composeValidators(
    fieldsNotEmpty(['employer', 'jobTitle', 'dateFrom', 'dateTo',]),
    fieldMinLength(['employer', 'jobTitle',], 3),
    fieldMaxLength(['employer', 'jobTitle',], 55),
    (values) => {
      const error = {dateFrom: 'From should not be after To'};

      return (new Date(values.dateFrom) >= new Date(values.dateTo)) ? error : {};
    },
    (values) => {
      const errorMessage = 'From should be before the current day';

      if(new Date() < new Date(values.dateTo)){
        return {dateTo: errorMessage};
      }

      if(new Date() < new Date(values.dateFrom)){
        return {dateFrom: errorMessage};
      }

      return {};
    },
  )
})(WorkHistoryUpdate);

const connected = parameterFetcher(
  connect(undefined, {
    putData: workHistoryRequest.putItem,
  })(formConnected),
  FORM_NAME,
  workHistoryRequest.getItem,
  selector.getItemById
);

export default routerModal(connected);