import * as React from "react";
import {Paper, WithStyles, withStyles} from "@material-ui/core";
import {withRouter, RouteComponentProps} from "react-router-dom";
import {Typography} from "@material-ui/core";
import {styles} from "../../../../styles/material/tesrer-card";
import {getTesterLocationString} from "../../../../helpers/location-helper";
import {dataFetcher} from "../../../../components/dataFetcher";
import {ISpecialism} from "../../../../models/specialism.interface";
import {result} from "lodash";
import {specialismsRequest} from "../../../../actions/specialisms";
import selector from "../../../../selectors/specialisms";
import {MAX_PAGINATION_RESULTS} from "../../../../constants/loader";
import {ACTIVE} from "../../../../constants/filter";
import {ITesterProfileGet} from "../../../../models/tester-profile.interface";
import {API_SECURE_DOCUMENTS_ENDPOINT_URL, BASE_URL} from "../../../../constants/urls";
import {getTesterPriceRange} from "../../../../helpers/tester-profile";
import Button from "@material-ui/core/Button";
import {connect} from "react-redux";
import {downloadSecureDocument} from "../../../../api/requests/document-download";
import countriesSelector from "../../../../selectors/countries";
import {ICountry} from "../../../../models/dialing-code.interface";
import {countriesRequest} from "../../../../actions/countries";
import {RawHTML} from "../../../../components/RawHTML";
import {replaceNToBr} from "../../../../helpers/converter-helper";

type IProps = RouteComponentProps<any> & WithStyles & {
  specialisms: ISpecialism[];
  downloadSecureDocument: (data: any) => void;
  testerProfile: any & ITesterProfileGet;
  countries: ICountry[];
};

interface IState {
  isLoaded: boolean;
  profile: any & ITesterProfileGet;
}

class Profile extends React.Component<IProps, IState> {
  state: IState = {
    isLoaded: false,
    profile: null
  };

  componentWillMount() {
    this.fetchData();
  }

  fetchData() {
    this.setState({profile: this.props.testerProfile, isLoaded: true});
  }

  componentWillReceiveProps(nextProps: Readonly<IProps>, nextContext: any): void {
    if (nextProps.match.params.id !== this.props.match.params.id) {
      this.setState({isLoaded: false, profile: null});
      this.fetchData();
    }
  }

  downloadFile = (event) => {
    const certificateId = event.currentTarget.dataset.certificateid;

    if (certificateId) {
      this.props.downloadSecureDocument(certificateId);
    }
  };

  getCountryName(stringId: string) {
    const arrayId = stringId.split("/");
    const country = this.props.countries.find((country: ICountry) =>
      country.id === Number(arrayId[arrayId.length - 1])
    );

    return country ? country.name : "";
  }

  getRightsToWorkCountries() {
    const {
      testerProfile,
      countries
    } = this.props;

    let result: string = "";

    if (testerProfile && testerProfile.rightsToWorkCountries) {
      testerProfile.rightsToWorkCountries.map((countryIRI: string) => {
        const countryIRIArray: string[] = countryIRI.split("/");
        const country = countries.find((curCountry: ICountry) =>
          curCountry.id === Number(countryIRIArray[countryIRIArray.length - 1])
        );
        if (country) {
          result = result === ""
            ? country.name
            : result + "; " + country.name;
        }
      });
    }

    return result;
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div>
          Loading...
        </div>
      );
    }

    const rightsToWork: string = this.getRightsToWorkCountries();

    return (
      <Paper className="p-4">
        <Typography variant="headline" className={this.props.classes.header}>
              <span>
                <Typography variant="headline">
                  Tester Profile
                </Typography>
              </span>
        </Typography>
        <hr/>

        <div className="row">
          <div className="col-md-9">
            <div className="table-responsive">
              <table className="table table-borderless">
                <tbody>
                <tr>
                  <th className="w-25">Name</th>
                  <td>{`${this.state.profile.testerFirstName} ${this.state.profile.testerLastName}`}</td>
                </tr>
                <tr>
                  <th>Rate</th>
                  <td>{getTesterPriceRange(this.state.profile)}</td>
                </tr>
                {this.state.profile.vatNumber ?
                  <tr>
                    <th>Vat Number</th>
                    <td>{this.state.profile.vatNumber}</td>
                  </tr> : null}
                {this.state.profile.isGovernmentCleared ?
                  <tr>
                    <th>Government Cleared</th>
                    <td>Yes</td>
                  </tr> : null}
                {this.state.profile.companyCrestCertificate ?
                  <tr>
                    <th>CREST certificate</th>
                    <td>
                      <Button
                        variant="contained"
                        color="primary"
                        data-certificateid={this.state.profile.companyCrestCertificate.replace(API_SECURE_DOCUMENTS_ENDPOINT_URL, "")}
                        onClick={this.downloadFile}>
                        Download
                      </Button>
                    </td>
                  </tr> : null}
                {this.state.profile.checkRegisteredCompany ?
                  <tr>
                    <th>CHECK Scheme ID</th>
                    <td>{this.state.profile.checkRegisteredCompany}</td>
                  </tr> : null}
                {this.state.profile.country ?
                  <tr>
                    <th>Country of origin</th>
                    <td>{this.getCountryName(this.state.profile.country)}</td>
                  </tr> : null}
                {!!rightsToWork &&
                (
                  <tr>
                    <th scope="row">Rights to work</th>
                    <td>{rightsToWork}</td>
                  </tr>
                )
                }
                <tr>
                  <th>Location</th>
                  <td>{getTesterLocationString(this.state.profile)}</td>
                </tr>
                <tr>
                  <th scope="row" className={this.props.classes.th}>
                    {!!this.state.profile.testingCompanyName ? "Testing Company" : "Company"}
                  </th>
                  <td>
                    {!!this.state.profile.testingCompanyName? result(this.state.profile, "testingCompanyName") : result(this.state.profile, "companyName")}
                  </td>
                </tr>
                {this.state.profile.companyNumber &&
                <tr>
                  <th>Company registration number</th>
                  <td>{this.state.profile.companyNumber}</td>
                </tr>
                }

                {this.props.specialisms ?
                  <tr>
                    <th>Specialisms</th>
                    <td>{this.state.profile.specialismsNames.join(", ")}</td>
                  </tr> : null}

                {this.state.profile.linkedinLink ?
                  <tr>
                    <th>LinkedIn profile</th>
                    <td>
                      <a target="_blank" href={this.state.profile.linkedinLink}>
                        LinkedIn
                      </a>
                    </td>
                  </tr> : null}
                <tr>
                  <th scope="row" className={this.props.classes.th}>
                    Bio
                  </th>
                  <td>
                    <div>
                      <RawHTML>
                        {replaceNToBr(this.state.profile.bio ? this.state.profile.bio : "")}
                      </RawHTML>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-md-3 admin-tester-view">
            <span className="rounded-image">
              <img
                className="img-fluid"
                src={this.state.profile.profileImagePath ? BASE_URL + this.state.profile.profileImagePath : "/images/user.svg"}
                alt={`${this.state.profile.testerFirstName} ${this.state.profile.testerLastName}`}
              />
            </span>
          </div>
        </div>
      </Paper>
    );
  }
}

const routered = connect(undefined, {
  downloadSecureDocument
})(withRouter(withStyles(styles as any)(Profile) as any));

export default dataFetcher<IProps>(routered, [
  {
    key: "countries",
    action: () => countriesRequest.getItems(1, 1000, ACTIVE),
    selector: (state, props) => countriesSelector.getItemsObject(state),
    alwaysReceiveFreshData: true,
  },
  {
    key: "specialisms",
    action: () => {
      return specialismsRequest.getItems(1, MAX_PAGINATION_RESULTS, ACTIVE);
    },
    selector: (state, props) => selector.getItems(state),
  },
]) as unknown as React.ComponentClass<{
  testerProfile: any & ITesterProfileGet;
}>;
