import {
  AuditLogFilterParamsType,
  AuditLogRequestParams,
  AuditLogResponseType
} from "../../types";

export const convertorFromSwagger = (
  model: AuditLogResponseType
): AuditLogResponseType => {
  const { id, actor, ipAddress, actionType, note, createdAt } = model;

  return {
    id,
    actor,
    ipAddress,
    actionType,
    note,
    createdAt
  };
};

export const convertorToSwagger = (model: AuditLogFilterParamsType) => {
  let params: AuditLogRequestParams = {
    page: model.pageNumber as number,
    items: model.itemsPerPage as number
  };

  if (!!model?.searchText) {
    params = { ...params, info: model.searchText };
  }
  if (model?.actions && !!model.actions.length) {
    params = { ...params, actionsTypes: model.actions.join() };
  }
  if (model?.dateFrom) {
    params = { ...params, createdAtAfter: model.dateFrom };
  }
  if (model?.dateTo) {
    params = { ...params, createdAtBefore: model.dateTo };
  }

  return params;
};
