import * as React from "react";
import Grid from "@material-ui/core/Grid/Grid";
import { Link } from "react-router-dom";
import { IBackRoute } from "components/routerModal";
import ReduxDocumentFileField from "components/forms/ReduxDocumentFileField";
import { FieldGeneric } from "components/forms/FieldGeneric";
import { dataFetcher } from "components/dataFetcher";

class DocumentForm extends React.Component<IBackRoute> {
  render() {
    const {backRoute} = this.props;

    return (
      <Grid container spacing={40}>
        <Grid item xs={12}>
          <div className="crud-sections-header">
            <h4>Upload new document</h4>
          </div>
          <FieldGeneric
            name="testDocuments"
            label="File"
            component={ReduxDocumentFileField}
          />
          <p>
            File must be less than 50 MB
          </p>
          <div className="text-right mt-4">
            <Link className="btn-cancel" to={backRoute}>
              Cancel
            </Link>
            <button type="submit" className="btn-save">
              Save
            </button>
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default dataFetcher<IBackRoute>(DocumentForm);
