import * as React from 'react';
import {RouteComponentProps, withRouter} from "react-router-dom";
import {reduxForm, InjectedFormProps} from "redux-form";
import {connect} from 'react-redux';
import {withStyles, Modal, Button, WithStyles} from '@material-ui/core';
import Grid from "@material-ui/core/Grid";
import {defaultStyleWidth} from '../../../../styles/material/popup-style';
import {reportDocumentCreateModalClose} from "../../../../actions/modals";
import {getIsOpenModalCreateReportDocument, getModalReportDocumentCreationItem} from "../../../../selectors/modal";
import {FieldGeneric} from '../../../../components/forms/FieldGeneric';
import {createReportDocumentModalSubmit} from "../../../../actions/report-documents";
import {CREATE_REPORT_DOCUMENT_FORM_NAME} from "../../../../constants/forms";
import ReduxDocumentFileField from "../../../../components/forms/ReduxDocumentFileField";
import {IReportDocument} from "../../../../models/report-documents.interface";
import {
  composeValidators,
  fieldsNotEmpty,
  fieldMaxLength,
  fieldMinLength
} from "../../../../helpers/validations/validation";
import ReduxTextField from "../../../../components/forms/ReduxTextField";

interface IStateToProps {
  isOpenModal: any;
  reportDocument: {
    item: IReportDocument
  };
}

interface IDispatchToProps {
  modalClose: () => any;
  modalSubmit: (values, formName, reportDocument, testId, backRoute) => any;
}

class ReportDocumentCreateModal extends React.Component<IStateToProps
  & IDispatchToProps
  & WithStyles<any>
  & RouteComponentProps<any>
  & InjectedFormProps<any>> {

  componentDidMount() {
    if(this.props?.reportDocument?.item?.name) {
      this.props.autofill('name', this.props.reportDocument.item.name);
    }
  }

  render() {
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.props.isOpenModal}
        onClose={this.handleClose}
      >
        <div className={this.props.classes.paper}>
          <div className="crud-sections-header">
            <h4>Upload Report Document</h4>
          </div>
          <hr/>
          <form noValidate autoComplete="off" onSubmit={this.props.handleSubmit((values) => {
            this.props.modalSubmit(
              values,
              CREATE_REPORT_DOCUMENT_FORM_NAME,
              this.props.reportDocument,
              this.props.match.params.test,
              this.props.match.url
            );
          })}>
            <FieldGeneric
              name="name"
              label="Document name *"
              component={ReduxTextField}
            />
            <FieldGeneric
              name="document"
              label="Choose report document *"
              component={ReduxDocumentFileField}
            />
            <br/>
            <Grid container spacing={16}>
              <Grid item xs={6}>
                <Button fullWidth={true} type="submit" variant="contained" onClick={this.props.modalClose}>
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button fullWidth={true} type="submit" variant="contained" color="primary">
                  Continue
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Modal>
    );
  }

  private readonly handleClose = () => {
    this.props.modalClose();
    this.props.reset();
  };
}

function mapStateToProps(state, props) {
  return {
    isOpenModal: getIsOpenModalCreateReportDocument(state),
    reportDocument: getModalReportDocumentCreationItem(state),
  };
}

const connectedComponent = connect<IStateToProps, IDispatchToProps>(mapStateToProps as any, {
  modalClose: reportDocumentCreateModalClose,
  modalSubmit: createReportDocumentModalSubmit,
})(ReportDocumentCreateModal);

const formed = reduxForm({
  form: CREATE_REPORT_DOCUMENT_FORM_NAME,
  validate: composeValidators(
    fieldsNotEmpty(['name', 'document']),
    fieldMinLength(['name'], 2),
    fieldMaxLength(['name'], 100),
  ),
})(connectedComponent);

const routered = withRouter(formed as any);

export default withRouter(withStyles(defaultStyleWidth(420))(routered as any) as any) as any;
