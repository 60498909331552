import * as React from "react";
import Grid from "@material-ui/core/Grid/Grid";
import {connect} from "react-redux";
import {ITesterProfileGet} from "../../../../models/tester-profile.interface";
import {Redirect, withRouter, RouteComponentProps} from "react-router-dom";
import {BASE_URL} from "../../../../constants/urls";
import {putItem} from "../../../../api/requests/favorite-testers";
import PermissionBlock from "../../../../components/auth/PermissionBlock";
import {ROLE_OWNER, ROLE_PLANNER} from "../../../../constants/roles";

type IProps = {
    item: ITesterProfileGet;
    hasBookingButton?: boolean;
  } & RouteComponentProps<any>;

interface IState {
  redirect: boolean;
  isModalOpen: boolean;
}

class ExternalTestersView extends React.Component<IProps, IState> {
  state = {
    redirect: false,
    isModalOpen: false
  };

  handleBook = () => {
    putItem(this.props.item.id)
      .then(() => {
          this.setState({redirect: true});
        }
      );
  };

  render() {
    const { item, hasBookingButton = true } = this.props;

    if (this.state.redirect) {
      return <Redirect push to={`/client/schedule/test/pre-fill/${item.id}`}/>;
    }

    return (
      <div className="styled-block">
        <div className="tester-view pt-3">
          <Grid container spacing={16} justify={"space-between"}>
            <Grid item xs={2} className="profile-picture">
              <div className="picture">
                {
                  !item.profileImagePath
                    ? (
                      <img
                        src="/images/user-large.svg"
                        alt={item.testerFirstName + " " + item.testerLastName}
                        className="rounded-circle img-fluid"
                      />
                    )
                    : (
                      <img
                        src={BASE_URL + item.profileImagePath}
                        alt={item.testerFirstName + " " + item.testerLastName}
                        className="rounded-profile-search img-fluid"
                      />
                    )
                }
              </div>
            </Grid>
            <Grid item xs={7}>
              <h2>
                {item.testerFirstName} {item.testerLastName}
              </h2>
              
              {
                (item.user && item.user.email)
                && (
                  <>
                    <h5>
                      Email
                    </h5>
                    <div>{item.user.email}</div>
                  </>
                )
              }
            </Grid>

            <PermissionBlock
              roles={
                [
                  ROLE_PLANNER,
                  ROLE_OWNER
                ]
              }
            >
            <Grid item xs={3}>
              {
                hasBookingButton && (
                  <div className="d-flex text-right align-items-center float-right">
                    <button className="btn-book" onClick={this.handleBook}>
                      Book
                    </button>
                  </div>
                )
              }
            </Grid>
            </PermissionBlock>
          </Grid>

          <Grid container spacing={16} justify={"space-between"}>
            {
              (item.user && item.user.groupName)
              && (
                <Grid item xs={5}>
                  <h5>Tester Type</h5>
                  <div>{item.user.groupName}</div>
                </Grid>
              )
            }

            {
              item.companyName
              && (
                <Grid item xs={7}>
                  <h5>Tester Company</h5>
                  <div>{item.companyName}</div>
                </Grid>
              )
            }
          </Grid>

          <Grid container spacing={16} justify={"space-between"}>
            <Grid item xs={12}>
              <h5>Comment</h5>
              <div>{item.bio}</div>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default connect()(withRouter(ExternalTestersView));
