import React from 'react';
import Button from "@material-ui/core/Button/Button";
import {Link, Route, Switch} from "react-router-dom";
import CertificateList from "./CertificateList";
import CertificateSearchForm from "./CertificateSearchForm";
import CertificateCreate from "./CertificateCreate";
import CertificateUpdate from "./CertificateUpdate";
import {withRouter} from 'react-router-dom';
import {getCreateLink} from '../../../../helpers/router-helper';

class Certificate extends React.Component {
  render() {
    const baseUrl = this.props.match.path;

    return (
      <div>
        <div className="crud-sections-header">
          <h4>Certificate types</h4>
          <Button component={Link} to={getCreateLink(baseUrl)} variant="contained" color="default">
            New Certificate type
          </Button>
        </div>
        <hr/>
        <Switch>
          <Route
            path={getCreateLink(baseUrl)}
            render={() => <CertificateCreate backRoute={baseUrl}/>}
          />
          <Route
            path={baseUrl + "/:id"}
            render={() => <CertificateUpdate backRoute={baseUrl}/>}
          />
        </Switch>
        <CertificateSearchForm/>
        <CertificateList baseUrl={baseUrl}/>
      </div>
    );
  }
}

export default withRouter(Certificate);
