export enum AdminUrls {
  ADMIN = "/admin",
  SETTINGS = "/admin/settings",
  INVOICES = "/admin/invoices",
  TESTERS = "/admin/testers",
  CLIENTS = "/admin/clients",
  TESTS = "/admin/tests",
  CONSULTANTS = "/admin/consultants",
  TIME_AND_EXPENSES = "/admin/time-and-expenses"
}
