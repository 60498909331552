import React, { useEffect } from "react";
import { ROLE } from "constants/roles";
import WeekTimeLogModalFrom from "./components/forms/WeekTimeLogModalFrom";
import DayTimeLogForm from "./components/forms/DayTimeLogModalForm";
import TimesheetPoNumberForm from "./components/forms/TimesheetPoNumberForm";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import {
  getExpensesTypesAction,
  getTimeCategoriesAction
} from "actions/time-expenses";
import { getRoles, getUserPermissions } from "selectors/auth";
import {
  containPlannerRole,
  getUserIsAdmin,
  getUserIsConsultant,
  getUserIsOwner,
  isUserAccountManager,
} from "helpers/timesheets-expenses";
import {
  TIME_SHEETS_USER_TYPE,
  TimeSheetExpensesContextValueType,
  TimesheetPoNumberRoute,
  TimesheetPoNumberType
} from "types/timesheets-and-expenses";
import { TimeSheetExpensesContext } from "./context/timeSheetsContext";
import ConsultantTimeSheetsComponent from "components/timesheets-and-expenses/components/Consultant/TimesheetPage";
import {
  Route,
  RouteComponentProps,
  Switch,
  withRouter
} from "react-router-dom";
import {
  getAdminViewTimeLogLink,
  getClientViewTimeLogLink,
  getCreateTimeLogLink,
  getCreateTotalTimeLogLink,
  getEditTimeLogLink,
  getConsultantViewTimeLogLink,
  getClientTimesheetPoNumberEdit,
  getAdminTimesheetPoNumberEdit
} from "helpers/router-helper";
import AdminTimeSheetsComponent from "./components/AdminTimeSheetsComponent";
import ClientTimeSheetsComponent from "./components/ClientTimeSheetsComponent";
import DailyTimeExpensesViewModal from "./components/DailyTimeExpensesViewModal";
import { Permission } from "constants/permission";
import { RootState } from "reducers";

type TimeSheetsAndExpensesProps = RouteComponentProps & {
  roles?: ROLE[];
  permissions?: Permission[];
  getTimeCategories: () => void;
  getExpensesTypes: () => void;
};

const TimeSheetsAndExpenses = (props: TimeSheetsAndExpensesProps) => {
  const {
    permissions = [],
    roles = [],
    getTimeCategories,
    getExpensesTypes
  } = props;

  const baseUrl = props.match.path;
  const goBackHandler = () => props.history.push(baseUrl);

  useEffect(() => {
    getTimeCategories();
    getExpensesTypes();
  }, []);

  const createDayTimeLog = () => (
    <DayTimeLogForm goBackHandler={goBackHandler} />
  );
  const viewDayTimeLog = () => <DailyTimeExpensesViewModal />;
  const createWeekTimeLog = () => (
    <WeekTimeLogModalFrom goBackHandler={goBackHandler} />
  );
  const updateTimeSheetPoNumber = () => (
    <TimesheetPoNumberForm
      poNumberType={TimesheetPoNumberType.PO_NUMBER}
      goBackHandler={goBackHandler}
    />
  );
  const updateTimeSheetExpensesPoNumber = () => (
    <TimesheetPoNumberForm
      poNumberType={TimesheetPoNumberType.EXPENSES_PO_NUMBER}
      goBackHandler={goBackHandler}
    />
  );

  const isAdmin = getUserIsAdmin(roles) || isUserAccountManager(roles);
  const isOwner = getUserIsOwner(roles);
  const isPlanner =
    containPlannerRole(roles) &&
    permissions.includes(Permission.CAN_MANGE_TIME_EXPENSES);
  const isConsultant = getUserIsConsultant(roles);

  let userType = TIME_SHEETS_USER_TYPE.CONSULTANT;

  if (isPlanner || isOwner) {
    userType = TIME_SHEETS_USER_TYPE.CLIENT;
  }
  if (isAdmin) {
    userType = TIME_SHEETS_USER_TYPE.ADMIN;
  }

  const contextValue: TimeSheetExpensesContextValueType = {
    isAdmin,
    canAdminManageTimeExpenses: isAdmin ? permissions.includes(Permission.CAN_MANAGE_TIME_EXPENSES) : false,
    isOwner,
    isPlanner,
    isConsultant,
    goBackHandler,
    userType
  };

  const canSeeAdminTimeSheetsComponent = isAdmin || permissions.includes(Permission.CAN_VIEW_TIME_EXPENSES);

  return (
    <TimeSheetExpensesContext.Provider value={contextValue}>
      <div className="pb-4">
        {canSeeAdminTimeSheetsComponent && <AdminTimeSheetsComponent />}
        {(isOwner || isPlanner) && <ClientTimeSheetsComponent />}

        {isConsultant && <ConsultantTimeSheetsComponent />}

        <Switch>
          <Route
            path={getCreateTimeLogLink() + "/:date"}
            render={createDayTimeLog}
          />
          <Route
            path={getEditTimeLogLink() + "/:id"}
            render={createDayTimeLog}
          />
          <Route
            path={getCreateTotalTimeLogLink() + "/:id"}
            render={createWeekTimeLog}
          />
          <Route
            path={getConsultantViewTimeLogLink() + "/:id"}
            render={viewDayTimeLog}
          />
          <Route
            path={getClientViewTimeLogLink() + "/:id"}
            render={viewDayTimeLog}
          />
          <Route
            path={getAdminViewTimeLogLink() + "/:id"}
            render={viewDayTimeLog}
          />
          <Route
            path={
              getClientTimesheetPoNumberEdit(TimesheetPoNumberRoute.PO_NUMBER) +
              "/:id"
            }
            render={updateTimeSheetPoNumber}
          />
          <Route
            path={
              getClientTimesheetPoNumberEdit(
                TimesheetPoNumberRoute.EXPENSES_PO_NUMBER
              ) + "/:id"
            }
            render={updateTimeSheetExpensesPoNumber}
          />
          <Route
            path={
              getAdminTimesheetPoNumberEdit(TimesheetPoNumberRoute.PO_NUMBER) +
              "/:id"
            }
            render={updateTimeSheetPoNumber}
          />
          <Route
            path={
              getAdminTimesheetPoNumberEdit(
                TimesheetPoNumberRoute.EXPENSES_PO_NUMBER
              ) + "/:id"
            }
            render={updateTimeSheetExpensesPoNumber}
          />
        </Switch>
      </div>
    </TimeSheetExpensesContext.Provider>
  );
};

function mapStateToProps(state: RootState) {
  return {
    roles: getRoles(state),
    permissions: getUserPermissions(state)
    //weeksItems: selector.getTimeExpensesWeeks(state)
  };
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      //getTimeExpensesWeeksAction: getTimeExpensesWeeksAction,
      getTimeCategories: getTimeCategoriesAction,
      getExpensesTypes: getExpensesTypesAction
    },
    dispatch
  );
};

const connected = connect(
  mapStateToProps,
  mapDispatchToProps
)(TimeSheetsAndExpenses);

export default withRouter(connected);
