import {getApiJSONLD} from "../swagger/api-factory";
import {fetchRequestBody} from "../../helpers/request-helper";

export interface IResetPasswordConfirmationRequest {
  token: string;
}

export const confirmResetPasswordRequest = async (parameters: IResetPasswordConfirmationRequest) => {
  return await getApiJSONLD()
    .then(api => api.getApiPublicPasswordResetByToken(parameters))
    .then(fetchRequestBody)
};