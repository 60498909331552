import { createContext } from "react";
import {
  TIME_SHEETS_USER_TYPE,
  TimeSheetExpensesContextValueType
} from "types/timesheets-and-expenses";

const defaultValue: TimeSheetExpensesContextValueType = {
  isAdmin: false,
  isOwner: false,
  isConsultant: false,
  goBackHandler: () => null,
  userType: TIME_SHEETS_USER_TYPE.CONSULTANT
};

export const TimeSheetExpensesContext = createContext(defaultValue);
