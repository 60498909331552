import {IConverterFromSwagger, IConverterToSwagger} from "./converter.interface";
import {IReport} from "../../models/report.interface";
import {extractId} from "../../helpers/converter-helper";
import {IAnyProps} from "../../interfaces/any-props.interface";

export const convertorFromSwagger: IConverterFromSwagger<any, any> = (modelIn: IReport & IAnyProps) => {
    const model: Partial<IReport | IAnyProps & {
        reportDocuments?: any;
        createdAt?: any;
    }>  = {...modelIn,};

    if (!!modelIn.reportDocuments && modelIn.reportDocuments.length) {
        model.reportDocuments = modelIn.reportDocuments.map(extractId);
    }

    if (!!modelIn.createdAt) {
        model.createdAt = modelIn.createdAt;
    }

    return model;
};

export const convertorToSwagger: IConverterToSwagger<any, any> = (model: IReport)=> {
    const newModel: Partial<IReport | IAnyProps & {
        reportDocuments?: any;
        createdAt?: any;
    }>  = {...model,};

    if (model.reportDocuments) {
        newModel.reportDocuments = model.reportDocuments.map(a => `/api/report_documents/${a}`);
    }

    return newModel;
};