import React from "react";
import InvoicesList from "./InvoicesList";
import InvoicesSearchForm from "./InvoicesSearchForm";
import { withRouter } from "react-router-dom";

class Invoices extends React.Component<any, {}> {
  baseUrl = this.props.match.path;

  render() {
    return (
      <div className="pb-4">
        <InvoicesSearchForm />
        <InvoicesList baseUrl={this.baseUrl} />
      </div>
    );
  }
}

export default withRouter(Invoices);
