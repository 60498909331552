import * as ActionTypes from "../constants/action-type";
import {
  reducerCrudItems,
  mergeReducers,
  reducerLoaderItems,
  paginationDefaultState, filterItems
} from "../helpers/reducer-helper";
import {combineReducers} from "redux";
import * as FilterTypes from "../constants/filter";

const mainReducer = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const loaderReducer = (state = paginationDefaultState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const filterReducer = (state = {
  field: "",
  conditions: {
    active: FilterTypes.ACTIVE
  }
}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default combineReducers({
  items: combineReducers({
    items: mergeReducers(reducerCrudItems(ActionTypes.FAVORITE_TESTERS), mainReducer),
    loader: mergeReducers(reducerLoaderItems(ActionTypes.FAVORITE_TESTERS), loaderReducer)
  }),
  filter: mergeReducers(filterItems(ActionTypes.FAVORITE_TESTERS), filterReducer)
});
