import * as Types from "../constants/action-type";
import { ROLE, ROLE_GUEST } from "../constants/roles";
import { Permission } from "../constants/permission";
import { Group } from "../constants/group";
import { ConsultantProject } from "../types/timesheets-and-expenses";

export interface IPermissionsState {
  group: Group;
  permissions: Permission[];
  roles: ROLE[];
  isLoaded: boolean;
  firstName: string;
  lastName: string;
  name: string;
  email: string;
  phone: string;
  consultantProfile?: {
    id: number;
    user: string;
    consultantProjects: ConsultantProject[];
    rate: number;
    xeroContactId: number | null;
  };
}

export const defaultPermissionsState: IPermissionsState = {
  group: Group.GUEST,
  permissions: [],
  roles: [ROLE_GUEST],
  isLoaded: false,
  firstName: "",
  lastName: "",
  name: "",
  email: "",
  phone: ""
};

export default function(state = defaultPermissionsState, action) {
  switch (action.type) {
    case Types.SET_PERMISSIONS:
      return { ...action.payload };
    default:
      return state;
  }
}
