import React from "react";
import {Field} from "redux-form";
import ReduxTextField from "../../../../components/forms/ReduxTextField";
import Grid from "@material-ui/core/Grid/Grid";
import {Link} from "react-router-dom";

const LocationsForm = ({backRoute}) => {
  return (
    <Grid container spacing={40}>
      <Grid item xs={12}>
        <Field
          inputProps={{maxLength: 100}}
          name="name"
          label="Name *"
          component={ReduxTextField}
          margin="normal"
        />
        <Field
          inputProps={{maxLength: 255}}
          name="address"
          label="Address *"
          component={ReduxTextField}
          margin="normal"
        />
        <Field
          inputProps={{maxLength: 255}}
          name="addressLine2"
          label="Address line 2"
          component={ReduxTextField}
          margin="normal"
        />
        <Field
          inputProps={{maxLength: 255}}
          name="addressLine3"
          label="Address line 3"
          component={ReduxTextField}
          margin="normal"
        />
        <Field
          inputProps={{maxLength: 50}}
          name="town"
          label="Town/City *"
          component={ReduxTextField}
          margin="normal"
        />
        <Field
          inputProps={{maxLength: 50}}
          name="state"
          label="State/Province"
          component={ReduxTextField}
          margin="normal"
        />
        <Field
          inputProps={{maxLength: 10}}
          name="postCode"
          label="Post/zip code *"
          component={ReduxTextField}
          margin="normal"
        />
        <div className="text-right mt-4">
          <Link className="btn-cancel" to={backRoute}>
            Cancel
          </Link>
          <button type="submit" className="btn-accept">
            Save
          </button>
        </div>
      </Grid>
    </Grid>
  );
};

export {LocationsForm};
