export const convertorFromSwagger = model => {
  if (Array.isArray(model)) {
    return [...model];
  }

  return {...model};
};

export const convertorToSwagger = model => {
  if (Array.isArray(model)) {
    return [...model];
  }

  return {...model};
};
