export function downloadFile(file: File) {
  const element = window.document.createElement('a');

  element.setAttribute('href', URL.createObjectURL(file));
  element.setAttribute('download', file.name);

  element.style.display = 'none';
  window.document.body.appendChild(element);

  element.click();

  window.document.body.removeChild(element);
}
