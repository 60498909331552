import * as React from "react";
import {getTestStatusName, TestStatus} from "../../constants/test-status";
import {WithStyles, withStyles} from "@material-ui/core";

const styles = {
  dot: {
    width: "6px",
    height: "6px",
    borderRadius: "50%",
    backgroundColor: "#FFCC00",
    display: "inline-block",
    lineHeight: "inherit",
    margin: "0 5px 2px 0"
  },
  orange: {
    backgroundColor: "#FFCC00"
  },
  orangeOutlined: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #FFCC00"
  },
  red: {
    backgroundColor: "#FF0000"
  },
  redOutlined: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #FF0000"
  },
  green: {
    backgroundColor: "#99CC66"
  },
  greenOutlined: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #99CC66"
  },
  lightblueOutlined: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #0FC6EE"
  },
  error: {
    backgroundColor: "#000000"
  }
};

interface IProps {
  status: TestStatus;
  noText?: boolean;
}

const StatusBlock = (props: IProps & WithStyles) => {
  const {status, classes, noText} = props;
  let color;

  if (!status) {
    color = styles.error;

    return (
      <span className="status-block">
        <span className={classes.dot} style={color}/>
        No Status
      </span>
    );
  }

  if (
    status === TestStatus.STATUS_BOOKED ||
    status === TestStatus.STATUS_AMENDMENT_RESPONDED ||
    status === TestStatus.STATUS_AMENDMENT_SENT
  ) {
    color = styles.orange;
  }

  if (
    status === TestStatus.STATUS_BOOKING_CANCELLED_BY_CLIENT ||
    status === TestStatus.STATUS_BOOKING_CANCELLED_BY_TESTER ||
    status === TestStatus.STATUS_PROPOSAL_SUBMITTED_CANCELLED_BY_TESTER ||
    status === TestStatus.STATUS_PROPOSAL_SUBMITTED_CANCELLED_BY_CUSTOMER ||
    status === TestStatus.STATUS_REPORT_REJECTED ||
    status === TestStatus.STATUS_BOOKING_REJECTED_BY_CUSTOMER ||
    status === TestStatus.STATUS_BOOKING_REJECTED_BY_TESTER
  ) {
    color = styles.red;
  }

  if (status === TestStatus.STATUS_DRAFT) {
    color = styles.redOutlined;
  }

  if (
    status === TestStatus.STATUS_REPORT_SUBMITTED ||
    status === TestStatus.STATUS_DRAFT ||
    status === TestStatus.STATUS_PROPOSAL_SUBMITTED ||
    status === TestStatus.STATUS_REPORT_ACCEPTED ||
    status === TestStatus.STATUS_COMPLETED
  ) {
    color = styles.green;
  }

  if (status === TestStatus.STATUS_SCHEDULED) {
    color = styles.lightblueOutlined;
  }

  return (
    <span className="status-block">
      <span className={classes.dot} style={color}/>
      {!noText && getTestStatusName(status)}
    </span>
  );
};

export default withStyles(styles)(StatusBlock);
