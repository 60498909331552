import React, { useEffect } from "react";
import { getCreateLink } from "helpers/router-helper";
import ConsultantSearchForm from "../components/ConsultantSearchForm";
import ConsultantsList from "../components/ConsultantsList";
import { Link } from "react-router-dom";
import { AdminUrls } from "constants/admin-urls";
import { bindActionCreators, Dispatch } from "redux";
import { getAllProjects } from "actions/project";
import { connect } from "react-redux";
import { ConnectedProps } from "types";
import { IState } from "../../../../interfaces/state.interface";
import { Permission } from "constants/permission";
import { getUserPermissions } from "selectors/auth";

type Props = PropsFromRedux;

function ListConsultantPage({ getAllProjects, permissions }: Props) {
  useEffect(() => {
    getAllProjects();
  }, []);

  const hasEditPermission = permissions.includes(Permission.CAN_MANAGE_CONSULTANTS);

  return (
    <div className="pb-4">
      <div className="d-flex justify-content-end mb-4">
        {hasEditPermission && (
          <Link to={getCreateLink(AdminUrls.CONSULTANTS)}>
            <button className="btn-new">Add Consultant</button>
          </Link>
        )}
      </div>
      <ConsultantSearchForm />
      <ConsultantsList baseUrl={AdminUrls.CONSULTANTS} />
    </div>
  );
}

function mapStateToProps(state: IState) {
  return {
    permissions: getUserPermissions(state)
  };
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getAllProjects
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ListConsultantPage);
