import * as React from 'react';
import TestersList from "./TestersList";
import {withRouter} from 'react-router-dom';
import SearchForm from "./SearchForm";
import { Grid } from "@material-ui/core";
import Icon from "@material-ui/core/Icon/Icon";
import InviteTesterModal from "./InviteTesterModal";
import CreateTesterModal from "./CreateTesterModal";
import {connect} from "react-redux";
import {IState} from "../../../interfaces/state.interface";

class Testers extends React.Component<any, {}> {
  state = {
    isInviteTesterModalVisible: false,
    isCreateTesterModalVisible: false
  }
  baseUrl = this.props.match.path;

  setModalVisibility (modal, value){
    this.setState({[modal]: value});
  }

  render() {
      const {currentUser} = this.props

      const isCompanyInputManager =
        !!currentUser.isConsultancyCompanyManager;

    return (
      <div>
        {!isCompanyInputManager && (
          <InviteTesterModal
            visible={this.state.isInviteTesterModalVisible}
            onClose={this.setModalVisibility.bind(
              this,
              "isInviteTesterModalVisible",
              false
            )}
          />
        )}
        <CreateTesterModal
          visible={this.state.isCreateTesterModalVisible}
          onClose={this.setModalVisibility.bind(
            this,
            "isCreateTesterModalVisible",
            false
          )}
        />
        <Grid
          item
          xs={12}
          lg={6}
          className="text-right"
          style={{
            maxWidth: "100%",
            marginBottom: "20px",
            color: "#778596",
            fontWeight: 600,
          }}
        >
          <div style={{ display: "inline-flex" }}>
            {!isCompanyInputManager && (
              <div
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={this.setModalVisibility.bind(
                  this,
                  "isInviteTesterModalVisible",
                  true
                )}
              >
                <Icon style={{ marginRight: "5px" }}>link</Icon> Invite AVORD
                tester
              </div>
            )}
            <div
              style={{
                display: "inline-flex",
                alignItems: "center",
                marginLeft: "30px",
                cursor: "pointer",
              }}
              onClick={this.setModalVisibility.bind(
                this,
                "isCreateTesterModalVisible",
                true
              )}
            >
              <Icon style={{ marginRight: "5px" }}>add_circle</Icon> Create
              tester
            </div>
          </div>
        </Grid>
        <SearchForm />
        <TestersList baseUrl={this.baseUrl} />
      </div>
    );
  }
}

function mapStateToProps(state: IState, ) {
    return {
        currentUser: state.auth,
    };
}


const connected = connect(mapStateToProps, null)(Testers);

const routered = withRouter(connected) as React.ComponentClass<{
    backRoute: string;
}>;

export default routered;