import {createLoaderAction} from '../helpers/action-helper';
import {getRequestName} from "../helpers/action-request-helper";
import {INVOICE} from "../constants/action-type";
import {setRequestInProcess} from "./request";
import {handleHttpErrors} from "../helpers/errors/http-error-handler";
import {getInvoicesRequest} from "../api/requests/invoice";
import {changeTestStatusAction} from "./test";
import {TestStatus} from "../constants/test-status";
import {getInvoiceURL} from "../api/requests/test";
import {openSnackbar} from "./snackbar";
import {questionResetAction} from "./question";

export const invoiceLoader = createLoaderAction(INVOICE);

export const getInvoicesAction = (
  page,
  itemsPerPage,
  showStatus,
  no: string,
  dateFrom: Date,
  dateTo: Date,
  client: string,
  po: string,
  status: string,
) => async dispatch => {
  dispatch(setRequestInProcess(true, getRequestName(INVOICE, 'getItems')));
  /** part responsible for a loader state */
  dispatch(createLoaderAction(INVOICE).setIsNotLoaded());

  let data = [], totalItems;

  try {
    ({items: data, totalItems} = await getInvoicesRequest(
      page,
      itemsPerPage,
      showStatus,
      no,
      dateFrom,
      dateTo,
      client,
      po,
      status
    ));
    dispatch(createLoaderAction(INVOICE).setPaginatioData(data, itemsPerPage, totalItems, page));
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(setRequestInProcess(false, getRequestName(INVOICE, 'getItems')));
};

export const book = (testId: number) => async dispatch => {
  dispatch(setRequestInProcess(true, 'book'));

  try {
    await dispatch(changeTestStatusAction(testId, TestStatus.STATUS_BOOKED));

    dispatch(questionResetAction(testId));
  } catch (e) {
    dispatch(openSnackbar("Error: Please complete your payment details within the Admin section of your account", 4000));
  }

  dispatch(setRequestInProcess(false, 'book'));
};

export type IGetInvoiceUrl = (testId) => any;

export const getInvoiceUrl: IGetInvoiceUrl = (testId: number) => async dispatch => {
  dispatch(setRequestInProcess(true, 'getInvoiceUrl'));

  const windowReference = window.open();

  getInvoiceURL(testId).then((data) => {
    if (data && data.xeroInvoiceUrl && windowReference) {
      windowReference.location = data.xeroInvoiceUrl;
    }
  });

  dispatch(setRequestInProcess(false, 'getInvoiceUrl'));
};