import * as React from "react";
import Paper from "@material-ui/core/Paper/Paper";
import { connect } from "react-redux";
import {
  testerBookingsLoader, getTestsRequestsAction
} from "actions/tester-bookings";
import selector from "selectors/tester-bookings";
import { TablePagination } from "@material-ui/core";
import { isChangesExist } from "helpers/props-checker";
import { IAnyProps } from "interfaces/any-props.interface";
import debounce from "debounce";
import { ITest } from "models/test.interface";
import { formValueSelector } from "redux-form";
import { RootState } from "reducers";
import { TESTS_SEARCH_FORM } from "../TestsSearchForm";
import { TableNoItems } from "components/table/TableNoItems";
import Table from "components/table/Table";
import { getTestsListColumns } from "./config";

import "../../styled.css";


interface IOwnProps {
  data: ITest[];
  setItemsPerPage: (page: string) => void;
  setPageNumber: (page: number) => void;
  isLoaded: boolean;
  totalItems: number;
  searchValues?: {
    status: string[]; dateFrom: string; dateTo: string;
  };
}

interface IFilterValues {
  itemsPerPage: number;
  pageNumber: number;
  dateFrom?: string;
  dateTo?: string;
  questionAsked?: boolean | string;
  statuses?: string;
  name?: string;
  plannerName?: string;
  clientCompany?: number;
  testerName?: string;
  testingCompany?: number;
}

// TODO: IAnyProps should be remove in future

type IProps = IOwnProps & IFilterValues & IAnyProps;

const selectorSearchForm = formValueSelector(TESTS_SEARCH_FORM);

class TestsList extends React.Component<IProps> {
  debouncedLoad = debounce(nextProps => {
    this.loadItems(nextProps);
  }, 500);

  loadItems = (props: IProps): void => {
    props.loadItems(
      props.pageNumber,
      props.itemsPerPage,
      "",
      "",
      props.dateFrom,
      props.dateTo,
      "",
      props.statuses,
      props.name,
      props.plannerName,
      props.clientCompany,
      props.testerName,
      props.testingCompany
    );
  };

  componentDidMount() {
    this.loadItems(this.props);
  }

  componentWillReceiveProps(nextProps: IProps) {
    if (
      isChangesExist(
        [
          "pageNumber",
          "itemsPerPage",
          "",
          "",
          "dateFrom",
          "dateTo",
          "",
          "statuses",
          "name",
          "plannerName",
          "clientCompany",
          "testerName",
          "testingCompany"
        ],
        nextProps,
        this.props
      )
    ) {
      if (
        isChangesExist(
          [
            "statuses",
            "name",
            "plannerName",
            "testerName",
            "dateFrom",
            "dateTo",
            "pageNumber",
            "itemsPerPage",
            "testingCompany",
            "clientCompany"
          ],
          nextProps,
          this.props
        )
      ) {
        this.debouncedLoad.clear();
        this.debouncedLoad(nextProps);
      } else {
        this.loadItems(nextProps);
      }
    }
  }

  handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number): void => {
    this.props.setPageNumber(page + 1);
  };

  handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.props.setItemsPerPage(event.target.value);
  };

  render() {
    const { data, isLoaded } = this.props;
    if (!isLoaded) {
      return <TableNoItems asLoading hasWrapper />;
    }

    if (!data.length) {
      return <TableNoItems hasWrapper />;
    }

    return (
      <Paper className="paperWrap">
        <Table
          columns={getTestsListColumns()}
          data={this.props.data}
          index="id"
        />
        <TablePagination
          component="div"
          count={this.props.totalItems}
          rowsPerPage={this.props.itemsPerPage}
          page={this.props.pageNumber - 1}
          backIconButtonProps={{
            "aria-label": "Previous Page"
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page"
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Paper>
    );
  }
}

export default connect<IAnyProps, IAnyProps, IAnyProps, RootState>((state) => {
  return {
    data: selector.getItems(state),
    isLoaded: selector.getIsLoaded(state),
    itemsPerPage: selector.getItemsPerPage(state),
    pageNumber: selector.getPageNumber(state),
    showStatus: selector.getFilterActivityStatus(state),
    totalItems: selector.getTotalItems(state),
    filterText: selector.getFilterTextFields(state),
    name: selectorSearchForm(state, "name") || "",
    statuses: selectorSearchForm(state, "statuses") || "",
    plannerName: selectorSearchForm(state, "plannerName") || "",
    testerName: selectorSearchForm(state, "testerName") || "",
    testingCompany: selectorSearchForm(state, "testingCompany") || "",
    clientCompany: selectorSearchForm(state, "clientCompany") || "",
    dateFrom: selectorSearchForm(state, "dateFrom") || "",
    dateTo: selectorSearchForm(state, "dateTo") || ""
  };
}, {
  loadItems: getTestsRequestsAction,
  setItemsPerPage: testerBookingsLoader.setItemsPerPage,
  setPageNumber: testerBookingsLoader.setPageNumber
})(TestsList);
