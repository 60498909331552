import {Observable} from "rxjs";
import {flatMap, switchMap} from "rxjs/operators";
import {ActionsObservable} from "redux-observable";
import * as actionType from "../constants/action-type";
import {fromPromise} from "rxjs/internal-compatibility";
import {IActionPayload} from "./index";
import {startEndCatch} from "./pipes/errors";
import {amendmentsSearchRequest} from "../api/requests/question";
import {createLoaderAction} from "../helpers/action-helper";
import selector from "../selectors/question";

export const amendmentsSearchApplyEpic = (action$: ActionsObservable<IActionPayload<any>>, state$): Observable<any> => {
  return action$
    .ofType(actionType.AMENDMENTS_SEARCH_APPLY)
    .pipe(
      switchMap((action: IActionPayload<any>) => {

        const pageNumber = selector.getPageNumber(state$.value);
        const itemsPerPage = selector.getItemsPerPage(state$.value);

        return fromPromise(amendmentsSearchRequest(
          action.payload,
          pageNumber,
          itemsPerPage,
        ))
          .pipe(
            flatMap(({items, totalItems}) => {
              return [
                createLoaderAction(actionType.QUESTION).setPaginatioData(items, itemsPerPage, totalItems, pageNumber),
              ];
            }),
            ...startEndCatch("amendmentsSearchApplyEpic"),
          );
      }),
    );
};
