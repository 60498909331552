import * as React from 'react';
import { dataFetcherRelated } from "./dataFetcherRelated";

export interface IParameterInquiry {
  selector: (state, props) => any;
  action: (props?: any) => any;
  key: string;
  alwaysReceiveFreshData?: boolean;
}

type typeDataFetcher = <T>(ChildComponent: React.ElementType, ...inquiries: IParameterInquiry[][]) =>
  React.ComponentClass<T>;

const dataFetcher: typeDataFetcher = (ChildComponent, ...inquiries) => {
  return dataFetcherRelated(ChildComponent, {}, ...inquiries);
};

export { dataFetcher };
