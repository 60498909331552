import * as React from "react";
import { WithStyles } from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import { archiveInsurance } from "../../../actions/insurance";
import { IInsurance } from "../../../models/insurance.interface";
import Table from "@material-ui/core/Table/Table";
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableBody from "@material-ui/core/TableBody/TableBody";
import TableCell from "@material-ui/core/TableCell/TableCell";
import EnhancedTableHead from "../../../components/table/EnhancedTableHead";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Icon from "@material-ui/core/Icon/Icon";
import { connect } from "react-redux";
import { downloadDocumentFile } from "../../../api/requests/document-download";
import Button from "@material-ui/core/Button";
import { API_DOCUMENTS_ENDPOINT_URL } from "../../../constants/urls";
import { formatDateWithSlashesDdMmYyyy } from "../../../helpers/date-formatter";
import { Link } from "react-router-dom";
import { INSURANCE_VALUES_COUNT } from "../../../constants/insurance";

interface IOwnProps {
  baseUrl: string;
  insurances: IInsurance[];
  insuranceArchive: (id: any) => void;
  downloadDocumentFile: (id: number) => void;
}

// Define styles for the component
const styles = () => ({});

interface IOwnProps {
  baseUrl: string;
  insurances: IInsurance[];
}

interface IDispatchProps {
  insuranceArchive: (id: number) => void;
  downloadDocumentFile: (id: number) => void;
}

type Props = IOwnProps & IDispatchProps & WithStyles<typeof styles>;

class InsurancesTable extends React.Component<Props> {
  downloadFile = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const evidenceDocumentId = event?.currentTarget?.dataset?.evidenceid;
    if (evidenceDocumentId) {
      this.props.downloadDocumentFile(+evidenceDocumentId);
    }
  };

  render() {
    return (
      <Table>
        <EnhancedTableHead
          columns={[
            "Name of insurance company",
            "Type",
            "Value",
            "Valid",
            "Evidence",
            "Comments",
            "Edit",
            "Archive"
          ]}
        />
        <TableBody>
          {this.props.insurances.map((item: IInsurance) => {
            return (
              <TableRow key={item.id}>
                <TableCell>{item.insuranceCompany}</TableCell>
                <TableCell>{item.type}</TableCell>
                <TableCell>
                  {item.value === INSURANCE_VALUES_COUNT
                    ? "more than £" + (INSURANCE_VALUES_COUNT - 1) + "m"
                    : "£" + item.value + "m"}
                </TableCell>
                <TableCell>{this.getValidity(item)}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    data-evidenceid={item.evidence.replace(
                      API_DOCUMENTS_ENDPOINT_URL,
                      ""
                    )}
                    onClick={this.downloadFile}
                  >
                    Download
                  </Button>
                </TableCell>
                <TableCell>{item.comments}</TableCell>
                <TableCell>
                  <Link to={`${this.props.baseUrl}/insurances/${item.id}`}>
                    <IconButton>
                      <Icon>edit</Icon>
                    </IconButton>
                  </Link>
                </TableCell>
                <TableCell onClick={this.handleArchive(item)}>
                  <IconButton>
                    <Icon>archive</Icon>
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  }

  private readonly getValidity = (item: IInsurance) => {
    if (item?.validFrom && item?.validTo) {
      const dateFrom =
        item.validFrom && formatDateWithSlashesDdMmYyyy(item.validFrom);
      const dateTo =
        item.validTo && formatDateWithSlashesDdMmYyyy(item.validTo);

      return `${dateFrom} to ${dateTo}`;
    }
    return "";
  };

  private readonly handleArchive = (item: IInsurance) => () => {
    this.props.insuranceArchive(item.id);
  };
}

export default connect(null, {
  insuranceArchive: archiveInsurance,
  downloadDocumentFile
})(withStyles(styles)(InsurancesTable));
