import {IOptionItem} from "../interfaces/option-item.interface";

export enum Weekend {
    INCLUDE_WEEKEND_YES = 1,
    INCLUDE_WEEKEND_NEVER = 2,
    ONLY_WEEKENDS = 3,
}

export const WEEKEND_LIST: IOptionItem[] = [
  {
    key: Weekend.INCLUDE_WEEKEND_YES,
    value: 'Yes',
  },
  {
    key: Weekend.INCLUDE_WEEKEND_NEVER,
    value: 'Never',
  },
  {
    key: Weekend.ONLY_WEEKENDS,
    value: 'Only weekends',
  },
];
