import * as Types from '../constants/action-type';
import {
  createFilterAction,
  createLoaderAction,
  createCrudAction,
  createRequestAction
} from '../helpers/action-helper';
import {MESSAGE_SEARCH_APPLY} from "../constants/action-type";
import {createAction} from "redux-actions";
import {setRequestInProcess} from "./request";
import {handleHttpErrors} from "../helpers/errors/http-error-handler";
import {messageModalClose, messageNewModalClose} from "./modals";
import {postMessage, putMessage, resetMessages} from "../api/requests/message";
import {reset} from "redux-form";
import {MESSAGE_FORM_NAME, MESSAGE_NEW_FORM_NAME} from "../constants/forms";

export const messageLoader = createLoaderAction(Types.MESSAGE);
export const messageRequest = createRequestAction(Types.MESSAGE);

export const messageSearchAction = createAction(MESSAGE_SEARCH_APPLY);

export const messageResetAction = (idTest) => async dispatch => {
  dispatch(setRequestInProcess(true, 'messageResetAction'));

  try {
    await resetMessages(idTest);
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(setRequestInProcess(false, 'messageResetAction'));
};

export const messageModalSubmit = (message, idMessage) => async dispatch => {
  dispatch(setRequestInProcess(true, 'messageModalSubmit'));

  let data;

  try {
    data = await putMessage(idMessage, message);

    dispatch(createCrudAction(Types.MESSAGE).replaceItem(data));
    dispatch(messageModalClose());
    dispatch(reset(MESSAGE_FORM_NAME));
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(setRequestInProcess(false, 'messageModalSubmit'));
};

export const messageNewModalSubmit = (idTest, message) => async dispatch => {
  dispatch(setRequestInProcess(true, 'messageModalSubmit'));

  let data;

  try {
    data = await postMessage(message, idTest);

    dispatch(createCrudAction(Types.MESSAGE).addItem(data));
    dispatch(messageNewModalClose());
    dispatch(reset(MESSAGE_NEW_FORM_NAME));
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(setRequestInProcess(false, 'messageModalSubmit'));
};