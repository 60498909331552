import * as applicationSettings from "./application-settings";
import * as carousel from "./carousel";
import * as faq from "./faq";
import * as countries from "./country";
import * as emailTemplates from "./email-templates";
import * as sector from "./sector";
import * as certificateType from "./certificate-type";
import * as specialisms from "./specialisms";
import * as contentManagement from "./content-management";
import * as resetPassword from "./reset-password";
import * as changePassword from "./change-password";
import * as testers from "./testers";
import * as certificates from "./certificates";
import * as insurances from "./insurances";
import * as locations from "./locations";
import * as users from "./users";
import * as risks from "./risks";
import * as clients from "./clients";
import * as workHistory from "./work-history";
import * as dbsCertificate from "./dbs-certificates";
import * as projects from "./projects";
import * as tests from "./test";
import * as specialismsWithoutReportTemplates from "./specialisms-without-report-templates";
import * as reports from "./reports";
import * as reportDocuments from "./report-document";
import * as document from "./document";
import * as Types from "../../constants/action-type";
import * as favoriteTesters from "./favorite-testers";
import { ADMIT_CRUD_TYPE } from "constants/action-type";
import * as groups from "./groups";
import * as testerExternalCompanies from "./tester-external-companies";
import * as testingCompanies from "./testing-companies";
import * as companyProjects from "./company-projects";

export function getRequestFromType(type: ADMIT_CRUD_TYPE) {
  switch (type) {
    case Types.CAROUSEL:
      return carousel;
    case Types.FAQ:
      return faq;
    case Types.COUNTRY:
      return countries;
    case Types.EMAIL_TEMPLATES:
      return emailTemplates;
    case Types.SECTOR:
      return sector;
    case Types.CERTIFICATE_TYPE:
      return certificateType;
    case Types.DBS_CERTIFICATES:
      return dbsCertificate;
    case Types.SPECIALISM:
      return specialisms;
    case Types.CONTENT_MANAGEMENT:
      return contentManagement;
    case Types.RESET_PASSWORD:
      return resetPassword;
    case Types.CHANGE_PASSWORD:
      return changePassword;
    case Types.TESTERS:
      return testers;
    case Types.CERTIFICATES:
      return certificates;
    case Types.INSURANCES:
      return insurances;
    case Types.LOCATIONS:
      return locations;
    case Types.WORK_HISTORY:
      return workHistory;
    case Types.PROJECT:
      return projects;
    case Types.USERS:
      return users;
    case Types.CLIENTS:
      return clients;
    case Types.TEST:
      return tests;
    case Types.SPECIALISMS_WITHOUT_REPORT_TEMPLATES:
      return specialismsWithoutReportTemplates;
    case Types.REPORTS:
      return reports;
    case Types.REPORT_DOCUMENTS:
      return reportDocuments;
    case Types.DOCUMENT:
      return document;
    case Types.RISKS:
      return risks;
    case Types.TEST_AND_PROJECTS:
      return tests;
    case Types.TEST_REPORTS:
      return tests;
    case Types.TESTER_BOOKING_REQUESTS:
      return tests;
    case Types.TESTER_BOOKINGS:
      return tests;
    case Types.CLIENT_BOOKING_REQUESTS:
      return tests;
    case Types.PROJECTS_FOR_SCHEDULING:
      return projects;
    case Types.APPLICATION_SETTINGS:
      return applicationSettings;
    case Types.FAVORITE_TESTERS:
      return favoriteTesters;
    case Types.GROUP:
    case Types.GROUP_SEARCH:
      return groups;
    case Types.TESTER_EXTERNAL_COMPANY:
      return testerExternalCompanies;
    case Types.GET_TESTING_COMPANIES:
      return testingCompanies;
    case Types.COMPANY_PROJECTS:
      return companyProjects;
    default:
      throw new Error("Implement please necessary action provider");
  }
}
