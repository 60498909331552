import * as React from "react";
import Header from "./Header";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import confirmResetPassword from "../../actions/reset-password-confirmation";
import {IResetPasswordConfirmationRequest} from "../../api/requests/reset-password-confirmation";
import {IResetPasswordConfirmationState} from "../../reducers/reset-password-confirmation";

interface IProps {
  confirmResetPassword: (parameters: IResetPasswordConfirmationRequest) => string;
  passwordResetConfirmation: IResetPasswordConfirmationState;
}

class ResetPasswordConfirmationPage extends React.Component<IProps & RouteComponentProps<any>, {}> {

  componentDidMount() {
    this.props.confirmResetPassword({token: this.props.match.params.resetPasswordToken});
  }

  renderMessage = () => {
    if (this.props.passwordResetConfirmation.status) {
      return 'Email with new password was sent to your mailbox.';
    }

    if (!this.props.passwordResetConfirmation.status && this.props.passwordResetConfirmation.message) {
      return this.props.passwordResetConfirmation.message
    }

    return 'Something went wrong please try again later.'
  };

  render() {
    if (!this.props.passwordResetConfirmation) {
      return (
        <div>
          Loading...
        </div>
      );
    }
    return (
      <div>
        <Header/>
        <div className="page-content">
          <div className="container">
            <div className="content">
              <h4>Change password confirmation</h4>
              <h5>{this.renderMessage()}</h5>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const routered = withRouter(ResetPasswordConfirmationPage);

export default connect ((state: any) => {
  return {
    passwordResetConfirmation: state.resetPasswordConfirmation.passwordResetPayload
  };
}, {
  confirmResetPassword
})(routered as any);
