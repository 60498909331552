import { TimesheetPoNumberRoute } from "../types/timesheets-and-expenses";

const CONSULTANT_DASHBOARD_ROUTE = "/consultant/dashboard";
const ADMIN_TIMESHEET_AND_EXPENSES_ROUTE = "/admin/time-and-expenses";
const CLIENT_TIMESHEET_AND_EXPENSES_ROUTE =
  "/client/tests-and-projects/timesheet-and-expenses";

export const getCreateLink = (baseUrl: string) => {
  return `${baseUrl}/create`;
};

export const getEditLink = (baseUrl: string, id: string) => {
  return `${baseUrl}/${id}`;
};

export const getCreateTimeLogLink = () => {
  return `${CONSULTANT_DASHBOARD_ROUTE}/create-time-log`;
};
export const getEditTimeLogLink = () => {
  return `${CONSULTANT_DASHBOARD_ROUTE}/edit-time-log`;
};

export const getConsultantViewTimeLogLink = () => {
  return `${CONSULTANT_DASHBOARD_ROUTE}/view-time-log`;
};
export const getClientViewTimeLogLink = () => {
  return `${CLIENT_TIMESHEET_AND_EXPENSES_ROUTE}/view-time-log`;
};
export const getClientTimesheetPoNumberEdit = (
  route: TimesheetPoNumberRoute
) => {
  return `${CLIENT_TIMESHEET_AND_EXPENSES_ROUTE}/edit-${route}`;
};
export const getAdminTimesheetPoNumberEdit = (
  route: TimesheetPoNumberRoute
) => {
  return `${ADMIN_TIMESHEET_AND_EXPENSES_ROUTE}/edit-${route}`;
};

export const getAdminViewTimeLogLink = () => {
  return `${ADMIN_TIMESHEET_AND_EXPENSES_ROUTE}/view-time-log`;
};

export const getCreateTotalTimeLogLink = () => {
  return `${CONSULTANT_DASHBOARD_ROUTE}/weekly-time-log`;
};

export const getBackLink = (baseUrl: string) => {
  return baseUrl
    .split("/")
    .slice(0, -1)
    .join("/");
};
