import { IUsers } from "models/users.interface";
import React from "react";
import { useCallback } from "react";
import { OnReset } from "../constants";
import {
  ConfirmWrapper
} from "containers/admin/testers/TesterList/columns/ConfirmWrapper";

type Reset2FAProps = {
  user: IUsers;
  onReset: OnReset;
};

export function Reset2FA({user, onReset}: Reset2FAProps) {
  const onResetHandler = useCallback(() => {
    onReset(user);
  }, [user]);

  if (user.deletedAt) {
    return <></>;
  }

  return <ConfirmWrapper
    onConfirm={onResetHandler}
    message='You want to reset 2FA?"'
  >
    <a
      href={user.email}
      style={{fontSize: '0.8125rem'}}
    >
      Reset 2FA
    </a>
  </ConfirmWrapper>;
}
