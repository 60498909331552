import * as Types from '../constants/action-type';
import {
  createFilterAction,
  createLoaderAction,
  createRequestAction
} from '../helpers/action-helper';
import {createAction} from 'redux-actions';
import {openSnackbar} from "./snackbar";

export const specialismsFilter = createFilterAction(Types.SPECIALISM);
export const specialismsLoader = createLoaderAction(Types.SPECIALISM);
export const specialismsRequest = createRequestAction(Types.SPECIALISM);

const submitFilter = (type, text) => async dispatch => {
  dispatch(specialismsFilter.setTextFilter(text));
  dispatch(specialismsFilter.setActiveFilter(type));

  await specialismsRequest.getItems(1, 10, type, text);
};

export const specialismAddItemsNested = (items) => createAction(Types.SPECIALISM_ADD_ITEMS_NESTED)(items);

export const specialismSaveBatch = (items) => async dispatch => {
  let isSpecialismDataValid = true;

  const specialisms = items.updated.concat(items.added);
  specialisms.map(specialism => {
    if (!specialism.name.trim()) {
      isSpecialismDataValid = false;
    }
  });

  if (!isSpecialismDataValid) {
    dispatch(openSnackbar('Not all specialism data is valid', 4000));
    return;
  }

  dispatch(createAction(Types.SPECIALISM_SAVE_BATCH)(items));
};