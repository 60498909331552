import * as React from "react";
import { IDocument } from "models/document.interface";
import DocumentDownloadModal from "../DocumentDownloadModal";
import { useState } from "react";

type Props = {
  document: IDocument;
  downloadDocumentFile: (doc: IDocument) => void;
}

export function DownloadDocumentColumn(
  {
    document,
    downloadDocumentFile
  }: Props
) {
  const [open, setOpen] = useState(false);

  const onClickHandler = () => {
    setOpen(true);
  };

  const onCloseHandler = () => {
    setOpen(false);
  };

  const onDownloadFile = () => {
    downloadDocumentFile(document);
    onCloseHandler();
  };


  return <div>
    <button
      className="btn-download"
      onClick={onClickHandler}
    >
      {document.name}
    </button>
    <DocumentDownloadModal
      open={open}
      onDownload={onDownloadFile}
      onClose={onCloseHandler}
    />
  </div>
}
