import * as Types from '../constants/action-type';
import {ICalendarEvent} from "./calendar";
import {IQuestion} from "../models/question.interface";
import {IReportDocument} from "../models/report-documents.interface";
import {IMessage} from "../models/message.interface";
import {TestStatus} from "../constants/test-status";
import {Moment} from "moment";

interface IBookingCancel {
  testId: number;
  status: TestStatus;
  backRoute: string;
}

interface IBookingReject {
  testId: number;
  status: TestStatus;
  backRoute: string;
}

interface ICalendarModal {
  date: Moment;
  events: ICalendarEvent[];
}

interface ITwoFactorAuthInit {
  code: string;
  url: string;
}

export interface IStateModal {
  isOpenContacts: boolean;
  isOpenAfterRegistrationModal: boolean;
  isOpenForgotPassword: boolean;
  isOpenChangePassword: boolean;
  isOpenLogin: boolean;
  isOpenRequestDeletion: boolean;
  isOpenTesterAccount: boolean;
  isOpenClientAccount: boolean;
  isOpenApproach: boolean;
  openDeleteTest: number;
  modalCalendar: ICalendarModal | false;
  isOpenReassign: number;
  modalGroup: string;
  modalTitle: string;
  isOpenQuestion: IQuestion | false;
  isOpenQuestionNew: boolean;
  isOpenAmendments: IReportDocument | false;
  isOpenAmendmentsAnswer: IQuestion | false;
  isOpenAcceptReport: boolean;
  isOpenCreateReportDocument: IReportDocument | false;
  openBookingReject: IBookingReject | false;
  openBookingCancel: IBookingCancel | false;
  isOpenMessage: IMessage | false;
  isOpenMessageNew: boolean;
  isOpenTwoFactorInit: ITwoFactorAuthInit | boolean;
  isOpenTwoFactorCheck: boolean;
  avordValidation: boolean;
  editProposalByTester: boolean;
  acceptEditProposalByTester: boolean
}

export default function (state: IStateModal = {
  isOpenContacts: false,
  modalTitle: '',
  isOpenAfterRegistrationModal: false,
  isOpenForgotPassword: false,
  isOpenChangePassword: false,
  isOpenLogin: false,
  isOpenRequestDeletion: false,
  isOpenTesterAccount: false,
  isOpenClientAccount: false,
  isOpenApproach: false,
  openDeleteTest: 0,
  modalCalendar: false,
  isOpenReassign: 0,
  modalGroup: '',
  isOpenQuestion: false,
  isOpenQuestionNew: false,
  isOpenAmendments: false,
  isOpenAmendmentsAnswer: false,
  isOpenAcceptReport: false,
  isOpenCreateReportDocument: false,
  openBookingReject: false,
  openBookingCancel: false,
  isOpenMessage: false,
  isOpenMessageNew: false,
  isOpenTwoFactorInit: false,
  isOpenTwoFactorCheck: false,
  avordValidation: false,
  editProposalByTester: false,
  acceptEditProposalByTester: false
}, action): IStateModal {
  switch (action.type) {
    case Types.MODAL_CONTACT_CLOSE:
      return {...state, isOpenContacts: false};
    case Types.MODAL_CONTACT_OPEN:
      return {...state, isOpenContacts: true};
    case Types.MODAL_AFTER_REGISTRATION_CLOSE:
      return {...state, isOpenAfterRegistrationModal: false};
    case Types.MODAL_AFTER_REGISTRATION_OPEN:
      return {...state, isOpenAfterRegistrationModal: true};
    case Types.MODAL_FORGOT_PASSWORD_CLOSE:
      return {...state, isOpenForgotPassword: false};
    case Types.MODAL_FORGOT_PASSWORD_OPEN:
      return {...state, isOpenForgotPassword: true};
    case Types.MODAL_LOGIN_CLOSE:
      return {...state, isOpenLogin: false};
    case Types.MODAL_LOGIN_OPEN:
      return {...state, isOpenLogin: true};
    case Types.CHANGE_PASSWORD_MODAL_OPEN:
      return {...state, isOpenChangePassword: true};
    case Types.CHANGE_PASSWORD_MODAL_CLOSE:
      return {...state, isOpenChangePassword: false};
    case Types.REQUEST_ACCOUNT_DELETION_MODAL_OPEN:
      return {...state, isOpenRequestDeletion: true};
    case Types.REQUEST_ACCOUNT_DELETION_MODAL_CLOSE:
      return {...state, isOpenRequestDeletion: false};
    case Types.TESTER_ACCOUNT_MODAL_OPEN:
      return {...state, isOpenTesterAccount: true};
    case Types.TESTER_ACCOUNT_MODAL_CLOSE:
      return {...state, isOpenTesterAccount: false};
    case Types.CLIENT_ACCOUNT_MODAL_OPEN:
      return {...state, isOpenClientAccount: true};
    case Types.CLIENT_ACCOUNT_MODAL_CLOSE:
      return {...state, isOpenClientAccount: false};
    case Types.APPROACH_MODAL_OPEN:
      return {...state, isOpenApproach: true};
    case Types.APPROACH_MODAL_CLOSE:
      return {...state, isOpenApproach: false};
    case Types.DELETE_TEST_MODAL_OPEN:
      return {...state, openDeleteTest: action.payload};
    case Types.DELETE_TEST_MODAL_CLOSE:
      return {...state, openDeleteTest: 0};
    case Types.CALENDAR_MODAL_OPEN:
      return {
        ...state,
        modalCalendar: {
          date: action.payload.date,
          events: action.payload.events,
        }
      };
    case Types.CALENDAR_MODAL_CLOSE:
      return {...state, modalCalendar: false};
    case Types.REASSIGN_MODAL_OPEN:
      return {...state, isOpenReassign: action.payload};
    case Types.REASSIGN_MODAL_GROUP:
      return {...state, modalGroup: action.payload};
    case Types.REASSIGN_MODAL_CLOSE:
      return {...state, isOpenReassign: 0};
    case Types.REASSIGN_MODAL_TITLE:
      return {...state, modalTitle: action.payload};
    case Types.QUESTION_MODAL_OPEN:
      return {...state, isOpenQuestion: action.payload};
    case Types.QUESTION_MODAL_CLOSE:
      return {...state, isOpenQuestion: false};
    case Types.QUESTION_NEW_MODAL_OPEN:
      return {...state, isOpenQuestionNew: true};
    case Types.QUESTION_NEW_MODAL_CLOSE:
      return {...state, isOpenQuestionNew: false};
    case Types.AMENDMENTS_MODAL_OPEN:
      return {...state, isOpenAmendments: action.payload};
    case Types.AMENDMENTS_MODAL_CLOSE:
      return {...state, isOpenAmendments: false};
    case Types.AMENDMENTS_ANSWER_MODAL_OPEN:
      return {...state, isOpenAmendmentsAnswer: action.payload};
    case Types.AMENDMENTS_ANSWER_MODAL_CLOSE:
      return {...state, isOpenAmendmentsAnswer: false};
    case Types.REPORT_ACCEPTED_MODAL_OPEN:
      return {...state, isOpenAcceptReport: true};
    case Types.REPORT_ACCEPTED_MODAL_CLOSE:
      return {...state, isOpenAcceptReport: false};
    case Types.REPORT_DOCUMENT_MODAL_OPEN:
      return {...state, isOpenCreateReportDocument: action.payload};
    case Types.REPORT_DOCUMENT_MODAL_CLOSE:
      return {...state, isOpenCreateReportDocument: false};
    case Types.BOOKING_REJECT_MODAL_OPEN:
      return {
        ...state,
        openBookingReject: {
          testId: action.payload.testId,
          status: action.payload.status,
          backRoute: action.payload.backRoute
        }
      };
    case Types.BOOKING_REJECT_MODAL_CLOSE:
      return {...state, openBookingReject: false};
    case Types.BOOKING_CANCEL_MODAL_OPEN:
      return {
        ...state,
        openBookingCancel: {
          testId: action.payload.testId,
          status: action.payload.status,
          backRoute: action.payload.backRoute
        }
      };
    case Types.BOOKING_CANCEL_MODAL_CLOSE:
      return {...state, openBookingCancel: false};
    case Types.AVORD_VALIDATION_MODAL_OPEN:
      return {
        ...state,
        avordValidation: true
      };
    case Types.AVORD_VALIDATION_MODAL_CLOSE:
      return {...state, avordValidation: false};
    case Types.EDIT_PROPOSAL_BY_TESTER_MODAL_OPEN:
      return {
        ...state,
        editProposalByTester: true
      };
    case Types.EDIT_PROPOSAL_BY_TESTER_MODAL_CLOSE:
      return {...state, editProposalByTester: false};
    case Types.ACCEPT_EDIT_PROPOSAL_BY_TESTER_MODAL_OPEN:
      return {
        ...state,
        acceptEditProposalByTester: true
      };
    case Types.ACCEPT_EDIT_PROPOSAL_BY_TESTER_MODAL_CLOSE:
      return {...state, acceptEditProposalByTester: false};
    case Types.MESSAGE_MODAL_OPEN:
      return {...state, isOpenMessage: action.payload};
    case Types.MESSAGE_MODAL_CLOSE:
      return {...state, isOpenMessage: false};
    case Types.MESSAGE_NEW_MODAL_OPEN:
      return {...state, isOpenMessageNew: true};
    case Types.MESSAGE_NEW_MODAL_CLOSE:
      return {...state, isOpenMessageNew: false};
    case Types.MODAL_TWO_FACTOR_INIT_CLOSE:
      return {...state, isOpenTwoFactorInit: false};
    case Types.MODAL_TWO_FACTOR_INIT_OPEN:
      return {...state, isOpenTwoFactorInit: {
          code: action.payload.code,
          url: action.payload.url,
        }};
    case Types.MODAL_TWO_FACTOR_CHECK_CLOSE:
      return {...state, isOpenTwoFactorCheck: false};
    case Types.MODAL_TWO_FACTOR_CHECK_OPEN:
      return {...state, isOpenTwoFactorCheck: true};
    default:
      return state;
  }
}
