import React from 'react';
import { composeValidators, fieldsNotEmpty, fieldMaxLength } from "../../../../helpers/validations/validation";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import { certificateTypeRequest } from "../../../../actions/certificate-type";
import parameterFetcher from '../../../../components/parametersFetcher';
import selector from '../../../../selectors/certificate-type';
import { CertificateForm } from "./CertificateForm";
import routerModal from '../../../../components/routerModal';

const FORM_NAME = 'CertificateUpdate';

class CertificateUpdate extends React.Component {
  render() {
    const { handleSubmit, putData, backRoute } = this.props;

    return (
      <form noValidate autoComplete="off"
        onSubmit={handleSubmit((values) => {
          putData(values, FORM_NAME, backRoute);
        })}>
        <CertificateForm backRoute={backRoute}/>
      </form>
    )
  }
}

const formConnected = reduxForm({
  form: FORM_NAME,
  validate: composeValidators(
    fieldsNotEmpty(['shortName', 'fullName']),
    fieldMaxLength(['shortName'], 20),
    fieldMaxLength(['fullName'], 100),
  )
})(CertificateUpdate);

const connected = parameterFetcher(connect(undefined, {
  putData: certificateTypeRequest.putItem,
})(formConnected), FORM_NAME, certificateTypeRequest.getItem, selector.getItemById);

export default routerModal(connected);
