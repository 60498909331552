import * as ActionTypes from "constants/action-type";
import * as FilterTypes from "constants/filter";
import {
  reducerCrudItems,
  mergeReducers,
  filterItems,
  reducerLoaderItems,
  paginationDefaultState
} from "helpers/reducer-helper";
import { combineReducers } from "redux";

const mainReducer = (state = [], action: { type: string }) => {
  switch (action.type) {
    default:
      return state;
  }
};

const loaderReducer = (
  state = paginationDefaultState,
  action: {
    type: string;
  }
) => {
  switch (action.type) {
    default:
      return state;
  }
};

const filterReducer = (
  state = {
    field: "",
    conditions: {
      active: FilterTypes.ACTIVE
    }
  },
  action: { type: string }
) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default combineReducers({
  items: combineReducers({
    items: mergeReducers(
      reducerCrudItems(ActionTypes.COMPANY_PROJECTS),
      mainReducer
    ),
    loader: mergeReducers(
      reducerLoaderItems(ActionTypes.COMPANY_PROJECTS),
      loaderReducer
    )
  }),
  filter: mergeReducers(
    filterItems(ActionTypes.COMPANY_PROJECTS),
    filterReducer
  )
});
