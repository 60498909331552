import React from 'react';
import { composeValidators, fieldsNotEmpty, fieldMaxLength } from "../../../../helpers/validations/validation";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import { emailTemplatesRequest } from "../../../../actions/email-templates";
import { EmailTemplatesForm } from "./EmailTemplatesForm";
import { withStyles } from "@material-ui/core/styles/index";
import { withRouter } from 'react-router-dom'
import { defaultStyleWidth } from '../../../../styles/material/popup-style';
import routerModal from '../../../../components/routerModal';

const FORM_NAME = 'EmailTemplatesCreate';

class EmailTemplatesCreate extends React.Component {
  render() {
    const { handleSubmit, postData, backRoute } = this.props;

    return (
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit((values) => {
          postData(values, FORM_NAME, backRoute);
        })}
      >
        <EmailTemplatesForm backRoute={backRoute}/>
      </form>
    );
  }
}

const formConnected = reduxForm({
  form: FORM_NAME,
  validate: composeValidators(
    fieldsNotEmpty(['subject', 'body',]),
    fieldMaxLength(['subject',], 150),
  )
})(EmailTemplatesCreate);

const connected = connect(undefined, {
  postData: emailTemplatesRequest.postItem,
})(formConnected);

export default routerModal(withRouter(withStyles(defaultStyleWidth(400))(connected)));
