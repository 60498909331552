import * as React from "react";
import {composeValidators, fieldMaxLength, fieldsNotEmpty} from "../../../helpers/validations/validation";
import {InjectedFormProps, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import routerModal, {IBackRoute} from "../../../components/routerModal";
import {WithStyles} from "@material-ui/core/styles/withStyles";
import ReduxTextField from "../../../components/forms/ReduxTextField";
import Button from "@material-ui/core/Button/Button";
import {FieldGeneric} from "../../../components/forms/FieldGeneric";
import Grid from "@material-ui/core/Grid/Grid";
import "react-datepicker/dist/react-datepicker.css";
import {IBankDatails} from "../../../models/bank-datails.interface";
import Typography from "@material-ui/core/Typography/Typography";
import * as actionType from "../../../constants/action-type";
import {dataFetcher} from "../../../components/dataFetcher";
import {createLoaderActionItem} from "../../../interfaces/reducer-item.interface";
import {getBankDetails} from "../../../selectors/tester-profile";
import {putBankDetailsAction} from "../../../actions/bank-details";

const FORM_NAME = "BankDetailsEdit";

type IProps = InjectedFormProps<{}, {}> & {
  putItem: (bankDetails: IBankDatails, backRoute: string, formName: string) => void,
} & WithStyles<any> & IBackRoute;

class BankDetailsEdit extends React.Component<IProps, {}> {
  render() {
    const {handleSubmit, putItem, backRoute} = this.props;

    return (
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit((values: IBankDatails) => {
          putItem(values, backRoute, FORM_NAME);
        })}
      >
        <h4>Bank Details</h4>
        <hr/>
        <p>
          Please enter the details for the account you would like
          AVORD to send payments to
        </p>
        <div className="row">
          <div className="col-md-6">
            <FieldGeneric
              inputProps={{minLength: 1, maxLength: 20}}
              name="account_no"
              label="Account no. *"
              component={ReduxTextField}
            />
          </div>
          <div className="col-md-6">
            <FieldGeneric
              inputProps={{minLength: 1, maxLength: 20}}
              name="sort_code"
              label="Sort code *"
              component={ReduxTextField}
            />
          </div>
        </div>
        <div className="d-flex justify-content-between mt-4">
          <Link to={this.props.backRoute}>
            <Button variant="contained" color="default">
              Cancel
            </Button>
          </Link>
          <Button type="submit" variant="contained" color="primary">
            Save
          </Button>
        </div>
      </form>
    );
  }
}

const formConnected = reduxForm({
  form: FORM_NAME,
  validate: composeValidators(
    fieldsNotEmpty(["account_no", "sort_code"]),
    fieldMaxLength(["account_no", "sort_code"], 20)
  )
})(BankDetailsEdit);

const connected = connect((state) => {
  const bankDetails = getBankDetails(state);

  if (!bankDetails.loaded || !bankDetails.item) {
    return {};
  }

  return {
    initialValues: {
      account_no: bankDetails.item.account_no,
      sort_code: bankDetails.item.sort_code
    }
  };
}, {
  putItem: putBankDetailsAction
})(formConnected);

const fetched = dataFetcher<IProps>(connected, [
  {
    key: "bankDetails",
    action: () => createLoaderActionItem(actionType.TESTER_BANK_DETAILS).get(),
    selector: (state, props) => getBankDetails(state)
  }
]);

export default routerModal(fetched);