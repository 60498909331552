import {ILocation} from "../models/location.interface";
import {ITesterIncoming, ITesterProfileGet} from "../models/tester-profile.interface";

export const getLocationString = (location: ILocation) => {
  return [
    location.address,
    location.addressLine2,
    location.addressLine3,
    location.town,
    location.state,
    location.postCode
  ].filter(a => a).join(', ');
};

export const getTesterLocationString = (tester: ITesterProfileGet | ITesterIncoming): string => {

  function convertResultString(value?: string, isNeedComma: boolean = true): string {
    if (value) {
      if (isNeedComma) {
        return value + ',';
      }
      return value;
    }
    return '';
  }

  return `
    ${convertResultString(tester.testerAddressLine)}
    ${convertResultString(tester.testerAddressLine2)}
    ${convertResultString(tester.testerAddressLine3)}
    ${convertResultString(tester.testerTown)}
    ${convertResultString(tester.testerState)}
    ${convertResultString(tester.postCode, false)}
  `;
};
