import {IConverterFromSwagger, IConverterToSwagger} from "./converter.interface";
import {ICertificate} from "../../models/certificate.interface";

export const converterFromSwagger: IConverterFromSwagger<ICertificate, any> = model => {
  return {
    ...model,
  };
};

export const converterToSwagger: IConverterToSwagger<ICertificate, any> = model => {
  return {
    ...model,
    evidence: `/api/secured_images/${model.evidence}`,
    certificateType: `/api/certificate_types/${model.certificateType}`,
    validFrom: model.validFrom,
    validTo: model.validTo,
  };
};
