import React from 'react';
import {composeValidators, fieldIsNumber, fieldsNotEmpty} from "../../../../helpers/validations/validation";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import { countriesRequest, putCountryItem } from "../../../../actions/countries";
import parameterFetcher from '../../../../components/parametersFetcher';
import selector from '../../../../selectors/countries';
import { CountryForm } from "./CountryForm";
import routerModal from '../../../../components/routerModal';

const FORM_NAME = 'CountryUpdate';

class CountryUpdate extends React.Component {
  render() {
    const { handleSubmit, putData, backRoute } = this.props;

    return (
      <form noValidate autoComplete="off"
        onSubmit={handleSubmit((values) => {
          putData(values, FORM_NAME, backRoute);
        })}>
        <CountryForm backRoute={backRoute}/>
      </form>
    )
  }
}

const formConnected = reduxForm({
  form: FORM_NAME,
  validate: composeValidators(
    fieldsNotEmpty([
      'name',
      'dialingCode',
    ]),
    fieldIsNumber([
      'dialingCode',
    ])
  )
})(CountryUpdate);

const connected = parameterFetcher(connect(undefined, {
  putData: putCountryItem,
})(formConnected), FORM_NAME, countriesRequest.getItem, selector.getItemById);

export default routerModal(connected);