import {WithStyles} from "@material-ui/core";
import {connect} from "react-redux";
import * as React from "react";
import {goToXeroInvoiceDetails} from "../../../../actions/xero";

interface IProps {
  testId: number;
  goToXeroInvoiceDetails: (testId: number) => void;
}

class InvoiceDetailsButton extends React.Component<IProps & WithStyles<any>>{
  goToXeroInvoiceDetails = () => {
    this.props.goToXeroInvoiceDetails(this.props.testId);
  };

  render () {
    return (
      <button
        className="btn-invoice-details"
        onClick={this.goToXeroInvoiceDetails}
      >
        Invoice&nbsp;Details
      </button>
    );
  }
}

const connected = connect(undefined, {
  goToXeroInvoiceDetails,
})(InvoiceDetailsButton as any);

export default connected as any;