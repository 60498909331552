import {getApiJSONLD} from "../swagger/api-factory";
import {extractJSONLD} from "../../helpers/request-helper";
import {convertorFromSwagger} from "../convertors/email-domains";
import {EmailDomainsType} from "../../types/client-admin-settings";

export const getEmailDomainsRequest = async () => {
  return getApiJSONLD()
    .then(api => api.getApiCompanyDomains())
    .then(extractJSONLD(convertorFromSwagger));
};

export const putEmailDomainsRequest = async (domains:EmailDomainsType[]) => {
  return getApiJSONLD()
    .then(api => api.postApiCompanyDomains({domains: domains}));
}