import * as React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import * as Storage from "../../helpers/storage-helper";
import {SHOW_COOKIES} from "../../constants/storage";


class Cookies extends React.Component {
  state = {
    showCookies: Storage.getValue(SHOW_COOKIES)
  };

  hideCookies = () => {
    Storage.setValue(SHOW_COOKIES, true);
  };

  render() {
    if (Storage.getValue(SHOW_COOKIES) === true) {
      return null;
    }

    return (
      <section className="cookies">
        <div className="container">
          <div className="text">
            This website stores cookies on your computer. These cookies are used to collect information about how you
            interact with our website and allow us to remember you. We use this information in order to improve and
            customize your browsing experience and for analytics and metrics about our visitors both on this website
            and other media. To find out more about the cookies we use, see our <Link to="/privacy-policy">Privacy
            Policy</Link>.
          </div>
          <a href="#" className="accept-button" onClick={this.hideCookies}>Hide</a>
        </div>
      </section>
    )
  }
}

export default Cookies;
