import * as React from "react";
import {Link, RouteComponentProps, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {book} from "../../../actions/invoice";
import Grid from "@material-ui/core/Grid/Grid";
import {dataFetcher} from "../../../components/dataFetcher";
import testsSelector from "../../../selectors/test";
import {testRequest} from "../../../actions/test";
import {result} from "lodash";
import {ITest} from "../../../models/test.interface";
import {push} from "react-router-redux";
import {AfterBookedStatuses, TestStatus} from "../../../constants/test-status";
import {RawHTML} from "../../../components/RawHTML";
import {IContentManagement} from "../../../models/content-management.interface";
import {getByKey} from "../../../selectors/content-management";
import {getItemByKey} from "../../../actions/content-management";
import {CLIENT_BOOKING_TERM} from "../../../constants/content-management";

class BookingRequestConfirmation extends React.Component<RouteComponentProps<any> & {
  book: (testId: number) => void,
  push: (...any) => void,
  test: ITest | null,
  contentManagement: IContentManagement,
}, {}> {
  baseUrl = this.props.match.path;

  render() {

    if (!this.props.test) {
      return (
        <div>
          Loading...
        </div>
      );
    }

    if (this.props.test.status as TestStatus !== TestStatus.STATUS_PROPOSAL_SUBMITTED) {
      if (AfterBookedStatuses.indexOf(this.props.test.status as TestStatus) !== -1) {
        this.props.push(`/client/reports/${this.props.test.id}/details`);
      } else {
        this.props.push(`/client/booking-requests`);
      }
    }

    return (
      <div className="styled-block">
        <h4><RawHTML>{this.props.contentManagement.content}</RawHTML></h4>

        <Grid container spacing={8} className="mt-2">
          <Grid item lg={1}/>

          <Grid item lg={3} xs={12}>
            <Link to={`/client/booking-requests/${this.props.match.params.test}`}>
              <button className="btn-cancel">Cancel</button>
            </Link>
          </Grid>

          <Grid item lg={4}/>

          <Grid item lg={3} xs={12}>
            <button onClick={this.book} className="btn-accept pull-right">
              Accept
            </button>
          </Grid>

          <Grid item lg={1}/>

        </Grid>

      </div>
    );
  }

  book = () => {
    this.props.book(this.props.match.params.test);
  };
}

const routered = withRouter(BookingRequestConfirmation as any);

const fetched = dataFetcher(
  routered,
  [{
    key: "test",
    selector: (state, props) => {
      return testsSelector.getItemById(result(props, "match.params.test", false))(state);
    },
    action: (props) => testRequest.getItem(result(props, "match.params.test", false)),
    alwaysReceiveFreshData: true,
  },
    {
      key: "contentManagement",
      selector: (state, props) => getByKey(CLIENT_BOOKING_TERM)(state),
      action: () => getItemByKey(CLIENT_BOOKING_TERM),
    }]
);

export default connect<any>(
  state => ({}),
  {
    book,
    push
  },
)(fetched);