import * as ReactGA from "react-ga";
import {RouteComponentProps} from "react-router-dom";
import * as React from "react";
import {withRouter} from "react-router-dom";
import {GA_KEY} from "../constants/google-analytics";
import {PROD_MODE} from "../constants/build-mode";

ReactGA.initialize(GA_KEY);

class GAListener extends React.Component<RouteComponentProps<any>> {

  static sendPageView(location) {
    ReactGA.set({page: location.pathname});
    ReactGA.pageview(location.pathname);
  }

  componentDidMount() {
    if (process.env.REACT_APP_BUILD_MODE === PROD_MODE) {
      GAListener.sendPageView(this.props.history.location);
      this.props.history.listen(GAListener.sendPageView);
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(GAListener as any);