import {IConverterFromSwagger, IConverterToSwagger} from "./converter.interface";
import {extractId} from "../../helpers/converter-helper";

export const converterFromSwagger: IConverterFromSwagger<any, any> = model => {
  return {
    ...model,
    picture: extractId(model.picture),
    country: extractId(model.country),
  };
};

export const converterToSwagger: IConverterToSwagger<any, any> = model => {
  if (model.testerFirstName) {
    model.testerFirstName = model.testerFirstName.trim();
  }

  if (model.testerLastName) {
    model.testerLastName = model.testerLastName.trim();
  }

  if (model.testerAddressLine) {
    model.testerAddressLine = model.testerAddressLine.trim();
  }

  if (model.testerAddressLine2) {
    model.testerAddressLine2 = model.testerAddressLine2.trim();
  }

  if (model.testerAddressLine3) {
    model.testerAddressLine3 = model.testerAddressLine3.trim();
  }

  if (model.testerTown) {
    model.testerTown = model.testerTown.trim();
  }

  if (model.testerState) {
    model.testerState = model.testerState.trim();
  }

  if (model.postCode) {
    model.postCode = model.postCode.trim();
  }

  if (model.countryId) {
    model.country = `/api/countries/${model.countryId}`;
  }

  model.picture = `/api/images/${model.picture}`;

  return model;
};
