import * as ActionTypes from "../constants/action-type";
import {
  reducerCrudItems,
  mergeReducers,
  reducerLoaderItems,
  paginationDefaultState
} from "../helpers/reducer-helper";
import { combineReducers } from "redux";

const mainReducer = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const loaderReducer = (
  state = { ...paginationDefaultState, itemsPerPage: 25, totalItems: 25 },
  action
) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default combineReducers({
  items: combineReducers({
    items: mergeReducers(
      reducerCrudItems(ActionTypes.TIMESHEET_TIME_CATEGORIES),
      mainReducer
    ),
    loader: mergeReducers(
      reducerLoaderItems(ActionTypes.TIMESHEET_TIME_CATEGORIES),
      loaderReducer
    )
  })
});
