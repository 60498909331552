import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import MuiButton from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

const Button = withStyles({
  root: {
    "&.ButtonWithTooltip": {
      pointerEvents: "auto"
    }
  }
})(MuiButton);

export const ButtonWithTooltip = ({ tooltipText, disabled, onClick, className, ...other }) => {
  const adjustedButtonProps = {
    disabled: disabled,
    component: disabled ? "div" : undefined,
    onClick: disabled ? undefined : onClick
  };
  const buttonClasses = className ? `ButtonWithTooltip ${className}` : 'ButtonWithTooltip';
  return (
    <Tooltip title={tooltipText}>
      <Button {...other} {...adjustedButtonProps} className={buttonClasses} />
    </Tooltip>
  );
};