import * as React from "react";
import {
  Button,
  TableBody,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router-dom";
import {
  TableRow,
  TableCell,
  Table,
  Typography,
  Card,
  CardContent,
} from "@material-ui/core";
import EnhancedTableHead from "../../../../components/table/EnhancedTableHead";
import { getTesterProfileInsurancesRequest } from "../../../../api/requests/admin-tester-profile";
import { IInsurance } from "../../../../models/insurance.interface";
import { styles } from "../../../../styles/material/tesrer-card";
import { downloadDocumentFile } from "../../../../api/requests/document-download";
import { API_DOCUMENTS_ENDPOINT_URL } from "../../../../constants/urls";
import { connect } from "react-redux";
import {formatDateWithSlashesDdMmYyyy} from "../../../../helpers/date-formatter";
import { INSURANCE_VALUES_COUNT } from "../../../../constants/insurance";

type IProps = RouteComponentProps<any> & WithStyles & {downloadDocumentFile: (...any) => void};

interface IState {
  isLoaded: boolean;
  insurances: IInsurance[];
}

class Insurance extends React.Component<IProps, IState> {
  state = {
    isLoaded: false,
    insurances: [],
  };

  componentWillMount() {
    this.fetchData();
  }

  downloadFile = (event) => {
    const evidenceDocumentId = event.currentTarget.dataset.evidenceid;
    if (evidenceDocumentId) {
      this.props.downloadDocumentFile(evidenceDocumentId)
    }
  };

  componentWillReceiveProps(nextProps: Readonly<IProps>, nextContext: any): void {
    this.setState({isLoaded: false, insurances: []});
    this.fetchData();
  }

  fetchData() {
    getTesterProfileInsurancesRequest(this.props.match.params.id)
      .then(data => {
        this.setState({insurances: data, isLoaded: true});
      });
  }

  getValidity = (item: IInsurance) => {
    const dateFrom = item.validFrom && formatDateWithSlashesDdMmYyyy(item.validFrom);
    const dateTo = item.validTo && formatDateWithSlashesDdMmYyyy(item.validTo);

    return `${dateFrom} to ${dateTo}`;
  };

  render() {
    if (!this.state.isLoaded){
      return (
        <div>
          Loading...
        </div>
      );
    }

    return (
      <Card className={this.props.classes.card}>
        <div className={this.props.classes.details}>
          <CardContent className={this.props.classes.content}>
            <Typography variant="headline" className={this.props.classes.header}>
              <span>
                Insurances
              </span>
            </Typography>
            <hr/>
            <Table>
              <EnhancedTableHead columns={['Name of insurance company', 'Type', 'Value', 'Valid', 'Evidence', 'Comments']} />
              <TableBody>
                {
                  !this.state.insurances.length ?
                    <TableRow key={0}>
                      <TableCell colSpan={6}>There are no Insurances.</TableCell>
                    </TableRow>
                    : this.state.insurances.map((item: IInsurance)=> {
                      return (
                        <TableRow key={item.id}>
                          <TableCell>{item.insuranceCompany}</TableCell>
                          <TableCell>{item.type}</TableCell>
                          <TableCell>
                            {item.value === INSURANCE_VALUES_COUNT
                              ? 'more than £' + (INSURANCE_VALUES_COUNT-1) + 'm'
                              : '£' +  item.value + 'm'
                            }
                          </TableCell>
                          <TableCell>{this.getValidity(item)}</TableCell>
                          <TableCell>
                            <Button
                              variant="contained"
                              color="primary"
                              data-evidenceid={item.evidence.replace(API_DOCUMENTS_ENDPOINT_URL, '')}
                              onClick={this.downloadFile}>
                              Download
                            </Button>
                          </TableCell>
                          <TableCell>{item.comments}</TableCell>
                        </TableRow>
                      );
                    })
                }
              </TableBody>
            </Table>
          </CardContent>
        </div>
      </Card>
    )
  }
}

const connected = connect(undefined ,{
  downloadDocumentFile
})(Insurance as any);

const routered = withRouter(withStyles(styles as any)(connected) as any);

export default routered as React.ComponentClass<{}>;
