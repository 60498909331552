import * as React from "react";
import {ManagedServicesUpdateListElement} from "./ManagedServicesUpdateListElement";
import {WrappedFieldArrayProps} from "redux-form/lib/FieldArray";
import {IManagedServices} from "../../../../models/managed-services.interface";

export interface ICompanyOption {
    name: string;
    id: number;
}

interface IManageServiceProps {
    level: number;
    companies: ICompanyOption[];
    isAdditionalServices?: boolean;
}

export const ManagedServicesUpdateList: React.SFC<WrappedFieldArrayProps<IManagedServices> & IManageServiceProps> =
    ({fields, level = 0, companies}) => {
        return (
            <ul className="specialisms-list">
                {fields.map((member, index) => {
                    return(
                            <ManagedServicesUpdateListElement
                                key={index}
                                fields={fields}
                                index={index}
                                level={level}
                                member={member}
                                companies={companies}
                                isAdditionalServices={member.includes("additionalServices")}
                            />
                    );
                })}
            </ul>
        );
    };
