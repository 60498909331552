import React, { useState } from "react";
import SearchByMonthForm from "../SearchByMonthForm";
import { InfoMessage } from "../InfoMessage";
import { getConsultantProfile } from "selectors/auth";
import { connect } from "react-redux";
import selector from "selectors/time-expenses";
import UpdateSelectedWeeksStatusForm from "../forms/UpdateSelectedWeeksStatusForm";
import { TE_STATUS } from "constants/timesheet-expenses";
import { TableNoItems } from "components/table/TableNoItems";
import { ConnectedProps } from "types";
import { RootState } from "reducers";
import { CONSULTANT_INFO_MESSAGE } from "components/timesheets-and-expenses/constants/info-message";
import ProjectTimesheet from "components/timesheets-and-expenses/components/Consultant/ProjectTimesheet";

type ConsultantTimeSheetsComponentProps = {} & PropsFromRedux;

export function TimesheetPage({
  consultantProfile,
  selectedProjectWeeks
}: ConsultantTimeSheetsComponentProps) {
  const projects = consultantProfile.projects || [];

  const [isOpenedConfirmModal, setIsOpenedConfirmModal] = useState<boolean>(
    false
  );

  return (
    <>
      <div className="d-flex justify-content-between align-items-center pt-2 pb-4">
        <h1 className="main-content-header mb-0">Time & Expenses</h1>
        <div>
          <button
            className="btn-accept"
            disabled={!selectedProjectWeeks.length}
            onClick={() => setIsOpenedConfirmModal(true)}
          >
            Submit selected
          </button>
        </div>
      </div>

      <SearchByMonthForm />
      <InfoMessage message={CONSULTANT_INFO_MESSAGE} />

      {projects.length === 0 && <TableNoItems hasWrapper />}

      {projects.length > 0 && (
        <ProjectTimesheet consultant={consultantProfile} />
      )}

      {isOpenedConfirmModal && (
        <UpdateSelectedWeeksStatusForm
          closeModal={() => setIsOpenedConfirmModal(false)}
          description="You are going to submit selected weeks. Please leave a comment if necessary."
          status={TE_STATUS.STATUS_SUBMITTED}
        />
      )}
    </>
  );
}

function mapStateToProps(state: RootState) {
  return {
    consultantProfile: getConsultantProfile(state),
    selectedProjectWeeks: selector.getSelectedProjectWeeks(state)
  };
}

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(TimesheetPage);
