import { createSelector } from "reselect";
import { ROLE, ROLE_GUEST } from "../constants/roles";
import { IPermissionsState } from "../reducers/auth";
import { Permission } from "../constants/permission";
import { Group } from "../constants/group";
import {
  AuthConsultantProject,
  ConsultantProfileType,
  ConsultantProject
} from "../types/timesheets-and-expenses";

export const getAuthState = (state): IPermissionsState => state.auth;

export const getAuth: (any: any) => boolean = createSelector(
  getAuthState,
  state => {
    return state.roles.indexOf(ROLE_GUEST) === -1;
  }
);

export const getIsLoadedPermissions: (any: any) => boolean = createSelector(
  getAuthState,
  state => state.isLoaded
);

export const getRoles: (any: any) => ROLE[] = createSelector(
  getAuthState,
  state => state.roles
);

export const getUserPermissions: (any: any) => Permission[] = createSelector(
  getAuthState,
  state => state.permissions
);

export const getUserGroup: (any: any) => Group = createSelector(
  getAuthState,
  state => state.group
);

export const getUserName: (any: any) => string = createSelector(
  getAuthState,
  state => state.name
);

export const getUserFirstName: (any: any) => string = createSelector(
  getAuthState,
  state => state.firstName
);

export const getUserLastName: (any: any) => string = createSelector(
  getAuthState,
  state => state.lastName
);

export const getUserEmail: (any: any) => string = createSelector(
  getAuthState,
  state => state.email
);

export const getUserPhone: (any: any) => string = createSelector(
  getAuthState,
  state => state.phone
);

export const getConsultantProfile: (
  any: any
) => ConsultantProfileType = createSelector(getAuthState, state => ({
  name: state.name,
  id: state.consultantProfile?.id,
  permissions: state.permissions,
  projects: state.consultantProfile?.consultantProjects
}));
