import {IConverterFromSwagger, IConverterToSwagger} from "./converter.interface";
// import {RegistrationClient as IRegistrationClientSwagger} from "../swagger/generated";
import {IRegistrationClient} from "../../models/registration-client.interface";

export const converterFromSwagger: IConverterFromSwagger<IRegistrationClient, any/*IRegistrationClientSwagger*/> = model => {
  return {...model, is_agree: model.isTermsAndConditionsApproved};
};

export const converterToSwagger: IConverterToSwagger<IRegistrationClient, any/*IRegistrationClientSwagger*/> = model => {
  return {...model, isTermsAndConditionsApproved: model.is_agree};
};
