import * as Types from "../constants/action-type";
import {
  createCrudAction,
  createRequestAction
} from "helpers/action-helper";
import { reset } from "redux-form";
import { setRequestInProcess } from "./request";
import { handleHttpErrors } from "helpers/errors/http-error-handler";
import {
  postItem,
  putItem,
  addAdditionalDocumentRequest,
} from "api/requests/report-document";
import { reportDocumentCreateModalClose } from "./modals";
import { IReportDocument } from "models/report-documents.interface";
import { ReportDocumentStatus } from "constants/report-document-status";
import { postDocumentItem } from "./document";
import { getReportForTest } from "api/requests/reports";
import { openSnackbar } from "./snackbar";
import { push } from "react-router-redux";
import {
  deleteAdditionalReportDocument, deleteReportDocument
} from "api/requests/document";
import {  RemoveReportsAdditionalDocumentType } from "types";

export const reportDocumentsRequest = createRequestAction(
  Types.REPORT_DOCUMENTS
);

export const setReportDocumentStatus = (
  idReportDocument,
  reportDocument: IReportDocument,
  status,
  formName?
) => async dispatch => {
  dispatch(setRequestInProcess(true, "setReportDocumentStatus"));

  let data;

  try {
    reportDocument.status = status;
    data = await putItem(idReportDocument, reportDocument);

    dispatch(createCrudAction(Types.REPORT_DOCUMENTS).replaceItem(data));

    if (formName) {
      dispatch(reset(formName));
    }
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(setRequestInProcess(false, "setReportDocumentStatus"));
};

export const acceptDocument = (item: IReportDocument) => async dispatch => {
  dispatch(setRequestInProcess(true, "acceptReportDocument"));

  let data;

  try {
    item.status = ReportDocumentStatus.STATUS_ACCEPTED;
    data = item.id && (await putItem(item.id.toString(), item));

    dispatch(createCrudAction(Types.REPORT_DOCUMENTS).replaceItem(data));
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(setRequestInProcess(false, "acceptReportDocument"));
};

export const createReportDocumentModalSubmit = (
  values,
  formName,
  reportDocument,
  testId,
  backRoute
) => async dispatch => {
  dispatch(setRequestInProcess(true, "createReportDocumentModalSubmit"));

  if (!values.document) {
    dispatch(openSnackbar("Choose report document", 4000));
    dispatch(setRequestInProcess(false, "createReportDocumentModalSubmit"));
    return;
  }

  let data;

  const reportStatus =
    reportDocument?.item?.status ===
      ReportDocumentStatus.STATUS_AMENDMENTS_REQUESTED ||
    reportDocument?.item?.status === ReportDocumentStatus.STATUS_REUPLOADED
      ? ReportDocumentStatus.STATUS_REUPLOADED
      : ReportDocumentStatus.STATUS_UPLOADED;

  const payload: IReportDocument = {
    document: "",
    name: values.name,
    report: testId,
    status: reportStatus,
    reportContent: " "
  };

  try {
    if (values.document instanceof File) {
      const createdFile = await dispatch(postDocumentItem(values.document));

      if (!createdFile) {
        dispatch(setRequestInProcess(false, "createReportDocumentModalSubmit"));
        return;
      }

      payload.document = createdFile.id;
    }

    if (!reportDocument || !reportDocument.item.id) {
      data = await postItem(payload);
      dispatch(
        openSnackbar(`New report document ${data.name} has been created`, 4000)
      );
    } else {
      data = await putItem(reportDocument.item.id, payload);
      dispatch(
        openSnackbar(`New report document ${data.name} has been updated`, 4000)
      );
    }

    dispatch(createCrudAction(Types.REPORT_DOCUMENTS).addItem(data));
    dispatch(reportDocumentCreateModalClose());
    dispatch(reset(formName));
    dispatch(push(backRoute));
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(setRequestInProcess(false, "createReportDocumentModalSubmit"));

  try {
    data = await getReportForTest(testId);

    dispatch(createCrudAction(Types.REPORTS).addItem(data));
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }
};
export const removeReportDocument = ({ reportId, document }) => async dispatch => {
  dispatch(setRequestInProcess(true, "removeReportDocument"));
  try {
    await deleteReportDocument(reportId, document.id);
    dispatch(createCrudAction(Types.REPORT_DOCUMENTS).removeItem(document));
    dispatch(setRequestInProcess(false, "removeReportDocument"));
    dispatch(
      openSnackbar(`Report document "${document.name}" has been removed`, 4000)
    );
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }
};
export const addReportsAdditionalDocument = ({
  values,
  testId,
  closeModal,
  reloadItems
}) => async dispatch => {
  dispatch(setRequestInProcess(true, "addReportsAdditionalDocument"));

  if (!values.document) {
    dispatch(openSnackbar("Choose additional document", 4000));
    dispatch(setRequestInProcess(false, "addReportsAdditionalDocument"));
    return;
  }

  try {
    if (values.name && values.document instanceof File) {
      const createdFile = await dispatch(postDocumentItem(values.document));

      if (!createdFile) {
        dispatch(setRequestInProcess(false, "addReportsAdditionalDocument"));
        return;
      }

      const newAdditionalDocument = await addAdditionalDocumentRequest({
        name: values.name,
        document: `/api/documents/${createdFile.id}`,
        report: `/api/reports/${testId}`
      });
      if (newAdditionalDocument?.name) {
        await reloadItems();
        closeModal();
        dispatch(openSnackbar("New additional document has been added", 4000));
      }
    }
  } catch (error) {
    handleHttpErrors(error, dispatch);
  } finally {
    dispatch(setRequestInProcess(false, "addReportsAdditionalDocument"));
  }
};
export const removeReportsAdditionalDocument = ({
  reportId,
  documentId,
  documentName,
  reloadItems
}: RemoveReportsAdditionalDocumentType) => async dispatch => {
  dispatch(setRequestInProcess(true, "removeReportsAdditionalDocument"));
  try {
    await deleteAdditionalReportDocument(reportId, documentId);

    await reloadItems();
    dispatch(setRequestInProcess(false, "removeReportsAdditionalDocument"));
    dispatch(
      openSnackbar(
        `Additional document "${documentName}" has been removed`,
        4000
      )
    );
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }
};
