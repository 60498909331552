import * as React from "react";
import { Button, Checkbox, ListItemText, MenuItem } from "@material-ui/core";
import { connect } from "react-redux";
import { find } from "lodash";
import { formValueSelector, InjectedFormProps, reduxForm } from "redux-form";
import { ACTIVE } from "../../../constants/filter";
import { testFilter } from "../../../actions/test";
import { FieldGeneric } from "../../../components/forms/FieldGeneric";
import { bindActionCreators } from "redux";
import { dataFetcher } from "../../../components/dataFetcher";
import { projectRequest } from "../../../actions/project";
import projectsSelector from "../../../selectors/project";
import ReduxSelectField from "../../../components/forms/ReduxSelectField";
import {
  getTestStatusNameForClientReportTab,
  TestStatus,
} from "../../../constants/test-status";
import ReduxDatePicker from "../../../components/forms/ReduxDatePicker";

const PROJECT_PAGE_BY_DEFAULT = 1;
const PROJECTS_PER_PAGE_BY_DEFAULT = 1000;

export const REPORTS_FORM_NAME = "ReportsSearchForm";
const selectorForm = formValueSelector(REPORTS_FORM_NAME);
export const ALL_STATUSES_REPORTS = [
  TestStatus.STATUS_BOOKED,
  TestStatus.STATUS_BOOKING_CANCELLED_BY_CLIENT,
  TestStatus.STATUS_BOOKING_CANCELLED_BY_TESTER,
  TestStatus.STATUS_REPORT_SUBMITTED,
  TestStatus.STATUS_AMENDMENT_SENT,
  TestStatus.STATUS_AMENDMENT_RESPONDED,
  TestStatus.STATUS_REPORT_ACCEPTED,
  TestStatus.STATUS_COMPLETED,
  TestStatus.STATUS_AVORD_VALIDATION,
];

export interface IProject {
  id: number;
  name: string;
  status: number;
}

type IProps = InjectedFormProps<{}, {}> & {
  submitFilterChanges: (value: string) => void;
  valuesData: {
    active: string;
    text?: string;
    questionAsked: boolean;
    status: number[];
    project: number[];
  };
  projects: IProject[];
};

const ReportsSearchForm = (props: IProps): JSX.Element => {
  const { handleSubmit, valuesData, projects, submitFilterChanges, change } =
    props;

  const joinProjects = (selected: IProject[]): string =>
    selected
      .map((data) => find(projects, { id: data }))
      .filter((a) => !!a)
      .map((data: IProject) => data.name)
      .join(", ");

  const joinStatuses = (selected: number[]): string =>
    selected.map(getTestStatusNameForClientReportTab).join(", ");

  const checkStatuses = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    change("status", ALL_STATUSES_REPORTS);
  };

  const uncheckStatuses = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    event.stopPropagation();
    change("status", []);
  };

  const checkProjects = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    change(
      "project",
      projects.map((a) => a.id)
    );
  };

  const uncheckProjects = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    event.stopPropagation();
    change("project", []);
  };

  return (
    <form
      onSubmit={handleSubmit((values: { active: string }) => {
        submitFilterChanges(values.active);
      })}
    >
      <div className="table-responsive styled-table styled-block">
        <div className="row">
          <div className="col-md-2">
            <FieldGeneric
              name="dateFrom"
              label="From"
              InputLabelProps={{
                shrink: true,
              }}
              component={ReduxDatePicker}
            />
          </div>
          <div className="col-md-2">
            <FieldGeneric
              name="dateTo"
              label="To"
              InputLabelProps={{ shrink: true }}
              addEndOfDay={true}
              component={ReduxDatePicker}
            />
          </div>
          <div className="col-md-8">
            <FieldGeneric
              name="project"
              label="Project"
              fullWidth
              multiple
              component={ReduxSelectField}
              renderValue={joinProjects}
            >
              <MenuItem>
                <Button color="primary" onClick={checkProjects}>
                  Check all
                </Button>
                <Button color="primary" onClick={uncheckProjects}>
                  Uncheck all
                </Button>
              </MenuItem>
              {projects.map((project) => (
                <MenuItem key={project.id} value={project.id}>
                  <Checkbox
                    checked={
                      (valuesData.project || []).indexOf(project.id) > -1
                    }
                  />
                  <ListItemText primary={project.name} />
                </MenuItem>
              ))}
            </FieldGeneric>
          </div>
          <div className="col-md-12 my-4">
            <FieldGeneric
              name="status"
              label="Status"
              fullWidth
              multiple
              component={ReduxSelectField}
              renderValue={joinStatuses}
            >
              <MenuItem>
                <Button color="primary" onClick={checkStatuses}>
                  Check all
                </Button>
                <Button color="primary" onClick={uncheckStatuses}>
                  Uncheck all
                </Button>
              </MenuItem>
              {ALL_STATUSES_REPORTS.map((status) => (
                <MenuItem key={status} value={status}>
                  <Checkbox
                    checked={(valuesData.status || []).indexOf(status) > -1}
                  />
                  <ListItemText
                    primary={getTestStatusNameForClientReportTab(status)}
                  />
                </MenuItem>
              ))}
            </FieldGeneric>
          </div>
        </div>
      </div>
    </form>
  );
};

function mapStateToProps(state: IProject, props: Readonly<IProps>) {
  return {
    valuesData: selectorForm(state, "project", "status"),
    initialValues: {
      project: props.projects.map((project) => project.id),
      status: ALL_STATUSES_REPORTS,
    },
  };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      submitFilterChanges: testFilter.submitFilterChanges,
    },
    dispatch
  );
};

const connectForm = reduxForm({
  form: REPORTS_FORM_NAME,
})(ReportsSearchForm);

const connected = connect(mapStateToProps, mapDispatchToProps)(connectForm);

const fetched = dataFetcher(connected, [
  {
    key: "projects",
    selector: (state) => projectsSelector.getItemsObject(state),
    action: () =>
       projectRequest.getItems(
        PROJECT_PAGE_BY_DEFAULT,
        PROJECTS_PER_PAGE_BY_DEFAULT,
        ACTIVE
      ),
  },
]);

export default fetched as React.ComponentClass<{}>;
