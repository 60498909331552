import * as React from "react";
import {
  composeValidators,
  fieldIsEmail,
  fieldsNotEmpty
} from "helpers/validations/validation";
import { InjectedFormProps, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { usersRequest } from "actions";
import AdminUsersForm from "./form/AdminUsersForm";
import { withRouter } from "react-router-dom";
import routerModal from "components/routerModal";
import { WithStyles } from "@material-ui/core/styles/withStyles";
import { Group } from "constants/group";
import { dataFetcher } from "components/dataFetcher";
import { RootState } from "reducers";
import { ConnectedProps } from "types";

const FORM_NAME = "AdminUsersCreate";

type Props = {
  backRoute: string;
};

class AdminUsersCreate extends React.Component<
  Props & InjectedFormProps & PropsFromRedux & WithStyles<any>
> {
  render() {
    const {
      handleSubmit,
      postData,
      backRoute,
      group,
      initialValues
    } = this.props;

    return (
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(values => {
          postData(values, FORM_NAME, backRoute);
        })}
      >
        <AdminUsersForm
          backRoute={backRoute}
          group={group}
          defaultRole={initialValues.role}
        />
      </form>
    );
  }
}

const formConnected = reduxForm({
  form: FORM_NAME,
  validate: composeValidators(
    fieldsNotEmpty(["firstName", "lastName", "email", "role"]),
    fieldIsEmail(["email"])
  )
})(AdminUsersCreate);

const connector = connect(
  (state: RootState) => {
    return {
      group: state.auth.group,
      initialValues: {
        role:
          state.auth.group === Group.SUPER_ADMIN
            ? Group.ADMIN
            : Group.ACCOUNT_MANAGER
      }
    };
  },
  {
    postData: usersRequest.postItem
  }
);

type PropsFromRedux = ConnectedProps<typeof connector>;

const connected = connector(formConnected);

const componentWithData = dataFetcher(connected, []);

export default routerModal(withRouter(componentWithData as any)) as any;
