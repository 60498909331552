import React from 'react';
import {Route, Switch} from "react-router-dom";
import EmailTemplatesList from "./EmailTemplatesList";
import EmailTemplatesCreate from "./EmailTemplatesCreate";
import EmailTemplatesUpdate from "./EmailTemplatesUpdate";
import {withRouter} from 'react-router-dom';
import {getCreateLink} from '../../../../helpers/router-helper';

class EmailTemplates extends React.Component {
  render() {
    const baseUrl = this.props.match.path;

    return (
      <div>
        <div className="crud-sections-header">
          <h4>Email Templates</h4>
        </div>
        <hr/>
        <Switch>
          <Route path={getCreateLink(baseUrl)} render={() => <EmailTemplatesCreate backRoute={baseUrl}/>}/>
          <Route path={baseUrl + "/:id"} render={() => <EmailTemplatesUpdate backRoute={baseUrl}/>}/>
        </Switch>
        <EmailTemplatesList baseUrl={baseUrl}/>
      </div>
    );
  }
}

export default withRouter(EmailTemplates);
