import * as Types from '../constants/action-type';
import {
  createFilterAction,
  createLoaderAction,
  createRequestAction
} from '../helpers/action-helper';

export const certificateTypeFilter = createFilterAction(Types.CERTIFICATE_TYPE);
export const certificateTypeLoader = createLoaderAction(Types.CERTIFICATE_TYPE);
export const certificateTypeRequest = createRequestAction(Types.CERTIFICATE_TYPE);
