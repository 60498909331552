// tslint:disable
import * as React from 'react';
import { connect } from "react-redux";
import { specialismsRequest, specialismAddItemsNested, specialismSaveBatch } from "../../../../actions/specialisms";
import selector from "../../../../selectors/specialisms";
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {FieldArray, InjectedFormProps, reduxForm} from 'redux-form';
import { SpecialismUpdateList } from './SpecialismUpdateList';
import Button from "@material-ui/core/Button/Button";
import {IAnyProps} from "../../../../interfaces/any-props.interface";
import {ISpecialism} from "../../../../models/specialism.interface";
import * as _ from 'lodash';
import SpecialismAddButton from "./SpecialismAddButton";

const FORM_NAME = 'SpecialismEdit';

interface IOwnProps{
  backRoute: string;
}

interface IMapProps {
  isLoaded: any;
  members: any;
}

interface IDispatchProps {
  specialismAddItemsNested: any;
  specialismSaveBatch: any;
  putData: any;
  deleteData: any;
  getItems: any;
}

interface IAccumulator {
  added: any[];
  updated: ISpecialism[];
  deleted: string[];
}

type OwnProps = RouteComponentProps<any>
  & InjectedFormProps<{members: ISpecialism[]}>
  & IAnyProps
  & IMapProps
  & IDispatchProps
  & IOwnProps;

class SpecialismUpdate extends React.Component<OwnProps, {}> {
  private static checkLevel = (valuesInit: Array<ISpecialism & IAnyProps>, comparisonFields: string[], parent?: ISpecialism) => {
    return (ak: IAccumulator, member: ISpecialism & IAnyProps, index, members: Array<ISpecialism & IAnyProps>): IAccumulator => {
      const acc: IAccumulator = {...ak};

      /* if new */
      if (!member.id) {
        /**
         *
         * Save with childs
         * Or additionally add parentType
         */
        acc.added = [
          ...(acc.added || []),
          parent ? {
              ...member,
              parentType: parent.id ? +parent.id : 0,
            } :
            member
        ];
      } else {
        const initialValue = _.find(valuesInit, {id: member.id}) as ISpecialism & IAnyProps;

        if (!_.isEqual(_.pick(initialValue, comparisonFields), _.pick(member, comparisonFields))) {
          acc.updated = [...(acc.updated || []), member];
        }

        const sub = _.result(member, 'subTypes', []);

        const difference = (items) => {
          const initValuesIds = _.map(items, 'id');
          const deleted = _.difference(initValuesIds, []);

          if (deleted.length) {
            return {
              deleted: [...deleted],
            };
          }

          return {};
        };

        /* Iterate through children */
        const children = sub.length ? sub.reduce(
          SpecialismUpdate.checkLevel(initialValue.subTypes || [], comparisonFields, member),
          {}
        )  as IAccumulator: difference(initialValue.subTypes || []) as IAccumulator;

        acc.added = [...(acc.added || []), ...(children.added || [])];
        acc.updated = [...(acc.updated || []), ...(children.updated || [])];
        acc.deleted = [...(acc.deleted || []), ...(children.deleted || [])];
      }

      /* Check deleted */
      if (index + 1 === members.length) {
        const membersIds = _.map(members, 'id');
        const initValuesIds = _.map(valuesInit, 'id');
        const deleted = _.difference(initValuesIds, membersIds);

        if (deleted.length) {
          acc.deleted = [...(acc.deleted || []), ...deleted] as string[];
        }
      }

      return acc;
    };
  };

  componentDidMount() {
    if (!this.props.isLoaded) {
      this.props.getItems();
    }
  }

  render() {
    const { handleSubmit, members, isLoaded } = this.props;

    if (!isLoaded) {
      return (<div>Loading...</div>);
    }

    return (
      <form
        noValidate
        className="specialism"
        autoComplete="off"
        onSubmit={handleSubmit((values) => {
          /** Check changes */
          this.checkChanges(values.members);
        })}
      >
        <Button variant="contained" type="submit" style={{marginLeft: 'auto', display: 'block'}}>
          Save
        </Button>
        <hr/>
        <FieldArray name="members" component={SpecialismUpdateList} rerenderOnEveryChange/>
        <FieldArray name="members" component={SpecialismAddButton}/>
      </form>
    );
  }

  private checkChanges = (membersArray: Array<ISpecialism & IAnyProps>) => {
    /* For short */
    const valuesInitial = this.props.initialValues.members || [];

    /* Iterate */
    const changes = membersArray.reduce(SpecialismUpdate.checkLevel(valuesInitial, ['name', 'description',]), {});

    this.props.specialismSaveBatch(changes);

    return 1;
  };

}

function mapStateToProps(state) {
  const isLoaded = selector.getIsLoaded(state);
  const vals: any =  {
    isLoaded,
    members: selector.getItems(state),
  };

  if (isLoaded) {
    vals.initialValues = {
      members: selector.getItems(state),
    };

    return vals;
  } else {
    return vals;
  }
}

const formConnected = reduxForm({
  form: FORM_NAME,
})(SpecialismUpdate);

const connected = connect<IMapProps, IDispatchProps>(mapStateToProps, {
  specialismAddItemsNested,
  specialismSaveBatch,
  putData: specialismsRequest.putItem,
  deleteData: specialismsRequest.archiveItem,
  getItems: specialismsRequest.getItems,
})(formConnected);

/** Todo: try to fix that */
export default withRouter(connected as any) as React.ComponentClass<IOwnProps>;
