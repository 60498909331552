import * as React from 'react';
import Button from "@material-ui/core/Button/Button";
import {Link, Route, Switch} from "react-router-dom";
import FaqList from "./FaqList";
import FaqSearchForm from "./FaqSearchForm";
import FaqCreate from "./FaqCreate";
import FaqUpdate from "./FaqUpdate";
import {withRouter} from 'react-router-dom';
import {getCreateLink} from '../../../../helpers/router-helper';

class Faq extends React.Component<any, {}> {
  baseUrl = this.props.match.path;

  createComponent = () => <FaqCreate backRoute={this.baseUrl}/>;
  updateComponent = () => <FaqUpdate backRoute={this.baseUrl}/>;

  render() {
    return (
      <div>
        <div>
          <div className="crud-sections-header">
            <h4>FAQ</h4>
            <Link to={getCreateLink(this.baseUrl)}>
              <Button variant="contained" color="default">
                New FAQ
              </Button>
            </Link>
          </div>
          <hr/>
          <Switch>
            <Route path={getCreateLink(this.baseUrl)} render={this.createComponent}/>
            <Route path={this.baseUrl + "/:id"} render={this.updateComponent}/>
          </Switch>
          <FaqSearchForm/>
          <FaqList baseUrl={this.baseUrl}/>
        </div>
      </div>
    );
  }
}

export default withRouter(Faq);
