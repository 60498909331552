import * as React from "react";
import {
  composeValidators, fieldMaxLength, fieldMinLength,
  fieldsNotEmpty
} from "../../../../helpers/validations/validation";
import {InjectedFormProps, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {request} from "../../../../actions/dbs-certificate";
import parameterFetcher from "../../../../components/parametersFetcher";
import selector from "../../../../selectors/dbs-certificate";
import routerModal from "../../../../components/routerModal";
import {WithStyles} from "@material-ui/core/styles/withStyles";
import {FieldGeneric} from "../../../../components/forms/FieldGeneric";
import ReduxTextField from "../../../../components/forms/ReduxTextField";
import {Link} from "react-router-dom";
import {Button, Grid, Typography} from "@material-ui/core";
import ReduxSelectField from "../../../../components/forms/ReduxSelectField";
import MenuItem from "@material-ui/core/MenuItem";
import {DBS_EDIT_STATUSES} from "../../../../constants/dbs-certificate-statuses";

const FORM_NAME = "DBSCertificateUpdate";

class DBSCertificateUpdate extends React.Component<InjectedFormProps<{}, {}> & {
  putData: (...any) => void,
  backRoute: string
} & WithStyles<any>, any> {
  render() {
    const {handleSubmit, putData, backRoute} = this.props;

    return (
      <div className="styled-modal md">
        <form noValidate autoComplete="off"
              onSubmit={handleSubmit((values) => {
                putData(values, FORM_NAME, backRoute);
              })}>
          <div>
            <Typography variant="headline">
            <span>
              Check Details
            </span>
            </Typography>
            <hr/>
            <Grid container spacing={40}>
              <Grid item xs={12}>
                <FieldGeneric
                  name="status"
                  label="Status"
                  component={ReduxSelectField}
                  fullWidth={true}
                >
                  {DBS_EDIT_STATUSES.map(option => (
                    <MenuItem
                      key={option.value}
                      value={option.value}>
                      {option.name}
                    </MenuItem>
                  ))}
                </FieldGeneric>

                <FieldGeneric
                  inputProps={{minLength: 2, maxLength: 60}}
                  name="adminComment"
                  label="Comments"
                  component={ReduxTextField}
                />

                <br/>
                <br/>

                <div className="d-flex justify-content-between mt-4">
                  <Link to={backRoute}>
                    <Button variant="contained" color="default">
                      Cancel
                    </Button>
                  </Link>
                  <Button type="submit" variant="contained" color="primary">
                    Save
                  </Button>
                </div>
              </Grid>
            </Grid>
          </div>
        </form>
      </div>
    );
  }
}

const formConnected = reduxForm({
  form: FORM_NAME,
  validate: composeValidators(
    fieldsNotEmpty(["employer", "jobTitle", "dateFrom", "dateTo"]),
    fieldMinLength(["employer", "jobTitle"], 3),
    fieldMaxLength(["employer", "jobTitle"], 55),
    (values) => {
      const error = {dateFrom: "From should not be after To"};

      return (new Date(values.dateFrom) >= new Date(values.dateTo)) ? error : {};
    },
    (values) => {
      const errorMessage = "From should be before the current day";

      if (new Date() < new Date(values.dateTo)) {
        return {dateTo: errorMessage};
      }

      if (new Date() < new Date(values.dateFrom)) {
        return {dateFrom: errorMessage};
      }

      return {};
    }
  )
})(DBSCertificateUpdate);

const connected = parameterFetcher(
  connect(undefined, {
    putData: request.putItem
  })(formConnected),
  FORM_NAME,
  request.getItem,
  selector.getItemById
);

export default routerModal(connected);