import * as React from "react";
import Paper from "@material-ui/core/Paper";
import {connect} from "react-redux";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import selector from "../../../selectors/users";
import {Field, formValueSelector, InjectedFormProps, reduxForm} from "redux-form";
import ReduxRadioButton from "../../../components/forms/ReduxRadioButton";
import * as FilterTypes from "../../../constants/filter";
import {bindActionCreators} from "redux";
import ReduxTextField from "../../../components/forms/ReduxTextField";
import {usersFilter} from "../../../actions/users";
import {isChangesExist} from "../../../helpers/props-checker";
import {FieldGeneric} from "../../../components/forms/FieldGeneric";

export const FORM_NAME = "TestersSearchForm";

const selectorForm = formValueSelector(FORM_NAME);


class TestersSearchForm extends React.Component<InjectedFormProps<{}, {}> & {
  submitFilterChanges: (...any) => void,
  valuesData: { active: string, text: string },
}, any> {
  componentWillReceiveProps(nextProps) {
    if (isChangesExist(["valuesData"], nextProps, this.props)) {
      this.props.submitFilterChanges(nextProps.valuesData.active, nextProps.valuesData.text);
    }
  }

  render() {
    // const {handleSubmit, reset} = this.props;

    return (
      <form className="mb-4">
        <Paper className="p-4">
          <div className="row">
            <div className="col-md-4">
              <Field
                name="text"
                label="Find"
                component={ReduxTextField}
                margin="normal"
              />
            </div>
            <div className="col-md-4" />
            <div className="col-md-4" />

          </div>
          <div className="row" >
            <div className="col-md-4 pt-4">
              <FieldGeneric
                name="active"
                inline={true}
                component={ReduxRadioButton}
                margin="normal"
              >
                <FormControlLabel
                  value={FilterTypes.ACTIVE}
                  control={<Radio/>}
                  label="Active"/>
                <FormControlLabel
                  value={FilterTypes.ARCHIVED}
                  control={<Radio/>}
                  label="Inactive"/>
                <FormControlLabel
                  value={FilterTypes.ALL}
                  control={<Radio/>}
                  label="Both"/>
              </FieldGeneric>
            </div>
          </div>
        </Paper>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    valuesData: {...selectorForm(state, "text", "active")},
    initialValues: {
      text: selector.getFilterTextFields(state),
      active: selector.getFilterActivityStatus(state)
    }
  };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    submitFilterChanges: usersFilter.submitFilterChanges
  }, dispatch);
};

const connectForm = reduxForm({
  form: FORM_NAME
})(TestersSearchForm);

export default connect(mapStateToProps, mapDispatchToProps)(connectForm);
