import React from "react";
import Button from "@material-ui/core/Button/Button";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import ExpensesTypesList from "./ExpensesTypesList";
import SearchForm from "./SearchForm";
import ExpensesTypesCreate from "./ExpensesTypesCreate";
import { withRouter } from "react-router-dom";
import { getCreateLink } from "../../../../helpers/router-helper";

interface ExpensesTypesProps extends RouteComponentProps {}

const ExpensesTypes = (props: ExpensesTypesProps) => {
  const baseUrl = props.match.path;
  const history = props.history;

  const openCreateModal = () => {
    history.push(getCreateLink(baseUrl));
  };

  return (
    <div>
      <div className="crud-sections-header">
        <h4>Expenses Types</h4>
        <Button
          variant="contained"
          color="default"
          type="button"
          onClick={openCreateModal}
        >
          New Expenses Type
        </Button>
      </div>
      <hr />
      <Switch>
        <Route
          path={getCreateLink(baseUrl)}
          render={() => <ExpensesTypesCreate />}
        />
        <Route path={baseUrl + "/:id"} render={() => <ExpensesTypesCreate />} />
      </Switch>
      <SearchForm />
      <ExpensesTypesList baseUrl={baseUrl} />
    </div>
  );
};

export default withRouter(ExpensesTypes);
