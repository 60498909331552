import React, { useState, useEffect } from "react";
import Table from "@material-ui/core/Table/Table";
import TableBody from "@material-ui/core/TableBody/TableBody";
import EnhancedTableHead from "../../../../../components/table/EnhancedTableHead";
import AdditionalDocumentsRow from "./AdditionalDocumentsRow";
import { AdditionalDocumentType } from "../../../../../types";
import { useAdditionalDocumentsList } from "../../../../../hooks/use-additional-documents-list";
import { TableNoItems } from "../../../../../components/table/TableNoItems";

interface IAdditionalDocumentsProps {
  testId?: number;
}

const AdditionalDocuments = (props: IAdditionalDocumentsProps) => {
  const { testId } = props;
  const [isLoading, setIsLoading] = useState<Boolean>(true);
  const {
    additionalDocumentsList,
    getAdditionalDocumentsItems
  } = useAdditionalDocumentsList();

  useEffect(() => {
    testId &&
      getAdditionalDocumentsItems(testId).then(() => setIsLoading(false));
  }, [testId]);

  if (isLoading) {
    return <TableNoItems asLoading />;
  }

  if (!additionalDocumentsList.length) {
    return <TableNoItems />;
  }

  return (
    <div className="table-responsive styled-table">
      {additionalDocumentsList.length && (
        <Table padding="dense">
          <EnhancedTableHead columns={["Document Name", "Uploaded Date", ""]} />
          <TableBody>
            {additionalDocumentsList.map((document: AdditionalDocumentType) => (
              <AdditionalDocumentsRow
                key={document.id}
                additionalDocument={document}
              />
            ))}
          </TableBody>
        </Table>
      )}
    </div>
  );
};

export default AdditionalDocuments;
