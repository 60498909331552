import * as Types from '../constants/action-type';
import {
  createLoaderAction,
  createCrudAction,
  createRequestAction
} from '../helpers/action-helper';
import {handleHttpErrors} from "../helpers/errors/http-error-handler";
import {getRequestName} from "../helpers/action-request-helper";
import {setRequestInProcess} from "./request";
import {fetchRequestBody} from "../helpers/request-helper";
import {getApi} from "../api/swagger/api-factory";
import {getItems, postItem, putItem} from "../api/requests/specialisms-without-report-templates";
import {postDocumentItem} from "./document";
import {openSnackbar} from "./snackbar";
import {reset} from "redux-form";
import {push} from "react-router-redux";

export const specialismsWithoutReportTemplatesRequest = createRequestAction(Types.SPECIALISMS_WITHOUT_REPORT_TEMPLATES);

export const getSpecialismsWithoutReportTemplatesAction = () => async dispatch => {
  let data;

  try {
    data = await getApi()
      .then(api => api.getApiReportTemplates())
      .then(fetchRequestBody);

    dispatch({type: Types.GET_SPECIALISMS_WITHOUT_REPORT_TEMPLATES, payload: data.without_templates});

  } catch (error) {
    handleHttpErrors(error, dispatch);
  }
};

export const postSpecialismWithoutReportTemplatesAction = (model, formName, backRoute) => async dispatch => {
  dispatch(setRequestInProcess(true, getRequestName(Types.CREATE_SPECIALISMS_WITHOUT_REPORT_TEMPLATES, 'postItem')));

  if (!model.specialism) {
    dispatch(openSnackbar('You must select specialism for a Report template', 4000));
    dispatch(setRequestInProcess(false, getRequestName(Types.CREATE_SPECIALISMS_WITHOUT_REPORT_TEMPLATES, 'postItem')));
    return;
  }

  let data, createdFile;

  let payload = {
    document_id: 0,
    specialism_id: 0
  };

  try {
    if (model.reportTemplate instanceof File) {
      createdFile = await dispatch(postDocumentItem(model.reportTemplate));

      if (!createdFile) {
        dispatch(setRequestInProcess(false, getRequestName(Types.CREATE_SPECIALISMS_WITHOUT_REPORT_TEMPLATES, 'postItem')));
        return;
      }

      payload.document_id = createdFile.id;
    }

    payload.specialism_id = model.specialism;

    data = await postItem(payload);

    dispatch(openSnackbar('Created', 4000));

    dispatch(createCrudAction(Types.CREATE_SPECIALISMS_WITHOUT_REPORT_TEMPLATES).addItem(data));
    dispatch({type: Types.UPDATE_SPECIALISMS_WITHOUT_REPORT_TEMPLATES, payload: data.without_templates});

    dispatch(reset(formName));
    dispatch(push(backRoute));
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(setRequestInProcess(false, getRequestName(Types.CREATE_SPECIALISMS_WITHOUT_REPORT_TEMPLATES, 'postItem')));

  try {
    data = await getItems();

    dispatch({
      type: Types.GET_SPECIALISMS_WITHOUT_REPORT_TEMPLATES,
      payload: data.without_templates,
    })
  }

  catch (error) {
    handleHttpErrors(error, dispatch);
  }
};


export const putSpecialismWithoutReportTemplatesAction = (model, formName, backRoute) => async dispatch => {
  dispatch(setRequestInProcess(true, getRequestName(Types.UPDATE_SPECIALISMS_WITHOUT_REPORT_TEMPLATES, 'putItem')));

  let data, createdFile;

  let payload = {
    reportTemplate: null,
    specialismId: null,
  };

  try {
    if (model.reportTemplate instanceof File) {

      createdFile = await dispatch(postDocumentItem(model.reportTemplate));

      if (!createdFile) {
        dispatch(setRequestInProcess(false, getRequestName(Types.UPDATE_SPECIALISMS_WITHOUT_REPORT_TEMPLATES, 'putItem')));
        return;
      }

      payload.reportTemplate = {
        document_id: createdFile.id
      };

      payload.specialismId = model.id;
    }

    data = await putItem(payload);

    dispatch(openSnackbar('Updated', 4000));
    dispatch(createCrudAction(Types.UPDATE_SPECIALISMS_WITHOUT_REPORT_TEMPLATES).replaceItem(data));
    dispatch(reset(formName));
    dispatch(push(backRoute));
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(setRequestInProcess(false, getRequestName(Types.UPDATE_SPECIALISMS_WITHOUT_REPORT_TEMPLATES, 'putItem')));

  try {
    data = await getItems();

    dispatch({
      type: Types.GET_SPECIALISMS_WITHOUT_REPORT_TEMPLATES,
      payload: data.without_templates,
    })
  }

  catch (error) {
    handleHttpErrors(error, dispatch);
  }
};

export const downloadReportTemplate = (id) => dispatch => async () => {
  dispatch(setRequestInProcess(true, getRequestName(Types.UPDATE_SPECIALISMS_WITHOUT_REPORT_TEMPLATES, 'putSpecialismsWithoutReportTemplates')));
  dispatch(createLoaderAction(Types.UPDATE_SPECIALISMS_WITHOUT_REPORT_TEMPLATES).setIsNotLoaded());

  let data;

  try {
    data = await getApi()
      .then(api => api.getDocumentItem(id))
      .then(fetchRequestBody);

    dispatch({type: Types.DOWNLOAD_SPECIALISM_REPOERT_TEMPLATE, payload: data});

  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(setRequestInProcess(false, getRequestName(Types.DOWNLOAD_SPECIALISM_REPOERT_TEMPLATE, 'getSpecialismsWithoutReportTemplates')));
};
