import { getApi } from "../swagger/api-factory";
import {
  IAdditionalServices,
  IEmptyAdditionalServices
} from "../../containers/client-tester/test-details/modal/AvordValidationModal";

export const adminValidateRequest = (additionalServices: IAdditionalServices[] | IEmptyAdditionalServices) => {
  if("testId" in additionalServices) {
    return getApi().then((api) => api.putServicesManagedCompanies({ testId: additionalServices }));
  } else {
    return getApi().then((api) => api.putServicesManagedCompanies({ additionalServices }));
  }
};

export const getAdditionalServicesRequest = (id: number) => {
  return getApi().then((api) => api.getServicesManagedCompanies({ id }));
};

