import * as _ from "lodash";
import * as CrudTypes from "../constants/crud-action-type";
import * as FilterTypes from "../constants/filter";
import * as ActionTypes from "../constants/loader";
import {
  APP_ACTION_PREFIX,
  LOADER_ITEM_TYPE,
  TESTER_PROFILE
} from "../constants/action-type";
import { getActionTypeName } from "./action-helper";
import {
  createLoaderActionItem,
  createLoaderReducerItem
} from "../interfaces/reducer-item.interface";
import { IActionParams } from "../epics";
import { IAnyProps } from "../interfaces/any-props.interface";
import { CHANGE_RECEIVE_BOOKING_REQUESTS } from "../constants/action-type";

export const replaceInArray = (array, item) => {
  const index = _.findIndex(array, { id: item.id });

  if (index < 0) {
    return [...array, item];
  } else {
    return [...array.slice(0, index), item, ...array.slice(index + 1)];
  }
};

export const removeInArray = (array, item) => {
  let index = _.findIndex(array, { id: item.id });

  index = index < 0 ? 0 : index;

  return [...array.slice(0, index), ...array.slice(index + 1)];
};

export const reducerCrud = (crudActions, state, action) => {
  const {
    ADD_ITEMS,
    ADD_ITEM,
    REMOVE_ITEM,
    REMOVE_ITEMS,
    REPLACE_ITEM
  } = crudActions;

  switch (action.type) {
    case ADD_ITEMS:
      return { ...state, items: [...action.payload] };
    case ADD_ITEM:
      return { ...state, items: replaceInArray(state.items, action.payload) };
    case REMOVE_ITEM:
      return { ...state, items: removeInArray(state.items, action.payload) };
    case REMOVE_ITEMS:
      return { ...state, items: [] };
    case REPLACE_ITEM:
      return { ...state, items: replaceInArray(state.items, action.payload) };
  }
};

export const emptyReducer = (state, action) => {
  return state;
};

export const mergeReducers = (...reducers) => {
  return (state, action) => {
    for (const reducer of reducers) {
      const crudResult = reducer(state, action);

      if (crudResult) {
        return crudResult;
      }
    }

    return state;
  };
};

export const filterItems = name => {
  /** for some performance optimization */
  const actionTypes = {
    setActiveActive: getActionTypeName(
      APP_ACTION_PREFIX,
      name,
      FilterTypes.ACTIVE
    ),
    setActiveArchived: getActionTypeName(
      APP_ACTION_PREFIX,
      name,
      FilterTypes.ARCHIVED
    ),
    setActiveAll: getActionTypeName(APP_ACTION_PREFIX, name, FilterTypes.ALL),
    setFilterText: getActionTypeName(APP_ACTION_PREFIX, name, FilterTypes.TEXT)
  };

  return (state, action) => {
    switch (action.type) {
      case actionTypes.setActiveActive:
        return { ...state, conditions: { active: FilterTypes.ACTIVE } };
      case actionTypes.setActiveArchived:
        return { ...state, conditions: { active: FilterTypes.ARCHIVED } };
      case actionTypes.setActiveAll:
        return { ...state, conditions: { active: FilterTypes.ALL } };
      case actionTypes.setFilterText:
        return { ...state, field: action.payload };
    }
  };
};

export const loaderItemReducerFactory = (name: LOADER_ITEM_TYPE) => {
  const ADD = createLoaderActionItem(name).add(null).type;
  const ERROR = createLoaderActionItem(name).error().type;
  const LOADED = createLoaderActionItem(name).loaded().type;

  return (
    state = createLoaderReducerItem(),
    action: IActionParams<IAnyProps>
  ) => {
    switch (action.type) {
      case ADD:
        return {
          ...state,
          error: false,
          loaded: true,
          item: action.payload
        };
      case ERROR:
        return {
          ...state,
          error: true,
          loaded: true,
          item: null
        };
      case LOADED:
        return {
          ...state,
          loaded: action.payload
        };
      case CHANGE_RECEIVE_BOOKING_REQUESTS:
        if (name === TESTER_PROFILE) {
          return {
            ...state,
            item: {
              ...(state.item as object),
              availableForBooking: action.payload
            }
          };
        }
        return state;
      default:
        return state;
    }
  };
};

export const reducerCrudItems = name => {
  const actionTypes = {
    addItems: getActionTypeName(APP_ACTION_PREFIX, name, CrudTypes.ADD_ITEMS),
    addItem: getActionTypeName(APP_ACTION_PREFIX, name, CrudTypes.ADD_ITEM),
    removeItem: getActionTypeName(
      APP_ACTION_PREFIX,
      name,
      CrudTypes.REMOVE_ITEM
    ),
    removeItems: getActionTypeName(
      APP_ACTION_PREFIX,
      name,
      CrudTypes.REMOVE_ITEMS
    ),
    replaceItem: getActionTypeName(
      APP_ACTION_PREFIX,
      name,
      CrudTypes.REPLACE_ITEM
    ),
    setPaginationData: getActionTypeName(
      APP_ACTION_PREFIX,
      name,
      ActionTypes.SET_PAGINATION_DATA
    )
  };

  return (state, action) => {
    switch (action.type) {
      case actionTypes.addItems:
        return [...action.payload];
      case actionTypes.addItem:
        return replaceInArray(state, action.payload);
      case actionTypes.removeItem:
        return removeInArray(state, action.payload);
      case actionTypes.removeItems:
        return [];
      case actionTypes.replaceItem:
        return replaceInArray(state, action.payload);
      case actionTypes.setPaginationData:
        return Array.isArray(action.payload.data) ? [...action.payload.data] : state;
      default:
        return;
    }
  };
};

const LOADER = "LOADER";

export const paginationDefaultState = {
  isLoaded: false,
  pageNumber: 1,
  itemsPerPage: 10,
  totalItems: 10
};

export const reducerLoaderItems = name => {
  /** for some performance optimization */
  const actionTypes = {
    setIsLoaded: getActionTypeName(
      APP_ACTION_PREFIX,
      name,
      ActionTypes.SET_IS_LOADED
    ),
    setIsNotLoaded: getActionTypeName(
      APP_ACTION_PREFIX,
      name,
      ActionTypes.SET_IS_NOT_LOADED
    ),
    setPageNumber: getActionTypeName(
      APP_ACTION_PREFIX,
      name,
      ActionTypes.SET_PAGE_NUMBER
    ),
    setItemsPerPage: getActionTypeName(
      APP_ACTION_PREFIX,
      name,
      ActionTypes.SET_ITEMS_PER_PAGE
    ),
    setTotalItems: getActionTypeName(
      APP_ACTION_PREFIX,
      name,
      ActionTypes.SET_TOTAL_ITEMS
    ),
    setPaginationData: getActionTypeName(
      APP_ACTION_PREFIX,
      name,
      ActionTypes.SET_PAGINATION_DATA
    )
  };

  return (state = paginationDefaultState, action) => {
    switch (action.type) {
      case actionTypes.setIsLoaded:
        return { ...state, isLoaded: true };
      case actionTypes.setIsNotLoaded:
        return { ...state, isLoaded: false };
      case actionTypes.setPageNumber:
        return { ...state, pageNumber: action.payload };
      case actionTypes.setItemsPerPage:
        const pageNumber =
          action.payload !== state.itemsPerPage ? { pageNumber: 1 } : {};

        /** Reset page number */
        return { ...state, itemsPerPage: action.payload, ...pageNumber };
      case actionTypes.setTotalItems:
        return { ...state, totalItems: action.payload };
      case actionTypes.setPaginationData:
        return {
          ...state,
          isLoaded: true,
          pageNumber: action.payload.pageNumber,
          totalItems: action.payload.totalItems,
          itemsPerPage: action.payload.itemsPerPage
        };
      default:
        return;
    }
  };
};
