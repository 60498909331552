import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { IAnyProps } from "../../../../interfaces/any-props.interface";
import { dataFetcher } from "../../../../components/dataFetcher";
import { IDocument } from "../../../../models/document.interface";
import { IReportDocument } from "../../../../models/report-documents.interface";
import { ISpecialism } from "../../../../models/specialism.interface";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import {
  getReportDocumentStatusName,
  ReportDocumentStatus,
  ReportDocumentStatusNames
} from "../../../../constants/report-document-status";
import { Button } from "@material-ui/core";
import { downloadDocumentFile } from "../../../../api/requests/document-download";
import { API_DOCUMENTS_ENDPOINT_URL } from "../../../../constants/urls";
import { connect } from "react-redux";
import {
  amendmentsModalOpen,
  reportAcceptedModalOpen
} from "../../../../actions/modals";
import { acceptDocument } from "../../../../actions/report-documents";
import { ITest } from "../../../../models/test.interface";
import { formatDateWithTime } from "../../../../helpers/date-formatter";
import { TestStatus } from "../../../../constants/test-status";
import PermissionBlock from "../../../../components/auth/PermissionBlock";
import { ROLE_PLANNER } from "../../../../constants/roles";

type IProps = RouteComponentProps<any> &
  IAnyProps & {
    reportDocument: any & IReportDocument;
    document: IDocument;
    specialism: ISpecialism;
    test: ITest;
    lastIndex: boolean;
    requestAmendmentsModal: (...any) => void;
    reportAcceptedModal: (...any) => any;
    acceptReportDocument: (item) => any;
    downloadDocumentFile: (id: number) => void;
  };

class CompletedReportTableRow extends React.Component<IProps, {}> {
  baseUrl = this.props.match.url;

  downloadFile = event => {
    const reportDocumentId = event.currentTarget.dataset.reportdocumentid;
    if (reportDocumentId) {
      this.props.downloadDocumentFile(reportDocumentId);
    }
  };

  requestAmendments = (item: IReportDocument) => () => {
    this.props.requestAmendmentsModal(item);
  };

  acceptReportDocument = (item: IReportDocument) => () => {
    this.props.acceptReportDocument(item);
  };

  getButtonAction = (item: IReportDocument) => {
    if (item.status === ReportDocumentStatus.STATUS_SUBMITTED) {
      return (
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="small"
          onClick={this.acceptReportDocument(item)}
        >
          Accept&nbsp;document
        </Button>
      );
    }

    return null;
  };

  getAmendmentsRequestAction = (item: IReportDocument) => {
    if (
      item.status !== ReportDocumentStatus.STATUS_ACCEPTED &&
      item.status !== ReportDocumentStatus.STATUS_AMENDMENTS_REQUESTED &&
      item.status !== ReportDocumentStatus.STATUS_UPLOADED &&
      item.status !== ReportDocumentStatus.STATUS_REUPLOADED
    ) {
      return (
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="small"
          onClick={this.requestAmendments(item)}
        >
          Request&nbsp;Clarification
        </Button>
      );
    }

    return null;
  };

  render() {
    const { reportDocument, specialism, test } = this.props;
    const getReportStatusName = () => {
      if (!reportDocument) {
        return null;
      }
      const statusName = getReportDocumentStatusName(
        reportDocument.status as ReportDocumentStatus
      );
      return statusName === ReportDocumentStatusNames.STATUS_REUPLOADED
        ? ReportDocumentStatusNames.STATUS_AMENDMENTS_REQUESTED
        : statusName;
    };
    return (
      <TableRow key={this.props.specialism.id}>
        <TableCell>{reportDocument.name}</TableCell>
        <TableCell>{getReportStatusName()}</TableCell>
        <TableCell>
          {reportDocument &&
            reportDocument.documentUploadedAt &&
            formatDateWithTime(reportDocument.documentUploadedAt)}
        </TableCell>
        <PermissionBlock roles={[ROLE_PLANNER]}>
          <TableCell>
            {reportDocument && this.getButtonAction(reportDocument)}
          </TableCell>
          <TableCell>
            {reportDocument && this.getAmendmentsRequestAction(reportDocument)}
          </TableCell>
        </PermissionBlock>
        <TableCell>
          {reportDocument?.document &&
            reportDocument.status !==
              ReportDocumentStatus.STATUS_REUPLOADED && (
              <Button
                variant="contained"
                color="primary"
                size="small"
                data-reportdocumentid={reportDocument.document
                  .toString()
                  .replace(API_DOCUMENTS_ENDPOINT_URL, "")}
                onClick={this.downloadFile}
              >
                Download
              </Button>
            )}
        </TableCell>
      </TableRow>
    );
  }
}

const fetched = dataFetcher(CompletedReportTableRow);

const routered = withRouter(fetched as any);

const connected = connect<IAnyProps, IAnyProps, IAnyProps>(
  (state: any) => ({}),
  {
    requestAmendmentsModal: amendmentsModalOpen,
    reportAcceptedModal: reportAcceptedModalOpen,
    acceptReportDocument: acceptDocument,
    downloadDocumentFile
  }
)(routered);

export default connected as any;
