export type CRUD_ACTION_TYPE =  'ADD_ITEM' |
                                'ADD_ITEMS' |
                                'REMOVE_ITEM' |
                                'REPLACE_ITEM' |
                                'REMOVE_ITEMS';

export const ADD_ITEM = 'ADD_ITEM' as CRUD_ACTION_TYPE;
export const ADD_ITEMS = 'ADD_ITEMS' as CRUD_ACTION_TYPE;
export const REMOVE_ITEM = 'REMOVE_ITEM' as CRUD_ACTION_TYPE;
export const REPLACE_ITEM = 'REPLACE_ITEM' as CRUD_ACTION_TYPE;
export const REMOVE_ITEMS = 'REMOVE_ITEMS' as CRUD_ACTION_TYPE;
