import * as Types from "../constants/action-type";
import {
  IAdditionalServices,
  IEmptyAdditionalServices,
} from "../containers/client-tester/test-details/modal/AvordValidationModal";

export default (
    state = {},
    action: {
      type: string;
      payload: IAdditionalServices | IEmptyAdditionalServices;
    }
) => {
  switch (action.type) {
    case Types.ADDITIONAL_SERVICE_GET_ITEM:
      return action.payload;
    default:
      return state;
  }
};