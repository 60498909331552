import {extractJSONLD, fetchRequestBody} from '../../helpers/request-helper';
import {getApiJSONLD, getApi} from '../swagger/api-factory';
import {converterFromSwagger} from '../convertors/message';

export const messageSearchRequest = (
  id: string | number,
  pageNumber: number,
  itemsPerPage: number,
  from: string,
  to: string
) => {
  return getApiJSONLD()
    .then(api => api.api_tests_messages_get_subresource({
      id,
      $queryParameters: {
        page: pageNumber,
        items: itemsPerPage,
      },
      createdAtAfter: from,
      createdAtBefore: to,
    }))
    .then(extractJSONLD(converterFromSwagger));
};

export const postMessage = (content: string, test: number | string) => {
  return getApi()
    .then(api => api.postApiMessages({message: {content, test: `api/tests/${test}`}}))
    .then(fetchRequestBody)
    .then(converterFromSwagger);
};

export const putMessage = (id: number | string, message: string) => {
  return getApi()
    .then(api => api.putMessageItem({id, message: {content: message}}))
    .then(fetchRequestBody)
    .then(converterFromSwagger);
};

export const resetMessages = (id: number | string) => {
  return getApi()
    .then(api => api.reset_messagesTestItem({id}))
    .then(fetchRequestBody)
    .then(converterFromSwagger);
};
