import * as Types from '../constants/action-type';
import {
  createFilterAction,
  createLoaderAction,
  createCrudAction,
  createRequestAction
} from '../helpers/action-helper';
import {createAction, Action} from "redux-actions";
import {IDBSCertificateLocal} from "../models/dbs-certificate.interface";

export interface IPostDBSCertificateAction {
  dbsCertificate: IDBSCertificateLocal;
  backRoute: string;
  formName: string;
}

export const crud = createCrudAction(Types.DBS_CERTIFICATES);
export const filter = createFilterAction(Types.DBS_CERTIFICATES);
export const loader = createLoaderAction(Types.DBS_CERTIFICATES);
export const request = createRequestAction(Types.DBS_CERTIFICATES);

export const getTesterDBSCertifications = () => {
  return {
    type: Types.DBS_CERTIFICATES_GET,
    payload: {},
  };
};

export const testerDBSCertificateArchive = (id: string) => {
  return {
    type: Types.DBS_CERTIFICATE_ARCHIVE,
    payload: id,
  };
};

export const postItem = (dbsCertificate: IDBSCertificateLocal, backRoute: string, formName: string): Action<IPostDBSCertificateAction> => {
  return createAction(Types.DBS_CERTIFICATE_POST, () => ({
    dbsCertificate,
    backRoute,
    formName,
  }))();
};
