import { Link } from "react-router-dom";
import * as React from "react";
import { ITest } from "models/test.interface";

type ClientNameProps = {
  test: ITest;
}

export function ClientName({test}: ClientNameProps) {
  return <Link to={`/admin/clients/details/${test.plannerId}`}>
    {test && test.plannerCompanyName}
  </Link>
}
