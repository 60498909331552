import {convertorFromSwagger, convertorToSwagger} from '../convertors/report';
import {fetchRequestBody, extractJSONLD} from '../../helpers/request-helper';
import {getApi, getApiJSONLD, getApiJSON} from '../swagger/api-factory';
import {IAnyProps} from "../../interfaces/any-props.interface";
import {format} from "date-fns";
import {IReport} from "../../models/report.interface";

export const getItems = (page: number, itemsPerPage: number) => {
    const parameters: IAnyProps = {page};

    parameters.items = itemsPerPage;

    return getApiJSONLD()
        .then(api => api.getReportCollection(parameters))
        .then(extractJSONLD(convertorFromSwagger));
};

export const getItem = (id: string | number) => {
    return getApi()
        .then(api => api.getReportItem({id}))
        .then(fetchRequestBody)
        .then(convertorFromSwagger);
};

export const postItem = (
  idTest,
  summaryIntroduction = " ",
  summaryGathering = " ",
  technicalIntroduction = " ",
  technicalGathering = " "
) => {
  return getApi()
    .then(api => api.postReportCollection({report: {
      test: `api/tests/${idTest}`,
      summaryGathering,
      summaryIntroduction,
      technicalIntroduction,
      technicalGathering,
    }
    }))
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};

export const putItem = (id: string, model: IReport) => {
    return getApi()
        .then(api => api.putReportItem({
            id,
            report: convertorToSwagger(model),
        }))
        .then(fetchRequestBody)
        .then(convertorFromSwagger);
};

export const getReportForTest = (idTest: string | number) => {
  return getApiJSON()
    .then(api => api.api_tests_report_get_subresource({id: idTest}))
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};

export const getReportAdditionalDocuments = async (testId: string | number) => {
  return getApiJSON()
    .then(api => api.getAdditionalReportDocumentCollection({report: testId}))
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};