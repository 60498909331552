import * as React from "react";
import { Link, Route, Switch } from "react-router-dom";
import UsersList from "./UsersList";
import UsersSearchForm from "./UsersSearchForm";
import UsersCreate from "./UsersCreate";
import UsersUpdate from "./UsersUpdate";
import { withRouter } from "react-router-dom";
import { getCreateLink } from "../../../../helpers/router-helper";
import { getUserGroup } from "selectors/auth";
import { connect } from "react-redux";

class Users extends React.Component<any, {}> {
  baseUrl = this.props.match.path;

  createComponent = () => <UsersCreate backRoute={this.baseUrl} />;
  updateComponent = () => <UsersUpdate backRoute={this.baseUrl} />;

  render() {
    const { userGroup } = this.props;
    return (
      <div>
        <div className="crud-sections-header">
          <Link to={getCreateLink(this.baseUrl)} className="btn-new">
            {"Add User"}
          </Link>
        </div>
        <Switch>
          <Route
            path={getCreateLink(this.baseUrl)}
            render={this.createComponent}
          />
          <Route path={this.baseUrl + "/:id"} render={this.updateComponent} />
        </Switch>
        <UsersSearchForm />
        <UsersList baseUrl={this.baseUrl} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userGroup: getUserGroup(state),
  };
}

export default connect(mapStateToProps, {})(withRouter(Users));
