import {setRequestInProcess} from "./request";
import {getRequestName} from "../helpers/action-request-helper";
import * as Types from "../constants/action-type";
import {createLoaderAction} from "../helpers/action-helper";
import {handleHttpErrors} from "../helpers/errors/http-error-handler";
import {getItems} from "../api/requests/audit-log";
import {AuditLogFilterParamsType} from "../types";

export const auditLogLoader = createLoaderAction(Types.GET_AUDIT_LOG);

export const getAuditLogsRequestsAction = (params: AuditLogFilterParamsType) => async dispatch => {
  const itemsPerPage = params?.itemsPerPage || 25;
  const pageNumber = params?.pageNumber || 1;
  dispatch(setRequestInProcess(true, getRequestName(Types.GET_AUDIT_LOG, 'getAuditLogsRequestsAction')));

  dispatch(auditLogLoader.setIsNotLoaded());

  let data = [], totalItems;

  try {
    ({items: data, totalItems} = await getItems(params));
    dispatch(auditLogLoader.setPaginatioData(data, itemsPerPage, totalItems, pageNumber));
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }
  finally {
    dispatch(setRequestInProcess(false, getRequestName(Types.GET_AUDIT_LOG, 'getAuditLogsRequestsAction')));
  }
};