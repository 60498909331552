import { Group } from "constants/group";
import { Switch } from "@material-ui/core";
import * as React from "react";
import { IUsers } from "models/users.interface";
import { useCallback } from "react";
import { connect } from "react-redux";
import { getUserPermissions } from "selectors/auth";
import { Permission } from "constants/permission";
import {
  ConfirmWrapper
} from "containers/admin/testers/TesterList/columns/ConfirmWrapper";
import { OnReset } from "containers/admin/testers/TesterList/constants";

type SwitchCompanyManagerProps = {
  user: IUsers,
  onSwitchCompanyManager: OnReset,
};

function Component({ user, onSwitchCompanyManager, permissions }: SwitchCompanyManagerProps & { permissions: string[] }) {
  const onConfirm = useCallback(() => {
    onSwitchCompanyManager(user);
  }, []);

  const isDisabled = !!user.deletedAt || !permissions.includes(Permission.CAN_MANAGE_TESTERS);

  return isDisabled ? (
    <Switch
      checked={user.groupName === Group.MANAGER}
      disabled={isDisabled}
      value="true"
    />
  ) : (
    <ConfirmWrapper onConfirm={onConfirm}>
      <Switch
        checked={user.groupName === Group.MANAGER}
        disabled={isDisabled}
        value="true"
      />
    </ConfirmWrapper>
  );
}

export const SwitchCompanyManager = connect(state => ({
  permissions: getUserPermissions(state),
}), {})(Component);
