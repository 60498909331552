import * as ActionTypes from '../constants/action-type';
import {IReview} from "../models/review.interface";
import {combineReducers} from "redux";
import {IWorkHistory} from "../models/work-history.interface";
import {ICertificate} from "../models/certificate.interface";
import {result} from "lodash";

export interface IAdminTesterProfileState {
  profiles: {
    [testerId: string]: {
      workHistories: IWorkHistory[];
      certificates: ICertificate[];
      reviews: IWorkHistory[];
      details: IWorkHistory[];
    }
  }
}

// TESTER_PROFILE_SET_WORK_HISTORIES
// TESTER_PROFILE_SET_REVIEWS
// TESTER_PROFILE_SET_CERTIFICATES
// TESTER_PROFILE_SET_DETAILS

const reducer = (state: IAdminTesterProfileState = { profiles: {} }, action) => {
  switch (action.type) {
    case ActionTypes.TESTER_PROFILE_SET_WORK_HISTORIES:
      return {
        ...state,
        [action.payload.id]: {
          ...result(state, `profile.${action.payload.id}`, {}),
          workHistory: action.payload.data,
        },
      };
    default:
      return state;
  }
};

export default reducer
