import * as React from "react";
import { Field } from "redux-form";
import ReduxTextField from "components/forms/ReduxTextField";
import { Link } from "react-router-dom";
import { handleKeyPressOnPhoneField } from "helpers/validations/numberFieldValidator";
import { handleSpaceKeyPress } from "helpers/validations/emptyTestFieldValidation";

class UsersForm extends React.Component<
  {
    backRoute: string;
    initialValues: any;
  },
  any
> {
  render() {
    return (
      <div>
        <Field
          inputProps={{ minLength: 1, maxLength: 50 }}
          name="firstName"
          label="First name *"
          component={ReduxTextField}
          margin="normal"
        />
        <Field
          inputProps={{ minLength: 1, maxLength: 50 }}
          name="lastName"
          label="Last Name *"
          component={ReduxTextField}
          margin="normal"
        />
        <Field
          inputProps={{ minLength: 1, maxLength: 60 }}
          name="email"
          label="Email *"
          component={ReduxTextField}
          onKeyPress={handleSpaceKeyPress}
          margin="normal"
        />
        <Field
          inputProps={{ minLength: 1, maxLength: 20 }}
          name="phone"
          label="Telephone *"
          type={`tel`}
          component={ReduxTextField}
          margin="normal"
          onKeyPress={handleKeyPressOnPhoneField}
        />
        <div className="text-right mt-4">
          <Link className="btn-cancel" to={this.props.backRoute}>
            Cancel
          </Link>
          <button type="submit" className="btn-accept">
            Save
          </button>
        </div>
      </div>
    );
  }
}

export default UsersForm;
