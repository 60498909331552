import * as React from "react";
import {RouteComponentProps} from "react-router-dom";
import {withRouter} from "react-router-dom";
import {FieldGeneric} from "../../../components/forms/FieldGeneric";
import {reduxForm} from "redux-form";
import {connect} from "react-redux";
import {createAction} from "redux-actions";
import {MESSAGE_SEARCH_APPLY} from "../../../constants/action-type";
import ReduxDatePicker from "../../../components/forms/ReduxDatePicker";

export const MESSAGE_SEARCH_FORM_NAME = "MessageSearch";

class MessageSearch extends React.Component<RouteComponentProps<any> & {
  messageSearchApply: (...any) => void,
}, {}> {
  search = () => {
    this.props.messageSearchApply(this.props.match.params.test);
  };

  render() {
    return (
      <div>
        <div className="py-4">
          <div className="row">
            <div className="col-md-4">
              <FieldGeneric
                name="dateFrom"
                label="From"
                InputLabelProps={{shrink: true}}
                component={ReduxDatePicker}
              />
            </div>
            <div className="col-md-4">
              <FieldGeneric
                name="dateTo"
                label="To"
                InputLabelProps={{shrink: true}}
                addEndOfDay={true}
                component={ReduxDatePicker}
              />
            </div>
            <div className="col-md-4 text-right">
              <button className="btn-search mt-4" onClick={this.search}>Search</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {};
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    messageSearchApply: (id: string) => dispatch(createAction(MESSAGE_SEARCH_APPLY, () => id)()),
  };
};

const routered = withRouter(MessageSearch);

const connectForm = reduxForm({
  form: MESSAGE_SEARCH_FORM_NAME,
})(routered as any);

const connected = connect(undefined, mapDispatchToProps)(connectForm);

export default connected;
