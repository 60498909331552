import { converterFromSwagger, converterToSwagger } from '../convertors/dbs-certificate';
import * as FilterActions from '../../constants/filter';
import { fetchRequestBody, dummyItemsPagination } from '../../helpers/request-helper';
import { getApi } from '../swagger/api-factory';
import {IDBSCertificate} from "../../models/dbs-certificate.interface";
import {IAnyProps} from "../../interfaces/any-props.interface";

export const getItems = (page: number, itemsPerPage: number, show: string, text: string = '') => {
  const isShow = show || FilterActions.DEFAULT_FILTER;
  const parameters: IAnyProps = { page };

  parameters.items = itemsPerPage;
  parameters.show = isShow;

  if (text) {
    parameters.question = text;
  }

  return getApi()
    .then(api => api.getDBSCheckCollection(parameters))
    .then(fetchRequestBody)
    .then(dummyItemsPagination);
};

export const getItem = (id: string|number) => {
  return getApi()
    .then(api => api.getDBSCheckItem({ id }))
    .then(fetchRequestBody)
    .then(converterFromSwagger);
};

export const postItem = (model: IDBSCertificate) => {
  return getApi()
    .then(api => api.postDBSCheckCollection({
      dBsCheck: converterToSwagger(model),
    }))
    .then(fetchRequestBody)
    .then(converterFromSwagger);
};

export const putItem = (model: IDBSCertificate) => {
  return getApi()
    .then(api => api.putDBSCheckItem({
      id: model.id,
      dBsCheck: converterToSwagger(model),
    }))
    .then(fetchRequestBody)
    .then(converterFromSwagger);
};

export const deleteItem = (id: string|number) => {
  return getApi()
    .then(api => api.deleteDBSCheckItem({ id, }))
    .then(fetchRequestBody);
};
