import {Observable} from 'rxjs';
import {catchError, flatMap, map, switchMap, take} from 'rxjs/operators';
import {ActionsObservable} from 'redux-observable';
import * as actionType from '../constants/action-type';
import * as Request from '../api/requests/tester-account';
import {fromPromise} from "rxjs/internal-compatibility";
import {IAction, IActionPayload} from "./index";
import {startEndCatch} from "./pipes/errors";
import {of} from "rxjs/internal/observable/of";
import {createLoaderActionItem} from "../interfaces/reducer-item.interface";
import {LOADER_ITEM_TYPE} from "../constants/action-type";
import {setRequestInProcess} from "../actions/request";
import {certificateLoader} from "../actions/certificate";
import {loader as DBScertificateLoader} from "../actions/dbs-certificate";
import {insuranceLoader} from "../actions/insurance";
import {getTesterSpecialisms} from "../selectors/tester-profile";
import {ITesterProfile} from "../models/tester-profile.interface";
import {merge} from "rxjs/internal/observable/merge";
import {IDocument} from "../models/document.interface";
import {createAction} from "redux-actions";
import {reset} from "redux-form";
import {IPutProfileAction} from "../actions/testers";
import {push} from "react-router-redux";
import {putTesterProfile, putTesterSpecialisms} from "../api/requests/tester-account";
import {ICertificate} from "../models/certificate.interface";
import {IAnyProps} from "../interfaces/any-props.interface";
import {combineLatest} from "rxjs/internal/observable/combineLatest";
import {getSpecialismsFlat} from "../helpers/specialisms";
import {IInsurance} from "../models/insurance.interface";

const createCrudLoaderEpic = (
  action$: ActionsObservable<IAction>,
  type: LOADER_ITEM_TYPE,
  request: (action) => Promise<any>
) => {
  return action$
    .ofType(createLoaderActionItem(type).get().type)
    /** Need to build system for a non repeating requests */
    /*zip(
      action$
        .ofType(createLoaderActionItem(type).get().type),
      merge(
        of(createAction('Obs init')()),
        action$.ofType(createLoaderActionItem(type).add(null).type),
        action$.ofType(createLoaderActionItem(type).error().type)
      )
    )*/
    .pipe(
      /** Skip until all things will be loaded */
      switchMap(action => {
        return fromPromise(request(action))
          .pipe(
            flatMap((data) => {
              return [
                createLoaderActionItem(type).add(data),
              ];
            }),
            ...startEndCatch(
              `${type}_request`,
              of(createLoaderActionItem(type).error())
            ),
          );
      }),
    );
};

export const testerProfileGetEpic = (action$: ActionsObservable<IAction>): Observable<any> => {
  return createCrudLoaderEpic(
    action$,
    actionType.TESTER_PROFILE,
    (action) => Request.requestOwnTesterProfile()
  );
};

export const testerBankDetailsGetEpic = (action$: ActionsObservable<IAction>): Observable<any> => {
  return createCrudLoaderEpic(
    action$,
    actionType.TESTER_BANK_DETAILS,
    (action) => Request.requestOwnTesterBankDetails()
  );
};

export const testerSpecialismsGetEpic = (action$: ActionsObservable<IAction>): Observable<any> => {
  return createCrudLoaderEpic(
    action$,
    actionType.TESTER_SPECIALISMS,
    (action) => Request.requestOwnTesterSpecialisms()
  );
};

type Fetcher<T = any, N = any> = (id: string|number, action: N) => Observable<T>;

const fetchFromServerCertificates: Fetcher<ICertificate[]> = (id: string|number, action) => {
  return fromPromise(Request.requestCertificatesProfile(id))
    .pipe(
      flatMap((data) => {
        return [
            certificateLoader.setPaginatioData(data, 1000, data.length, 1),
        ];
      }),
      ...startEndCatch('testerCertificatesEpic'),
    );
};

const fetchFromServerInsurances: Fetcher<IInsurance[]> = (id: string|number, action) => {
  return fromPromise(Request.requestInsurancesProfile(id))
    .pipe(
      flatMap((data) => {
        return [
          insuranceLoader.setPaginatioData(data, 1000, data.length, 1),
        ];
      }),
      ...startEndCatch('testerInsurancesEpic'),
    );
};

const postProfile: Fetcher<ICertificate[]> = (id: string|number, action) => {
  return fromPromise(Request.requestCertificatesProfile(id))
    .pipe(
      flatMap((data) => {
        return [
            certificateLoader.setPaginatioData(data, 1000, data.length, 1),
        ];
      }),
      ...startEndCatch('testerCertificatesEpic'),
    );
};

const fetchTesterProfileId = (action$: ActionsObservable<any>, state$, action, fetcherFn: Fetcher<any>) => {
  // const testerProfile: IReducerItem<ITesterProfile> = getProfile(state$.value);

  // if (!testerProfile.loaded) {
    return merge(
      of(createLoaderActionItem(actionType.TESTER_PROFILE).get()),
      action$
        .ofType(createLoaderActionItem(actionType.TESTER_PROFILE).add(null).type)
        .pipe(
          take(1),
          switchMap((tester: IActionPayload<ITesterProfile>) => {
            return fetcherFn(tester.payload.id ? tester.payload.id : '', action);
          })
        ),
    );
  // }

  // return fetcherFn(testerProfile.item && testerProfile.item.id ? testerProfile.item.id : '', action);
};

export const testerCertificatesEpic = (action$: ActionsObservable<IAction>, state$): Observable<any> => {
  return action$
    .ofType(actionType.CERTIFICATES_GET)
    .pipe(
      switchMap(action => {
        return fetchTesterProfileId(action$, state$, action, fetchFromServerCertificates);
      }),
      catchError((error) => {
        return of(setRequestInProcess(false, 'testerCertificatesEpic'));
      })
    );
};

export const testerInsurancesEpic = (action$: ActionsObservable<IAction>, state$): Observable<any> => {
  return action$
    .ofType(actionType.INSURANCES_GET)
    .pipe(
      switchMap(action => {
        return fetchTesterProfileId(action$, state$, action, fetchFromServerInsurances);
      }),
      catchError((error) => {
        return of(setRequestInProcess(false, 'testerCertificatesEpic'));
      })
    );
};

const fetchFromServerDBSCertificates: Fetcher<ICertificate[]> = (id: string|number, action) => {
    return fromPromise(Request.requestDBSCertificatesProfile(id))
        .pipe(
            flatMap((data) => {
                return [
                    DBScertificateLoader.setPaginatioData(data, 1000, data.length, 1),
                ];
            }),
            ...startEndCatch('testerDBSCertificatesEpic'),
        );
};

export const testerDBSCertificatesEpic = (action$: ActionsObservable<IAction>, state$): Observable<any> => {
    return action$
        .ofType(actionType.DBS_CERTIFICATES_GET)
        .pipe(
            switchMap(action => {
                return fetchTesterProfileId(action$, state$, action, fetchFromServerDBSCertificates);
            }),
            catchError((error) => {
                return of(setRequestInProcess(false, 'testerDBSCertificatesEpic'));
            })
        );
};

export const putTesterProfileEpic = (action$: ActionsObservable<IActionPayload<IPutProfileAction>>, state$): Observable<any> => {
  return action$
    .ofType(createLoaderActionItem(actionType.TESTER_PROFILE).put().type)
    .pipe(
      switchMap((action: IActionPayload<IPutProfileAction>) => {
        const fetcher: Fetcher<any> = (profileId, action: IActionPayload<IPutProfileAction>) => {

          const putProfileFn = (createdFile?: IActionPayload<IDocument>, crestCertificate?: IActionPayload<IDocument>): Observable<any> => {
            const model: IAnyProps = {
              "testerFirstName": action.payload.item.testerFirstName,
              "testerLastName": action.payload.item.testerLastName,
              "companyName": action.payload.item.companyName,
              "linkedinLink": action.payload.item.linkedinLink,
              "testerAddressLine": action.payload.item.testerAddressLine,
              "testerAddressLine2": action.payload.item.testerAddressLine2,
              "testerAddressLine3": action.payload.item.testerAddressLine3,
              "testerTown": action.payload.item.testerTown,
              "testerState": action.payload.item.testerState,
              "postCode": action.payload.item.postCode,
              "bio": action.payload.item.bio,
              "picture": action.payload.item.picture,
              "currencyCode": action.payload.item.currencyCode,
              "rate": action.payload.item.rate,
              "companyNumber": action.payload.item.companyNumber,
              "isHasVatNumber": action.payload.item.isHasVatNumber,
              "vatNumber": action.payload.item.vatNumber,
              "isGovernmentCleared": action.payload.item.isGovernmentCleared,
              "isCheckRegisteredCompany": action.payload.item.isCheckRegisteredCompany,
              "checkRegisteredCompany": action.payload.item.checkRegisteredCompany,
              "isCrestRegisteredCompany": action.payload.item.isCrestRegisteredCompany,
              "companyCrestCertificate": action.payload.item.companyCrestCertificate,
              "countryId": action.payload.item.country,
              "testingCompanyName": action.payload.item.testingCompanyName
            };

            if (createdFile) {
              model.picture = createdFile.payload.id;
            }

              if (crestCertificate) {
                  model.companyCrestCertificate = crestCertificate.payload.id;
              }

            const savingSpecialisms = (specialisms) => {
              const sort = (a, b) => {
                return a.localeCompare(b);
              };
              let specialismsInit = getTesterSpecialisms(state$.value).item;

              specialismsInit = !specialismsInit ? [] : getSpecialismsFlat(specialismsInit);

              const specialismsInitSorted = specialismsInit.map(a => '' + a.id).sort(sort);
              const specialismsSorted = Object.keys(specialisms || {}).filter(specId => {
                return specialisms[specId];
              }).map(a => '' + a).sort(sort);

              if (JSON.stringify(specialismsSorted) === JSON.stringify(specialismsInitSorted)) {
                return of([]);
              }

              return fromPromise(putTesterSpecialisms(specialismsSorted))
                .pipe(
                  map((data) => {
                    return [
                      createLoaderActionItem(actionType.TESTER_SPECIALISMS).get(),
                    ];
                  }),
                );
            };

            return merge(
              of(createLoaderActionItem(actionType.TESTER_PROFILE).loaded(false)),
              combineLatest(
                fromPromise(putTesterProfile(profileId, model)),
                savingSpecialisms(action.payload.item.specialisms)
              )
                .pipe(
                  flatMap(([data, specialistActions = []]) => {
                    return [
                      reset(action.payload.formName),
                      push(action.payload.backRoute),
                      createAction(actionType.TESTER_SPECIALISMS_EDIT_CLOSE)(),
                      createLoaderActionItem(actionType.TESTER_PROFILE).add(data),
                      ...specialistActions,
                    ];
                  }),
                  ...startEndCatch('postCertificateEpic', [
                    createLoaderActionItem(actionType.TESTER_PROFILE).loaded()
                  ])
                ),
            );
          };

          return putProfileFn();
        };

        return fetchTesterProfileId(action$, state$, action, fetcher);
      })
    );
};
