import * as React from "react";
import Icon from "@material-ui/core/Icon/Icon";
import Button from "@material-ui/core/Button/Button";
import {WrappedFieldArrayProps} from "redux-form/lib/FieldArray";
import {IManagedServices} from "../../../../models/managed-services.interface";
import {IAnyProps} from "../../../../interfaces/any-props.interface";

export default class ManagedServicesAddButton extends React.Component<WrappedFieldArrayProps<IManagedServices | IAnyProps>> {
    newItem = () => this.props.fields.push({name: '', description: '', subTypes: []});

    render() {
        return (
            <Button
                className="action-button"
                variant="fab"
                type="button"
                color="secondary"
                onClick={this.newItem}
                style={{height: 40, width: 40, marginTop: 15}}
            >
                <Icon>add</Icon>
            </Button>
        )
    }
}